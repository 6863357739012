import { Box, chakra, Grid, Text } from '@chakra-ui/react';

import { ReactComponent as Startups } from './assets/500.svg';
import { ReactComponent as Andreessen } from './assets/andreessen.svg';
import { ReactComponent as Kleiner } from './assets/kleiner.svg';
import { ReactComponent as LererHippeau } from './assets/lerer-hippeau.svg';
import { ReactComponent as Sequoia } from './assets/sequoia.svg';
import { ReactComponent as TechStars } from './assets/techstars.svg';

const AndreessenLogo = chakra(Andreessen, {
  baseStyle: {
    width: { base: '145px', xs: '305px' },
    height: { base: '48px', xs: '100px' },
  },
});

const TechStarsLogo = chakra(TechStars, {
  baseStyle: {
    width: { base: '145px', xs: '305px' },
    height: { base: '48px', xs: '100px' },
  },
});

const SequoiaLogo = chakra(Sequoia, {
  baseStyle: {
    width: { base: '145px', xs: '305px' },
    height: { base: '48px', xs: '100px' },
  },
});

const KleinerLogo = chakra(Kleiner, {
  baseStyle: {
    width: { base: '145px', xs: '305px' },
    height: { base: '48px', xs: '100px' },
  },
});

const LererHippeauLogo = chakra(LererHippeau, {
  baseStyle: {
    width: { base: '145px', xs: '305px' },
    height: { base: '48px', xs: '100px' },
  },
});

const StartupsLogo = chakra(Startups, {
  baseStyle: {
    width: { base: '145px', xs: '305px' },
    height: { base: '40px', xs: '70px' },
  },
});

export const CoInvest = () => {
  return (
    <Box bgColor="grey.50" pb={{ base: '46px', xs: '34px', lg: '62px' }}>
      <Box maxWidth={1112} mx="auto">
        <Box px={{ base: '4', xs: '30px', lg: 0 }} mb="14">
          <Box
            maxWidth={{ base: '60', xs: 'none' }}
            mb={{ base: '8', xs: '3', lg: '6' }}
          >
            <Text
              color="grey.900"
              fontSize="3xl"
              fontWeight="semibold"
              lineHeight="3xl"
              letterSpacing="tighter"
            >
              Co-invest with the best
            </Text>
          </Box>
          <Box pr={{ xs: '36', sm: '80', lg: 0 }} w={{ lg: '596px' }}>
            <Text
              color="grey.600"
              fontSize="lg"
              fontWeight="normal"
              lineHeight="md"
              letterSpacing="tight"
            >
              Some of the top names in venture capital have invested in the same
              companies that raise on Republic.
            </Text>
          </Box>
        </Box>
        <Grid
          gridTemplateColumns={{ base: '50% 50%', lg: 'auto auto auto' }}
          alignItems="center"
          color="grey.900"
        >
          <AndreessenLogo justifySelf="center" mb={{ base: '9', lg: '7' }} />
          <TechStarsLogo justifySelf="center" mb={{ base: '9', lg: '7' }} />
          <SequoiaLogo justifySelf="center" mb={{ base: '9', lg: '7' }} />
          <KleinerLogo justifySelf="center" mb={{ base: '9', lg: '7' }} />
          <LererHippeauLogo justifySelf="center" mb={{ base: '9', lg: '7' }} />
          <StartupsLogo justifySelf="center" mb={{ base: '9', lg: '7' }} />
        </Grid>
      </Box>
    </Box>
  );
};
