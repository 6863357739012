import { Box, Button, Img, Text } from '@chakra-ui/react';

import avawatzLogo from './assets/avawatz-logo.png';
import avawatzLogo2x from './assets/avawatz-logo@2x.png';

export function AvaWatzBanner() {
  return (
    <Box
      as="a"
      aria-label="AvaWatz: View deal"
      background={{
        base: 'radial-gradient(113.28% 179.93% at 50% 55.11%, #EF4A23 34.5%, #892A14 100%)',
        md: 'radial-gradient(circle, #EF4A23 45%, #A52D11 100%)',
      }}
      color="white"
      display="block"
      href="/avawatz"
      overflow="hidden"
    >
      <Box
        px={{ base: '2' }}
        pt={{ base: '2', md: '2.5' }}
        pb={{ base: '4', md: '2.5' }}
        position="relative"
        textAlign={{ base: 'center', md: 'right' }}
      >
        <Box
          display={{ base: 'block', md: 'flex' }}
          position={{ base: 'unset', md: 'absolute' }}
          top={{ base: 'unset', md: '50%' }}
          left={{ base: 'unset', md: '4', lg: '50%' }}
          w={{ md: '720px' }}
          transform={{
            base: 'unset',
            md: 'translate(0, -50%)',
            lg: 'translate(-50%, -50%)',
          }}
          justifyContent="center"
          flexWrap="nowrap"
          alignItems="center"
        >
          <Text as="span">
            Trusted AI Robotics: Transforming safety across high-risk and
            tedious tasks
          </Text>
          <Img
            src={avawatzLogo}
            srcSet={`${avawatzLogo} 1x, ${avawatzLogo2x} 2x`}
            display={{ base: 'none', md: 'inline-block' }}
            w="40px"
            h="40px"
            border="1px solid"
            borderColor="grey.50"
            borderRadius="base"
            mx="2"
          />
          <Text as="span" display={{ md: 'none' }}>
            &nbsp;with&nbsp;
          </Text>
          <Text as="span" fontWeight="bold">
            Avawatz
          </Text>
        </Box>

        <Box px={{ base: '2', md: '0' }}>
          <Button
            backgroundColor="transparent"
            as="span"
            color="white"
            w={{ base: 'full', md: 'initial' }}
            mt={{ base: 3, md: 0 }}
            _hover={{
              backgroundColor: 'rgba(255,255,255, 0.08)',
            }}
            px="6"
            py={{ base: '1', md: '2.5' }}
            height="auto"
            borderRadius="lg"
            fontSize={{ base: '14px', md: 'lg' }}
            fontWeight={{ base: 'medium', md: 'bold' }}
            border="1px solid"
            borderColor="grey.200"
            lineHeight={{ base: 'md', md: 'lg' }}
            boxShadow="none"
          >
            View deal
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
