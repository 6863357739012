import React from 'react';

import {
  Box,
  Heading,
  Image,
  LinkBox,
  LinkOverlay,
  Skeleton,
  Text,
} from '@chakra-ui/react';

import { formatCurrencyFromCents } from '@r-client/shared/util/core';

import { IEuropeCardData } from '../../types';

export interface IEuropeOfferingCardProps {
  cardData: IEuropeCardData | null;
  isLoading?: boolean;
  onOfferingClick?: VoidFunction;
}

const COVER_HEIGHT = '255px';

export const EuropeOfferingCard = ({
  cardData,
  isLoading,
  onOfferingClick,
}: IEuropeOfferingCardProps) => {
  const [showExtraInfo, setShowExtraInfo] = React.useState(false);

  const skeletonStyles = {
    fadeDuration: 1,
    startColor: 'brand.grey.f5',
    endColor: 'brand.grey.f5',
  };

  if (!cardData)
    return (
      <Skeleton
        isLoaded={false}
        minHeight="500px"
        minWidth="345px"
        {...skeletonStyles}
      />
    );

  const extraInfo: Record<'value' | 'label' | 'key', string>[] = [
    {
      value: formatCurrencyFromCents(
        cardData.currency,
        cardData.raisedAmountCents
      ),
      label: 'raised',
      key: 'raised',
    },
    {
      value: formatCurrencyFromCents(
        cardData.currency,
        cardData.targetAmountCents
      ),
      label: 'target',
      key: 'target',
    },
    {
      value: cardData.numberOfInvestors.toString(),
      label: 'investors',
      key: 'investors',
    },
    {
      value: formatCurrencyFromCents(
        cardData.currency,
        cardData.preMoneyValuationCents,
        true,
        true
      ),
      label: 'valuation',
      key: 'valuation',
    },
  ];

  return (
    <Skeleton
      as="article"
      data-testid="eu-offering-card"
      isLoaded={!isLoading}
      bg="white"
      borderRadius="2xl"
      {...skeletonStyles}
      _hover={{
        boxShadow: '0 3px 8px rgba(0,0,0, 0.05)',
      }}
    >
      <LinkBox
        onMouseEnter={() => setShowExtraInfo(true)}
        onMouseLeave={() => setShowExtraInfo(false)}
        onClick={onOfferingClick}
      >
        <Box
          h="full"
          minHeight="500px"
          border="1px solid"
          borderColor="grey.100"
          borderRadius="2xl"
          position="relative"
        >
          <Image
            alt=""
            w="100%"
            h={COVER_HEIGHT}
            objectFit="cover"
            src={cardData.coverImage}
            borderTopRadius="2xl"
          />
          <Box
            p="6"
            pt="6"
            top={showExtraInfo ? `calc(${COVER_HEIGHT} - 105px)` : COVER_HEIGHT}
            bottom="0"
            position="absolute"
            bgColor="white"
            transition="top 0.2s ease-in-out"
            borderBottomRadius="2xl"
          >
            <Box>
              <Image
                alt=""
                src={cardData.logo}
                w="60px"
                h="60px"
                position="absolute"
                top="-43px"
                backgroundColor="white"
                borderRadius="base"
                boxShadow="0 1px 2px 0 rgba(0, 0, 0, 0.2)"
              />
              <Heading
                as="p"
                size="republic-h4"
                mb="2"
                mt="0"
                fontWeight="extrabold"
              >
                {cardData.title}
              </Heading>
              <Text noOfLines={2} textStyle="md" color="grey.600">
                {cardData.description}
              </Text>
              <Box
                as="footer"
                position="absolute"
                bottom="0"
                left="0"
                right="0"
                overflow="hidden"
                pb="3.5"
              >
                <Box
                  px="6"
                  opacity={showExtraInfo ? '0' : '1'}
                  position={showExtraInfo ? 'absolute' : 'initial'}
                  transition="opacity  0.2s ease-in-out"
                  transitionDelay={showExtraInfo ? '0' : '0.3s'}
                >
                  <Text textStyle="md" color="grey.400">
                    {cardData.city}, {cardData.countryCode}
                  </Text>
                  <Text
                    color="grey.700"
                    bgColor="grey.100"
                    textTransform="uppercase"
                    borderRadius="md"
                    fontSize="xs"
                    px="1.5"
                    py="1"
                    my="1"
                    display="inline-block"
                  >
                    Europe
                  </Text>
                  <Text color="grey.200" textStyle="xs" mt="0.5">
                    {cardData.typeForDisplay}
                  </Text>
                </Box>
                <Box
                  px="0"
                  maxHeight={
                    showExtraInfo ? `calc(${COVER_HEIGHT} - 30px)` : '0'
                  }
                  opacity={showExtraInfo ? '1' : '0'}
                  transition="max-height 0.3s ease-in-out, opacity 0.4s ease-in-out"
                >
                  {extraInfo.map((info) => (
                    <Text
                      key={info.key}
                      textStyle="md"
                      borderTop="1px solid"
                      borderColor="grey.100"
                      py="9px"
                      px="6"
                      _last={{
                        pb: '6px',
                      }}
                    >
                      <Text as="span" fontWeight="semibold" mr="1">
                        {info.value}
                      </Text>
                      {info.label}
                    </Text>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <LinkOverlay
          isExternal
          href={`https://www.seedrs.com/${cardData.slug}?app=republic`}
        />
      </LinkBox>
    </Skeleton>
  );
};
