import { Search2Icon } from '@chakra-ui/icons';
import { IconButton, IconButtonProps } from '@chakra-ui/react';

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
type IconButtonWithOptionalLabel = PartialBy<IconButtonProps, 'aria-label'>;
type ISearchIconButtonProps = Omit<IconButtonWithOptionalLabel, 'icon'>;

export const SearchIconButton = ({
  'aria-label': ariaLabel,
  ...props
}: ISearchIconButtonProps) => {
  return (
    <IconButton
      role="group"
      aria-label={ariaLabel || 'Search'}
      icon={
        <Search2Icon
          boxSize="18px"
          color="blackAlpha.400"
          transition="color 0.2s ease-in-out"
          _groupHover={{ color: 'brand.blue.2d' }}
        />
      }
      rounded="full"
      variant="ghost"
      paddingInlineStart={0}
      paddingInlineEnd={0}
      bgColor="blackAlpha.50"
      _hover={{ bgColor: 'blackAlpha.100' }}
      {...props}
    />
  );
};
