/* eslint-disable max-lines */

import { Heading, Text, TextProps as BaseTextProps } from '@chakra-ui/react';
import styled from 'styled-components';

import { Maybe } from '@r-client/shared/util/core';

import { media } from '../../../utils/media';
import { rem } from '../../../utils/styles';
import { Col, Grid, Row } from '../grid';
import { PITCH_THEME, SECONDARY_MARKET_THEME } from './constants';
import { IContentWrapperConfig, IPillProps } from './types';

export const CarouselGridSecondaryMarket = styled(Grid)`
  height: 100%;
`;

export const CarouselGrid = styled(Grid)`
  height: 50%;
  max-width: ${rem(1134)};

  ${(props) =>
    media(props.theme.grid.breakpoints.md)`
      height: 100%;
    `}
`;

export const CarouselRow = styled(Row)`
  height: 100%;
`;

export const CarouselCol = styled(Col)`
  position: relative;
  height: 100%;
`;

const getOverlayColor = (color: Maybe<string>, theme: string) => {
  if (color) {
    return color;
  } else if (theme === 'dark') {
    return 'black';
  } else {
    return 'white';
  }
};

interface IBackgroundImageProps {
  backgroundMobileImage: Maybe<string>;
  backgroundDesktopImage: Maybe<string>;
}

interface IForegroundImageProps {
  foregroundMobileImage?: Maybe<string>;
  foregroundDesktopImage?: Maybe<string>;
}

interface ITextProps extends BaseTextProps {
  overlayTheme?: string;
  children?: React.ReactNode;
}

interface IHeaderProps {
  overlayTheme?: string;
  children?: React.ReactNode;
  dangerouslySetInnerHTML: { __html: string };
}

interface IDisclaimerProps {
  overlayTheme?: string;
  itemTheme?: Maybe<string>;
}

interface IDescriptionWrapperProps {
  itemTheme?: Maybe<string>;
}

interface IOverlayProps {
  overlayColor?: Maybe<string>;
  overlayOpacity?: number;
  overlayTheme: string;
}

const BackgroundImageWrapper = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: block;
`;

const Overlay = styled.div<IOverlayProps>`
  position: absolute;
  height: 50%;
  width: 100%;
  background-color: ${(props) =>
    getOverlayColor(props.overlayColor, props.overlayTheme)};
  opacity: ${(props) => props.overlayOpacity};
  z-index: 2;
  ${(props) =>
    media(props.theme.grid.breakpoints.md)`
      height: 100%;
      width: 50%;
    `}
`;

const OverlayEnd = styled.div<IOverlayProps>`
  position: absolute;
  top: 50%;
  border-style: solid;
  width: 0;
  height: 0;
  border-width: 30px 800px 0 0;
  border-color: ${(props) =>
      getOverlayColor(props.overlayColor, props.overlayTheme)}
    transparent transparent transparent;
  opacity: ${(props) => props.overlayOpacity};
  z-index: 2;
  ${(props) =>
    media(props.theme.grid.breakpoints.md)`
      position: absolute;
      left: 50%;
      top: 0;
      height: 100%;
      border-style: solid;
      border-width: ${rem(500)} 0 0 ${rem(120)};
      border-color: transparent transparent transparent ${getOverlayColor(
        props.overlayColor,
        props.overlayTheme
      )};
    `}
`;

const ContentWrapper = styled.div<IContentWrapperConfig>`
  position: relative;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: ${(props) => (props.centered ? 'center' : 'flex-start')};
  margin-top: ${(props) => (props.displayTitle ? rem(24) : 0)};
  margin-bottom: 0;
  box-sizing: border-box;
  padding: ${(props) => (props.centered ? '3rem 0 1rem 0' : '2rem 0 0')};
  height: 100%;
  text-align: center;
  max-width: 73.125rem;
  margin-left: auto;
  margin-right: auto;
  ${(props) =>
    media(props.theme.grid.breakpoints.md)`
      text-align: left;
      justify-content: flex-start;
      margin: 0 auto;
  `}
`;

const Header = ({ overlayTheme, ...props }: IHeaderProps) => {
  return (
    <Heading
      fontSize={{ base: 'xl', md: '4xl' }}
      color={overlayTheme === 'dark' ? 'white' : 'grey.900'}
      fontWeight="semibold"
      lineHeight={{ base: 'xl', md: '4xl' }}
      letterSpacing={{ base: 'tight', md: 'tighter' }}
      my="4"
      mb="4"
      {...props}
    />
  );
};

const LogoWrapper = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 1rem;
  max-width: 18rem;
  text-align: center;
  img {
    display: block;
    border: none;
    max-height: 100%;
    width: auto;
    max-width: 100%;
    font-size: 1rem;
    font-weight: 400;
    max-height: 2.5rem;

    /* HACK FOR IE */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      width: 100%;
    }
    /* HACK FOR IE */
  }

  ${(props) =>
    media(props.theme.grid.breakpoints.md)`
      justify-content: flex-start;
      flex-direction: row;
      align-items: flex-end;

      img {
      max-height: 4.5rem;
      }
  `}
`;

const LogoPlaceholder = styled.div`
  height: ${rem(50)};
  width: ${rem(100)};
`;

const Pill = styled.div<IPillProps>`
  display: ${(props) => (props.show ? 'inline-block' : 'none')};
  margin: 0.75rem auto 0;
  border-radius: 0.75rem;
  padding: 0 0.5rem;
  height: 1.5rem;
  font-size: 0.75rem;
  line-height: 1.5rem;
  text-align: center;
  color: white;
  cursor: default;
  transition: background-color 0.3s;
  background-color: ${(props) => props.theme.colors.blue.base};
  ${(props) =>
    media(props.theme.grid.breakpoints.md)`
      display: inline-block;
      margin: 0 0 0 1rem;
      position: absolute;
      bottom: 0;
      left: 100%;
      z-index: 10;
  `}
`;

const DescriptionWrapper = styled.div<IDescriptionWrapperProps>`
  margin-bottom: ${(props) => {
    switch (props.itemTheme) {
      case PITCH_THEME:
        return rem(24);
      case SECONDARY_MARKET_THEME:
        return rem(10);
      default:
        return rem(16);
    }
  }};
  font-size: 1.125rem;
  line-height: 1.5;
  font-weight: 400;
  letter-spacing: -0.03125rem;
`;

const DescriptionText = ({ children, overlayTheme, ...props }: ITextProps) => {
  return (
    <Text
      fontSize={rem(18)}
      size="republic-body-md"
      color={overlayTheme === 'dark' ? 'white' : 'grey.600'}
      {...props}
    >
      {children}
    </Text>
  );
};

const SecondaryMarketDescriptionText = (props: ITextProps) => {
  return <DescriptionText {...props} fontSize={rem(16)} />;
};

const ActionWrapper = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: center;
  ${(props) =>
    media(props.theme.grid.breakpoints.md)`
      display: block;
  `}
`;

const BackgroundImage = styled.div<IBackgroundImageProps>`
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.colors.neutral.xlight};
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  background-image: url(${(props) => props.backgroundMobileImage});
  ${(props) =>
    media(props.theme.grid.breakpoints.md)`
      background-image: url(${props.backgroundDesktopImage});
    `}
`;

const DisclaimerOverlay = styled.div<IDisclaimerProps>`
  padding: 0.75rem;
  background-color: ${(props) =>
    props.overlayTheme === 'dark' ? 'rgba(0, 0, 0, 0.7)' : 'transparent'};
  z-index: 10;
  ${(props) =>
    media(props.theme.grid.breakpoints.md)`
      max-width: 45vw;
      position: initial;
      background-color: transparent;
      padding-left: 0;
  `}
`;

const DisclaimerText = ({ overlayTheme, ...props }: ITextProps) => {
  return (
    <Text
      size="republic-body-xs"
      css={{
        a: {
          textDecoration: 'underline',
        },
      }}
      {...props}
      color={overlayTheme === 'dark' ? 'white' : 'grey.600'}
    />
  );
};

const ForegroundImageWrapper = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 10%;
  right: 0;
  bottom: 10%;
  left: 0;
  z-index: 2;
  display: block;

  ${(props) =>
    media(props.theme.grid.breakpoints.md)`
      top: ${rem(16)};
      right: ${rem(16)};
      bottom: ${rem(16)};
  `}
`;

const ForegroundImage = styled.div<IForegroundImageProps>`
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  background-image: ${(props) =>
    props.foregroundMobileImage
      ? `url(${props.foregroundMobileImage})`
      : 'none'};
  ${(props) =>
    media(props.theme.grid.breakpoints.md)`
      background-image: ${
        props.foregroundDesktopImage
          ? `url(${props.foregroundDesktopImage})`
          : 'none'
      };
    `}
`;

export const ForegroundComponentWrapper = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: ${rem(16)};
  height: 100%;

  ${(props) =>
    media(props.theme.grid.breakpoints.md)`
      margin-top: 0;
    `}
`;

export const Styles = {
  ActionWrapper,
  ContentWrapper,
  DescriptionText,
  DescriptionWrapper,
  DisclaimerOverlay,
  DisclaimerText,
  Header,
  BackgroundImage,
  BackgroundImageWrapper,
  ForegroundImage,
  ForegroundImageWrapper,
  ForegroundComponentWrapper,
  LogoWrapper,
  LogoPlaceholder,
  Overlay,
  OverlayEnd,
  Pill,
  SecondaryMarketDescriptionText,
};
