import React from 'react';

import { Box, Button, Img, Text } from '@chakra-ui/react';

import modeMobileBg from './assets/mode-mobile-bg.png';
import modeMobileLogo from './assets/mode-mobile-logo.png';
import modeMobileLogo2x from './assets/mode-mobile-logo@2x.png';

export function ModeMobileBanner() {
  return (
    <Box
      as="a"
      aria-label="Mode Mobile: View deal"
      backgroundColor="black"
      backgroundImage={`url(${modeMobileBg})`}
      backgroundPosition="50% 0"
      backgroundRepeat="no-repeat"
      backgroundSize={{ base: 'cover' }}
      color="white"
      display="block"
      href="/mode-mobile"
      overflow="hidden"
    >
      <Box
        px={{ base: 4 }}
        pt={{ base: '6px', md: '10px' }}
        pb={{ base: 4, md: '10px' }}
        position="relative"
        textAlign={{ base: 'center', md: 'right' }}
      >
        <Box
          display={{ base: 'block', md: 'flex' }}
          position={{ base: 'unset', md: 'absolute' }}
          top={{ base: 'unset', md: '50%' }}
          left={{ base: 'unset', md: '50%' }}
          transform={{ base: 'unset', md: 'translate(-50%, -50%)' }}
          justifyContent="center"
          flexWrap="nowrap"
          alignItems="center"
          width={{ md: '600px' }}
        >
          <Text>Closing Soon: Turning the Smartphone into the EarnPhone</Text>
          <Img
            src={modeMobileLogo}
            srcSet={`${modeMobileLogo} 1x, ${modeMobileLogo2x} 2x`}
            display={{ base: 'none', md: 'inline-block' }}
            w="40px"
            h="40px"
            border="1px solid"
            borderColor="grey.50"
            borderRadius="base"
            mx="2"
          />
          <Text display={{ base: 'inline', md: 'none' }}>&nbsp;with&nbsp;</Text>
          <Text as="span" fontWeight="bold">
            Mode Mobile
          </Text>
        </Box>

        <Button
          backgroundColor="transparent"
          as="span"
          color="white"
          w={{ base: 'full', md: 'initial' }}
          mt={{ base: 3, md: 0 }}
          _hover={{
            backgroundColor: 'rgba(255,255,255, 0.08)',
          }}
          px={{ md: '6' }}
          py={{ md: '10px' }}
          height="auto"
          borderRadius="lg"
          fontSize={{ base: '14px', md: 'lg' }}
          fontWeight={{ base: 'medium', md: 'bold' }}
          border="1px solid"
          borderColor="grey.200"
          lineHeight={{ base: 'md', md: 'lg' }}
          boxShadow="none"
        >
          View deal
        </Button>
      </Box>
    </Box>
  );
}
