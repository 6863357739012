import { createIcon } from '@chakra-ui/react';

export const ChevronUpIcon = createIcon({
  displayName: 'ChevronUpIcon',
  viewBox: '0 0 18 18',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <path
      d="M15.5 12L9 5.5L2.5 12"
      stroke="#959595"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});
