/*
 * CODE GENERATED AUTOMATICALLY WITH GraphQL Codegen
 * THIS FILE SHOULD NOT BE EDITED MANUALLY.
 */
import * as Types from '@r-client/data/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type IGdprQueryVariables = Types.Exact<{ [key: string]: never }>;

export type IGdprQuery = { __typename?: 'Query'; gdprZone: boolean };

export type ITickerInvestmentsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type ITickerInvestmentsQuery = {
  __typename?: 'Query';
  tickerInvestments?:
    | {
        __typename?: 'InvestmentTickerConnection';
        nodes: Array<{
          __typename?: 'InvestmentTicker';
          id: string;
          amountCents?: number | null | undefined;
          amountPublic: boolean;
          investorFirstName?: string | null | undefined;
          investorLastName?: string | null | undefined;
          avatarUrl?: string | null | undefined;
          createdAt?: string | null | undefined;
          companyName: string;
          offeringSlug: string;
          userSlug: string;
          userVisibilityPublic: boolean;
          avatarUrl2x?: string | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type IOfferingCardDetailsFragment = {
  __typename?: 'Offering';
  id: string;
  slug: string;
  airdrop: boolean;
  companyName: string;
  following: boolean;
  description?: string | null | undefined;
  amountRaised?: string | null | undefined;
  externalInvestmentsAmountCents?: number | null | undefined;
  externalInvestmentsAmountTooltip?: string | null | undefined;
  amountRaisedWithExternalCents?: number | null | undefined;
  cardTooltipText?: string | null | undefined;
  cardImageUrl?: string | null | undefined;
  badges: Array<Types.IOfferingBadgeNameEnum>;
  spotlight?: boolean | null | undefined;
  minInvestmentAmountCents?: number | null | undefined;
  regulationForDisplay: string;
  cardComplianceText?: string | null | undefined;
  hostingEntityForDisplay?: string | null | undefined;
  calculatedMinInvestmentAmount?: string | null | undefined;
  logoUrl?: string | null | undefined;
  state?: Types.IOfferingStateEnum | null | undefined;
  timeRemainingValueForDisplay?: number | null | undefined;
  timeRemainingUnitForDisplay?: string | null | undefined;
  hasClosed: boolean;
  reviewsCount: number;
  testingTheWaters: boolean;
  testingTheWatersFirstDaysOff: boolean;
  amountRaisedCents?: number | null | undefined;
  maxGoalReached: boolean;
  investorsCount?: number | null | undefined;
  crypto: boolean;
  verticalTag?: string | null | undefined;
  trending: boolean;
  hostingEntity?: string | null | undefined;
  logoUrl2x?: string | null | undefined;
  tags?:
    | Array<{
        __typename?: 'TagsTag';
        id: string;
        colorHex?: string | null | undefined;
        displayName: string;
        slug: string;
        impact: boolean;
        vertical: boolean;
        group: boolean;
        topLevelSectorTag?:
          | {
              __typename?: 'TagsTag';
              colorHex?: string | null | undefined;
              displayName: string;
              description?: string | null | undefined;
              vertical: boolean;
              group: boolean;
            }
          | null
          | undefined;
      }>
    | null
    | undefined;
  address: {
    __typename?: 'Address';
    id: string;
    state?: string | null | undefined;
    city?: string | null | undefined;
  };
  flexibleDealTerms?:
    | Array<{
        __typename?: 'OfferingTermTierDealTerm';
        title: string;
        value: string;
        nextTierValue?: string | null | undefined;
        showOnTombstone?: boolean | null | undefined;
        type: Types.IOfferingTermTierDealTermTypeEnum;
        xValueForDealCard?: string | null | undefined;
        id: string;
      }>
    | null
    | undefined;
  xSecurity?:
    | {
        __typename?: 'OfferingSecurity';
        xValuationCapCents?: number | null | undefined;
        xValuationCents?: number | null | undefined;
      }
    | null
    | undefined;
  timeToInvestBadgeParams?:
    | { __typename?: 'OfferingBadgeTimeToInvest'; value: number; unit: string }
    | null
    | undefined;
  investmentBadge?:
    | {
        __typename?: 'InvestmentBadge';
        id: string;
        description?: string | null | undefined;
        state?: string | null | undefined;
        amountCents?: number | null | undefined;
      }
    | null
    | undefined;
};

export type IGetUsOfferingsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  scopeFilter?: Types.InputMaybe<Types.IOfferingScopeFilterInput>;
  baseScopeFilters?: Types.InputMaybe<
    Array<Types.IOfferingBaseScopeEnum> | Types.IOfferingBaseScopeEnum
  >;
}>;

export type IGetUsOfferingsQuery = {
  __typename?: 'Query';
  offerings: {
    __typename?: 'OfferingConnection';
    edges: Array<{
      __typename?: 'OfferingEdge';
      node: {
        __typename?: 'Offering';
        id: string;
        slug: string;
        airdrop: boolean;
        companyName: string;
        following: boolean;
        description?: string | null | undefined;
        amountRaised?: string | null | undefined;
        externalInvestmentsAmountCents?: number | null | undefined;
        externalInvestmentsAmountTooltip?: string | null | undefined;
        amountRaisedWithExternalCents?: number | null | undefined;
        cardTooltipText?: string | null | undefined;
        cardImageUrl?: string | null | undefined;
        badges: Array<Types.IOfferingBadgeNameEnum>;
        spotlight?: boolean | null | undefined;
        minInvestmentAmountCents?: number | null | undefined;
        regulationForDisplay: string;
        cardComplianceText?: string | null | undefined;
        hostingEntityForDisplay?: string | null | undefined;
        calculatedMinInvestmentAmount?: string | null | undefined;
        logoUrl?: string | null | undefined;
        state?: Types.IOfferingStateEnum | null | undefined;
        timeRemainingValueForDisplay?: number | null | undefined;
        timeRemainingUnitForDisplay?: string | null | undefined;
        hasClosed: boolean;
        reviewsCount: number;
        testingTheWaters: boolean;
        testingTheWatersFirstDaysOff: boolean;
        amountRaisedCents?: number | null | undefined;
        maxGoalReached: boolean;
        investorsCount?: number | null | undefined;
        crypto: boolean;
        verticalTag?: string | null | undefined;
        trending: boolean;
        hostingEntity?: string | null | undefined;
        logoUrl2x?: string | null | undefined;
        tags?:
          | Array<{
              __typename?: 'TagsTag';
              id: string;
              colorHex?: string | null | undefined;
              displayName: string;
              slug: string;
              impact: boolean;
              vertical: boolean;
              group: boolean;
              topLevelSectorTag?:
                | {
                    __typename?: 'TagsTag';
                    colorHex?: string | null | undefined;
                    displayName: string;
                    description?: string | null | undefined;
                    vertical: boolean;
                    group: boolean;
                  }
                | null
                | undefined;
            }>
          | null
          | undefined;
        address: {
          __typename?: 'Address';
          id: string;
          state?: string | null | undefined;
          city?: string | null | undefined;
        };
        flexibleDealTerms?:
          | Array<{
              __typename?: 'OfferingTermTierDealTerm';
              title: string;
              value: string;
              nextTierValue?: string | null | undefined;
              showOnTombstone?: boolean | null | undefined;
              type: Types.IOfferingTermTierDealTermTypeEnum;
              xValueForDealCard?: string | null | undefined;
              id: string;
            }>
          | null
          | undefined;
        xSecurity?:
          | {
              __typename?: 'OfferingSecurity';
              xValuationCapCents?: number | null | undefined;
              xValuationCents?: number | null | undefined;
            }
          | null
          | undefined;
        timeToInvestBadgeParams?:
          | {
              __typename?: 'OfferingBadgeTimeToInvest';
              value: number;
              unit: string;
            }
          | null
          | undefined;
        investmentBadge?:
          | {
              __typename?: 'InvestmentBadge';
              id: string;
              description?: string | null | undefined;
              state?: string | null | undefined;
              amountCents?: number | null | undefined;
            }
          | null
          | undefined;
      };
    }>;
    pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean };
  };
};

export const OfferingCardDetailsFragmentDoc = gql`
  fragment OfferingCardDetails on Offering {
    id
    slug
    airdrop
    companyName
    following
    description
    amountRaised
    externalInvestmentsAmountCents
    externalInvestmentsAmountTooltip
    amountRaisedWithExternalCents
    cardTooltipText
    cardImageUrl
    amountRaised
    badges
    spotlight
    minInvestmentAmountCents
    regulationForDisplay
    cardComplianceText
    hostingEntityForDisplay
    calculatedMinInvestmentAmount
    logoUrl(resize: { width: 60, height: 60, type: fit })
    logoUrl2x: logoUrl(resize: { width: 120, height: 120, type: fit })
    tags {
      id
      colorHex
      displayName
      slug
      topLevelSectorTag {
        colorHex
        displayName
        description
        vertical
        group
      }
      impact
      vertical
      group
    }
    address {
      id
      state
      city
    }
    state
    flexibleDealTerms {
      title
      value
      nextTierValue
      showOnTombstone
      type
      xValueForDealCard
      id
    }
    xSecurity {
      xValuationCapCents
      xValuationCents
    }
    timeRemainingValueForDisplay
    timeRemainingUnitForDisplay
    timeToInvestBadgeParams {
      value
      unit
    }
    hasClosed
    reviewsCount
    testingTheWaters
    testingTheWatersFirstDaysOff
    amountRaisedCents
    maxGoalReached
    investorsCount
    crypto
    verticalTag
    trending
    investmentBadge {
      id
      description
      state
      amountCents
    }
    hostingEntity
  }
`;
export const GdprDocument = gql`
  query Gdpr {
    gdprZone
  }
`;
export type GdprQueryResult = Apollo.QueryResult<
  IGdprQuery,
  IGdprQueryVariables
>;
export const TickerInvestmentsDocument = gql`
  query TickerInvestments($first: Int) {
    tickerInvestments(first: $first) {
      nodes {
        id
        amountCents
        amountPublic
        investorFirstName
        investorLastName
        avatarUrl(resize: { width: 36, height: 36, type: fill })
        avatarUrl2x: avatarUrl(resize: { width: 72, height: 72, type: fill })
        createdAt
        companyName
        offeringSlug
        userSlug
        userVisibilityPublic
      }
    }
  }
`;
export type TickerInvestmentsQueryResult = Apollo.QueryResult<
  ITickerInvestmentsQuery,
  ITickerInvestmentsQueryVariables
>;
export const GetUsOfferingsDocument = gql`
  query GetUSOfferings(
    $first: Int
    $scopeFilter: OfferingScopeFilterInput
    $baseScopeFilters: [OfferingBaseScopeEnum!]
  ) {
    offerings(
      scopeFilter: $scopeFilter
      first: $first
      baseScopeFilters: $baseScopeFilters
    ) {
      edges {
        node {
          ...OfferingCardDetails
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
  ${OfferingCardDetailsFragmentDoc}
`;
export type GetUsOfferingsQueryResult = Apollo.QueryResult<
  IGetUsOfferingsQuery,
  IGetUsOfferingsQueryVariables
>;
