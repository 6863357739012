import { ComponentStyleConfig } from '@chakra-ui/react';

export const FormLabel: ComponentStyleConfig = {
  variants: {
    republic: {
      fontSize: 'md',
      fontWeight: 'semibold',
      lineHeight: 'md',
      letterSpacing: 'less-tight',
      color: 'grey.800',
    },
  },
};
