import { Box, Button, Flex, Hide, Image, Show, Text } from '@chakra-ui/react';
import { useRouter } from 'next/router';

import { useAnalytics } from '@r-client/shared/data/analytics';

import walletDesktopBackground from '../../assets/wallet-desktop-background.png';
import walletLogo from '../../assets/wallet-logo.svg';
import walletMobileBackground from '../../assets/wallet-mobile-background.png';
import walletTabletBackground from '../../assets/wallet-tablet-background.png';

export const WalletLaunchBanner = () => {
  const router = useRouter();
  const analytics = useAnalytics();
  const onClick = () => {
    analytics.track({
      name: 'banner_clicked',
      params: {
        banner_type: 'wallet',
        cta_type: 'wallet_lander',
        platform: 'web',
      },
    });
    router.push('/wallet');
  };

  return (
    <Box
      backgroundColor="brand.primary"
      color="white"
      height={{ base: '100px', sm: '67px' }}
      position="relative"
    >
      <Box height="100%" position="relative" zIndex="1">
        <Show above="sm">
          <Flex alignItems="center" height="100%" px={{ base: '4', sm: '8' }}>
            <Flex
              alignItems="center"
              flexGrow="0"
              justify={{ base: 'flex-start', md: 'center' }}
              w="100%"
              h="100%"
            >
              <Text textAlign="right" size="republic-body-lg" fontWeight={700}>
                Republic Wallet
              </Text>
              <Image
                alt="Republic Wallet logo"
                px="10"
                alignSelf="end"
                src={walletLogo}
              />
              <Text size="republic-body-md" fontWeight={500}>
                Unlock the future of private investing
              </Text>
            </Flex>
            <Box>
              <Button
                background="none"
                color="white"
                px="8"
                py="10px"
                _hover={{ color: 'white' }}
                onClick={onClick}
              >
                Get your key
              </Button>
            </Box>
          </Flex>
        </Show>
        <Hide above="sm">
          <Box px="4" py="3">
            <Flex alignItems="center" justifyContent="center" mb={2}>
              <Image
                alt="Republic Wallet logo"
                mr="2"
                mb="2"
                w="35px"
                h="35px"
                src={walletLogo}
              />
              <Text size="republic-body-lg" fontWeight={700}>
                Republic Wallet
              </Text>
            </Flex>
            <Button
              background="none"
              color="white"
              py="0"
              w="100%"
              h="30px"
              _hover={{ color: 'white' }}
              onClick={onClick}
            >
              <Text size="republic-body-sm" fontWeight={700}>
                Create your wallet and get your key
              </Text>
            </Button>
          </Box>
        </Hide>
      </Box>
      <Box
        backgroundImage={{
          base: walletMobileBackground,
          sm: walletTabletBackground,
          md: walletDesktopBackground,
        }}
        backgroundSize="cover"
        w="100%"
        h="100%"
        left="0"
        position="absolute"
        top="0"
        zIndex="0"
        mixBlendMode="multiply"
      ></Box>
    </Box>
  );
};
