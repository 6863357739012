import {
  Box,
  Button,
  Img,
  Text,
  useMediaQuery,
  useTheme,
} from '@chakra-ui/react';

import { useAnalytics } from '@r-client/shared/data/analytics';

import leftStripesBig from './assets/stripes-left.png';
import leftStripesMobile from './assets/stripes-left-mobile.png';
import rightStripesBig from './assets/stripes-right.png';
import rightStripesMobile from './assets/stripes-right-mobile.png';
import watfordLogo from './assets/watford-logo.png';
import watfordLogo2x from './assets/watford-logo@2x.png';

export function WatfordBanner() {
  const theme = useTheme();
  const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, {
    ssr: false,
  });
  const analytics = useAnalytics();

  const onBannerClick = () => {
    analytics.track({
      name: 'promo_flash_banner_clicked',
      params: {
        label: 'watford_banner',
      },
    });
  };

  return (
    <Box
      as="a"
      aria-label="Watford: Invest now"
      position="relative"
      background="black"
      color="white"
      display="block"
      href="/watford"
      overflow="hidden"
      onClick={onBannerClick}
    >
      {isMobile ? (
        <>
          <Img
            alt="Stripe left"
            position="absolute"
            left="0"
            top="0"
            h="100%"
            src={leftStripesMobile}
          />
          <Img
            alt="Stripe right"
            position="absolute"
            right={{ base: '-35px', xs: '0' }}
            top="0"
            h="100%"
            src={rightStripesMobile}
          />
        </>
      ) : (
        <>
          <Img
            alt="Stripe left"
            position="absolute"
            left={{ sm: '-138px', md: '-50px', lg: '0' }}
            top="0"
            height="100%"
            src={leftStripesBig}
          />
          <Img
            alt="Stripe right"
            position="absolute"
            right={{ sm: '-248px', md: '8' }}
            top="0"
            height="100%"
            src={rightStripesBig}
          />
        </>
      )}

      <Box
        px={{ base: '2', md: '5' }}
        pt={{ base: '2', md: '2.5' }}
        pb={{ base: '4', md: '2.5' }}
        position="relative"
        textAlign={{ base: 'center', md: 'right' }}
      >
        <Box
          display={{ base: 'block', md: 'flex' }}
          position={{ base: 'unset', md: 'absolute' }}
          top={{ base: 'unset', md: '50%' }}
          left={{ base: 'unset', md: '160px', lg: '50%' }}
          w={{ md: '510px' }}
          transform={{
            base: 'unset',
            md: 'translate(0, -50%)',
            lg: 'translate(-50%, -50%)',
          }}
          justifyContent="center"
          flexWrap="nowrap"
          alignItems="center"
          textStyle="md"
        >
          <Text display={{ base: 'inline-block', xs: 'inline' }}>
            Own a team one step from the Premier League
          </Text>
          <Img
            alt="Watford logo"
            src={watfordLogo}
            srcSet={`${watfordLogo} 1x, ${watfordLogo2x} 2x`}
            display={{ base: 'none', md: 'inline-block' }}
            w="40px"
            h="40px"
            border="1px solid"
            borderColor="grey.50"
            borderRadius="base"
            mx="2"
          />
          <Text as="span" display={{ md: 'none' }}>
            &nbsp;with&nbsp;
          </Text>
          <Text
            as="span"
            display={{ base: 'none', md: 'inline' }}
            fontWeight="bold"
          >
            Watford FC
          </Text>
          <Text
            display={{ base: 'inline-block', sm: 'inline', md: 'none' }}
            fontWeight="bold"
          >
            Watford Football Club
          </Text>
        </Box>

        <Box px={{ base: '2', md: '0' }}>
          <Button
            backgroundColor="black"
            as="span"
            color="white"
            w={{ base: 'full', md: 'initial' }}
            mt={{ base: 2, md: 0 }}
            _hover={{
              color: 'inherit',
              backgroundColor: 'grey.900',
            }}
            px="6"
            py={{ base: '1', md: '2.5' }}
            height="auto"
            borderRadius="lg"
            fontSize={{ base: '14px', md: 'lg' }}
            fontWeight={{ base: 'medium', md: 'bold' }}
            border="1px solid"
            borderColor={{ base: '#FBEE23', md: 'grey.200' }}
            lineHeight={{ base: 'md', md: 'lg' }}
            boxShadow="none"
          >
            Learn more
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
