import { createContext, ReactNode, useCallback, useState } from 'react';

import { useDisclosure } from '@chakra-ui/react';
import dynamic from 'next/dynamic';

import { TDisplayMode } from '@r-client/shared/feature/digital-assets-core';
import { TDigitalAsset } from '@r-client/shared/feature/wallet';

export interface ITransferModalContext {
  onOpenModal: (asset: TDigitalAsset, displayMode?: 'modal' | 'drawer') => void;
  onCloseModal: VoidFunction;
}

const TransferProviderContentLazy = dynamic(
  () =>
    import('./transfer-modal-content.dynamic').then(
      (m) => m.TransferModalProviderContent
    ),
  {
    ssr: false,
  }
);

export const TransferModalContext = createContext({} as ITransferModalContext);

export interface ITransferProviderProps {
  children: ReactNode;
  region?: string;
}

export function TransferModalProvider({
  children,
  region,
}: ITransferProviderProps) {
  const [shouldBeLoaded, setShouldBeLoaded] = useState(false);
  const { isOpen, onOpen, onClose: onCloseModal } = useDisclosure();
  const [mode, setMode] = useState<TDisplayMode>('drawer');
  const [asset, setAsset] = useState<TDigitalAsset>();

  const onOpenModal = useCallback(
    (
      assetToTransfer: TDigitalAsset,
      modeToDisplay: TDisplayMode = 'drawer'
    ) => {
      setMode(modeToDisplay);
      setAsset(assetToTransfer);
      setShouldBeLoaded(true);
      onOpen();
    },
    [onOpen]
  );

  return (
    <>
      <TransferModalContext.Provider
        value={{
          onOpenModal,
          onCloseModal,
        }}
      >
        {children}
      </TransferModalContext.Provider>
      {shouldBeLoaded && (
        <TransferProviderContentLazy
          asset={asset}
          onClose={onCloseModal}
          isOpen={isOpen}
          mode={mode}
          region={region}
        />
      )}
    </>
  );
}
