import React from 'react';

import {
  ErrorBoundary as ErrorBoundaryBase,
  IErrorBoundaryProps as IErrorBoundaryBaseProps,
} from '@r-client/shared/data/error-reporting';

import { ErrorMessage } from '../error-message/error-message';

export interface IErrorBoundaryProps extends IErrorBoundaryBaseProps {
  className?: string;
}

export const ErrorBoundary = ({
  className,
  children,
  fallbackUI = ErrorMessage,
  callback,
}: IErrorBoundaryProps) => {
  return (
    <div className={className}>
      <ErrorBoundaryBase callback={callback} fallbackUI={fallbackUI}>
        {children}
      </ErrorBoundaryBase>
    </div>
  );
};
