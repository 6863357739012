import { IFlipperEnum } from '@r-client/data/graphql-types';
import { useGetFeatureToggleQuery } from '@r-client/shared/data/flags/remote';

export const useWalletCtaFeatureFlag = () => {
  const {
    data: walletCtaFeatureFlagData,
    isLoading: isWalletCtaFeatureFlagDataLoading,
  } = useGetFeatureToggleQuery({
    variables: { feature: IFlipperEnum.RepublicWalletCta },
  });

  return (
    !isWalletCtaFeatureFlagDataLoading &&
    walletCtaFeatureFlagData?.flipper.enabled
  );
};
