import { createContext, useContext, useEffect, useState } from 'react';

import { getCookieParser } from 'next/dist/server/api-utils';
import { NextComponentType, NextPageContext } from 'next/types';

import { getCookie } from '@r-client/shared/util/core';

import {
  REGION_VALID_IDS,
  TRegionValidIds,
} from '../../utils/regional-switcher';

export interface IRegionContext {
  region: TRegionValidIds;
  setRegion: (id: TRegionValidIds) => void;
}

export const RegionContext = createContext<IRegionContext | null>(null);

export const useRegionContext = (): IRegionContext => {
  const context = useContext(RegionContext);
  if (context === null) {
    throw new Error(
      'useRegionContext must be used within a RegionContextProvider'
    );
  }
  return context;
};

export interface IRegionContextProviderProps {
  children: React.ReactNode;
  initialRegion: TRegionValidIds;
}

export const RegionContextProvider = ({
  children,
  initialRegion,
}: IRegionContextProviderProps) => {
  const [isClient, setIsClient] = useState(false);
  const [currentRegion, setCurrentRegion] =
    useState<TRegionValidIds>(initialRegion);
  const context = useContext(RegionContext);

  useEffect(() => {
    if (context) {
      return;
    }
    return setIsClient(true);
  }, []);

  useEffect(() => {
    if (context) {
      return;
    }
    if (!isClient) {
      return setCurrentRegion(initialRegion);
    }

    const value = getCookie('region') as TRegionValidIds | undefined;
    if (value && REGION_VALID_IDS.includes(value)) {
      return setCurrentRegion(value);
    }

    return setCurrentRegion('global');
  }, [isClient]);

  return (
    <RegionContext.Provider
      value={
        context
          ? context
          : { region: currentRegion, setRegion: setCurrentRegion }
      }
    >
      {children}
    </RegionContext.Provider>
  );
};

type WithRegionContextProps = {
  initialRegion?: TRegionValidIds;
};

export const withRegionContext = <TProps extends Record<string, unknown>>(
  Component: NextComponentType<NextPageContext, any, TProps>
) => {
  const WithRegionContextComponent = (
    props: TProps & WithRegionContextProps
  ) => {
    return (
      <RegionContextProvider initialRegion={props.initialRegion!}>
        <Component {...props} />
      </RegionContextProvider>
    );
  };

  WithRegionContextComponent.getInitialProps = async (ctx: NextPageContext) => {
    let componentProps = {};
    if (Component.getInitialProps) {
      componentProps = await Component.getInitialProps(ctx);
    }

    let initialRegion: TRegionValidIds = 'global';
    if (ctx.req) {
      const cookies = getCookieParser(ctx.req.headers)();
      const value = cookies.region as TRegionValidIds | undefined;
      if (value && REGION_VALID_IDS.includes(value)) {
        initialRegion = value;
      }
    }

    return {
      ...componentProps,
      initialRegion,
    };
  };

  return WithRegionContextComponent;
};
