/* eslint-disable max-lines */
// @TODO: refactor the component with Chakra UI and new design system https://linear.app/republic/issue/ATOM-2164/refactor-offeringcard-component
import { forwardRef } from 'react';

import CN from 'clsx';

import { ITagsTag } from '@r-client/data/graphql-types';
import { Box, Button, Img, Tooltip } from '@r-client/shared/ui/core';
import { InfoIcon } from '@r-client/shared/ui/icons';
import { formatDollars, getCN } from '@r-client/shared/util/core';

import { E_OFFERING_INVESTMENT_BADGE_NAME } from '../../types';
import { Follow } from '../follow';
import {
  IOfferingBadgeProps,
  OfferingCardBadgeWrapper,
} from './offering-card-badge';
import { OfferingCardContent } from './offering-card-content';
import { OfferingCardFooter } from './offering-card-footer';
import { IOfferingTerms, OfferingCardTerms } from './offering-card-terms';

import styles from './offering-card.module.scss';

export interface IOfferingCardProps {
  badges?: IOfferingBadgeProps['badge'][];
  className?: string | { main?: string; follow?: string };
  companyName?: string;
  cover?: string;
  description?: string;
  slug?: string;
  id?: number;
  indexInAllOfferings?: number;
  isNote?: boolean;
  following?: boolean;
  learnMore?: string;
  location?: string;
  logoUrl?: string;
  logoUrl2x?: string;
  regulationForDisplay?: string;
  hostingEntityForDisplay?: string;
  cardComplianceText?: string;
  noteCapCents?: number;
  numOfAllOfferings?: number;
  tags?: Pick<ITagsTag, 'colorHex' | 'displayName'>[] | null;
  terms?: IOfferingTerms[] | null;
  tooltipText?: string;
  type?: 'default' | 'small';
  target?: '_blank';
  leftToInvestValue?: number;
  leftToInvestUnit?: string;
  investmentBadge?: {
    description: E_OFFERING_INVESTMENT_BADGE_NAME;
    amount: number;
  };
  spotlight?: boolean;
  onClick?: VoidFunction;
}

export enum E_TEST_IDS {
  OfferingCard = 'offeringCard',
}

export const OfferingCard = forwardRef<HTMLDivElement, IOfferingCardProps>(
  (
    {
      badges,
      className,
      companyName,
      cover,
      description,
      slug,
      indexInAllOfferings,
      isNote,
      learnMore,
      location,
      logoUrl,
      logoUrl2x,
      noteCapCents,
      numOfAllOfferings,
      tags,
      terms,
      tooltipText,
      type = 'default',
      target,
      cardComplianceText,
      following,
      leftToInvestValue,
      leftToInvestUnit,
      investmentBadge,
      spotlight,
      onClick,
    },
    ref
  ) => {
    const isSmallCard = type === 'small';

    const handleCardClicked = () => {
      onClick?.();
    };

    return (
      <div
        className={CN(
          styles.card,
          {
            [styles.smallCard]: isSmallCard,
          },
          getCN(className)
        )}
        itemScope
        itemType="http://schema.org/ItemList"
      >
        <a
          target={target}
          href={`/${slug}`}
          itemScope
          itemType="https://schema.org/Organization"
          itemProp="itemListElement"
          data-testid={E_TEST_IDS.OfferingCard}
          className={CN(styles.link, terms && styles.linkAnimation, {
            [styles.smallCardLink]: isSmallCard,
          })}
          onClick={handleCardClicked}
        >
          <figure
            className={CN(styles.figure, { [styles.smallFigure]: isSmallCard })}
          >
            {!!badges && (
              <OfferingCardBadgeWrapper
                badges={badges}
                learnMore={learnMore}
                isSmallCard={isSmallCard}
                leftToInvestValue={leftToInvestValue}
                leftToInvestUnit={leftToInvestUnit}
                investmentBadge={investmentBadge}
              />
            )}
            <Img
              src={cover}
              src2x={cover}
              alt={companyName}
              fit="cover"
              className={CN(styles.cover, {
                [styles.smallCover]: isSmallCard,
              })}
            />
          </figure>

          <div
            className={CN(styles.content, {
              [styles.smallCardContent]: isSmallCard,
              [styles.smallNoteCardContent]: isNote,
            })}
            ref={ref}
          >
            <OfferingCardContent
              className={CN(styles.cardHeader, {
                [styles.smallCardHeader]: isSmallCard,
              })}
              regulationClassName={styles.regulationEntityText}
              logoUrl={isNote ? undefined : logoUrl}
              logoUrl2x={isNote ? undefined : logoUrl2x}
              description={description}
              indexInAllOfferings={indexInAllOfferings}
              numOfAllOfferings={numOfAllOfferings}
              companyName={companyName}
              regulationEntityText={cardComplianceText}
              isSmallCard={isSmallCard}
              spotlight={spotlight}
            />
            {tooltipText && !isSmallCard && (
              <Box className={styles.tooltip}>
                <Tooltip content={tooltipText ?? ''}>
                  <InfoIcon />
                </Tooltip>
              </Box>
            )}
            {isNote && !isSmallCard ? (
              <>
                <ul
                  className={CN(styles.noteTerms, {
                    [styles.smallCardNoteTerms]: isSmallCard,
                  })}
                >
                  <li>
                    <span className={styles.termName}>Cap </span>
                    <strong>{formatDollars(noteCapCents)}</strong>
                  </li>
                  <li>
                    <span className={styles.termName}>Min. investment </span>
                    <strong>$100</strong>
                  </li>
                </ul>
                <div className={styles.noteFooter}>
                  <p className={styles.secondaryDescription}>
                    A new kind of asset that shares profits when startups and
                    private equities that raise with Republic later sell or go
                    public.
                  </p>
                  <Button className={styles.button} href="/note" type="primary">
                    Learn more
                  </Button>
                </div>
              </>
            ) : (
              <>
                {!!tags && !isSmallCard && (
                  <OfferingCardFooter
                    className={CN(styles.footer, styles.smallCardFooter)}
                    tags={tags}
                    location={!isSmallCard ? location : ''}
                    isSmallCard={isSmallCard}
                    regulationClassName={styles.regulationEntityText}
                    regulationEntityText={cardComplianceText}
                  />
                )}
                {!!terms && (
                  <OfferingCardTerms
                    className={CN(styles.terms, {
                      [styles.smallCardTerms]: isSmallCard,
                    })}
                    terms={terms}
                    isSmallCard={isSmallCard}
                  />
                )}
              </>
            )}
          </div>
          <Follow
            className={CN(styles.follow, getCN(className, 'follow'), {
              [styles.isSmallCard]: isSmallCard,
            })}
            following={following}
            companyName={companyName}
            isSmallCard={isSmallCard}
            offeringSlug={slug || ''}
          />
        </a>
      </div>
    );
  }
);
