import React from 'react';

import { observer } from 'mobx-react-lite';

import { useAnalytics } from '@r-client/shared/data/analytics';
import { useAuth } from '@r-client/shared/feature/auth';
import { useHasWallet } from '@r-client/shared/feature/wallet';
import { Box, Divider, Link } from '@r-client/shared/ui/core';
import { Flex } from '@r-client/shared/ui/flex';
import { SvgComponent, SvgIconLight } from '@r-client/shared/ui/icons';
import { BasicMenu, BasicMenuItem } from '@r-client/shared/ui/menu';

import { EditableEntities } from '../../editable-entities/editable-entities';
import { userNavItems } from '../../navigation-data';
import { TEditableEntities } from '../../types';
import {
  getUserTeasers,
  teasersToEditableEntities,
} from '../../utilities/teasers-to-editable-entities';
import { useWalletCtaFeatureFlag } from '../../utilities/use-wallet-cta-feature-flag';

import styles from '../mobile-user-navigation.module.scss';

export interface IUserMobileNavRowContentProps {
  userSlug: string;
  editableOfferings?: TEditableEntities;
  onNotificationsClick?: VoidFunction;
  notificationsCount: number;
  messagesCount: number;
}

function ListItemRightSide({
  notificationsCount,
  messagesCount,
  title,
  icon,
}: {
  notificationsCount: number;
  messagesCount: number;
  title: string;
  icon: SvgComponent;
}) {
  switch (true) {
    case title === 'Notifications' && !!notificationsCount:
      return (
        <Box className={styles.notificationsCount}>{notificationsCount}</Box>
      );
    case title === 'Messages' && !!messagesCount:
      return <Box className={styles.notificationsCount}>{messagesCount}</Box>;
    default:
      return <SvgIconLight icon={icon} width={22} height={22} />;
  }
}

export const UserMobileNavRowContent = observer(
  function UserMobileNavRowContent({
    userSlug,
    editableOfferings,
    onNotificationsClick,
    notificationsCount,
    messagesCount,
  }: IUserMobileNavRowContentProps) {
    const { viewer, signOut } = useAuth();
    const teasers = teasersToEditableEntities(getUserTeasers(viewer));
    const republicWalletCta = useWalletCtaFeatureFlag();
    const { hasWallet } = useHasWallet();
    const analytics = useAnalytics();

    const navItems = userNavItems({
      userSlug,
      mobile: true,
      republicWalletCta,
      hasWallet,
    });
    const handleLogout = (
      e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
      e.preventDefault();
      signOut({ redirectTo: '/logout' });
    };

    const handleItemClick = (title: string) => {
      switch (true) {
        case title === 'Notifications':
          return onNotificationsClick?.();
        default:
          return undefined;
      }
    };

    const trackNavigation = (
      title = '',
      trackingParams?: Record<string, string>
    ) => {
      analytics.track({
        name: 'user_menu_clicked',
        params: {
          label: `user_menu_${title.toLowerCase().replace(' ', '_')}`,
          ...trackingParams,
        },
      });
    };

    return (
      <Box p="0.5brm 0" className={styles.userMenu}>
        <BasicMenu>
          <EditableEntities
            offerings={editableOfferings}
            teasers={teasers}
            mobile={true}
          />
          {/* All options minus logout */}
          {navItems
            .filter(Boolean)
            .slice(0, -1)
            .map((item) => (
              <BasicMenuItem key={item?.href} className={styles.itemTitle}>
                {item?.icon ? (
                  <Link
                    onClick={() => {
                      trackNavigation(item.title, item.trackingParams);
                      handleItemClick(item.title);
                    }}
                    href={item.href}
                    variant={Link.variants.unstyled}
                  >
                    <Flex justifyContent="space-between" alignItems="center">
                      {item.title}
                      <ListItemRightSide
                        title={item.title}
                        icon={item.icon}
                        notificationsCount={notificationsCount}
                        messagesCount={messagesCount}
                      />
                    </Flex>
                  </Link>
                ) : (
                  <Link
                    href={item?.href}
                    onClick={() =>
                      trackNavigation(item?.title, item?.trackingParams)
                    }
                    variant={Link.variants.unstyled}
                  >
                    {item?.title}
                  </Link>
                )}
              </BasicMenuItem>
            ))}
        </BasicMenu>
        <Divider margin="0.5" />
        <BasicMenu>
          {/* Logout option */}
          {navItems.slice(-1).map((item) => (
            <BasicMenuItem key={item?.href} className={styles.itemTitle}>
              <Link
                href={item?.href}
                variant={Link.variants.unstyled}
                onClick={handleLogout}
              >
                {item?.title}
              </Link>
            </BasicMenuItem>
          ))}
        </BasicMenu>
      </Box>
    );
  }
);
