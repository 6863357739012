/*
 * CODE GENERATED AUTOMATICALLY WITH GraphQL Codegen
 * THIS FILE SHOULD NOT BE EDITED MANUALLY.
 */
import * as Types from '@r-client/data/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type IGetUserQueryVariables = Types.Exact<{ [key: string]: never }>;

export type IGetUserQuery = {
  __typename?: 'Query';
  user?:
    | {
        __typename?: 'User';
        id: string;
        authId?: string | null | undefined;
        requiresMultiFactorAuthentication?: boolean | null | undefined;
        slug: string;
        email?: string | null | undefined;
        confirmedAt?: string | null | undefined;
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
        fullLegalName?: string | null | undefined;
        avatarUrl?: string | null | undefined;
        verified?: boolean | null | undefined;
        founder: boolean;
        unreadMessagesCount: number;
        unreadNotificationsCount: number;
        lastIosAppSignInAt?: string | null | undefined;
        signInCount: number;
        requestedSecondariesAccess?: boolean | null | undefined;
        secondariesAccessGranted: boolean;
        isAdmin: boolean;
        editableOfferings: Array<{
          __typename?: 'Offering';
          id: string;
          slug: string;
          companyName: string;
          logoUrl?: string | null | undefined;
          logoUrl2x?: string | null | undefined;
        }>;
        secretsData?:
          | {
              __typename?: 'MultiFactorAuthenticationSecret';
              authUri: string;
              secretKey: string;
            }
          | null
          | undefined;
        investorProfile?:
          | {
              __typename?: 'InvestorProfile';
              id: string;
              xSelfAccredited?: boolean | null | undefined;
              xRemainingInvestmentLimitCents?: number | null | undefined;
              hasActiveRepublicSubscription: boolean;
              inxIntegrationAccount?:
                | {
                    __typename?: 'InxIntegrationAccount';
                    id: string;
                    redirectUrl: string;
                    state: Types.IInxIntegrationAccountStateEnum;
                    createdAt: string;
                    inxUserId: string;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        issuerApplications: {
          __typename?: 'IssuerApplicationConnection';
          nodes: Array<{ __typename?: 'IssuerApplication'; id: string }>;
        };
        issuerProfiles: Array<{
          __typename?: 'IssuerProfile';
          id: string;
          companyName?: string | null | undefined;
          onboardingCompleteness: number;
          offerings: Array<{
            __typename?: 'Offering';
            id: string;
            logoUrl?: string | null | undefined;
            hasPublished: boolean;
            indexInAllOfferings: number;
            regulation: Types.IOfferingRegulationEnum;
            slug: string;
          }>;
        }>;
        teaserPages?:
          | {
              __typename?: 'TeaserPageConnection';
              nodes: Array<{
                __typename?: 'TeaserPage';
                id: string;
                slug: string;
                companyName: string;
                backgroundImageUrl?: string | null | undefined;
                logoImageUrl?: string | null | undefined;
                summary: string;
                socialLinksUrls: Array<string>;
                tag?:
                  | { __typename?: 'TagsTag'; slug: string }
                  | null
                  | undefined;
              }>;
            }
          | null
          | undefined;
        accreditedAccess?:
          | {
              __typename?: 'AccreditedAccess';
              state?:
                | Types.IAccreditedAccreditedAccessStateEnum
                | null
                | undefined;
            }
          | null
          | undefined;
        followsOfferings?:
          | Array<{
              __typename?: 'Offering';
              following: boolean;
              tags?:
                | Array<{
                    __typename?: 'TagsTag';
                    following?: boolean | null | undefined;
                  }>
                | null
                | undefined;
            }>
          | null
          | undefined;
        capabilities?:
          | Array<{
              __typename?: 'Capability';
              id: string;
              domain?: Types.ICapabilityDomainEnum | null | undefined;
              level?: Types.ICapabilityLevelEnum | null | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ISignInMutationVariables = Types.Exact<{
  email: Types.Scalars['Email']['input'];
  password: Types.Scalars['String']['input'];
  source?: Types.InputMaybe<Types.IAuthSourceEnum>;
}>;

export type ISignInMutation = {
  __typename?: 'Mutation';
  signIn?:
    | {
        __typename?: 'AuthSignInPayload';
        signInCode?: string | null | undefined;
        authFlags?:
          | { __typename?: 'AuthFlags'; need2fa?: boolean | null | undefined }
          | null
          | undefined;
        authToken?:
          | {
              __typename?: 'AuthToken';
              accessToken: string;
              refreshToken: string;
              expiresAt?: string | null | undefined;
            }
          | null
          | undefined;
        errors?:
          | Array<{
              __typename?: 'ValidationError';
              message: string;
              extensions: { __typename?: 'Extensions'; code: Types.ICodeEnum };
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type IAuthenticateWithTwoFactorMutationVariables = Types.Exact<{
  code: Types.Scalars['String']['input'];
  signInCode: Types.Scalars['String']['input'];
}>;

export type IAuthenticateWithTwoFactorMutation = {
  __typename?: 'Mutation';
  authenticateWithTwoFactor?:
    | {
        __typename?: 'AuthAuthenticateWithTwoFactorPayload';
        authToken?:
          | {
              __typename?: 'AuthToken';
              accessToken: string;
              refreshToken: string;
              expiresAt?: string | null | undefined;
            }
          | null
          | undefined;
        errors?:
          | Array<{
              __typename?: 'ValidationError';
              message: string;
              extensions: { __typename?: 'Extensions'; code: Types.ICodeEnum };
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ISignOutMutationVariables = Types.Exact<{
  accessToken?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type ISignOutMutation = {
  __typename?: 'Mutation';
  UserAuth_signOutUser: {
    __typename?: 'AuthSignOutPayload';
    isLoggedBackInAsAdmin: boolean;
    errors?:
      | Array<{
          __typename?: 'ValidationError';
          message: string;
          extensions: { __typename?: 'Extensions'; code: Types.ICodeEnum };
        }>
      | null
      | undefined;
  };
};

export type IRefreshTokenMutationVariables = Types.Exact<{
  refreshToken: Types.Scalars['String']['input'];
}>;

export type IRefreshTokenMutation = {
  __typename?: 'Mutation';
  Jwt_refreshToken: {
    __typename?: 'AuthRefreshPayload';
    token?:
      | {
          __typename?: 'AuthToken';
          accessToken: string;
          refreshToken: string;
          idToken?: string | null | undefined;
          expiresAt?: string | null | undefined;
        }
      | null
      | undefined;
    errors?:
      | Array<{
          __typename?: 'ValidationError';
          message: string;
          extensions: { __typename?: 'Extensions'; code: Types.ICodeEnum };
        }>
      | null
      | undefined;
  };
};

export type IUserEmailQueryVariables = Types.Exact<{
  slug: Types.Scalars['String']['input'];
}>;

export type IUserEmailQuery = {
  __typename?: 'Query';
  user?:
    | {
        __typename?: 'User';
        id: string;
        email?: string | null | undefined;
        unconfirmedEmail?: string | null | undefined;
      }
    | null
    | undefined;
};

export const GetUserDocument = gql`
  query getUser {
    user {
      id
      authId
      requiresMultiFactorAuthentication
      slug
      email
      confirmedAt
      firstName
      lastName
      fullLegalName
      avatarUrl(resize: { width: 110, height: 110, type: fill })
      verified
      founder
      unreadMessagesCount
      unreadNotificationsCount
      lastIosAppSignInAt
      signInCount
      editableOfferings {
        id
        slug
        companyName
        logoUrl(resize: { width: 35, height: 35, type: fit })
        logoUrl2x: logoUrl(resize: { width: 70, height: 70, type: fit })
      }
      secretsData {
        authUri
        secretKey
      }
      investorProfile {
        id
        xSelfAccredited
        xRemainingInvestmentLimitCents
        hasActiveRepublicSubscription
        inxIntegrationAccount {
          id
          redirectUrl
          state
          createdAt
          inxUserId
        }
      }
      issuerApplications {
        nodes {
          id
        }
      }
      issuerProfiles {
        id
        companyName
        onboardingCompleteness
        offerings {
          id
          logoUrl
          hasPublished
          indexInAllOfferings
          regulation
          slug
        }
      }
      teaserPages(first: 1) {
        nodes {
          id
          slug
          companyName
          backgroundImageUrl
          logoImageUrl
          summary
          socialLinksUrls
          tag {
            slug
          }
        }
      }
      requestedSecondariesAccess
      secondariesAccessGranted
      accreditedAccess {
        state
      }
      followsOfferings {
        following
        tags {
          following
        }
      }
      isAdmin
      capabilities {
        id
        domain
        level
      }
    }
  }
`;
export type GetUserQueryResult = Apollo.QueryResult<
  IGetUserQuery,
  IGetUserQueryVariables
>;
export const SignInDocument = gql`
  mutation signIn($email: Email!, $password: String!, $source: AuthSourceEnum) {
    signIn(input: { email: $email, password: $password, source: $source }) {
      authFlags {
        need2fa
      }
      signInCode
      authToken {
        accessToken
        refreshToken
        expiresAt
      }
      errors {
        message
        extensions {
          code
        }
      }
    }
  }
`;
export type ISignInMutationFn = Apollo.MutationFunction<
  ISignInMutation,
  ISignInMutationVariables
>;
export type SignInMutationResult = Apollo.MutationResult<ISignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<
  ISignInMutation,
  ISignInMutationVariables
>;
export const AuthenticateWithTwoFactorDocument = gql`
  mutation authenticateWithTwoFactor($code: String!, $signInCode: String!) {
    authenticateWithTwoFactor(input: { signInCode: $signInCode, code: $code }) {
      authToken {
        accessToken
        refreshToken
        expiresAt
      }
      errors {
        message
        extensions {
          code
        }
      }
    }
  }
`;
export type IAuthenticateWithTwoFactorMutationFn = Apollo.MutationFunction<
  IAuthenticateWithTwoFactorMutation,
  IAuthenticateWithTwoFactorMutationVariables
>;
export type AuthenticateWithTwoFactorMutationResult =
  Apollo.MutationResult<IAuthenticateWithTwoFactorMutation>;
export type AuthenticateWithTwoFactorMutationOptions =
  Apollo.BaseMutationOptions<
    IAuthenticateWithTwoFactorMutation,
    IAuthenticateWithTwoFactorMutationVariables
  >;
export const SignOutDocument = gql`
  mutation signOut($accessToken: String) {
    UserAuth_signOutUser(accessToken: $accessToken) {
      isLoggedBackInAsAdmin
      errors {
        message
        extensions {
          code
        }
      }
    }
  }
`;
export type ISignOutMutationFn = Apollo.MutationFunction<
  ISignOutMutation,
  ISignOutMutationVariables
>;
export type SignOutMutationResult = Apollo.MutationResult<ISignOutMutation>;
export type SignOutMutationOptions = Apollo.BaseMutationOptions<
  ISignOutMutation,
  ISignOutMutationVariables
>;
export const RefreshTokenDocument = gql`
  mutation refreshToken($refreshToken: String!) {
    Jwt_refreshToken(refreshToken: $refreshToken) {
      token {
        accessToken
        refreshToken
        idToken
        expiresAt
      }
      errors {
        message
        extensions {
          code
        }
      }
    }
  }
`;
export type IRefreshTokenMutationFn = Apollo.MutationFunction<
  IRefreshTokenMutation,
  IRefreshTokenMutationVariables
>;
export type RefreshTokenMutationResult =
  Apollo.MutationResult<IRefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<
  IRefreshTokenMutation,
  IRefreshTokenMutationVariables
>;
export const UserEmailDocument = gql`
  query UserEmail($slug: String!) {
    user(slug: $slug) {
      id
      email
      unconfirmedEmail
    }
  }
`;
export type UserEmailQueryResult = Apollo.QueryResult<
  IUserEmailQuery,
  IUserEmailQueryVariables
>;
