import { IRegionSwitcherSettings } from '@r-client/republic/feature/main-header';

type ISwithingMode = 'cookie-only' | 'navigate';

interface IVariantsOpts {
  onChange: (id: TRegionValidIds) => void;
  mode: ISwithingMode;
  republicEuropeBaseUrl: string;
}

export const regionalSwitcherVariants = ({
  onChange,
  mode,
  republicEuropeBaseUrl,
}: IVariantsOpts): IRegionSwitcherSettings['variants'] => [
  {
    name: 'Global',
    id: 'global',
    inlineName: 'global',
    titleName: 'Global',
    ...(mode === 'cookie-only'
      ? {
          onChange: () => onChange('global'),
        }
      : {
          onChange: () => onChange('global'),
          url: '/',
        }),
  },
  {
    name: 'United States',
    id: 'us',
    inlineName: 'US',
    titleName: 'US',
    onChange: onChange ? () => onChange('us') : undefined,
    needsConfirmation: true,
  },
  {
    name: 'Europe',
    id: 'europe',
    inlineName: 'europe',
    titleName: 'EU',
    needsConfirmation: true,
    onChange: undefined,
    url: republicEuropeBaseUrl,
  },
];

export type TRegionValidIds = 'global' | 'us' | 'europe';
export const REGION_VALID_IDS: TRegionValidIds[] = ['global', 'us', 'europe'];
