import { Box, Button, Img } from '@chakra-ui/react';

import countdownPlaceholder from './assets/countdown-placeholder.svg';
import republicNoteBackgroundLogo from './assets/note-launch-logo.png';
import republicNoteBackgroundLogoX2 from './assets/note-launch-logo@2x.png';
import republicNoteTinyLogo from './assets/republic-note-tiny-logo.svg';

export const NoteLaunchBanner = () => {
  return (
    <Box
      position="relative"
      overflow="hidden"
      backgroundColor="black"
      color="white"
    >
      <Img
        src={republicNoteBackgroundLogo}
        srcSet={`${republicNoteBackgroundLogoX2} 2x`}
        position="absolute"
        left={{ base: '-60px', sm: '-40px', md: '0' }}
        opacity={{ base: '0.3', sm: '0.5', md: '1' }}
        height="100%"
        pointerEvents="none"
      />
      <Box
        as="a"
        href="/note"
        display="block"
        padding="12px 16px"
        textDecoration="none"
        color="inherit"
        transition="background-color 0.2s ease"
        _hover={{
          backgroundColor: 'rgba(255,255,255, 0.08)',
          textDecoration: 'none',
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          flexDirection={{ base: 'column', sm: 'row' }}
        >
          <Box
            display="flex"
            flexWrap={{ base: 'wrap', sm: 'nowrap' }}
            justifyContent={{ base: 'center', sm: 'flex-start', md: 'center' }}
            alignItems="center"
            width={{ base: '100%', sm: '75%', md: '66.6%' }}
            marginLeft={{ base: '0', md: '16.6%' }}
            marginBottom={{ base: '12px', sm: '0' }}
            columnGap="0.6em"
            fontSize={{ base: '14px', sm: '18px', md: '20px' }}
            fontWeight={{ base: '500', sm: '400' }}
            letterSpacing="-0.015em"
          >
            <Img
              src={republicNoteTinyLogo}
              display={{ base: 'none', md: 'block' }}
              width="1em"
              height="0.95em"
            />
            Republic Note goes live in
            <Img src={countdownPlaceholder} width="10.15em" height="1.25em" />
          </Box>
          <Box
            textAlign="right"
            width={{ base: '100%', sm: '25%', md: '16.6%' }}
          >
            <Button
              width={{ base: '100%', sm: 'auto' }}
              height="auto"
              py="9px"
              paddingInlineStart="18px"
              paddingInlineEnd="18px"
              background="none"
              color="white"
              fontSize="14px"
              borderRadius="3px"
              boxShadow="inset 0 0 0 1px rgba(255,255,255, 0.4)"
              _hover={{ boxShadow: 'inset 0 0 0 1px white' }}
            >
              Get access
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
