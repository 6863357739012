import { Flex, Heading, Skeleton, Text } from '@chakra-ui/react';
import Link from 'next/link';

export interface IStatBoxProps {
  title: string;
  description: string;
  url?: string;
  isLoading?: boolean;
}

export const StatBox = ({
  title,
  description,
  url = '',
  isLoading = false,
}: IStatBoxProps) => {
  const content = (
    <Flex
      alignItems={{ base: 'center', md: 'stretch' }}
      justifyContent={{ base: 'center' }}
      direction="column"
      gap="2"
      w="100%"
    >
      <Flex alignItems="top" justifyContent="space-between">
        <Skeleton isLoaded={!isLoading} data-testid="stats-skeleton">
          <Heading size="republic-h4">{isLoading ? '0000' : title}</Heading>
        </Skeleton>
      </Flex>
      <Text
        textStyle="md"
        color="grey.600"
        textAlign={{ base: 'center', md: 'initial' }}
      >
        {description}
      </Text>
    </Flex>
  );

  return url ? <Link href={url}>{content}</Link> : content;
};
