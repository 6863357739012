/*
 * CODE GENERATED AUTOMATICALLY WITH GraphQL Codegen
 * THIS FILE SHOULD NOT BE EDITED MANUALLY.
 */
export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  BigDecimal: { input: number; output: number };
  /**
   * Represents non-fractional signed whole numeric values. Since the value may
   * exceed the size of a 32-bit integer, it's encoded as a string.
   */
  BigInt: { input: number; output: number };
  /** A valid email, transported as a string */
  Email: { input: string; output: string };
  /** An ISO 8601-encoded date */
  ISO8601Date: { input: string; output: string };
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  ISO8601DateTime: { input: string; output: string };
  /** The JSONObject scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: Record<string, unknown>; output: Record<string, unknown> };
  /** A monetary value */
  Money: { input: string; output: string };
  /** A valid phone numer, transported as a string */
  PhoneNumber: { input: string; output: string };
  /** Series like [2020, [0, 0, 0, 20000, 0, 0, 0, 12000, 5000, 0, 0, 0]] */
  SeriesDate: { input: unknown; output: unknown };
  /** A valid SSN or Tax ID transported as String */
  Ssn: { input: string; output: string };
  /** A valid URL, transported as a string */
  Url: { input: string; output: string };
};

/** AccreditationReason object of Comments domain */
export type IAccreditationReason = INode & {
  __typename?: 'AccreditationReason';
  id: Scalars['ID']['output'];
  selfAccreditedReason: IAccreditationSelfAccreditedReasonEnum;
  selfAccreditedReasonOptionalText?: Maybe<Scalars['String']['output']>;
};

/** Input parameters for AccreditationReason */
export type IAccreditationReasonInput = {
  selfAccreditedReason: IAccreditationSelfAccreditedReasonEnum;
  selfAccreditedReasonOptionalText?: InputMaybe<Scalars['String']['input']>;
};

/** List of available AccreditedReasons of Core domain */
export enum IAccreditationSelfAccreditedReasonEnum {
  AnnualIncome = 'ANNUAL_INCOME',
  EntityByStandards = 'ENTITY_BY_STANDARDS',
  EntityOrTrust = 'ENTITY_OR_TRUST',
  Finra = 'FINRA',
  Individual = 'INDIVIDUAL',
  Other = 'OTHER',
}

/** Input parameters for Accreditation claim */
export type IAccreditationVerificationClaimInput = {
  acceptance?: InputMaybe<Scalars['Boolean']['input']>;
  entityType?: InputMaybe<IAnnualIncomeClaimEntityTypeEnum>;
  estimatedNetWorthCents?: InputMaybe<Scalars['BigInt']['input']>;
  proofOfIncomeLastYearCents?: InputMaybe<Scalars['BigInt']['input']>;
  proofOfIncomePreLastYearCents?: InputMaybe<Scalars['BigInt']['input']>;
  type: IAccreditationVerificationClaimTypeEnum;
  verifierEmail?: InputMaybe<Scalars['String']['input']>;
  verifierName?: InputMaybe<Scalars['String']['input']>;
  verifierTitle?: InputMaybe<ILetterClaimVerifierTitleEnum>;
};

/** Enum of possible types of accreditation verification claim */
export enum IAccreditationVerificationClaimTypeEnum {
  AnnualIncomeClaim = 'ANNUAL_INCOME_CLAIM',
  FinraClaim = 'FINRA_CLAIM',
  LetterClaim = 'LETTER_CLAIM',
  NetWorthClaim = 'NET_WORTH_CLAIM',
}

/** Autogenerated input type of AccreditationVerificationCreateDocumentUpload */
export type IAccreditationVerificationCreateDocumentUploadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contentType: Scalars['String']['input'];
  fileSize: Scalars['BigInt']['input'];
  investorEntityCrossTableId: Scalars['String']['input'];
};

/** Autogenerated return type of AccreditationVerificationCreateDocumentUpload. */
export type IAccreditationVerificationCreateDocumentUploadPayload = {
  __typename?: 'AccreditationVerificationCreateDocumentUploadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  presignedFileUpload?: Maybe<IPresignedFileUpload>;
};

/** Autogenerated input type of AccreditationVerificationCreate */
export type IAccreditationVerificationCreateInput = {
  claim?: InputMaybe<IAccreditationVerificationClaimInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  documents: Array<IAccreditationVerificationDocumentInput>;
  ownerId: Scalars['ID']['input'];
  ownerType: IAccreditationVerificationOwnerTypeEnum;
};

/** Autogenerated return type of AccreditationVerificationCreate. */
export type IAccreditationVerificationCreatePayload = {
  __typename?: 'AccreditationVerificationCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Enum of possible additional infos of accreditation verification document */
export enum IAccreditationVerificationDocumentAdditionalInfoEnum {
  LastYear = 'LAST_YEAR',
  PreLastYear = 'PRE_LAST_YEAR',
}

/** Input parameters for Accreditation document */
export type IAccreditationVerificationDocumentInput = {
  additionalInfo?: InputMaybe<IAccreditationVerificationDocumentAdditionalInfoEnum>;
  directUploadAttachmentUuid: Scalars['String']['input'];
  documentType: IAccreditationVerificationDocumentTypeEnum;
};

/** Enum of possible types of accreditation verifiction document */
export enum IAccreditationVerificationDocumentTypeEnum {
  BankStatement = 'BANK_STATEMENT',
  CreditReport = 'CREDIT_REPORT',
  EntityVerification = 'ENTITY_VERIFICATION',
  FinraProof = 'FINRA_PROOF',
  IncomeProof = 'INCOME_PROOF',
  K1Form = 'K1_FORM',
  PayStubs = 'PAY_STUBS',
  TaxReturn = 'TAX_RETURN',
  VerifierLetter = 'VERIFIER_LETTER',
  W2Form = 'W2_FORM',
}

/** Enum of possible types of accreditation verifiction owner class */
export enum IAccreditationVerificationOwnerTypeEnum {
  Corporation = 'CORPORATION',
  Individual = 'INDIVIDUAL',
  JointSpouse = 'JOINT_SPOUSE',
  JointTenant = 'JOINT_TENANT',
  Llc = 'LLC',
  Partnership = 'PARTNERSHIP',
  SelfDirectedIra = 'SELF_DIRECTED_IRA',
  Trust = 'TRUST',
}

export type IAccreditedAccess = INode & {
  __typename?: 'AccreditedAccess';
  amountInvestedLastYearCents?: Maybe<Scalars['Int']['output']>;
  autoGenerated?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  institutionName?: Maybe<Scalars['String']['output']>;
  institutionWebsite?: Maybe<Scalars['Url']['output']>;
  investorType?: Maybe<IAccreditedInvestorTypeEnum>;
  linkedinUrl?: Maybe<Scalars['Url']['output']>;
  ownerId?: Maybe<Scalars['ID']['output']>;
  ownerType?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  state?: Maybe<IAccreditedAccreditedAccessStateEnum>;
};

/** Autogenerated input type of AccreditedAccessCreate */
export type IAccreditedAccessCreateInput = {
  accreditedAccess: IAccreditedAccreditedAccessInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AccreditedAccessCreate. */
export type IAccreditedAccessCreatePayload = {
  __typename?: 'AccreditedAccessCreatePayload';
  accreditedAccess?: Maybe<IAccreditedAccess>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

export type IAccreditedAccreditedAccessInput = {
  amountInvestedLastYearCents?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  institutionName?: InputMaybe<Scalars['String']['input']>;
  institutionWebsite?: InputMaybe<Scalars['Url']['input']>;
  investorType?: InputMaybe<IAccreditedInvestorTypeEnum>;
  linkedinUrl?: InputMaybe<Scalars['Url']['input']>;
  ownerId?: InputMaybe<Scalars['Int']['input']>;
  ownerType?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export enum IAccreditedAccreditedAccessStateEnum {
  AccessDenied = 'ACCESS_DENIED',
  AccessNotRequested = 'ACCESS_NOT_REQUESTED',
  AccessRequested = 'ACCESS_REQUESTED',
  Tier_1Granted = 'TIER_1_GRANTED',
  Tier_2Granted = 'TIER_2_GRANTED',
}

export enum IAccreditedInvestorTypeEnum {
  Individual = 'INDIVIDUAL',
  Institutional = 'INSTITUTIONAL',
}

/** Bank accounts */
export type IAchAccount = INode & {
  __typename?: 'AchAccount';
  accountNumber: Scalars['String']['output'];
  accountType: IAchAccountTypeEnum;
  bankStatementContentType?: Maybe<Scalars['String']['output']>;
  bankStatementFileName?: Maybe<Scalars['String']['output']>;
  bankStatementFileSize?: Maybe<Scalars['BigInt']['output']>;
  bankStatementUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  bankStatementUrl?: Maybe<Scalars['Url']['output']>;
  id: Scalars['ID']['output'];
  nameOnAccount?: Maybe<Scalars['String']['output']>;
  routingNumber: Scalars['String']['output'];
};

/** The connection type for AchAccount. */
export type IAchAccountConnection = {
  __typename?: 'AchAccountConnection';
  /** A list of edges. */
  edges: Array<IAchAccountEdge>;
  /** A list of nodes. */
  nodes: Array<IAchAccount>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type IAchAccountCreateInput = {
  accountNumber: Scalars['String']['input'];
  accountType?: InputMaybe<IAchAccountTypeEnum>;
  directUploadBankStatementUuid?: InputMaybe<Scalars['String']['input']>;
  nameOnAccount: Scalars['String']['input'];
  offeringSlug?: InputMaybe<Scalars['String']['input']>;
  routingNumber: Scalars['String']['input'];
};

/** Autogenerated return type of AchAccountCreate. */
export type IAchAccountCreatePayload = {
  __typename?: 'AchAccountCreatePayload';
  achAccount?: Maybe<IAchAccount>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

/** An edge in a connection. */
export type IAchAccountEdge = {
  __typename?: 'AchAccountEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: IAchAccount;
};

export enum IAchAccountTypeEnum {
  Checking = 'CHECKING',
  Savings = 'SAVINGS',
}

export type IAchAccountUpdateInput = {
  accountNumber: Scalars['String']['input'];
  accountType?: InputMaybe<IAchAccountTypeEnum>;
  directUploadBankStatementUuid?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  nameOnAccount: Scalars['String']['input'];
  routingNumber: Scalars['String']['input'];
};

/** Autogenerated return type of AchAccountUpdate. */
export type IAchAccountUpdatePayload = {
  __typename?: 'AchAccountUpdatePayload';
  achAccount?: Maybe<IAchAccount>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Issues */
export type IActionListIssue = INode & {
  __typename?: 'ActionListIssue';
  blockedBy?: Maybe<Array<IActionListIssue>>;
  blocking?: Maybe<Array<IActionListIssue>>;
  childIssues?: Maybe<Array<IActionListIssue>>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  isBlocked: Scalars['Boolean']['output'];
  issueLabels?: Maybe<Array<IActionListIssueLabel>>;
  name: Scalars['String']['output'];
  parentIssue?: Maybe<IActionListIssue>;
  slug: Scalars['String']['output'];
  state: IActionListIssueStateEnum;
  text?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for ActionListIssue. */
export type IActionListIssueConnection = {
  __typename?: 'ActionListIssueConnection';
  /** A list of edges. */
  edges: Array<IActionListIssueEdge>;
  /** A list of nodes. */
  nodes: Array<IActionListIssue>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IActionListIssueEdge = {
  __typename?: 'ActionListIssueEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: IActionListIssue;
};

/** Issue Labels */
export type IActionListIssueLabel = INode & {
  __typename?: 'ActionListIssueLabel';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  label: IActionListLabel;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum IActionListIssueStateEnum {
  Backlog = 'BACKLOG',
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  InReview = 'IN_REVIEW',
  Question = 'QUESTION',
  Todo = 'TODO',
}

/** Autogenerated input type of ActionListIssueUpdateState */
export type IActionListIssueUpdateStateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  issueSlug: Scalars['String']['input'];
  projectSlug: Scalars['String']['input'];
  state: IActionListIssueStateEnum;
};

/** Autogenerated return type of ActionListIssueUpdateState. */
export type IActionListIssueUpdateStatePayload = {
  __typename?: 'ActionListIssueUpdateStatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  issue: IActionListIssue;
};

/** Labels */
export type IActionListLabel = INode & {
  __typename?: 'ActionListLabel';
  colorHex?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type IActionListMutations = {
  /** Update issue state */
  actionListUpdateIssueState?: Maybe<IActionListIssueUpdateStatePayload>;
};

export type IActionListMutationsActionListUpdateIssueStateArgs = {
  input: IActionListIssueUpdateStateInput;
};

export type IActionListResolvers = {
  /** Fetch Project Issues */
  projectIssues?: Maybe<IActionListIssueConnection>;
};

export type IActionListResolversProjectIssuesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  projectSlug: Scalars['String']['input'];
};

export type IActionRequiredBanner = {
  __typename?: 'ActionRequiredBanner';
  investment?: Maybe<IInvestmentActionRequired>;
};

export type IActivityHistoriesActivityHistory = INode & {
  __typename?: 'ActivityHistoriesActivityHistory';
  createdAt: Scalars['ISO8601DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  deviceInfo?: Maybe<IDevice>;
  id: Scalars['ID']['output'];
  ipAddress?: Maybe<Scalars['String']['output']>;
  justification?: Maybe<Scalars['String']['output']>;
  location?: Maybe<IAddress>;
  overridingAdmin?: Maybe<IUser>;
  primary: IActivityHistoriesPrimaryUnion;
  primaryCategory: Scalars['String']['output'];
  primaryId: Scalars['ID']['output'];
  primaryType: Scalars['String']['output'];
  secondaryId?: Maybe<Scalars['ID']['output']>;
  secondaryType?: Maybe<Scalars['String']['output']>;
  simpleLocation?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user?: Maybe<IUser>;
};

/** The connection type for ActivityHistoriesActivityHistory. */
export type IActivityHistoriesActivityHistoryConnection = {
  __typename?: 'ActivityHistoriesActivityHistoryConnection';
  /** A list of edges. */
  edges: Array<IActivityHistoriesActivityHistoryEdge>;
  /** A list of nodes. */
  nodes: Array<IActivityHistoriesActivityHistory>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IActivityHistoriesActivityHistoryEdge = {
  __typename?: 'ActivityHistoriesActivityHistoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: IActivityHistoriesActivityHistory;
};

export enum IActivityHistoriesOrderEnum {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  PrimaryCategoryAsc = 'PRIMARY_CATEGORY_ASC',
  PrimaryCategoryDesc = 'PRIMARY_CATEGORY_DESC',
  PrimaryIdAsc = 'PRIMARY_ID_ASC',
  PrimaryIdDesc = 'PRIMARY_ID_DESC',
  SecondaryIdAsc = 'SECONDARY_ID_ASC',
  SecondaryIdDesc = 'SECONDARY_ID_DESC',
}

/** Valid types for the primary_type field */
export enum IActivityHistoriesPrimaryTypeEnum {
  ActionListIssue = 'ACTION_LIST_ISSUE',
  Comment = 'COMMENT',
  CorePrivilege = 'CORE_PRIVILEGE',
  CoreSecondariesTransaction = 'CORE_SECONDARIES_TRANSACTION',
  Investment = 'INVESTMENT',
  IssuerProfile = 'ISSUER_PROFILE',
  Offering = 'OFFERING',
  Post = 'POST',
  TeaserPage = 'TEASER_PAGE',
  User = 'USER',
}

/** Possible types for ActivityHistory primary field */
export type IActivityHistoriesPrimaryUnion =
  | IInvestment
  | IIssuerProfile
  | IOffering
  | IUser;

export type IActivityHistoriesResolvers = {
  /** List of ActivityHistory records */
  activityHistories: IActivityHistoriesActivityHistoryConnection;
};

export type IActivityHistoriesResolversActivityHistoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<IActivityHistoriesOrderEnum>>;
  overridingAdminIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  primaryCategories?: InputMaybe<Array<Scalars['String']['input']>>;
  primaryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  primaryTypes?: InputMaybe<Array<IActivityHistoriesPrimaryTypeEnum>>;
  secondaryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  secondaryTypes?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type IAddress = INode & {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** @deprecated Use xOwnerId */
  ownerId: Scalars['ID']['output'];
  ownerType?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street1?: Maybe<Scalars['String']['output']>;
  street2?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  xOwnerId?: Maybe<Scalars['ID']['output']>;
};

export enum IAddressIncorporationCountriesEnum {
  /** Andorra */
  Ad = 'AD',
  /** United Arab Emirates */
  Ae = 'AE',
  /** Afghanistan */
  Af = 'AF',
  /** Antigua And Barbuda */
  Ag = 'AG',
  /** Anguilla */
  Ai = 'AI',
  /** Albania */
  Al = 'AL',
  /** Armenia */
  Am = 'AM',
  /** Netherlands Antilles */
  An = 'AN',
  /** Angola */
  Ao = 'AO',
  /** Antarctica */
  Aq = 'AQ',
  /** Argentina */
  Ar = 'AR',
  /** American Samoa */
  As = 'AS',
  /** Austria */
  At = 'AT',
  /** Australia */
  Au = 'AU',
  /** Aruba */
  Aw = 'AW',
  /** Åland Islands */
  Ax = 'AX',
  /** Azerbaijan */
  Az = 'AZ',
  /** Bosnia And Herzegovina */
  Ba = 'BA',
  /** Barbados */
  Bb = 'BB',
  /** Bangladesh */
  Bd = 'BD',
  /** Belgium */
  Be = 'BE',
  /** Burkina Faso */
  Bf = 'BF',
  /** Bulgaria */
  Bg = 'BG',
  /** Bahrain */
  Bh = 'BH',
  /** Burundi */
  Bi = 'BI',
  /** Benin */
  Bj = 'BJ',
  /** Bermuda */
  Bm = 'BM',
  /** Brunei Darussalam */
  Bn = 'BN',
  /** Bolivia */
  Bo = 'BO',
  /** Brazil */
  Br = 'BR',
  /** Bahamas */
  Bs = 'BS',
  /** Bhutan */
  Bt = 'BT',
  /** Bouvet Island */
  Bv = 'BV',
  /** Botswana */
  Bw = 'BW',
  /** Belarus */
  By = 'BY',
  /** Belize */
  Bz = 'BZ',
  /** Canada */
  Ca = 'CA',
  /** Cocos (Keeling) Islands */
  Cc = 'CC',
  /** Congo (D.R.C.) */
  Cd = 'CD',
  /** Central African Republic */
  Cf = 'CF',
  /** Congo */
  Cg = 'CG',
  /** Switzerland */
  Ch = 'CH',
  /** Cote D'Ivoire */
  Ci = 'CI',
  /** Cook Islands */
  Ck = 'CK',
  /** Chile */
  Cl = 'CL',
  /** Cameroon */
  Cm = 'CM',
  /** China */
  Cn = 'CN',
  /** Colombia */
  Co = 'CO',
  /** Costa Rica */
  Cr = 'CR',
  /** Cuba */
  Cu = 'CU',
  /** Cape Verde */
  Cv = 'CV',
  /** Christmas Island */
  Cx = 'CX',
  /** Cyprus */
  Cy = 'CY',
  /** Czech Republic */
  Cz = 'CZ',
  /** Germany */
  De = 'DE',
  /** Djibouti */
  Dj = 'DJ',
  /** Denmark */
  Dk = 'DK',
  /** Dominica */
  Dm = 'DM',
  /** Dominican Republic */
  Do = 'DO',
  /** Algeria */
  Dz = 'DZ',
  /** Ecuador */
  Ec = 'EC',
  /** Estonia */
  Ee = 'EE',
  /** Egypt */
  Eg = 'EG',
  /** Western Sahara */
  Eh = 'EH',
  /** Eritrea */
  Er = 'ER',
  /** Spain */
  Es = 'ES',
  /** Ethiopia */
  Et = 'ET',
  /** Finland */
  Fi = 'FI',
  /** Fiji */
  Fj = 'FJ',
  /** Falkland Islands (Malvinas) */
  Fk = 'FK',
  /** Micronesia, Federated States Of */
  Fm = 'FM',
  /** Faroe Islands */
  Fo = 'FO',
  /** France */
  Fr = 'FR',
  /** Gabon */
  Ga = 'GA',
  /** United Kingdom */
  Gb = 'GB',
  /** Grenada */
  Gd = 'GD',
  /** Georgia */
  Ge = 'GE',
  /** French Guiana */
  Gf = 'GF',
  /** Ghana */
  Gh = 'GH',
  /** Gibraltar */
  Gi = 'GI',
  /** Greenland */
  Gl = 'GL',
  /** Gambia */
  Gm = 'GM',
  /** Guinea */
  Gn = 'GN',
  /** Guadeloupe */
  Gp = 'GP',
  /** Equatorial Guinea */
  Gq = 'GQ',
  /** Greece */
  Gr = 'GR',
  /** South Georgia And The South Sandwich Islands */
  Gs = 'GS',
  /** Guatemala */
  Gt = 'GT',
  /** Guam */
  Gu = 'GU',
  /** Guinea-Bissau */
  Gw = 'GW',
  /** Guyana */
  Gy = 'GY',
  /** Guernsey */
  Gg = 'Gg',
  /** Hong Kong */
  Hk = 'HK',
  /** Heard Island And Mcdonald Islands */
  Hm = 'HM',
  /** Honduras */
  Hn = 'HN',
  /** Croatia */
  Hr = 'HR',
  /** Haiti */
  Ht = 'HT',
  /** Hungary */
  Hu = 'HU',
  /** Indonesia */
  Id = 'ID',
  /** Ireland */
  Ie = 'IE',
  /** Israel */
  Il = 'IL',
  /** Isle Of Man */
  Im = 'IM',
  /** India */
  In = 'IN',
  /** British Indian Ocean Territory */
  Io = 'IO',
  /** Iraq */
  Iq = 'IQ',
  /** Iran */
  Ir = 'IR',
  /** Iceland */
  Is = 'IS',
  /** Italy */
  It = 'IT',
  /** Jersey */
  Je = 'JE',
  /** Jamaica */
  Jm = 'JM',
  /** Jordan */
  Jo = 'JO',
  /** Japan */
  Jp = 'JP',
  /** Kenya */
  Ke = 'KE',
  /** Kyrgyzstan */
  Kg = 'KG',
  /** Cambodia */
  Kh = 'KH',
  /** Kiribati */
  Ki = 'KI',
  /** Comoros */
  Km = 'KM',
  /** Saint Kitts And Nevis */
  Kn = 'KN',
  /** North Korea */
  Kp = 'KP',
  /** South Korea */
  Kr = 'KR',
  /** Kuwait */
  Kw = 'KW',
  /** Cayman Islands */
  Ky = 'KY',
  /** Kazakhstan */
  Kz = 'KZ',
  /** Lao People'S Democratic Republic */
  La = 'LA',
  /** Lebanon */
  Lb = 'LB',
  /** Saint Lucia */
  Lc = 'LC',
  /** Liechtenstein */
  Li = 'LI',
  /** Sri Lanka */
  Lk = 'LK',
  /** Liberia */
  Lr = 'LR',
  /** Lesotho */
  Ls = 'LS',
  /** Lithuania */
  Lt = 'LT',
  /** Luxembourg */
  Lu = 'LU',
  /** Latvia */
  Lv = 'LV',
  /** Libyan Arab Jamahiriya */
  Ly = 'LY',
  /** Morocco */
  Ma = 'MA',
  /** Monaco */
  Mc = 'MC',
  /** Moldova, Republic Of */
  Md = 'MD',
  /** Montenegro */
  Me = 'ME',
  /** Madagascar */
  Mg = 'MG',
  /** Marshall Islands */
  Mh = 'MH',
  /** Macedonia, The Former Yugoslav Republic Of */
  Mk = 'MK',
  /** Mali */
  Ml = 'ML',
  /** Myanmar */
  Mm = 'MM',
  /** Mongolia */
  Mn = 'MN',
  /** Macao */
  Mo = 'MO',
  /** Northern Mariana Islands */
  Mp = 'MP',
  /** Martinique */
  Mq = 'MQ',
  /** Mauritania */
  Mr = 'MR',
  /** Montserrat */
  Ms = 'MS',
  /** Malta */
  Mt = 'MT',
  /** Mauritius */
  Mu = 'MU',
  /** Maldives */
  Mv = 'MV',
  /** Malawi */
  Mw = 'MW',
  /** Mexico */
  Mx = 'MX',
  /** Malaysia */
  My = 'MY',
  /** Mozambique */
  Mz = 'MZ',
  /** Namibia */
  Na = 'NA',
  /** New Caledonia */
  Nc = 'NC',
  /** Niger */
  Ne = 'NE',
  /** Norfolk Island */
  Nf = 'NF',
  /** Nigeria */
  Ng = 'NG',
  /** Nicaragua */
  Ni = 'NI',
  /** Netherlands */
  Nl = 'NL',
  /** Norway */
  No = 'NO',
  /** Nepal */
  Np = 'NP',
  /** Nauru */
  Nr = 'NR',
  /** Niue */
  Nu = 'NU',
  /** New Zealand */
  Nz = 'NZ',
  /** Oman */
  Om = 'OM',
  /** Panama */
  Pa = 'PA',
  /** Peru */
  Pe = 'PE',
  /** French Polynesia */
  Pf = 'PF',
  /** Papua New Guinea */
  Pg = 'PG',
  /** Philippines */
  Ph = 'PH',
  /** Pakistan */
  Pk = 'PK',
  /** Poland */
  Pl = 'PL',
  /** Saint Pierre And Miquelon */
  Pm = 'PM',
  /** Pitcairn */
  Pn = 'PN',
  /** Puerto Rico */
  Pr = 'PR',
  /** Palestinian Territory, Occupied */
  Ps = 'PS',
  /** Portugal */
  Pt = 'PT',
  /** Palau */
  Pw = 'PW',
  /** Paraguay */
  Py = 'PY',
  /** Qatar */
  Qa = 'QA',
  /** Reunion */
  Re = 'RE',
  /** Romania */
  Ro = 'RO',
  /** Serbia */
  Rs = 'RS',
  /** Russian Federation */
  Ru = 'RU',
  /** Rwanda */
  Rw = 'RW',
  /** Saudi Arabia */
  Sa = 'SA',
  /** Solomon Islands */
  Sb = 'SB',
  /** Seychelles */
  Sc = 'SC',
  /** Sudan */
  Sd = 'SD',
  /** Sweden */
  Se = 'SE',
  /** Singapore */
  Sg = 'SG',
  /** Saint Helena */
  Sh = 'SH',
  /** Slovenia */
  Si = 'SI',
  /** Svalbard And Jan Mayen */
  Sj = 'SJ',
  /** Slovakia */
  Sk = 'SK',
  /** Sierra Leone */
  Sl = 'SL',
  /** San Marino */
  Sm = 'SM',
  /** Senegal */
  Sn = 'SN',
  /** Somalia */
  So = 'SO',
  /** Suriname */
  Sr = 'SR',
  /** South Sudan */
  Ss = 'SS',
  /** Sao Tome And Principe */
  St = 'ST',
  /** El Salvador */
  Sv = 'SV',
  /** Syria */
  Sy = 'SY',
  /** Swaziland */
  Sz = 'SZ',
  /** Turks And Caicos Islands */
  Tc = 'TC',
  /** Chad */
  Td = 'TD',
  /** French Southern Territories */
  Tf = 'TF',
  /** Togo */
  Tg = 'TG',
  /** Thailand */
  Th = 'TH',
  /** Tajikistan */
  Tj = 'TJ',
  /** Tokelau */
  Tk = 'TK',
  /** Timor-Leste */
  Tl = 'TL',
  /** Turkmenistan */
  Tm = 'TM',
  /** Tunisia */
  Tn = 'TN',
  /** Tonga */
  To = 'TO',
  /** Turkey */
  Tr = 'TR',
  /** Trinidad And Tobago */
  Tt = 'TT',
  /** Tuvalu */
  Tv = 'TV',
  /** Taiwan */
  Tw = 'TW',
  /** Tanzania, United Republic Of */
  Tz = 'TZ',
  /** Ukraine */
  Ua = 'UA',
  /** Uganda */
  Ug = 'UG',
  /** U.S. Minor Outlying Islands */
  Um = 'UM',
  /** Uruguay */
  Uy = 'UY',
  /** Uzbekistan */
  Uz = 'UZ',
  /** Holy See (Vatican City State) */
  Va = 'VA',
  /** Saint Vincent And The Grenadines */
  Vc = 'VC',
  /** Venezuela */
  Ve = 'VE',
  /** Virgin Islands, British */
  Vg = 'VG',
  /** U.S. Virgin Islands */
  Vi = 'VI',
  /** Viet Nam */
  Vn = 'VN',
  /** Vanuatu */
  Vu = 'VU',
  /** Wallis And Futuna */
  Wf = 'WF',
  /** Samoa */
  Ws = 'WS',
  /** Yemen */
  Ye = 'YE',
  /** Mayotte */
  Yt = 'YT',
  /** South Africa */
  Za = 'ZA',
  /** Zambia */
  Zm = 'ZM',
  /** Zimbabwe */
  Zw = 'ZW',
}

export enum IAddressIncorporationStatesEnum {
  /** Alaska */
  Ak = 'AK',
  /** Alabama */
  Al = 'AL',
  /** Arkansas */
  Ar = 'AR',
  /** Arizona */
  Az = 'AZ',
  /** California */
  Ca = 'CA',
  /** Colorado */
  Co = 'CO',
  /** Connecticut */
  Ct = 'CT',
  /** District of Columbia */
  Dc = 'DC',
  /** Delaware */
  De = 'DE',
  /** Florida */
  Fl = 'FL',
  /** Georgia */
  Ga = 'GA',
  /** Hawaii */
  Hi = 'HI',
  /** Iowa */
  Ia = 'IA',
  /** Idaho */
  Id = 'ID',
  /** Illinois */
  Il = 'IL',
  /** Indiana */
  In = 'IN',
  /** Kansas */
  Ks = 'KS',
  /** Kentucky */
  Ky = 'KY',
  /** Louisiana */
  La = 'LA',
  /** Massachusetts */
  Ma = 'MA',
  /** Maryland */
  Md = 'MD',
  /** Maine */
  Me = 'ME',
  /** Michigan */
  Mi = 'MI',
  /** Minnesota */
  Mn = 'MN',
  /** Missouri */
  Mo = 'MO',
  /** Mississippi */
  Ms = 'MS',
  /** Montana */
  Mt = 'MT',
  /** North Carolina */
  Nc = 'NC',
  /** North Dakota */
  Nd = 'ND',
  /** Nebraska */
  Ne = 'NE',
  /** New Hampshire */
  Nh = 'NH',
  /** New Jersey */
  Nj = 'NJ',
  /** New Mexico */
  Nm = 'NM',
  /** Nevada */
  Nv = 'NV',
  /** New York */
  Ny = 'NY',
  /** Ohio */
  Oh = 'OH',
  /** Oklahoma */
  Ok = 'OK',
  /** Oregon */
  Or = 'OR',
  /** Pennsylvania */
  Pa = 'PA',
  /** Puerto Rico */
  Pr = 'PR',
  /** Rhode Island */
  Ri = 'RI',
  /** South Carolina */
  Sc = 'SC',
  /** South Dakota */
  Sd = 'SD',
  /** Tennessee */
  Tn = 'TN',
  /** Texas */
  Tx = 'TX',
  /** Utah */
  Ut = 'UT',
  /** Virginia */
  Va = 'VA',
  /** British Virgin Islands */
  Vg = 'VG',
  /** Vermont */
  Vt = 'VT',
  /** Washington */
  Wa = 'WA',
  /** Wisconsin */
  Wi = 'WI',
  /** West Virginia */
  Wv = 'WV',
  /** Wyoming */
  Wy = 'WY',
}

export type IAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ownerId?: InputMaybe<Scalars['Int']['input']>;
  ownerType?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street1?: InputMaybe<Scalars['String']['input']>;
  street2?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};

/** Autogenerated input type of AdminInvestorProfileApproveKycAml */
export type IAdminInvestorProfileApproveKycAmlInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  investorProfileId: Scalars['ID']['input'];
};

/** Autogenerated return type of AdminInvestorProfileApproveKycAml. */
export type IAdminInvestorProfileApproveKycAmlPayload = {
  __typename?: 'AdminInvestorProfileApproveKycAmlPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  investorProfile?: Maybe<IInvestorProfile>;
};

export type IAdminMenu = {
  __typename?: 'AdminMenu';
  items?: Maybe<Array<IAdminMenu>>;
  link?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

/** Autogenerated input type of AdminOfferingTermTierDealTermUpsert */
export type IAdminOfferingTermTierDealTermUpsertInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dealTerms?: InputMaybe<Array<IOfferingTermTierDealTermInput>>;
  termTierId: Scalars['Int']['input'];
};

/** Autogenerated return type of AdminOfferingTermTierDealTermUpsert. */
export type IAdminOfferingTermTierDealTermUpsertPayload = {
  __typename?: 'AdminOfferingTermTierDealTermUpsertPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dealTerms?: Maybe<Array<IOfferingTermTierDealTerm>>;
  errors?: Maybe<Array<IMultiObjectsError>>;
};

/** Autogenerated input type of AdminOfferingTermTierUpsert */
export type IAdminOfferingTermTierUpsertInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  offeringId: Scalars['Int']['input'];
  termTiers?: InputMaybe<Array<IOfferingTermTierInput>>;
};

/** Autogenerated return type of AdminOfferingTermTierUpsert. */
export type IAdminOfferingTermTierUpsertPayload = {
  __typename?: 'AdminOfferingTermTierUpsertPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IMultiObjectsError>>;
  termTiers?: Maybe<Array<IOfferingTermTier>>;
};

/** Autogenerated input type of AdminUserCapabilityAdd */
export type IAdminUserCapabilityAddInput = {
  capability: IAdminUsersCapabilityInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of AdminUserCapabilityAdd. */
export type IAdminUserCapabilityAddPayload = {
  __typename?: 'AdminUserCapabilityAddPayload';
  capability?: Maybe<ICapability>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Autogenerated input type of AdminUserCapabilityEdit */
export type IAdminUserCapabilityEditInput = {
  capability: IAdminUsersCapabilityInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AdminUserCapabilityEdit. */
export type IAdminUserCapabilityEditPayload = {
  __typename?: 'AdminUserCapabilityEditPayload';
  capability?: Maybe<ICapability>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Autogenerated input type of AdminUserCapabilityRemove */
export type IAdminUserCapabilityRemoveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of AdminUserCapabilityRemove. */
export type IAdminUserCapabilityRemovePayload = {
  __typename?: 'AdminUserCapabilityRemovePayload';
  capability?: Maybe<ICapability>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

export type IAdminUsersCapabilityInput = {
  domain?: InputMaybe<ICapabilityDomainEnum>;
  id?: InputMaybe<Scalars['ID']['input']>;
  level?: InputMaybe<ICapabilityLevelEnum>;
};

export type IAirdropActionPoint = INode & {
  __typename?: 'AirdropActionPoint';
  actionName?: Maybe<Scalars['String']['output']>;
  amount: Scalars['Int']['output'];
  amountDescription?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  identifier: Scalars['String']['output'];
  link?: Maybe<Scalars['Url']['output']>;
  maxAmount?: Maybe<Scalars['Int']['output']>;
  shortDescription: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type IAirdropActionPointInput = {
  actionName?: InputMaybe<Scalars['String']['input']>;
  amount: Scalars['Int']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  identifier: Scalars['String']['input'];
  link?: InputMaybe<Scalars['Url']['input']>;
  maxAmount?: InputMaybe<Scalars['Int']['input']>;
  offeringSlug?: InputMaybe<Scalars['String']['input']>;
  shortDescription: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type IAmount = {
  __typename?: 'Amount';
  amountInCents?: Maybe<Scalars['BigInt']['output']>;
  currency: Scalars['String']['output'];
};

export type IAnnouncement = INode & {
  __typename?: 'Announcement';
  id: Scalars['ID']['output'];
  link: Scalars['String']['output'];
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

/** Enum of possible types of accreditation verification claim entity */
export enum IAnnualIncomeClaimEntityTypeEnum {
  Individual = 'INDIVIDUAL',
  JointSpouse = 'JOINT_SPOUSE',
}

export type IArticleAuthor = {
  author?: Maybe<IUser>;
};

export type IArticleTags = {
  tags?: Maybe<Array<ITagsTag>>;
};

/** Details of an offering's asset */
export type IAsset = IAssetInterface & {
  __typename?: 'Asset';
  assetType?: Maybe<IAssetTypeEnum>;
  assetYear: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** Discount offered in the Safe agreement rounded to 4 decimal points eg. 0.25 means 25% */
  discountPercentage: Scalars['BigDecimal']['output'];
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  issuerProfile?: Maybe<IIssuerProfile>;
  offering: IOffering;
  symbol?: Maybe<Scalars['String']['output']>;
  /** Current secondary market tradability status of the asset */
  tradability: ISecondaryTradabilityEnum;
  tradable: Scalars['Boolean']['output'];
  valuationCapCents?: Maybe<Scalars['BigInt']['output']>;
  valueChangeRatio?: Maybe<Scalars['BigDecimal']['output']>;
};

/** Information for an asset */
export type IAssetInterface = {
  assetType?: Maybe<IAssetTypeEnum>;
  assetYear: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** Discount offered in the Safe agreement rounded to 4 decimal points eg. 0.25 means 25% */
  discountPercentage: Scalars['BigDecimal']['output'];
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  issuerProfile?: Maybe<IIssuerProfile>;
  offering: IOffering;
  symbol?: Maybe<Scalars['String']['output']>;
  /** Current secondary market tradability status of the asset */
  tradability: ISecondaryTradabilityEnum;
  tradable: Scalars['Boolean']['output'];
  valuationCapCents?: Maybe<Scalars['BigInt']['output']>;
  valueChangeRatio?: Maybe<Scalars['BigDecimal']['output']>;
};

/** The connection type for AssetInterface. */
export type IAssetInterfaceConnection = {
  __typename?: 'AssetInterfaceConnection';
  /** A list of edges. */
  edges: Array<IAssetInterfaceEdge>;
  /** A list of nodes. */
  nodes: Array<IAssetInterface>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IAssetInterfaceEdge = {
  __typename?: 'AssetInterfaceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<IAssetInterface>;
};

/** List of available Order Enums of Asset domain */
export enum IAssetOrderEnum {
  IssuerProfileNameAsc = 'ISSUER_PROFILE_NAME_ASC',
  IssuerProfileNameDesc = 'ISSUER_PROFILE_NAME_DESC',
  IssuerProfileTradabilityAsc = 'ISSUER_PROFILE_TRADABILITY_ASC',
  IssuerProfileTradabilityDesc = 'ISSUER_PROFILE_TRADABILITY_DESC',
  UserHeldValueCentsAsc = 'USER_HELD_VALUE_CENTS_ASC',
  UserHeldValueCentsDesc = 'USER_HELD_VALUE_CENTS_DESC',
  UserReturnOnInvestmentCentsAsc = 'USER_RETURN_ON_INVESTMENT_CENTS_ASC',
  UserReturnOnInvestmentCentsDesc = 'USER_RETURN_ON_INVESTMENT_CENTS_DESC',
}

export enum IAssetScopeEnum {
  UserPortfolio = 'USER_PORTFOLIO',
}

/** Types of assets */
export enum IAssetTypeEnum {
  Debt = 'DEBT',
  Equity = 'EQUITY',
  Interest = 'INTEREST',
  Other = 'OTHER',
  Safe = 'SAFE',
  Saft = 'SAFT',
  Subscription = 'SUBSCRIPTION',
  Token = 'TOKEN',
  TokenDpa = 'TOKEN_DPA',
}

export type IAuthApplicationAuthenticateInput = {
  applicationId: Scalars['String']['input'];
  applicationSecret: Scalars['String']['input'];
};

export type IAuthApplicationAuthenticatePayload = IBasePayloadType & {
  __typename?: 'AuthApplicationAuthenticatePayload';
  errors?: Maybe<Array<IValidationError>>;
  accessToken?: Maybe<Scalars['String']['output']>;
};

export type IAuthApplicationChangeEmailInput = {
  /** The new email being set */
  email: Scalars['String']['input'];
  /** The r-auth UUID for the user */
  authId: Scalars['String']['input'];
};

export type IAuthApplicationChangeEmailPayload = IBasePayloadType & {
  __typename?: 'AuthApplicationChangeEmailPayload';
  errors?: Maybe<Array<IValidationError>>;
};

export type IAuthApplicationCreateSocialAuthInput = {
  attributes: ISocialAuthInput;
};

export type IAuthApplicationCreateSocialAuthPayload = IBasePayloadType & {
  __typename?: 'AuthApplicationCreateSocialAuthPayload';
  errors?: Maybe<Array<IValidationError>>;
  token?: Maybe<IAuthToken>;
};

export type IAuthApplicationGeneratePasswordResetInput = {
  email: Scalars['String']['input'];
};

export type IAuthApplicationGeneratePasswordResetPayload = IBasePayloadType & {
  __typename?: 'AuthApplicationGeneratePasswordResetPayload';
  errors?: Maybe<Array<IValidationError>>;
  resetToken?: Maybe<Scalars['String']['output']>;
};

export type IAuthApplicationLoginAsAnotherUserInput = {
  fromAuthId?: InputMaybe<Scalars['String']['input']>;
  toAuthId: Scalars['String']['input'];
};

export type IAuthApplicationLoginAsAnotherUserPayload = IBasePayloadType & {
  __typename?: 'AuthApplicationLoginAsAnotherUserPayload';
  errors?: Maybe<Array<IValidationError>>;
  token?: Maybe<IAuthToken>;
};

export type IAuthApplicationLoginSocialAuthInput = {
  attributes: ISocialAuthInput;
};

export type IAuthApplicationLoginSocialAuthPayload = IBasePayloadType & {
  __typename?: 'AuthApplicationLoginSocialAuthPayload';
  errors?: Maybe<Array<IValidationError>>;
  token?: Maybe<IAuthToken>;
};

export type IAuthApplicationRegisterUserInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type IAuthApplicationRegisterUserPayload = IBasePayloadType & {
  __typename?: 'AuthApplicationRegisterUserPayload';
  errors?: Maybe<Array<IValidationError>>;
  token?: Maybe<IAuthToken>;
  authId?: Maybe<Scalars['String']['output']>;
};

export type IAuthApplicationResetPasswordWithTokenInput = {
  email: Scalars['String']['input'];
  resetToken: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type IAuthApplicationResetPasswordWithTokenPayload = IBasePayloadType & {
  __typename?: 'AuthApplicationResetPasswordWithTokenPayload';
  errors?: Maybe<Array<IValidationError>>;
};

export type IAuthApplicationSignInUserInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type IAuthApplicationSignInUserPayload = IBasePayloadType & {
  __typename?: 'AuthApplicationSignInUserPayload';
  errors?: Maybe<Array<IValidationError>>;
  token?: Maybe<IAuthToken>;
};

export type IAuthApplicationSyncUserInput = {
  createdAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type IAuthApplicationSyncUserPayload = IBasePayloadType & {
  __typename?: 'AuthApplicationSyncUserPayload';
  errors?: Maybe<Array<IValidationError>>;
  authId?: Maybe<Scalars['String']['output']>;
};

export type IAuthApplicationValidatePasswordResetTokenInput = {
  email: Scalars['String']['input'];
  resetToken: Scalars['String']['input'];
};

export type IAuthApplicationValidatePasswordResetTokenPayload =
  IBasePayloadType & {
    __typename?: 'AuthApplicationValidatePasswordResetTokenPayload';
    errors?: Maybe<Array<IValidationError>>;
    valid: Scalars['Boolean']['output'];
  };

/** Autogenerated input type of AuthAuthenticateWithTwoFactor */
export type IAuthAuthenticateWithTwoFactorInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  signInCode: Scalars['String']['input'];
};

/** Autogenerated return type of AuthAuthenticateWithTwoFactor. */
export type IAuthAuthenticateWithTwoFactorPayload = {
  __typename?: 'AuthAuthenticateWithTwoFactorPayload';
  authFlags?: Maybe<IAuthFlags>;
  authToken?: Maybe<IAuthToken>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  jwtToken?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use flags instead */
  needSetPassword?: Maybe<Scalars['Boolean']['output']>;
  user?: Maybe<IUser>;
};

export type IAuthChangePasswordInput = {
  email: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
  oldPassword?: InputMaybe<Scalars['String']['input']>;
};

export type IAuthChangePasswordPayload = IBasePayloadType & {
  __typename?: 'AuthChangePasswordPayload';
  errors?: Maybe<Array<IValidationError>>;
};

/** Autogenerated input type of AuthExchangeToken */
export type IAuthExchangeTokenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  oldToken: Scalars['String']['input'];
};

/** Autogenerated return type of AuthExchangeToken. */
export type IAuthExchangeTokenPayload = {
  __typename?: 'AuthExchangeTokenPayload';
  authToken?: Maybe<IAuthToken>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  user?: Maybe<IUser>;
};

/** Flags relating to auth */
export type IAuthFlags = {
  __typename?: 'AuthFlags';
  /** This request created the user */
  justCreated?: Maybe<Scalars['Boolean']['output']>;
  /** The user must complete 2FA before signing in */
  need2fa?: Maybe<Scalars['Boolean']['output']>;
  /** User has not set a password (e.g. social auth signup) */
  needSetPassword?: Maybe<Scalars['Boolean']['output']>;
};

export type IAuthRefreshInput = {
  refreshToken?: InputMaybe<Scalars['String']['input']>;
};

export type IAuthRefreshPayload = IBasePayloadType & {
  __typename?: 'AuthRefreshPayload';
  errors?: Maybe<Array<IValidationError>>;
  token?: Maybe<IAuthToken>;
};

/** Autogenerated input type of AuthSignInAsUser */
export type IAuthSignInAsUserInput = {
  /** Should have the Core Admin role? */
  admin?: InputMaybe<Scalars['Boolean']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Should have investments? */
  investor?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should be an offering admin? */
  offeringAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  /** The user to login as */
  user?: InputMaybe<IFindUserInput>;
};

/** Autogenerated return type of AuthSignInAsUser. */
export type IAuthSignInAsUserPayload = {
  __typename?: 'AuthSignInAsUserPayload';
  authToken?: Maybe<IAuthToken>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<IValidationErrors>;
  user?: Maybe<IUser>;
};

/** Autogenerated input type of AuthSignIn */
export type IAuthSignInInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['Email']['input'];
  password: Scalars['String']['input'];
  source?: InputMaybe<IAuthSourceEnum>;
};

/** Autogenerated return type of AuthSignIn. */
export type IAuthSignInPayload = {
  __typename?: 'AuthSignInPayload';
  authFlags?: Maybe<IAuthFlags>;
  authToken?: Maybe<IAuthToken>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  /** @deprecated Use flags instead */
  justCreated?: Maybe<Scalars['Boolean']['output']>;
  jwtToken?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use flags instead */
  needSetPassword?: Maybe<Scalars['Boolean']['output']>;
  signInCode?: Maybe<Scalars['String']['output']>;
  user?: Maybe<IUser>;
};

export type IAuthSignOutInput = {
  accessToken: Scalars['String']['input'];
};

export type IAuthSignOutPayload = IBasePayloadType & {
  __typename?: 'AuthSignOutPayload';
  errors?: Maybe<Array<IValidationError>>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  isLoggedBackInAsAdmin: Scalars['Boolean']['output'];
};

/** Autogenerated input type of AuthSignUp */
export type IAuthSignUpInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  referralToken?: InputMaybe<Scalars['String']['input']>;
  user: IUserCreateInput;
};

/** Autogenerated return type of AuthSignUp. */
export type IAuthSignUpPayload = {
  __typename?: 'AuthSignUpPayload';
  authFlags?: Maybe<IAuthFlags>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  jwtToken?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use flags instead */
  needSetPassword?: Maybe<Scalars['Boolean']['output']>;
  token?: Maybe<IAuthToken>;
  user?: Maybe<IUser>;
};

/** List of available Affiliations Enums of Comments domain */
export enum IAuthSourceEnum {
  Capital = 'CAPITAL',
  Retail = 'RETAIL',
}

export type IAuthToken = {
  __typename?: 'AuthToken';
  id: Scalars['ID']['output'];
  accessToken: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
  idToken?: Maybe<Scalars['String']['output']>;
  expiresAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export type IAutocompleteTags = {
  tags?: Maybe<Array<ITagsTag>>;
};

export type IAvaWatzBanner = {
  __typename?: 'AvaWatzBanner';
  user: IUser;
};

export type IBadge = INode & {
  __typename?: 'Badge';
  id: Scalars['ID']['output'];
  type: Scalars['String']['output'];
};

export type IBankInfo = INode & {
  __typename?: 'BankInfo';
  bankName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  routingNumber: Scalars['String']['output'];
};

export type IBannerContent = {
  __typename?: 'BannerContent';
  activatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  backgroundImageAltText?: Maybe<Scalars['String']['output']>;
  backgroundImageDesktop?: Maybe<Scalars['String']['output']>;
  backgroundImageMobile?: Maybe<Scalars['String']['output']>;
  carouselId?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  ctaText?: Maybe<Scalars['String']['output']>;
  ctaTheme?: Maybe<Scalars['String']['output']>;
  deactivatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  foregroundImageDesktop?: Maybe<Scalars['String']['output']>;
  foregroundImageMobile?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  logoAltText?: Maybe<Scalars['String']['output']>;
  openLinkInNewTab?: Maybe<Scalars['Boolean']['output']>;
  overlayBlendMode: Scalars['String']['output'];
  overlayColor?: Maybe<Scalars['String']['output']>;
  overlayOpacity: Scalars['String']['output'];
  overlayTheme: Scalars['String']['output'];
  overlayTransparent: Scalars['Boolean']['output'];
  pillText?: Maybe<Scalars['String']['output']>;
  previewToken?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  termsAndConditions?: Maybe<Scalars['String']['output']>;
  theme?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of BannerDismiss */
export type IBannerDismissInput = {
  bannerType: IBannerTypeEnum;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of BannerDismiss. */
export type IBannerDismissPayload = {
  __typename?: 'BannerDismissPayload';
  bannerType?: Maybe<IBannerTypeEnum>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Enum of possible types of user banners */
export enum IBannerTypeEnum {
  ActionRequired = 'ACTION_REQUIRED',
  AnnualReport = 'ANNUAL_REPORT',
  AvaWatz = 'AVA_WATZ',
  ClosingSoon = 'CLOSING_SOON',
  ConfirmEmail = 'CONFIRM_EMAIL',
  Film = 'FILM',
  ModeMobile = 'MODE_MOBILE',
  Music = 'MUSIC',
  NoteDistributionComing = 'NOTE_DISTRIBUTION_COMING',
  NoteLaunch = 'NOTE_LAUNCH',
  NoteRewardClaim = 'NOTE_REWARD_CLAIM',
  PriorInvestorIdentityVerification = 'PRIOR_INVESTOR_IDENTITY_VERIFICATION',
  PrivacyUpdate = 'PRIVACY_UPDATE',
  RepublicSeriesB = 'REPUBLIC_SERIES_B',
  RepublicWalletLaunch = 'REPUBLIC_WALLET_LAUNCH',
  Seedrs = 'SEEDRS',
  SpaceWeek = 'SPACE_WEEK',
  StateChangeConfirmation = 'STATE_CHANGE_CONFIRMATION',
  Watford = 'WATFORD',
}

/** Possible types of user banners */
export type IBannerUnion =
  | IActionRequiredBanner
  | IAvaWatzBanner
  | IClosingSoonBanner
  | IConfirmEmailBanner
  | IFilmBanner
  | IIdentityVerificationBanner
  | IModeMobileBanner
  | INoteDistributionBanner
  | INoteLaunchBanner
  | INoteRewardClaimBanner
  | IPrivacyUpdateBanner
  | IRepublicWalletLaunchBanner
  | ISpaceWeekBanner
  | IStateChangeConfirmationBanner
  | IWatfordBanner;

export type IBasePayloadType = {
  errors?: Maybe<Array<IValidationError>>;
};

/** Autogenerated input type of BeneficialOwnersDraftInvite */
export type IBeneficialOwnersDraftInviteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  investorEntityCrossTableId: Scalars['String']['input'];
};

/** Autogenerated return type of BeneficialOwnersDraftInvite. */
export type IBeneficialOwnersDraftInvitePayload = {
  __typename?: 'BeneficialOwnersDraftInvitePayload';
  beneficialOwners?: Maybe<Array<IBeneficialOwnerType>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Autogenerated input type of BeneficialOwnersRemoveOwner */
export type IBeneficialOwnersRemoveOwnerInput = {
  beneficialOwnerId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of BeneficialOwnersRemoveOwner. */
export type IBeneficialOwnersRemoveOwnerPayload = {
  __typename?: 'BeneficialOwnersRemoveOwnerPayload';
  beneficialOwners?: Maybe<Array<IBeneficialOwnerType>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Autogenerated input type of BeneficialOwnersResendInvite */
export type IBeneficialOwnersResendInviteInput = {
  beneficialOwnerId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of BeneficialOwnersResendInvite. */
export type IBeneficialOwnersResendInvitePayload = {
  __typename?: 'BeneficialOwnersResendInvitePayload';
  beneficialOwners?: Maybe<Array<IBeneficialOwnerType>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

export type IBeneficialOwnerType = {
  __typename?: 'BeneficialOwnerType';
  id: Scalars['ID']['output'];
  inviteEmail?: Maybe<Scalars['String']['output']>;
  inviteStatus: IInvestorEntityBeneficialOwnerInviteStatusesEnum;
  passedKyc: Scalars['Boolean']['output'];
  userName?: Maybe<Scalars['String']['output']>;
};

export type IBitpayAccount = {
  __typename?: 'BitpayAccount';
  countOfAllInvoices?: Maybe<Scalars['String']['output']>;
  countOfPaidInvoices?: Maybe<Scalars['String']['output']>;
  countOfRefundedInvoices?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  sumOfAllInvoices?: Maybe<Scalars['Int']['output']>;
  sumOfPendingRefunds?: Maybe<Scalars['Int']['output']>;
  sumOfRefundedInvoices?: Maybe<Scalars['Int']['output']>;
};

/** CryptoBlockchainRuleSet Input for Core Domain */
export type IBlockchainRuleSetInput = {
  charactersErrorMessage?: InputMaybe<Scalars['String']['input']>;
  cryptoTokenId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  logoUrl?: InputMaybe<Scalars['Url']['input']>;
  max?: InputMaybe<Scalars['Int']['input']>;
  min?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  prefixNotAllowed?: InputMaybe<Scalars['String']['input']>;
  prefixNotAllowedErrorMessage?: InputMaybe<Scalars['String']['input']>;
  prefixRequired?: InputMaybe<Scalars['String']['input']>;
  prefixRequiredErrorMessage?: InputMaybe<Scalars['String']['input']>;
  regex?: InputMaybe<Scalars['String']['input']>;
  regexErrorMessage?: InputMaybe<Scalars['String']['input']>;
};

export type ICampaignAgreement = INode & {
  __typename?: 'CampaignAgreement';
  attachmentContentType?: Maybe<Scalars['String']['output']>;
  attachmentFileName?: Maybe<Scalars['String']['output']>;
  attachmentFileSize?: Maybe<Scalars['BigInt']['output']>;
  attachmentUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  attachmentUrl?: Maybe<Scalars['Url']['output']>;
  documentType?: Maybe<ICampaignAgreementDocumentTypeEnum>;
  downloadSignedPath?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  previewSignedPath?: Maybe<Scalars['String']['output']>;
};

export enum ICampaignAgreementDocumentTypeEnum {
  CampaignAgreement = 'CAMPAIGN_AGREEMENT',
  EngagementLetter = 'ENGAGEMENT_LETTER',
  TechServicesAgreement = 'TECH_SERVICES_AGREEMENT',
}

/** Offerings search category cap type */
export type ICap = INode & {
  __typename?: 'Cap';
  /** ID of the object. */
  id: Scalars['ID']['output'];
  maximum?: Maybe<Scalars['String']['output']>;
  minimum?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type ICapability = INode & {
  __typename?: 'Capability';
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  domain?: Maybe<ICapabilityDomainEnum>;
  grantedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  grantedBy?: Maybe<IUser>;
  id: Scalars['ID']['output'];
  level?: Maybe<ICapabilityLevelEnum>;
};

/** Domains of capability */
export enum ICapabilityDomainEnum {
  Accredited = 'ACCREDITED',
  Autopilot = 'AUTOPILOT',
  Capital = 'CAPITAL',
  Crypto = 'CRYPTO',
  Event = 'EVENT',
  ExternalOffering = 'EXTERNAL_OFFERING',
  Investment = 'INVESTMENT',
  Investor = 'INVESTOR',
  Issuer = 'ISSUER',
  LandingPage = 'LANDING_PAGE',
  Offering = 'OFFERING',
  Referral = 'REFERRAL',
  Secondaries = 'SECONDARIES',
  Subscription = 'SUBSCRIPTION',
  Tag = 'TAG',
  User = 'USER',
}

/** Levels of capability */
export enum ICapabilityLevelEnum {
  Full = 'FULL',
  Manager = 'MANAGER',
  Support = 'SUPPORT',
}

export type ICapitalUserConnection = {
  __typename?: 'CapitalUserConnection';
  previousCount: Scalars['Int']['output'];
  currentCount: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  edges: Array<ICapitalUserTypeEdge>;
  pageInfo: IPageInfo;
};

/** Republic Capital user */
export type ICapitalUserType = IBasePayloadType & {
  __typename?: 'CapitalUserType';
  errors?: Maybe<Array<IValidationError>>;
  id: Scalars['Int']['output'];
  email: Scalars['String']['output'];
  signInCount: Scalars['Int']['output'];
  lastSignInAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  hasCapitalAccess?: Maybe<Scalars['Boolean']['output']>;
  existingRepublicUser?: Maybe<Scalars['Boolean']['output']>;
  boxFolderId?: Maybe<Scalars['String']['output']>;
  boxStructure?: Maybe<Scalars['String']['output']>;
};

export type ICapitalUserTypeEdge = {
  __typename?: 'CapitalUserTypeEdge';
  cursor: Scalars['String']['output'];
  node: ICapitalUserType;
};

/** Career object of careers domain */
export type ICareersCareer = {
  __typename?: 'CareersCareer';
  /** Optional closing content for the job posting (as styled HTML). This may be an empty string */
  additional?: Maybe<Scalars['String']['output']>;
  /** A URL which points to Lever's hosted application form to apply to the job posting */
  applyUrl: Scalars['Url']['output'];
  /**
   * Job location, commitment, team, department and level. Note: This field comes directly
   *                           from Lever API. It appears to be a plural object, but is in fact, a singular field.
   */
  categories: ICareersCategory;
  /** Job description (as styled HTML) */
  description: Scalars['String']['output'];
  /** Temporary field. Duplication of slug for Apolo caching */
  id: Scalars['String']['output'];
  /** Extra lists (such as requirements, benefits, etc.) from the job posting */
  perksAndRequirementsList: Array<ICareersPerksAndRequirementsList>;
  /** Unique job posting ID */
  slug: Scalars['String']['output'];
  /** Job posting name */
  text: Scalars['String']['output'];
};

/** Object with location, commitment, team, department and level */
export type ICareersCategory = {
  __typename?: 'CareersCategory';
  commitment?: Maybe<Scalars['String']['output']>;
  department?: Maybe<Scalars['String']['output']>;
  level?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  team?: Maybe<Scalars['String']['output']>;
};

/** Extra lists (such as requirements, benefits, etc.) from the career posting */
export type ICareersPerksAndRequirementsList = {
  __typename?: 'CareersPerksAndRequirementsList';
  content: Scalars['String']['output'];
  /** Category type (such as requirements, benefits, etc.) */
  text: Scalars['String']['output'];
};

export type ICareersResolvers = {
  /**
   * List of Careers with ability to filter the result scope
   * @deprecated GQL API is not used for fetching career options.
   *                               Deprecated unless it would be decided otherwise
   */
  careers: Array<ICareersCareer>;
};

export type ICareersResolversCareersArgs = {
  slugs?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ICarousel = INode & {
  __typename?: 'Carousel';
  bannerContents: Array<IBannerContent>;
  id: Scalars['ID']['output'];
  jurisdiction: Scalars['String']['output'];
  settings?: Maybe<Scalars['JSON']['output']>;
  slug: Scalars['String']['output'];
};

/** The connection type for Carousel. */
export type ICarouselConnection = {
  __typename?: 'CarouselConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ICarouselEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ICarousel>>>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
};

/** An edge in a connection. */
export type ICarouselEdge = {
  __typename?: 'CarouselEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ICarousel>;
};

/** Possible types of categories */
export type ICategoryUnion = ICap | ITagsTag;

export type IChangeRequest = {
  __typename?: 'ChangeRequest';
  id: Scalars['ID']['output'];
  changes: IChangeRequestChanges;
  status: ITransferAgentChangeRequestStatus;
  reason: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type IChangeRequestChanges = {
  __typename?: 'ChangeRequestChanges';
  fullName?: Maybe<Scalars['String']['output']>;
  nameDetails?: Maybe<INameDetails>;
  email?: Maybe<Scalars['String']['output']>;
  dateOfBirth?: Maybe<Scalars['ISO8601DateTime']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  legalAddress?: Maybe<ITransferAgentAddress>;
  mailingAddress?: Maybe<ITransferAgentAddress>;
};

export type IClosingSoonBanner = {
  __typename?: 'ClosingSoonBanner';
  /** The offering closing first */
  offeringClosingFirst: IOffering;
  /** The subset of offerings that are closing soon */
  offerings: Array<IOffering>;
  /** Time left until closing */
  timeLeft: Scalars['Int']['output'];
};

/** Valid error codes */
export enum ICodeEnum {
  BadUserInput = 'BAD_USER_INPUT',
  Forbidden = 'FORBIDDEN',
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  Unauthenticated = 'UNAUTHENTICATED',
  ElaStiCSearchUnreachable = 'ELA_STI_C_SEARCH_UNREACHABLE',
  InsufficientFunds = 'INSUFFICIENT_FUNDS',
  InvalidPaymentMethodType = 'INVALID_PAYMENT_METHOD_TYPE',
  MissingUserAttributes = 'MISSING_USER_ATTRIBUTES',
  NotTradable = 'NOT_TRADABLE',
  TokenExpired = 'TOKEN_EXPIRED',
  VersionNotSupported = 'VERSION_NOT_SUPPORTED',
}

export type ICognitoSettings = {
  __typename?: 'CognitoSettings';
  customerReference: Scalars['String']['output'];
  publishableKey?: Maybe<Scalars['String']['output']>;
  signature: Scalars['String']['output'];
  templateId?: Maybe<Scalars['String']['output']>;
  userEmail: Scalars['String']['output'];
};

/** Comment object of Comments domain */
export type IComment = ILikeableInterface &
  INode &
  IPostInterface & {
    __typename?: 'Comment';
    affiliation?: Maybe<Scalars['String']['output']>;
    amountInvested?: Maybe<Scalars['Int']['output']>;
    /** @deprecated Use amountInvested instead */
    amountInvestedByUser?: Maybe<Scalars['Int']['output']>;
    /** @deprecated Use xApprovedAt instead */
    approvedAt: Scalars['ISO8601DateTime']['output'];
    body: Scalars['String']['output'];
    buttonText?: Maybe<Scalars['String']['output']>;
    commentable: Scalars['Boolean']['output'];
    comments?: Maybe<Array<IComment>>;
    commentsCount: Scalars['Int']['output'];
    createdAt: Scalars['ISO8601DateTime']['output'];
    createdAtInteger?: Maybe<Scalars['Int']['output']>;
    defaultSelectedRole?: Maybe<Scalars['String']['output']>;
    deletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    displayAction: Scalars['String']['output'];
    displayType: Scalars['String']['output'];
    displayTypeWithPreposition: Scalars['String']['output'];
    downvoted: Scalars['Boolean']['output'];
    flaggedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    headline?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    inputPlaceholder: Scalars['String']['output'];
    isHelpful: Scalars['Boolean']['output'];
    isHelpfulAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    likeCount: Scalars['Int']['output'];
    /** @deprecated Use likedByUser instead */
    liked: Scalars['Boolean']['output'];
    likedByUser: Scalars['Boolean']['output'];
    /** @deprecated Use likeCount instead */
    likesCount?: Maybe<Scalars['Int']['output']>;
    markedAsSpamByUserId?: Maybe<Scalars['Int']['output']>;
    markedHiddenByUserId?: Maybe<Scalars['Int']['output']>;
    offeringRoleDisclosure: Scalars['String']['output'];
    offeringTeam: Scalars['Boolean']['output'];
    ownerId: Scalars['ID']['output'];
    ownerType: Scalars['String']['output'];
    pinned: Scalars['Boolean']['output'];
    pinnedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    possibleSpamAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    rating: Scalars['Int']['output'];
    role?: Maybe<ICommentRoleEnum>;
    roleForDisplay?: Maybe<Scalars['String']['output']>;
    showRole: Scalars['Boolean']['output'];
    showRoleSelect: Scalars['Boolean']['output'];
    slug: Scalars['String']['output'];
    spam?: Maybe<Scalars['Boolean']['output']>;
    state?: Maybe<Scalars['String']['output']>;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    upvoted: Scalars['Boolean']['output'];
    user: IUser;
    /** Permitted actions for Comment object */
    userPermissions: ICommentUserPermissions;
    xApprovedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  };

/** The connection type for Comment. */
export type ICommentConnection = {
  __typename?: 'CommentConnection';
  /** A list of edges. */
  edges: Array<ICommentEdge>;
  /** A list of nodes. */
  nodes: Array<IComment>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Autogenerated input type of CommentCreate */
export type ICommentCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  comment: ICommentInput;
  ownerId: Scalars['ID']['input'];
  ownerType: ICommentOwnerTypeEnum;
};

/** Autogenerated return type of CommentCreate. */
export type ICommentCreatePayload = {
  __typename?: 'CommentCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<IComment>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Autogenerated input type of CommentDelete */
export type ICommentDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
};

/** Autogenerated return type of CommentDelete. */
export type ICommentDeletePayload = {
  __typename?: 'CommentDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<IComment>;
  errors?: Maybe<Array<IValidationError>>;
};

/** An edge in a connection. */
export type ICommentEdge = {
  __typename?: 'CommentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: IComment;
};

export type ICommentInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  role?: InputMaybe<ICommentRoleEnum>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of CommentMarkAsHelpful */
export type ICommentMarkAsHelpfulInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of CommentMarkAsHelpful. */
export type ICommentMarkAsHelpfulPayload = {
  __typename?: 'CommentMarkAsHelpfulPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<IComment>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Autogenerated input type of CommentMarkAsNotHelpful */
export type ICommentMarkAsNotHelpfulInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of CommentMarkAsNotHelpful. */
export type ICommentMarkAsNotHelpfulPayload = {
  __typename?: 'CommentMarkAsNotHelpfulPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<IComment>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Autogenerated input type of CommentMarkNotSpam */
export type ICommentMarkNotSpamInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of CommentMarkNotSpam. */
export type ICommentMarkNotSpamPayload = {
  __typename?: 'CommentMarkNotSpamPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<IComment>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Autogenerated input type of CommentMarkSpam */
export type ICommentMarkSpamInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of CommentMarkSpam. */
export type ICommentMarkSpamPayload = {
  __typename?: 'CommentMarkSpamPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<IComment>;
  errors?: Maybe<Array<IValidationError>>;
  spamAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/** Autogenerated input type of CommentOfferingUpdateCommentCreate */
export type ICommentOfferingUpdateCommentCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  comment: ICommentInput;
  offeringUpdateSlug: Scalars['String']['input'];
};

/** Autogenerated return type of CommentOfferingUpdateCommentCreate. */
export type ICommentOfferingUpdateCommentCreatePayload = {
  __typename?: 'CommentOfferingUpdateCommentCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<IComment>;
  errors?: Maybe<Array<IValidationError>>;
};

/** List of available Order Enums of Comments domain */
export enum ICommentOrderEnum {
  ApprovedAtAsc = 'APPROVED_AT_ASC',
  ApprovedAtDesc = 'APPROVED_AT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  FlaggedAtAsc = 'FLAGGED_AT_ASC',
  FlaggedAtDesc = 'FLAGGED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsHelpfulAtAsc = 'IS_HELPFUL_AT_ASC',
  IsHelpfulAtDesc = 'IS_HELPFUL_AT_DESC',
  MarkedAsSpamByUserIdAsc = 'MARKED_AS_SPAM_BY_USER_ID_ASC',
  MarkedAsSpamByUserIdDesc = 'MARKED_AS_SPAM_BY_USER_ID_DESC',
  MarkedHiddenByUserIdAsc = 'MARKED_HIDDEN_BY_USER_ID_ASC',
  MarkedHiddenByUserIdDesc = 'MARKED_HIDDEN_BY_USER_ID_DESC',
  OwnerTypeAsc = 'OWNER_TYPE_ASC',
  OwnerTypeDesc = 'OWNER_TYPE_DESC',
  PinnedAtAsc = 'PINNED_AT_ASC',
  PinnedAtDesc = 'PINNED_AT_DESC',
  RoleAsc = 'ROLE_ASC',
  RoleDesc = 'ROLE_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
}

/** List of available Owner Type Enums of Comments domain */
export enum ICommentOwnerTypeEnum {
  InvestorGroup = 'INVESTOR_GROUP',
  JournalArticle = 'JOURNAL_ARTICLE',
  Offering = 'OFFERING',
  OfferingsUpdate = 'OFFERINGS_UPDATE',
  Post = 'POST',
  Press = 'PRESS',
}

/** Autogenerated input type of CommentReply */
export type ICommentReplyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  comment: ICommentInput;
  parentId: Scalars['ID']['input'];
};

/** Autogenerated return type of CommentReply. */
export type ICommentReplyPayload = {
  __typename?: 'CommentReplyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<IComment>;
  errors?: Maybe<Array<IValidationError>>;
};

/** List of available Roles Enums of Comments domain */
export enum ICommentRoleEnum {
  Affiliate = 'AFFILIATE',
  Employee = 'EMPLOYEE',
  Founder = 'FOUNDER',
  Investor = 'INVESTOR',
  Promoter = 'PROMOTER',
  Republic = 'REPUBLIC',
  Team = 'TEAM',
}

/** User permissions exposed to a client for Comment object of Comments domain */
export type ICommentUserPermissions = {
  __typename?: 'CommentUserPermissions';
  canCreate: Scalars['Boolean']['output'];
  canDeleteLinkPreview: Scalars['Boolean']['output'];
  canDestroy: Scalars['Boolean']['output'];
  canMarkAsHelpful: Scalars['Boolean']['output'];
  canMarkAsNotHelpful: Scalars['Boolean']['output'];
  canMarkFlagged: Scalars['Boolean']['output'];
  canMarkNotSpam: Scalars['Boolean']['output'];
  canMarkSpam: Scalars['Boolean']['output'];
  canReply: Scalars['Boolean']['output'];
  canShow: Scalars['Boolean']['output'];
  canShowHelpful: Scalars['Boolean']['output'];
  canUpdate: Scalars['Boolean']['output'];
};

/** Common Term object of Common Terms domain */
export type ICommonTerm = INode & {
  __typename?: 'CommonTerm';
  capitalR: Scalars['Boolean']['output'];
  checkbox: Scalars['Boolean']['output'];
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  fig: Scalars['Boolean']['output'];
  hostingEntity: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  nonSpvDeal: Scalars['Boolean']['output'];
  position: Scalars['Int']['output'];
  republicAdvisoryServices: Scalars['Boolean']['output'];
  republicCore: Scalars['Boolean']['output'];
  republicDealRoomAdvisor: Scalars['Boolean']['output'];
  republicFundingPortal: Scalars['Boolean']['output'];
  republicLabs: Scalars['Boolean']['output'];
  republicRealEstate: Scalars['Boolean']['output'];
  spvDeal: Scalars['Boolean']['output'];
  termType: Scalars['String']['output'];
  text: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Permitted actions for Common Term object */
  userPermissions: ICommonTermUserPermissions;
};

/** The connection type for CommonTerm. */
export type ICommonTermConnection = {
  __typename?: 'CommonTermConnection';
  /** A list of edges. */
  edges: Array<ICommonTermEdge>;
  /** A list of nodes. */
  nodes: Array<ICommonTerm>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Autogenerated input type of CommonTermCreate */
export type ICommonTermCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  commonTerm: ICommonTermInput;
};

/** Autogenerated return type of CommonTermCreate. */
export type ICommonTermCreatePayload = {
  __typename?: 'CommonTermCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  commonTerm?: Maybe<ICommonTerm>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Autogenerated input type of CommonTermDelete */
export type ICommonTermDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of CommonTermDelete. */
export type ICommonTermDeletePayload = {
  __typename?: 'CommonTermDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

/** An edge in a connection. */
export type ICommonTermEdge = {
  __typename?: 'CommonTermEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ICommonTerm;
};

export type ICommonTermInput = {
  capitalR?: InputMaybe<Scalars['Boolean']['input']>;
  checkbox?: InputMaybe<Scalars['Boolean']['input']>;
  fig?: InputMaybe<Scalars['Boolean']['input']>;
  hostingEntity?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  nonSpvDeal?: InputMaybe<Scalars['Boolean']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  republicAdvisoryServices?: InputMaybe<Scalars['Boolean']['input']>;
  republicCore?: InputMaybe<Scalars['Boolean']['input']>;
  republicDealRoomAdvisor?: InputMaybe<Scalars['Boolean']['input']>;
  republicFundingPortal?: InputMaybe<Scalars['Boolean']['input']>;
  republicLabs?: InputMaybe<Scalars['Boolean']['input']>;
  republicRealEstate?: InputMaybe<Scalars['Boolean']['input']>;
  spvDeal?: InputMaybe<Scalars['Boolean']['input']>;
  termType?: InputMaybe<ICommonTermTermTypeEnum>;
  text?: InputMaybe<Scalars['String']['input']>;
};

/** List of available Order Enums of Common Term domain */
export enum ICommonTermOrderEnum {
  CapitalRAsc = 'CAPITAL_R_ASC',
  CapitalRDesc = 'CAPITAL_R_DESC',
  CheckboxAsc = 'CHECKBOX_ASC',
  CheckboxDesc = 'CHECKBOX_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  FigAsc = 'FIG_ASC',
  FigDesc = 'FIG_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NonSpvDealAsc = 'NON_SPV_DEAL_ASC',
  NonSpvDealDesc = 'NON_SPV_DEAL_DESC',
  PositionAsc = 'POSITION_ASC',
  PositionDesc = 'POSITION_DESC',
  RepublicAdvisoryServicesAsc = 'REPUBLIC_ADVISORY_SERVICES_ASC',
  RepublicAdvisoryServicesDesc = 'REPUBLIC_ADVISORY_SERVICES_DESC',
  RepublicCoreAsc = 'REPUBLIC_CORE_ASC',
  RepublicCoreDesc = 'REPUBLIC_CORE_DESC',
  RepublicDealRoomAdvisorAsc = 'REPUBLIC_DEAL_ROOM_ADVISOR_ASC',
  RepublicDealRoomAdvisorDesc = 'REPUBLIC_DEAL_ROOM_ADVISOR_DESC',
  RepublicFundingPortalAsc = 'REPUBLIC_FUNDING_PORTAL_ASC',
  RepublicFundingPortalDesc = 'REPUBLIC_FUNDING_PORTAL_DESC',
  RepublicLabsAsc = 'REPUBLIC_LABS_ASC',
  RepublicLabsDesc = 'REPUBLIC_LABS_DESC',
  RepublicRealEstateAsc = 'REPUBLIC_REAL_ESTATE_ASC',
  RepublicRealEstateDesc = 'REPUBLIC_REAL_ESTATE_DESC',
  SpvDealAsc = 'SPV_DEAL_ASC',
  SpvDealDesc = 'SPV_DEAL_DESC',
  TermTypeAsc = 'TERM_TYPE_ASC',
  TermTypeDesc = 'TERM_TYPE_DESC',
  TextAsc = 'TEXT_ASC',
  TextDesc = 'TEXT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
}

/** List of available Common Term Type Enums of Common Term domain */
export enum ICommonTermTermTypeEnum {
  RegulationAPlus = 'REGULATION_A_PLUS',
  RegulationCf = 'REGULATION_CF',
  RegulationD_504 = 'REGULATION_D_504',
  RegulationD_506B = 'REGULATION_D_506_B',
  RegulationD_506C = 'REGULATION_D_506_C',
  RegulationS = 'REGULATION_S',
}

/** Autogenerated input type of CommonTermUpdate */
export type ICommonTermUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  commonTerm: ICommonTermInput;
};

/** Autogenerated return type of CommonTermUpdate. */
export type ICommonTermUpdatePayload = {
  __typename?: 'CommonTermUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  commonTerm?: Maybe<ICommonTerm>;
  errors?: Maybe<Array<IValidationError>>;
};

/** User permissions exposed to a client for Common Term object of Common Term domain */
export type ICommonTermUserPermissions = {
  __typename?: 'CommonTermUserPermissions';
  canCreate: Scalars['Boolean']['output'];
  canDestroy: Scalars['Boolean']['output'];
  canShow: Scalars['Boolean']['output'];
  canUpdate: Scalars['Boolean']['output'];
};

export type IConfirmEmailBanner = {
  __typename?: 'ConfirmEmailBanner';
  user: IUser;
};

/** Input paramaters for crypto landing asset management contact form */
export type IContactFormAssetManagementEmailInput = {
  additionalCommunications: Scalars['Boolean']['input'];
  company?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  investorStatus: IContactFormInvestorStatusEnum;
  investorType?: InputMaybe<IContactFormInvestorTypeEnum>;
  lastName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  recaptcha: Scalars['String']['input'];
  targetEmail: IContactFormTargetEmailEnum;
};

/** Autogenerated input type of ContactFormAssetManagementEmailSend */
export type IContactFormAssetManagementEmailSendInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  emailParameters: IContactFormAssetManagementEmailInput;
};

/** Autogenerated return type of ContactFormAssetManagementEmailSend. */
export type IContactFormAssetManagementEmailSendPayload = {
  __typename?: 'ContactFormAssetManagementEmailSendPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Input paramaters for crypto landing contact form */
export type IContactFormEmailInput = {
  companyName?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  message: Scalars['String']['input'];
  origin?: InputMaybe<Scalars['String']['input']>;
  recaptcha: Scalars['String']['input'];
  targetEmail: IContactFormTargetEmailEnum;
};

/** Autogenerated input type of ContactFormEmailSend */
export type IContactFormEmailSendInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  emailParameters: IContactFormEmailInput;
};

/** Autogenerated return type of ContactFormEmailSend. */
export type IContactFormEmailSendPayload = {
  __typename?: 'ContactFormEmailSendPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

/** List of Investor Statuses */
export enum IContactFormInvestorStatusEnum {
  AccreditedInvestor = 'ACCREDITED_INVESTOR',
  NonAccredited = 'NON_ACCREDITED',
  ProfessionalInvestor = 'PROFESSIONAL_INVESTOR',
  QualifiedPurchaser = 'QUALIFIED_PURCHASER',
}

/** List of Investor Types */
export enum IContactFormInvestorTypeEnum {
  Endowment = 'ENDOWMENT',
  FamilyOffice = 'FAMILY_OFFICE',
  FundOfFunds = 'FUND_OF_FUNDS',
  HedgeVentureFund = 'HEDGE_VENTURE_FUND',
  HighNetWorthIndividual = 'HIGH_NET_WORTH_INDIVIDUAL',
  MultiFamilyOffice = 'MULTI_FAMILY_OFFICE',
  Other = 'OTHER',
  Pension = 'PENSION',
  RegisteredInvestmentAdvisor = 'REGISTERED_INVESTMENT_ADVISOR',
  SoveriegnWealthFund = 'SOVERIEGN_WEALTH_FUND',
}

/** List of internal target email addresses to select from */
export enum IContactFormTargetEmailEnum {
  AssetManagement = 'ASSET_MANAGEMENT',
  BlockchainInfrastructure = 'BLOCKCHAIN_INFRASTRUCTURE',
  GeneralContact = 'GENERAL_CONTACT',
  MediaInquiry = 'MEDIA_INQUIRY',
  NftStrategy = 'NFT_STRATEGY',
  Tokenization = 'TOKENIZATION',
  Tokenomics = 'TOKENOMICS',
  TokenOfferings = 'TOKEN_OFFERINGS',
  Web3Advisory = 'WEB3_ADVISORY',
}

export type IContext = {
  __typename?: 'Context';
  achAccountNumber?: Maybe<Scalars['String']['output']>;
  achRoutingNumber?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  locked?: Maybe<Scalars['String']['output']>;
  lockedAt?: Maybe<Scalars['String']['output']>;
  lockedReason?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  offerings?: Maybe<IMediciOfferingConnection>;
  totalAvailableBalance?: Maybe<Scalars['Int']['output']>;
  totalCurrentBalance?: Maybe<Scalars['Int']['output']>;
  treasuryPrimeAccountApplications?: Maybe<ITreasuryPrimeAccountApplicationConnection>;
  treasuryPrimeAccounts?: Maybe<ITreasuryPrimeAccountConnection>;
  treasuryPrimeTransactions?: Maybe<ITreasuryPrimeTransactionConnection>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['String']['output'];
  wireAccountNumber?: Maybe<Scalars['String']['output']>;
  wireRoutingNumber?: Maybe<Scalars['String']['output']>;
};

export type IContextOfferingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IContextTreasuryPrimeAccountApplicationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IContextTreasuryPrimeAccountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IContextTreasuryPrimeTransactionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ICountry = INode & {
  __typename?: 'Country';
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** Crypto Address Book object of Core domain */
export type ICryptoAddressBook = {
  __typename?: 'CryptoAddressBook';
  address: Scalars['String']['output'];
  blockchainId?: Maybe<Scalars['String']['output']>;
  blockchainName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  republicWallet: Scalars['Boolean']['output'];
  walletTokens?: Maybe<Array<ICryptoWalletTokenType>>;
};

/** Autogenerated input type of CryptoBlockchainRuleSetCreate */
export type ICryptoBlockchainRuleSetCreateInput = {
  blockchainRuleSet: IBlockchainRuleSetInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CryptoBlockchainRuleSetCreate. */
export type ICryptoBlockchainRuleSetCreatePayload = {
  __typename?: 'CryptoBlockchainRuleSetCreatePayload';
  blockchainRuleSet?: Maybe<ICryptoBlockchainRuleSetType>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Autogenerated input type of CryptoBlockchainRuleSetDelete */
export type ICryptoBlockchainRuleSetDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of CryptoBlockchainRuleSetDelete. */
export type ICryptoBlockchainRuleSetDeletePayload = {
  __typename?: 'CryptoBlockchainRuleSetDeletePayload';
  blockchainRuleSet?: Maybe<ICryptoBlockchainRuleSetType>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Get native token symbol and balance */
export type ICryptoBlockchainRuleSetNativeTokenBalance = {
  __typename?: 'CryptoBlockchainRuleSetNativeTokenBalance';
  errorMessage?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  nativeTokenBalance: Scalars['Float']['output'];
  nativeTokenSymbol: Scalars['String']['output'];
};

/** List of available orders for CryptoBlockchainRuleSet Index query of Core domain */
export enum ICryptoBlockchainRuleSetOrderEnum {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  MaxAsc = 'MAX_ASC',
  MaxDesc = 'MAX_DESC',
  MinAsc = 'MIN_ASC',
  MinDesc = 'MIN_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
}

/** CryptoBlockchainRuleSet object of Core domain */
export type ICryptoBlockchainRuleSetType = {
  __typename?: 'CryptoBlockchainRuleSetType';
  charactersErrorMessage?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAtForDisplay?: Maybe<Scalars['String']['output']>;
  cryptoTokens?: Maybe<Array<ICryptoTokensType>>;
  id: Scalars['ID']['output'];
  logoUrl?: Maybe<Scalars['String']['output']>;
  max?: Maybe<Scalars['Int']['output']>;
  min?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nativeCryptoToken?: Maybe<ICryptoTokensType>;
  prefixNotAllowed?: Maybe<Scalars['String']['output']>;
  prefixNotAllowedErrorMessage?: Maybe<Scalars['String']['output']>;
  prefixRequired?: Maybe<Scalars['String']['output']>;
  prefixRequiredErrorMessage?: Maybe<Scalars['String']['output']>;
  regex?: Maybe<Scalars['String']['output']>;
  regexErrorMessage?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  updatedAtForDisplay?: Maybe<Scalars['String']['output']>;
};

/** The connection type for CryptoBlockchainRuleSetType. */
export type ICryptoBlockchainRuleSetTypeConnection = {
  __typename?: 'CryptoBlockchainRuleSetTypeConnection';
  /** A list of edges. */
  edges: Array<ICryptoBlockchainRuleSetTypeEdge>;
  /** A list of nodes. */
  nodes: Array<ICryptoBlockchainRuleSetType>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ICryptoBlockchainRuleSetTypeEdge = {
  __typename?: 'CryptoBlockchainRuleSetTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ICryptoBlockchainRuleSetType;
};

/** Autogenerated input type of CryptoBlockchainRuleSetUpdate */
export type ICryptoBlockchainRuleSetUpdateInput = {
  blockchainRuleSet: IBlockchainRuleSetInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CryptoBlockchainRuleSetUpdate. */
export type ICryptoBlockchainRuleSetUpdatePayload = {
  __typename?: 'CryptoBlockchainRuleSetUpdatePayload';
  blockchainRuleSet?: Maybe<ICryptoBlockchainRuleSetType>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Validate Address on Crypto Blockchain object of Core domain */
export type ICryptoBlockchainRuleSetValidateAddress = {
  __typename?: 'CryptoBlockchainRuleSetValidateAddress';
  errorMessage?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  valid: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CryptoCreateInvestmentToken */
export type ICryptoCreateInvestmentTokenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  investmentId: Scalars['ID']['input'];
};

/** Autogenerated return type of CryptoCreateInvestmentToken. */
export type ICryptoCreateInvestmentTokenPayload = {
  __typename?: 'CryptoCreateInvestmentTokenPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Records of tokens due, along with whether they have been distributed */
export type ICryptoDistribution = INode & {
  __typename?: 'CryptoDistribution';
  address?: Maybe<Scalars['String']['output']>;
  amount?: Maybe<Scalars['BigDecimal']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  cryptoToken?: Maybe<ICryptoTokensType>;
  cryptoTokenId?: Maybe<Scalars['ID']['output']>;
  cryptoTokenName?: Maybe<Scalars['String']['output']>;
  cryptoTokenSymbol?: Maybe<Scalars['String']['output']>;
  distributedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  distributionType: ICryptoDistributionTypeEnum;
  email?: Maybe<Scalars['String']['output']>;
  entityName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  instrumentDate?: Maybe<Scalars['ISO8601Date']['output']>;
  investorProfile?: Maybe<IInvestorProfile>;
  /** Meta information about Agreement(s). Enabled only for Republic Note crypto tokens */
  joinderAgreementMeta?: Maybe<ICryptoDistributionJoinderAgreementMeta>;
  offeringSlug?: Maybe<Scalars['String']['output']>;
  securityInstrument?: Maybe<ICryptoDistributionInstrumentTypeEnum>;
  slug?: Maybe<Scalars['String']['output']>;
  state: ICryptoDistributionStateEnum;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/** The connection type for CryptoDistribution. */
export type ICryptoDistributionConnection = {
  __typename?: 'CryptoDistributionConnection';
  /** A list of edges. */
  edges: Array<ICryptoDistributionEdge>;
  /** A list of nodes. */
  nodes: Array<ICryptoDistribution>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Autogenerated input type of CryptoDistributionCreate */
export type ICryptoDistributionCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  cryptoDistributions: Array<ICryptoDistributionInput>;
};

/** Autogenerated return type of CryptoDistributionCreate. */
export type ICryptoDistributionCreatePayload = {
  __typename?: 'CryptoDistributionCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  cryptoDistributions?: Maybe<Array<ICryptoDistribution>>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
};

/** An edge in a connection. */
export type ICryptoDistributionEdge = {
  __typename?: 'CryptoDistributionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ICryptoDistribution;
};

/** CryptoDistributions Input for Core Domain */
export type ICryptoDistributionInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  blockchain?: InputMaybe<Scalars['String']['input']>;
  cryptoTokenId?: InputMaybe<Scalars['ID']['input']>;
  distributionType?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  entityName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  instrumentDate?: InputMaybe<Scalars['String']['input']>;
  /** Investment ID for template */
  jaInvestmentId?: InputMaybe<Scalars['ID']['input']>;
  /** Template to Sign by Investor for Joinder Agreement */
  jaTemplate?: InputMaybe<ICryptoDistributionJoinderAgreementTemplateEnum>;
  offeringSlug?: InputMaybe<Scalars['String']['input']>;
  securityInstrument?: InputMaybe<ICryptoDistributionInstrumentTypeEnum>;
  state?: InputMaybe<Scalars['String']['input']>;
  symbol?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** List of potential instrument types for Distributions */
export enum ICryptoDistributionInstrumentTypeEnum {
  Na = 'NA',
  Sa = 'SA',
  Safest = 'SAFEST',
  Saft = 'SAFT',
  Tpa = 'TPA',
}

/** Meta information about Joinder Agreement */
export type ICryptoDistributionJoinderAgreementMeta = {
  __typename?: 'CryptoDistributionJoinderAgreementMeta';
  fullySigned?: Maybe<Scalars['Boolean']['output']>;
  joinderAgreementSignedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  template?: Maybe<ICryptoDistributionJoinderAgreementTemplate>;
};

/** Template information for Joinder Agreement Template */
export type ICryptoDistributionJoinderAgreementTemplate = {
  __typename?: 'CryptoDistributionJoinderAgreementTemplate';
  email?: Maybe<Scalars['String']['output']>;
  instrumentDate?: Maybe<Scalars['ISO8601Date']['output']>;
  instrumentType?: Maybe<ICryptoDistributionInstrumentTypeEnum>;
  investmentId?: Maybe<Scalars['ID']['output']>;
  kind?: Maybe<ICryptoDistributionJoinderAgreementTemplateEnum>;
  name?: Maybe<Scalars['String']['output']>;
};

/** List of required agreement templates for JoinderAgreement of Core domain */
export enum ICryptoDistributionJoinderAgreementTemplateEnum {
  GranteeEntity = 'GRANTEE_ENTITY',
  GranteeIndividual = 'GRANTEE_INDIVIDUAL',
  InvestorEntity = 'INVESTOR_ENTITY',
  InvestorIndividual = 'INVESTOR_INDIVIDUAL',
  NewEntity = 'NEW_ENTITY',
  NewIndividual = 'NEW_INDIVIDUAL',
  OldEntity = 'OLD_ENTITY',
  OldIndividual = 'OLD_INDIVIDUAL',
}

/** List of available orders for CryptoDistribution Index query of Core domain */
export enum ICryptoDistributionOrderEnum {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
}

/** Autogenerated input type of CryptoDistributionSignJoinderAgreement */
export type ICryptoDistributionSignJoinderAgreementInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  distributionId: Scalars['ID']['input'];
};

/** Autogenerated return type of CryptoDistributionSignJoinderAgreement. */
export type ICryptoDistributionSignJoinderAgreementPayload = {
  __typename?: 'CryptoDistributionSignJoinderAgreementPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  cryptoDistribution?: Maybe<ICryptoDistribution>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Valid states for Crypto Distributions */
export enum ICryptoDistributionStateEnum {
  Completed = 'COMPLETED',
  Pending = 'PENDING',
  PendingWalletSetup = 'PENDING_WALLET_SETUP',
}

/** Valid types for Crypto Distributions */
export enum ICryptoDistributionTypeEnum {
  ExternalInvestment = 'EXTERNAL_INVESTMENT',
  Grant = 'GRANT',
  RepublicInvestment = 'REPUBLIC_INVESTMENT',
  Sharedrop = 'SHAREDROP',
}

/** Autogenerated input type of CryptoDistributionUpdateJoinderAgreement */
export type ICryptoDistributionUpdateJoinderAgreementInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  distributionParams: ICryptoDistributionInput;
};

/** Autogenerated return type of CryptoDistributionUpdateJoinderAgreement. */
export type ICryptoDistributionUpdateJoinderAgreementPayload = {
  __typename?: 'CryptoDistributionUpdateJoinderAgreementPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  cryptoDistribution?: Maybe<ICryptoDistribution>;
  errors?: Maybe<Scalars['String']['output']>;
};

/** CryptoEncryptedChallenge string for user authentication by seed phrase */
export type ICryptoEncryptedChallengeType = {
  __typename?: 'CryptoEncryptedChallengeType';
  encryptedChallenge?: Maybe<Scalars['String']['output']>;
  existingUser: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
};

/** Autogenerated input type of CryptoInvestmentOptionCreate */
export type ICryptoInvestmentOptionCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  investmentOption: ICryptoInvestmentOptionInput;
};

/** Autogenerated return type of CryptoInvestmentOptionCreate. */
export type ICryptoInvestmentOptionCreatePayload = {
  __typename?: 'CryptoInvestmentOptionCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  investmentOption?: Maybe<ICryptoInvestmentOptionType>;
};

/** Autogenerated input type of CryptoInvestmentOptionDelete */
export type ICryptoInvestmentOptionDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of CryptoInvestmentOptionDelete. */
export type ICryptoInvestmentOptionDeletePayload = {
  __typename?: 'CryptoInvestmentOptionDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  investmentOption?: Maybe<ICryptoInvestmentOptionType>;
};

/** Crypto Investment Option Input for Core Domain */
export type ICryptoInvestmentOptionInput = {
  decimalPlacesForSale?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  maxTokensForSale?: InputMaybe<Scalars['BigDecimal']['input']>;
  offeringId?: InputMaybe<Scalars['ID']['input']>;
  pricePerToken: Scalars['Float']['input'];
  pricePerTokenCents?: InputMaybe<Scalars['BigDecimal']['input']>;
  reservedPercent?: InputMaybe<Scalars['Int']['input']>;
  soldOut?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** CryptoInvestmentOption object of Core domain */
export type ICryptoInvestmentOptionType = {
  __typename?: 'CryptoInvestmentOptionType';
  createdAt: Scalars['ISO8601DateTime']['output'];
  decimalPlacesForSale: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  formattedAvailableTokensInclReserved: Scalars['String']['output'];
  formattedAvailableTokensNumber: Scalars['String']['output'];
  formattedPricePerToken?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  investments?: Maybe<Array<IInvestment>>;
  lockupUntil?: Maybe<Scalars['ISO8601DateTime']['output']>;
  maxTokensForSale?: Maybe<Scalars['BigDecimal']['output']>;
  offering: IOffering;
  pricePerToken?: Maybe<Scalars['Float']['output']>;
  pricePerTokenCents: Scalars['BigDecimal']['output'];
  reservedPercent?: Maybe<Scalars['Int']['output']>;
  soldOut?: Maybe<Scalars['Boolean']['output']>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for CryptoInvestmentOptionType. */
export type ICryptoInvestmentOptionTypeConnection = {
  __typename?: 'CryptoInvestmentOptionTypeConnection';
  /** A list of edges. */
  edges: Array<ICryptoInvestmentOptionTypeEdge>;
  /** A list of nodes. */
  nodes: Array<ICryptoInvestmentOptionType>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ICryptoInvestmentOptionTypeEdge = {
  __typename?: 'CryptoInvestmentOptionTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ICryptoInvestmentOptionType;
};

/** Autogenerated input type of CryptoInvestmentOptionUpdate */
export type ICryptoInvestmentOptionUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  investmentOption: ICryptoInvestmentOptionInput;
};

/** Autogenerated return type of CryptoInvestmentOptionUpdate. */
export type ICryptoInvestmentOptionUpdatePayload = {
  __typename?: 'CryptoInvestmentOptionUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  investmentOption?: Maybe<ICryptoInvestmentOptionType>;
};

/** Crypto Multisig Account object of Core domain */
export type ICryptoMultisigAccount = INode & {
  __typename?: 'CryptoMultisigAccount';
  accountCreator: IUser;
  actionsRequiredCount?: Maybe<Scalars['Int']['output']>;
  allInvitesAccepted: Scalars['Boolean']['output'];
  availableWallabyAssets?: Maybe<Array<ICryptoTokensType>>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  creatorJoinDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  cryptoTokens?: Maybe<Array<ICryptoTokensType>>;
  cryptoWalletTokens?: Maybe<Array<ICryptoWalletTokenType>>;
  id: Scalars['ID']['output'];
  invitedJoinDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  nickname: Scalars['String']['output'];
  requiredSignatures: Scalars['Int']['output'];
  signerDetails?: Maybe<Array<ICryptoMultisigAccountSigner>>;
  signers?: Maybe<Array<IInvestorProfile>>;
  status: ICryptoMultisigAccountStatusEnum;
  totalSigners: Scalars['Int']['output'];
  transactionSignatures: Array<ITransactionSignatureType>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/** Multisig Address object of Core domain */
export type ICryptoMultisigAccountAddress = {
  __typename?: 'CryptoMultisigAccountAddress';
  creatorAddress: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  participants: Array<Scalars['String']['output']>;
};

/** The connection type for CryptoMultisigAccount. */
export type ICryptoMultisigAccountConnection = {
  __typename?: 'CryptoMultisigAccountConnection';
  /** A list of edges. */
  edges: Array<ICryptoMultisigAccountEdge>;
  /** A list of nodes. */
  nodes: Array<ICryptoMultisigAccount>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Autogenerated input type of CryptoMultisigAccountCreate */
export type ICryptoMultisigAccountCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  multisigAccount: ICryptoMultisigAccountInput;
};

/** Autogenerated return type of CryptoMultisigAccountCreate. */
export type ICryptoMultisigAccountCreatePayload = {
  __typename?: 'CryptoMultisigAccountCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  multisigAccount?: Maybe<ICryptoMultisigAccount>;
};

/** Autogenerated input type of CryptoMultisigAccountDelete */
export type ICryptoMultisigAccountDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of CryptoMultisigAccountDelete. */
export type ICryptoMultisigAccountDeletePayload = {
  __typename?: 'CryptoMultisigAccountDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  multisigAccount?: Maybe<ICryptoMultisigAccount>;
};

/** An edge in a connection. */
export type ICryptoMultisigAccountEdge = {
  __typename?: 'CryptoMultisigAccountEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ICryptoMultisigAccount;
};

/** Crypto Multisig Account Input for Core Domain */
export type ICryptoMultisigAccountInput = {
  accountCreatorUserId: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  requiredSignatures?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<ICryptoMultisigAccountStatusEnum>;
  totalSigners?: InputMaybe<Scalars['Int']['input']>;
};

/** Crypto Multisig Account Signer object of Core domain */
export type ICryptoMultisigAccountSigner = INode & {
  __typename?: 'CryptoMultisigAccountSigner';
  createdAt: Scalars['ISO8601DateTime']['output'];
  cryptoMultisigAccount?: Maybe<ICryptoMultisigAccount>;
  id: Scalars['ID']['output'];
  investorProfile?: Maybe<IInvestorProfile>;
  investorProfileId?: Maybe<Scalars['String']['output']>;
  inviteStatus: ICryptoMultisigAccountSignerStatusEnum;
  isCurrentUser: Scalars['Boolean']['output'];
  joinedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  signerAlgorandAddress?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  wallabyUserId: Scalars['String']['output'];
};

/** The connection type for CryptoMultisigAccountSigner. */
export type ICryptoMultisigAccountSignerConnection = {
  __typename?: 'CryptoMultisigAccountSignerConnection';
  /** A list of edges. */
  edges: Array<ICryptoMultisigAccountSignerEdge>;
  /** A list of nodes. */
  nodes: Array<ICryptoMultisigAccountSigner>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Autogenerated input type of CryptoMultisigAccountSignerCreate */
export type ICryptoMultisigAccountSignerCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  multisigAccountSigner: ICryptoMultisigAccountSignerInput;
};

/** Autogenerated return type of CryptoMultisigAccountSignerCreate. */
export type ICryptoMultisigAccountSignerCreatePayload = {
  __typename?: 'CryptoMultisigAccountSignerCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  multisigAccountSigner?: Maybe<ICryptoMultisigAccountSigner>;
};

/** Autogenerated input type of CryptoMultisigAccountSignerDelete */
export type ICryptoMultisigAccountSignerDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of CryptoMultisigAccountSignerDelete. */
export type ICryptoMultisigAccountSignerDeletePayload = {
  __typename?: 'CryptoMultisigAccountSignerDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  multisigAccountSigner?: Maybe<ICryptoMultisigAccountSigner>;
};

/** An edge in a connection. */
export type ICryptoMultisigAccountSignerEdge = {
  __typename?: 'CryptoMultisigAccountSignerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ICryptoMultisigAccountSigner;
};

/** Crypto Multisig Account Invite Email object of Core domain */
export type ICryptoMultisigAccountSignerEmail = {
  __typename?: 'CryptoMultisigAccountSignerEmail';
  id: Scalars['ID']['output'];
  value: Scalars['Email']['output'];
};

/** Crypto Multisig Account Signer Input for Core Domain */
export type ICryptoMultisigAccountSignerInput = {
  cryptoMultisigAccountId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  investorProfileId?: InputMaybe<Scalars['Int']['input']>;
  inviteStatus?: InputMaybe<ICryptoMultisigAccountSignerStatusEnum>;
  joinedAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};

/** List of available Crypto Multisig Account Signer Status Enums of Core domain */
export enum ICryptoMultisigAccountSignerStatusEnum {
  InviteAccepted = 'INVITE_ACCEPTED',
  InviteNotSent = 'INVITE_NOT_SENT',
  InviteSent = 'INVITE_SENT',
}

/** Autogenerated input type of CryptoMultisigAccountSignerUpdate */
export type ICryptoMultisigAccountSignerUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currentMultisigAccountSigner: ICryptoMultisigAccountSignerInput;
};

/** Autogenerated return type of CryptoMultisigAccountSignerUpdate. */
export type ICryptoMultisigAccountSignerUpdatePayload = {
  __typename?: 'CryptoMultisigAccountSignerUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  currentMultisigAccountSigner?: Maybe<ICryptoMultisigAccountSigner>;
  errors?: Maybe<Array<IValidationError>>;
};

/** List of available Crypto Multisig Account Status Enums of Core domain */
export enum ICryptoMultisigAccountStatusEnum {
  Created = 'CREATED',
  InvitesNotSent = 'INVITES_NOT_SENT',
  InvitesPending = 'INVITES_PENDING',
}

/** Autogenerated input type of CryptoMultisigAccountUpdate */
export type ICryptoMultisigAccountUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  multisigAccount: ICryptoMultisigAccountInput;
};

/** Autogenerated return type of CryptoMultisigAccountUpdate. */
export type ICryptoMultisigAccountUpdatePayload = {
  __typename?: 'CryptoMultisigAccountUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  multisigAccount?: Maybe<ICryptoMultisigAccount>;
};

/** Autogenerated input type of CryptoSetPublicKey */
export type ICryptoSetPublicKeyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  publicKey: Scalars['String']['input'];
};

/** Autogenerated return type of CryptoSetPublicKey. */
export type ICryptoSetPublicKeyPayload = {
  __typename?: 'CryptoSetPublicKeyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  user?: Maybe<IUser>;
};

/** CryptoTokenAddress object of Core domain */
export type ICryptoTokenAddress = INode & {
  __typename?: 'CryptoTokenAddress';
  address: Scalars['String']['output'];
  addressEditingEnabled?: Maybe<Scalars['Boolean']['output']>;
  addressType: ICryptoTokenAddressAddressTypeEnum;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  cryptoWalletTokenId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  primaryAddress: Scalars['Boolean']['output'];
  receiverTransactions?: Maybe<Array<ICryptoTransaction>>;
  senderTransactions?: Maybe<Array<ICryptoTransaction>>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  walletToken?: Maybe<ICryptoWalletTokenType>;
};

/** List of available Crypto Token Addresses Address Type Enums of Core domain */
export enum ICryptoTokenAddressAddressTypeEnum {
  Default = 'DEFAULT',
  Legacy = 'LEGACY',
  Segwit = 'SEGWIT',
}

/** The connection type for CryptoTokenAddress. */
export type ICryptoTokenAddressConnection = {
  __typename?: 'CryptoTokenAddressConnection';
  /** A list of edges. */
  edges: Array<ICryptoTokenAddressEdge>;
  /** A list of nodes. */
  nodes: Array<ICryptoTokenAddress>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Autogenerated input type of CryptoTokenAddressCreate */
export type ICryptoTokenAddressCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  tokenAddress: ICryptoTokenAddressInput;
};

/** Autogenerated return type of CryptoTokenAddressCreate. */
export type ICryptoTokenAddressCreatePayload = {
  __typename?: 'CryptoTokenAddressCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  tokenAddress?: Maybe<ICryptoTokenAddress>;
};

/** Autogenerated input type of CryptoTokenAddressDelete */
export type ICryptoTokenAddressDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of CryptoTokenAddressDelete. */
export type ICryptoTokenAddressDeletePayload = {
  __typename?: 'CryptoTokenAddressDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  tokenAddress?: Maybe<ICryptoTokenAddress>;
};

/** An edge in a connection. */
export type ICryptoTokenAddressEdge = {
  __typename?: 'CryptoTokenAddressEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ICryptoTokenAddress;
};

/** Crypto Token Address Input for Code domain */
export type ICryptoTokenAddressInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  addressType?: InputMaybe<ICryptoTokenAddressAddressTypeEnum>;
  airdropInvestmentId?: InputMaybe<Scalars['ID']['input']>;
  cryptoWalletTokenId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  investmentId?: InputMaybe<Scalars['ID']['input']>;
  primaryAddress: Scalars['Boolean']['input'];
};

/** Autogenerated input type of CryptoTokenAddressUpdate */
export type ICryptoTokenAddressUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  tokenAddress: ICryptoTokenAddressInput;
};

/** Autogenerated return type of CryptoTokenAddressUpdate. */
export type ICryptoTokenAddressUpdatePayload = {
  __typename?: 'CryptoTokenAddressUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  tokenAddress?: Maybe<ICryptoTokenAddress>;
};

/** Autogenerated input type of CryptoTokenCreate */
export type ICryptoTokenCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  cryptoToken: ICryptoTokenInput;
};

/** Autogenerated return type of CryptoTokenCreate. */
export type ICryptoTokenCreatePayload = {
  __typename?: 'CryptoTokenCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  cryptoToken?: Maybe<ICryptoTokensType>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Autogenerated input type of CryptoTokenDelete */
export type ICryptoTokenDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  offeringId: Scalars['ID']['input'];
};

/** Autogenerated return type of CryptoTokenDelete. */
export type ICryptoTokenDeletePayload = {
  __typename?: 'CryptoTokenDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  cryptoToken?: Maybe<ICryptoTokensType>;
  errors?: Maybe<Array<IValidationError>>;
};

/** CryptoTokens Input for Core Domain */
export type ICryptoTokenInput = {
  associatedTokens?: InputMaybe<Array<ICryptoTokenInput>>;
  associatedTokensTouched?: InputMaybe<Scalars['Boolean']['input']>;
  blockchain?: InputMaybe<Scalars['String']['input']>;
  blockchainRuleSetId?: InputMaybe<Scalars['ID']['input']>;
  blockchainTokenId?: InputMaybe<Scalars['String']['input']>;
  comakeryIntegrationProjectId?: InputMaybe<Scalars['String']['input']>;
  decimalPlaces?: InputMaybe<Scalars['Int']['input']>;
  externalWalletUrl?: InputMaybe<Scalars['Url']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  logoUrl?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offeringId?: InputMaybe<Scalars['ID']['input']>;
  optinRequired?: InputMaybe<Scalars['Boolean']['input']>;
  symbol?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Use xTokenStandard instead */
  tokenStandard?: InputMaybe<ICryptoTokenTokenStandardEnum>;
  tokenType?: InputMaybe<ICryptoTokenTokenTypeEnum>;
  tutorialLink?: InputMaybe<Scalars['Url']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
  wallabyAssetId?: InputMaybe<Scalars['String']['input']>;
  walletSource?: InputMaybe<ICryptoTokenWalletSourceEnum>;
  xTokenStandard?: InputMaybe<Scalars['String']['input']>;
};

/** List of available orders for CryptoToken Index query of Core domain */
export enum ICryptoTokenOrderEnum {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
}

/** CryptoTokens object of Core domain */
export type ICryptoTokensType = {
  __typename?: 'CryptoTokensType';
  associatedTokens?: Maybe<Array<ICryptoTokensType>>;
  blockchainId?: Maybe<Scalars['String']['output']>;
  blockchainLogoUrl?: Maybe<Scalars['Url']['output']>;
  blockchainRuleSet?: Maybe<ICryptoBlockchainRuleSetType>;
  blockchainTokenId?: Maybe<Scalars['String']['output']>;
  blockchainTooltipContent?: Maybe<Scalars['String']['output']>;
  comakeryIntegrationProjectId?: Maybe<Scalars['String']['output']>;
  contractAddress?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  decimalPlaces?: Maybe<Scalars['Int']['output']>;
  externalWalletUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isFormEditable?: Maybe<Scalars['Boolean']['output']>;
  lockedBalancesTooltipContent?: Maybe<Scalars['String']['output']>;
  logoUrl?: Maybe<Scalars['Url']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nativeToken?: Maybe<ICryptoTokensType>;
  offerings?: Maybe<Array<IOffering>>;
  offeringsCount?: Maybe<Scalars['String']['output']>;
  optinRequired?: Maybe<Scalars['Boolean']['output']>;
  symbol?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use xTokenStandard instead */
  tokenStandard?: Maybe<ICryptoTokenTokenStandardEnum>;
  tokenType?: Maybe<ICryptoTokenTokenTypeEnum>;
  tutorialLink?: Maybe<Scalars['Url']['output']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  visible?: Maybe<Scalars['Boolean']['output']>;
  wallabyAssetId?: Maybe<Scalars['String']['output']>;
  walletDisclaimers?: Maybe<Array<ICryptoWalletDisclaimerType>>;
  walletSource?: Maybe<ICryptoTokenWalletSourceEnum>;
  xTokenStandard?: Maybe<Scalars['String']['output']>;
};

/** The connection type for CryptoTokensType. */
export type ICryptoTokensTypeConnection = {
  __typename?: 'CryptoTokensTypeConnection';
  /** A list of edges. */
  edges: Array<ICryptoTokensTypeEdge>;
  /** A list of nodes. */
  nodes: Array<ICryptoTokensType>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ICryptoTokensTypeEdge = {
  __typename?: 'CryptoTokensTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ICryptoTokensType;
};

/** List of available Crypto Token Standard Enums of Core domain */
export enum ICryptoTokenTokenStandardEnum {
  AlgoApp = 'ALGO_APP',
  AlgoStandard = 'ALGO_STANDARD',
  Arc_20 = 'ARC_20',
  Avalanche = 'AVALANCHE',
  Bep_20 = 'BEP_20',
  Erc_1155 = 'ERC_1155',
  Erc_1404 = 'ERC_1404',
  Erc_20 = 'ERC_20',
  Erc_721 = 'ERC_721',
  Mrc_20 = 'MRC_20',
  Native = 'NATIVE',
}

/** List of available Crypto Token Type Enums of Core domain */
export enum ICryptoTokenTokenTypeEnum {
  Coin = 'COIN',
  GovernanceToken = 'GOVERNANCE_TOKEN',
  NonFungibleToken = 'NON_FUNGIBLE_TOKEN',
  RewardToken = 'REWARD_TOKEN',
  SecurityToken = 'SECURITY_TOKEN',
  StableCoin = 'STABLE_COIN',
  Token = 'TOKEN',
  UtilityToken = 'UTILITY_TOKEN',
}

/** Autogenerated input type of CryptoTokenUpdate */
export type ICryptoTokenUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  cryptoToken: ICryptoTokenInput;
};

/** Autogenerated return type of CryptoTokenUpdate. */
export type ICryptoTokenUpdatePayload = {
  __typename?: 'CryptoTokenUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  cryptoToken?: Maybe<ICryptoTokensType>;
  errors?: Maybe<Array<IValidationError>>;
};

/** List of available Crypto Token Wallet Source Type Enums of Core domain */
export enum ICryptoTokenWalletSourceEnum {
  AnySource = 'ANY_SOURCE',
  NoWalletRequired = 'NO_WALLET_REQUIRED',
  Republic = 'REPUBLIC',
  UserProvided = 'USER_PROVIDED',
}

/** Crypto Transaction object of Core domain */
export type ICryptoTransaction = INode & {
  __typename?: 'CryptoTransaction';
  allowanceSpenderAddress?: Maybe<Scalars['String']['output']>;
  amount?: Maybe<Scalars['BigDecimal']['output']>;
  assetName?: Maybe<Scalars['String']['output']>;
  assetSymbol?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  rawTransaction?: Maybe<Scalars['String']['output']>;
  receiverAddress?: Maybe<ICryptoTokenAddress>;
  receiverWalletAddress?: Maybe<Scalars['String']['output']>;
  senderAddress?: Maybe<ICryptoTokenAddress>;
  signatures?: Maybe<Array<ITransactionSignatureType>>;
  state?: Maybe<ICryptoTransactionStateEnum>;
  transactionCompletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  transactionHash?: Maybe<Scalars['String']['output']>;
  transactionType?: Maybe<ICryptoTransactionTypeEnum>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
  wallabyId?: Maybe<Scalars['ID']['output']>;
};

/** The connection type for CryptoTransaction. */
export type ICryptoTransactionConnection = {
  __typename?: 'CryptoTransactionConnection';
  /** A list of edges. */
  edges: Array<ICryptoTransactionEdge>;
  /** A list of nodes. */
  nodes: Array<ICryptoTransaction>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Autogenerated input type of CryptoTransactionCreate */
export type ICryptoTransactionCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  cryptoTransaction: ICryptoTransactionInput;
  tokenSymbol?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CryptoTransactionCreate. */
export type ICryptoTransactionCreatePayload = {
  __typename?: 'CryptoTransactionCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  cryptoTransaction?: Maybe<ICryptoTransaction>;
  errors?: Maybe<Array<IValidationError>>;
};

/** An edge in a connection. */
export type ICryptoTransactionEdge = {
  __typename?: 'CryptoTransactionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ICryptoTransaction;
};

/** Crypto Transaction Input for Core domain */
export type ICryptoTransactionInput = {
  allowanceSpenderAddress?: InputMaybe<Scalars['String']['input']>;
  amount?: InputMaybe<Scalars['Float']['input']>;
  comakeryIntegrationProjectId?: InputMaybe<Scalars['ID']['input']>;
  fundOptinRequired?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  rawTransaction?: InputMaybe<Scalars['String']['input']>;
  receiverTokenAddress?: InputMaybe<Scalars['String']['input']>;
  receiverTokenAddressId?: InputMaybe<Scalars['ID']['input']>;
  receiverWalletAddress?: InputMaybe<Scalars['String']['input']>;
  senderTokenAddress?: InputMaybe<Scalars['String']['input']>;
  senderTokenAddressId?: InputMaybe<Scalars['ID']['input']>;
  shouldUpdateWalletToken?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<ICryptoTransactionStateEnum>;
  transactionCompletedAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  transactionHash?: InputMaybe<Scalars['String']['input']>;
  transactionType?: InputMaybe<ICryptoTransactionTypeEnum>;
  uuid?: InputMaybe<Scalars['ID']['input']>;
  wallabyId?: InputMaybe<Scalars['ID']['input']>;
};

/** Crypto Transaction Signature Input for Core domain */
export type ICryptoTransactionSignatureInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  signed?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated input type of CryptoTransactionSignatureUpdate */
export type ICryptoTransactionSignatureUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  transactionSignature: ICryptoTransactionSignatureInput;
};

/** Autogenerated return type of CryptoTransactionSignatureUpdate. */
export type ICryptoTransactionSignatureUpdatePayload = {
  __typename?: 'CryptoTransactionSignatureUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  transactionSignature?: Maybe<ITransactionSignatureType>;
};

/** List of available Crypto Transaction State Enums of Core domain */
export enum ICryptoTransactionStateEnum {
  Approved = 'APPROVED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  PendingApproval = 'PENDING_APPROVAL',
  Rejected = 'REJECTED',
  Success = 'SUCCESS',
}

/** List of available Crypto Transaction Type Enums of Core domain */
export enum ICryptoTransactionTypeEnum {
  Allowance = 'ALLOWANCE',
  FundOptin = 'FUND_OPTIN',
  Optin = 'OPTIN',
  RawTransaction = 'RAW_TRANSACTION',
  Transfer = 'TRANSFER',
}

/** Autogenerated input type of CryptoTransactionUpdate */
export type ICryptoTransactionUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  cryptoTransaction?: InputMaybe<ICryptoTransactionInput>;
  cryptoTransactions?: InputMaybe<Scalars['JSON']['input']>;
};

/** Autogenerated return type of CryptoTransactionUpdate. */
export type ICryptoTransactionUpdatePayload = {
  __typename?: 'CryptoTransactionUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  cryptoTransaction?: Maybe<ICryptoTransaction>;
  cryptoTransactions?: Maybe<Array<ICryptoTransaction>>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Autogenerated input type of CryptoWalletCreate */
export type ICryptoWalletCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  fingerprint: Scalars['String']['input'];
  walletDetails: ICryptoWalletCreateWalletInput;
};

/** Autogenerated return type of CryptoWalletCreate. */
export type ICryptoWalletCreatePayload = {
  __typename?: 'CryptoWalletCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  wallet?: Maybe<ICryptoWalletType>;
};

/** Crypto Create Wallet Input with associations for Code domain */
export type ICryptoWalletCreateWalletInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  addressType?: InputMaybe<ICryptoTokenAddressAddressTypeEnum>;
  airdropInvestmentId?: InputMaybe<Scalars['ID']['input']>;
  assets?: InputMaybe<Scalars['String']['input']>;
  cryptoTokenId?: InputMaybe<Scalars['ID']['input']>;
  investmentId?: InputMaybe<Scalars['ID']['input']>;
  investorProfileId: Scalars['ID']['input'];
  primaryAddress?: InputMaybe<Scalars['Boolean']['input']>;
  republicWallet?: InputMaybe<Scalars['Boolean']['input']>;
  seedPhraseHint?: InputMaybe<Scalars['String']['input']>;
  wallabyWalletId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of CryptoWalletDelete */
export type ICryptoWalletDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  investmentId?: InputMaybe<Scalars['ID']['input']>;
  userSlug?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CryptoWalletDelete. */
export type ICryptoWalletDeletePayload = {
  __typename?: 'CryptoWalletDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  wallet?: Maybe<ICryptoWalletType>;
};

/** Crypto Wallet Device object */
export type ICryptoWalletDevice = {
  __typename?: 'CryptoWalletDevice';
  clearFromDevice?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  cryptoWallet?: Maybe<ICryptoWalletType>;
  fingerprint?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  initialSignIn?: Maybe<Scalars['ISO8601DateTime']['output']>;
  ip?: Maybe<Scalars['String']['output']>;
  lastUsed?: Maybe<Scalars['ISO8601DateTime']['output']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/** Autogenerated input type of CryptoWalletDeviceClear */
export type ICryptoWalletDeviceClearInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  fingerprint: Scalars['String']['input'];
  userSlug: Scalars['String']['input'];
};

/** Autogenerated return type of CryptoWalletDeviceClear. */
export type ICryptoWalletDeviceClearPayload = {
  __typename?: 'CryptoWalletDeviceClearPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  device?: Maybe<ICryptoWalletDevice>;
  errors?: Maybe<Array<IValidationError>>;
};

/** The connection type for CryptoWalletDevice. */
export type ICryptoWalletDeviceConnection = {
  __typename?: 'CryptoWalletDeviceConnection';
  /** A list of edges. */
  edges: Array<ICryptoWalletDeviceEdge>;
  /** A list of nodes. */
  nodes: Array<ICryptoWalletDevice>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Autogenerated input type of CryptoWalletDeviceCreate */
export type ICryptoWalletDeviceCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  device: ICryptoWalletDeviceInput;
};

/** Autogenerated return type of CryptoWalletDeviceCreate. */
export type ICryptoWalletDeviceCreatePayload = {
  __typename?: 'CryptoWalletDeviceCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  device?: Maybe<ICryptoWalletDevice>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Autogenerated input type of CryptoWalletDeviceDelete */
export type ICryptoWalletDeviceDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  fingerprint: Scalars['String']['input'];
};

/** Autogenerated return type of CryptoWalletDeviceDelete. */
export type ICryptoWalletDeviceDeletePayload = {
  __typename?: 'CryptoWalletDeviceDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  device?: Maybe<ICryptoWalletDevice>;
  errors?: Maybe<Array<IValidationError>>;
};

/** An edge in a connection. */
export type ICryptoWalletDeviceEdge = {
  __typename?: 'CryptoWalletDeviceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ICryptoWalletDevice;
};

export type ICryptoWalletDeviceInput = {
  cryptoWalletId?: InputMaybe<Scalars['ID']['input']>;
  fingerprint?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  initialSignIn?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  lastUsed?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};

/** Autogenerated input type of CryptoWalletDeviceUpdate */
export type ICryptoWalletDeviceUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  fingerprint: Scalars['String']['input'];
};

/** Autogenerated return type of CryptoWalletDeviceUpdate. */
export type ICryptoWalletDeviceUpdatePayload = {
  __typename?: 'CryptoWalletDeviceUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  device?: Maybe<ICryptoWalletDevice>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Autogenerated input type of CryptoWalletDisclaimerCreate */
export type ICryptoWalletDisclaimerCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  walletDisclaimer: ICryptoWalletDisclaimerInput;
};

/** Autogenerated return type of CryptoWalletDisclaimerCreate. */
export type ICryptoWalletDisclaimerCreatePayload = {
  __typename?: 'CryptoWalletDisclaimerCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  walletDisclaimer?: Maybe<ICryptoWalletDisclaimerType>;
};

/** Autogenerated input type of CryptoWalletDisclaimerDelete */
export type ICryptoWalletDisclaimerDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of CryptoWalletDisclaimerDelete. */
export type ICryptoWalletDisclaimerDeletePayload = {
  __typename?: 'CryptoWalletDisclaimerDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  walletDisclaimer?: Maybe<ICryptoWalletDisclaimerType>;
};

/** Crypto Wallet Disclaimer Input for Core domain */
export type ICryptoWalletDisclaimerInput = {
  cryptoTokenId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
};

/** Crypto Wallet Disclaimer object of Core domain */
export type ICryptoWalletDisclaimerType = INode & {
  __typename?: 'CryptoWalletDisclaimerType';
  createdAt: Scalars['ISO8601DateTime']['output'];
  cryptoToken?: Maybe<ICryptoTokensType>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for CryptoWalletDisclaimerType. */
export type ICryptoWalletDisclaimerTypeConnection = {
  __typename?: 'CryptoWalletDisclaimerTypeConnection';
  /** A list of edges. */
  edges: Array<ICryptoWalletDisclaimerTypeEdge>;
  /** A list of nodes. */
  nodes: Array<ICryptoWalletDisclaimerType>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ICryptoWalletDisclaimerTypeEdge = {
  __typename?: 'CryptoWalletDisclaimerTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ICryptoWalletDisclaimerType;
};

/** Autogenerated input type of CryptoWalletDisclaimerUpdate */
export type ICryptoWalletDisclaimerUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  walletDisclaimer: ICryptoWalletDisclaimerInput;
};

/** Autogenerated return type of CryptoWalletDisclaimerUpdate. */
export type ICryptoWalletDisclaimerUpdatePayload = {
  __typename?: 'CryptoWalletDisclaimerUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  walletDisclaimer?: Maybe<ICryptoWalletDisclaimerType>;
};

/** Autogenerated input type of CryptoWalletTokenCreate */
export type ICryptoWalletTokenCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  walletToken: ICryptoWalletTokenInput;
};

/** Autogenerated return type of CryptoWalletTokenCreate. */
export type ICryptoWalletTokenCreatePayload = {
  __typename?: 'CryptoWalletTokenCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  walletToken?: Maybe<ICryptoWalletTokenType>;
};

/** Autogenerated input type of CryptoWalletTokenDelete */
export type ICryptoWalletTokenDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of CryptoWalletTokenDelete. */
export type ICryptoWalletTokenDeletePayload = {
  __typename?: 'CryptoWalletTokenDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  walletToken?: Maybe<ICryptoWalletTokenType>;
};

export type ICryptoWalletTokenInput = {
  airdropInvestmentId?: InputMaybe<Scalars['ID']['input']>;
  balance?: InputMaybe<Scalars['BigDecimal']['input']>;
  cryptoMultisigAccountId?: InputMaybe<Scalars['ID']['input']>;
  cryptoTokenId?: InputMaybe<Scalars['ID']['input']>;
  cryptoWalletId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  investmentId?: InputMaybe<Scalars['ID']['input']>;
  isOptinRequired?: InputMaybe<Scalars['Boolean']['input']>;
  lockedBalance?: InputMaybe<Scalars['BigDecimal']['input']>;
  ownershipType?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<ICryptoWalletTokenStateEnum>;
  wallabyId?: InputMaybe<Scalars['ID']['input']>;
};

/** Crypto Wallet Token Owner Info object of Core domain */
export type ICryptoWalletTokenOwnerInfo = {
  __typename?: 'CryptoWalletTokenOwnerInfo';
  id?: Maybe<Scalars['ID']['output']>;
  ownerEmail?: Maybe<Scalars['String']['output']>;
  ownerSlug?: Maybe<Scalars['String']['output']>;
  primaryAddress?: Maybe<Scalars['String']['output']>;
  wallabyUserId?: Maybe<Scalars['String']['output']>;
  walletId?: Maybe<Scalars['ID']['output']>;
};

/** List of available Crypto Wallet Token State Type Enums of Core domain */
export enum ICryptoWalletTokenStateEnum {
  FullyVested = 'FULLY_VESTED',
  PartiallyVested = 'PARTIALLY_VESTED',
  Unvested = 'UNVESTED',
}

/** Crypto Wallet Token object of Core domain */
export type ICryptoWalletTokenType = INode & {
  __typename?: 'CryptoWalletTokenType';
  airdropInvestmentId?: Maybe<Scalars['ID']['output']>;
  associatedIndividualAddresses?: Maybe<Array<ICryptoWalletTokenOwnerInfo>>;
  blockchainId?: Maybe<Scalars['ID']['output']>;
  blockchainLogoUrl?: Maybe<Scalars['String']['output']>;
  blockchainName?: Maybe<Scalars['String']['output']>;
  blockchainTokenId?: Maybe<Scalars['String']['output']>;
  comakeryIntegrationProjectId?: Maybe<Scalars['String']['output']>;
  contractAddress?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  cryptoToken?: Maybe<ICryptoTokensType>;
  cryptoTokenAddresses?: Maybe<Array<ICryptoTokenAddress>>;
  cryptoTokenId?: Maybe<Scalars['ID']['output']>;
  cryptoWallet?: Maybe<ICryptoWalletType>;
  displayName?: Maybe<Scalars['String']['output']>;
  formattedAvailableBalance?: Maybe<Scalars['String']['output']>;
  formattedLockedBalance?: Maybe<Scalars['String']['output']>;
  formattedPendingDistributionAmount?: Maybe<Scalars['String']['output']>;
  formattedTotalBalance?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  investment?: Maybe<IInvestment>;
  investmentId?: Maybe<Scalars['ID']['output']>;
  investmentSlug?: Maybe<Scalars['String']['output']>;
  isDapp?: Maybe<Scalars['Boolean']['output']>;
  isNative?: Maybe<Scalars['Boolean']['output']>;
  isOptinRequired?: Maybe<Scalars['Boolean']['output']>;
  isSecondariesTradeEnabled?: Maybe<Scalars['Boolean']['output']>;
  isVisible?: Maybe<Scalars['Boolean']['output']>;
  lockupUntil?: Maybe<Scalars['ISO8601DateTime']['output']>;
  multisigAddress?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nativeTokenBalance?: Maybe<Scalars['String']['output']>;
  nativeTokenSymbol?: Maybe<Scalars['String']['output']>;
  noteRoutescanBalance?: Maybe<INoteRoutescanBalance>;
  optedInAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  ownershipType?: Maybe<Scalars['String']['output']>;
  primaryAddress?: Maybe<Scalars['String']['output']>;
  primaryAddressId?: Maybe<Scalars['Int']['output']>;
  shouldFundOptin?: Maybe<Scalars['Boolean']['output']>;
  state?: Maybe<ICryptoWalletTokenStateEnum>;
  symbol?: Maybe<Scalars['String']['output']>;
  tokenLogoUrl?: Maybe<Scalars['Url']['output']>;
  /** @deprecated Use xTokenStandard instead */
  tokenStandard?: Maybe<ICryptoTokenTokenStandardEnum>;
  tokenType?: Maybe<ICryptoTokenTokenTypeEnum>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  wallabyAssetId?: Maybe<Scalars['String']['output']>;
  wallabyId?: Maybe<Scalars['String']['output']>;
  wallabyUserId?: Maybe<Scalars['String']['output']>;
  xTokenStandard?: Maybe<Scalars['String']['output']>;
};

/** The connection type for CryptoWalletTokenType. */
export type ICryptoWalletTokenTypeConnection = {
  __typename?: 'CryptoWalletTokenTypeConnection';
  /** A list of edges. */
  edges: Array<ICryptoWalletTokenTypeEdge>;
  /** A list of nodes. */
  nodes: Array<ICryptoWalletTokenType>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ICryptoWalletTokenTypeEdge = {
  __typename?: 'CryptoWalletTokenTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ICryptoWalletTokenType;
};

/** Autogenerated input type of CryptoWalletTokenUpdate */
export type ICryptoWalletTokenUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  walletToken: ICryptoWalletTokenInput;
};

/** Autogenerated return type of CryptoWalletTokenUpdate. */
export type ICryptoWalletTokenUpdatePayload = {
  __typename?: 'CryptoWalletTokenUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  walletToken?: Maybe<ICryptoWalletTokenType>;
};

/** Crypto Wallet object of Core domain */
export type ICryptoWalletType = {
  __typename?: 'CryptoWalletType';
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  cryptoTokenAddresses?: Maybe<Array<ICryptoTokenAddress>>;
  cryptoWalletTokens?: Maybe<Array<ICryptoWalletTokenType>>;
  deviceFingerprintsToClear?: Maybe<Array<Scalars['String']['output']>>;
  devices?: Maybe<Array<ICryptoWalletDevice>>;
  hasPositiveAlgoBalanceForOptin?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  investments?: Maybe<Array<IInvestment>>;
  investorProfile?: Maybe<IInvestorProfile>;
  republicWallet?: Maybe<Scalars['Boolean']['output']>;
  seedPhraseHint?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  userSlug: Scalars['String']['output'];
  wallabyUserId?: Maybe<Scalars['String']['output']>;
  wallabyWalletId?: Maybe<Scalars['String']['output']>;
  zenledgerRedirectUrl?: Maybe<Scalars['String']['output']>;
};

/** The connection type for CryptoWalletType. */
export type ICryptoWalletTypeConnection = {
  __typename?: 'CryptoWalletTypeConnection';
  /** A list of edges. */
  edges: Array<ICryptoWalletTypeEdge>;
  /** A list of nodes. */
  nodes: Array<ICryptoWalletType>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ICryptoWalletTypeEdge = {
  __typename?: 'CryptoWalletTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ICryptoWalletType;
};

/** Autogenerated input type of CryptoWalletUpdate */
export type ICryptoWalletUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  walletDetails: ICryptoWalletUpdateWalletInput;
};

/** Autogenerated return type of CryptoWalletUpdate. */
export type ICryptoWalletUpdatePayload = {
  __typename?: 'CryptoWalletUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  wallet?: Maybe<ICryptoWalletType>;
};

/** Crypto Update Wallet Input with associations for Core domain */
export type ICryptoWalletUpdateWalletInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  assets?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  republicWallet?: InputMaybe<Scalars['Boolean']['input']>;
  seedPhraseHint?: InputMaybe<Scalars['String']['input']>;
};

/** Crypto Validate Password Input with associations for Core domain */
export type ICryptoWalletValidatePasswordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

/** Autogenerated return type of CryptoWalletValidatePassword. */
export type ICryptoWalletValidatePasswordPayload = {
  __typename?: 'CryptoWalletValidatePasswordPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  valid: Scalars['Boolean']['output'];
};

/** The Account Application a user must completeto open a Custodial Account */
export type ICustodialAccount = {
  __typename?: 'CustodialAccount';
  creationFlow?: Maybe<ICustodialFlow>;
  id: Scalars['ID']['output'];
  status: ICustodialAccountStatusEnum;
};

/** This is a deprecated type. Please use AccountType unless this is replaced. */
export type ICustodialAccountApplication = {
  __typename?: 'CustodialAccountApplication';
  applicationSteps?: Maybe<Array<ICustodialAccountApplicationStep>>;
  id: Scalars['ID']['output'];
  pendingApplicationSteps?: Maybe<Array<ICustodialAccountApplicationStep>>;
};

/** Autogenerated input type of CustodialAccountApplicationAcceptDisclosures */
export type ICustodialAccountApplicationAcceptDisclosuresInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  investmentId: Scalars['ID']['input'];
};

/** Autogenerated return type of CustodialAccountApplicationAcceptDisclosures. */
export type ICustodialAccountApplicationAcceptDisclosuresPayload = {
  __typename?: 'CustodialAccountApplicationAcceptDisclosuresPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  custodialAccount?: Maybe<ICustodialAccount>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Autogenerated input type of CustodialAccountApplicationSignAgreements */
export type ICustodialAccountApplicationSignAgreementsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  investmentId: Scalars['ID']['input'];
};

/** Autogenerated return type of CustodialAccountApplicationSignAgreements. */
export type ICustodialAccountApplicationSignAgreementsPayload = {
  __typename?: 'CustodialAccountApplicationSignAgreementsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  custodialAccount?: Maybe<ICustodialAccount>;
  errors?: Maybe<Array<IValidationError>>;
};

/** One of many steps that must be complete to open a custodial account */
export type ICustodialAccountApplicationStep = {
  __typename?: 'CustodialAccountApplicationStep';
  agreementText?: Maybe<Scalars['String']['output']>;
  signed: Scalars['Boolean']['output'];
  signedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  stepType?: Maybe<ICustodialApplicationStepTypeEnum>;
};

export type ICustodialAccountSetup = {
  __typename?: 'CustodialAccountSetup';
  custodialAccount: ICustodialAccount;
  kycStepStatus: ICustodialKycStepEnum;
  legalInformation?: Maybe<IUserLegalInformation>;
};

/** The status of the Custodial Account */
export enum ICustodialAccountStatusEnum {
  Active = 'ACTIVE',
  Closed = 'CLOSED',
  Disabled = 'DISABLED',
  InFlow = 'IN_FLOW',
  Pending = 'PENDING',
  Synced = 'SYNCED',
}

/** The type of application step a user must complete */
export enum ICustodialApplicationStepTypeEnum {
  SignAgreement = 'SIGN_AGREEMENT',
  SignDisclaimers = 'SIGN_DISCLAIMERS',
}

/** This is a generic flow type for custodial accounts. */
export type ICustodialFlow = {
  __typename?: 'CustodialFlow';
  agreement?: Maybe<ICustodialFlowAgreement>;
  certificationAgreement?: Maybe<ICustodialFlowAgreement>;
  currentStep?: Maybe<Scalars['String']['output']>;
  disclosure?: Maybe<ICustodialFlowAgreement>;
  flowItems?: Maybe<Array<ICustodialFlowItem>>;
  id: Scalars['ID']['output'];
  nomineeAgreement?: Maybe<ICustodialFlowAgreement>;
  status: ICustodialFlowStatusEnum;
};

/** Any legal agreements that must be signed to open a custodial account */
export type ICustodialFlowAgreement = {
  __typename?: 'CustodialFlowAgreement';
  agreed: Scalars['Boolean']['output'];
  agreedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  agreementHtml?: Maybe<Scalars['String']['output']>;
};

/** One of many steps that must be complete to complete a custodial flow */
export type ICustodialFlowItem = {
  __typename?: 'CustodialFlowItem';
  /** JSON representing the status of the flow item. Will likely be translated to a more specific type in the future. */
  details?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  status: ICustodialFlowItemStatusEnum;
};

/** The status of the Custodial Flow Item */
export enum ICustodialFlowItemStatusEnum {
  Failure = 'FAILURE',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Success = 'SUCCESS',
}

/** The status of the Custodial Flow */
export enum ICustodialFlowStatusEnum {
  ActionRequired = 'ACTION_REQUIRED',
  Cancelled = 'CANCELLED',
  Done = 'DONE',
  Pending = 'PENDING',
  Stopped = 'STOPPED',
}

export enum ICustodialKycStepEnum {
  RequireReconfirmation = 'REQUIRE_RECONFIRMATION',
  RequireReflow = 'REQUIRE_REFLOW',
  Skip = 'SKIP',
}

/** Autogenerated input type of CustodialSignBeneficialOwnerCertification */
export type ICustodialSignBeneficialOwnerCertificationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  investmentId: Scalars['ID']['input'];
};

/** Autogenerated return type of CustodialSignBeneficialOwnerCertification. */
export type ICustodialSignBeneficialOwnerCertificationPayload = {
  __typename?: 'CustodialSignBeneficialOwnerCertificationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  custodialAccount?: Maybe<ICustodialAccount>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Autogenerated input type of CustodialSignNomineeAgreement */
export type ICustodialSignNomineeAgreementInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  investmentId: Scalars['ID']['input'];
};

/** Autogenerated return type of CustodialSignNomineeAgreement. */
export type ICustodialSignNomineeAgreementPayload = {
  __typename?: 'CustodialSignNomineeAgreementPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  custodialAccount?: Maybe<ICustodialAccount>;
  errors?: Maybe<Array<IValidationError>>;
};

/** The Custodial Vendor that supports a given offering */
export type ICustodialVendor = {
  __typename?: 'CustodialVendor';
  active: Scalars['Boolean']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type ICustomCheckoutInfo = {
  __typename?: 'CustomCheckoutInfo';
  bulletPoints: Array<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type IDealPostInput = {
  affiliation?: InputMaybe<ICommentRoleEnum>;
  body?: InputMaybe<Scalars['String']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  offeringSlug: Scalars['String']['input'];
};

export enum IDealScopeEnum {
  ComingSoon = 'COMING_SOON',
}

export type IDealTermsInterface = {
  activeInvestmentsCount?: Maybe<Scalars['Int']['output']>;
  amountRaised?: Maybe<Scalars['String']['output']>;
  calculatedMinInvestmentAmount?: Maybe<Scalars['String']['output']>;
  equityClaimed?: Maybe<Scalars['String']['output']>;
  flexibleDealTermsForDealCard?: Maybe<Array<IOfferingTermTierDealTerm>>;
  reviewsCount?: Maybe<Scalars['Int']['output']>;
  securityValuationOrCap?: Maybe<Scalars['String']['output']>;
  securityValuationOrCapLabel?: Maybe<Scalars['String']['output']>;
  timeRemainingUnitForDisplay?: Maybe<Scalars['String']['output']>;
  timeRemainingValueForDisplay?: Maybe<Scalars['Int']['output']>;
  totalCapitalization?: Maybe<Scalars['String']['output']>;
};

/** All possible deal types */
export type IDealUnion = IOffering | ITeaserPage;

/** The connection type for DealUnion. */
export type IDealUnionConnection = {
  __typename?: 'DealUnionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IDealUnionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<IDealUnion>>>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
};

/** An edge in a connection. */
export type IDealUnionEdge = {
  __typename?: 'DealUnionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<IDealUnion>;
};

export type IDevice = INode & {
  __typename?: 'Device';
  browserName?: Maybe<Scalars['String']['output']>;
  browserVersion?: Maybe<Scalars['String']['output']>;
  deviceName?: Maybe<Scalars['String']['output']>;
  deviceType?: Maybe<Scalars['String']['output']>;
  /** ID of the object. */
  id: Scalars['ID']['output'];
  mobile: Scalars['Boolean']['output'];
  userAgent?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of DownloadTransactionLedger */
export type IDownloadTransactionLedgerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** This is context uuid */
  uuid: Scalars['String']['input'];
};

/** Autogenerated return type of DownloadTransactionLedger. */
export type IDownloadTransactionLedgerPayload = {
  __typename?: 'DownloadTransactionLedgerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success?: Maybe<ISuccess>;
};

export type IEmailArgs = {
  from: Scalars['String']['input'];
  to: Array<Scalars['String']['input']>;
  bcc?: InputMaybe<Array<Scalars['String']['input']>>;
  subject: Scalars['String']['input'];
  date: Scalars['String']['input'];
  messageId?: InputMaybe<Scalars['String']['input']>;
};

export type IEmailSaveRequestInput = {
  /** Unique ID of a user to which email is being sent to from monolith */
  userId: Scalars['Float']['input'];
  /** Box folder ID where copy/snapshot of the email will be stored for compliance purpose */
  folderId: Scalars['String']['input'];
  /** Optional unique template ID used */
  templateIdentifier?: InputMaybe<Scalars['String']['input']>;
  /** Optional reference ID in stored as a reference by monolith */
  legacyId?: InputMaybe<Scalars['String']['input']>;
  target: Scalars['String']['input'];
  /** System will use this name to store compliance document */
  fileName: Scalars['String']['input'];
  /** html or txt, raw email body */
  emailBody: Scalars['String']['input'];
  /** Information such as: To, From, Bcc, Subject etc. */
  emailArgs: IEmailArgs;
  /** Parameters/ Json data used to generate email body */
  mergeVars?: InputMaybe<Scalars['String']['input']>;
};

export type IEntityChangeRequest = {
  __typename?: 'EntityChangeRequest';
  id: Scalars['ID']['output'];
  changes: IEntityChangeRequestChanges;
  status: ITransferAgentChangeRequestStatus;
  reason: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export type IEntityChangeRequestChanges = {
  __typename?: 'EntityChangeRequestChanges';
  fullName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  legalAddress?: Maybe<ITransferAgentAddress>;
  mailingAddress?: Maybe<ITransferAgentAddress>;
};

export type IEntityOwnerInput = {
  ownerId: Scalars['String']['input'];
  ownerType: ITransferAgentEntityOwnerType;
  title: Scalars['String']['input'];
};

/** Possible types of user entity */
export type IEntityUnion =
  | IInvestmentEntityCorporation
  | IInvestmentEntityIndividual
  | IInvestmentEntityJointSpouse
  | IInvestmentEntityJointTenant
  | IInvestmentEntityLlc
  | IInvestmentEntityPartnership
  | IInvestmentEntitySelfDirectedIra
  | IInvestmentEntityTrust;

/** A human-readable error */
export type IError = {
  __typename?: 'Error';
  /** A description of the error */
  message: Scalars['String']['output'];
  /** Which input value this error came from */
  path?: Maybe<Array<Scalars['String']['output']>>;
};

export enum IEventsCategoryEnum {
  Breakfast = 'BREAKFAST',
  Cocktails = 'COCKTAILS',
  Dinner = 'DINNER',
  FacebookLive = 'FACEBOOK_LIVE',
  Lunch = 'LUNCH',
  Showcase = 'SHOWCASE',
  Webcast = 'WEBCAST',
}

/** Autogenerated input type of EventsCreate */
export type IEventsCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  event: IEventsEventInput;
};

/** Autogenerated return type of EventsCreate. */
export type IEventsCreatePayload = {
  __typename?: 'EventsCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  event?: Maybe<IEventsEvent>;
};

export type IEventsCta = INode & {
  __typename?: 'EventsCta';
  /** ID of the object. */
  id: Scalars['ID']['output'];
  rsvp?: Maybe<Scalars['Boolean']['output']>;
  text: Scalars['String']['output'];
  url?: Maybe<Scalars['Url']['output']>;
};

/** Autogenerated input type of EventsDelete */
export type IEventsDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of EventsDelete. */
export type IEventsDeletePayload = {
  __typename?: 'EventsDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

export type IEventsEvent = IEventTags &
  INode &
  IOfferings & {
    __typename?: 'EventsEvent';
    address?: Maybe<IAddress>;
    beginAt: Scalars['ISO8601DateTime']['output'];
    body?: Maybe<Scalars['String']['output']>;
    bodySanitized?: Maybe<Scalars['String']['output']>;
    category?: Maybe<IEventsCategoryEnum>;
    coverPhotoContentType?: Maybe<Scalars['String']['output']>;
    coverPhotoFileName?: Maybe<Scalars['String']['output']>;
    coverPhotoFileSize?: Maybe<Scalars['BigInt']['output']>;
    coverPhotoUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    coverPhotoUrl?: Maybe<Scalars['Url']['output']>;
    cta?: Maybe<IEventsCta>;
    endAt: Scalars['ISO8601DateTime']['output'];
    future: Scalars['Boolean']['output'];
    /** @deprecated Use x_hosts instead, is nullable */
    hosts: Array<IEventsHost>;
    id: Scalars['ID']['output'];
    live: Scalars['Boolean']['output'];
    offerings: Array<IOffering>;
    past?: Maybe<Scalars['Boolean']['output']>;
    seoDescription?: Maybe<Scalars['String']['output']>;
    seoImageContentType?: Maybe<Scalars['String']['output']>;
    seoImageFileName?: Maybe<Scalars['String']['output']>;
    seoImageFileSize?: Maybe<Scalars['BigInt']['output']>;
    seoImageUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    seoImageUrl?: Maybe<Scalars['Url']['output']>;
    slug: Scalars['String']['output'];
    state?: Maybe<IEventsStateEnum>;
    tags?: Maybe<Array<ITagsTag>>;
    /** Timezone in IANA format */
    timezone: Scalars['String']['output'];
    title: Scalars['String']['output'];
    /** Parameter for hiding/showing event info */
    undisclosed?: Maybe<Scalars['Boolean']['output']>;
    url?: Maybe<Scalars['Url']['output']>;
    /** Parameter for users to create RSVP */
    useRsvp?: Maybe<Scalars['Boolean']['output']>;
    /** Permitted actions for event */
    userPermissions: IEventsUserPermissions;
    userSubscribed: Scalars['Boolean']['output'];
    xHosts?: Maybe<Array<IEventsHost>>;
  };

export type IEventsEventCoverPhotoUrlArgs = {
  extension?: InputMaybe<IFileProcessingImgproxyExtensionEnum>;
  gravity?: InputMaybe<IFileProcessingImgproxyGravityEnum>;
  resize?: InputMaybe<IFileProcessingImgproxyResizeInput>;
};

export type IEventsEventSeoImageUrlArgs = {
  extension?: InputMaybe<IFileProcessingImgproxyExtensionEnum>;
  gravity?: InputMaybe<IFileProcessingImgproxyGravityEnum>;
  resize?: InputMaybe<IFileProcessingImgproxyResizeInput>;
};

/** The connection type for EventsEvent. */
export type IEventsEventConnection = {
  __typename?: 'EventsEventConnection';
  /** A list of edges. */
  edges: Array<IEventsEventEdge>;
  /** A list of nodes. */
  nodes: Array<IEventsEvent>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IEventsEventEdge = {
  __typename?: 'EventsEventEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: IEventsEvent;
};

export type IEventsEventInput = {
  /** Nested address attributes */
  addressAttributes?: InputMaybe<IAddressInput>;
  beginAt: Scalars['ISO8601DateTime']['input'];
  body?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<IEventsCategoryEnum>;
  endAt: Scalars['ISO8601DateTime']['input'];
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  /** Nested hosts attributes */
  hostsAttributes: Array<IEventsHostInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** List of slugs */
  offerings: Array<Scalars['String']['input']>;
  seoDescription?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  /** List of slugs */
  tags: Array<Scalars['String']['input']>;
  timezone: IEventsTimezoneEnum;
  title: Scalars['String']['input'];
  undisclosed?: InputMaybe<Scalars['Boolean']['input']>;
  url?: InputMaybe<Scalars['Url']['input']>;
  useRsvp?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IEventsGuestInput = {
  email: Scalars['Email']['input'];
  eventId: Scalars['ID']['input'];
  fullName: Scalars['String']['input'];
  linkedinUrl?: InputMaybe<Scalars['Url']['input']>;
  phoneNumber?: InputMaybe<Scalars['PhoneNumber']['input']>;
};

/** Autogenerated input type of EventsGuestRsvpCreate */
export type IEventsGuestRsvpCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  guest: IEventsGuestInput;
};

/** Autogenerated return type of EventsGuestRsvpCreate. */
export type IEventsGuestRsvpCreatePayload = {
  __typename?: 'EventsGuestRsvpCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

export type IEventsHost = INode & {
  __typename?: 'EventsHost';
  avatarContentType?: Maybe<Scalars['String']['output']>;
  avatarFileName?: Maybe<Scalars['String']['output']>;
  avatarFileSize?: Maybe<Scalars['BigInt']['output']>;
  avatarUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  avatarUrl?: Maybe<Scalars['Url']['output']>;
  bio?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['String']['output']>;
};

export type IEventsHostAvatarUrlArgs = {
  extension?: InputMaybe<IFileProcessingImgproxyExtensionEnum>;
  gravity?: InputMaybe<IFileProcessingImgproxyGravityEnum>;
  resize?: InputMaybe<IFileProcessingImgproxyResizeInput>;
};

export type IEventsHostInput = {
  bio?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

export type IEventsMutations = {
  /** Create Event object of Events domain */
  eventsCreate?: Maybe<IEventsCreatePayload>;
  /** Delete Event object of Events domain by id */
  eventsDelete?: Maybe<IEventsDeletePayload>;
  /** Subscribe Guest to Event by id */
  eventsGuestsRsvpCreate?: Maybe<IEventsGuestRsvpCreatePayload>;
  /** Publish Event object of Events domain by id */
  eventsPublish?: Maybe<IEventsPublishPayload>;
  /** Unpublish Event object of Events domain by id */
  eventsUnpublish?: Maybe<IEventsUnpublishPayload>;
  /** Update Event object of Events domain by id */
  eventsUpdate?: Maybe<IEventsUpdatePayload>;
};

export type IEventsMutationsEventsCreateArgs = {
  input: IEventsCreateInput;
};

export type IEventsMutationsEventsDeleteArgs = {
  input: IEventsDeleteInput;
};

export type IEventsMutationsEventsGuestsRsvpCreateArgs = {
  input: IEventsGuestRsvpCreateInput;
};

export type IEventsMutationsEventsPublishArgs = {
  input: IEventsPublishInput;
};

export type IEventsMutationsEventsUnpublishArgs = {
  input: IEventsUnpublishInput;
};

export type IEventsMutationsEventsUpdateArgs = {
  input: IEventsUpdateInput;
};

export enum IEventsOrderEnum {
  BeginAtAsc = 'BEGIN_AT_ASC',
  BeginAtDesc = 'BEGIN_AT_DESC',
  CategoryAsc = 'CATEGORY_ASC',
  CategoryDesc = 'CATEGORY_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  EndAtAsc = 'END_AT_ASC',
  EndAtDesc = 'END_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
}

/** Autogenerated input type of EventsPublish */
export type IEventsPublishInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of EventsPublish. */
export type IEventsPublishPayload = {
  __typename?: 'EventsPublishPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

export type IEventsResolvers = {
  /** Fetch an event by a slug */
  event?: Maybe<IEventsEvent>;
  /** List of Events with ability to filter the result scope */
  events: IEventsEventConnection;
};

export type IEventsResolversEventArgs = {
  slug: Scalars['String']['input'];
};

export type IEventsResolversEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  categories?: InputMaybe<Array<IEventsCategoryEnum>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  future?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<IEventsOrderEnum>>;
  slugs?: InputMaybe<Array<Scalars['String']['input']>>;
  states?: InputMaybe<Array<IEventsStateEnum>>;
  tagSlug?: InputMaybe<Scalars['String']['input']>;
  timeFilters?: InputMaybe<IEventsTimeFiltersInput>;
};

export enum IEventsStateEnum {
  Created = 'CREATED',
  Published = 'PUBLISHED',
}

/** Input for filtering events by time */
export type IEventsTimeFiltersInput = {
  /** in future or past */
  future?: Scalars['Boolean']['input'];
  /** period to get events in days(in future or past) */
  periodDaysInTime?: InputMaybe<Scalars['Int']['input']>;
};

export enum IEventsTimezoneEnum {
  AmericaChicago = 'AMERICA_CHICAGO',
  AmericaDenver = 'AMERICA_DENVER',
  AmericaLosAngeles = 'AMERICA_LOS_ANGELES',
  AmericaNewYork = 'AMERICA_NEW_YORK',
}

/** Autogenerated input type of EventsUnpublish */
export type IEventsUnpublishInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of EventsUnpublish. */
export type IEventsUnpublishPayload = {
  __typename?: 'EventsUnpublishPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Autogenerated input type of EventsUpdate */
export type IEventsUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  event: IEventsEventInput;
};

/** Autogenerated return type of EventsUpdate. */
export type IEventsUpdatePayload = {
  __typename?: 'EventsUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  event?: Maybe<IEventsEvent>;
};

export type IEventsUserPermissions = {
  __typename?: 'EventsUserPermissions';
  canCreate: Scalars['Boolean']['output'];
  canDelete: Scalars['Boolean']['output'];
  canPublish: Scalars['Boolean']['output'];
  canRsvpCreate: Scalars['Boolean']['output'];
  canUnpublish: Scalars['Boolean']['output'];
  canUpdate: Scalars['Boolean']['output'];
};

export type IEventTags = {
  tags?: Maybe<Array<ITagsTag>>;
};

export type IExtensions = {
  __typename?: 'Extensions';
  code: ICodeEnum;
  exception?: Maybe<Scalars['JSON']['output']>;
  field?: Maybe<Scalars['String']['output']>;
};

export enum IFileProcessingImgproxyExtensionEnum {
  Avif = 'avif',
  Gif = 'gif',
  Jpg = 'jpg',
  Png = 'png',
  Svg = 'svg',
  Webp = 'webp',
}

export enum IFileProcessingImgproxyGravityEnum {
  Ce = 'ce',
  Ea = 'ea',
  No = 'no',
  Noea = 'noea',
  Nowe = 'nowe',
  So = 'so',
  Soea = 'soea',
  Sowe = 'sowe',
  We = 'we',
}

export type IFileProcessingImgproxyResizeInput = {
  height: Scalars['Int']['input'];
  type: IFileProcessingImgproxyResizeTypeEnum;
  width: Scalars['Int']['input'];
};

export enum IFileProcessingImgproxyResizeTypeEnum {
  Auto = 'auto',
  Fill = 'fill',
  Fit = 'fit',
  Force = 'force',
}

export type IFileProcessingMutations = {
  /** Generates presigned URL for direct file processing to S3 bucket */
  fileProcessingPresignUrl?: Maybe<IFileProcessingUploadPresignUrlPayload>;
};

export type IFileProcessingMutationsFileProcessingPresignUrlArgs = {
  input: IFileProcessingUploadPresignUrlInput;
};

export type IFileProcessingResolvers = {
  /** Provide information about validations for uploaded files */
  fileProcessingValidations: Array<IFileProcessingUploadValidation>;
};

export type IFileProcessingResolversFileProcessingValidationsArgs = {
  types?: InputMaybe<Array<IFileProcessingUploadValidationTypeEnum>>;
};

export type IFileProcessingUploadFileInput = {
  /** Size in bytes */
  contentLength: Scalars['Int']['input'];
  /** MD5 sum of the file */
  contentMd5: Scalars['String']['input'];
  contentType: Scalars['String']['input'];
  /** GQL schema type field name */
  field: Scalars['String']['input'];
  /** Filename with extension and without path */
  filename: Scalars['String']['input'];
  storage?: InputMaybe<IFileProcessingUploadStorageEnum>;
  /** GQL schema type name */
  type: IFileProcessingUploadValidationTypeEnum;
};

/** Autogenerated input type of FileProcessingUploadPresignUrl */
export type IFileProcessingUploadPresignUrlInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  file: IFileProcessingUploadFileInput;
};

/** Autogenerated return type of FileProcessingUploadPresignUrl. */
export type IFileProcessingUploadPresignUrlPayload = {
  __typename?: 'FileProcessingUploadPresignUrlPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  presignedUrl?: Maybe<Scalars['String']['output']>;
  publicUrl?: Maybe<Scalars['String']['output']>;
  uploadRequestUuid?: Maybe<Scalars['String']['output']>;
};

export enum IFileProcessingUploadStorageEnum {
  Default = 'DEFAULT',
  Secure = 'SECURE',
}

/** Validation for specific field of some type */
export type IFileProcessingUploadValidation = {
  __typename?: 'FileProcessingUploadValidation';
  contentType: Array<Scalars['String']['output']>;
  /** GQL schema type field name */
  field: Scalars['String']['output'];
  /** Size in bytes */
  maxSize: Scalars['Int']['output'];
  /** GQL schema type name */
  type: IFileProcessingUploadValidationTypeEnum;
};

export enum IFileProcessingUploadValidationTypeEnum {
  AccreditationDocument = 'AccreditationDocument',
  AchAccount = 'AchAccount',
  ActionListAttachment = 'ActionListAttachment',
  EventsEvent = 'EventsEvent',
  IssuerApplication = 'IssuerApplication',
  JournalArticle = 'JournalArticle',
  Offering = 'Offering',
  OfferingBrief = 'OfferingBrief',
  OfferingFinancialDocument = 'OfferingFinancialDocument',
  OfferingForm1A = 'OfferingForm1A',
  OfferingSliderMediaItem = 'OfferingSliderMediaItem',
  OnboardingDocumentEdgarAccess = 'OnboardingDocumentEdgarAccess',
  Payment = 'Payment',
  RoleCoveredCoreDocument = 'RoleCoveredCoreDocument',
  TagsTag = 'TagsTag',
  TeaserPage = 'TeaserPage',
  User = 'User',
  UserInvestorProfileDocument = 'UserInvestorProfileDocument',
  UserUserProfile = 'UserUserProfile',
}

export type IFilmBanner = {
  __typename?: 'FilmBanner';
  user: IUser;
};

/** Provides multiple options for resolving a user */
export type IFindUserInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type IFintronDocument = {
  __typename?: 'FintronDocument';
  /** Name of image */
  name: Scalars['String']['output'];
  /** Type of document */
  type: IFintronDocumentTypeEnum;
  /** Uid of image returned by FinTron API */
  uid: Scalars['String']['output'];
};

/** Enum of possible types of fintron documents */
export enum IFintronDocumentTypeEnum {
  UserDocumentTypeDriversLicense = 'USER_DOCUMENT_TYPE_DRIVERS_LICENSE',
  UserDocumentTypeIdentificationCard = 'USER_DOCUMENT_TYPE_IDENTIFICATION_CARD',
  UserDocumentTypeOther = 'USER_DOCUMENT_TYPE_OTHER',
  UserDocumentTypePassport = 'USER_DOCUMENT_TYPE_PASSPORT',
  UserDocumentTypeSocialSecurityCard = 'USER_DOCUMENT_TYPE_SOCIAL_SECURITY_CARD',
  UserDocumentTypeSsnCard = 'USER_DOCUMENT_TYPE_SSN_CARD',
  UserDocumentTypeStateIdCard = 'USER_DOCUMENT_TYPE_STATE_ID_CARD',
  UserDocumentTypeUnspecified = 'USER_DOCUMENT_TYPE_UNSPECIFIED',
}

/** Autogenerated input type of FinTronUploadDocuments */
export type IFinTronUploadDocumentsInput = {
  accessToken: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of FinTronUploadDocuments. */
export type IFinTronUploadDocumentsPayload = {
  __typename?: 'FinTronUploadDocumentsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<IFintronDocument>>;
  errors?: Maybe<Array<IValidationError>>;
};

export type IFlipper = INode & {
  __typename?: 'Flipper';
  /** Feature flag status */
  enabled: Scalars['Boolean']['output'];
  feature: IFlipperEnum;
  id: Scalars['ID']['output'];
  /** Pop-up status */
  viewed: Scalars['Boolean']['output'];
};

export enum IFlipperEnum {
  AccessTierOneNotification = 'ACCESS_TIER_ONE_NOTIFICATION',
  ActionListIntegration = 'ACTION_LIST_INTEGRATION',
  ActionRequiredPushNotifications = 'ACTION_REQUIRED_PUSH_NOTIFICATIONS',
  ActivityHistory = 'ACTIVITY_HISTORY',
  AdminLogging = 'ADMIN_LOGGING',
  AnnualReportFlashbar = 'ANNUAL_REPORT_FLASHBAR',
  AppsflyerIntegration = 'APPSFLYER_INTEGRATION',
  AppFintronIntegration = 'APP_FINTRON_INTEGRATION',
  AppWallet = 'APP_WALLET',
  AutopilotReact = 'AUTOPILOT_REACT',
  AutotestMutations = 'AUTOTEST_MUTATIONS',
  AvaWatzBanner = 'AVA_WATZ_BANNER',
  BrassicaLivelinessSandbox = 'BRASSICA_LIVELINESS_SANDBOX',
  BrazeIntegration = 'BRAZE_INTEGRATION',
  CitySearch = 'CITY_SEARCH',
  ContoraAiPortfolio = 'CONTORA_AI_PORTFOLIO',
  CryptoAssets = 'CRYPTO_ASSETS',
  CryptoMfaJwt = 'CRYPTO_MFA_JWT',
  CryptoPageRedirect = 'CRYPTO_PAGE_REDIRECT',
  CryptoTransactionHistory = 'CRYPTO_TRANSACTION_HISTORY',
  CryptoTransfers = 'CRYPTO_TRANSFERS',
  CryptoWalletLogin = 'CRYPTO_WALLET_LOGIN',
  CustodialAccountCreation = 'CUSTODIAL_ACCOUNT_CREATION',
  DealRoomReact = 'DEAL_ROOM_REACT',
  DisableInvestFlowTracking = 'DISABLE_INVEST_FLOW_TRACKING',
  DisableRaisedAmountCaching = 'DISABLE_RAISED_AMOUNT_CACHING',
  EducationCenterButton = 'EDUCATION_CENTER_BUTTON',
  Eoy_2023Report = 'EOY_2023_REPORT',
  FilmBanner = 'FILM_BANNER',
  GlobalAuth = 'GLOBAL_AUTH',
  /** @deprecated This field is deprecated. */
  GlobalNavigation_2024Variant = 'GLOBAL_NAVIGATION_2024_VARIANT',
  GlobalSearch = 'GLOBAL_SEARCH',
  GraphqlDealsAutocomplete = 'GRAPHQL_DEALS_AUTOCOMPLETE',
  IndexSearchReact = 'INDEX_SEARCH_REACT',
  LegacyPortfolioOverride = 'LEGACY_PORTFOLIO_OVERRIDE',
  LpbAllowJavascript = 'LPB_ALLOW_JAVASCRIPT',
  LpbAllowManageSections = 'LPB_ALLOW_MANAGE_SECTIONS',
  MediaSliderReact = 'MEDIA_SLIDER_REACT',
  MilestoneAutoChecker = 'MILESTONE_AUTO_CHECKER',
  MobileInvestmentStatusesNotifications = 'MOBILE_INVESTMENT_STATUSES_NOTIFICATIONS',
  MobileTagNotifications = 'MOBILE_TAG_NOTIFICATIONS',
  ModeMobileBanner = 'MODE_MOBILE_BANNER',
  MusicFlashbar = 'MUSIC_FLASHBAR',
  NewFeesAndCommission = 'NEW_FEES_AND_COMMISSION',
  Note_2023Launch = 'NOTE_2023_LAUNCH',
  Note_2023LaunchCountdown = 'NOTE_2023_LAUNCH_COUNTDOWN',
  Note_2023LaunchEmptyCountdown = 'NOTE_2023_LAUNCH_EMPTY_COUNTDOWN',
  NoteLaunchBanner = 'NOTE_LAUNCH_BANNER',
  NoteRegAClaim = 'NOTE_REG_A_CLAIM',
  NoteWalletSetupNotify = 'NOTE_WALLET_SETUP_NOTIFY',
  OauthProviderEngine = 'OAUTH_PROVIDER_ENGINE',
  OnboardingTeamMembers = 'ONBOARDING_TEAM_MEMBERS',
  OreIdSetup = 'ORE_ID_SETUP',
  PaymentOptions = 'PAYMENT_OPTIONS',
  PrivacyUpdatePopup = 'PRIVACY_UPDATE_POPUP',
  ReactAnalytics = 'REACT_ANALYTICS',
  RepublicSeriesBFlashbar = 'REPUBLIC_SERIES_B_FLASHBAR',
  RepublicWallet = 'REPUBLIC_WALLET',
  RepublicWalletCta = 'REPUBLIC_WALLET_CTA',
  RepublicWalletLaunchBanner = 'REPUBLIC_WALLET_LAUNCH_BANNER',
  RevealSeedphrase = 'REVEAL_SEEDPHRASE',
  SecondariesPortfolio = 'SECONDARIES_PORTFOLIO',
  SecondaryMarketMockTaIntegration = 'SECONDARY_MARKET_MOCK_TA_INTEGRATION',
  SecondaryMarketTransactions = 'SECONDARY_MARKET_TRANSACTIONS',
  SeedrsFlashbar = 'SEEDRS_FLASHBAR',
  SegmentAnalytics = 'SEGMENT_ANALYTICS',
  SegmentPageEventsServerSide = 'SEGMENT_PAGE_EVENTS_SERVER_SIDE',
  SelfServeTagging = 'SELF_SERVE_TAGGING',
  SlackNotifications = 'SLACK_NOTIFICATIONS',
  SpaceweekFlashbar = 'SPACEWEEK_FLASHBAR',
  SuggestedTagsGs = 'SUGGESTED_TAGS_GS',
  TeaserPages = 'TEASER_PAGES',
  TradeWithInx = 'TRADE_WITH_INX',
  TransferAgent = 'TRANSFER_AGENT',
  UserDashLearningBlock = 'USER_DASH_LEARNING_BLOCK',
  ValidateInvestorAge = 'VALIDATE_INVESTOR_AGE',
  Vault = 'VAULT',
  WatfordBanner = 'WATFORD_BANNER',
  WebSdkBraze = 'WEB_SDK_BRAZE',
  XteCryptoTaxes = 'XTE_CRYPTO_TAXES',
  XteCustomAssets = 'XTE_CUSTOM_ASSETS',
  XteDigitalAssetPortfolio = 'XTE_DIGITAL_ASSET_PORTFOLIO',
  XteNewPortfolio_2024 = 'XTE_NEW_PORTFOLIO_2024',
  XteNewWalletFlow = 'XTE_NEW_WALLET_FLOW',
  XteSecondariesTradeButton = 'XTE_SECONDARIES_TRADE_BUTTON',
  ZeroHashPayments = 'ZERO_HASH_PAYMENTS',
}

/** Autogenerated input type of FlipperFeatureUpdate */
export type IFlipperFeatureUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  feature: IFlipperEnum;
};

/** Autogenerated return type of FlipperFeatureUpdate. */
export type IFlipperFeatureUpdatePayload = {
  __typename?: 'FlipperFeatureUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  flipperObject: IFlipper;
};

export type IFollow = INode & {
  __typename?: 'Follow';
  createdAt: Scalars['ISO8601DateTime']['output'];
  follower: IUser;
  id: Scalars['ID']['output'];
  source: IFollowSourceEnum;
  target: IFollowTargetUnion;
  /** @deprecated FollowTargetUnion instead */
  targetId: Scalars['ID']['output'];
  /** @deprecated FollowTargetUnion instead */
  targetType: IFollowTargetTypeEnum;
};

/** The connection type for Follow. */
export type IFollowConnection = {
  __typename?: 'FollowConnection';
  /** A list of edges. */
  edges: Array<IFollowEdge>;
  /** A list of nodes. */
  nodes: Array<IFollow>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Autogenerated input type of FollowCreate */
export type IFollowCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  recordType?: InputMaybe<IFollowRecordTypeEnum>;
  slug: Scalars['String']['input'];
};

/** Autogenerated return type of FollowCreate. */
export type IFollowCreatePayload = {
  __typename?: 'FollowCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  target?: Maybe<IFollowTargetUnion>;
};

/** Autogenerated input type of FollowDelete */
export type IFollowDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
};

/** Autogenerated return type of FollowDelete. */
export type IFollowDeletePayload = {
  __typename?: 'FollowDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  target?: Maybe<IFollowTargetUnion>;
};

/** An edge in a connection. */
export type IFollowEdge = {
  __typename?: 'FollowEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: IFollow;
};

/** List of available Follows::RecordType Enums of Core domain */
export enum IFollowRecordTypeEnum {
  InfluencerGroup = 'INFLUENCER_GROUP',
  Offering = 'OFFERING',
  Tag = 'TAG',
  User = 'USER',
}

export enum IFollowSourceEnum {
  Investment = 'INVESTMENT',
  LeadConversion = 'LEAD_CONVERSION',
  Linkedin = 'LINKEDIN',
  Organic = 'ORGANIC',
}

/** List of available Follows::TargetType Enums of Core domain */
export enum IFollowTargetTypeEnum {
  Offering = 'OFFERING',
  Tag = 'TAG',
  User = 'USER',
}

/** Possible types of targets for Follow */
export type IFollowTargetUnion = IOffering | ITagsTag | IUser;

export type IFooter = INode & {
  __typename?: 'Footer';
  content: Scalars['String']['output'];
  /** @deprecated Type instead */
  footerType: IFooterTypeEnum;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: IFooterTypeEnum;
};

export enum IFooterTypeEnum {
  HostingEntity = 'HOSTING_ENTITY',
  Section = 'SECTION',
}

export type IFounderProfileFeaturedInvestmentReasons = {
  featuredInvestmentReasons?: Maybe<Array<IOfferingInvestmentReasons>>;
};

export type IFounderProfileOffering = {
  offering?: Maybe<IOffering>;
};

export type IFounderProfileUser = {
  user?: Maybe<IUser>;
};

export type IFundAmericaBulkTransfer = {
  __typename?: 'FundAmericaBulkTransfer';
  amount: Scalars['Money']['output'];
  bankReference?: Maybe<Scalars['String']['output']>;
  creditedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  fundAmericaId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  processedManually: Scalars['Boolean']['output'];
  status: Scalars['String']['output'];
};

/** The connection type for FundAmericaBulkTransfer. */
export type IFundAmericaBulkTransferConnection = {
  __typename?: 'FundAmericaBulkTransferConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IFundAmericaBulkTransferEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<IFundAmericaBulkTransfer>>>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IFundAmericaBulkTransferEdge = {
  __typename?: 'FundAmericaBulkTransferEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<IFundAmericaBulkTransfer>;
};

export type IFundAmericaDisbursement = {
  __typename?: 'FundAmericaDisbursement';
  administrationFee?: Maybe<Scalars['Money']['output']>;
  amount: Scalars['Money']['output'];
  commission?: Maybe<Scalars['Money']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  disbursedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  discountReduced?: Maybe<Scalars['Money']['output']>;
  escrowFeesCollected?: Maybe<Scalars['Money']['output']>;
  fundAmericaDisbursementUrl: Scalars['String']['output'];
  fundAmericaId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  paymentMethod?: Maybe<Scalars['String']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  reimbursement?: Maybe<Scalars['Money']['output']>;
  sendTo?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  stripeFeesCollected?: Maybe<Scalars['Money']['output']>;
};

/** The connection type for FundAmericaDisbursement. */
export type IFundAmericaDisbursementConnection = {
  __typename?: 'FundAmericaDisbursementConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IFundAmericaDisbursementEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<IFundAmericaDisbursement>>>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IFundAmericaDisbursementEdge = {
  __typename?: 'FundAmericaDisbursementEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<IFundAmericaDisbursement>;
};

export type IFundAmericaEntity = {
  __typename?: 'FundAmericaEntity';
  fundAmericaId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type IFundAmericaInvestment = {
  __typename?: 'FundAmericaInvestment';
  amount: Scalars['Money']['output'];
  amountInEscrow: Scalars['Money']['output'];
  amountReceived: Scalars['Money']['output'];
  amountRefunded: Scalars['Money']['output'];
  apiCreatedAt: Scalars['ISO8601DateTime']['output'];
  apiUpdatedAt: Scalars['ISO8601DateTime']['output'];
  disbursedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  fundAmericaBrokerDealerUrl: Scalars['String']['output'];
  fundAmericaId: Scalars['String']['output'];
  fundAmericaUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  inEscrowAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  investedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  isShallow: Scalars['Boolean']['output'];
  paymentMethod: Scalars['String']['output'];
  paymentReference: Scalars['String']['output'];
  refundedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  status: Scalars['String']['output'];
};

/** The connection type for FundAmericaInvestment. */
export type IFundAmericaInvestmentConnection = {
  __typename?: 'FundAmericaInvestmentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IFundAmericaInvestmentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<IFundAmericaInvestment>>>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IFundAmericaInvestmentEdge = {
  __typename?: 'FundAmericaInvestmentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<IFundAmericaInvestment>;
};

export type IFundAmericaInvestor = {
  __typename?: 'FundAmericaInvestor';
  fundAmericaId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  primaryEntity: IFundAmericaEntity;
};

/** The connection type for FundAmericaInvestor. */
export type IFundAmericaInvestorConnection = {
  __typename?: 'FundAmericaInvestorConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IFundAmericaInvestorEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<IFundAmericaInvestor>>>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IFundAmericaInvestorEdge = {
  __typename?: 'FundAmericaInvestorEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<IFundAmericaInvestor>;
};

export type IFundAmericaOffering = {
  __typename?: 'FundAmericaOffering';
  disbursements?: Maybe<IFundAmericaDisbursementConnection>;
  fundAmericaBulkTransfers: IFundAmericaBulkTransferConnection;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type IFundAmericaOfferingDisbursementsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IFundAmericaOfferingFundAmericaBulkTransfersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Possible fund status names for republic capital investment instruments */
export enum IFundStatus {
  Fundraising = 'FUNDRAISING',
  FundraisingComplete = 'FUNDRAISING_COMPLETE',
  DealClosing = 'DEAL_CLOSING',
  AwaitingDistribution = 'AWAITING_DISTRIBUTION',
  PartiallyDistributed = 'PARTIALLY_DISTRIBUTED',
  Distributed = 'DISTRIBUTED',
  Terminated = 'TERMINATED',
  Formation = 'FORMATION',
  Investing = 'INVESTING',
  FundComplete = 'FUND_COMPLETE',
}

/** Base GlobalSearch result object type(ElasticSearch objects) */
export type IGlobalSearchBase = INode & {
  __typename?: 'GlobalSearchBase';
  /** ElasticSearch error -> Popular returned */
  errors?: Maybe<IGlobalSearchErrorElastic>;
  /** ID of the object. */
  id: Scalars['ID']['output'];
  /** No results -> Popular returned */
  isPopular: Scalars['Boolean']['output'];
  /** Offering objects */
  offerings?: Maybe<Array<IGlobalSearchOffering>>;
  /** SuggestedTag(Tags) objects */
  suggestedTags?: Maybe<Array<IGlobalSearchTag>>;
  /** Tag objects */
  tags?: Maybe<Array<IGlobalSearchTag>>;
  /** User objects */
  users?: Maybe<Array<IGlobalSearchUser>>;
};

/** ElasticSearch error type */
export type IGlobalSearchErrorElastic = {
  __typename?: 'GlobalSearchErrorElastic';
  extensions: IExtensions;
  locations?: Maybe<Array<ILocation>>;
  message: Scalars['String']['output'];
  path?: Maybe<Array<Scalars['String']['output']>>;
};

/** Offering GlobalSearch result object type(ElasticSearch object) */
export type IGlobalSearchOffering = INode & {
  __typename?: 'GlobalSearchOffering';
  /** Live or Funded */
  campaignStatus: Scalars['String']['output'];
  /** company name from database */
  companyName: Scalars['String']['output'];
  /** Description for deal */
  description?: Maybe<Scalars['String']['output']>;
  /** ID from database */
  id: Scalars['ID']['output'];
  logoImageContentType?: Maybe<Scalars['String']['output']>;
  logoImageFileName?: Maybe<Scalars['String']['output']>;
  logoImageFileSize?: Maybe<Scalars['BigInt']['output']>;
  logoImageUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  logoImageUrl?: Maybe<Scalars['Url']['output']>;
  /** Slug from database */
  slug: Scalars['String']['output'];
  /** state from database */
  state: Scalars['String']['output'];
};

/** Offering GlobalSearch result object type(ElasticSearch object) */
export type IGlobalSearchOfferingLogoImageUrlArgs = {
  extension?: InputMaybe<IFileProcessingImgproxyExtensionEnum>;
  gravity?: InputMaybe<IFileProcessingImgproxyGravityEnum>;
  resize?: InputMaybe<IFileProcessingImgproxyResizeInput>;
};

export type IGlobalSearchResolvers = {
  /** Returns global search results */
  globalSearch: IGlobalSearchBase;
};

export type IGlobalSearchResolversGlobalSearchArgs = {
  q?: InputMaybe<Scalars['String']['input']>;
};

/** Tag(Category) GlobalSearch result object type(ElasticSearch object) */
export type IGlobalSearchTag = INode & {
  __typename?: 'GlobalSearchTag';
  /** Color code of tag */
  colorHex?: Maybe<Scalars['String']['output']>;
  /** Display name attribute */
  displayName: Scalars['String']['output'];
  /** ID from database */
  id: Scalars['ID']['output'];
  /** List of keywords of tag */
  keywords?: Maybe<Array<Scalars['String']['output']>>;
  /** Name of parent tag */
  parentName?: Maybe<Scalars['String']['output']>;
  /** Slug from database */
  slug: Scalars['String']['output'];
};

/** User GlobalSearch result object type(ElasticSearch object) */
export type IGlobalSearchUser = INode & {
  __typename?: 'GlobalSearchUser';
  avatarContentType?: Maybe<Scalars['String']['output']>;
  avatarFileName?: Maybe<Scalars['String']['output']>;
  avatarFileSize?: Maybe<Scalars['BigInt']['output']>;
  avatarUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  avatarUrl?: Maybe<Scalars['Url']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  /** ID from database */
  id: Scalars['ID']['output'];
  /** Quantity of visible user's investments */
  investmentsQuantity: Scalars['Int']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  /** Slug from database */
  slug: Scalars['String']['output'];
};

/** User GlobalSearch result object type(ElasticSearch object) */
export type IGlobalSearchUserAvatarUrlArgs = {
  extension?: InputMaybe<IFileProcessingImgproxyExtensionEnum>;
  gravity?: InputMaybe<IFileProcessingImgproxyGravityEnum>;
  resize?: InputMaybe<IFileProcessingImgproxyResizeInput>;
};

export type IGroupDealPost = IPostInterface & {
  __typename?: 'GroupDealPost';
  affiliation?: Maybe<Scalars['String']['output']>;
  /** Amount co-invested per offering, USD */
  amountCoinvested: Scalars['Int']['output'];
  amountInvested?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use xApprovedAt instead */
  approvedAt: Scalars['ISO8601DateTime']['output'];
  body: Scalars['String']['output'];
  comments?: Maybe<Array<IComment>>;
  commentsCount: Scalars['Int']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  deletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  downvoted: Scalars['Boolean']['output'];
  flaggedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  headline?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Number of investors from current group */
  investorsCount: Scalars['Int']['output'];
  markedAsSpamByUserId?: Maybe<Scalars['Int']['output']>;
  markedHiddenByUserId?: Maybe<Scalars['Int']['output']>;
  offering: IOffering;
  ownerId: Scalars['ID']['output'];
  ownerType: Scalars['String']['output'];
  pinned: Scalars['Boolean']['output'];
  pinnedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  rating: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  spam?: Maybe<Scalars['Boolean']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  upvoted: Scalars['Boolean']['output'];
  user: IUser;
  /** Permitted actions for deal post */
  userPermissions?: Maybe<IInvestorGroupDealPostUserPermissions>;
  xApprovedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/** The connection type for GroupDealPost. */
export type IGroupDealPostConnection = {
  __typename?: 'GroupDealPostConnection';
  /** A list of edges. */
  edges: Array<IGroupDealPostEdge>;
  /** A list of nodes. */
  nodes: Array<IGroupDealPost>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Autogenerated input type of GroupDealPostCreate */
export type IGroupDealPostCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dealPost: IDealPostInput;
  slug: Scalars['String']['input'];
};

/** Autogenerated return type of GroupDealPostCreate. */
export type IGroupDealPostCreatePayload = {
  __typename?: 'GroupDealPostCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dealPost?: Maybe<IGroupDealPost>;
  errors?: Maybe<Array<IValidationError>>;
};

/** An edge in a connection. */
export type IGroupDealPostEdge = {
  __typename?: 'GroupDealPostEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: IGroupDealPost;
};

/** A special type for previewing deal posts */
export type IGroupDealPostPreview = IPostInterface & {
  __typename?: 'GroupDealPostPreview';
  affiliation?: Maybe<Scalars['String']['output']>;
  /** Amount co-invested per offering, USD */
  amountCoinvested: Scalars['Int']['output'];
  amountInvested?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use xApprovedAt instead */
  approvedAt: Scalars['ISO8601DateTime']['output'];
  body: Scalars['String']['output'];
  comments?: Maybe<Array<IComment>>;
  commentsCount: Scalars['Int']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  deletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  downvoted: Scalars['Boolean']['output'];
  flaggedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  headline?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Number of investors from current group */
  investorsCount: Scalars['Int']['output'];
  markedAsSpamByUserId?: Maybe<Scalars['Int']['output']>;
  markedHiddenByUserId?: Maybe<Scalars['Int']['output']>;
  offering: IOffering;
  ownerId: Scalars['ID']['output'];
  ownerType: Scalars['String']['output'];
  pinned: Scalars['Boolean']['output'];
  pinnedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  rating: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  spam?: Maybe<Scalars['Boolean']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  upvoted: Scalars['Boolean']['output'];
  user: IUser;
  /** Permitted actions for deal post */
  userPermissions?: Maybe<IInvestorGroupDealPostUserPermissions>;
  xApprovedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/** Autogenerated input type of GroupLeave */
export type IGroupLeaveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
};

/** Autogenerated return type of GroupLeave. */
export type IGroupLeavePayload = {
  __typename?: 'GroupLeavePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Autogenerated input type of GroupUpdate */
export type IGroupUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  investorGroup: IInvestorGroupInput;
};

/** Autogenerated return type of GroupUpdate. */
export type IGroupUpdatePayload = {
  __typename?: 'GroupUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  investorGroup?: Maybe<IInvestorGroup>;
};

/** Article object of Help Center domain */
export type IHelpCenterArticle = {
  __typename?: 'HelpCenterArticle';
  body: Scalars['String']['output'];
  categoryId: Scalars['ID']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  helpCategory: IHelpCenterCategory;
  id: Scalars['ID']['output'];
  publishedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  slug: Scalars['String']['output'];
  state: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Permitted actions for help center article */
  userPermissions: IHelpCenterArticleUserPermissions;
  views: Scalars['Int']['output'];
};

/** The connection type for HelpCenterArticle. */
export type IHelpCenterArticleConnection = {
  __typename?: 'HelpCenterArticleConnection';
  /** A list of edges. */
  edges: Array<IHelpCenterArticleEdge>;
  /** A list of nodes. */
  nodes: Array<IHelpCenterArticle>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Autogenerated input type of HelpCenterArticleCreate */
export type IHelpCenterArticleCreateInput = {
  article: IHelpCenterArticleInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of HelpCenterArticleCreate. */
export type IHelpCenterArticleCreatePayload = {
  __typename?: 'HelpCenterArticleCreatePayload';
  article?: Maybe<IHelpCenterArticle>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Autogenerated input type of HelpCenterArticleDelete */
export type IHelpCenterArticleDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of HelpCenterArticleDelete. */
export type IHelpCenterArticleDeletePayload = {
  __typename?: 'HelpCenterArticleDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

/** An edge in a connection. */
export type IHelpCenterArticleEdge = {
  __typename?: 'HelpCenterArticleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: IHelpCenterArticle;
};

export type IHelpCenterArticleInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** List of available orders for Article Index query of HelpCenter domain */
export enum IHelpCenterArticleOrderEnum {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
}

/** Autogenerated input type of HelpCenterArticlePublish */
export type IHelpCenterArticlePublishInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of HelpCenterArticlePublish. */
export type IHelpCenterArticlePublishPayload = {
  __typename?: 'HelpCenterArticlePublishPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

/** List of available Article states of HelpCenter domain */
export enum IHelpCenterArticleStateEnum {
  Created = 'CREATED',
  Published = 'PUBLISHED',
}

/** Autogenerated input type of HelpCenterArticleUnpublish */
export type IHelpCenterArticleUnpublishInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of HelpCenterArticleUnpublish. */
export type IHelpCenterArticleUnpublishPayload = {
  __typename?: 'HelpCenterArticleUnpublishPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Autogenerated input type of HelpCenterArticleUpdate */
export type IHelpCenterArticleUpdateInput = {
  article: IHelpCenterArticleInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of HelpCenterArticleUpdate. */
export type IHelpCenterArticleUpdatePayload = {
  __typename?: 'HelpCenterArticleUpdatePayload';
  article?: Maybe<IHelpCenterArticle>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

/** User permissions exposed to a client for Article object of HelpCenter domain */
export type IHelpCenterArticleUserPermissions = {
  __typename?: 'HelpCenterArticleUserPermissions';
  canDestroy: Scalars['Boolean']['output'];
  canPublish: Scalars['Boolean']['output'];
  canShow: Scalars['Boolean']['output'];
  canUnpublish: Scalars['Boolean']['output'];
  canUpdate: Scalars['Boolean']['output'];
};

/** Category object of Help Center domain */
export type IHelpCenterCategory = {
  __typename?: 'HelpCenterCategory';
  createdAt: Scalars['ISO8601DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  helpArticles?: Maybe<Array<IHelpCenterArticle>>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parentCategory?: Maybe<IHelpCenterCategory>;
  priority?: Maybe<Scalars['Int']['output']>;
  showOnHomepage: Scalars['Boolean']['output'];
  slug: Scalars['String']['output'];
  subcategories?: Maybe<Array<IHelpCenterCategory>>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Permitted actions for help center category */
  userPermissions: IHelpCenterCategoryUserPermissions;
};

/** The connection type for HelpCenterCategory. */
export type IHelpCenterCategoryConnection = {
  __typename?: 'HelpCenterCategoryConnection';
  /** A list of edges. */
  edges: Array<IHelpCenterCategoryEdge>;
  /** A list of nodes. */
  nodes: Array<IHelpCenterCategory>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IHelpCenterCategoryEdge = {
  __typename?: 'HelpCenterCategoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: IHelpCenterCategory;
};

/** User permissions exposed to a client for Category object of HelpCenter domain */
export type IHelpCenterCategoryUserPermissions = {
  __typename?: 'HelpCenterCategoryUserPermissions';
  canShow: Scalars['Boolean']['output'];
};

export type IHelpCenterMutations = {
  /** Create Article object of HelpCenter domain */
  helpCenterArticleCreate?: Maybe<IHelpCenterArticleCreatePayload>;
  /** Delete Article object of HelpCenter domain by id */
  helpCenterArticleDelete?: Maybe<IHelpCenterArticleDeletePayload>;
  /** Publish Article object of HelpCenter domain by id */
  helpCenterArticlePublish?: Maybe<IHelpCenterArticlePublishPayload>;
  /** UnPublish Article object of HelpCenter domain by id */
  helpCenterArticleUnpublish?: Maybe<IHelpCenterArticleUnpublishPayload>;
  /** Update Article object of HelpCenter domain by id */
  helpCenterArticleUpdate?: Maybe<IHelpCenterArticleUpdatePayload>;
};

export type IHelpCenterMutationsHelpCenterArticleCreateArgs = {
  input: IHelpCenterArticleCreateInput;
};

export type IHelpCenterMutationsHelpCenterArticleDeleteArgs = {
  input: IHelpCenterArticleDeleteInput;
};

export type IHelpCenterMutationsHelpCenterArticlePublishArgs = {
  input: IHelpCenterArticlePublishInput;
};

export type IHelpCenterMutationsHelpCenterArticleUnpublishArgs = {
  input: IHelpCenterArticleUnpublishInput;
};

export type IHelpCenterMutationsHelpCenterArticleUpdateArgs = {
  input: IHelpCenterArticleUpdateInput;
};

export type IHelpCenterResolvers = {
  /** List of Categories articles with ability to filter the result scope */
  helpCenterArticles: IHelpCenterArticleConnection;
  /** List of Categories with ability to filter the result scope */
  helpCenterCategories: IHelpCenterCategoryConnection;
  /** Download Gaap Example PDF of HelpCenter domain */
  helpCenterGaapExample: Scalars['Url']['output'];
  /** Download Gaap Example PDF of HelpCenter domain */
  helpCenterOpendealBcp: Scalars['Url']['output'];
};

export type IHelpCenterResolversHelpCenterArticlesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<IHelpCenterArticleOrderEnum>>;
  search?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Array<IHelpCenterArticleStateEnum>>;
};

export type IHelpCenterResolversHelpCenterCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  articleSlug?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** Amount and details of a user's holdings in an asset */
export type IHolding = {
  __typename?: 'Holding';
  allocations?: Maybe<Array<ISecondaryOrderAllocation>>;
  /** @deprecated Changed to union, use x prefixed field instead */
  asset: IAsset;
  dateIssued?: Maybe<Scalars['ISO8601DateTime']['output']>;
  entity?: Maybe<IEntityUnion>;
  grantingAction: ISecondaryGrantingAction;
  holdingCostCents?: Maybe<Scalars['BigInt']['output']>;
  id: Scalars['ID']['output'];
  indicativeFaceValueCents?: Maybe<Scalars['BigInt']['output']>;
  investorProfile: IInvestorProfile;
  profitCents?: Maybe<Scalars['BigInt']['output']>;
  profitPercentage?: Maybe<Scalars['BigDecimal']['output']>;
  rootHolding?: Maybe<IHolding>;
  state?: Maybe<IHoldingStateEnum>;
  units?: Maybe<Scalars['BigInt']['output']>;
  xAsset: IAssetInterface;
  /** @deprecated Fix in place, please use non-prefixed value */
  xIndicativeFaceValueCents?: Maybe<Scalars['BigInt']['output']>;
  /** @deprecated Use non-x-prefixed field instead */
  xProfitCents?: Maybe<Scalars['BigInt']['output']>;
  /** @deprecated Use non-x-prefixed field instead */
  xProfitPercentage?: Maybe<Scalars['BigDecimal']['output']>;
  /** @deprecated Use non-x-prefixed field instead */
  xState?: Maybe<IHoldingStateEnum>;
  /** @deprecated Use non-x-prefixed field instead */
  xUnits?: Maybe<Scalars['BigInt']['output']>;
};

/** The connection type for Holding. */
export type IHoldingConnection = {
  __typename?: 'HoldingConnection';
  /** A list of edges. */
  edges: Array<IHoldingEdge>;
  /** A list of nodes. */
  nodes: Array<IHolding>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IHoldingEdge = {
  __typename?: 'HoldingEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: IHolding;
};

export enum IHoldingFilter {
  AlertAchFailed = 'alert_ach_failed',
  AlertFundAmericaInvestmentsTotalTooLarge = 'alert_fund_america_investments_total_too_large',
  AlertFundAmericaInvestmentsTotalTooSmall = 'alert_fund_america_investments_total_too_small',
  AlertInactiveAchInvestmentsWithFundsInEscrow = 'alert_inactive_ach_investments_with_funds_in_escrow',
  AlertInactiveCardInvestmentsWithFundsInEscrow = 'alert_inactive_card_investments_with_funds_in_escrow',
  AlertInactiveWireInvestmentsWithFundsInEscrow = 'alert_inactive_wire_investments_with_funds_in_escrow',
  AlertIncorrectAchTransfers = 'alert_incorrect_ach_transfers',
  AlertInvestedInvestmentsWithFailedPayments = 'alert_invested_investments_with_failed_payments',
  AlertNotReceivedWireInvestments = 'alert_not_received_wire_investments',
  AlertNotSynchronized = 'alert_not_synchronized',
  AlertOversubscribed = 'alert_oversubscribed',
  AlertStripeChargesExcessive = 'alert_stripe_charges_excessive',
  AlertStripeChargesInsufficient = 'alert_stripe_charges_insufficient',
  AlertUndersubscribed = 'alert_undersubscribed',
  AlertUnfundedBitpayInvestments = 'alert_unfunded_bitpay_investments',
}

/** Holdings can be transferred or not query Errors */
export enum IHoldingsCanBeTransferredErrors {
  InvalidBuyer = 'INVALID_BUYER',
  InvalidSeller = 'INVALID_SELLER',
  RestrictedHolding = 'RESTRICTED_HOLDING',
}

export type IHoldingsCanBeTransferredInput = {
  taHoldingIds: Array<Scalars['String']['input']>;
  buyerInvestorEntityId?: InputMaybe<Scalars['BigInt']['input']>;
  buyerInvestorEntityType?: InputMaybe<IRepublicInvestorEntityType>;
  refreshHoldings?: InputMaybe<Scalars['Boolean']['input']>;
  simulateError?: InputMaybe<IHoldingsCanBeTransferredErrors>;
};

/** States of holdings */
export enum IHoldingStateEnum {
  Cancelled = 'CANCELLED',
  Created = 'CREATED',
  Transferred = 'TRANSFERRED',
}

export type IHoldingsTransferHealthCheck = {
  __typename?: 'HoldingsTransferHealthCheck';
  isTransferAllowed: Scalars['Boolean']['output'];
  holdings: Array<ITransferAgentHolding>;
  taBuyerAccount?: Maybe<ITransferAgentAccount>;
  taSellerAccount?: Maybe<ITransferAgentAccount>;
};

/** Autogenerated input type of IdentityCreate */
export type IIdentityCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  identity: IUserInvestorProfileIdentityInput;
};

/** Autogenerated return type of IdentityCreate. */
export type IIdentityCreatePayload = {
  __typename?: 'IdentityCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  identity?: Maybe<IUserInvestorProfileIdentity>;
};

/** Identity::Document Type in Core Domain */
export type IIdentityDocument = {
  __typename?: 'IdentityDocument';
  id: Scalars['ID']['output'];
  presignedFileUpload?: Maybe<IPresignedFileUpload>;
};

/** Autogenerated input type of IdentityDocumentCreate */
export type IIdentityDocumentCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  documents: Array<IIdentityDocumentInput>;
  ownerId: Scalars['ID']['input'];
  ownerType: IIdentityOwnerTypeEnum;
};

/** Autogenerated return type of IdentityDocumentCreate. */
export type IIdentityDocumentCreatePayload = {
  __typename?: 'IdentityDocumentCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Input parameters for Identity document */
export type IIdentityDocumentInput = {
  directUploadAttachmentUuid: Scalars['String']['input'];
  documentType: IIdentityDocumentTypeEnum;
};

/** Enum of possible types of identity document */
export enum IIdentityDocumentTypeEnum {
  Beneficiaries = 'BENEFICIARIES',
  GeneralUpload = 'GENERAL_UPLOAD',
  Identity = 'IDENTITY',
  Owners = 'OWNERS',
  ProofOfFormation = 'PROOF_OF_FORMATION',
  ProofOfOwnership = 'PROOF_OF_OWNERSHIP',
  Settlement = 'SETTLEMENT',
  Trustees = 'TRUSTEES',
}

/** Enum of possible types of identity owner class */
export enum IIdentityOwnerTypeEnum {
  Corporation = 'CORPORATION',
  Individual = 'INDIVIDUAL',
  JointSpouse = 'JOINT_SPOUSE',
  JointTenant = 'JOINT_TENANT',
  Llc = 'LLC',
  Partnership = 'PARTNERSHIP',
  SelfDirectedIra = 'SELF_DIRECTED_IRA',
  Trust = 'TRUST',
}

export type IIdentityVerification = {
  __typename?: 'IdentityVerification';
  documents: Array<IIdentityDocument>;
  id: Scalars['ID']['output'];
  state: IIdentityVerificationStatesEnum;
};

export type IIdentityVerificationBanner = {
  __typename?: 'IdentityVerificationBanner';
  user: IUser;
};

export enum IIdentityVerificationStatesEnum {
  Abandoned = 'ABANDONED',
  Approved = 'APPROVED',
  Created = 'CREATED',
  DocumentsRequested = 'DOCUMENTS_REQUESTED',
  Expired = 'EXPIRED',
  FollowupRequested = 'FOLLOWUP_REQUESTED',
  Rejected = 'REJECTED',
  ReviewRequested = 'REVIEW_REQUESTED',
}

export type IImage = {
  __typename?: 'Image';
  croppedLocation: Scalars['String']['output'];
  defaultLocation: Scalars['String']['output'];
  defaultUrl?: Maybe<Scalars['String']['output']>;
};

/** Implied Valuation Query Type */
export type IImpliedValuation = {
  __typename?: 'ImpliedValuation';
  amountCents?: Maybe<Scalars['BigInt']['output']>;
  percentChange?: Maybe<Scalars['Float']['output']>;
};

/** One single republic capital investment instrument */
export type IInstrumentType = IBasePayloadType & {
  __typename?: 'InstrumentType';
  errors?: Maybe<Array<IValidationError>>;
  id: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  fundStatus: IFundStatus;
};

/** Investment Type for Core Domain */
export type IInvestment = {
  __typename?: 'Investment';
  actionRequired?: Maybe<Scalars['Boolean']['output']>;
  actionRequiredDeadline?: Maybe<Scalars['Int']['output']>;
  adminCancellationReason?: Maybe<Scalars['String']['output']>;
  allowOneTimePaymentMethodUpdate: Scalars['Boolean']['output'];
  amountCents: Scalars['Int']['output'];
  amountPublic: Scalars['Boolean']['output'];
  banners?: Maybe<Array<IInvestmentBanner>>;
  canBeReinvested?: Maybe<Scalars['Boolean']['output']>;
  cancellationReason?: Maybe<Scalars['String']['output']>;
  cancelledAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  cancelledReservation?: Maybe<Scalars['Boolean']['output']>;
  cardTxMessage?: Maybe<Scalars['String']['output']>;
  completedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  cryptoPayoutAmount?: Maybe<Scalars['Float']['output']>;
  entity?: Maybe<IEntityUnion>;
  events?: Maybe<Array<IInvestmentEvent>>;
  exit?: Maybe<IInvestmentExit>;
  exited?: Maybe<Scalars['Boolean']['output']>;
  finalizedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  fullyPaid?: Maybe<Scalars['Boolean']['output']>;
  hasWalletAddress?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  identityVerificationRequired?: Maybe<Scalars['Boolean']['output']>;
  investedViaAutopilot?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use xInvestmentReason instead */
  investmentReason: IOfferingInvestmentReasons;
  investmentType: IInvestmentTypeEnum;
  investorCrowdSafeUrl?: Maybe<Scalars['Url']['output']>;
  investorProfile?: Maybe<IInvestorProfile>;
  notCompleted?: Maybe<Scalars['Boolean']['output']>;
  offering: IOffering;
  offeringsRollingCloses: Array<IOfferingRollingClose>;
  paymentMethod?: Maybe<IPaymentMethodUnion>;
  paymentReceivedDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  perk?: Maybe<IOfferingPerk>;
  promotionCodeAmountCents: Scalars['Int']['output'];
  reservationConfirmationRequired?: Maybe<Scalars['Boolean']['output']>;
  skipSocialStep?: Maybe<Scalars['Boolean']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  state: IInvestmentStateEnum;
  timeLeft?: Maybe<Scalars['Int']['output']>;
  tokenAddressId?: Maybe<Scalars['ID']['output']>;
  /** @deprecated Use xTokensInvestmentOptionId instead */
  tokensInvestmentOptionId: Scalars['Int']['output'];
  type: IInvestmentStiTypeEnum;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  userCancellationReason?: Maybe<Scalars['String']['output']>;
  visibility?: Maybe<Scalars['String']['output']>;
  wireProvided?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use x version */
  wiringBankIsCustomer: Scalars['Boolean']['output'];
  /** @deprecated Use x version */
  wiringBankIsFatf: Scalars['Boolean']['output'];
  wiringBankName?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use actionRequired instead */
  xActionRequired?: Maybe<Scalars['Boolean']['output']>;
  xInvestmentReason?: Maybe<IOfferingInvestmentReasons>;
  xTokensInvestmentOptionId?: Maybe<Scalars['Int']['output']>;
  xWiringBankIsCustomer?: Maybe<Scalars['Boolean']['output']>;
  xWiringBankIsFatf?: Maybe<Scalars['Boolean']['output']>;
};

/** Action Required Type for Core Domain(copy of Investment) */
export type IInvestmentActionRequired = {
  __typename?: 'InvestmentActionRequired';
  actionRequired?: Maybe<Scalars['Boolean']['output']>;
  actionRequiredDeadline?: Maybe<Scalars['Int']['output']>;
  actionRequiredRules?: Maybe<Array<IInvestmentActionRequiredRuleEnum>>;
  adminCancellationReason?: Maybe<Scalars['String']['output']>;
  allowOneTimePaymentMethodUpdate: Scalars['Boolean']['output'];
  amountCents: Scalars['Int']['output'];
  amountPublic: Scalars['Boolean']['output'];
  banners?: Maybe<Array<IInvestmentBanner>>;
  canBeReinvested?: Maybe<Scalars['Boolean']['output']>;
  cancellationReason?: Maybe<Scalars['String']['output']>;
  cancelledAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  cancelledReservation?: Maybe<Scalars['Boolean']['output']>;
  cardTxMessage?: Maybe<Scalars['String']['output']>;
  completedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  cryptoPayoutAmount?: Maybe<Scalars['Float']['output']>;
  entity?: Maybe<IEntityUnion>;
  events?: Maybe<Array<IInvestmentEvent>>;
  exit?: Maybe<IInvestmentExit>;
  exited?: Maybe<Scalars['Boolean']['output']>;
  finalizedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  fullyPaid?: Maybe<Scalars['Boolean']['output']>;
  hasWalletAddress?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  identityVerificationRequired?: Maybe<Scalars['Boolean']['output']>;
  investedViaAutopilot?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use xInvestmentReason instead */
  investmentReason: IOfferingInvestmentReasons;
  investmentType: IInvestmentTypeEnum;
  investorCrowdSafeUrl?: Maybe<Scalars['Url']['output']>;
  investorProfile?: Maybe<IInvestorProfile>;
  notCompleted?: Maybe<Scalars['Boolean']['output']>;
  offering: IOffering;
  offeringsRollingCloses: Array<IOfferingRollingClose>;
  paymentMethod?: Maybe<IPaymentMethodUnion>;
  paymentReceivedDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  perk?: Maybe<IOfferingPerk>;
  promotionCodeAmountCents: Scalars['Int']['output'];
  reservationConfirmationRequired?: Maybe<Scalars['Boolean']['output']>;
  skipSocialStep?: Maybe<Scalars['Boolean']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  state: IInvestmentStateEnum;
  timeLeft?: Maybe<Scalars['Int']['output']>;
  tokenAddressId?: Maybe<Scalars['ID']['output']>;
  /** @deprecated Use xTokensInvestmentOptionId instead */
  tokensInvestmentOptionId: Scalars['Int']['output'];
  type: IInvestmentStiTypeEnum;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  userCancellationReason?: Maybe<Scalars['String']['output']>;
  visibility?: Maybe<Scalars['String']['output']>;
  wireProvided?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use x version */
  wiringBankIsCustomer: Scalars['Boolean']['output'];
  /** @deprecated Use x version */
  wiringBankIsFatf: Scalars['Boolean']['output'];
  wiringBankName?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use actionRequired instead */
  xActionRequired?: Maybe<Scalars['Boolean']['output']>;
  xInvestmentReason?: Maybe<IOfferingInvestmentReasons>;
  xTokensInvestmentOptionId?: Maybe<Scalars['Int']['output']>;
  xWiringBankIsCustomer?: Maybe<Scalars['Boolean']['output']>;
  xWiringBankIsFatf?: Maybe<Scalars['Boolean']['output']>;
};

/** The Action Required Rule type the user must complete */
export enum IInvestmentActionRequiredRuleEnum {
  AccreditationVerificationRequired = 'ACCREDITATION_VERIFICATION_REQUIRED',
  AccreditationVerificationRequiredNote = 'ACCREDITATION_VERIFICATION_REQUIRED_NOTE',
  AltoIraPayment = 'ALTO_IRA_PAYMENT',
  AltoIraPaymentNote = 'ALTO_IRA_PAYMENT_NOTE',
  BitpayPayment = 'BITPAY_PAYMENT',
  BitpayPaymentNote = 'BITPAY_PAYMENT_NOTE',
  Conversion = 'CONVERSION',
  CreateCustodialAccount = 'CREATE_CUSTODIAL_ACCOUNT',
  CreateCustodialAccountForHistoricInvestment = 'CREATE_CUSTODIAL_ACCOUNT_FOR_HISTORIC_INVESTMENT',
  CreateRepublicWallet = 'CREATE_REPUBLIC_WALLET',
  CryptoTransferPayment = 'CRYPTO_TRANSFER_PAYMENT',
  DocumentExpired = 'DOCUMENT_EXPIRED',
  EmailConfirmationRequired = 'EMAIL_CONFIRMATION_REQUIRED',
  EmailConfirmationRequiredNote = 'EMAIL_CONFIRMATION_REQUIRED_NOTE',
  JumioUnsupportedIdType = 'JUMIO_UNSUPPORTED_ID_TYPE',
  KybStatus = 'KYB_STATUS',
  KybStatusNote = 'KYB_STATUS_NOTE',
  KycAmlStatus = 'KYC_AML_STATUS',
  KycAmlStatusNote = 'KYC_AML_STATUS_NOTE',
  MaterialChange = 'MATERIAL_CHANGE',
  OneTimePaymentMethodUpdate = 'ONE_TIME_PAYMENT_METHOD_UPDATE',
  PaymentFailed = 'PAYMENT_FAILED',
  ProofOfAddress = 'PROOF_OF_ADDRESS',
  RegBiQuizRequired = 'REG_BI_QUIZ_REQUIRED',
  ReservationConfirmation = 'RESERVATION_CONFIRMATION',
  WaitlistedReservationConfirmation = 'WAITLISTED_RESERVATION_CONFIRMATION',
  WalletAddressRequired = 'WALLET_ADDRESS_REQUIRED',
  WirePayment = 'WIRE_PAYMENT',
  ZeroHashPayment = 'ZERO_HASH_PAYMENT',
}

export type IInvestmentAgreementsInput = {
  alcoholRepresentationWarranty?: InputMaybe<Scalars['Boolean']['input']>;
  esignDisclosure?: InputMaybe<Scalars['Boolean']['input']>;
  over21Warranty?: InputMaybe<Scalars['Boolean']['input']>;
  termOfInvestment?: InputMaybe<Scalars['String']['input']>;
};

/** Investor::Badge Type in Core Domain */
export type IInvestmentBadge = {
  __typename?: 'InvestmentBadge';
  amountCents?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  state?: Maybe<Scalars['String']['output']>;
};

/** Investments::Event Type in Core Domain */
export type IInvestmentBanner = {
  __typename?: 'InvestmentBanner';
  email?: Maybe<Scalars['String']['output']>;
  failedPaymentInfo?: Maybe<Scalars['String']['output']>;
  failedPaymentMethod?: Maybe<Scalars['String']['output']>;
  materialChangesDate?: Maybe<Scalars['String']['output']>;
  materialChangesInvestmentId?: Maybe<Scalars['ID']['output']>;
  materialChangesText?: Maybe<Scalars['String']['output']>;
  paymentFailReason?: Maybe<Scalars['String']['output']>;
  type: IInvestmentsBannerTypeEnum;
  wireInstruction?: Maybe<IInvestmentWireInstruction>;
};

/** Autogenerated input type of InvestmentCancel */
export type IInvestmentCancelInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  input: IInvestmentCancellationInput;
};

export type IInvestmentCancellationInput = {
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of InvestmentCancel. */
export type IInvestmentCancelPayload = {
  __typename?: 'InvestmentCancelPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  investment?: Maybe<IInvestment>;
};

/** The connection type for Investment. */
export type IInvestmentConnection = {
  __typename?: 'InvestmentConnection';
  /** A list of edges. */
  edges: Array<IInvestmentEdge>;
  /** A list of nodes. */
  nodes: Array<IInvestment>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Autogenerated input type of InvestmentCreate */
export type IInvestmentCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  investment: IInvestmentCreationInput;
};

/** Autogenerated return type of InvestmentCreate. */
export type IInvestmentCreatePayload = {
  __typename?: 'InvestmentCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  investment?: Maybe<IInvestment>;
};

export type IInvestmentCreationInput = {
  /** @deprecated use xAgreements instead */
  agreements?: InputMaybe<IInvestmentAgreementsInput>;
  /** @deprecated Not used anymore */
  altoIra?: InputMaybe<IPaymentMethodAltoIraInput>;
  /** @deprecated Not used anymore */
  card?: InputMaybe<IPaymentMethodCardInput>;
  confirmRegBi?: InputMaybe<Scalars['Boolean']['input']>;
  entity?: InputMaybe<IInvestmentEntityInput>;
  /** @deprecated Not used anymore */
  identity?: InputMaybe<IUserInvestorProfileIdentityInput>;
  investment: IInvestmentInput;
  offeringSlug: Scalars['String']['input'];
  promotionCode?: InputMaybe<IInvestmentPromotionCodeInput>;
  w8Ben?: InputMaybe<IInvestmentW8BenInput>;
  w9?: InputMaybe<IInvestmentW9Input>;
  wire?: InputMaybe<IPaymentMethodWireInput>;
  xAgreements?: InputMaybe<IInvestmentXAgreementsInput>;
};

/** An edge in a connection. */
export type IInvestmentEdge = {
  __typename?: 'InvestmentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: IInvestment;
};

export type IInvestmentEntityConnection = {
  __typename?: 'InvestmentEntityConnection';
  previousCount: Scalars['Int']['output'];
  currentCount: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  edges: Array<IInvestmentEntityTypeEdge>;
  pageInfo: IPageInfo;
};

export type IInvestmentEntityCorporation = {
  __typename?: 'InvestmentEntityCorporation';
  address?: Maybe<IAddress>;
  authorizedSignatory?: Maybe<Scalars['String']['output']>;
  countryOfIncorporation?: Maybe<Scalars['String']['output']>;
  dateOfIncorporation?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  multipleOwnerLimit: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['PhoneNumber']['output']>;
  soloOwner?: Maybe<Scalars['Boolean']['output']>;
  stateOfIncorporation?: Maybe<Scalars['String']['output']>;
  taxId?: Maybe<Scalars['Ssn']['output']>;
};

export type IInvestmentEntityCorporationInput = {
  address?: InputMaybe<IAddressInput>;
  authorizedSignatory?: InputMaybe<Scalars['String']['input']>;
  countryOfIncorporation?: InputMaybe<Scalars['String']['input']>;
  dateOfIncorporation?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  multipleOwnerLimit?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['PhoneNumber']['input']>;
  soloOwner?: InputMaybe<Scalars['Boolean']['input']>;
  stateOfIncorporation?: InputMaybe<Scalars['String']['input']>;
  taxId?: InputMaybe<Scalars['Ssn']['input']>;
};

export type IInvestmentEntityIndividual = {
  __typename?: 'InvestmentEntityIndividual';
  id: Scalars['ID']['output'];
};

export type IInvestmentEntityInput = {
  corporation?: InputMaybe<IInvestmentEntityCorporationInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  jointSpouse?: InputMaybe<IInvestmentEntityJointSpouseInput>;
  jointTenant?: InputMaybe<IInvestmentEntityJointTenantInput>;
  llc?: InputMaybe<IInvestmentEntityLlcInput>;
  partnership?: InputMaybe<IInvestmentEntityPartnershipInput>;
  selfDirectedIra?: InputMaybe<IInvestmentEntitySelfDirectedIraInput>;
  trust?: InputMaybe<IInvestmentEntityTrustInput>;
  type?: InputMaybe<IInvestmentEntityTypeEnum>;
};

export type IInvestmentEntityJointSpouse = {
  __typename?: 'InvestmentEntityJointSpouse';
  dateOfBirth?: Maybe<Scalars['ISO8601DateTime']['output']>;
  documentType?: Maybe<IInvestmentEntityJointSpouseDocumentTypeEnum>;
  documentValue?: Maybe<Scalars['String']['output']>;
  fullLegalName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export enum IInvestmentEntityJointSpouseDocumentTypeEnum {
  Passport = 'PASSPORT',
  Ssn = 'SSN',
}

export type IInvestmentEntityJointSpouseInput = {
  dateOfBirth?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  documentType?: InputMaybe<IInvestmentEntityJointSpouseDocumentTypeEnum>;
  documentValue?: InputMaybe<Scalars['String']['input']>;
  fullLegalName?: InputMaybe<Scalars['String']['input']>;
};

export type IInvestmentEntityJointTenant = {
  __typename?: 'InvestmentEntityJointTenant';
  dateOfBirth?: Maybe<Scalars['ISO8601DateTime']['output']>;
  fullLegalName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  ssn?: Maybe<Scalars['Ssn']['output']>;
};

export type IInvestmentEntityJointTenantInput = {
  dateOfBirth?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  fullLegalName?: InputMaybe<Scalars['String']['input']>;
  ssn?: InputMaybe<Scalars['Ssn']['input']>;
};

export type IInvestmentEntityLlc = {
  __typename?: 'InvestmentEntityLlc';
  address?: Maybe<IAddress>;
  authorizedSignatory?: Maybe<Scalars['String']['output']>;
  countryOfFormation?: Maybe<Scalars['String']['output']>;
  dateOfFormation?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  multipleOwnerLimit: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['PhoneNumber']['output']>;
  soloOwner?: Maybe<Scalars['Boolean']['output']>;
  stateOfFormation?: Maybe<Scalars['String']['output']>;
  taxId?: Maybe<Scalars['Ssn']['output']>;
};

export type IInvestmentEntityLlcInput = {
  address?: InputMaybe<IAddressInput>;
  authorizedSignatory?: InputMaybe<Scalars['String']['input']>;
  countryOfFormation?: InputMaybe<Scalars['String']['input']>;
  dateOfFormation?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  multipleOwnerLimit?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['PhoneNumber']['input']>;
  soloOwner?: InputMaybe<Scalars['Boolean']['input']>;
  stateOfFormation?: InputMaybe<Scalars['String']['input']>;
  taxId?: InputMaybe<Scalars['Ssn']['input']>;
};

export type IInvestmentEntityPartnership = {
  __typename?: 'InvestmentEntityPartnership';
  address?: Maybe<IAddress>;
  authorizedSignatory?: Maybe<Scalars['String']['output']>;
  countryOfFormation?: Maybe<Scalars['String']['output']>;
  dateOfFormation?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  multipleOwnerLimit: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['PhoneNumber']['output']>;
  soloOwner?: Maybe<Scalars['Boolean']['output']>;
  stateOfFormation?: Maybe<Scalars['String']['output']>;
  taxId?: Maybe<Scalars['Ssn']['output']>;
};

export type IInvestmentEntityPartnershipInput = {
  address?: InputMaybe<IAddressInput>;
  authorizedSignatory?: InputMaybe<Scalars['String']['input']>;
  countryOfFormation?: InputMaybe<Scalars['String']['input']>;
  dateOfFormation?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  multipleOwnerLimit?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['PhoneNumber']['input']>;
  soloOwner?: InputMaybe<Scalars['Boolean']['input']>;
  stateOfFormation?: InputMaybe<Scalars['String']['input']>;
  taxId?: InputMaybe<Scalars['Ssn']['input']>;
};

export type IInvestmentEntitySelfDirectedIra = {
  __typename?: 'InvestmentEntitySelfDirectedIra';
  address?: Maybe<IAddress>;
  authorizedSignatory?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  multipleOwnerLimit: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  soloOwner?: Maybe<Scalars['Boolean']['output']>;
  taxId?: Maybe<Scalars['Ssn']['output']>;
};

export type IInvestmentEntitySelfDirectedIraInput = {
  address?: InputMaybe<IAddressInput>;
  authorizedSignatory?: InputMaybe<Scalars['String']['input']>;
  multipleOwnerLimit?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  soloOwner?: InputMaybe<Scalars['Boolean']['input']>;
  taxId?: InputMaybe<Scalars['Ssn']['input']>;
};

export type IInvestmentEntityTrust = {
  __typename?: 'InvestmentEntityTrust';
  address?: Maybe<IAddress>;
  authorizedSignatory?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  multipleOwnerLimit: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['PhoneNumber']['output']>;
  soloOwner?: Maybe<Scalars['Boolean']['output']>;
  taxId?: Maybe<Scalars['Ssn']['output']>;
};

export type IInvestmentEntityTrustInput = {
  address?: InputMaybe<IAddressInput>;
  authorizedSignatory?: InputMaybe<Scalars['String']['input']>;
  multipleOwnerLimit?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['PhoneNumber']['input']>;
  soloOwner?: InputMaybe<Scalars['Boolean']['input']>;
  taxId?: InputMaybe<Scalars['Ssn']['input']>;
};

/** One single republic capital investment entity */
export type IInvestmentEntityType = IBasePayloadType & {
  __typename?: 'InvestmentEntityType';
  errors?: Maybe<Array<IValidationError>>;
  id: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
};

export type IInvestmentEntityTypeEdge = {
  __typename?: 'InvestmentEntityTypeEdge';
  cursor: Scalars['String']['output'];
  node: IInvestmentEntityType;
};

export enum IInvestmentEntityTypeEnum {
  Corporation = 'CORPORATION',
  Individual = 'INDIVIDUAL',
  JointSpouse = 'JOINT_SPOUSE',
  JointTenant = 'JOINT_TENANT',
  Llc = 'LLC',
  Partnership = 'PARTNERSHIP',
  SelfDirectedIra = 'SELF_DIRECTED_IRA',
  Trust = 'TRUST',
}

/** Investments::Event Type in Core Domain */
export type IInvestmentEvent = {
  __typename?: 'InvestmentEvent';
  amountCents?: Maybe<Scalars['Int']['output']>;
  category: IInvestmentEventCategoryEnum;
  createdAt: Scalars['ISO8601DateTime']['output'];
  descriptionForDisplay?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
  previousAmountCents?: Maybe<Scalars['Int']['output']>;
};

export enum IInvestmentEventCategoryEnum {
  AmountLocked = 'AMOUNT_LOCKED',
  ApprovedFromWaitlist = 'APPROVED_FROM_WAITLIST',
  AutoDecreased = 'AUTO_DECREASED',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Converted = 'CONVERTED',
  Created = 'CREATED',
  Decreased = 'DECREASED',
  EscrowOpened = 'ESCROW_OPENED',
  Expired = 'EXPIRED',
  Finalized = 'FINALIZED',
  Increased = 'INCREASED',
  IncreaseMaximumRaiseGoal = 'INCREASE_MAXIMUM_RAISE_GOAL',
  NeedsConversion = 'NEEDS_CONVERSION',
  NeedsReconfirmation = 'NEEDS_RECONFIRMATION',
  PaymentMethodUpdated = 'PAYMENT_METHOD_UPDATED',
  PaymentReceived = 'PAYMENT_RECEIVED',
  ReconfirmationAfterTtw = 'RECONFIRMATION_AFTER_TTW',
  Reconfirmed = 'RECONFIRMED',
  RefundReceived = 'REFUND_RECEIVED',
  ReservationConfirmed = 'RESERVATION_CONFIRMED',
  Reserved = 'RESERVED',
  ReturnEarned = 'RETURN_EARNED',
  Transferred = 'TRANSFERRED',
  Uncancelled = 'UNCANCELLED',
  Waitlisted = 'WAITLISTED',
  WaitlistedReserved = 'WAITLISTED_RESERVED',
  WalletAddressChanged = 'WALLET_ADDRESS_CHANGED',
  WalletAddressCreated = 'WALLET_ADDRESS_CREATED',
}

/** Investments::Exit Type in Core Domain */
export type IInvestmentExit = {
  __typename?: 'InvestmentExit';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  payoutAmountCents?: Maybe<Scalars['Int']['output']>;
};

export type IInvestmentFee = {
  __typename?: 'InvestmentFee';
  feePercentage: Scalars['String']['output'];
  maximumFee: IAmount;
  minimumFee: IAmount;
};

export type IInvestmentInput = {
  amountCents: Scalars['Int']['input'];
  paymentMethod?: InputMaybe<IInvestmentPaymentMethodEnum>;
  paymentMethodId?: InputMaybe<Scalars['ID']['input']>;
  wiringBankIsCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  wiringBankIsFatf?: InputMaybe<Scalars['Boolean']['input']>;
  wiringBankName?: InputMaybe<Scalars['String']['input']>;
};

/** List of available Order Enums of Investment domain */
export enum IInvestmentOrderEnum {
  AmountCentsAsc = 'AMOUNT_CENTS_ASC',
  AmountCentsDesc = 'AMOUNT_CENTS_DESC',
  CancelledAtAsc = 'CANCELLED_AT_ASC',
  CancelledAtDesc = 'CANCELLED_AT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  InvestedAtAsc = 'INVESTED_AT_ASC',
  InvestedAtDesc = 'INVESTED_AT_DESC',
  IssuerProfileNameAsc = 'ISSUER_PROFILE_NAME_ASC',
  IssuerProfileNameDesc = 'ISSUER_PROFILE_NAME_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
}

export enum IInvestmentPaymentMethodEnum {
  Ach = 'ACH',
  Alto = 'ALTO',
  Bitcoin = 'BITCOIN',
  Bitpay = 'BITPAY',
  Card = 'CARD',
  Check = 'CHECK',
  CryptoTransfer = 'CRYPTO_TRANSFER',
  PayLater = 'PAY_LATER',
  RewardNotes = 'REWARD_NOTES',
  Transferred = 'TRANSFERRED',
  Wire = 'WIRE',
}

export enum IInvestmentPaymentOptionEnum {
  Drawdown = 'drawdown',
  OneOff = 'one_off',
}

export type IInvestmentPromotionCodeInput = {
  code?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of InvestmentReasonDelete */
export type IInvestmentReasonDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  offeringSlug: Scalars['String']['input'];
};

/** Autogenerated return type of InvestmentReasonDelete. */
export type IInvestmentReasonDeletePayload = {
  __typename?: 'InvestmentReasonDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  investmentReason?: Maybe<IOfferingInvestmentReasons>;
};

export type IInvestmentReconfirmationInput = {
  materialChangesInvestmentId: Scalars['ID']['input'];
};

/** Autogenerated input type of InvestmentReconfirm */
export type IInvestmentReconfirmInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  input: IInvestmentReconfirmationInput;
};

/** Autogenerated return type of InvestmentReconfirm. */
export type IInvestmentReconfirmPayload = {
  __typename?: 'InvestmentReconfirmPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  investment?: Maybe<IInvestment>;
};

/** Autogenerated input type of InvestmentReinvest */
export type IInvestmentReinvestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  investment: IInvestmentReinvestmentInput;
};

export type IInvestmentReinvestmentInput = {
  achAccount?: InputMaybe<IPaymentMethodAchInput>;
  agreements?: InputMaybe<IInvestmentAgreementsInput>;
  altoIra?: InputMaybe<IPaymentMethodAltoIraInput>;
  card?: InputMaybe<IPaymentMethodCardInput>;
  entity?: InputMaybe<IInvestmentEntityInput>;
  id: Scalars['ID']['input'];
  investment: IInvestmentInput;
  promotionCode?: InputMaybe<IInvestmentPromotionCodeInput>;
  w8Ben?: InputMaybe<IInvestmentW8BenInput>;
  w9?: InputMaybe<IInvestmentW9Input>;
  wire?: InputMaybe<IPaymentMethodWireInput>;
};

/** Autogenerated return type of InvestmentReinvest. */
export type IInvestmentReinvestPayload = {
  __typename?: 'InvestmentReinvestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  investment?: Maybe<IInvestment>;
};

export type IInvestmentReservationConfirmationInput = {
  id: Scalars['ID']['input'];
  promotionCode?: InputMaybe<IInvestmentPromotionCodeInput>;
  w8Ben?: InputMaybe<IInvestmentW8BenInput>;
  w9?: InputMaybe<IInvestmentW9Input>;
};

/** Autogenerated input type of InvestmentReservationConfirm */
export type IInvestmentReservationConfirmInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  input: IInvestmentReservationConfirmationInput;
};

/** Autogenerated return type of InvestmentReservationConfirm. */
export type IInvestmentReservationConfirmPayload = {
  __typename?: 'InvestmentReservationConfirmPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  investment?: Maybe<IInvestment>;
};

/** Possible types of investment banners */
export enum IInvestmentsBannerTypeEnum {
  AccreditationBanner = 'ACCREDITATION_BANNER',
  AccreditationProcessingBanner = 'ACCREDITATION_PROCESSING_BANNER',
  AccreditationRejectedBanner = 'ACCREDITATION_REJECTED_BANNER',
  AdditionalInformationRequiredBanner = 'ADDITIONAL_INFORMATION_REQUIRED_BANNER',
  DocExpiredBanner = 'DOC_EXPIRED_BANNER',
  EmailConfirmationBanner = 'EMAIL_CONFIRMATION_BANNER',
  EntityVerificationProcessingBanner = 'ENTITY_VERIFICATION_PROCESSING_BANNER',
  EntityVerificationRejectedBanner = 'ENTITY_VERIFICATION_REJECTED_BANNER',
  IdentityReverificationBanner = 'IDENTITY_REVERIFICATION_BANNER',
  IdentityVerificationFailedBanner = 'IDENTITY_VERIFICATION_FAILED_BANNER',
  IdentityVerificationProcessingBanner = 'IDENTITY_VERIFICATION_PROCESSING_BANNER',
  KybBanner = 'KYB_BANNER',
  PaymentFailedBanner = 'PAYMENT_FAILED_BANNER',
  ProofOfAddressBanner = 'PROOF_OF_ADDRESS_BANNER',
  ReconfirmationBanner = 'RECONFIRMATION_BANNER',
  ReservationConfirmationBanner = 'RESERVATION_CONFIRMATION_BANNER',
  WalletAddressRequiredBanner = 'WALLET_ADDRESS_REQUIRED_BANNER',
  WireInstuctionsBanner = 'WIRE_INSTUCTIONS_BANNER',
}

export enum IInvestmentScopeEnum {
  Admin = 'ADMIN',
  UserPortfolio = 'USER_PORTFOLIO',
}

export enum IInvestmentStateEnum {
  ActionRequired = 'ACTION_REQUIRED',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Finalized = 'FINALIZED',
  Reserved = 'RESERVED',
  Transferred = 'TRANSFERRED',
  Waitlisted = 'WAITLISTED',
  WaitlistedReserved = 'WAITLISTED_RESERVED',
}

export enum IInvestmentStiTypeEnum {
  AirdropInvestment = 'AIRDROP_INVESTMENT',
  Investment = 'INVESTMENT',
}

/** Type for homepage Ticker section for Core Domain */
export type IInvestmentTicker = {
  __typename?: 'InvestmentTicker';
  amountCents?: Maybe<Scalars['Int']['output']>;
  amountPublic: Scalars['Boolean']['output'];
  avatarContentType?: Maybe<Scalars['String']['output']>;
  avatarFileName?: Maybe<Scalars['String']['output']>;
  avatarFileSize?: Maybe<Scalars['BigInt']['output']>;
  avatarUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  avatarUrl?: Maybe<Scalars['Url']['output']>;
  companyName: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  investorFirstName?: Maybe<Scalars['String']['output']>;
  investorLastName?: Maybe<Scalars['String']['output']>;
  offeringSlug: Scalars['String']['output'];
  userSlug: Scalars['String']['output'];
  userVisibilityPublic: Scalars['Boolean']['output'];
};

/** Type for homepage Ticker section for Core Domain */
export type IInvestmentTickerAvatarUrlArgs = {
  extension?: InputMaybe<IFileProcessingImgproxyExtensionEnum>;
  gravity?: InputMaybe<IFileProcessingImgproxyGravityEnum>;
  resize?: InputMaybe<IFileProcessingImgproxyResizeInput>;
};

/** The connection type for InvestmentTicker. */
export type IInvestmentTickerConnection = {
  __typename?: 'InvestmentTickerConnection';
  /** A list of edges. */
  edges: Array<IInvestmentTickerEdge>;
  /** A list of nodes. */
  nodes: Array<IInvestmentTicker>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IInvestmentTickerEdge = {
  __typename?: 'InvestmentTickerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: IInvestmentTicker;
};

/** Possible types of investments */
export enum IInvestmentTypeEnum {
  Confirmation = 'CONFIRMATION',
  Investment = 'INVESTMENT',
  Participation = 'PARTICIPATION',
  Reservation = 'RESERVATION',
}

/** Autogenerated input type of InvestmentUpdate */
export type IInvestmentUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  input: IInvestmentUpdatesInput;
};

/** Autogenerated return type of InvestmentUpdate. */
export type IInvestmentUpdatePayload = {
  __typename?: 'InvestmentUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  investment?: Maybe<IInvestment>;
};

export type IInvestmentUpdatesInput = {
  amountCents?: InputMaybe<Scalars['Int']['input']>;
  entity?: InputMaybe<IInvestmentEntityInput>;
  id: Scalars['ID']['input'];
  paymentMethodId?: InputMaybe<Scalars['ID']['input']>;
  wire?: InputMaybe<IPaymentMethodWireInput>;
};

export type IInvestmentW8BenInput = {
  countryOfResidence?: InputMaybe<Scalars['String']['input']>;
  taxpayerIdentificationNumber?: InputMaybe<Scalars['String']['input']>;
  usCitizen?: InputMaybe<Scalars['String']['input']>;
};

export type IInvestmentW9Input = {
  backupWithholdingOfTaxes?: InputMaybe<Scalars['String']['input']>;
  coInvestorName?: InputMaybe<Scalars['String']['input']>;
  coInvestorSsn?: InputMaybe<Scalars['String']['input']>;
  coInvestorType?: InputMaybe<Scalars['String']['input']>;
  usBankAccount?: InputMaybe<Scalars['String']['input']>;
  usCitizen?: InputMaybe<Scalars['String']['input']>;
};

/** WireInstruction Type in Core Domain */
export type IInvestmentWireInstruction = {
  __typename?: 'InvestmentWireInstruction';
  accountNumber?: Maybe<Scalars['String']['output']>;
  amount?: Maybe<Scalars['String']['output']>;
  beneficiaryAddressLine1?: Maybe<Scalars['String']['output']>;
  beneficiaryAddressLine2?: Maybe<Scalars['String']['output']>;
  beneficiaryName?: Maybe<Scalars['String']['output']>;
  ffc?: Maybe<Scalars['String']['output']>;
  iban?: Maybe<Scalars['String']['output']>;
  referenceCode?: Maybe<Scalars['String']['output']>;
  routingNumber?: Maybe<Scalars['String']['output']>;
  swiftCode?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  toAddressLine1?: Maybe<Scalars['String']['output']>;
  toAddressLine2?: Maybe<Scalars['String']['output']>;
  toBankName?: Maybe<Scalars['String']['output']>;
  wireInstructionsAvailable?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of InvestmentWireUpload */
export type IInvestmentWireUploadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  directUploadWireReceiptUuid: Scalars['String']['input'];
  investmentId: Scalars['ID']['input'];
};

/** Autogenerated return type of InvestmentWireUpload. */
export type IInvestmentWireUploadPayload = {
  __typename?: 'InvestmentWireUploadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  investment?: Maybe<IInvestment>;
};

export type IInvestmentXAgreementsInput = {
  alcoholRepresentationWarranty?: InputMaybe<Scalars['String']['input']>;
  esignDisclosure?: InputMaybe<Scalars['String']['input']>;
  over21Warranty?: InputMaybe<Scalars['String']['input']>;
  termOfInvestment?: InputMaybe<Scalars['String']['input']>;
};

export type IInvestor = {
  __typename?: 'Investor';
  fundAmericaInvestors: IFundAmericaInvestorConnection;
  id: Scalars['ID']['output'];
  uuid: Scalars['String']['output'];
};

export type IInvestorFundAmericaInvestorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Investor Crowd Safe */
export type IInvestorCrowdSafe = INode & {
  __typename?: 'InvestorCrowdSafe';
  attachmentContentType?: Maybe<Scalars['String']['output']>;
  attachmentFileName?: Maybe<Scalars['String']['output']>;
  attachmentFileSize?: Maybe<Scalars['BigInt']['output']>;
  attachmentUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  attachmentUrl?: Maybe<Scalars['Url']['output']>;
  crowdSafeTimestamp?: Maybe<Scalars['ISO8601DateTime']['output']>;
  filenameForDisplay?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  investment?: Maybe<IInvestment>;
  investmentAmountCents?: Maybe<Scalars['Int']['output']>;
  investmentTimestamp?: Maybe<Scalars['ISO8601DateTime']['output']>;
  investorName?: Maybe<Scalars['String']['output']>;
  investorProfile: IInvestorProfile;
  offering: IOffering;
  republicPlatformFee?: Maybe<Scalars['Boolean']['output']>;
};

export enum IInvestorEntityBeneficialOwnerInviteStatusesEnum {
  Drafted = 'DRAFTED',
  InviteAccepted = 'INVITE_ACCEPTED',
  InviteSent = 'INVITE_SENT',
}

/** Autogenerated input type of InvestorEntityIdentityVerificationCreateDocumentUpload */
export type IInvestorEntityIdentityVerificationCreateDocumentUploadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contentType: Scalars['String']['input'];
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileSize: Scalars['BigInt']['input'];
  investorEntityCrossTableId: Scalars['String']['input'];
};

/** Autogenerated return type of InvestorEntityIdentityVerificationCreateDocumentUpload. */
export type IInvestorEntityIdentityVerificationCreateDocumentUploadPayload = {
  __typename?: 'InvestorEntityIdentityVerificationCreateDocumentUploadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  presignedFileUpload?: Maybe<IPresignedFileUpload>;
};

/** Autogenerated input type of InvestorEntityIdentityVerificationSubmitVerification */
export type IInvestorEntityIdentityVerificationSubmitVerificationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  investorEntityCrossTableId: Scalars['String']['input'];
  presignedFileUploadsIds: Array<Scalars['String']['input']>;
};

/** Autogenerated return type of InvestorEntityIdentityVerificationSubmitVerification. */
export type IInvestorEntityIdentityVerificationSubmitVerificationPayload = {
  __typename?: 'InvestorEntityIdentityVerificationSubmitVerificationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  investorEntity?: Maybe<IInvestorEntityType>;
};

export type IInvestorEntityType = {
  __typename?: 'InvestorEntityType';
  beneficialOwners: Array<IBeneficialOwnerType>;
  canSubmitIdentityVerification: Scalars['Boolean']['output'];
  crossTableId: Scalars['ID']['output'];
  entityType: IInvestorEntityTypesEnum;
  identityVerification?: Maybe<IIdentityVerification>;
  isMultiOwner: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export enum IInvestorEntityTypesEnum {
  Corporation = 'CORPORATION',
  JointSpouse = 'JOINT_SPOUSE',
  JointTenant = 'JOINT_TENANT',
  LimitedLiabilityCompany = 'LIMITED_LIABILITY_COMPANY',
  Partnership = 'PARTNERSHIP',
  SelfDirectedIndividualRetirementAccount = 'SELF_DIRECTED_INDIVIDUAL_RETIREMENT_ACCOUNT',
  Trust = 'TRUST',
}

/** Investor Group for Core Domain */
export type IInvestorGroup = IInvestorGroupTags & {
  __typename?: 'InvestorGroup';
  /** Group leads */
  admins?: Maybe<Array<IInvestorGroupAdmin>>;
  /** Sum of members investments in portfolio deals, USD */
  amountCoinvested: Scalars['Int']['output'];
  coverHexColor?: Maybe<Scalars['String']['output']>;
  coverPhotoContentType?: Maybe<Scalars['String']['output']>;
  coverPhotoFileName?: Maybe<Scalars['String']['output']>;
  coverPhotoFileSize?: Maybe<Scalars['BigInt']['output']>;
  coverPhotoUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  coverPhotoUrl?: Maybe<Scalars['Url']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discussionSize: Scalars['Int']['output'];
  facebookUrl?: Maybe<Scalars['Url']['output']>;
  groupType?: Maybe<Scalars['String']['output']>;
  hasPassword?: Maybe<Scalars['Boolean']['output']>;
  headline?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  investmentThesis?: Maybe<Scalars['String']['output']>;
  investmentsForTicker?: Maybe<Array<IInvestmentTicker>>;
  investorsCount: Scalars['Int']['output'];
  isMember: Scalars['Boolean']['output'];
  logoContentType?: Maybe<Scalars['String']['output']>;
  logoFileName?: Maybe<Scalars['String']['output']>;
  logoFileSize?: Maybe<Scalars['BigInt']['output']>;
  logoUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  logoUrl?: Maybe<Scalars['Url']['output']>;
  members: Array<IInvestorGroupMember>;
  membersCount: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  newPostsThisWeekCount: Scalars['Int']['output'];
  portfolio?: Maybe<Array<IGroupDealPost>>;
  portfolioSize: Scalars['Int']['output'];
  seoDescription?: Maybe<Scalars['String']['output']>;
  seoImageContentType?: Maybe<Scalars['String']['output']>;
  seoImageFileName?: Maybe<Scalars['String']['output']>;
  seoImageFileSize?: Maybe<Scalars['BigInt']['output']>;
  seoImageUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  seoImageUrl?: Maybe<Scalars['Url']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<ITagsTag>>;
  twitterUrl?: Maybe<Scalars['Url']['output']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Permitted actions for investor group */
  userPermissions: IInvestorGroupUserPermissions;
  websiteUrl?: Maybe<Scalars['Url']['output']>;
};

/** Investor Group for Core Domain */
export type IInvestorGroupInvestmentsForTickerArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

/** Investor Group for Core Domain */
export type IInvestorGroupPortfolioArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IInvestorGroupAdmin = {
  __typename?: 'InvestorGroupAdmin';
  numberOfComments: Scalars['Int']['output'];
  numberOfDealPosts: Scalars['Int']['output'];
  numberOfGroupInvestments: Scalars['Int']['output'];
  roleName: IInvestorGroupAdminRole;
  user: IUser;
};

export enum IInvestorGroupAdminRole {
  Host = 'Host',
  Lead = 'Lead',
}

/** The connection type for InvestorGroup. */
export type IInvestorGroupConnection = {
  __typename?: 'InvestorGroupConnection';
  /** A list of edges. */
  edges: Array<IInvestorGroupEdge>;
  /** A list of nodes. */
  nodes: Array<IInvestorGroup>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Autogenerated input type of InvestorGroupCreate */
export type IInvestorGroupCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  post: IPostInput;
  slug: Scalars['String']['input'];
};

/** Autogenerated return type of InvestorGroupCreate. */
export type IInvestorGroupCreatePayload = {
  __typename?: 'InvestorGroupCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  post?: Maybe<IPost>;
};

/** User permissions exposed to a client for Deal post object of Core domain */
export type IInvestorGroupDealPostUserPermissions = INode & {
  __typename?: 'InvestorGroupDealPostUserPermissions';
  canDelete: Scalars['Boolean']['output'];
  canMarkFlagged: Scalars['Boolean']['output'];
  canMarkSpam: Scalars['Boolean']['output'];
  canPin: Scalars['Boolean']['output'];
  canReply: Scalars['Boolean']['output'];
  canView: Scalars['Boolean']['output'];
  /** ID of the object. */
  id: Scalars['ID']['output'];
};

/** An edge in a connection. */
export type IInvestorGroupEdge = {
  __typename?: 'InvestorGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: IInvestorGroup;
};

export type IInvestorGroupInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  investmentThesis?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type IInvestorGroupMember = {
  __typename?: 'InvestorGroupMember';
  id: Scalars['ID']['output'];
  numberOfGroupInvestments: Scalars['Int']['output'];
  user: IUser;
};

export type IInvestorGroupMembership = INode & {
  __typename?: 'InvestorGroupMembership';
  id: Scalars['ID']['output'];
  investorGroup: IInvestorGroup;
  user: IUser;
};

/** User permissions exposed to a client for Post object of Core domain */
export type IInvestorGroupPostUserPermissions = INode & {
  __typename?: 'InvestorGroupPostUserPermissions';
  canDelete: Scalars['Boolean']['output'];
  canMarkFlagged: Scalars['Boolean']['output'];
  canMarkSpam: Scalars['Boolean']['output'];
  canPin: Scalars['Boolean']['output'];
  canReply: Scalars['Boolean']['output'];
  canView: Scalars['Boolean']['output'];
  /** ID of the object. */
  id: Scalars['ID']['output'];
};

export type IInvestorGroupTags = {
  tags?: Maybe<Array<ITagsTag>>;
};

/** User permissions exposed to a client for Investor Group object of Core domain */
export type IInvestorGroupUserPermissions = INode & {
  __typename?: 'InvestorGroupUserPermissions';
  canCreateDealPost: Scalars['Boolean']['output'];
  canCreatePost: Scalars['Boolean']['output'];
  canEdit: Scalars['Boolean']['output'];
  canJoin: Scalars['Boolean']['output'];
  /** ID of the object. */
  id: Scalars['ID']['output'];
};

/** InvestorProfile object of Core domain */
export type IInvestorProfile = INode & {
  __typename?: 'InvestorProfile';
  accreditationReason?: Maybe<IAccreditationReason>;
  /** @deprecated Use x version */
  accreditationVerified: Scalars['Boolean']['output'];
  activeInvestments: Array<IInvestment>;
  activeInvestmentsCount: Scalars['Int']['output'];
  /** @deprecated Use x version */
  activeInvestmentsOnRepublicAmountCents: Scalars['Int']['output'];
  /** @deprecated User x version */
  activeInvestmentsOnRepublicLastYearAmountCents: Scalars['Int']['output'];
  /** @deprecated User x version */
  amountInvestedInLastYearViaThirdPartyPortalsCents: Scalars['Int']['output'];
  cognitoFlowCustomerReference?: Maybe<Scalars['String']['output']>;
  /** @deprecated User x version */
  completedRegBiQuiz: Scalars['Boolean']['output'];
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  cryptoAddressBook?: Maybe<Array<ICryptoAddressBook>>;
  cryptoWallet?: Maybe<ICryptoWalletType>;
  diversificationStats?: Maybe<IUserInvestorProfileDiversificationStats>;
  documents?: Maybe<Array<IUserInvestorProfileDocument>>;
  employmentStatus?: Maybe<IUserInvestorProfileEmploymentStatusEnum>;
  esignDisclosureAgreed?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use x version */
  groupCodeOfConductAccepted: Scalars['Boolean']['output'];
  hasActiveRepublicSubscription: Scalars['Boolean']['output'];
  /** @deprecated User x version */
  hasInvestedViaThirdParty: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated Should be bigint type */
  incomeCents?: Maybe<Scalars['Int']['output']>;
  incomeChangeCount: Scalars['Int']['output'];
  incomeChangeState: IUserInvestorProfileIncomeChangeStateEnum;
  incomeSource?: Maybe<Scalars['String']['output']>;
  investedInRepublicAmountCents?: Maybe<Scalars['Int']['output']>;
  investments: Array<IInvestment>;
  investmentsCount: Scalars['Int']['output'];
  /** @deprecated Use x version */
  investmentsStats: IUserInvestorProfileInvestmentsStats;
  inxIntegrationAccount?: Maybe<IInxIntegrationAccount>;
  isCurrentUser: Scalars['Boolean']['output'];
  /** @deprecated Use JobIndustryEnum instead */
  jobIndustry?: Maybe<Scalars['String']['output']>;
  jobIndustryEnum?: Maybe<IUserInvestorProfileJobIndustryEnum>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use x version */
  kycAmlManualRequired: Scalars['Boolean']['output'];
  kycAmlManualRequiredAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  kycAmlState?: Maybe<IInvestorProfileKycAmlStateEnum>;
  /** @deprecated Use x version */
  kycAmlThresholdCents: Scalars['Int']['output'];
  kycVerification?: Maybe<IInvestorProfileKycVerification>;
  /** @deprecated Use x version */
  needsCognitoFlowVerification: Scalars['Boolean']['output'];
  /** @deprecated Incorrect field type, pls use 'xNetWorthCents' */
  netWorthCents?: Maybe<Scalars['Int']['output']>;
  noteAmount?: Maybe<Scalars['Int']['output']>;
  /** @deprecated User x version */
  proofOfAddressProvided: Scalars['Boolean']['output'];
  /** @deprecated User x version */
  providedFullKycAmlInformation: Scalars['Boolean']['output'];
  /** @deprecated User x version */
  providedKycAmlInformation: Scalars['Boolean']['output'];
  recentlyUsedPaymentMethod?: Maybe<IPaymentMethodUnion>;
  /** @deprecated Not used anymore */
  regCfQuizPassed: Scalars['Boolean']['output'];
  /** @deprecated Use x version */
  remainingInvestmentLimitCents: Scalars['Int']['output'];
  republicWallet?: Maybe<ICryptoWalletType>;
  /** @deprecated User x version */
  requiredKycAmlVerification: Scalars['Boolean']['output'];
  /** @deprecated Use x version */
  selfAccredited: Scalars['Boolean']['output'];
  /** @deprecated Use x version */
  showJointEntitiesBanner: Scalars['Boolean']['output'];
  /** @deprecated Use x version */
  showKycAmlProcessing: Scalars['Boolean']['output'];
  spouseFirstname?: Maybe<Scalars['String']['output']>;
  spouseHasProfile?: Maybe<Scalars['Boolean']['output']>;
  spouseLastname?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use x version */
  totalInvestmentLimitCents: Scalars['Int']['output'];
  totalNoteAmount?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** @deprecated Use x version */
  usCitizen: Scalars['Boolean']['output'];
  user: IUser;
  xAccreditationVerified?: Maybe<Scalars['Boolean']['output']>;
  xActiveInvestmentsOnRepublicAmountCents?: Maybe<Scalars['Int']['output']>;
  xActiveInvestmentsOnRepublicLastYearAmountCents?: Maybe<
    Scalars['Int']['output']
  >;
  xAmountInvestedInLastYearViaThirdPartyPortalsCents?: Maybe<
    Scalars['Int']['output']
  >;
  xCompletedRegBiQuiz?: Maybe<Scalars['Boolean']['output']>;
  xGroupCodeOfConductAccepted?: Maybe<Scalars['Boolean']['output']>;
  xHasInvestedViaThirdParty?: Maybe<Scalars['Boolean']['output']>;
  xIncomeCents?: Maybe<Scalars['BigInt']['output']>;
  xInvestmentsStats?: Maybe<IUserInvestorProfileInvestmentsStats>;
  xKycAmlManualRequired?: Maybe<Scalars['Boolean']['output']>;
  xKycAmlThresholdCents?: Maybe<Scalars['Int']['output']>;
  xNeedsCognitoFlowVerification?: Maybe<Scalars['Boolean']['output']>;
  xNetWorthCents?: Maybe<Scalars['BigInt']['output']>;
  xProofOfAddressProvided?: Maybe<Scalars['Boolean']['output']>;
  xProvidedFullKycAmlInformation?: Maybe<Scalars['Boolean']['output']>;
  xProvidedKycAmlInformation?: Maybe<Scalars['Boolean']['output']>;
  xRegCfQuizPassed?: Maybe<Scalars['Boolean']['output']>;
  xRemainingInvestmentLimitCents?: Maybe<Scalars['BigInt']['output']>;
  xRequiredKycAmlVerification?: Maybe<Scalars['Boolean']['output']>;
  xSelfAccredited?: Maybe<Scalars['Boolean']['output']>;
  xShowJointEntitiesBanner?: Maybe<Scalars['Boolean']['output']>;
  xShowKycAmlProcessing?: Maybe<Scalars['Boolean']['output']>;
  xTotalInvestmentLimitCents?: Maybe<Scalars['Int']['output']>;
  xUsCitizen?: Maybe<Scalars['Boolean']['output']>;
};

/** InvestorProfile object of Core domain */
export type IInvestorProfileKycVerificationArgs = {
  cognitoId?: InputMaybe<Scalars['ID']['input']>;
};

/** InvestorProfile object of Core domain */
export type IInvestorProfileRecentlyUsedPaymentMethodArgs = {
  type: IInvestmentPaymentMethodEnum;
};

/** Return type for cognito integration flow sessions */
export type IInvestorProfileCognitoFlowSession = INode & {
  __typename?: 'InvestorProfileCognitoFlowSession';
  id: Scalars['ID']['output'];
  shareableUrl: Scalars['Url']['output'];
  status: Scalars['String']['output'];
};

export enum IInvestorProfileDiversificationStatsScoreLevelEnum {
  Excellent = 'EXCELLENT',
  Good = 'GOOD',
  Low = 'LOW',
  Medium = 'MEDIUM',
  VeryLow = 'VERY_LOW',
}

/** Autogenerated input type of investorProfileDocumentCreate */
export type IInvestorProfileDocumentCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  documentInput: IUserInvestorProfileDocumentInput;
};

/** Autogenerated return type of investorProfileDocumentCreate. */
export type IInvestorProfileDocumentCreatePayload = {
  __typename?: 'investorProfileDocumentCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  document: IUserInvestorProfileDocument;
  errors?: Maybe<Array<IValidationError>>;
};

/** Autogenerated input type of InvestorProfileEntityCreate */
export type IInvestorProfileEntityCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  entity: IInvestmentEntityInput;
};

/** Autogenerated return type of InvestorProfileEntityCreate. */
export type IInvestorProfileEntityCreatePayload = {
  __typename?: 'InvestorProfileEntityCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  entity?: Maybe<IEntityUnion>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Autogenerated input type of InvestorProfileEntityDelete */
export type IInvestorProfileEntityDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  entityId: Scalars['ID']['input'];
  entityType: IInvestmentEntityTypeEnum;
};

/** Autogenerated return type of InvestorProfileEntityDelete. */
export type IInvestorProfileEntityDeletePayload = {
  __typename?: 'InvestorProfileEntityDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  entity?: Maybe<IEntityUnion>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Autogenerated input type of InvestorProfileEntityUpdate */
export type IInvestorProfileEntityUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  entity: IInvestmentEntityInput;
};

/** Autogenerated return type of InvestorProfileEntityUpdate. */
export type IInvestorProfileEntityUpdatePayload = {
  __typename?: 'InvestorProfileEntityUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  entity?: Maybe<IEntityUnion>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Input parameters for InvestorProfile */
export type IInvestorProfileInput = {
  accreditationReason?: InputMaybe<IAccreditationReasonInput>;
  /** @deprecated Use amountInvestedInLastYearViaThirdPartyPortalsCents instead */
  amountInvestedInLastYearViaPartyPortalsCents?: InputMaybe<
    Scalars['Int']['input']
  >;
  amountInvestedInLastYearViaThirdPartyPortalsCents?: InputMaybe<
    Scalars['Int']['input']
  >;
  employmentStatus?: InputMaybe<IUserInvestorProfileEmploymentStatusEnum>;
  esignDisclosureAgreed?: InputMaybe<Scalars['Boolean']['input']>;
  groupCodeOfConductAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  /** @deprecated Dont use it */
  id?: InputMaybe<Scalars['ID']['input']>;
  incomeCents?: InputMaybe<Scalars['Int']['input']>;
  incomeSource?: InputMaybe<Scalars['String']['input']>;
  jobIndustry?: InputMaybe<Scalars['String']['input']>;
  jobIndustryEnum?: InputMaybe<IUserInvestorProfileJobIndustryEnum>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  netWorthCents?: InputMaybe<Scalars['Int']['input']>;
  /** @deprecated Not used anymore */
  reqCfQuizPassed?: InputMaybe<Scalars['Boolean']['input']>;
  selfAccredited?: InputMaybe<Scalars['Boolean']['input']>;
  showJointEntitiesBanner?: InputMaybe<Scalars['Boolean']['input']>;
  spouseFirstname?: InputMaybe<Scalars['String']['input']>;
  spouseHasProfile?: InputMaybe<Scalars['Boolean']['input']>;
  spouseLastname?: InputMaybe<Scalars['String']['input']>;
};

export enum IInvestorProfileKycAmlStateEnum {
  Approved = 'APPROVED',
  Created = 'CREATED',
  ManualBanned = 'MANUAL_BANNED',
  ManualRequired = 'MANUAL_REQUIRED',
  NotRequired = 'NOT_REQUIRED',
  Required = 'REQUIRED',
}

/** Autogenerated input type of InvestorProfileKycPrecheck */
export type IInvestorProfileKycPrecheckInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  forceReflow?: InputMaybe<Scalars['Boolean']['input']>;
  /** @deprecated No longer used */
  needsDocumentUpload?: InputMaybe<Scalars['Boolean']['input']>;
  offeringSlug?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<IUserInvestorProfileCognitoFlowSessionSourceEnum>;
};

/** Autogenerated return type of InvestorProfileKycPrecheck. */
export type IInvestorProfileKycPrecheckPayload = {
  __typename?: 'InvestorProfileKycPrecheckPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  cognitoFlowSession?: Maybe<IInvestorProfileCognitoFlowSession>;
  errors?: Maybe<Array<IValidationError>>;
};

export type IInvestorProfileKycVerification = {
  __typename?: 'InvestorProfileKycVerification';
  status?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of InvestorProfileProofOfAddressApprove */
export type IInvestorProfileProofOfAddressApproveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of InvestorProfileProofOfAddressApprove. */
export type IInvestorProfileProofOfAddressApprovePayload = {
  __typename?: 'InvestorProfileProofOfAddressApprovePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  investorProfile?: Maybe<IInvestorProfile>;
};

/** Autogenerated input type of InvestorProfileUpdate */
export type IInvestorProfileUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  investorProfile: IInvestorProfileInput;
};

/** Autogenerated return type of InvestorProfileUpdate. */
export type IInvestorProfileUpdatePayload = {
  __typename?: 'InvestorProfileUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  investorProfile?: Maybe<IInvestorProfile>;
};

/** Inx Account */
export type IInxIntegrationAccount = {
  __typename?: 'InxIntegrationAccount';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  inxUserId: Scalars['String']['output'];
  redirectUrl: Scalars['String']['output'];
  state: IInxIntegrationAccountStateEnum;
};

/** Autogenerated input type of InxIntegrationAccountCreate */
export type IInxIntegrationAccountCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  inxAccount?: InputMaybe<IInxIntegrationAccountInput>;
};

/** Autogenerated return type of InxIntegrationAccountCreate. */
export type IInxIntegrationAccountCreatePayload = {
  __typename?: 'InxIntegrationAccountCreatePayload';
  account?: Maybe<IInxIntegrationAccount>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

/** INX Account Input */
export type IInxIntegrationAccountInput = {
  inxUserId?: InputMaybe<Scalars['String']['input']>;
  redirectUrl?: InputMaybe<Scalars['String']['input']>;
};

export enum IInxIntegrationAccountStateEnum {
  Connected = 'CONNECTED',
  Created = 'CREATED',
}

export type IInxIntegrationMutations = {
  /** Create account on INX side */
  inxIntegrationAccountCreate?: Maybe<IInxIntegrationAccountCreatePayload>;
};

export type IInxIntegrationMutationsInxIntegrationAccountCreateArgs = {
  input: IInxIntegrationAccountCreateInput;
};

/** Input paramaters for analytics */
export type IIosParametersInput = {
  iosAppVersion?: InputMaybe<Scalars['String']['input']>;
  iosDevice?: InputMaybe<Scalars['String']['input']>;
  iosOsVersion?: InputMaybe<Scalars['String']['input']>;
};

export type IIssuerApplication = INode & {
  __typename?: 'IssuerApplication';
  accessToPartnerNetwork?: Maybe<Scalars['Boolean']['output']>;
  address?: Maybe<IAddress>;
  approvedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  businessModel?: Maybe<IIssuerBusinessModelEnum>;
  category?: Maybe<IIssuerCategoryEnum>;
  companyName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  deckUrl?: Maybe<Scalars['Url']['output']>;
  facebookUrl?: Maybe<Scalars['Url']['output']>;
  fundraisingTraction?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  industry?: Maybe<Scalars['String']['output']>;
  intendedUseOfCapital?: Maybe<Scalars['String']['output']>;
  maxRaiseAmountCents?: Maybe<Scalars['Int']['output']>;
  otherCommunity?: Maybe<Scalars['String']['output']>;
  pitchDeckContentType?: Maybe<Scalars['String']['output']>;
  pitchDeckFileName?: Maybe<Scalars['String']['output']>;
  pitchDeckFileSize?: Maybe<Scalars['BigInt']['output']>;
  pitchDeckUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  pitchDeckUrl?: Maybe<Scalars['Url']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  rejectedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  securityType?: Maybe<Scalars['String']['output']>;
  stage?: Maybe<IIssuerStageEnum>;
  state?: Maybe<IIssuerStateEnum>;
  targetRaiseAmountCents?: Maybe<Scalars['Int']['output']>;
  telegramUrl?: Maybe<Scalars['Url']['output']>;
  terms?: Maybe<Scalars['String']['output']>;
  traction?: Maybe<Scalars['String']['output']>;
  twitterUrl?: Maybe<Scalars['Url']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user?: Maybe<IUser>;
  utmCampaign?: Maybe<Scalars['String']['output']>;
  utmContent?: Maybe<Scalars['String']['output']>;
  utmMedium?: Maybe<Scalars['String']['output']>;
  utmSource?: Maybe<Scalars['String']['output']>;
  utmTerm?: Maybe<Scalars['String']['output']>;
  videoUrl?: Maybe<Scalars['Url']['output']>;
  websiteUrl?: Maybe<Scalars['Url']['output']>;
  whitepaperContentType?: Maybe<Scalars['String']['output']>;
  whitepaperFileName?: Maybe<Scalars['String']['output']>;
  whitepaperFileSize?: Maybe<Scalars['BigInt']['output']>;
  whitepaperUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  whitepaperUrl?: Maybe<Scalars['Url']['output']>;
};

/** The connection type for IssuerApplication. */
export type IIssuerApplicationConnection = {
  __typename?: 'IssuerApplicationConnection';
  /** A list of edges. */
  edges: Array<IIssuerApplicationEdge>;
  /** A list of nodes. */
  nodes: Array<IIssuerApplication>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IIssuerApplicationEdge = {
  __typename?: 'IssuerApplicationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: IIssuerApplication;
};

export type IIssuerApplicationInput = {
  accessToPartnerNetwork?: InputMaybe<Scalars['Boolean']['input']>;
  /** Nested address attributes */
  addressAttributes?: InputMaybe<IAddressInput>;
  businessModel?: InputMaybe<IIssuerBusinessModelEnum>;
  category?: InputMaybe<IIssuerCategoryEnum>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  deckUrl?: InputMaybe<Scalars['Url']['input']>;
  facebookUrl?: InputMaybe<Scalars['Url']['input']>;
  fundraisingTraction?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  industry?: InputMaybe<Scalars['String']['input']>;
  intendedUseOfCapital?: InputMaybe<Scalars['String']['input']>;
  maxRaiseAmountCents?: InputMaybe<Scalars['Int']['input']>;
  otherCommunity?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  securityType?: InputMaybe<Scalars['String']['input']>;
  stage?: InputMaybe<IIssuerStageEnum>;
  state?: InputMaybe<IIssuerStateEnum>;
  targetRaiseAmountCents?: InputMaybe<Scalars['Int']['input']>;
  telegramUrl?: InputMaybe<Scalars['Url']['input']>;
  terms?: InputMaybe<Scalars['String']['input']>;
  traction?: InputMaybe<Scalars['String']['input']>;
  twitterUrl?: InputMaybe<Scalars['Url']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
  utmCampaign?: InputMaybe<Scalars['String']['input']>;
  utmContent?: InputMaybe<Scalars['String']['input']>;
  utmMedium?: InputMaybe<Scalars['String']['input']>;
  utmSource?: InputMaybe<Scalars['String']['input']>;
  utmTerm?: InputMaybe<Scalars['String']['input']>;
  videoUrl?: InputMaybe<Scalars['Url']['input']>;
  websiteUrl?: InputMaybe<Scalars['Url']['input']>;
};

/** Autogenerated input type of IssuerApply */
export type IIssuerApplyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  issuerApplication: IIssuerApplicationInput;
};

/** Autogenerated return type of IssuerApply. */
export type IIssuerApplyPayload = {
  __typename?: 'IssuerApplyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  issuerApplication?: Maybe<IIssuerApplication>;
};

export enum IIssuerBusinessModelEnum {
  B2B = 'B2_B',
  B2C = 'B2_C',
  C2C = 'C2_C',
}

export enum IIssuerCategoryEnum {
  Crypto = 'CRYPTO',
  Launch = 'LAUNCH',
  MeetTheDrapers = 'MEET_THE_DRAPERS',
  Republic = 'REPUBLIC',
  RepublicAcademy = 'REPUBLIC_ACADEMY',
  RepublicCities = 'REPUBLIC_CITIES',
  RepublicPartnerships = 'REPUBLIC_PARTNERSHIPS',
  TeaserPage = 'TEASER_PAGE',
}

/** Edgar access codes */
export type IIssuerIssuerProfileEdgarAccess = {
  __typename?: 'IssuerIssuerProfileEdgarAccess';
  accessCreatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  accessExpiredAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  ccc?: Maybe<Scalars['String']['output']>;
  cik?: Maybe<Scalars['String']['output']>;
  passphrase?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  pmac?: Maybe<Scalars['String']['output']>;
};

export type IIssuerIssuerProfileEdgarAccessInput = {
  accessCreatedAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  accessExpiredAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  ccc?: InputMaybe<Scalars['String']['input']>;
  cik?: InputMaybe<Scalars['String']['input']>;
  passphrase?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  pmac?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of IssuerIssuerProfileEdgarAccessUpdate */
export type IIssuerIssuerProfileEdgarAccessUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  edgarAccess: IIssuerIssuerProfileEdgarAccessInput;
  offeringSlug: Scalars['String']['input'];
};

/** Autogenerated return type of IssuerIssuerProfileEdgarAccessUpdate. */
export type IIssuerIssuerProfileEdgarAccessUpdatePayload = {
  __typename?: 'IssuerIssuerProfileEdgarAccessUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  edgarAccess?: Maybe<IIssuerIssuerProfileEdgarAccess>;
  errors?: Maybe<Array<IValidationError>>;
};

export enum IIssuerIssuerProfileIncorporationTypeEnum {
  Company = 'COMPANY',
  CCorporation = 'C_CORPORATION',
  LimitedPartnership = 'LIMITED_PARTNERSHIP',
  Llc = 'LLC',
  Other = 'OTHER',
  SCorporation = 'S_CORPORATION',
}

/** Autogenerated input type of IssuerIssuerProfileUpdate */
export type IIssuerIssuerProfileUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of issuer */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Issuer provided owners during onboarding */
  providedOwners?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of IssuerIssuerProfileUpdate. */
export type IIssuerIssuerProfileUpdatePayload = {
  __typename?: 'IssuerIssuerProfileUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  issuerProfile?: Maybe<IIssuerProfile>;
};

/** Issuer Profile Type in Core Domain */
export type IIssuerProfile = {
  __typename?: 'IssuerProfile';
  achAccount?: Maybe<IAchAccount>;
  address?: Maybe<IAddress>;
  cap?: Maybe<Scalars['Int']['output']>;
  /** @deprecated This field was removed from issuer profiles. Use edgarAccess instead. */
  cik?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  companySymbol: Scalars['String']['output'];
  contactEmail?: Maybe<Scalars['Email']['output']>;
  contactFullName?: Maybe<Scalars['String']['output']>;
  contactPhoneNumber?: Maybe<Scalars['PhoneNumber']['output']>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discordUrl?: Maybe<Scalars['Url']['output']>;
  discount?: Maybe<Scalars['Int']['output']>;
  doingBusinessAs?: Maybe<Scalars['String']['output']>;
  edgarAcces?: Maybe<IIssuerProfilesEdgarAccess>;
  employeeCount?: Maybe<Scalars['Int']['output']>;
  employerIdNumber?: Maybe<Scalars['String']['output']>;
  facebookUrl?: Maybe<Scalars['Url']['output']>;
  fiscalYearEndMonth?: Maybe<Scalars['Int']['output']>;
  fundAmericaEntityId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  incorporatedInUs?: Maybe<Scalars['Boolean']['output']>;
  incorporationCountry?: Maybe<IAddressIncorporationCountriesEnum>;
  incorporationDate?: Maybe<Scalars['String']['output']>;
  /** @deprecated User enumerized xIncorporationState instead */
  incorporationState?: Maybe<Scalars['String']['output']>;
  /** @deprecated User enumerized xIncorporationType instead */
  incorporationType?: Maybe<Scalars['String']['output']>;
  incorporationTypeOtherValue?: Maybe<Scalars['String']['output']>;
  /** All indicative valuations sorted from most recent to oldest */
  indicativeValuations?: Maybe<Array<ISecondaryIndicativeValuation>>;
  instagramUrl?: Maybe<Scalars['Url']['output']>;
  investorCount?: Maybe<Scalars['Int']['output']>;
  isUs?: Maybe<Scalars['Boolean']['output']>;
  /** The most recent indicative valuation */
  latestIndicativeValuation?: Maybe<ISecondaryIndicativeValuation>;
  legalCompanyName?: Maybe<Scalars['String']['output']>;
  linkedinUrl?: Maybe<Scalars['Url']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use xLogoUrl instead */
  logoUrl?: Maybe<Scalars['String']['output']>;
  mediumUrl?: Maybe<Scalars['Url']['output']>;
  naics?: Maybe<Scalars['String']['output']>;
  offerings: Array<IOffering>;
  onboardingCompleteness: Scalars['Int']['output'];
  /** List of team members associated with issuer */
  onboardingMembers: Array<IOnboardingMember>;
  pinterestUrl?: Maybe<Scalars['Url']['output']>;
  /** @deprecated Use onboardingMembers instead */
  primaryContact?: Maybe<IOnboardingMember>;
  primaryOfficer?: Maybe<IRoleCoveredPerson>;
  providedOwners: Scalars['Boolean']['output'];
  publicPhoneNumber?: Maybe<Scalars['PhoneNumber']['output']>;
  rolesCoveredCores: Array<IRoleCoveredCoreUnion>;
  slug?: Maybe<Scalars['String']['output']>;
  sponsorName?: Maybe<Scalars['String']['output']>;
  tiktokUrl?: Maybe<Scalars['Url']['output']>;
  /** Current secondary market tradability status of the issuer */
  tradability: ISecondaryTradabilityEnum;
  tradableAssetTypes?: Maybe<Array<IAssetTypeEnum>>;
  transactApiId?: Maybe<Scalars['String']['output']>;
  twitterUrl?: Maybe<Scalars['Url']['output']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  user?: Maybe<IUser>;
  userHasHoldings: Scalars['Boolean']['output'];
  userHeldUnits?: Maybe<Scalars['BigInt']['output']>;
  userHeldValueCents?: Maybe<Scalars['BigInt']['output']>;
  userReturnOnInvestmentCents?: Maybe<Scalars['BigInt']['output']>;
  videoUrl?: Maybe<Scalars['Url']['output']>;
  websiteUrl?: Maybe<Scalars['Url']['output']>;
  xIncorporationState?: Maybe<IAddressIncorporationStatesEnum>;
  xIncorporationType?: Maybe<IIssuerIssuerProfileIncorporationTypeEnum>;
  xLogoContentType?: Maybe<Scalars['String']['output']>;
  xLogoFileName?: Maybe<Scalars['String']['output']>;
  xLogoFileSize?: Maybe<Scalars['BigInt']['output']>;
  xLogoUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  xLogoUrl?: Maybe<Scalars['Url']['output']>;
  youtubeUrl?: Maybe<Scalars['Url']['output']>;
};

/** Issuer Profile Type in Core Domain */
export type IIssuerProfileXLogoUrlArgs = {
  extension?: InputMaybe<IFileProcessingImgproxyExtensionEnum>;
  gravity?: InputMaybe<IFileProcessingImgproxyGravityEnum>;
  resize?: InputMaybe<IFileProcessingImgproxyResizeInput>;
};

/** The connection type for IssuerProfile. */
export type IIssuerProfileConnection = {
  __typename?: 'IssuerProfileConnection';
  /** A list of edges. */
  edges: Array<IIssuerProfileEdge>;
  /** A list of nodes. */
  nodes: Array<IIssuerProfile>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IIssuerProfileEdge = {
  __typename?: 'IssuerProfileEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: IIssuerProfile;
};

export type IIssuerProfilesEdgarAccess = INode & {
  __typename?: 'IssuerProfilesEdgarAccess';
  accessCreatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  accessExpiredAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  ccc?: Maybe<Scalars['String']['output']>;
  cik?: Maybe<Scalars['String']['output']>;
  /** ID of the object. */
  id: Scalars['ID']['output'];
  passphrase?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  pmac?: Maybe<Scalars['String']['output']>;
};

export enum IIssuerStageEnum {
  Other = 'OTHER',
  PreSeed = 'PRE_SEED',
  Seed = 'SEED',
  SeriesA = 'SERIES_A',
  SeriesB = 'SERIES_B',
}

export enum IIssuerStateEnum {
  Approved = 'APPROVED',
  Created = 'CREATED',
  Rejected = 'REJECTED',
}

/** Article object of Journal domain */
export type IJournalArticle = IArticleAuthor &
  IArticleTags &
  ILikeableInterface & {
    __typename?: 'JournalArticle';
    author?: Maybe<IUser>;
    body: Scalars['String']['output'];
    category?: Maybe<IJournalCategoryEnum>;
    coverPhotoContentType?: Maybe<Scalars['String']['output']>;
    coverPhotoFileName?: Maybe<Scalars['String']['output']>;
    coverPhotoFileSize?: Maybe<Scalars['BigInt']['output']>;
    coverPhotoUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    coverPhotoUrl?: Maybe<Scalars['Url']['output']>;
    excerpt: Scalars['String']['output'];
    founderProfile?: Maybe<IJournalArticleFounderProfile>;
    headline: Scalars['String']['output'];
    hideComments: Scalars['Boolean']['output'];
    id: Scalars['ID']['output'];
    imageTranscript?: Maybe<Scalars['String']['output']>;
    likeCount: Scalars['Int']['output'];
    likedByUser: Scalars['Boolean']['output'];
    publishedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    readNextPosts: Array<IJournalArticle>;
    seoImageUrl?: Maybe<Scalars['Url']['output']>;
    showAuthor: Scalars['Boolean']['output'];
    slug: Scalars['String']['output'];
    state: IJournalStateEnum;
    tags?: Maybe<Array<ITagsTag>>;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    /** Permitted actions for article */
    userPermissions: IJournalArticleUserPermissions;
  };

/** Article object of Journal domain */
export type IJournalArticleCoverPhotoUrlArgs = {
  extension?: InputMaybe<IFileProcessingImgproxyExtensionEnum>;
  gravity?: InputMaybe<IFileProcessingImgproxyGravityEnum>;
  resize?: InputMaybe<IFileProcessingImgproxyResizeInput>;
};

/** The connection type for JournalArticle. */
export type IJournalArticleConnection = {
  __typename?: 'JournalArticleConnection';
  /** A list of edges. */
  edges: Array<IJournalArticleEdge>;
  /** A list of nodes. */
  nodes: Array<IJournalArticle>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Autogenerated input type of JournalArticleCreate */
export type IJournalArticleCreateInput = {
  authorId?: InputMaybe<Scalars['ID']['input']>;
  body: Scalars['String']['input'];
  category: IJournalCategoryEnum;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  directUploadCoverPhotoUuid?: InputMaybe<Scalars['String']['input']>;
  excerpt: Scalars['String']['input'];
  founderProfileAttributes?: InputMaybe<IJournalArticleFounderProfileInput>;
  headline: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of JournalArticleCreate. */
export type IJournalArticleCreatePayload = {
  __typename?: 'JournalArticleCreatePayload';
  article?: Maybe<IJournalArticle>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Autogenerated input type of JournalArticleDelete */
export type IJournalArticleDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of JournalArticleDelete. */
export type IJournalArticleDeletePayload = {
  __typename?: 'JournalArticleDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

/** An edge in a connection. */
export type IJournalArticleEdge = {
  __typename?: 'JournalArticleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: IJournalArticle;
};

export type IJournalArticleFounderProfile =
  IFounderProfileFeaturedInvestmentReasons &
    IFounderProfileOffering &
    IFounderProfileUser & {
      __typename?: 'JournalArticleFounderProfile';
      featuredInvestmentReasons?: Maybe<Array<IOfferingInvestmentReasons>>;
      from?: Maybe<Scalars['String']['output']>;
      hates?: Maybe<Scalars['String']['output']>;
      livesIn?: Maybe<Scalars['String']['output']>;
      loves?: Maybe<Scalars['String']['output']>;
      motto?: Maybe<Scalars['String']['output']>;
      offering?: Maybe<IOffering>;
      spiritAnimal?: Maybe<Scalars['String']['output']>;
      superpower?: Maybe<Scalars['String']['output']>;
      user?: Maybe<IUser>;
    };

export type IJournalArticleFounderProfileInput = {
  _destroy?: InputMaybe<Scalars['Boolean']['input']>;
  from?: InputMaybe<Scalars['String']['input']>;
  hates?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  livesIn?: InputMaybe<Scalars['String']['input']>;
  loves?: InputMaybe<Scalars['String']['input']>;
  moto?: InputMaybe<Scalars['String']['input']>;
  offeringId: Scalars['ID']['input'];
  spiritAnimal?: InputMaybe<Scalars['String']['input']>;
  superpower?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

/** Autogenerated input type of JournalArticlePublish */
export type IJournalArticlePublishInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of JournalArticlePublish. */
export type IJournalArticlePublishPayload = {
  __typename?: 'JournalArticlePublishPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Autogenerated input type of JournalArticleUnpublish */
export type IJournalArticleUnpublishInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of JournalArticleUnpublish. */
export type IJournalArticleUnpublishPayload = {
  __typename?: 'JournalArticleUnpublishPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Autogenerated input type of JournalArticleUpdate */
export type IJournalArticleUpdateInput = {
  authorId?: InputMaybe<Scalars['ID']['input']>;
  body?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<IJournalCategoryEnum>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  directUploadCoverPhotoUuid?: InputMaybe<Scalars['String']['input']>;
  excerpt?: InputMaybe<Scalars['String']['input']>;
  founderProfileAttributes?: InputMaybe<IJournalArticleFounderProfileInput>;
  headline?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of JournalArticleUpdate. */
export type IJournalArticleUpdatePayload = {
  __typename?: 'JournalArticleUpdatePayload';
  article?: Maybe<IJournalArticle>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

/** User permissions exposed to a client for Article object of Journal domain */
export type IJournalArticleUserPermissions = {
  __typename?: 'JournalArticleUserPermissions';
  canDelete: Scalars['Boolean']['output'];
  canEdit: Scalars['Boolean']['output'];
  canPublish: Scalars['Boolean']['output'];
  canUnpublish: Scalars['Boolean']['output'];
};

/** List of Article categories of Journal domain */
export enum IJournalCategoryEnum {
  AskMeAnything = 'ASK_ME_ANYTHING',
  Crypto = 'CRYPTO',
  FounderResources = 'FOUNDER_RESOURCES',
  Industry = 'INDUSTRY',
  InvestorEducation = 'INVESTOR_EDUCATION',
  Partners = 'PARTNERS',
  Podcast = 'PODCAST',
  RealEstate = 'REAL_ESTATE',
  RepublicNote = 'REPUBLIC_NOTE',
  RepublicUpdates = 'REPUBLIC_UPDATES',
  Superheroes = 'SUPERHEROES',
}

export type IJournalMutations = {
  /** Create Article object of Journal domain */
  journalArticleCreate?: Maybe<IJournalArticleCreatePayload>;
  /** Delete Article object of Journal domain by id */
  journalArticleDelete?: Maybe<IJournalArticleDeletePayload>;
  /** Publish Article object of Journal domain by id */
  journalArticlePublish?: Maybe<IJournalArticlePublishPayload>;
  /** Unpublish Article object of Journal domain by id */
  journalArticleUnpublish?: Maybe<IJournalArticleUnpublishPayload>;
  /** Update Article object of Journal domain by id */
  journalArticleUpdate?: Maybe<IJournalArticleUpdatePayload>;
};

export type IJournalMutationsJournalArticleCreateArgs = {
  input: IJournalArticleCreateInput;
};

export type IJournalMutationsJournalArticleDeleteArgs = {
  input: IJournalArticleDeleteInput;
};

export type IJournalMutationsJournalArticlePublishArgs = {
  input: IJournalArticlePublishInput;
};

export type IJournalMutationsJournalArticleUnpublishArgs = {
  input: IJournalArticleUnpublishInput;
};

export type IJournalMutationsJournalArticleUpdateArgs = {
  input: IJournalArticleUpdateInput;
};

/** List of available orders for Article Index query of Journal domain */
export enum IJournalOrderEnum {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
}

export type IJournalResolvers = {
  /** List of Journal articles with ability to filter the result scope */
  journalArticles: IJournalArticleConnection;
  journalArticlesCategories: Array<Scalars['String']['output']>;
};

export type IJournalResolversJournalArticlesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  categories?: InputMaybe<Array<IJournalCategoryEnum>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<IJournalOrderEnum>>;
  states?: InputMaybe<Array<IJournalStateEnum>>;
  tagSlug?: InputMaybe<Scalars['String']['input']>;
};

/** List of available Article states of Journal domain */
export enum IJournalStateEnum {
  Created = 'CREATED',
  Published = 'PUBLISHED',
}

/** An object that defines a Landing Page display logic */
export type ILandingPage = {
  __typename?: 'LandingPage';
  assets?: Maybe<Scalars['String']['output']>;
  components?: Maybe<Scalars['String']['output']>;
  css?: Maybe<Scalars['String']['output']>;
  darkLayout: Scalars['Boolean']['output'];
  hideFooter: Scalars['Boolean']['output'];
  hideFooterNavigation: Scalars['Boolean']['output'];
  hideHeader: Scalars['Boolean']['output'];
  html?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  styles?: Maybe<Scalars['String']['output']>;
  transparentHeader: Scalars['Boolean']['output'];
  userPermissions: ILandingPagesUserPermissions;
};

export type ILandingPageInput = {
  assets?: InputMaybe<Scalars['String']['input']>;
  components?: InputMaybe<Scalars['String']['input']>;
  css?: InputMaybe<Scalars['String']['input']>;
  darkLayout?: InputMaybe<Scalars['Boolean']['input']>;
  hideFooter?: InputMaybe<Scalars['Boolean']['input']>;
  hideFooterNavigation?: InputMaybe<Scalars['Boolean']['input']>;
  hideHeader?: InputMaybe<Scalars['Boolean']['input']>;
  html?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  styles?: InputMaybe<Scalars['String']['input']>;
  transparentHeader?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ILandingPagesMutations = {
  landingPageUpdate?: Maybe<ILandingPageUpdatePayload>;
};

export type ILandingPagesMutationsLandingPageUpdateArgs = {
  input: ILandingPageUpdateInput;
};

export type ILandingPagesResolvers = {
  /** Request a Landing Page by ID */
  landingPage: ILandingPage;
};

export type ILandingPagesResolversLandingPageArgs = {
  landingPageId: Scalars['ID']['input'];
};

/** User permissions exposed to a client for LandingPage object of LandingPages domain */
export type ILandingPagesUserPermissions = {
  __typename?: 'LandingPagesUserPermissions';
  canUpdate: Scalars['Boolean']['output'];
};

/** Autogenerated input type of LandingPageUpdate */
export type ILandingPageUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  landingPage: ILandingPageInput;
};

/** Autogenerated return type of LandingPageUpdate. */
export type ILandingPageUpdatePayload = {
  __typename?: 'LandingPageUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  landingPage?: Maybe<ILandingPage>;
};

/** Lead Type for Core Domain */
export type ILead = {
  __typename?: 'Lead';
  confirmationHash?: Maybe<Scalars['String']['output']>;
  confirmedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  currentPageAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  currentPageIp?: Maybe<Scalars['String']['output']>;
  currentPagePath?: Maybe<Scalars['String']['output']>;
  currentPageQueryParams?: Maybe<Scalars['String']['output']>;
  currentPageReferrer?: Maybe<Scalars['String']['output']>;
  currentSessionAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  currentSessionIp?: Maybe<Scalars['String']['output']>;
  currentSessionPath?: Maybe<Scalars['String']['output']>;
  currentSessionQueryParams?: Maybe<Scalars['String']['output']>;
  currentSessionReferrer?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['Email']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  firstSessionAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  firstSessionIp?: Maybe<Scalars['String']['output']>;
  firstSessionPath?: Maybe<Scalars['String']['output']>;
  firstSessionQueryParams?: Maybe<Scalars['String']['output']>;
  firstSessionReferrer?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  invitedByUserId?: Maybe<Scalars['ID']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  noteAmountUnits?: Maybe<Scalars['Int']['output']>;
  referralCampaignId?: Maybe<Scalars['ID']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  userCreatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  userCreatedIp?: Maybe<Scalars['String']['output']>;
  userCreatedPath?: Maybe<Scalars['String']['output']>;
  userCreatedQueryParams?: Maybe<Scalars['String']['output']>;
  userCreatedReferrer?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type ILedgerIntegrationPayout = INode & {
  __typename?: 'LedgerIntegrationPayout';
  category?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  noteAmountUnits?: Maybe<Scalars['Int']['output']>;
};

/** Enum of possible verifier titles for letter claim */
export enum ILetterClaimVerifierTitleEnum {
  Attorney = 'ATTORNEY',
  BrokerDealer = 'BROKER_DEALER',
  Cpa = 'CPA',
  InvestmentAdvisor = 'INVESTMENT_ADVISOR',
}

export type ILike = INode & {
  __typename?: 'Like';
  id: Scalars['ID']['output'];
  type: Scalars['String']['output'];
};

/** Information for an object that can be liked by a user */
export type ILikeableInterface = {
  likeCount: Scalars['Int']['output'];
  likedByUser: Scalars['Boolean']['output'];
};

/** List of objects that are likeable */
export enum ILikeableObjectTypeEnum {
  Article = 'ARTICLE',
  Comment = 'COMMENT',
  OfferingUpdate = 'OFFERING_UPDATE',
  Press = 'PRESS',
}

export type ILikeableObjectUnion =
  | IComment
  | IJournalArticle
  | IOfferingUpdate
  | IPressReleasesPress;

/** Autogenerated input type of LikeObject */
export type ILikeObjectInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  objectId: Scalars['ID']['input'];
  objectType: ILikeableObjectTypeEnum;
};

/** Autogenerated return type of LikeObject. */
export type ILikeObjectPayload = {
  __typename?: 'LikeObjectPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  likedObject?: Maybe<ILikeableObjectUnion>;
};

export type ILinkedinContact = INode & {
  __typename?: 'LinkedinContact';
  companyName?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  user?: Maybe<IUser>;
};

export type ILocation = {
  __typename?: 'Location';
  col: Scalars['Int']['output'];
  line: Scalars['Int']['output'];
};

export type IMediciHolding = {
  __typename?: 'MediciHolding';
  alertCategory?: Maybe<Scalars['String']['output']>;
  amount: Scalars['Money']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  fundAmericaInvestments: IFundAmericaInvestmentConnection;
  id: Scalars['String']['output'];
  investor: IInvestor;
  offering: IMediciOffering;
  paymentMethod: IPaymentMethod;
  refreshedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  republicUrl: Scalars['String']['output'];
  stripeCharges: IStripeChargeConnection;
  treasuryPrimeAchTransfers?: Maybe<ITreasuryPrimeAchTransferConnection>;
  uuid: Scalars['String']['output'];
};

export type IMediciHoldingFundAmericaInvestmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IMediciHoldingStripeChargesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IMediciHoldingTreasuryPrimeAchTransfersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for MediciHolding. */
export type IMediciHoldingConnection = {
  __typename?: 'MediciHoldingConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IMediciHoldingEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<IMediciHolding>>>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IMediciHoldingEdge = {
  __typename?: 'MediciHoldingEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<IMediciHolding>;
};

/** Medici integration offering */
export type IMediciIntegrationOffering = {
  __typename?: 'MediciIntegrationOffering';
  escrowAccountId?: Maybe<Scalars['String']['output']>;
  escrowProvider: IMediciIntegrationOfferingEscrowProviderEnum;
  fundsInEscrowCents: Scalars['BigInt']['output'];
  fundsInEscrowFormatted: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  offering: IOffering;
  stripeAccount?: Maybe<IMediciIntegrationOfferingStripeAccount>;
  uuid: Scalars['String']['output'];
};

/** The connection type for MediciIntegrationOffering. */
export type IMediciIntegrationOfferingConnection = {
  __typename?: 'MediciIntegrationOfferingConnection';
  /** A list of edges. */
  edges: Array<IMediciIntegrationOfferingEdge>;
  /** A list of nodes. */
  nodes: Array<IMediciIntegrationOffering>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IMediciIntegrationOfferingEdge = {
  __typename?: 'MediciIntegrationOfferingEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: IMediciIntegrationOffering;
};

/** List of medici integration offering escrow providers */
export enum IMediciIntegrationOfferingEscrowProviderEnum {
  EscrowLess = 'ESCROW_LESS',
  FundAmerica = 'FUND_AMERICA',
  TreasuryPrime = 'TREASURY_PRIME',
}

/** Stripe account information strore on Medici side */
export type IMediciIntegrationOfferingStripeAccount = {
  __typename?: 'MediciIntegrationOfferingStripeAccount';
  chargesEnabled?: Maybe<Scalars['Boolean']['output']>;
  createTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** Person email name */
  personEmail?: Maybe<Scalars['Email']['output']>;
  /** Person first name */
  personFirstName?: Maybe<Scalars['String']['output']>;
  /** Person last name */
  personLastName?: Maybe<Scalars['String']['output']>;
  /** Person role */
  personRole?: Maybe<Scalars['String']['output']>;
  tosAcceptanceTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export type IMediciOffering = {
  __typename?: 'MediciOffering';
  bitpayAccount?: Maybe<IBitpayAccount>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  escrowProvider: Scalars['String']['output'];
  fundAmericaOffering?: Maybe<IFundAmericaOffering>;
  holdings: IMediciHoldingConnection;
  id: Scalars['ID']['output'];
  refreshedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  stripeAccount?: Maybe<IStripeAccount>;
  transactions: ITreasuryPrimeTransactionConnection;
  treasuryPrimeAccount?: Maybe<ITreasuryPrimeAccount>;
  treasuryPrimeAccountApplication?: Maybe<ITreasuryPrimeAccountApplication>;
  treasuryPrimeBusinessApplication?: Maybe<ITreasuryPrimeBusinessApplication>;
  uuid: Scalars['String']['output'];
};

export type IMediciOfferingHoldingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Array<IHoldingFilter>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IMediciOfferingTransactionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  uuid: Scalars['String']['input'];
};

/** The connection type for MediciOffering. */
export type IMediciOfferingConnection = {
  __typename?: 'MediciOfferingConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IMediciOfferingEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<IMediciOffering>>>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IMediciOfferingEdge = {
  __typename?: 'MediciOfferingEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<IMediciOffering>;
};

/** Message object of messages domain */
export type IMessage = {
  __typename?: 'Message';
  body?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  ownerId: Scalars['ID']['output'];
  /** @deprecated Use xOwnerOffering */
  ownerOffering: IOffering;
  ownerType?: Maybe<Scalars['String']['output']>;
  recipientUser: IUser;
  replyHash?: Maybe<Scalars['String']['output']>;
  seen: Scalars['Boolean']['output'];
  /** @deprecated Use xSeenAt */
  seenAt: Scalars['ISO8601DateTime']['output'];
  senderUser: IUser;
  sentByCurrentUser?: Maybe<Scalars['Boolean']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  unreadMessagesToUserCount?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /** Permitted actions for Core Message */
  userPermissions: IMessageUserPermissions;
  xOwnerOffering?: Maybe<IOffering>;
  xSeenAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/** The connection type for Message. */
export type IMessageConnection = {
  __typename?: 'MessageConnection';
  /** A list of edges. */
  edges: Array<IMessageEdge>;
  /** A list of nodes. */
  nodes: Array<IMessage>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Autogenerated input type of MessageCreate */
export type IMessageCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  message: IMessageInput;
};

/** Autogenerated return type of MessageCreate. */
export type IMessageCreatePayload = {
  __typename?: 'MessageCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  message?: Maybe<IMessage>;
};

/** An edge in a connection. */
export type IMessageEdge = {
  __typename?: 'MessageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: IMessage;
};

/** All the attributes for creating or updating a Message in Core domain */
export type IMessageInput = {
  body: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ownerId?: InputMaybe<Scalars['ID']['input']>;
  ownerType?: InputMaybe<Scalars['String']['input']>;
  recipientUserId?: InputMaybe<Scalars['ID']['input']>;
  recipientUserSlug?: InputMaybe<Scalars['String']['input']>;
  replyHash?: InputMaybe<Scalars['String']['input']>;
  seenAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  senderUserId?: InputMaybe<Scalars['ID']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};

/** Autogenerated input type of MessageMarkAsSeen */
export type IMessageMarkAsSeenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  senderUserSlug: Scalars['String']['input'];
};

/** Autogenerated return type of MessageMarkAsSeen. */
export type IMessageMarkAsSeenPayload = {
  __typename?: 'MessageMarkAsSeenPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  messages?: Maybe<Array<IMessage>>;
};

/** Autogenerated input type of MessageUpdate */
export type IMessageUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  message: IMessageInput;
};

/** Autogenerated return type of MessageUpdate. */
export type IMessageUpdatePayload = {
  __typename?: 'MessageUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  message?: Maybe<IMessage>;
};

/** User permissions exposed to a client for Message object of Core domain */
export type IMessageUserPermissions = {
  __typename?: 'MessageUserPermissions';
  canCreate: Scalars['Boolean']['output'];
  canUpdate: Scalars['Boolean']['output'];
};

export type IModeMobileBanner = {
  __typename?: 'ModeMobileBanner';
  user: IUser;
};

/** Autogenerated input type of MonolithAuthSessionCreate */
export type IMonolithAuthSessionCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Fragment in redirect path */
  fragment?: InputMaybe<Scalars['String']['input']>;
  /** Redirect path */
  redirectPath: Scalars['String']['input'];
};

/** Autogenerated return type of MonolithAuthSessionCreate. */
export type IMonolithAuthSessionCreatePayload = {
  __typename?: 'MonolithAuthSessionCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  redirectUrl: Scalars['Url']['output'];
};

/** Backup code for 2FA */
export type IMultiFactorAuthenticationBackupCode = {
  __typename?: 'MultiFactorAuthenticationBackupCode';
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  used: Scalars['Boolean']['output'];
};

/** Secret key for 2FA */
export type IMultiFactorAuthenticationSecret = {
  __typename?: 'MultiFactorAuthenticationSecret';
  authUri: Scalars['Url']['output'];
  id: Scalars['ID']['output'];
  secretKey: Scalars['String']['output'];
};

/** Validation error type for mutations which work with several objects */
export type IMultiObjectsError = {
  __typename?: 'MultiObjectsError';
  errors?: Maybe<Array<IValidationError>>;
  orderNumber: Scalars['Int']['output'];
};

export type IMutation = IActionListMutations &
  IEventsMutations &
  IFileProcessingMutations &
  IHelpCenterMutations &
  IInxIntegrationMutations &
  IJournalMutations &
  ILandingPagesMutations &
  IMutations &
  IPressReleasesMutations &
  ITagsMutations &
  IZenLedgerIntegrationMutations & {
    __typename?: 'Mutation';
    /**
     * Signs in an existing user via email/password
     * @deprecated Use Jwt_refreshToken instead
     */
    authRefresh: IAuthRefreshPayload;
    /** Generates a new set of auth tokens from a valid refresh token */
    Jwt_refreshToken: IAuthRefreshPayload;
    /** Logs in or creates a user via a new social auth connection */
    _socialAuth_loginOrCreate: IAuthApplicationCreateSocialAuthPayload;
    /**
     * Logs in a user via a new social auth connection
     * @deprecated Use _socialAuth_create instead
     */
    authApplicationCreateSocialAuth: IAuthApplicationCreateSocialAuthPayload;
    /**
     * Logs in a user via an existing social auth connection
     * @deprecated Use _socialAuth_login instead
     */
    authApplicationLoginSocialAuth: IAuthApplicationLoginSocialAuthPayload;
    /**
     * Registers a user in r-auth
     * @deprecated Use _userAuth_registerUser instead
     */
    authApplicationRegisterUser: IAuthApplicationRegisterUserPayload;
    /**
     * Signs in an existing user via email/password
     * @deprecated Use _userAuth_signInUser instead
     */
    authApplicationSignInUser: IAuthApplicationSignInUserPayload;
    /**
     * Finds the user and returns their authId
     * @deprecated Use _userAuth_getAuthId instead
     */
    authApplicationSyncUser: IAuthApplicationSyncUserPayload;
    /**
     * Invalidates the user's token
     * @deprecated Use _userAuth_signOutUser instead
     */
    authSignOut: IAuthSignOutPayload;
    /**
     * Logs in as another user
     * @deprecated Use _userAuth_loginAsAnotherUser instead
     */
    authApplicationLoginAsAnotherUser: IAuthApplicationLoginAsAnotherUserPayload;
    /** Changes a user's password */
    authChangePassword: IAuthChangePasswordPayload;
    /** Changes a user's email */
    authApplicationChangeEmail: IAuthApplicationChangeEmailPayload;
    /** Generates a password reset for a user */
    authApplicationGeneratePasswordReset: IAuthApplicationGeneratePasswordResetPayload;
    /** Resets a user's password using a reset token */
    authApplicationResetPasswordWithToken: IAuthApplicationResetPasswordWithTokenPayload;
    /** Checks if a reset token is valid w/o actually resetting the password */
    authApplicationValidatePasswordResetToken: IAuthApplicationValidatePasswordResetTokenPayload;
    /** Registers a user in r-auth */
    _userAuth_registerUser: IAuthApplicationRegisterUserPayload;
    /** Signs in an existing user via email/password */
    _userAuth_signInUser: IAuthApplicationSignInUserPayload;
    /** Invalidates the user's token */
    UserAuth_signOutUser: IAuthSignOutPayload;
    /** Logs in as another user */
    _userAuth_loginAsAnotherUser: IAuthApplicationLoginAsAnotherUserPayload;
    /** Authenticates a service (application) in r-auth */
    authApplicationAuthenticate: IAuthApplicationAuthenticatePayload;
    /** Update R/Capital portfolio access */
    capitalUsers_updateUserAccess: ICapitalUserType;
    /** Republic Capital self-serve document upload and management */
    capitalUsers_initBoxAuditAndFileTransfer: Scalars['String']['output'];
    /** Creates a Transfer Agent Account */
    _transferAgentAccount_createTransferAgentAccount: ITransferAgentAccount;
    /** Updates a Transfer Agent Account */
    _transferAgentAccount_updateTransferAgentAccount: ITransferAgentAccount;
    /** Creates a Transfer Agent Entity */
    _transferAgentEntity_createTransferAgentEntity: ITransferAgentEntity;
    /** Updates a Transfer Agent Entity */
    _transferAgentEntity_updateTransferAgentEntity: ITransferAgentEntity;
    /** Submits a change request for an Entity */
    _transferAgentEntity_submitChangeRequestForTransferAgentEntity: ITransferAgentEntity;
    /** Creates a Transfer Agent Holding */
    _transferAgentHolding_createTransferAgentHolding: ITransferAgentHolding;
    /** Updates a Transfer Agent Holding */
    _transferAgentHolding_updateTransferAgentHolding?: Maybe<ITransferAgentHolding>;
    /** Creates a Transfer Agent Security */
    _transferAgentSecurity_createTransferAgentSecurity: ITransferAgentSecurity;
    /** Updates a Transfer Agent Security */
    _transferAgentSecurity_updateTransferAgentSecurity: ITransferAgentSecurity;
    /** Updates an Engagement Request with details from the external TA (Brassica) and generates a new agreement URL if needed */
    _transferAgentSecurity_refreshTransferAgentSecurityEngagementRequest: ITransferAgentSecurity;
    /** Cancels a PENDING engagement request for a Security */
    _transferAgentSecurity_cancelTransferAgentSecurityEngagementRequest: ITransferAgentSecurity;
    /** Creates a Transfer Agent Security Transfer */
    _transferAgentSecurityTransfer_createTransferAgentSecurityTransfer: ITransferAgentSecurityTransfer;
    /** Cancels a PENDING Transfer Agent Security Transfer */
    _transferAgentSecurityTransfer_cancelTransferAgentSecurityTransfer: ITransferAgentSecurityTransfer;
    /** Submits a PENDING Transfer Agent Security Transfer to the external TA Vendor for review and process */
    _transferAgentSecurityTransfer_submitTransferAgentSecurityTransfer: ITransferAgentSecurityTransfer;
    /** Determines if one or more Transfer Agent Holdings can be transferred */
    _transferAgentSecurityTransfer_canHoldingsBeTransferred: IHoldingsTransferHealthCheck;
    /** Creates a Transfer Agent User */
    _transferAgentUser_createTransferAgentUser: ITransferAgentUser;
    /** Submits a change request for an User */
    _transferAgentUser_submitChangeRequestForTransferAgentUser: ITransferAgentUser;
    /** Creates a Transfer Agent Vendor */
    _transferAgentVendor_createTransferAgentVendor: ITransferAgentVendor;
    /** Updates a Transfer Agent Vendor */
    _transferAgentVendor_updateTransferAgentVendor: ITransferAgentVendor;
    invokeProcessorEvent: Scalars['String']['output'];
    /** Saves email template in DDB for compliance purposes */
    _docGenerationFactory_saveEmailForCompliance: Scalars['String']['output'];
    downloadTransactionLedger: IDownloadTransactionLedgerPayload;
    refreshHolding: IRefreshHoldingPayload;
    refreshTransactions: IRefreshTransactionsPayload;
    synchronizeHolding: ISynchronizeHoldingPayload;
    synchronizeOfferingHolding: ISynchronizeOfferingHoldingPayload;
    accreditationVerificationsCreate?: Maybe<IAccreditationVerificationCreatePayload>;
    /** [DEPRECATED] Was supposed to be InvestorEntityIdentityVerficationCreateDocumentUpload */
    accreditationVerificationsCreateDocumentUpload?: Maybe<IAccreditationVerificationCreateDocumentUploadPayload>;
    /** Create AccreditedAccess object of Core domain */
    accreditedAccessCreate?: Maybe<IAccreditedAccessCreatePayload>;
    /** Create Bank account */
    achAccountCreate?: Maybe<IAchAccountCreatePayload>;
    /** Update Bank account */
    achAccountUpdate?: Maybe<IAchAccountUpdatePayload>;
    /** Update issue state */
    actionListUpdateIssueState?: Maybe<IActionListIssueUpdateStatePayload>;
    adminDealTermsUpsert?: Maybe<IAdminOfferingTermTierDealTermUpsertPayload>;
    adminInvestorProfileApproveKycAml?: Maybe<IAdminInvestorProfileApproveKycAmlPayload>;
    adminTermTiersUpsert?: Maybe<IAdminOfferingTermTierUpsertPayload>;
    /** Add Capability to User */
    adminUserCapabilityAdd?: Maybe<IAdminUserCapabilityAddPayload>;
    /** Edit Capability of User */
    adminUserCapabilityEdit?: Maybe<IAdminUserCapabilityEditPayload>;
    /** Remove Capability of a User */
    adminUserCapabilityRemove?: Maybe<IAdminUserCapabilityRemovePayload>;
    /** Process OTP codes for 2FA */
    authenticateWithTwoFactor?: Maybe<IAuthAuthenticateWithTwoFactorPayload>;
    /**
     * Dismiss Banner by type for user of Core domain
     * @deprecated Clients manage this logic on their side, use dismissedBanners argument of banner query
     */
    bannerDismiss?: Maybe<IBannerDismissPayload>;
    beneficialOwnersDraftInvite?: Maybe<IBeneficialOwnersDraftInvitePayload>;
    beneficialOwnersRemove?: Maybe<IBeneficialOwnersRemoveOwnerPayload>;
    beneficialOwnersResendInvite?: Maybe<IBeneficialOwnersResendInvitePayload>;
    /** Cancel Order object */
    cancelOrder?: Maybe<ISecondaryCancelOrderPayload>;
    /** Create Comment object of Comments domain */
    commentCreate?: Maybe<ICommentCreatePayload>;
    /** Delete Comment object of Comments domain by slug */
    commentDelete?: Maybe<ICommentDeletePayload>;
    /** Mark Comment As Helpful of Comments domain if owner is Offering */
    commentMarkAsHelpful?: Maybe<ICommentMarkAsHelpfulPayload>;
    /** Mark Comment As Not Helpful of Comments domain if owner is Offering */
    commentMarkAsNotHelpful?: Maybe<ICommentMarkAsNotHelpfulPayload>;
    /** Mark Comment object As Not Spam of Comments domain by id */
    commentMarkNotSpam?: Maybe<ICommentMarkNotSpamPayload>;
    /** Mark Comment (children) object As Spam of Comments domain by id */
    commentMarkSpam?: Maybe<ICommentMarkSpamPayload>;
    /** Reply to Comment object of Comments domain */
    commentReply?: Maybe<ICommentReplyPayload>;
    /** Create common term object for Common Term domain */
    commonTermCreate?: Maybe<ICommonTermCreatePayload>;
    /** Delete common term object for Common Term domain */
    commonTermDelete?: Maybe<ICommonTermDeletePayload>;
    /** Update common term object for Common Term domain */
    commonTermUpdate?: Maybe<ICommonTermUpdatePayload>;
    /** Send an email to specified internal emails from Crypto landing Contact Form */
    contactFormAssetManagementEmailSend?: Maybe<IContactFormAssetManagementEmailSendPayload>;
    /** Send an email to specified internal emails from Crypto landing Contact Form */
    contactFormEmailSend?: Maybe<IContactFormEmailSendPayload>;
    /** Create Covered Core(Person or Entity) object */
    coveredCoreCreate?: Maybe<IRoleCoveredCoreCreatePayload>;
    /** Delete Covered Core(Person or Entity) object */
    coveredCoreDelete?: Maybe<IRoleCoveredCoreDeletePayload>;
    /** Create Document object for Covered Core(Person or Entity) */
    coveredCoreDocumentCreate?: Maybe<IRoleCoveredCoreDocumentCreatePayload>;
    /** Delete Document object for Covered Core(Person or Entity) */
    coveredCoreDocumentDelete?: Maybe<IRoleCoveredCoreDocumentDeletePayload>;
    /** Update Covered Core(Person or Entity) object */
    coveredCoreUpdate?: Maybe<IRoleCoveredCoreUpdatePayload>;
    /** Create matching Order object with BUY_ORDER type for an existing Sell Order */
    createMatchingBuyOrder?: Maybe<ISecondarySellOrderCreateBuyOrderPayload>;
    /** Create Order object with SELL_ORDER type */
    createSellOrder?: Maybe<ISecondaryCreateSellOrderPayload>;
    /** Create Stripe account for an offering */
    createStripeAccount?: Maybe<IOnboardingCardPaymentCreateStripeAccountPayload>;
    /** Create Crypto BlockchainRuleSet object for Core domain */
    cryptoBlockchainRuleSetCreate?: Maybe<ICryptoBlockchainRuleSetCreatePayload>;
    /** Delete Crypto BlockchainRuleSet object for Core domain */
    cryptoBlockchainRuleSetDelete?: Maybe<ICryptoBlockchainRuleSetDeletePayload>;
    /** Update  Crypto BlockchainRuleSet object for Core domain */
    cryptoBlockchainRuleSetUpdate?: Maybe<ICryptoBlockchainRuleSetUpdatePayload>;
    /** Create Crypto Distribution object for Core domain */
    cryptoDistributionsCreate?: Maybe<ICryptoDistributionCreatePayload>;
    /** Update Joinder Agreement for Crypto Distribution in Core domain */
    cryptoDistributionsJoinderAgreementUpdate?: Maybe<ICryptoDistributionUpdateJoinderAgreementPayload>;
    /** Allow a user to sign Joinder Agreement for a provided Crypto Distribution */
    cryptoDistributionsSignJoinderAgreement?: Maybe<ICryptoDistributionSignJoinderAgreementPayload>;
    /** Create Crypto Investment Option object for Core domain */
    cryptoInvestmentOptionCreate?: Maybe<ICryptoInvestmentOptionCreatePayload>;
    /** Delete CryptoInvestmentOption object for Core domain */
    cryptoInvestmentOptionDelete?: Maybe<ICryptoInvestmentOptionDeletePayload>;
    /** Update Crypto Investment Option object for Core domain */
    cryptoInvestmentOptionUpdate?: Maybe<ICryptoInvestmentOptionUpdatePayload>;
    /** Create Crypto Investment Token objects of Core domain */
    cryptoInvestmentTokenCreate?: Maybe<ICryptoCreateInvestmentTokenPayload>;
    /** Create Crypto Multisig Account object of Core domain */
    cryptoMultisigAccountCreate?: Maybe<ICryptoMultisigAccountCreatePayload>;
    /** Delete Crypto Multisig Account object of Core domain */
    cryptoMultisigAccountDelete?: Maybe<ICryptoMultisigAccountDeletePayload>;
    /** Create Crypto Multisig Account Signer object of Core domain */
    cryptoMultisigAccountSignerCreate?: Maybe<ICryptoMultisigAccountSignerCreatePayload>;
    /** Delete Crypto Multisig Account Signer object of Core domain */
    cryptoMultisigAccountSignerDelete?: Maybe<ICryptoMultisigAccountSignerDeletePayload>;
    /** Update Crypto Multisig Account Signer object of Core domain */
    cryptoMultisigAccountSignerUpdate?: Maybe<ICryptoMultisigAccountSignerUpdatePayload>;
    /** Update Crypto Multisig Account object of Core domain */
    cryptoMultisigAccountUpdate?: Maybe<ICryptoMultisigAccountUpdatePayload>;
    /** Sets a user's public key on the user model */
    cryptoSetPublicKey?: Maybe<ICryptoSetPublicKeyPayload>;
    /** Create Crypto Token Address object for Core domain */
    cryptoTokenAddressCreate?: Maybe<ICryptoTokenAddressCreatePayload>;
    /** Delete Crypto Token Address object for Core domain */
    cryptoTokenAddressDelete?: Maybe<ICryptoTokenAddressDeletePayload>;
    /** Update Crypto Token Address object for Core domain */
    cryptoTokenAddressUpdate?: Maybe<ICryptoTokenAddressUpdatePayload>;
    /** Create Crypto Token object for Core domain */
    cryptoTokensCreate?: Maybe<ICryptoTokenCreatePayload>;
    /** Delete CryptoTokens object for Core domain */
    cryptoTokensDelete?: Maybe<ICryptoTokenDeletePayload>;
    /** Update Crypto Token object for Core domain */
    cryptoTokensUpdate?: Maybe<ICryptoTokenUpdatePayload>;
    /** Create Crypto Transaction object for Core domain */
    cryptoTransactionCreate?: Maybe<ICryptoTransactionCreatePayload>;
    /** Update Crypto Transaction Signature object for Core domain */
    cryptoTransactionSignatureUpdate?: Maybe<ICryptoTransactionSignatureUpdatePayload>;
    /** Update Crypto Transaction object for Core domain */
    cryptoTransactionUpdate?: Maybe<ICryptoTransactionUpdatePayload>;
    /** Update crypto wallet object for Core domain */
    cryptoValidatePassword?: Maybe<ICryptoWalletValidatePasswordPayload>;
    /** Clear wallet from device. Doesn't delete record. Just updates clear_from_device to true. */
    cryptoWalletDeviceClear?: Maybe<ICryptoWalletDeviceClearPayload>;
    /** Create Crypto Wallet Device object */
    cryptoWalletDeviceCreate?: Maybe<ICryptoWalletDeviceCreatePayload>;
    /** Delete Crypto Wallet Device object */
    cryptoWalletDeviceDelete?: Maybe<ICryptoWalletDeviceDeletePayload>;
    /** Create or Update Crypto Wallet Device object */
    cryptoWalletDeviceUpdate?: Maybe<ICryptoWalletDeviceUpdatePayload>;
    /** Create Crypto Wallet Disclaimer object for Core domain */
    cryptoWalletDisclaimersCreate?: Maybe<ICryptoWalletDisclaimerCreatePayload>;
    /** Delete Crypto Wallet Disclaimers object for Core domain */
    cryptoWalletDisclaimersDelete?: Maybe<ICryptoWalletDisclaimerDeletePayload>;
    /** Update Crypto Wallet Disclaimer object for Core domain */
    cryptoWalletDisclaimersUpdate?: Maybe<ICryptoWalletDisclaimerUpdatePayload>;
    /** Create crypto wallet token object for Core domain */
    cryptoWalletTokensCreate?: Maybe<ICryptoWalletTokenCreatePayload>;
    /** Delete crypto wallet token object for Core domain */
    cryptoWalletTokensDelete?: Maybe<ICryptoWalletTokenDeletePayload>;
    /** Update crypto wallet token object for Core domain */
    cryptoWalletTokensUpdate?: Maybe<ICryptoWalletTokenUpdatePayload>;
    /** Create crypto wallet object for Core domain */
    cryptoWalletsCreate?: Maybe<ICryptoWalletCreatePayload>;
    /** Delete crypto wallet object for Core domain */
    cryptoWalletsDelete?: Maybe<ICryptoWalletDeletePayload>;
    /** Update crypto wallet object for Core domain */
    cryptoWalletsUpdate?: Maybe<ICryptoWalletUpdatePayload>;
    /** Accept the Disclosure for a Brassica Custodial Account */
    custodialAccountApplicationAcceptDisclosures?: Maybe<ICustodialAccountApplicationAcceptDisclosuresPayload>;
    /** Sign the Custodial Agreement Terms for a Brassica Custodial Account */
    custodialAccountApplicationSignAgreements?: Maybe<ICustodialAccountApplicationSignAgreementsPayload>;
    /** Create custom term object for Offering. */
    customTermCreate?: Maybe<IOfferingsCustomTermCreatePayload>;
    /** Delete custom term object for Offering. */
    customTermDelete?: Maybe<IOfferingsCustomTermDeletePayload>;
    /** Update custom term object for Offering. */
    customTermUpdate?: Maybe<IOfferingsCustomTermUpdatePayload>;
    /** Update new EDGAR access codes */
    edgarAccessUpdate?: Maybe<IIssuerIssuerProfileEdgarAccessUpdatePayload>;
    /** Edit Order object */
    editOrder?: Maybe<ISecondaryEditOrderPayload>;
    /** Create entity */
    entityCreate?: Maybe<IInvestorProfileEntityCreatePayload>;
    /** Delete entity */
    entityDelete?: Maybe<IInvestorProfileEntityDeletePayload>;
    /** Update entity */
    entityUpdate?: Maybe<IInvestorProfileEntityUpdatePayload>;
    /** Create Event object of Events domain */
    eventsCreate?: Maybe<IEventsCreatePayload>;
    /** Delete Event object of Events domain by id */
    eventsDelete?: Maybe<IEventsDeletePayload>;
    /** Subscribe Guest to Event by id */
    eventsGuestsRsvpCreate?: Maybe<IEventsGuestRsvpCreatePayload>;
    /** Publish Event object of Events domain by id */
    eventsPublish?: Maybe<IEventsPublishPayload>;
    /** Unpublish Event object of Events domain by id */
    eventsUnpublish?: Maybe<IEventsUnpublishPayload>;
    /** Update Event object of Events domain by id */
    eventsUpdate?: Maybe<IEventsUpdatePayload>;
    /** Exchange an old-style token for a new r-auth one */
    exchangeToken?: Maybe<IAuthExchangeTokenPayload>;
    /** Generates presigned URL for direct file processing to S3 bucket */
    fileProcessingPresignUrl?: Maybe<IFileProcessingUploadPresignUrlPayload>;
    /** Upload user's documents to FinTron blob service */
    finTronUploadDocuments?: Maybe<IFinTronUploadDocumentsPayload>;
    /** Create Document object for financial details */
    financialDocumentCreate?: Maybe<IOfferingFinancialDocumentCreatePayload>;
    /** Delete Document object for financial document */
    financialDocumentDelete?: Maybe<IOfferingFinancialDocumentDeletePayload>;
    /** Allow to enable/disable or view/unview flags or popups for User */
    flipperFeatureUpdate?: Maybe<IFlipperFeatureUpdatePayload>;
    /**
     * Allow a user to follow a record. It is recommended to include the type of
     * object being followedIf no explicit record type is included, slug collisions are possible.
     */
    follow?: Maybe<IFollowCreatePayload>;
    /** Issuer requested attroney? */
    formCRequestAttorney?: Maybe<IOnboardingFormCRequestAttorneyPayload>;
    /** Get the template of TA or signed version if exists */
    generateSecondaryTransferAgreementUrl?: Maybe<ISecondaryTransactionGenerateSecondaryTransferAgreementUrlPayload>;
    groupDealPostCreate?: Maybe<IGroupDealPostCreatePayload>;
    groupLeave?: Maybe<IGroupLeavePayload>;
    groupPostCreate?: Maybe<IInvestorGroupCreatePayload>;
    groupUpdate?: Maybe<IGroupUpdatePayload>;
    /** Create Article object of HelpCenter domain */
    helpCenterArticleCreate?: Maybe<IHelpCenterArticleCreatePayload>;
    /** Delete Article object of HelpCenter domain by id */
    helpCenterArticleDelete?: Maybe<IHelpCenterArticleDeletePayload>;
    /** Publish Article object of HelpCenter domain by id */
    helpCenterArticlePublish?: Maybe<IHelpCenterArticlePublishPayload>;
    /** UnPublish Article object of HelpCenter domain by id */
    helpCenterArticleUnpublish?: Maybe<IHelpCenterArticleUnpublishPayload>;
    /** Update Article object of HelpCenter domain by id */
    helpCenterArticleUpdate?: Maybe<IHelpCenterArticleUpdatePayload>;
    /**
     * Fill Identity for current user of Core domain
     * @deprecated Not used anymore
     */
    identityCreate?: Maybe<IIdentityCreatePayload>;
    identityDocumentsCreate?: Maybe<IIdentityDocumentCreatePayload>;
    /** Initializes Stripe Link session to buy crypto */
    initializeStripeOnrampSession?: Maybe<IStripeIntegrationInitializeOnrampSessionPayload>;
    /** Cancel Investment object of Core domain */
    investmentCancel?: Maybe<IInvestmentCancelPayload>;
    investmentCreate?: Maybe<IInvestmentCreatePayload>;
    /** Create investment reason */
    investmentReasonCreate?: Maybe<IOfferingInvestmentReasonCreatePayload>;
    investmentReasonDelete?: Maybe<IInvestmentReasonDeletePayload>;
    /** Update investment reason */
    investmentReasonUpdate?: Maybe<IOfferingInvestmentReasonUpdatePayload>;
    /** Reconfirm Investment object of Core domain */
    investmentReconfirm?: Maybe<IInvestmentReconfirmPayload>;
    investmentReinvest?: Maybe<IInvestmentReinvestPayload>;
    /** Confirm Investment reservation object of Core domain */
    investmentReservationConfirm?: Maybe<IInvestmentReservationConfirmPayload>;
    /** Update Investment object of Core domain */
    investmentUpdate?: Maybe<IInvestmentUpdatePayload>;
    investmentWireUpload?: Maybe<IInvestmentWireUploadPayload>;
    investorEntityIdentityVerificationCreateDocumentUpload?: Maybe<IInvestorEntityIdentityVerificationCreateDocumentUploadPayload>;
    investorEntityIdentityVerificationSubmitVerification?: Maybe<IInvestorEntityIdentityVerificationSubmitVerificationPayload>;
    /** Create secure document */
    investorProfileDocumentCreate?: Maybe<IInvestorProfileDocumentCreatePayload>;
    /** Kyc precheck for offering */
    investorProfileKycPrecheck?: Maybe<IInvestorProfileKycPrecheckPayload>;
    /** Proof of address for current users investor profile */
    investorProfileProofOfAddressApprove?: Maybe<IInvestorProfileProofOfAddressApprovePayload>;
    /** Update InvestorProfile object of Core domain */
    investorProfileUpdate?: Maybe<IInvestorProfileUpdatePayload>;
    /** Create account on INX side */
    inxIntegrationAccountCreate?: Maybe<IInxIntegrationAccountCreatePayload>;
    /** [DEPRECATED] Not implemented, do not use */
    issuerConfirmSecondaryTransactions?: Maybe<ISecondaryIssuerConfirmSecondaryTransactionsPayload>;
    /** Update Issuer Profile object */
    issuerProfileUpdate?: Maybe<IIssuerIssuerProfileUpdatePayload>;
    /** Create IssuerApplication object of Core domain */
    issuersApply?: Maybe<IIssuerApplyPayload>;
    /** Create Article object of Journal domain */
    journalArticleCreate?: Maybe<IJournalArticleCreatePayload>;
    /** Delete Article object of Journal domain by id */
    journalArticleDelete?: Maybe<IJournalArticleDeletePayload>;
    /** Publish Article object of Journal domain by id */
    journalArticlePublish?: Maybe<IJournalArticlePublishPayload>;
    /** Unpublish Article object of Journal domain by id */
    journalArticleUnpublish?: Maybe<IJournalArticleUnpublishPayload>;
    /** Update Article object of Journal domain by id */
    journalArticleUpdate?: Maybe<IJournalArticleUpdatePayload>;
    landingPageUpdate?: Maybe<ILandingPageUpdatePayload>;
    /** Update LegalInformation object of Core domain */
    legalInformationUpdate?: Maybe<IUserLegalInformationUpdatePayload>;
    /** Allow user to like an object. */
    likeObject?: Maybe<ILikeObjectPayload>;
    /** Create a Message object of Core domain. */
    messageCreate?: Maybe<IMessageCreatePayload>;
    /** Mark users incoming messages from sender_user_slug as seen */
    messageMarkAsSeen?: Maybe<IMessageMarkAsSeenPayload>;
    /** Update Message object of Core domain by id */
    messageUpdate?: Maybe<IMessageUpdatePayload>;
    /** Create/Update Offerings::Financials::Detail object */
    modifyFinancialsDetails?: Maybe<IOfferingFinancialModifyDetailsPayload>;
    /** Mark users notifications as seen, returns maximum of 10 notifications by default */
    notificationsMarkAsSeen?: Maybe<INotificationMarkAsSeenPayload>;
    /** Notifications in header viewed */
    notificationsMarkHeaderViewed?: Maybe<INotificationMarkHeaderViewedPayload>;
    /** Sign in through social network */
    oauth?: Maybe<IOAuthPayload>;
    /** Offering Crypto Settings Update for Core domain */
    offeringCryptoSettingsUpdate?: Maybe<IOfferingCryptoSettingsUpdatePayload>;
    /** Create NdaSign object for User */
    offeringUnlockDeal?: Maybe<IOfferingUnlockDealPayload>;
    /**
     * Create comment object for offering update of Comments domain
     * @deprecated Use commentCreate instead
     */
    offeringUpdateCommentCreate?: Maybe<ICommentOfferingUpdateCommentCreatePayload>;
    offeringsUpdateMarkAsSeen?: Maybe<IOfferingUpdateMarkAsSeenPayload>;
    /** Create airdrop action points */
    onboardingAirdropActionPointCreate?: Maybe<IOnboardingAirdropActionPointCreatePayload>;
    /** Delete airdrop action point */
    onboardingAirdropActionPointDelete?: Maybe<IOnboardingAirdropActionPointDeletePayload>;
    /** Update airdrop action point */
    onboardingAirdropActionPointUpdate?: Maybe<IOnboardingAirdropActionPointUpdatePayload>;
    /** Publish airdrop action points */
    onboardingAirdropActionPointsPublish?: Maybe<IOnboardingAirdropActionPointPublishPayload>;
    /** Update onboarding company profile */
    onboardingCompanyProfileUpdate?: Maybe<IOnboardingCompanyProfileUpdatePayload>;
    /** Create new onboarding EDGAR access document */
    onboardingDocumentEdgarAccessCreate?: Maybe<IOnboardingDocumentEdgarAccessCreatePayload>;
    /** Update onboarding EDGAR access document */
    onboardingDocumentEdgarAccessUpdate?: Maybe<IOnboardingDocumentEdgarAccessUpdatePayload>;
    /** Select cik flow */
    onboardingEdgarAccessSelectCikFlow?: Maybe<IOnboardingEdgarAccessSelectCikFlowPayload>;
    /** Create onboarding member object */
    onboardingMemberCreate?: Maybe<IOnboardingMemberCreatePayload>;
    /** Delete onboarding member object by id */
    onboardingMemberDelete?: Maybe<IOnboardingMemberDeletePayload>;
    /** Update onboarding member object by id */
    onboardingMemberUpdate?: Maybe<IOnboardingMemberUpdatePayload>;
    /** Update onboarding Target Launch Date */
    onboardingOverviewUpdateTargetLaunchDate?: Maybe<IOnboardingOverviewTargetLaunchDateUpdatePayload>;
    /** Publish Offering risks */
    onboardingPublishRisks?: Maybe<IOnboardingRiskPublishRisksPayload>;
    /** Update onboarding Pixel and offering analytics related fields */
    onboardingTrackingPixelUpdate?: Maybe<IOnboardingTrackingPixelUpdatePayload>;
    /** Request TTW enabling for an offering */
    onboardingTtwRequest?: Maybe<IOnboardingReservationCampaignRequestTtwPayload>;
    /** Fill in the risks from Form C (removes existing risks) */
    onboardingUpdateFormCRisks?: Maybe<IOnboardingRiskUpdateFormCRisksPayload>;
    /** Update Offering risks (create/update/delete) */
    onboardingUpdateRisks?: Maybe<IOnboardingRiskUpdateRisksPayload>;
    /** Update Outward Notifications Category object for Core domain */
    outwardNotificationsCategoryUpdate?: Maybe<IOutwardNotificationsCategoryUpdatePayload>;
    paymentMethodCreateAch?: Maybe<IPaymentMethodCreateAchPayload>;
    paymentMethodCreateAltoIra?: Maybe<IPaymentMethodCreateAltoIraPayload>;
    paymentMethodCreateBitpay?: Maybe<IPaymentMethodCreateBitpayPayload>;
    paymentMethodCreateCard?: Maybe<IPaymentMethodCreateCardPayload>;
    paymentMethodCreateWire?: Maybe<IPaymentMethodCreateWirePayload>;
    /** [DEPRECATED] No longer work with Medici */
    paymentMethodDelete?: Maybe<IPaymentMethodDeletePayload>;
    plaidIntegrationCreate?: Maybe<IPlaidIntegrationCreatePayload>;
    /**
     * Create Comment object of Comments domain
     * @deprecated Use commentCreate instead
     */
    postCommentCreate?: Maybe<ICommentCreatePayload>;
    /** Create Press article object of Press Releases domain */
    pressReleasesCreate?: Maybe<IPressReleasesCreatePayload>;
    /** Delete Press article object of Press Releases domain by id */
    pressReleasesDelete?: Maybe<IPressReleasesDeletePayload>;
    /** Publish Press article object of Press Releases domain by id */
    pressReleasesPublish?: Maybe<IPressReleasesPublishPayload>;
    /** Unpublish Press article object of Press Releases domain by id */
    pressReleasesUnpublish?: Maybe<IPressReleasesUnpublishPayload>;
    /** Update Press article object of Press Releases domain */
    pressReleasesUpdate?: Maybe<IPressReleasesUpdatePayload>;
    /** Create Privilege object of Core domain */
    privilegeCreate?: Maybe<IPrivilegeCreatePayload>;
    promotionCampaignValidateCode?: Maybe<IPromotionCampaignValidateCodePayload>;
    promotionCampaignValidateNoteCode?: Maybe<IPromotionCampaignValidateNoteCodePayload>;
    /** Update transaction state by providing crypto hash from byuer */
    provideCryptoTransactionHash?: Maybe<ISecondaryTransactionProvideCryptoHashPayload>;
    /** Create Regulation Best Interest Quiz object for Regulation Best Interest Quizzes domain */
    regulationBestInterestQuizCreate?: Maybe<IRegulationBestInterestQuizCreatePayload>;
    /** Delete Regulation Best Interest Quiz object for Regulation Best Interest Quizzes domain */
    regulationBestInterestQuizDelete?: Maybe<IRegulationBestInterestQuizDeletePayload>;
    /** Update Regulation Best Interest Quiz object for Regulation Best Interest Quizzes domain */
    regulationBestInterestQuizUpdate?: Maybe<IRegulationBestInterestQuizUpdatePayload>;
    /** Invites a new user to Republic Capital */
    republicCapitalPopulateUserData?: Maybe<IRepublicCapitalPopulateUserDataPayload>;
    /** Export CSV file with all opportunities in a user portfolio */
    republicCapitalPortfolioCsv?: Maybe<IRepublicCapitalPortfolioCsvPayload>;
    /** Refreshes data for all users of Republic Capital */
    republicCapitalRefreshUserData?: Maybe<IRepublicCapitalRefreshUserDataPayload>;
    /** Resend a new user invitation email to a Republic Capital user */
    republicCapitalResetPasswordEmail?: Maybe<IRepublicCapitalResetPasswordEmailPayload>;
    /** Indicate interest in secondary market */
    requestAccess?: Maybe<ISecondaryRequestAccessPayload>;
    /** Request CPA to collect financials */
    requestCpa?: Maybe<IOfferingFinancialRequestCpaPayload>;
    /** Reconfirm interest to sell */
    secondariesReconfirmInterestToSell?: Maybe<ISecondaryReconfirmInterestToSellPayload>;
    /** Initiate payment for a buy order. Must be called before proceeding with the payment. */
    secondariesStartBuyerPayment?: Maybe<ISecondaryStartBuyerPaymentPayload>;
    /** [DEPRECATED] Change state of object Secondary Transaction to PENDING_TA_CONFIRMATION */
    sellerConfirmSecondaryTransaction?: Maybe<ISecondarySellerConfirmSecondaryTransactionPayload>;
    /** Session create */
    sessionCreate?: Maybe<IMonolithAuthSessionCreatePayload>;
    /** Sign the beneficial owner certification a Brassica Custodial Account */
    signBeneficialOwnerCertification?: Maybe<ICustodialSignBeneficialOwnerCertificationPayload>;
    /** Sign in */
    signIn?: Maybe<IAuthSignInPayload>;
    /** Admin-only mutation to log in as a user */
    signInAsUser?: Maybe<IAuthSignInAsUserPayload>;
    /** Sign the nominee agreement */
    signNomineeAgreement?: Maybe<ICustodialSignNomineeAgreementPayload>;
    /**
     * Get the template of TA or signed version if exists
     * @deprecated Use generateSecondaryTransferAgreementUrl
     */
    signTransferAgreement?: Maybe<ISecondaryTransactionGenerateSecondaryTransferAgreementUrlPayload>;
    signUp?: Maybe<IAuthSignUpPayload>;
    /** Create slider media item object for Offering. */
    sliderMediaItemCreate?: Maybe<IOfferingSliderMediaItemCreatePayload>;
    /** Delete slider media item object for Offering. */
    sliderMediaItemDelete?: Maybe<IOfferingSliderMediaItemDeletePayload>;
    /** Update slider media item object for Offering. */
    sliderMediaItemUpdate?: Maybe<IOfferingSliderMediaItemUpdatePayload>;
    /** Bulk update slider media item objects order. */
    sliderMediaItemsUpdateOrder?: Maybe<IOfferingSliderMediaItemsUpdateOrderPayload>;
    /** Subscribe User Via Email for Core domain */
    subscribeUserViaEmail?: Maybe<ISubscribeUserViaEmailPayload>;
    /** Create Tag object of Tags domain */
    tagsCreate?: Maybe<ITagsCreatePayload>;
    /** Delete Tag object of Tags domain by id */
    tagsDelete?: Maybe<ITagsDeletePayload>;
    /** Publish Tag object of Tags domain by id */
    tagsPublish?: Maybe<ITagsPublishPayload>;
    /** Unpublish Tag object of Tags domain by id */
    tagsUnpublish?: Maybe<ITagsUnpublishPayload>;
    /** Update Tag object of Tags domain by id */
    tagsUpdate?: Maybe<ITagsUpdatePayload>;
    /** Create Team Member object of About domain (within the Core domain) */
    teamMemberCreate?: Maybe<IUserRepublicTeamMemberCreatePayload>;
    /** Delete Team Member object of About domain (within the Core domain) */
    teamMemberDelete?: Maybe<IUserRepublicTeamMemberDeletePayload>;
    /** Update Team Member object of About domain (within the Core domain) */
    teamMemberUpdate?: Maybe<IUserRepublicTeamMemberUpdatePayload>;
    /** Create teaser page object */
    teaserPageCreate?: Maybe<ITeaserPageCreatePayload>;
    /** Delete teaser page object */
    teaserPageDelete?: Maybe<ITeaserPageDeletePayload>;
    /** Publish teaser page object */
    teaserPagePublish?: Maybe<ITeaserPagePublishPayload>;
    /** Send email with link to TeaserPage create page */
    teaserPageSendCreateLink?: Maybe<ITeaserPageSendCreateLinkPayload>;
    /** Unpublish teaser page object */
    teaserPageUnpublish?: Maybe<ITeaserPageUnpublishPayload>;
    /** Update teaser page object */
    teaserPageUpdate?: Maybe<ITeaserPageUpdatePayload>;
    /** Create teaser pages follower object */
    teaserPagesFollowerCreate?: Maybe<ITeaserPageFollowerCreatePayload>;
    /** Export CSV file with followers of teaser pages */
    teaserPagesFollowerCsv?: Maybe<ITeaserPageFollowersCsvPayload>;
    /** Delete teaser pages follower object */
    teaserPagesFollowerDelete?: Maybe<ITeaserPageFollowerDeletePayload>;
    /** Update teaser pages follower object */
    teaserPagesFollowerUpdate?: Maybe<ITeaserPageFollowerUpdatePayload>;
    /** Delete Follow object of Core domain */
    unfollow?: Maybe<IFollowDeletePayload>;
    /** Allow user to unlike an object. */
    unlikeObject?: Maybe<IUnlikeObjectPayload>;
    /** Update post-investment message for an offering */
    updateInvestmentMessage?: Maybe<IOnboardingPostInvestmentMessageUpdatePayload>;
    /** Update post-reservation message for an offering */
    updateReservationMessage?: Maybe<IOnboardingReservationCampaignUpdatePostMessagePayload>;
    /** Update basic info for current user */
    userBasicInfoUpdate?: Maybe<IUserBasicInfoUpdatePayload>;
    userConfirmEmail?: Maybe<IUserConfirmEmailPayload>;
    userEmailConfirmationResend?: Maybe<IUserEmailConfirmationResendPayload>;
    /** Updates current users email */
    userEmailUpdate?: Maybe<IUserEmailUpdatePayload>;
    /** Disable 2FA for current user */
    userMfaDisable?: Maybe<IUserMfaDisablePayload>;
    /** Enable 2FA for current user */
    userMfaEnable?: Maybe<IUserMfaEnablePayload>;
    userNotificationPreferencesUpdate?: Maybe<IUserNotificationPreferencesUpdatePayload>;
    /** Resets user password with reset token */
    userPasswordReset?: Maybe<IUserPasswordResetPayload>;
    userPasswordSet?: Maybe<IUserPasswordSetPayload>;
    /** Create a new reset token for user */
    userPasswordTokenCreate?: Maybe<IUserPasswordTokenCreatePayload>;
    /** Update password for current user using Devise functionality */
    userPasswordUpdate?: Maybe<IUserPasswordUpdatePayload>;
    userPrivacySettingsUpdate?: Maybe<IUserPrivacySettingsUpdatePayload>;
    userProfileUpdate?: Maybe<IUserProfileUpdatePayload>;
    /** Send delete request for user */
    userSendDeleteRequest?: Maybe<IUserSendDeleteRequestPayload>;
    userSetInactive?: Maybe<IUserSetInactivePayload>;
    userUpdate?: Maybe<IUserUpdatePayload>;
    /** Send address to ZendLEdger */
    zenLedgerSendPortfolio?: Maybe<IZenLedgerIntegrationTaxPortfolioPayload>;
    /** An example field added by the generator */
    seedrsTestMutation: Scalars['String']['output'];
  };

export type IMutationAuthRefreshArgs = {
  input: IAuthRefreshInput;
};

export type IMutationJwt_RefreshTokenArgs = {
  refreshToken?: InputMaybe<Scalars['String']['input']>;
};

export type IMutation_SocialAuth_LoginOrCreateArgs = {
  authId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  provider: Scalars['String']['input'];
  providerId: Scalars['String']['input'];
};

export type IMutationAuthApplicationCreateSocialAuthArgs = {
  input: IAuthApplicationCreateSocialAuthInput;
};

export type IMutationAuthApplicationLoginSocialAuthArgs = {
  input: IAuthApplicationLoginSocialAuthInput;
};

export type IMutationAuthApplicationRegisterUserArgs = {
  input: IAuthApplicationRegisterUserInput;
};

export type IMutationAuthApplicationSignInUserArgs = {
  input: IAuthApplicationSignInUserInput;
};

export type IMutationAuthApplicationSyncUserArgs = {
  input: IAuthApplicationSyncUserInput;
};

export type IMutationAuthSignOutArgs = {
  input: IAuthSignOutInput;
};

export type IMutationAuthApplicationLoginAsAnotherUserArgs = {
  input: IAuthApplicationLoginAsAnotherUserInput;
};

export type IMutationAuthChangePasswordArgs = {
  input: IAuthChangePasswordInput;
};

export type IMutationAuthApplicationChangeEmailArgs = {
  input: IAuthApplicationChangeEmailInput;
};

export type IMutationAuthApplicationGeneratePasswordResetArgs = {
  input: IAuthApplicationGeneratePasswordResetInput;
};

export type IMutationAuthApplicationResetPasswordWithTokenArgs = {
  input: IAuthApplicationResetPasswordWithTokenInput;
};

export type IMutationAuthApplicationValidatePasswordResetTokenArgs = {
  input: IAuthApplicationValidatePasswordResetTokenInput;
};

export type IMutation_UserAuth_RegisterUserArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type IMutation_UserAuth_SignInUserArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type IMutationUserAuth_SignOutUserArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
};

export type IMutation_UserAuth_LoginAsAnotherUserArgs = {
  fromAuthId?: InputMaybe<Scalars['String']['input']>;
  toAuthId: Scalars['String']['input'];
};

export type IMutationAuthApplicationAuthenticateArgs = {
  input: IAuthApplicationAuthenticateInput;
};

export type IMutationCapitalUsers_UpdateUserAccessArgs = {
  userId: Scalars['Int']['input'];
  hasCapitalAccess: Scalars['Boolean']['input'];
};

export type IMutationCapitalUsers_InitBoxAuditAndFileTransferArgs = {
  folderId: Scalars['String']['input'];
  forceVersionUpdate?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IMutation_TransferAgentAccount_CreateTransferAgentAccountArgs = {
  transferAgentAccount: ITransferAgentAccountCreateInput;
};

export type IMutation_TransferAgentAccount_UpdateTransferAgentAccountArgs = {
  transferAgentAccount: ITransferAgentAccountUpdateInput;
};

export type IMutation_TransferAgentEntity_CreateTransferAgentEntityArgs = {
  transferAgentEntity: ITransferAgentEntityCreateInput;
};

export type IMutation_TransferAgentEntity_UpdateTransferAgentEntityArgs = {
  transferAgentEntity: ITransferAgentEntityUpdateInput;
};

export type IMutation_TransferAgentEntity_SubmitChangeRequestForTransferAgentEntityArgs =
  {
    changeRequest: ITransferAgentEntityChangeRequestInput;
  };

export type IMutation_TransferAgentHolding_CreateTransferAgentHoldingArgs = {
  transferAgentHolding: ITransferAgentHoldingCreateInput;
};

export type IMutation_TransferAgentHolding_UpdateTransferAgentHoldingArgs = {
  transferAgentHolding: ITransferAgentHoldingUpdateInput;
};

export type IMutation_TransferAgentSecurity_CreateTransferAgentSecurityArgs = {
  transferAgentSecurity: ITransferAgentSecurityCreateInput;
};

export type IMutation_TransferAgentSecurity_UpdateTransferAgentSecurityArgs = {
  transferAgentSecurity: ITransferAgentSecurityUpdateInput;
};

export type IMutation_TransferAgentSecurity_RefreshTransferAgentSecurityEngagementRequestArgs =
  {
    input: ITransferAgentSecurityEngagementReqRefreshInput;
  };

export type IMutation_TransferAgentSecurity_CancelTransferAgentSecurityEngagementRequestArgs =
  {
    id: Scalars['String']['input'];
  };

export type IMutation_TransferAgentSecurityTransfer_CreateTransferAgentSecurityTransferArgs =
  {
    transferAgentSecurityTransfer: ITransferAgentSecurityTransferInput;
  };

export type IMutation_TransferAgentSecurityTransfer_CancelTransferAgentSecurityTransferArgs =
  {
    id: Scalars['String']['input'];
  };

export type IMutation_TransferAgentSecurityTransfer_SubmitTransferAgentSecurityTransferArgs =
  {
    submitTransferAgentSecurityTransfer: ISubmitTransferAgentSecurityTransferInput;
  };

export type IMutation_TransferAgentSecurityTransfer_CanHoldingsBeTransferredArgs =
  {
    input: IHoldingsCanBeTransferredInput;
  };

export type IMutation_TransferAgentUser_CreateTransferAgentUserArgs = {
  transferAgentUser: ITransferAgentUserInput;
};

export type IMutation_TransferAgentUser_SubmitChangeRequestForTransferAgentUserArgs =
  {
    changeRequest: ITransferAgentUserChangeRequestInput;
  };

export type IMutation_TransferAgentVendor_CreateTransferAgentVendorArgs = {
  transferAgentVendor: ITransferAgentVendorCreateInput;
};

export type IMutation_TransferAgentVendor_UpdateTransferAgentVendorArgs = {
  transferAgentVendor: ITransferAgentVendorUpdateInput;
};

export type IMutationInvokeProcessorEventArgs = {
  input: IProcessRequestInput;
};

export type IMutation_DocGenerationFactory_SaveEmailForComplianceArgs = {
  input: IEmailSaveRequestInput;
};

export type IMutationDownloadTransactionLedgerArgs = {
  input: IDownloadTransactionLedgerInput;
};

export type IMutationRefreshHoldingArgs = {
  input: IRefreshHoldingInput;
};

export type IMutationRefreshTransactionsArgs = {
  input: IRefreshTransactionsInput;
};

export type IMutationSynchronizeHoldingArgs = {
  input: ISynchronizeHoldingInput;
};

export type IMutationSynchronizeOfferingHoldingArgs = {
  input: ISynchronizeOfferingHoldingInput;
};

export type IMutationAccreditationVerificationsCreateArgs = {
  input: IAccreditationVerificationCreateInput;
};

export type IMutationAccreditationVerificationsCreateDocumentUploadArgs = {
  input: IAccreditationVerificationCreateDocumentUploadInput;
};

export type IMutationAccreditedAccessCreateArgs = {
  input: IAccreditedAccessCreateInput;
};

export type IMutationAchAccountCreateArgs = {
  input: IAchAccountCreateInput;
};

export type IMutationAchAccountUpdateArgs = {
  input: IAchAccountUpdateInput;
};

export type IMutationActionListUpdateIssueStateArgs = {
  input: IActionListIssueUpdateStateInput;
};

export type IMutationAdminDealTermsUpsertArgs = {
  input: IAdminOfferingTermTierDealTermUpsertInput;
};

export type IMutationAdminInvestorProfileApproveKycAmlArgs = {
  input: IAdminInvestorProfileApproveKycAmlInput;
};

export type IMutationAdminTermTiersUpsertArgs = {
  input: IAdminOfferingTermTierUpsertInput;
};

export type IMutationAdminUserCapabilityAddArgs = {
  input: IAdminUserCapabilityAddInput;
};

export type IMutationAdminUserCapabilityEditArgs = {
  input: IAdminUserCapabilityEditInput;
};

export type IMutationAdminUserCapabilityRemoveArgs = {
  input: IAdminUserCapabilityRemoveInput;
};

export type IMutationAuthenticateWithTwoFactorArgs = {
  input: IAuthAuthenticateWithTwoFactorInput;
};

export type IMutationBannerDismissArgs = {
  input: IBannerDismissInput;
};

export type IMutationBeneficialOwnersDraftInviteArgs = {
  input: IBeneficialOwnersDraftInviteInput;
};

export type IMutationBeneficialOwnersRemoveArgs = {
  input: IBeneficialOwnersRemoveOwnerInput;
};

export type IMutationBeneficialOwnersResendInviteArgs = {
  input: IBeneficialOwnersResendInviteInput;
};

export type IMutationCancelOrderArgs = {
  input: ISecondaryCancelOrderInput;
};

export type IMutationCommentCreateArgs = {
  input: ICommentCreateInput;
};

export type IMutationCommentDeleteArgs = {
  input: ICommentDeleteInput;
};

export type IMutationCommentMarkAsHelpfulArgs = {
  input: ICommentMarkAsHelpfulInput;
};

export type IMutationCommentMarkAsNotHelpfulArgs = {
  input: ICommentMarkAsNotHelpfulInput;
};

export type IMutationCommentMarkNotSpamArgs = {
  input: ICommentMarkNotSpamInput;
};

export type IMutationCommentMarkSpamArgs = {
  input: ICommentMarkSpamInput;
};

export type IMutationCommentReplyArgs = {
  input: ICommentReplyInput;
};

export type IMutationCommonTermCreateArgs = {
  input: ICommonTermCreateInput;
};

export type IMutationCommonTermDeleteArgs = {
  input: ICommonTermDeleteInput;
};

export type IMutationCommonTermUpdateArgs = {
  input: ICommonTermUpdateInput;
};

export type IMutationContactFormAssetManagementEmailSendArgs = {
  input: IContactFormAssetManagementEmailSendInput;
};

export type IMutationContactFormEmailSendArgs = {
  input: IContactFormEmailSendInput;
};

export type IMutationCoveredCoreCreateArgs = {
  input: IRoleCoveredCoreCreateInput;
};

export type IMutationCoveredCoreDeleteArgs = {
  input: IRoleCoveredCoreDeleteInput;
};

export type IMutationCoveredCoreDocumentCreateArgs = {
  input: IRoleCoveredCoreDocumentCreateInput;
};

export type IMutationCoveredCoreDocumentDeleteArgs = {
  input: IRoleCoveredCoreDocumentDeleteInput;
};

export type IMutationCoveredCoreUpdateArgs = {
  input: IRoleCoveredCoreUpdateInput;
};

export type IMutationCreateMatchingBuyOrderArgs = {
  input: ISecondarySellOrderCreateBuyOrderInput;
};

export type IMutationCreateSellOrderArgs = {
  input: ISecondaryCreateSellOrderInput;
};

export type IMutationCreateStripeAccountArgs = {
  input: IOnboardingCardPaymentCreateStripeAccountInput;
};

export type IMutationCryptoBlockchainRuleSetCreateArgs = {
  input: ICryptoBlockchainRuleSetCreateInput;
};

export type IMutationCryptoBlockchainRuleSetDeleteArgs = {
  input: ICryptoBlockchainRuleSetDeleteInput;
};

export type IMutationCryptoBlockchainRuleSetUpdateArgs = {
  input: ICryptoBlockchainRuleSetUpdateInput;
};

export type IMutationCryptoDistributionsCreateArgs = {
  input: ICryptoDistributionCreateInput;
};

export type IMutationCryptoDistributionsJoinderAgreementUpdateArgs = {
  input: ICryptoDistributionUpdateJoinderAgreementInput;
};

export type IMutationCryptoDistributionsSignJoinderAgreementArgs = {
  input: ICryptoDistributionSignJoinderAgreementInput;
};

export type IMutationCryptoInvestmentOptionCreateArgs = {
  input: ICryptoInvestmentOptionCreateInput;
};

export type IMutationCryptoInvestmentOptionDeleteArgs = {
  input: ICryptoInvestmentOptionDeleteInput;
};

export type IMutationCryptoInvestmentOptionUpdateArgs = {
  input: ICryptoInvestmentOptionUpdateInput;
};

export type IMutationCryptoInvestmentTokenCreateArgs = {
  input: ICryptoCreateInvestmentTokenInput;
};

export type IMutationCryptoMultisigAccountCreateArgs = {
  input: ICryptoMultisigAccountCreateInput;
};

export type IMutationCryptoMultisigAccountDeleteArgs = {
  input: ICryptoMultisigAccountDeleteInput;
};

export type IMutationCryptoMultisigAccountSignerCreateArgs = {
  input: ICryptoMultisigAccountSignerCreateInput;
};

export type IMutationCryptoMultisigAccountSignerDeleteArgs = {
  input: ICryptoMultisigAccountSignerDeleteInput;
};

export type IMutationCryptoMultisigAccountSignerUpdateArgs = {
  input: ICryptoMultisigAccountSignerUpdateInput;
};

export type IMutationCryptoMultisigAccountUpdateArgs = {
  input: ICryptoMultisigAccountUpdateInput;
};

export type IMutationCryptoSetPublicKeyArgs = {
  input: ICryptoSetPublicKeyInput;
};

export type IMutationCryptoTokenAddressCreateArgs = {
  input: ICryptoTokenAddressCreateInput;
};

export type IMutationCryptoTokenAddressDeleteArgs = {
  input: ICryptoTokenAddressDeleteInput;
};

export type IMutationCryptoTokenAddressUpdateArgs = {
  input: ICryptoTokenAddressUpdateInput;
};

export type IMutationCryptoTokensCreateArgs = {
  input: ICryptoTokenCreateInput;
};

export type IMutationCryptoTokensDeleteArgs = {
  input: ICryptoTokenDeleteInput;
};

export type IMutationCryptoTokensUpdateArgs = {
  input: ICryptoTokenUpdateInput;
};

export type IMutationCryptoTransactionCreateArgs = {
  input: ICryptoTransactionCreateInput;
};

export type IMutationCryptoTransactionSignatureUpdateArgs = {
  input: ICryptoTransactionSignatureUpdateInput;
};

export type IMutationCryptoTransactionUpdateArgs = {
  input: ICryptoTransactionUpdateInput;
};

export type IMutationCryptoValidatePasswordArgs = {
  input: ICryptoWalletValidatePasswordInput;
};

export type IMutationCryptoWalletDeviceClearArgs = {
  input: ICryptoWalletDeviceClearInput;
};

export type IMutationCryptoWalletDeviceCreateArgs = {
  input: ICryptoWalletDeviceCreateInput;
};

export type IMutationCryptoWalletDeviceDeleteArgs = {
  input: ICryptoWalletDeviceDeleteInput;
};

export type IMutationCryptoWalletDeviceUpdateArgs = {
  input: ICryptoWalletDeviceUpdateInput;
};

export type IMutationCryptoWalletDisclaimersCreateArgs = {
  input: ICryptoWalletDisclaimerCreateInput;
};

export type IMutationCryptoWalletDisclaimersDeleteArgs = {
  input: ICryptoWalletDisclaimerDeleteInput;
};

export type IMutationCryptoWalletDisclaimersUpdateArgs = {
  input: ICryptoWalletDisclaimerUpdateInput;
};

export type IMutationCryptoWalletTokensCreateArgs = {
  input: ICryptoWalletTokenCreateInput;
};

export type IMutationCryptoWalletTokensDeleteArgs = {
  input: ICryptoWalletTokenDeleteInput;
};

export type IMutationCryptoWalletTokensUpdateArgs = {
  input: ICryptoWalletTokenUpdateInput;
};

export type IMutationCryptoWalletsCreateArgs = {
  input: ICryptoWalletCreateInput;
};

export type IMutationCryptoWalletsDeleteArgs = {
  input: ICryptoWalletDeleteInput;
};

export type IMutationCryptoWalletsUpdateArgs = {
  input: ICryptoWalletUpdateInput;
};

export type IMutationCustodialAccountApplicationAcceptDisclosuresArgs = {
  input: ICustodialAccountApplicationAcceptDisclosuresInput;
};

export type IMutationCustodialAccountApplicationSignAgreementsArgs = {
  input: ICustodialAccountApplicationSignAgreementsInput;
};

export type IMutationCustomTermCreateArgs = {
  input: IOfferingsCustomTermCreateInput;
};

export type IMutationCustomTermDeleteArgs = {
  input: IOfferingsCustomTermDeleteInput;
};

export type IMutationCustomTermUpdateArgs = {
  input: IOfferingsCustomTermUpdateInput;
};

export type IMutationEdgarAccessUpdateArgs = {
  input: IIssuerIssuerProfileEdgarAccessUpdateInput;
};

export type IMutationEditOrderArgs = {
  input: ISecondaryEditOrderInput;
};

export type IMutationEntityCreateArgs = {
  input: IInvestorProfileEntityCreateInput;
};

export type IMutationEntityDeleteArgs = {
  input: IInvestorProfileEntityDeleteInput;
};

export type IMutationEntityUpdateArgs = {
  input: IInvestorProfileEntityUpdateInput;
};

export type IMutationEventsCreateArgs = {
  input: IEventsCreateInput;
};

export type IMutationEventsDeleteArgs = {
  input: IEventsDeleteInput;
};

export type IMutationEventsGuestsRsvpCreateArgs = {
  input: IEventsGuestRsvpCreateInput;
};

export type IMutationEventsPublishArgs = {
  input: IEventsPublishInput;
};

export type IMutationEventsUnpublishArgs = {
  input: IEventsUnpublishInput;
};

export type IMutationEventsUpdateArgs = {
  input: IEventsUpdateInput;
};

export type IMutationExchangeTokenArgs = {
  input: IAuthExchangeTokenInput;
};

export type IMutationFileProcessingPresignUrlArgs = {
  input: IFileProcessingUploadPresignUrlInput;
};

export type IMutationFinTronUploadDocumentsArgs = {
  input: IFinTronUploadDocumentsInput;
};

export type IMutationFinancialDocumentCreateArgs = {
  input: IOfferingFinancialDocumentCreateInput;
};

export type IMutationFinancialDocumentDeleteArgs = {
  input: IOfferingFinancialDocumentDeleteInput;
};

export type IMutationFlipperFeatureUpdateArgs = {
  input: IFlipperFeatureUpdateInput;
};

export type IMutationFollowArgs = {
  input: IFollowCreateInput;
};

export type IMutationFormCRequestAttorneyArgs = {
  input: IOnboardingFormCRequestAttorneyInput;
};

export type IMutationGenerateSecondaryTransferAgreementUrlArgs = {
  input: ISecondaryTransactionGenerateSecondaryTransferAgreementUrlInput;
};

export type IMutationGroupDealPostCreateArgs = {
  input: IGroupDealPostCreateInput;
};

export type IMutationGroupLeaveArgs = {
  input: IGroupLeaveInput;
};

export type IMutationGroupPostCreateArgs = {
  input: IInvestorGroupCreateInput;
};

export type IMutationGroupUpdateArgs = {
  input: IGroupUpdateInput;
};

export type IMutationHelpCenterArticleCreateArgs = {
  input: IHelpCenterArticleCreateInput;
};

export type IMutationHelpCenterArticleDeleteArgs = {
  input: IHelpCenterArticleDeleteInput;
};

export type IMutationHelpCenterArticlePublishArgs = {
  input: IHelpCenterArticlePublishInput;
};

export type IMutationHelpCenterArticleUnpublishArgs = {
  input: IHelpCenterArticleUnpublishInput;
};

export type IMutationHelpCenterArticleUpdateArgs = {
  input: IHelpCenterArticleUpdateInput;
};

export type IMutationIdentityCreateArgs = {
  input: IIdentityCreateInput;
};

export type IMutationIdentityDocumentsCreateArgs = {
  input: IIdentityDocumentCreateInput;
};

export type IMutationInitializeStripeOnrampSessionArgs = {
  input: IStripeIntegrationInitializeOnrampSessionInput;
};

export type IMutationInvestmentCancelArgs = {
  input: IInvestmentCancelInput;
};

export type IMutationInvestmentCreateArgs = {
  input: IInvestmentCreateInput;
};

export type IMutationInvestmentReasonCreateArgs = {
  input: IOfferingInvestmentReasonInput;
};

export type IMutationInvestmentReasonDeleteArgs = {
  input: IInvestmentReasonDeleteInput;
};

export type IMutationInvestmentReasonUpdateArgs = {
  input: IOfferingInvestmentReasonInput;
};

export type IMutationInvestmentReconfirmArgs = {
  input: IInvestmentReconfirmInput;
};

export type IMutationInvestmentReinvestArgs = {
  input: IInvestmentReinvestInput;
};

export type IMutationInvestmentReservationConfirmArgs = {
  input: IInvestmentReservationConfirmInput;
};

export type IMutationInvestmentUpdateArgs = {
  input: IInvestmentUpdateInput;
};

export type IMutationInvestmentWireUploadArgs = {
  input: IInvestmentWireUploadInput;
};

export type IMutationInvestorEntityIdentityVerificationCreateDocumentUploadArgs =
  {
    input: IInvestorEntityIdentityVerificationCreateDocumentUploadInput;
  };

export type IMutationInvestorEntityIdentityVerificationSubmitVerificationArgs =
  {
    input: IInvestorEntityIdentityVerificationSubmitVerificationInput;
  };

export type IMutationInvestorProfileDocumentCreateArgs = {
  input: IInvestorProfileDocumentCreateInput;
};

export type IMutationInvestorProfileKycPrecheckArgs = {
  input: IInvestorProfileKycPrecheckInput;
};

export type IMutationInvestorProfileProofOfAddressApproveArgs = {
  input: IInvestorProfileProofOfAddressApproveInput;
};

export type IMutationInvestorProfileUpdateArgs = {
  input: IInvestorProfileUpdateInput;
};

export type IMutationInxIntegrationAccountCreateArgs = {
  input: IInxIntegrationAccountCreateInput;
};

export type IMutationIssuerConfirmSecondaryTransactionsArgs = {
  input: ISecondaryIssuerConfirmSecondaryTransactionsInput;
};

export type IMutationIssuerProfileUpdateArgs = {
  input: IIssuerIssuerProfileUpdateInput;
};

export type IMutationIssuersApplyArgs = {
  input: IIssuerApplyInput;
};

export type IMutationJournalArticleCreateArgs = {
  input: IJournalArticleCreateInput;
};

export type IMutationJournalArticleDeleteArgs = {
  input: IJournalArticleDeleteInput;
};

export type IMutationJournalArticlePublishArgs = {
  input: IJournalArticlePublishInput;
};

export type IMutationJournalArticleUnpublishArgs = {
  input: IJournalArticleUnpublishInput;
};

export type IMutationJournalArticleUpdateArgs = {
  input: IJournalArticleUpdateInput;
};

export type IMutationLandingPageUpdateArgs = {
  input: ILandingPageUpdateInput;
};

export type IMutationLegalInformationUpdateArgs = {
  input: IUserLegalInformationUpdateInput;
};

export type IMutationLikeObjectArgs = {
  input: ILikeObjectInput;
};

export type IMutationMessageCreateArgs = {
  input: IMessageCreateInput;
};

export type IMutationMessageMarkAsSeenArgs = {
  input: IMessageMarkAsSeenInput;
};

export type IMutationMessageUpdateArgs = {
  input: IMessageUpdateInput;
};

export type IMutationModifyFinancialsDetailsArgs = {
  input: IOfferingFinancialModifyDetailsInput;
};

export type IMutationNotificationsMarkAsSeenArgs = {
  input: INotificationMarkAsSeenInput;
};

export type IMutationNotificationsMarkHeaderViewedArgs = {
  input: INotificationMarkHeaderViewedInput;
};

export type IMutationOauthArgs = {
  input: IOAuthInput;
};

export type IMutationOfferingCryptoSettingsUpdateArgs = {
  input: IOfferingCryptoSettingsUpdateInput;
};

export type IMutationOfferingUnlockDealArgs = {
  input: IOfferingUnlockDealInput;
};

export type IMutationOfferingUpdateCommentCreateArgs = {
  input: ICommentOfferingUpdateCommentCreateInput;
};

export type IMutationOfferingsUpdateMarkAsSeenArgs = {
  input: IOfferingUpdateMarkAsSeenInput;
};

export type IMutationOnboardingAirdropActionPointCreateArgs = {
  input: IAirdropActionPointInput;
};

export type IMutationOnboardingAirdropActionPointDeleteArgs = {
  input: IOnboardingAirdropActionPointDeleteInput;
};

export type IMutationOnboardingAirdropActionPointUpdateArgs = {
  input: IAirdropActionPointInput;
};

export type IMutationOnboardingAirdropActionPointsPublishArgs = {
  input: IOnboardingAirdropActionPointPublishInput;
};

export type IMutationOnboardingCompanyProfileUpdateArgs = {
  input: IOnboardingCompanyProfileUpdateInput;
};

export type IMutationOnboardingDocumentEdgarAccessCreateArgs = {
  input: IOnboardingDocumentEdgarAccessCreateInput;
};

export type IMutationOnboardingDocumentEdgarAccessUpdateArgs = {
  input: IOnboardingDocumentEdgarAccessUpdateInput;
};

export type IMutationOnboardingEdgarAccessSelectCikFlowArgs = {
  input: IOnboardingEdgarAccessSelectCikFlowInput;
};

export type IMutationOnboardingMemberCreateArgs = {
  input: IOnboardingMemberCreateInput;
};

export type IMutationOnboardingMemberDeleteArgs = {
  input: IOnboardingMemberDeleteInput;
};

export type IMutationOnboardingMemberUpdateArgs = {
  input: IOnboardingMemberUpdateInput;
};

export type IMutationOnboardingOverviewUpdateTargetLaunchDateArgs = {
  input: IOnboardingOverviewTargetLaunchDateUpdateInput;
};

export type IMutationOnboardingPublishRisksArgs = {
  input: IOnboardingRiskPublishRisksInput;
};

export type IMutationOnboardingTrackingPixelUpdateArgs = {
  input: IOnboardingTrackingPixelUpdateInput;
};

export type IMutationOnboardingTtwRequestArgs = {
  input: IOnboardingReservationCampaignRequestTtwInput;
};

export type IMutationOnboardingUpdateFormCRisksArgs = {
  input: IOnboardingRiskUpdateFormCRisksInput;
};

export type IMutationOnboardingUpdateRisksArgs = {
  input: IOnboardingRiskUpdateRisksInput;
};

export type IMutationOutwardNotificationsCategoryUpdateArgs = {
  input: IOutwardNotificationsCategoryUpdateInput;
};

export type IMutationPaymentMethodCreateAchArgs = {
  input: IPaymentMethodCreateAchInput;
};

export type IMutationPaymentMethodCreateAltoIraArgs = {
  input: IPaymentMethodCreateAltoIraInput;
};

export type IMutationPaymentMethodCreateBitpayArgs = {
  input: IPaymentMethodCreateBitpayInput;
};

export type IMutationPaymentMethodCreateCardArgs = {
  input: IPaymentMethodCreateCardInput;
};

export type IMutationPaymentMethodCreateWireArgs = {
  input: IPaymentMethodCreateWireInput;
};

export type IMutationPaymentMethodDeleteArgs = {
  input: IPaymentMethodDeleteInput;
};

export type IMutationPlaidIntegrationCreateArgs = {
  input: IPlaidIntegrationCreateInput;
};

export type IMutationPostCommentCreateArgs = {
  input: ICommentCreateInput;
};

export type IMutationPressReleasesCreateArgs = {
  input: IPressReleasesCreateInput;
};

export type IMutationPressReleasesDeleteArgs = {
  input: IPressReleasesDeleteInput;
};

export type IMutationPressReleasesPublishArgs = {
  input: IPressReleasesPublishInput;
};

export type IMutationPressReleasesUnpublishArgs = {
  input: IPressReleasesUnpublishInput;
};

export type IMutationPressReleasesUpdateArgs = {
  input: IPressReleasesUpdateInput;
};

export type IMutationPrivilegeCreateArgs = {
  input: IPrivilegeCreateInput;
};

export type IMutationPromotionCampaignValidateCodeArgs = {
  input: IPromotionCampaignValidateCodeInput;
};

export type IMutationPromotionCampaignValidateNoteCodeArgs = {
  input: IPromotionCampaignValidateNoteCodeInput;
};

export type IMutationProvideCryptoTransactionHashArgs = {
  input: ISecondaryTransactionProvideCryptoHashInput;
};

export type IMutationRegulationBestInterestQuizCreateArgs = {
  input: IRegulationBestInterestQuizCreateInput;
};

export type IMutationRegulationBestInterestQuizDeleteArgs = {
  input: IRegulationBestInterestQuizDeleteInput;
};

export type IMutationRegulationBestInterestQuizUpdateArgs = {
  input: IRegulationBestInterestQuizUpdateInput;
};

export type IMutationRepublicCapitalPopulateUserDataArgs = {
  input: IRepublicCapitalPopulateUserDataInput;
};

export type IMutationRepublicCapitalPortfolioCsvArgs = {
  input: IRepublicCapitalPortfolioCsvInput;
};

export type IMutationRepublicCapitalRefreshUserDataArgs = {
  input: IRepublicCapitalRefreshUserDataInput;
};

export type IMutationRepublicCapitalResetPasswordEmailArgs = {
  input: IRepublicCapitalResetPasswordEmailInput;
};

export type IMutationRequestAccessArgs = {
  input: ISecondaryRequestAccessInput;
};

export type IMutationRequestCpaArgs = {
  input: IOfferingFinancialRequestCpaInput;
};

export type IMutationSecondariesReconfirmInterestToSellArgs = {
  input: ISecondaryReconfirmInterestToSellInput;
};

export type IMutationSecondariesStartBuyerPaymentArgs = {
  input: ISecondaryStartBuyerPaymentInput;
};

export type IMutationSellerConfirmSecondaryTransactionArgs = {
  input: ISecondarySellerConfirmSecondaryTransactionInput;
};

export type IMutationSessionCreateArgs = {
  input: IMonolithAuthSessionCreateInput;
};

export type IMutationSignBeneficialOwnerCertificationArgs = {
  input: ICustodialSignBeneficialOwnerCertificationInput;
};

export type IMutationSignInArgs = {
  input: IAuthSignInInput;
};

export type IMutationSignInAsUserArgs = {
  input: IAuthSignInAsUserInput;
};

export type IMutationSignNomineeAgreementArgs = {
  input: ICustodialSignNomineeAgreementInput;
};

export type IMutationSignTransferAgreementArgs = {
  input: ISecondaryTransactionGenerateSecondaryTransferAgreementUrlInput;
};

export type IMutationSignUpArgs = {
  input: IAuthSignUpInput;
};

export type IMutationSliderMediaItemCreateArgs = {
  input: IOfferingSliderMediaItemCreateInput;
};

export type IMutationSliderMediaItemDeleteArgs = {
  input: IOfferingSliderMediaItemDeleteInput;
};

export type IMutationSliderMediaItemUpdateArgs = {
  input: IOfferingSliderMediaItemUpdateInput;
};

export type IMutationSliderMediaItemsUpdateOrderArgs = {
  input: IOfferingSliderMediaItemsUpdateOrderInput;
};

export type IMutationSubscribeUserViaEmailArgs = {
  input: ISubscribeUserViaEmailInput;
};

export type IMutationTagsCreateArgs = {
  input: ITagsCreateInput;
};

export type IMutationTagsDeleteArgs = {
  input: ITagsDeleteInput;
};

export type IMutationTagsPublishArgs = {
  input: ITagsPublishInput;
};

export type IMutationTagsUnpublishArgs = {
  input: ITagsUnpublishInput;
};

export type IMutationTagsUpdateArgs = {
  input: ITagsUpdateInput;
};

export type IMutationTeamMemberCreateArgs = {
  input: IUserRepublicTeamMemberCreateInput;
};

export type IMutationTeamMemberDeleteArgs = {
  input: IUserRepublicTeamMemberDeleteInput;
};

export type IMutationTeamMemberUpdateArgs = {
  input: IUserRepublicTeamMemberUpdateInput;
};

export type IMutationTeaserPageCreateArgs = {
  input: ITeaserPageCreateInput;
};

export type IMutationTeaserPageDeleteArgs = {
  input: ITeaserPageDeleteInput;
};

export type IMutationTeaserPagePublishArgs = {
  input: ITeaserPagePublishInput;
};

export type IMutationTeaserPageSendCreateLinkArgs = {
  input: ITeaserPageSendCreateLinkInput;
};

export type IMutationTeaserPageUnpublishArgs = {
  input: ITeaserPageUnpublishInput;
};

export type IMutationTeaserPageUpdateArgs = {
  input: ITeaserPageUpdateInput;
};

export type IMutationTeaserPagesFollowerCreateArgs = {
  input: ITeaserPageFollowerCreateInput;
};

export type IMutationTeaserPagesFollowerCsvArgs = {
  input: ITeaserPageFollowersCsvInput;
};

export type IMutationTeaserPagesFollowerDeleteArgs = {
  input: ITeaserPageFollowerDeleteInput;
};

export type IMutationTeaserPagesFollowerUpdateArgs = {
  input: ITeaserPageFollowerUpdateInput;
};

export type IMutationUnfollowArgs = {
  input: IFollowDeleteInput;
};

export type IMutationUnlikeObjectArgs = {
  input: IUnlikeObjectInput;
};

export type IMutationUpdateInvestmentMessageArgs = {
  input: IOnboardingPostInvestmentMessageUpdateInput;
};

export type IMutationUpdateReservationMessageArgs = {
  input: IOnboardingReservationCampaignUpdatePostMessageInput;
};

export type IMutationUserBasicInfoUpdateArgs = {
  input: IUserBasicInfoUpdateInput;
};

export type IMutationUserConfirmEmailArgs = {
  input: IUserConfirmEmailInput;
};

export type IMutationUserEmailConfirmationResendArgs = {
  input: IUserEmailConfirmationResendInput;
};

export type IMutationUserEmailUpdateArgs = {
  input: IUserEmailUpdateInput;
};

export type IMutationUserMfaDisableArgs = {
  input: IUserMfaDisableInput;
};

export type IMutationUserMfaEnableArgs = {
  input: IUserMfaEnableInput;
};

export type IMutationUserNotificationPreferencesUpdateArgs = {
  input: IUserNotificationPreferencesUpdateInput;
};

export type IMutationUserPasswordResetArgs = {
  input: IUserPasswordResetInput;
};

export type IMutationUserPasswordSetArgs = {
  input: IUserPasswordSetInput;
};

export type IMutationUserPasswordTokenCreateArgs = {
  input: IUserPasswordTokenCreateInput;
};

export type IMutationUserPasswordUpdateArgs = {
  input: IUserPasswordUpdateInput;
};

export type IMutationUserPrivacySettingsUpdateArgs = {
  input: IUserPrivacySettingsUpdateInput;
};

export type IMutationUserProfileUpdateArgs = {
  input: IUserProfileUpdateInput;
};

export type IMutationUserSendDeleteRequestArgs = {
  input: IUserSendDeleteRequestInput;
};

export type IMutationUserSetInactiveArgs = {
  input: IUserSetInactiveInput;
};

export type IMutationUserUpdateArgs = {
  input: IUserUpdateInput;
};

export type IMutationZenLedgerSendPortfolioArgs = {
  input: IZenLedgerIntegrationTaxPortfolioInput;
};

export type IMutations = {
  accreditationVerificationsCreate?: Maybe<IAccreditationVerificationCreatePayload>;
  /** [DEPRECATED] Was supposed to be InvestorEntityIdentityVerficationCreateDocumentUpload */
  accreditationVerificationsCreateDocumentUpload?: Maybe<IAccreditationVerificationCreateDocumentUploadPayload>;
  /** Create AccreditedAccess object of Core domain */
  accreditedAccessCreate?: Maybe<IAccreditedAccessCreatePayload>;
  /** Create Bank account */
  achAccountCreate?: Maybe<IAchAccountCreatePayload>;
  /** Update Bank account */
  achAccountUpdate?: Maybe<IAchAccountUpdatePayload>;
  adminDealTermsUpsert?: Maybe<IAdminOfferingTermTierDealTermUpsertPayload>;
  adminInvestorProfileApproveKycAml?: Maybe<IAdminInvestorProfileApproveKycAmlPayload>;
  adminTermTiersUpsert?: Maybe<IAdminOfferingTermTierUpsertPayload>;
  /** Add Capability to User */
  adminUserCapabilityAdd?: Maybe<IAdminUserCapabilityAddPayload>;
  /** Edit Capability of User */
  adminUserCapabilityEdit?: Maybe<IAdminUserCapabilityEditPayload>;
  /** Remove Capability of a User */
  adminUserCapabilityRemove?: Maybe<IAdminUserCapabilityRemovePayload>;
  /** Process OTP codes for 2FA */
  authenticateWithTwoFactor?: Maybe<IAuthAuthenticateWithTwoFactorPayload>;
  /**
   * Dismiss Banner by type for user of Core domain
   * @deprecated Clients manage this logic on their side, use dismissedBanners argument of banner query
   */
  bannerDismiss?: Maybe<IBannerDismissPayload>;
  beneficialOwnersDraftInvite?: Maybe<IBeneficialOwnersDraftInvitePayload>;
  beneficialOwnersRemove?: Maybe<IBeneficialOwnersRemoveOwnerPayload>;
  beneficialOwnersResendInvite?: Maybe<IBeneficialOwnersResendInvitePayload>;
  /** Cancel Order object */
  cancelOrder?: Maybe<ISecondaryCancelOrderPayload>;
  /** Create Comment object of Comments domain */
  commentCreate?: Maybe<ICommentCreatePayload>;
  /** Delete Comment object of Comments domain by slug */
  commentDelete?: Maybe<ICommentDeletePayload>;
  /** Mark Comment As Helpful of Comments domain if owner is Offering */
  commentMarkAsHelpful?: Maybe<ICommentMarkAsHelpfulPayload>;
  /** Mark Comment As Not Helpful of Comments domain if owner is Offering */
  commentMarkAsNotHelpful?: Maybe<ICommentMarkAsNotHelpfulPayload>;
  /** Mark Comment object As Not Spam of Comments domain by id */
  commentMarkNotSpam?: Maybe<ICommentMarkNotSpamPayload>;
  /** Mark Comment (children) object As Spam of Comments domain by id */
  commentMarkSpam?: Maybe<ICommentMarkSpamPayload>;
  /** Reply to Comment object of Comments domain */
  commentReply?: Maybe<ICommentReplyPayload>;
  /** Create common term object for Common Term domain */
  commonTermCreate?: Maybe<ICommonTermCreatePayload>;
  /** Delete common term object for Common Term domain */
  commonTermDelete?: Maybe<ICommonTermDeletePayload>;
  /** Update common term object for Common Term domain */
  commonTermUpdate?: Maybe<ICommonTermUpdatePayload>;
  /** Send an email to specified internal emails from Crypto landing Contact Form */
  contactFormAssetManagementEmailSend?: Maybe<IContactFormAssetManagementEmailSendPayload>;
  /** Send an email to specified internal emails from Crypto landing Contact Form */
  contactFormEmailSend?: Maybe<IContactFormEmailSendPayload>;
  /** Create Covered Core(Person or Entity) object */
  coveredCoreCreate?: Maybe<IRoleCoveredCoreCreatePayload>;
  /** Delete Covered Core(Person or Entity) object */
  coveredCoreDelete?: Maybe<IRoleCoveredCoreDeletePayload>;
  /** Create Document object for Covered Core(Person or Entity) */
  coveredCoreDocumentCreate?: Maybe<IRoleCoveredCoreDocumentCreatePayload>;
  /** Delete Document object for Covered Core(Person or Entity) */
  coveredCoreDocumentDelete?: Maybe<IRoleCoveredCoreDocumentDeletePayload>;
  /** Update Covered Core(Person or Entity) object */
  coveredCoreUpdate?: Maybe<IRoleCoveredCoreUpdatePayload>;
  /** Create matching Order object with BUY_ORDER type for an existing Sell Order */
  createMatchingBuyOrder?: Maybe<ISecondarySellOrderCreateBuyOrderPayload>;
  /** Create Order object with SELL_ORDER type */
  createSellOrder?: Maybe<ISecondaryCreateSellOrderPayload>;
  /** Create Stripe account for an offering */
  createStripeAccount?: Maybe<IOnboardingCardPaymentCreateStripeAccountPayload>;
  /** Create Crypto BlockchainRuleSet object for Core domain */
  cryptoBlockchainRuleSetCreate?: Maybe<ICryptoBlockchainRuleSetCreatePayload>;
  /** Delete Crypto BlockchainRuleSet object for Core domain */
  cryptoBlockchainRuleSetDelete?: Maybe<ICryptoBlockchainRuleSetDeletePayload>;
  /** Update  Crypto BlockchainRuleSet object for Core domain */
  cryptoBlockchainRuleSetUpdate?: Maybe<ICryptoBlockchainRuleSetUpdatePayload>;
  /** Create Crypto Distribution object for Core domain */
  cryptoDistributionsCreate?: Maybe<ICryptoDistributionCreatePayload>;
  /** Update Joinder Agreement for Crypto Distribution in Core domain */
  cryptoDistributionsJoinderAgreementUpdate?: Maybe<ICryptoDistributionUpdateJoinderAgreementPayload>;
  /** Allow a user to sign Joinder Agreement for a provided Crypto Distribution */
  cryptoDistributionsSignJoinderAgreement?: Maybe<ICryptoDistributionSignJoinderAgreementPayload>;
  /** Create Crypto Investment Option object for Core domain */
  cryptoInvestmentOptionCreate?: Maybe<ICryptoInvestmentOptionCreatePayload>;
  /** Delete CryptoInvestmentOption object for Core domain */
  cryptoInvestmentOptionDelete?: Maybe<ICryptoInvestmentOptionDeletePayload>;
  /** Update Crypto Investment Option object for Core domain */
  cryptoInvestmentOptionUpdate?: Maybe<ICryptoInvestmentOptionUpdatePayload>;
  /** Create Crypto Investment Token objects of Core domain */
  cryptoInvestmentTokenCreate?: Maybe<ICryptoCreateInvestmentTokenPayload>;
  /** Create Crypto Multisig Account object of Core domain */
  cryptoMultisigAccountCreate?: Maybe<ICryptoMultisigAccountCreatePayload>;
  /** Delete Crypto Multisig Account object of Core domain */
  cryptoMultisigAccountDelete?: Maybe<ICryptoMultisigAccountDeletePayload>;
  /** Create Crypto Multisig Account Signer object of Core domain */
  cryptoMultisigAccountSignerCreate?: Maybe<ICryptoMultisigAccountSignerCreatePayload>;
  /** Delete Crypto Multisig Account Signer object of Core domain */
  cryptoMultisigAccountSignerDelete?: Maybe<ICryptoMultisigAccountSignerDeletePayload>;
  /** Update Crypto Multisig Account Signer object of Core domain */
  cryptoMultisigAccountSignerUpdate?: Maybe<ICryptoMultisigAccountSignerUpdatePayload>;
  /** Update Crypto Multisig Account object of Core domain */
  cryptoMultisigAccountUpdate?: Maybe<ICryptoMultisigAccountUpdatePayload>;
  /** Sets a user's public key on the user model */
  cryptoSetPublicKey?: Maybe<ICryptoSetPublicKeyPayload>;
  /** Create Crypto Token Address object for Core domain */
  cryptoTokenAddressCreate?: Maybe<ICryptoTokenAddressCreatePayload>;
  /** Delete Crypto Token Address object for Core domain */
  cryptoTokenAddressDelete?: Maybe<ICryptoTokenAddressDeletePayload>;
  /** Update Crypto Token Address object for Core domain */
  cryptoTokenAddressUpdate?: Maybe<ICryptoTokenAddressUpdatePayload>;
  /** Create Crypto Token object for Core domain */
  cryptoTokensCreate?: Maybe<ICryptoTokenCreatePayload>;
  /** Delete CryptoTokens object for Core domain */
  cryptoTokensDelete?: Maybe<ICryptoTokenDeletePayload>;
  /** Update Crypto Token object for Core domain */
  cryptoTokensUpdate?: Maybe<ICryptoTokenUpdatePayload>;
  /** Create Crypto Transaction object for Core domain */
  cryptoTransactionCreate?: Maybe<ICryptoTransactionCreatePayload>;
  /** Update Crypto Transaction Signature object for Core domain */
  cryptoTransactionSignatureUpdate?: Maybe<ICryptoTransactionSignatureUpdatePayload>;
  /** Update Crypto Transaction object for Core domain */
  cryptoTransactionUpdate?: Maybe<ICryptoTransactionUpdatePayload>;
  /** Update crypto wallet object for Core domain */
  cryptoValidatePassword?: Maybe<ICryptoWalletValidatePasswordPayload>;
  /** Clear wallet from device. Doesn't delete record. Just updates clear_from_device to true. */
  cryptoWalletDeviceClear?: Maybe<ICryptoWalletDeviceClearPayload>;
  /** Create Crypto Wallet Device object */
  cryptoWalletDeviceCreate?: Maybe<ICryptoWalletDeviceCreatePayload>;
  /** Delete Crypto Wallet Device object */
  cryptoWalletDeviceDelete?: Maybe<ICryptoWalletDeviceDeletePayload>;
  /** Create or Update Crypto Wallet Device object */
  cryptoWalletDeviceUpdate?: Maybe<ICryptoWalletDeviceUpdatePayload>;
  /** Create Crypto Wallet Disclaimer object for Core domain */
  cryptoWalletDisclaimersCreate?: Maybe<ICryptoWalletDisclaimerCreatePayload>;
  /** Delete Crypto Wallet Disclaimers object for Core domain */
  cryptoWalletDisclaimersDelete?: Maybe<ICryptoWalletDisclaimerDeletePayload>;
  /** Update Crypto Wallet Disclaimer object for Core domain */
  cryptoWalletDisclaimersUpdate?: Maybe<ICryptoWalletDisclaimerUpdatePayload>;
  /** Create crypto wallet token object for Core domain */
  cryptoWalletTokensCreate?: Maybe<ICryptoWalletTokenCreatePayload>;
  /** Delete crypto wallet token object for Core domain */
  cryptoWalletTokensDelete?: Maybe<ICryptoWalletTokenDeletePayload>;
  /** Update crypto wallet token object for Core domain */
  cryptoWalletTokensUpdate?: Maybe<ICryptoWalletTokenUpdatePayload>;
  /** Create crypto wallet object for Core domain */
  cryptoWalletsCreate?: Maybe<ICryptoWalletCreatePayload>;
  /** Delete crypto wallet object for Core domain */
  cryptoWalletsDelete?: Maybe<ICryptoWalletDeletePayload>;
  /** Update crypto wallet object for Core domain */
  cryptoWalletsUpdate?: Maybe<ICryptoWalletUpdatePayload>;
  /** Accept the Disclosure for a Brassica Custodial Account */
  custodialAccountApplicationAcceptDisclosures?: Maybe<ICustodialAccountApplicationAcceptDisclosuresPayload>;
  /** Sign the Custodial Agreement Terms for a Brassica Custodial Account */
  custodialAccountApplicationSignAgreements?: Maybe<ICustodialAccountApplicationSignAgreementsPayload>;
  /** Create custom term object for Offering. */
  customTermCreate?: Maybe<IOfferingsCustomTermCreatePayload>;
  /** Delete custom term object for Offering. */
  customTermDelete?: Maybe<IOfferingsCustomTermDeletePayload>;
  /** Update custom term object for Offering. */
  customTermUpdate?: Maybe<IOfferingsCustomTermUpdatePayload>;
  /** Update new EDGAR access codes */
  edgarAccessUpdate?: Maybe<IIssuerIssuerProfileEdgarAccessUpdatePayload>;
  /** Edit Order object */
  editOrder?: Maybe<ISecondaryEditOrderPayload>;
  /** Create entity */
  entityCreate?: Maybe<IInvestorProfileEntityCreatePayload>;
  /** Delete entity */
  entityDelete?: Maybe<IInvestorProfileEntityDeletePayload>;
  /** Update entity */
  entityUpdate?: Maybe<IInvestorProfileEntityUpdatePayload>;
  /** Exchange an old-style token for a new r-auth one */
  exchangeToken?: Maybe<IAuthExchangeTokenPayload>;
  /** Upload user's documents to FinTron blob service */
  finTronUploadDocuments?: Maybe<IFinTronUploadDocumentsPayload>;
  /** Create Document object for financial details */
  financialDocumentCreate?: Maybe<IOfferingFinancialDocumentCreatePayload>;
  /** Delete Document object for financial document */
  financialDocumentDelete?: Maybe<IOfferingFinancialDocumentDeletePayload>;
  /** Allow to enable/disable or view/unview flags or popups for User */
  flipperFeatureUpdate?: Maybe<IFlipperFeatureUpdatePayload>;
  /**
   * Allow a user to follow a record. It is recommended to include the type of
   * object being followedIf no explicit record type is included, slug collisions are possible.
   */
  follow?: Maybe<IFollowCreatePayload>;
  /** Issuer requested attroney? */
  formCRequestAttorney?: Maybe<IOnboardingFormCRequestAttorneyPayload>;
  /** Get the template of TA or signed version if exists */
  generateSecondaryTransferAgreementUrl?: Maybe<ISecondaryTransactionGenerateSecondaryTransferAgreementUrlPayload>;
  groupDealPostCreate?: Maybe<IGroupDealPostCreatePayload>;
  groupLeave?: Maybe<IGroupLeavePayload>;
  groupPostCreate?: Maybe<IInvestorGroupCreatePayload>;
  groupUpdate?: Maybe<IGroupUpdatePayload>;
  /**
   * Fill Identity for current user of Core domain
   * @deprecated Not used anymore
   */
  identityCreate?: Maybe<IIdentityCreatePayload>;
  identityDocumentsCreate?: Maybe<IIdentityDocumentCreatePayload>;
  /** Initializes Stripe Link session to buy crypto */
  initializeStripeOnrampSession?: Maybe<IStripeIntegrationInitializeOnrampSessionPayload>;
  /** Cancel Investment object of Core domain */
  investmentCancel?: Maybe<IInvestmentCancelPayload>;
  investmentCreate?: Maybe<IInvestmentCreatePayload>;
  /** Create investment reason */
  investmentReasonCreate?: Maybe<IOfferingInvestmentReasonCreatePayload>;
  investmentReasonDelete?: Maybe<IInvestmentReasonDeletePayload>;
  /** Update investment reason */
  investmentReasonUpdate?: Maybe<IOfferingInvestmentReasonUpdatePayload>;
  /** Reconfirm Investment object of Core domain */
  investmentReconfirm?: Maybe<IInvestmentReconfirmPayload>;
  investmentReinvest?: Maybe<IInvestmentReinvestPayload>;
  /** Confirm Investment reservation object of Core domain */
  investmentReservationConfirm?: Maybe<IInvestmentReservationConfirmPayload>;
  /** Update Investment object of Core domain */
  investmentUpdate?: Maybe<IInvestmentUpdatePayload>;
  investmentWireUpload?: Maybe<IInvestmentWireUploadPayload>;
  investorEntityIdentityVerificationCreateDocumentUpload?: Maybe<IInvestorEntityIdentityVerificationCreateDocumentUploadPayload>;
  investorEntityIdentityVerificationSubmitVerification?: Maybe<IInvestorEntityIdentityVerificationSubmitVerificationPayload>;
  /** Create secure document */
  investorProfileDocumentCreate?: Maybe<IInvestorProfileDocumentCreatePayload>;
  /** Kyc precheck for offering */
  investorProfileKycPrecheck?: Maybe<IInvestorProfileKycPrecheckPayload>;
  /** Proof of address for current users investor profile */
  investorProfileProofOfAddressApprove?: Maybe<IInvestorProfileProofOfAddressApprovePayload>;
  /** Update InvestorProfile object of Core domain */
  investorProfileUpdate?: Maybe<IInvestorProfileUpdatePayload>;
  /** [DEPRECATED] Not implemented, do not use */
  issuerConfirmSecondaryTransactions?: Maybe<ISecondaryIssuerConfirmSecondaryTransactionsPayload>;
  /** Update Issuer Profile object */
  issuerProfileUpdate?: Maybe<IIssuerIssuerProfileUpdatePayload>;
  /** Create IssuerApplication object of Core domain */
  issuersApply?: Maybe<IIssuerApplyPayload>;
  /** Update LegalInformation object of Core domain */
  legalInformationUpdate?: Maybe<IUserLegalInformationUpdatePayload>;
  /** Allow user to like an object. */
  likeObject?: Maybe<ILikeObjectPayload>;
  /** Create a Message object of Core domain. */
  messageCreate?: Maybe<IMessageCreatePayload>;
  /** Mark users incoming messages from sender_user_slug as seen */
  messageMarkAsSeen?: Maybe<IMessageMarkAsSeenPayload>;
  /** Update Message object of Core domain by id */
  messageUpdate?: Maybe<IMessageUpdatePayload>;
  /** Create/Update Offerings::Financials::Detail object */
  modifyFinancialsDetails?: Maybe<IOfferingFinancialModifyDetailsPayload>;
  /** Mark users notifications as seen, returns maximum of 10 notifications by default */
  notificationsMarkAsSeen?: Maybe<INotificationMarkAsSeenPayload>;
  /** Notifications in header viewed */
  notificationsMarkHeaderViewed?: Maybe<INotificationMarkHeaderViewedPayload>;
  /** Sign in through social network */
  oauth?: Maybe<IOAuthPayload>;
  /** Offering Crypto Settings Update for Core domain */
  offeringCryptoSettingsUpdate?: Maybe<IOfferingCryptoSettingsUpdatePayload>;
  /** Create NdaSign object for User */
  offeringUnlockDeal?: Maybe<IOfferingUnlockDealPayload>;
  /**
   * Create comment object for offering update of Comments domain
   * @deprecated Use commentCreate instead
   */
  offeringUpdateCommentCreate?: Maybe<ICommentOfferingUpdateCommentCreatePayload>;
  offeringsUpdateMarkAsSeen?: Maybe<IOfferingUpdateMarkAsSeenPayload>;
  /** Create airdrop action points */
  onboardingAirdropActionPointCreate?: Maybe<IOnboardingAirdropActionPointCreatePayload>;
  /** Delete airdrop action point */
  onboardingAirdropActionPointDelete?: Maybe<IOnboardingAirdropActionPointDeletePayload>;
  /** Update airdrop action point */
  onboardingAirdropActionPointUpdate?: Maybe<IOnboardingAirdropActionPointUpdatePayload>;
  /** Publish airdrop action points */
  onboardingAirdropActionPointsPublish?: Maybe<IOnboardingAirdropActionPointPublishPayload>;
  /** Update onboarding company profile */
  onboardingCompanyProfileUpdate?: Maybe<IOnboardingCompanyProfileUpdatePayload>;
  /** Create new onboarding EDGAR access document */
  onboardingDocumentEdgarAccessCreate?: Maybe<IOnboardingDocumentEdgarAccessCreatePayload>;
  /** Update onboarding EDGAR access document */
  onboardingDocumentEdgarAccessUpdate?: Maybe<IOnboardingDocumentEdgarAccessUpdatePayload>;
  /** Select cik flow */
  onboardingEdgarAccessSelectCikFlow?: Maybe<IOnboardingEdgarAccessSelectCikFlowPayload>;
  /** Create onboarding member object */
  onboardingMemberCreate?: Maybe<IOnboardingMemberCreatePayload>;
  /** Delete onboarding member object by id */
  onboardingMemberDelete?: Maybe<IOnboardingMemberDeletePayload>;
  /** Update onboarding member object by id */
  onboardingMemberUpdate?: Maybe<IOnboardingMemberUpdatePayload>;
  /** Update onboarding Target Launch Date */
  onboardingOverviewUpdateTargetLaunchDate?: Maybe<IOnboardingOverviewTargetLaunchDateUpdatePayload>;
  /** Publish Offering risks */
  onboardingPublishRisks?: Maybe<IOnboardingRiskPublishRisksPayload>;
  /** Update onboarding Pixel and offering analytics related fields */
  onboardingTrackingPixelUpdate?: Maybe<IOnboardingTrackingPixelUpdatePayload>;
  /** Request TTW enabling for an offering */
  onboardingTtwRequest?: Maybe<IOnboardingReservationCampaignRequestTtwPayload>;
  /** Fill in the risks from Form C (removes existing risks) */
  onboardingUpdateFormCRisks?: Maybe<IOnboardingRiskUpdateFormCRisksPayload>;
  /** Update Offering risks (create/update/delete) */
  onboardingUpdateRisks?: Maybe<IOnboardingRiskUpdateRisksPayload>;
  /** Update Outward Notifications Category object for Core domain */
  outwardNotificationsCategoryUpdate?: Maybe<IOutwardNotificationsCategoryUpdatePayload>;
  paymentMethodCreateAch?: Maybe<IPaymentMethodCreateAchPayload>;
  paymentMethodCreateAltoIra?: Maybe<IPaymentMethodCreateAltoIraPayload>;
  paymentMethodCreateBitpay?: Maybe<IPaymentMethodCreateBitpayPayload>;
  paymentMethodCreateCard?: Maybe<IPaymentMethodCreateCardPayload>;
  paymentMethodCreateWire?: Maybe<IPaymentMethodCreateWirePayload>;
  /** [DEPRECATED] No longer work with Medici */
  paymentMethodDelete?: Maybe<IPaymentMethodDeletePayload>;
  plaidIntegrationCreate?: Maybe<IPlaidIntegrationCreatePayload>;
  /**
   * Create Comment object of Comments domain
   * @deprecated Use commentCreate instead
   */
  postCommentCreate?: Maybe<ICommentCreatePayload>;
  /** Create Privilege object of Core domain */
  privilegeCreate?: Maybe<IPrivilegeCreatePayload>;
  promotionCampaignValidateCode?: Maybe<IPromotionCampaignValidateCodePayload>;
  promotionCampaignValidateNoteCode?: Maybe<IPromotionCampaignValidateNoteCodePayload>;
  /** Update transaction state by providing crypto hash from byuer */
  provideCryptoTransactionHash?: Maybe<ISecondaryTransactionProvideCryptoHashPayload>;
  /** Create Regulation Best Interest Quiz object for Regulation Best Interest Quizzes domain */
  regulationBestInterestQuizCreate?: Maybe<IRegulationBestInterestQuizCreatePayload>;
  /** Delete Regulation Best Interest Quiz object for Regulation Best Interest Quizzes domain */
  regulationBestInterestQuizDelete?: Maybe<IRegulationBestInterestQuizDeletePayload>;
  /** Update Regulation Best Interest Quiz object for Regulation Best Interest Quizzes domain */
  regulationBestInterestQuizUpdate?: Maybe<IRegulationBestInterestQuizUpdatePayload>;
  /** Invites a new user to Republic Capital */
  republicCapitalPopulateUserData?: Maybe<IRepublicCapitalPopulateUserDataPayload>;
  /** Export CSV file with all opportunities in a user portfolio */
  republicCapitalPortfolioCsv?: Maybe<IRepublicCapitalPortfolioCsvPayload>;
  /** Refreshes data for all users of Republic Capital */
  republicCapitalRefreshUserData?: Maybe<IRepublicCapitalRefreshUserDataPayload>;
  /** Resend a new user invitation email to a Republic Capital user */
  republicCapitalResetPasswordEmail?: Maybe<IRepublicCapitalResetPasswordEmailPayload>;
  /** Indicate interest in secondary market */
  requestAccess?: Maybe<ISecondaryRequestAccessPayload>;
  /** Request CPA to collect financials */
  requestCpa?: Maybe<IOfferingFinancialRequestCpaPayload>;
  /** Reconfirm interest to sell */
  secondariesReconfirmInterestToSell?: Maybe<ISecondaryReconfirmInterestToSellPayload>;
  /** Initiate payment for a buy order. Must be called before proceeding with the payment. */
  secondariesStartBuyerPayment?: Maybe<ISecondaryStartBuyerPaymentPayload>;
  /** [DEPRECATED] Change state of object Secondary Transaction to PENDING_TA_CONFIRMATION */
  sellerConfirmSecondaryTransaction?: Maybe<ISecondarySellerConfirmSecondaryTransactionPayload>;
  /** Session create */
  sessionCreate?: Maybe<IMonolithAuthSessionCreatePayload>;
  /** Sign the beneficial owner certification a Brassica Custodial Account */
  signBeneficialOwnerCertification?: Maybe<ICustodialSignBeneficialOwnerCertificationPayload>;
  /** Sign in */
  signIn?: Maybe<IAuthSignInPayload>;
  /** Admin-only mutation to log in as a user */
  signInAsUser?: Maybe<IAuthSignInAsUserPayload>;
  /** Sign the nominee agreement */
  signNomineeAgreement?: Maybe<ICustodialSignNomineeAgreementPayload>;
  /**
   * Get the template of TA or signed version if exists
   * @deprecated Use generateSecondaryTransferAgreementUrl
   */
  signTransferAgreement?: Maybe<ISecondaryTransactionGenerateSecondaryTransferAgreementUrlPayload>;
  signUp?: Maybe<IAuthSignUpPayload>;
  /** Create slider media item object for Offering. */
  sliderMediaItemCreate?: Maybe<IOfferingSliderMediaItemCreatePayload>;
  /** Delete slider media item object for Offering. */
  sliderMediaItemDelete?: Maybe<IOfferingSliderMediaItemDeletePayload>;
  /** Update slider media item object for Offering. */
  sliderMediaItemUpdate?: Maybe<IOfferingSliderMediaItemUpdatePayload>;
  /** Bulk update slider media item objects order. */
  sliderMediaItemsUpdateOrder?: Maybe<IOfferingSliderMediaItemsUpdateOrderPayload>;
  /** Subscribe User Via Email for Core domain */
  subscribeUserViaEmail?: Maybe<ISubscribeUserViaEmailPayload>;
  /** Create Team Member object of About domain (within the Core domain) */
  teamMemberCreate?: Maybe<IUserRepublicTeamMemberCreatePayload>;
  /** Delete Team Member object of About domain (within the Core domain) */
  teamMemberDelete?: Maybe<IUserRepublicTeamMemberDeletePayload>;
  /** Update Team Member object of About domain (within the Core domain) */
  teamMemberUpdate?: Maybe<IUserRepublicTeamMemberUpdatePayload>;
  /** Create teaser page object */
  teaserPageCreate?: Maybe<ITeaserPageCreatePayload>;
  /** Delete teaser page object */
  teaserPageDelete?: Maybe<ITeaserPageDeletePayload>;
  /** Publish teaser page object */
  teaserPagePublish?: Maybe<ITeaserPagePublishPayload>;
  /** Send email with link to TeaserPage create page */
  teaserPageSendCreateLink?: Maybe<ITeaserPageSendCreateLinkPayload>;
  /** Unpublish teaser page object */
  teaserPageUnpublish?: Maybe<ITeaserPageUnpublishPayload>;
  /** Update teaser page object */
  teaserPageUpdate?: Maybe<ITeaserPageUpdatePayload>;
  /** Create teaser pages follower object */
  teaserPagesFollowerCreate?: Maybe<ITeaserPageFollowerCreatePayload>;
  /** Export CSV file with followers of teaser pages */
  teaserPagesFollowerCsv?: Maybe<ITeaserPageFollowersCsvPayload>;
  /** Delete teaser pages follower object */
  teaserPagesFollowerDelete?: Maybe<ITeaserPageFollowerDeletePayload>;
  /** Update teaser pages follower object */
  teaserPagesFollowerUpdate?: Maybe<ITeaserPageFollowerUpdatePayload>;
  /** Delete Follow object of Core domain */
  unfollow?: Maybe<IFollowDeletePayload>;
  /** Allow user to unlike an object. */
  unlikeObject?: Maybe<IUnlikeObjectPayload>;
  /** Update post-investment message for an offering */
  updateInvestmentMessage?: Maybe<IOnboardingPostInvestmentMessageUpdatePayload>;
  /** Update post-reservation message for an offering */
  updateReservationMessage?: Maybe<IOnboardingReservationCampaignUpdatePostMessagePayload>;
  /** Update basic info for current user */
  userBasicInfoUpdate?: Maybe<IUserBasicInfoUpdatePayload>;
  userConfirmEmail?: Maybe<IUserConfirmEmailPayload>;
  userEmailConfirmationResend?: Maybe<IUserEmailConfirmationResendPayload>;
  /** Updates current users email */
  userEmailUpdate?: Maybe<IUserEmailUpdatePayload>;
  /** Disable 2FA for current user */
  userMfaDisable?: Maybe<IUserMfaDisablePayload>;
  /** Enable 2FA for current user */
  userMfaEnable?: Maybe<IUserMfaEnablePayload>;
  userNotificationPreferencesUpdate?: Maybe<IUserNotificationPreferencesUpdatePayload>;
  /** Resets user password with reset token */
  userPasswordReset?: Maybe<IUserPasswordResetPayload>;
  userPasswordSet?: Maybe<IUserPasswordSetPayload>;
  /** Create a new reset token for user */
  userPasswordTokenCreate?: Maybe<IUserPasswordTokenCreatePayload>;
  /** Update password for current user using Devise functionality */
  userPasswordUpdate?: Maybe<IUserPasswordUpdatePayload>;
  userPrivacySettingsUpdate?: Maybe<IUserPrivacySettingsUpdatePayload>;
  userProfileUpdate?: Maybe<IUserProfileUpdatePayload>;
  /** Send delete request for user */
  userSendDeleteRequest?: Maybe<IUserSendDeleteRequestPayload>;
  userSetInactive?: Maybe<IUserSetInactivePayload>;
  userUpdate?: Maybe<IUserUpdatePayload>;
};

export type IMutationsAccreditationVerificationsCreateArgs = {
  input: IAccreditationVerificationCreateInput;
};

export type IMutationsAccreditationVerificationsCreateDocumentUploadArgs = {
  input: IAccreditationVerificationCreateDocumentUploadInput;
};

export type IMutationsAccreditedAccessCreateArgs = {
  input: IAccreditedAccessCreateInput;
};

export type IMutationsAchAccountCreateArgs = {
  input: IAchAccountCreateInput;
};

export type IMutationsAchAccountUpdateArgs = {
  input: IAchAccountUpdateInput;
};

export type IMutationsAdminDealTermsUpsertArgs = {
  input: IAdminOfferingTermTierDealTermUpsertInput;
};

export type IMutationsAdminInvestorProfileApproveKycAmlArgs = {
  input: IAdminInvestorProfileApproveKycAmlInput;
};

export type IMutationsAdminTermTiersUpsertArgs = {
  input: IAdminOfferingTermTierUpsertInput;
};

export type IMutationsAdminUserCapabilityAddArgs = {
  input: IAdminUserCapabilityAddInput;
};

export type IMutationsAdminUserCapabilityEditArgs = {
  input: IAdminUserCapabilityEditInput;
};

export type IMutationsAdminUserCapabilityRemoveArgs = {
  input: IAdminUserCapabilityRemoveInput;
};

export type IMutationsAuthenticateWithTwoFactorArgs = {
  input: IAuthAuthenticateWithTwoFactorInput;
};

export type IMutationsBannerDismissArgs = {
  input: IBannerDismissInput;
};

export type IMutationsBeneficialOwnersDraftInviteArgs = {
  input: IBeneficialOwnersDraftInviteInput;
};

export type IMutationsBeneficialOwnersRemoveArgs = {
  input: IBeneficialOwnersRemoveOwnerInput;
};

export type IMutationsBeneficialOwnersResendInviteArgs = {
  input: IBeneficialOwnersResendInviteInput;
};

export type IMutationsCancelOrderArgs = {
  input: ISecondaryCancelOrderInput;
};

export type IMutationsCommentCreateArgs = {
  input: ICommentCreateInput;
};

export type IMutationsCommentDeleteArgs = {
  input: ICommentDeleteInput;
};

export type IMutationsCommentMarkAsHelpfulArgs = {
  input: ICommentMarkAsHelpfulInput;
};

export type IMutationsCommentMarkAsNotHelpfulArgs = {
  input: ICommentMarkAsNotHelpfulInput;
};

export type IMutationsCommentMarkNotSpamArgs = {
  input: ICommentMarkNotSpamInput;
};

export type IMutationsCommentMarkSpamArgs = {
  input: ICommentMarkSpamInput;
};

export type IMutationsCommentReplyArgs = {
  input: ICommentReplyInput;
};

export type IMutationsCommonTermCreateArgs = {
  input: ICommonTermCreateInput;
};

export type IMutationsCommonTermDeleteArgs = {
  input: ICommonTermDeleteInput;
};

export type IMutationsCommonTermUpdateArgs = {
  input: ICommonTermUpdateInput;
};

export type IMutationsContactFormAssetManagementEmailSendArgs = {
  input: IContactFormAssetManagementEmailSendInput;
};

export type IMutationsContactFormEmailSendArgs = {
  input: IContactFormEmailSendInput;
};

export type IMutationsCoveredCoreCreateArgs = {
  input: IRoleCoveredCoreCreateInput;
};

export type IMutationsCoveredCoreDeleteArgs = {
  input: IRoleCoveredCoreDeleteInput;
};

export type IMutationsCoveredCoreDocumentCreateArgs = {
  input: IRoleCoveredCoreDocumentCreateInput;
};

export type IMutationsCoveredCoreDocumentDeleteArgs = {
  input: IRoleCoveredCoreDocumentDeleteInput;
};

export type IMutationsCoveredCoreUpdateArgs = {
  input: IRoleCoveredCoreUpdateInput;
};

export type IMutationsCreateMatchingBuyOrderArgs = {
  input: ISecondarySellOrderCreateBuyOrderInput;
};

export type IMutationsCreateSellOrderArgs = {
  input: ISecondaryCreateSellOrderInput;
};

export type IMutationsCreateStripeAccountArgs = {
  input: IOnboardingCardPaymentCreateStripeAccountInput;
};

export type IMutationsCryptoBlockchainRuleSetCreateArgs = {
  input: ICryptoBlockchainRuleSetCreateInput;
};

export type IMutationsCryptoBlockchainRuleSetDeleteArgs = {
  input: ICryptoBlockchainRuleSetDeleteInput;
};

export type IMutationsCryptoBlockchainRuleSetUpdateArgs = {
  input: ICryptoBlockchainRuleSetUpdateInput;
};

export type IMutationsCryptoDistributionsCreateArgs = {
  input: ICryptoDistributionCreateInput;
};

export type IMutationsCryptoDistributionsJoinderAgreementUpdateArgs = {
  input: ICryptoDistributionUpdateJoinderAgreementInput;
};

export type IMutationsCryptoDistributionsSignJoinderAgreementArgs = {
  input: ICryptoDistributionSignJoinderAgreementInput;
};

export type IMutationsCryptoInvestmentOptionCreateArgs = {
  input: ICryptoInvestmentOptionCreateInput;
};

export type IMutationsCryptoInvestmentOptionDeleteArgs = {
  input: ICryptoInvestmentOptionDeleteInput;
};

export type IMutationsCryptoInvestmentOptionUpdateArgs = {
  input: ICryptoInvestmentOptionUpdateInput;
};

export type IMutationsCryptoInvestmentTokenCreateArgs = {
  input: ICryptoCreateInvestmentTokenInput;
};

export type IMutationsCryptoMultisigAccountCreateArgs = {
  input: ICryptoMultisigAccountCreateInput;
};

export type IMutationsCryptoMultisigAccountDeleteArgs = {
  input: ICryptoMultisigAccountDeleteInput;
};

export type IMutationsCryptoMultisigAccountSignerCreateArgs = {
  input: ICryptoMultisigAccountSignerCreateInput;
};

export type IMutationsCryptoMultisigAccountSignerDeleteArgs = {
  input: ICryptoMultisigAccountSignerDeleteInput;
};

export type IMutationsCryptoMultisigAccountSignerUpdateArgs = {
  input: ICryptoMultisigAccountSignerUpdateInput;
};

export type IMutationsCryptoMultisigAccountUpdateArgs = {
  input: ICryptoMultisigAccountUpdateInput;
};

export type IMutationsCryptoSetPublicKeyArgs = {
  input: ICryptoSetPublicKeyInput;
};

export type IMutationsCryptoTokenAddressCreateArgs = {
  input: ICryptoTokenAddressCreateInput;
};

export type IMutationsCryptoTokenAddressDeleteArgs = {
  input: ICryptoTokenAddressDeleteInput;
};

export type IMutationsCryptoTokenAddressUpdateArgs = {
  input: ICryptoTokenAddressUpdateInput;
};

export type IMutationsCryptoTokensCreateArgs = {
  input: ICryptoTokenCreateInput;
};

export type IMutationsCryptoTokensDeleteArgs = {
  input: ICryptoTokenDeleteInput;
};

export type IMutationsCryptoTokensUpdateArgs = {
  input: ICryptoTokenUpdateInput;
};

export type IMutationsCryptoTransactionCreateArgs = {
  input: ICryptoTransactionCreateInput;
};

export type IMutationsCryptoTransactionSignatureUpdateArgs = {
  input: ICryptoTransactionSignatureUpdateInput;
};

export type IMutationsCryptoTransactionUpdateArgs = {
  input: ICryptoTransactionUpdateInput;
};

export type IMutationsCryptoValidatePasswordArgs = {
  input: ICryptoWalletValidatePasswordInput;
};

export type IMutationsCryptoWalletDeviceClearArgs = {
  input: ICryptoWalletDeviceClearInput;
};

export type IMutationsCryptoWalletDeviceCreateArgs = {
  input: ICryptoWalletDeviceCreateInput;
};

export type IMutationsCryptoWalletDeviceDeleteArgs = {
  input: ICryptoWalletDeviceDeleteInput;
};

export type IMutationsCryptoWalletDeviceUpdateArgs = {
  input: ICryptoWalletDeviceUpdateInput;
};

export type IMutationsCryptoWalletDisclaimersCreateArgs = {
  input: ICryptoWalletDisclaimerCreateInput;
};

export type IMutationsCryptoWalletDisclaimersDeleteArgs = {
  input: ICryptoWalletDisclaimerDeleteInput;
};

export type IMutationsCryptoWalletDisclaimersUpdateArgs = {
  input: ICryptoWalletDisclaimerUpdateInput;
};

export type IMutationsCryptoWalletTokensCreateArgs = {
  input: ICryptoWalletTokenCreateInput;
};

export type IMutationsCryptoWalletTokensDeleteArgs = {
  input: ICryptoWalletTokenDeleteInput;
};

export type IMutationsCryptoWalletTokensUpdateArgs = {
  input: ICryptoWalletTokenUpdateInput;
};

export type IMutationsCryptoWalletsCreateArgs = {
  input: ICryptoWalletCreateInput;
};

export type IMutationsCryptoWalletsDeleteArgs = {
  input: ICryptoWalletDeleteInput;
};

export type IMutationsCryptoWalletsUpdateArgs = {
  input: ICryptoWalletUpdateInput;
};

export type IMutationsCustodialAccountApplicationAcceptDisclosuresArgs = {
  input: ICustodialAccountApplicationAcceptDisclosuresInput;
};

export type IMutationsCustodialAccountApplicationSignAgreementsArgs = {
  input: ICustodialAccountApplicationSignAgreementsInput;
};

export type IMutationsCustomTermCreateArgs = {
  input: IOfferingsCustomTermCreateInput;
};

export type IMutationsCustomTermDeleteArgs = {
  input: IOfferingsCustomTermDeleteInput;
};

export type IMutationsCustomTermUpdateArgs = {
  input: IOfferingsCustomTermUpdateInput;
};

export type IMutationsEdgarAccessUpdateArgs = {
  input: IIssuerIssuerProfileEdgarAccessUpdateInput;
};

export type IMutationsEditOrderArgs = {
  input: ISecondaryEditOrderInput;
};

export type IMutationsEntityCreateArgs = {
  input: IInvestorProfileEntityCreateInput;
};

export type IMutationsEntityDeleteArgs = {
  input: IInvestorProfileEntityDeleteInput;
};

export type IMutationsEntityUpdateArgs = {
  input: IInvestorProfileEntityUpdateInput;
};

export type IMutationsExchangeTokenArgs = {
  input: IAuthExchangeTokenInput;
};

export type IMutationsFinTronUploadDocumentsArgs = {
  input: IFinTronUploadDocumentsInput;
};

export type IMutationsFinancialDocumentCreateArgs = {
  input: IOfferingFinancialDocumentCreateInput;
};

export type IMutationsFinancialDocumentDeleteArgs = {
  input: IOfferingFinancialDocumentDeleteInput;
};

export type IMutationsFlipperFeatureUpdateArgs = {
  input: IFlipperFeatureUpdateInput;
};

export type IMutationsFollowArgs = {
  input: IFollowCreateInput;
};

export type IMutationsFormCRequestAttorneyArgs = {
  input: IOnboardingFormCRequestAttorneyInput;
};

export type IMutationsGenerateSecondaryTransferAgreementUrlArgs = {
  input: ISecondaryTransactionGenerateSecondaryTransferAgreementUrlInput;
};

export type IMutationsGroupDealPostCreateArgs = {
  input: IGroupDealPostCreateInput;
};

export type IMutationsGroupLeaveArgs = {
  input: IGroupLeaveInput;
};

export type IMutationsGroupPostCreateArgs = {
  input: IInvestorGroupCreateInput;
};

export type IMutationsGroupUpdateArgs = {
  input: IGroupUpdateInput;
};

export type IMutationsIdentityCreateArgs = {
  input: IIdentityCreateInput;
};

export type IMutationsIdentityDocumentsCreateArgs = {
  input: IIdentityDocumentCreateInput;
};

export type IMutationsInitializeStripeOnrampSessionArgs = {
  input: IStripeIntegrationInitializeOnrampSessionInput;
};

export type IMutationsInvestmentCancelArgs = {
  input: IInvestmentCancelInput;
};

export type IMutationsInvestmentCreateArgs = {
  input: IInvestmentCreateInput;
};

export type IMutationsInvestmentReasonCreateArgs = {
  input: IOfferingInvestmentReasonInput;
};

export type IMutationsInvestmentReasonDeleteArgs = {
  input: IInvestmentReasonDeleteInput;
};

export type IMutationsInvestmentReasonUpdateArgs = {
  input: IOfferingInvestmentReasonInput;
};

export type IMutationsInvestmentReconfirmArgs = {
  input: IInvestmentReconfirmInput;
};

export type IMutationsInvestmentReinvestArgs = {
  input: IInvestmentReinvestInput;
};

export type IMutationsInvestmentReservationConfirmArgs = {
  input: IInvestmentReservationConfirmInput;
};

export type IMutationsInvestmentUpdateArgs = {
  input: IInvestmentUpdateInput;
};

export type IMutationsInvestmentWireUploadArgs = {
  input: IInvestmentWireUploadInput;
};

export type IMutationsInvestorEntityIdentityVerificationCreateDocumentUploadArgs =
  {
    input: IInvestorEntityIdentityVerificationCreateDocumentUploadInput;
  };

export type IMutationsInvestorEntityIdentityVerificationSubmitVerificationArgs =
  {
    input: IInvestorEntityIdentityVerificationSubmitVerificationInput;
  };

export type IMutationsInvestorProfileDocumentCreateArgs = {
  input: IInvestorProfileDocumentCreateInput;
};

export type IMutationsInvestorProfileKycPrecheckArgs = {
  input: IInvestorProfileKycPrecheckInput;
};

export type IMutationsInvestorProfileProofOfAddressApproveArgs = {
  input: IInvestorProfileProofOfAddressApproveInput;
};

export type IMutationsInvestorProfileUpdateArgs = {
  input: IInvestorProfileUpdateInput;
};

export type IMutationsIssuerConfirmSecondaryTransactionsArgs = {
  input: ISecondaryIssuerConfirmSecondaryTransactionsInput;
};

export type IMutationsIssuerProfileUpdateArgs = {
  input: IIssuerIssuerProfileUpdateInput;
};

export type IMutationsIssuersApplyArgs = {
  input: IIssuerApplyInput;
};

export type IMutationsLegalInformationUpdateArgs = {
  input: IUserLegalInformationUpdateInput;
};

export type IMutationsLikeObjectArgs = {
  input: ILikeObjectInput;
};

export type IMutationsMessageCreateArgs = {
  input: IMessageCreateInput;
};

export type IMutationsMessageMarkAsSeenArgs = {
  input: IMessageMarkAsSeenInput;
};

export type IMutationsMessageUpdateArgs = {
  input: IMessageUpdateInput;
};

export type IMutationsModifyFinancialsDetailsArgs = {
  input: IOfferingFinancialModifyDetailsInput;
};

export type IMutationsNotificationsMarkAsSeenArgs = {
  input: INotificationMarkAsSeenInput;
};

export type IMutationsNotificationsMarkHeaderViewedArgs = {
  input: INotificationMarkHeaderViewedInput;
};

export type IMutationsOauthArgs = {
  input: IOAuthInput;
};

export type IMutationsOfferingCryptoSettingsUpdateArgs = {
  input: IOfferingCryptoSettingsUpdateInput;
};

export type IMutationsOfferingUnlockDealArgs = {
  input: IOfferingUnlockDealInput;
};

export type IMutationsOfferingUpdateCommentCreateArgs = {
  input: ICommentOfferingUpdateCommentCreateInput;
};

export type IMutationsOfferingsUpdateMarkAsSeenArgs = {
  input: IOfferingUpdateMarkAsSeenInput;
};

export type IMutationsOnboardingAirdropActionPointCreateArgs = {
  input: IAirdropActionPointInput;
};

export type IMutationsOnboardingAirdropActionPointDeleteArgs = {
  input: IOnboardingAirdropActionPointDeleteInput;
};

export type IMutationsOnboardingAirdropActionPointUpdateArgs = {
  input: IAirdropActionPointInput;
};

export type IMutationsOnboardingAirdropActionPointsPublishArgs = {
  input: IOnboardingAirdropActionPointPublishInput;
};

export type IMutationsOnboardingCompanyProfileUpdateArgs = {
  input: IOnboardingCompanyProfileUpdateInput;
};

export type IMutationsOnboardingDocumentEdgarAccessCreateArgs = {
  input: IOnboardingDocumentEdgarAccessCreateInput;
};

export type IMutationsOnboardingDocumentEdgarAccessUpdateArgs = {
  input: IOnboardingDocumentEdgarAccessUpdateInput;
};

export type IMutationsOnboardingEdgarAccessSelectCikFlowArgs = {
  input: IOnboardingEdgarAccessSelectCikFlowInput;
};

export type IMutationsOnboardingMemberCreateArgs = {
  input: IOnboardingMemberCreateInput;
};

export type IMutationsOnboardingMemberDeleteArgs = {
  input: IOnboardingMemberDeleteInput;
};

export type IMutationsOnboardingMemberUpdateArgs = {
  input: IOnboardingMemberUpdateInput;
};

export type IMutationsOnboardingOverviewUpdateTargetLaunchDateArgs = {
  input: IOnboardingOverviewTargetLaunchDateUpdateInput;
};

export type IMutationsOnboardingPublishRisksArgs = {
  input: IOnboardingRiskPublishRisksInput;
};

export type IMutationsOnboardingTrackingPixelUpdateArgs = {
  input: IOnboardingTrackingPixelUpdateInput;
};

export type IMutationsOnboardingTtwRequestArgs = {
  input: IOnboardingReservationCampaignRequestTtwInput;
};

export type IMutationsOnboardingUpdateFormCRisksArgs = {
  input: IOnboardingRiskUpdateFormCRisksInput;
};

export type IMutationsOnboardingUpdateRisksArgs = {
  input: IOnboardingRiskUpdateRisksInput;
};

export type IMutationsOutwardNotificationsCategoryUpdateArgs = {
  input: IOutwardNotificationsCategoryUpdateInput;
};

export type IMutationsPaymentMethodCreateAchArgs = {
  input: IPaymentMethodCreateAchInput;
};

export type IMutationsPaymentMethodCreateAltoIraArgs = {
  input: IPaymentMethodCreateAltoIraInput;
};

export type IMutationsPaymentMethodCreateBitpayArgs = {
  input: IPaymentMethodCreateBitpayInput;
};

export type IMutationsPaymentMethodCreateCardArgs = {
  input: IPaymentMethodCreateCardInput;
};

export type IMutationsPaymentMethodCreateWireArgs = {
  input: IPaymentMethodCreateWireInput;
};

export type IMutationsPaymentMethodDeleteArgs = {
  input: IPaymentMethodDeleteInput;
};

export type IMutationsPlaidIntegrationCreateArgs = {
  input: IPlaidIntegrationCreateInput;
};

export type IMutationsPostCommentCreateArgs = {
  input: ICommentCreateInput;
};

export type IMutationsPrivilegeCreateArgs = {
  input: IPrivilegeCreateInput;
};

export type IMutationsPromotionCampaignValidateCodeArgs = {
  input: IPromotionCampaignValidateCodeInput;
};

export type IMutationsPromotionCampaignValidateNoteCodeArgs = {
  input: IPromotionCampaignValidateNoteCodeInput;
};

export type IMutationsProvideCryptoTransactionHashArgs = {
  input: ISecondaryTransactionProvideCryptoHashInput;
};

export type IMutationsRegulationBestInterestQuizCreateArgs = {
  input: IRegulationBestInterestQuizCreateInput;
};

export type IMutationsRegulationBestInterestQuizDeleteArgs = {
  input: IRegulationBestInterestQuizDeleteInput;
};

export type IMutationsRegulationBestInterestQuizUpdateArgs = {
  input: IRegulationBestInterestQuizUpdateInput;
};

export type IMutationsRepublicCapitalPopulateUserDataArgs = {
  input: IRepublicCapitalPopulateUserDataInput;
};

export type IMutationsRepublicCapitalPortfolioCsvArgs = {
  input: IRepublicCapitalPortfolioCsvInput;
};

export type IMutationsRepublicCapitalRefreshUserDataArgs = {
  input: IRepublicCapitalRefreshUserDataInput;
};

export type IMutationsRepublicCapitalResetPasswordEmailArgs = {
  input: IRepublicCapitalResetPasswordEmailInput;
};

export type IMutationsRequestAccessArgs = {
  input: ISecondaryRequestAccessInput;
};

export type IMutationsRequestCpaArgs = {
  input: IOfferingFinancialRequestCpaInput;
};

export type IMutationsSecondariesReconfirmInterestToSellArgs = {
  input: ISecondaryReconfirmInterestToSellInput;
};

export type IMutationsSecondariesStartBuyerPaymentArgs = {
  input: ISecondaryStartBuyerPaymentInput;
};

export type IMutationsSellerConfirmSecondaryTransactionArgs = {
  input: ISecondarySellerConfirmSecondaryTransactionInput;
};

export type IMutationsSessionCreateArgs = {
  input: IMonolithAuthSessionCreateInput;
};

export type IMutationsSignBeneficialOwnerCertificationArgs = {
  input: ICustodialSignBeneficialOwnerCertificationInput;
};

export type IMutationsSignInArgs = {
  input: IAuthSignInInput;
};

export type IMutationsSignInAsUserArgs = {
  input: IAuthSignInAsUserInput;
};

export type IMutationsSignNomineeAgreementArgs = {
  input: ICustodialSignNomineeAgreementInput;
};

export type IMutationsSignTransferAgreementArgs = {
  input: ISecondaryTransactionGenerateSecondaryTransferAgreementUrlInput;
};

export type IMutationsSignUpArgs = {
  input: IAuthSignUpInput;
};

export type IMutationsSliderMediaItemCreateArgs = {
  input: IOfferingSliderMediaItemCreateInput;
};

export type IMutationsSliderMediaItemDeleteArgs = {
  input: IOfferingSliderMediaItemDeleteInput;
};

export type IMutationsSliderMediaItemUpdateArgs = {
  input: IOfferingSliderMediaItemUpdateInput;
};

export type IMutationsSliderMediaItemsUpdateOrderArgs = {
  input: IOfferingSliderMediaItemsUpdateOrderInput;
};

export type IMutationsSubscribeUserViaEmailArgs = {
  input: ISubscribeUserViaEmailInput;
};

export type IMutationsTeamMemberCreateArgs = {
  input: IUserRepublicTeamMemberCreateInput;
};

export type IMutationsTeamMemberDeleteArgs = {
  input: IUserRepublicTeamMemberDeleteInput;
};

export type IMutationsTeamMemberUpdateArgs = {
  input: IUserRepublicTeamMemberUpdateInput;
};

export type IMutationsTeaserPageCreateArgs = {
  input: ITeaserPageCreateInput;
};

export type IMutationsTeaserPageDeleteArgs = {
  input: ITeaserPageDeleteInput;
};

export type IMutationsTeaserPagePublishArgs = {
  input: ITeaserPagePublishInput;
};

export type IMutationsTeaserPageSendCreateLinkArgs = {
  input: ITeaserPageSendCreateLinkInput;
};

export type IMutationsTeaserPageUnpublishArgs = {
  input: ITeaserPageUnpublishInput;
};

export type IMutationsTeaserPageUpdateArgs = {
  input: ITeaserPageUpdateInput;
};

export type IMutationsTeaserPagesFollowerCreateArgs = {
  input: ITeaserPageFollowerCreateInput;
};

export type IMutationsTeaserPagesFollowerCsvArgs = {
  input: ITeaserPageFollowersCsvInput;
};

export type IMutationsTeaserPagesFollowerDeleteArgs = {
  input: ITeaserPageFollowerDeleteInput;
};

export type IMutationsTeaserPagesFollowerUpdateArgs = {
  input: ITeaserPageFollowerUpdateInput;
};

export type IMutationsUnfollowArgs = {
  input: IFollowDeleteInput;
};

export type IMutationsUnlikeObjectArgs = {
  input: IUnlikeObjectInput;
};

export type IMutationsUpdateInvestmentMessageArgs = {
  input: IOnboardingPostInvestmentMessageUpdateInput;
};

export type IMutationsUpdateReservationMessageArgs = {
  input: IOnboardingReservationCampaignUpdatePostMessageInput;
};

export type IMutationsUserBasicInfoUpdateArgs = {
  input: IUserBasicInfoUpdateInput;
};

export type IMutationsUserConfirmEmailArgs = {
  input: IUserConfirmEmailInput;
};

export type IMutationsUserEmailConfirmationResendArgs = {
  input: IUserEmailConfirmationResendInput;
};

export type IMutationsUserEmailUpdateArgs = {
  input: IUserEmailUpdateInput;
};

export type IMutationsUserMfaDisableArgs = {
  input: IUserMfaDisableInput;
};

export type IMutationsUserMfaEnableArgs = {
  input: IUserMfaEnableInput;
};

export type IMutationsUserNotificationPreferencesUpdateArgs = {
  input: IUserNotificationPreferencesUpdateInput;
};

export type IMutationsUserPasswordResetArgs = {
  input: IUserPasswordResetInput;
};

export type IMutationsUserPasswordSetArgs = {
  input: IUserPasswordSetInput;
};

export type IMutationsUserPasswordTokenCreateArgs = {
  input: IUserPasswordTokenCreateInput;
};

export type IMutationsUserPasswordUpdateArgs = {
  input: IUserPasswordUpdateInput;
};

export type IMutationsUserPrivacySettingsUpdateArgs = {
  input: IUserPrivacySettingsUpdateInput;
};

export type IMutationsUserProfileUpdateArgs = {
  input: IUserProfileUpdateInput;
};

export type IMutationsUserSendDeleteRequestArgs = {
  input: IUserSendDeleteRequestInput;
};

export type IMutationsUserSetInactiveArgs = {
  input: IUserSetInactiveInput;
};

export type IMutationsUserUpdateArgs = {
  input: IUserUpdateInput;
};

export type INameDetails = {
  __typename?: 'NameDetails';
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

export type INameDetailsInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

/** Input for generic person name */
export type INameInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

/** An object with an ID. */
export type INode = {
  /** ID of the object. */
  id: Scalars['ID']['output'];
};

export type INoteDistributionBanner = {
  __typename?: 'NoteDistributionBanner';
  user: IUser;
};

export type INoteLaunchBanner = {
  __typename?: 'NoteLaunchBanner';
  user: IUser;
};

/** Note launch configuration */
export type INoteLaunchConfig = {
  __typename?: 'NoteLaunchConfig';
  countdown?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/** Note price */
export type INotePrice = {
  __typename?: 'NotePrice';
  inCirculation?: Maybe<Scalars['BigInt']['output']>;
  marketCap?: Maybe<Scalars['BigInt']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  totalSupply?: Maybe<Scalars['BigInt']['output']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export type INoteRewardClaimBanner = {
  __typename?: 'NoteRewardClaimBanner';
  timeLeft: Scalars['Int']['output'];
};

/** Note price */
export type INoteRoutescanBalance = {
  __typename?: 'NoteRoutescanBalance';
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  formattedBalance?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/** Note promotion campaign type */
export type INotesPromotionCampaign = IPromotionCampaignInterface & {
  __typename?: 'NotesPromotionCampaign';
  endAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  expired: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  message?: Maybe<Scalars['String']['output']>;
  mobile: Scalars['Boolean']['output'];
  noteAmount?: Maybe<Scalars['Int']['output']>;
  status: IPromotionCampaignStatusEnum;
  valid: Scalars['Boolean']['output'];
};

/** Platform notifications */
export type INotification = {
  __typename?: 'Notification';
  action?: Maybe<Scalars['ID']['output']>;
  actionEnding?: Maybe<Scalars['String']['output']>;
  actor?: Maybe<IUser>;
  /** @deprecated Use actor */
  actorName?: Maybe<Scalars['ID']['output']>;
  avatarUrl?: Maybe<Scalars['Url']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  ctaObject?: Maybe<INotificationCtaObjectUnion>;
  ctaObjectExtraInfo?: Maybe<INotificationCtaObjectExtraInfo>;
  /**
   * Call to action object id or slug
   * @deprecated Use ctaObject instead
   */
  ctaObjectId?: Maybe<Scalars['String']['output']>;
  ctaObjectOwners?: Maybe<Array<INotificationCtaObjectUnion>>;
  /** @deprecated Use ctaObject instead */
  ctaObjectType?: Maybe<Scalars['String']['output']>;
  /** @deprecated Please use xCtaText instead */
  ctaText: Scalars['String']['output'];
  /** @deprecated Please use xCtaUrl instead */
  ctaUrl: Scalars['Url']['output'];
  description?: Maybe<Scalars['String']['output']>;
  event?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  meta?: Maybe<INotificationMeta>;
  seen: Scalars['Boolean']['output'];
  showEntireDescription: Scalars['Boolean']['output'];
  source: INotificationSourceUnion;
  targetEvent?: Maybe<Scalars['String']['output']>;
  targetName?: Maybe<Scalars['String']['output']>;
  xCtaText?: Maybe<Scalars['String']['output']>;
  xCtaUrl?: Maybe<Scalars['Url']['output']>;
};

/** The connection type for Notification. */
export type INotificationConnection = {
  __typename?: 'NotificationConnection';
  /** A list of edges. */
  edges: Array<INotificationEdge>;
  /** A list of nodes. */
  nodes: Array<INotification>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Temporary object type to fill in missing cta object types */
export type INotificationCtaBaseObject = {
  __typename?: 'NotificationCtaBaseObject';
  id?: Maybe<Scalars['ID']['output']>;
  /** @deprecated Use xSlug instead */
  slug: Scalars['String']['output'];
  type: Scalars['String']['output'];
  xSlug?: Maybe<Scalars['String']['output']>;
};

/** Cta object extra info type */
export type INotificationCtaObjectExtraInfo = {
  __typename?: 'NotificationCtaObjectExtraInfo';
  colorHex?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
};

/** Union of possible types of call to action object owner */
export type INotificationCtaObjectUnion = INotificationCtaBaseObject | IUser;

/** An edge in a connection. */
export type INotificationEdge = {
  __typename?: 'NotificationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: INotification;
};

/** Autogenerated input type of NotificationMarkAsSeen */
export type INotificationMarkAsSeenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  notificationLimit?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated return type of NotificationMarkAsSeen. */
export type INotificationMarkAsSeenPayload = {
  __typename?: 'NotificationMarkAsSeenPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  notifications?: Maybe<Array<INotification>>;
};

/** Autogenerated input type of NotificationMarkHeaderViewed */
export type INotificationMarkHeaderViewedInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of NotificationMarkHeaderViewed. */
export type INotificationMarkHeaderViewedPayload = {
  __typename?: 'NotificationMarkHeaderViewedPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  viewed?: Maybe<Scalars['Boolean']['output']>;
};

/** Meta type for extra Notification info */
export type INotificationMeta = {
  __typename?: 'NotificationMeta';
  /** Text for mobile purposes */
  mobileText?: Maybe<Scalars['String']['output']>;
  state?: Maybe<INotificationMetaStateEnum>;
  /** Color for background or state name */
  stateColorHex?: Maybe<Scalars['String']['output']>;
  /** Text for web-notifications */
  text?: Maybe<Scalars['String']['output']>;
};

/** List of available meta States of Notifications */
export enum INotificationMetaStateEnum {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Finalized = 'FINALIZED',
}

/** List of available Order Enums of Notifications domain */
export enum INotificationOrderEnum {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
}

/** List of available Scopes of Notifications domain */
export enum INotificationScopeEnum {
  PortfolioUpdates = 'PORTFOLIO_UPDATES',
}

/** Possible types of sources for Notification */
export type INotificationSourceUnion =
  | IAccreditedAccess
  | IAnnouncement
  | IBadge
  | IComment
  | IFollow
  | IInvestment
  | IInvestorGroupMembership
  | ILedgerIntegrationPayout
  | ILike
  | ILinkedinContact
  | IOffering
  | IOfferingExitInvestment
  | IOfferingHierarchicalOffering
  | IOfferingMaterialChangeInvestment
  | IOfferingRollingCloseInvestment
  | IOfferingUpdate
  | IPost
  | ITagsTag;

/** Autogenerated input type of OAuth */
export type IOAuthInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['Email']['input']>;
  /** Apple JWT */
  jwt?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<INameInput>;
  oauthToken?: InputMaybe<Scalars['String']['input']>;
  oauthTokenSecret?: InputMaybe<Scalars['String']['input']>;
  provider: IOAuthProviderEnum;
  /** Apple user identity */
  userIdentity?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OAuth. */
export type IOAuthPayload = {
  __typename?: 'OAuthPayload';
  authFlags?: Maybe<IAuthFlags>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  jwtToken?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use flags instead */
  needSetPassword?: Maybe<Scalars['Boolean']['output']>;
  signInCode?: Maybe<Scalars['String']['output']>;
  token?: Maybe<IAuthToken>;
  user?: Maybe<IUser>;
};

/** Variants of oAuth provider (social network) */
export enum IOAuthProviderEnum {
  Apple = 'APPLE',
  Facebook = 'FACEBOOK',
  Twitter = 'TWITTER',
}

/** An Offering from Core domain */
export type IOffering = IDealTermsInterface &
  IOfferingTags &
  IPressReleasesInterface & {
    __typename?: 'Offering';
    abandonedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    achEnabled: Scalars['Boolean']['output'];
    achInvestmentLimit: Scalars['Int']['output'];
    activeInvestmentsCount?: Maybe<Scalars['Int']['output']>;
    address: IAddress;
    airdrop: Scalars['Boolean']['output'];
    airdropActionPoints?: Maybe<Array<IAirdropActionPoint>>;
    allowEditingWallet: Scalars['Boolean']['output'];
    altoiraEnabled?: Maybe<Scalars['Boolean']['output']>;
    amountRaised?: Maybe<Scalars['String']['output']>;
    amountRaisedCents?: Maybe<Scalars['BigInt']['output']>;
    amountRaisedLastThreeDays?: Maybe<Scalars['Int']['output']>;
    amountRaisedPercentage?: Maybe<Scalars['Int']['output']>;
    amountRaisedThisWeekCents?: Maybe<Scalars['Int']['output']>;
    amountRaisedWithExternalCents?: Maybe<Scalars['BigInt']['output']>;
    assets: Array<IAssetInterface>;
    autopilotEligible?: Maybe<Scalars['Boolean']['output']>;
    /** @deprecated Incorrect name of field, pls use 'logo' */
    avatarContentType?: Maybe<Scalars['String']['output']>;
    /** @deprecated Incorrect name of field, pls use 'logo' */
    avatarFileName?: Maybe<Scalars['String']['output']>;
    /** @deprecated Incorrect name of field, pls use 'logo' */
    avatarFileSize?: Maybe<Scalars['BigInt']['output']>;
    /** @deprecated Incorrect name of field, pls use 'logo' */
    avatarUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    /** @deprecated Incorrect name of field, pls use 'logo' */
    avatarUrl?: Maybe<Scalars['Url']['output']>;
    /** List of badge names */
    badges: Array<IOfferingBadgeNameEnum>;
    bankName?: Maybe<IOfferingBankNameEnum>;
    bitpayEnabled?: Maybe<Scalars['Boolean']['output']>;
    brief?: Maybe<IOfferingBrief>;
    calculatedMinInvestmentAmount?: Maybe<Scalars['String']['output']>;
    campaignAgreement?: Maybe<ICampaignAgreement>;
    canSelectCryptoToken?: Maybe<Scalars['Boolean']['output']>;
    cancellationReason?: Maybe<Scalars['String']['output']>;
    cancelledAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    /** Compliance text to display(HostingEntity + Regulation) */
    cardComplianceText?: Maybe<Scalars['String']['output']>;
    cardEnabled?: Maybe<Scalars['Boolean']['output']>;
    cardEnabledByIssuer: Scalars['Boolean']['output'];
    cardImageContentType?: Maybe<Scalars['String']['output']>;
    cardImageFileName?: Maybe<Scalars['String']['output']>;
    cardImageFileSize?: Maybe<Scalars['BigInt']['output']>;
    cardImageUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    cardImageUrl?: Maybe<Scalars['Url']['output']>;
    cardInvestmentLimit: Scalars['Int']['output'];
    cardTooltipText?: Maybe<Scalars['String']['output']>;
    closedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    collectEthereumAddresses: Scalars['Boolean']['output'];
    collectStellarAddresses: Scalars['Boolean']['output'];
    combinedDeal: Scalars['Boolean']['output'];
    commentsDisabled: Scalars['Boolean']['output'];
    companyName: Scalars['String']['output'];
    coverPhotoContentType?: Maybe<Scalars['String']['output']>;
    coverPhotoFileName?: Maybe<Scalars['String']['output']>;
    coverPhotoFileSize?: Maybe<Scalars['BigInt']['output']>;
    coverPhotoUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    coverPhotoUrl?: Maybe<Scalars['Url']['output']>;
    cpaReviewCompleted: Scalars['Boolean']['output'];
    createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    creditCardDisabled: Scalars['Boolean']['output'];
    crypto: Scalars['Boolean']['output'];
    cryptoInvestmentOptions?: Maybe<Array<ICryptoInvestmentOptionType>>;
    cryptoToken?: Maybe<ICryptoTokensType>;
    cryptoTransferAddresses: Array<IOfferingCryptoTransferAddress>;
    custodial: Scalars['Boolean']['output'];
    custodialVendor?: Maybe<ICustodialVendor>;
    customTerms?: Maybe<Array<IOfferingCustomTerm>>;
    deadline?: Maybe<Scalars['ISO8601DateTime']['output']>;
    description?: Maybe<Scalars['String']['output']>;
    /** @deprecated Incorrect type of field, pls use 'xDisableInternationalEntities' */
    disableInternationalEntities?: Maybe<Scalars['Int']['output']>;
    disallowInvestmentCancellations: Scalars['Boolean']['output'];
    disallowInvestmentDecreases: Scalars['Boolean']['output'];
    disallowInvestmentIncreases: Scalars['Boolean']['output'];
    disallowInvestorEntityEdit: Scalars['Boolean']['output'];
    disallowReservationCancellations: Scalars['Boolean']['output'];
    disallowReservationDecreases: Scalars['Boolean']['output'];
    disallowReservationIncreases: Scalars['Boolean']['output'];
    discussionsCount: Scalars['Int']['output'];
    documents?: Maybe<Array<IOfferingDocument>>;
    emailDisclaimer?: Maybe<Scalars['String']['output']>;
    employeeCount: Scalars['Int']['output'];
    equityClaimed?: Maybe<Scalars['String']['output']>;
    escrowAgreementContentType?: Maybe<Scalars['String']['output']>;
    escrowAgreementFileName?: Maybe<Scalars['String']['output']>;
    escrowAgreementFileSize?: Maybe<Scalars['BigInt']['output']>;
    escrowAgreementSignedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    escrowAgreementUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    escrowAgreementUrl?: Maybe<Scalars['Url']['output']>;
    escrowProvider?: Maybe<Scalars['String']['output']>;
    exit?: Maybe<IOfferingExit>;
    externalInvestmentsAmountCents?: Maybe<Scalars['Int']['output']>;
    externalInvestmentsAmountTooltip?: Maybe<Scalars['String']['output']>;
    externalInvestmentsDescription?: Maybe<Scalars['String']['output']>;
    externalInvestors: Array<IOfferingExternalInvestor>;
    externalInvestorsDescription?: Maybe<Scalars['String']['output']>;
    externalInvestorsQuantity?: Maybe<Scalars['Int']['output']>;
    facebookPixelId?: Maybe<Scalars['String']['output']>;
    faqs?: Maybe<Array<IOfferingFaq>>;
    feeDescription?: Maybe<Scalars['String']['output']>;
    /** @deprecated Use 'xFeePercentage' */
    feePercentage?: Maybe<Scalars['Int']['output']>;
    feeRequired: Scalars['Boolean']['output'];
    feeTiers?: Maybe<Array<IOfferingFeeTier>>;
    feeType?: Maybe<Scalars['String']['output']>;
    fig: Scalars['Boolean']['output'];
    finalRaiseAmountCents?: Maybe<Scalars['Int']['output']>;
    finalizedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    financialDetail?: Maybe<IOfferingFinancialDetail>;
    financialsAlreadyGaap: Scalars['Boolean']['output'];
    fixedFeeAmountCents?: Maybe<Scalars['Int']['output']>;
    flexibleDealTerms?: Maybe<Array<IOfferingTermTierDealTerm>>;
    flexibleDealTermsForDealCard?: Maybe<Array<IOfferingTermTierDealTerm>>;
    following: Scalars['Boolean']['output'];
    followsCount: Scalars['Int']['output'];
    forceKycAmlCheck: Scalars['Boolean']['output'];
    forceKycAmlCheckSetAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    form1A?: Maybe<IOfferingForm1A>;
    formC?: Maybe<IOfferingFormC>;
    founderSignedSecuritiesAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    fundAmericaCashManagementFeesCents?: Maybe<Scalars['Int']['output']>;
    fundingGoalRationale?: Maybe<Scalars['String']['output']>;
    fundingRaiseAmountCents?: Maybe<Scalars['Int']['output']>;
    gaapReviewCompleted: Scalars['Boolean']['output'];
    googleAnalyticsId?: Maybe<Scalars['String']['output']>;
    hasClosed: Scalars['Boolean']['output'];
    hasPublished: Scalars['Boolean']['output'];
    heroImageContentType?: Maybe<Scalars['String']['output']>;
    heroImageFileName?: Maybe<Scalars['String']['output']>;
    heroImageFileSize?: Maybe<Scalars['BigInt']['output']>;
    heroImageUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    heroImageUrl?: Maybe<Scalars['Url']['output']>;
    hidden: Scalars['Boolean']['output'];
    hideTimeline: Scalars['Boolean']['output'];
    highConcept?: Maybe<Scalars['String']['output']>;
    hostingEntity?: Maybe<Scalars['String']['output']>;
    hostingEntityForDisplay?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    indexInAllOfferings: Scalars['Int']['output'];
    investmentAmountMultiplierCents?: Maybe<Scalars['Int']['output']>;
    investmentBadge?: Maybe<IInvestmentBadge>;
    investmentChangeDeadline?: Maybe<Scalars['Int']['output']>;
    investmentFinalizedEmailsSentAt?: Maybe<
      Scalars['ISO8601DateTime']['output']
    >;
    investmentReasons?: Maybe<Array<IOfferingInvestmentReasons>>;
    investmentsPaused?: Maybe<Scalars['Boolean']['output']>;
    investorCrowdSafeTemplateContentType?: Maybe<Scalars['String']['output']>;
    investorCrowdSafeTemplateFileName?: Maybe<Scalars['String']['output']>;
    investorCrowdSafeTemplateFileSize?: Maybe<Scalars['BigInt']['output']>;
    investorCrowdSafeTemplateUpdatedAt?: Maybe<
      Scalars['ISO8601DateTime']['output']
    >;
    investorCrowdSafeTemplateUrl?: Maybe<Scalars['Url']['output']>;
    investorsCount?: Maybe<Scalars['Int']['output']>;
    issuerContactUserId?: Maybe<Scalars['Int']['output']>;
    issuerProfile: IIssuerProfile;
    launchFestival: Scalars['Boolean']['output'];
    /** @deprecated Use xLegalCompanyName */
    legalCompanyName: Scalars['String']['output'];
    locationRestricted?: Maybe<IOfferingLocationRestricted>;
    logoContentType?: Maybe<Scalars['String']['output']>;
    logoFileName?: Maybe<Scalars['String']['output']>;
    logoFileSize?: Maybe<Scalars['BigInt']['output']>;
    logoUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    logoUrl?: Maybe<Scalars['Url']['output']>;
    maxGoalReached: Scalars['Boolean']['output'];
    maxGoalReachedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    maxInvestmentAmountCents?: Maybe<Scalars['BigInt']['output']>;
    maxRaiseAmountCents?: Maybe<Scalars['BigInt']['output']>;
    maximumFeeAmountCents?: Maybe<Scalars['Int']['output']>;
    mediciIntegrationOffering?: Maybe<IMediciIntegrationOffering>;
    mediciUuid?: Maybe<Scalars['String']['output']>;
    meetTheDrapers: Scalars['Boolean']['output'];
    minApRaisedAmountReachedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    minInvestmentAmountCents?: Maybe<Scalars['Int']['output']>;
    minRaiseAmountCents?: Maybe<Scalars['Int']['output']>;
    minWireAmountCents?: Maybe<Scalars['Int']['output']>;
    minimumFeeAmountCents?: Maybe<Scalars['Int']['output']>;
    ndaRequired: Scalars['Boolean']['output'];
    networkFeeAmountCents?: Maybe<Scalars['Int']['output']>;
    noRepublicBranding: Scalars['Boolean']['output'];
    noTrending: Scalars['Boolean']['output'];
    noteCapCents?: Maybe<Scalars['Int']['output']>;
    numOfAllOfferings: Scalars['Int']['output'];
    offeringEditorCanEditPitch: Scalars['Boolean']['output'];
    otherOfferings: Array<IOffering>;
    parentId?: Maybe<Scalars['Int']['output']>;
    payLaterEnabled: Scalars['Boolean']['output'];
    perks?: Maybe<Array<IOfferingPerk>>;
    pitch?: Maybe<Scalars['String']['output']>;
    pitchHighlights?: Maybe<Scalars['String']['output']>;
    pitchSections: Array<IOfferingPitchSection>;
    platformFeeCashPercentage?: Maybe<Scalars['Int']['output']>;
    platformFeeEquityPercentage?: Maybe<Scalars['Int']['output']>;
    postFundedDescription?: Maybe<Scalars['String']['output']>;
    postInvestmentFounderMessage?: Maybe<Scalars['String']['output']>;
    preparingToFinalizeAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    pressReleases: Array<IPressReleasesPress>;
    previousCampaigns?: Maybe<Array<IOfferingPreviousCampaigns>>;
    privateAccess: Scalars['Boolean']['output'];
    propertyProfile?: Maybe<Scalars['String']['output']>;
    publishedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    questions?: Maybe<Scalars['String']['output']>;
    reachedMaxRaiseAmount: Scalars['Boolean']['output'];
    reachedMinRaiseAmount: Scalars['Boolean']['output'];
    refundFeeEnabled?: Maybe<Scalars['Boolean']['output']>;
    regBiQuizRequired: Scalars['Boolean']['output'];
    regulation: IOfferingRegulationEnum;
    regulationForDisplay: Scalars['String']['output'];
    relatedOfferings: Array<IOffering>;
    removedFromSearchEngines: Scalars['Boolean']['output'];
    republicDriUser?: Maybe<IUser>;
    republicEngineerDriUserId?: Maybe<Scalars['Int']['output']>;
    republicExpenseCents?: Maybe<Scalars['Int']['output']>;
    requiresAlcoholReps: Scalars['Boolean']['output'];
    requiresSelfAccreditation: Scalars['Boolean']['output'];
    requiresVerifiedAccreditation: Scalars['Boolean']['output'];
    requiresW8: Scalars['Boolean']['output'];
    requiresW9: Scalars['Boolean']['output'];
    requiresWalletConfirmation: Scalars['Boolean']['output'];
    revenue?: Maybe<Scalars['BigInt']['output']>;
    reviewsCount: Scalars['Int']['output'];
    risks: Array<IOfferingRisk>;
    risksDisabled: Scalars['Boolean']['output'];
    risksFinalizedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    rolesTeamMembers?: Maybe<Array<IOfferingRoleTeamMember>>;
    rollingCloses: Array<IOfferingRollingClose>;
    rollingClosesEnabled: Scalars['Boolean']['output'];
    round?: Maybe<Scalars['String']['output']>;
    secondariesTaRecord?: Maybe<ISecondaryTransferAgreementRecord>;
    securitiesIssuedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    /** @deprecated Use xSecurity */
    security?: Maybe<Scalars['String']['output']>;
    securityAttachmentContentType?: Maybe<Scalars['String']['output']>;
    securityAttachmentFileName?: Maybe<Scalars['String']['output']>;
    securityAttachmentFileSize?: Maybe<Scalars['BigInt']['output']>;
    securityAttachmentUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    securityAttachmentUrl?: Maybe<Scalars['Url']['output']>;
    securityValuationOrCap?: Maybe<Scalars['String']['output']>;
    securityValuationOrCapLabel?: Maybe<Scalars['String']['output']>;
    sentToAccountantForReview?: Maybe<Scalars['ISO8601DateTime']['output']>;
    sentToAttorneyForReview?: Maybe<Scalars['ISO8601DateTime']['output']>;
    seoDescription?: Maybe<Scalars['String']['output']>;
    seoImageContentType?: Maybe<Scalars['String']['output']>;
    seoImageFileName?: Maybe<Scalars['String']['output']>;
    seoImageFileSize?: Maybe<Scalars['BigInt']['output']>;
    seoImageUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    seoImageUrl?: Maybe<Scalars['Url']['output']>;
    sharingDisabled: Scalars['Boolean']['output'];
    /** @deprecated Badge names list available */
    showAcceptingReservationsBadge: Scalars['Boolean']['output'];
    /** @deprecated Badge names list available */
    showAccreditedOnlyBadge: Scalars['Boolean']['output'];
    /** @deprecated Badge names list available */
    showFullyFundedBadge: Scalars['Boolean']['output'];
    /** @deprecated Badge names list available */
    showFullyReservedBadge: Scalars['Boolean']['output'];
    /** @deprecated Badge names list available */
    showFundedBadge: Scalars['Boolean']['output'];
    showInfoOnHoverInDealroom: Scalars['Boolean']['output'];
    /** @deprecated Badge names list available */
    showLiveBadge: Scalars['Boolean']['output'];
    /** @deprecated Badge names list available */
    showNewBadge: Scalars['Boolean']['output'];
    showOnCompaniesPage: Scalars['Boolean']['output'];
    showOnDealRoom: Scalars['Boolean']['output'];
    showOnHomepage: Scalars['Boolean']['output'];
    /** @deprecated Badge names list available */
    showPlatinumMembershipEligibleBadge: Scalars['Boolean']['output'];
    /** @deprecated Badge names list available */
    showSoldOutBadge: Scalars['Boolean']['output'];
    /** @deprecated Badge names list available */
    showSpecialBadge: Scalars['Boolean']['output'];
    /** @deprecated Badge names list available */
    showTimeToInvestBadge: Scalars['Boolean']['output'];
    /** @deprecated Badge names list available */
    showTrendingBadge: Scalars['Boolean']['output'];
    /** @deprecated Badge names list available */
    showWaitlistBadge: Scalars['Boolean']['output'];
    sliderMediaItems?: Maybe<Array<IOfferingSliderMediaItem>>;
    slug: Scalars['String']['output'];
    socialCapitalDisabled: Scalars['Boolean']['output'];
    specialDealTerms?: Maybe<Scalars['Boolean']['output']>;
    spotlight?: Maybe<Scalars['Boolean']['output']>;
    spvDeal: Scalars['Boolean']['output'];
    stage?: Maybe<Scalars['String']['output']>;
    state?: Maybe<IOfferingStateEnum>;
    static: Scalars['Boolean']['output'];
    stripePublishableKey?: Maybe<Scalars['String']['output']>;
    summary?: Maybe<Scalars['String']['output']>;
    supportEmail?: Maybe<Scalars['String']['output']>;
    supportPhone?: Maybe<Scalars['String']['output']>;
    supportedByIosApp: Scalars['Boolean']['output'];
    tags?: Maybe<Array<ITagsTag>>;
    targetLaunchDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
    techServicesFeeAmountCents?: Maybe<Scalars['Int']['output']>;
    terms?: Maybe<Scalars['String']['output']>;
    testingTheWaters: Scalars['Boolean']['output'];
    testingTheWatersFirstDaysOff: Scalars['Boolean']['output'];
    testingTheWatersLaunched: Scalars['Boolean']['output'];
    testingTheWatersPostInvestmentFounderMessage?: Maybe<
      Scalars['String']['output']
    >;
    testingTheWatersRequestedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    testingTheWatersTurnedOffAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    timeRemainingUnitForDisplay?: Maybe<Scalars['String']['output']>;
    timeRemainingValueForDisplay?: Maybe<Scalars['Int']['output']>;
    timeToInvestBadgeParams?: Maybe<IOfferingBadgeTimeToInvest>;
    tokenDistributedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    tokenSale?: Maybe<Scalars['Boolean']['output']>;
    tokensOfferingId?: Maybe<Scalars['BigInt']['output']>;
    totalCapitalization?: Maybe<Scalars['String']['output']>;
    transactApiId?: Maybe<Scalars['String']['output']>;
    trending: Scalars['Boolean']['output'];
    twitterPixelId?: Maybe<Scalars['String']['output']>;
    type?: Maybe<Scalars['String']['output']>;
    updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    /** List of updates */
    updates?: Maybe<Array<IOfferingUpdate>>;
    updatesCount: Scalars['Int']['output'];
    usOnly: Scalars['Boolean']['output'];
    /** Permitted actions for Core Offering */
    userPermissions?: Maybe<IOfferingUserPermissions>;
    /** Formatted with a dollar sign and commas */
    valuationCap?: Maybe<Scalars['String']['output']>;
    valuationCapCents?: Maybe<Scalars['BigInt']['output']>;
    vertical?: Maybe<Scalars['String']['output']>;
    verticalTag?: Maybe<Scalars['String']['output']>;
    videoContentType?: Maybe<Scalars['String']['output']>;
    videoFileName?: Maybe<Scalars['String']['output']>;
    videoFileSize?: Maybe<Scalars['BigInt']['output']>;
    videoUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    videoUrl?: Maybe<Scalars['Url']['output']>;
    videoYoutubeId?: Maybe<Scalars['String']['output']>;
    walletAddressRequired: Scalars['Boolean']['output'];
    whitelabel?: Maybe<Scalars['Boolean']['output']>;
    wireAutoDecreaseEnabled: Scalars['Boolean']['output'];
    wireEnabled: Scalars['Boolean']['output'];
    xDisableInternationalEntities: Scalars['Boolean']['output'];
    xFeePercentage?: Maybe<Scalars['Float']['output']>;
    xLegalCompanyName?: Maybe<Scalars['String']['output']>;
    xSecurity?: Maybe<IOfferingSecurity>;
  };

/** An Offering from Core domain */
export type IOfferingAvatarUrlArgs = {
  extension?: InputMaybe<IFileProcessingImgproxyExtensionEnum>;
  gravity?: InputMaybe<IFileProcessingImgproxyGravityEnum>;
  resize?: InputMaybe<IFileProcessingImgproxyResizeInput>;
};

/** An Offering from Core domain */
export type IOfferingCardImageUrlArgs = {
  extension?: InputMaybe<IFileProcessingImgproxyExtensionEnum>;
  gravity?: InputMaybe<IFileProcessingImgproxyGravityEnum>;
  resize?: InputMaybe<IFileProcessingImgproxyResizeInput>;
};

/** An Offering from Core domain */
export type IOfferingCardInvestmentLimitArgs = {
  userSlug: Scalars['String']['input'];
};

/** An Offering from Core domain */
export type IOfferingCoverPhotoUrlArgs = {
  extension?: InputMaybe<IFileProcessingImgproxyExtensionEnum>;
  gravity?: InputMaybe<IFileProcessingImgproxyGravityEnum>;
  resize?: InputMaybe<IFileProcessingImgproxyResizeInput>;
};

/** An Offering from Core domain */
export type IOfferingHeroImageUrlArgs = {
  extension?: InputMaybe<IFileProcessingImgproxyExtensionEnum>;
  gravity?: InputMaybe<IFileProcessingImgproxyGravityEnum>;
  resize?: InputMaybe<IFileProcessingImgproxyResizeInput>;
};

/** An Offering from Core domain */
export type IOfferingLogoUrlArgs = {
  extension?: InputMaybe<IFileProcessingImgproxyExtensionEnum>;
  gravity?: InputMaybe<IFileProcessingImgproxyGravityEnum>;
  resize?: InputMaybe<IFileProcessingImgproxyResizeInput>;
};

/** An Offering from Core domain */
export type IOfferingRelatedOfferingsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

/** An Offering from Core domain */
export type IOfferingSeoImageUrlArgs = {
  extension?: InputMaybe<IFileProcessingImgproxyExtensionEnum>;
  gravity?: InputMaybe<IFileProcessingImgproxyGravityEnum>;
  resize?: InputMaybe<IFileProcessingImgproxyResizeInput>;
};

/** Offerings autocopmlete with related tags and hints */
export type IOfferingAutocomplete = IAutocompleteTags &
  INode & {
    __typename?: 'OfferingAutocomplete';
    hints?: Maybe<Array<IOfferingAutocompleteHint>>;
    /** ID of the object. */
    id: Scalars['ID']['output'];
    tags?: Maybe<Array<ITagsTag>>;
  };

/** Offerings autocopmlete single hint */
export type IOfferingAutocompleteHint = INode & {
  __typename?: 'OfferingAutocompleteHint';
  campaignStatus?: Maybe<Scalars['String']['output']>;
  hint: Scalars['String']['output'];
  /** ID of the object. */
  id: Scalars['ID']['output'];
  offering?: Maybe<IOffering>;
};

export enum IOfferingBadgeNameEnum {
  AcceptingReservations = 'ACCEPTING_RESERVATIONS',
  AccreditedOnly = 'ACCREDITED_ONLY',
  FullyFunded = 'FULLY_FUNDED',
  FullyReserved = 'FULLY_RESERVED',
  Funded = 'FUNDED',
  Live = 'LIVE',
  New = 'NEW',
  PlatinumMembershipEligible = 'PLATINUM_MEMBERSHIP_ELIGIBLE',
  SoldOut = 'SOLD_OUT',
  Special = 'SPECIAL',
  TimeToInvest = 'TIME_TO_INVEST',
  Trending = 'TRENDING',
  Waitlist = 'WAITLIST',
}

export type IOfferingBadgeTimeToInvest = {
  __typename?: 'OfferingBadgeTimeToInvest';
  /** Unit(days, hours, hours) */
  unit: Scalars['String']['output'];
  /** Value(number) of unit */
  value: Scalars['Int']['output'];
};

export enum IOfferingBankNameEnum {
  Bankprov = 'BANKPROV',
}

export enum IOfferingBaseScopeEnum {
  All = 'ALL',
  HasClosed = 'HAS_CLOSED',
  Live = 'LIVE',
  Published = 'PUBLISHED',
  ShowOnCompaniesPage = 'SHOW_ON_COMPANIES_PAGE',
  ShowOnHomepage = 'SHOW_ON_HOMEPAGE',
}

export type IOfferingBrief = {
  __typename?: 'OfferingBrief';
  dealPagePreviewContentType?: Maybe<Scalars['String']['output']>;
  dealPagePreviewFileName?: Maybe<Scalars['String']['output']>;
  dealPagePreviewFileSize?: Maybe<Scalars['BigInt']['output']>;
  dealPagePreviewUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dealPagePreviewUrl?: Maybe<Scalars['Url']['output']>;
  id: Scalars['ID']['output'];
  previewContentType?: Maybe<Scalars['String']['output']>;
  previewFileName?: Maybe<Scalars['String']['output']>;
  previewFileSize?: Maybe<Scalars['BigInt']['output']>;
  previewUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  previewUrl?: Maybe<Scalars['Url']['output']>;
  videoContentType?: Maybe<Scalars['String']['output']>;
  videoFileName?: Maybe<Scalars['String']['output']>;
  videoFileSize?: Maybe<Scalars['BigInt']['output']>;
  videoUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  videoUrl?: Maybe<Scalars['Url']['output']>;
};

export type IOfferingBriefDealPagePreviewUrlArgs = {
  extension?: InputMaybe<IFileProcessingImgproxyExtensionEnum>;
  gravity?: InputMaybe<IFileProcessingImgproxyGravityEnum>;
  resize?: InputMaybe<IFileProcessingImgproxyResizeInput>;
};

export type IOfferingBriefPreviewUrlArgs = {
  extension?: InputMaybe<IFileProcessingImgproxyExtensionEnum>;
  gravity?: InputMaybe<IFileProcessingImgproxyGravityEnum>;
  resize?: InputMaybe<IFileProcessingImgproxyResizeInput>;
};

/** Offerings search category type */
export type IOfferingCategory = INode & {
  __typename?: 'OfferingCategory';
  categories: Array<ICategoryUnion>;
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

/** The connection type for Offering. */
export type IOfferingConnection = {
  __typename?: 'OfferingConnection';
  /** A list of edges. */
  edges: Array<IOfferingEdge>;
  /** A list of nodes. */
  nodes: Array<IOffering>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type IOfferingCryptoSettingsInput = {
  allowEditingWallet?: InputMaybe<Scalars['Boolean']['input']>;
  cryptoTokenId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  walletAddressRequired?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated input type of OfferingCryptoSettingsUpdate */
export type IOfferingCryptoSettingsUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  offering: IOfferingCryptoSettingsInput;
};

/** Autogenerated return type of OfferingCryptoSettingsUpdate. */
export type IOfferingCryptoSettingsUpdatePayload = {
  __typename?: 'OfferingCryptoSettingsUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  offering?: Maybe<IOffering>;
};

/** Offerings crypto transfer address */
export type IOfferingCryptoTransferAddress = INode & {
  __typename?: 'OfferingCryptoTransferAddress';
  address: Scalars['String']['output'];
  contractAddress: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  currencyName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  network: Scalars['String']['output'];
};

/** Custom Term object of Core domain */
export type IOfferingCustomTerm = INode & {
  __typename?: 'OfferingCustomTerm';
  checkbox: Scalars['Boolean']['output'];
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  offeringId?: Maybe<Scalars['ID']['output']>;
  /** @deprecated Use xPosition instead */
  position: Scalars['Int']['output'];
  text: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  xPosition?: Maybe<Scalars['Int']['output']>;
};

/** The connection type for OfferingCustomTerm. */
export type IOfferingCustomTermConnection = {
  __typename?: 'OfferingCustomTermConnection';
  /** A list of edges. */
  edges: Array<IOfferingCustomTermEdge>;
  /** A list of nodes. */
  nodes: Array<IOfferingCustomTerm>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IOfferingCustomTermEdge = {
  __typename?: 'OfferingCustomTermEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: IOfferingCustomTerm;
};

export type IOfferingCustomTermInput = {
  checkbox?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  offeringSlug?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type IOfferingDocument = {
  __typename?: 'OfferingDocument';
  attachmentContentType?: Maybe<Scalars['String']['output']>;
  attachmentFileName?: Maybe<Scalars['String']['output']>;
  attachmentFileSize?: Maybe<Scalars['BigInt']['output']>;
  attachmentUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  attachmentUrl?: Maybe<Scalars['Url']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

/** An edge in a connection. */
export type IOfferingEdge = {
  __typename?: 'OfferingEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: IOffering;
};

export type IOfferingExit = INode & {
  __typename?: 'OfferingExit';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  returnPercentage?: Maybe<Scalars['Float']['output']>;
  returnPeriodDays?: Maybe<Scalars['Int']['output']>;
  totalPayoutAmountCents: Scalars['Int']['output'];
};

export type IOfferingExitInvestment = INode & {
  __typename?: 'OfferingExitInvestment';
  id: Scalars['ID']['output'];
  investment?: Maybe<IInvestment>;
  payoutAmountCents: Scalars['Int']['output'];
  payoutCurrency?: Maybe<Scalars['String']['output']>;
};

export type IOfferingExternalInvestor = INode & {
  __typename?: 'OfferingExternalInvestor';
  companiesContentType?: Maybe<Scalars['String']['output']>;
  companiesFileName?: Maybe<Scalars['String']['output']>;
  companiesFileSize?: Maybe<Scalars['BigInt']['output']>;
  companiesUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  companiesUrl?: Maybe<Scalars['Url']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  logoContentType?: Maybe<Scalars['String']['output']>;
  logoFileName?: Maybe<Scalars['String']['output']>;
  logoFileSize?: Maybe<Scalars['BigInt']['output']>;
  logoUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  logoUrl?: Maybe<Scalars['Url']['output']>;
  name: Scalars['String']['output'];
  tagline?: Maybe<Scalars['String']['output']>;
};

export type IOfferingExternalInvestorCompaniesUrlArgs = {
  extension?: InputMaybe<IFileProcessingImgproxyExtensionEnum>;
  gravity?: InputMaybe<IFileProcessingImgproxyGravityEnum>;
  resize?: InputMaybe<IFileProcessingImgproxyResizeInput>;
};

export type IOfferingExternalInvestorLogoUrlArgs = {
  extension?: InputMaybe<IFileProcessingImgproxyExtensionEnum>;
  gravity?: InputMaybe<IFileProcessingImgproxyGravityEnum>;
  resize?: InputMaybe<IFileProcessingImgproxyResizeInput>;
};

/** FAQ Type for Offering in Core Domain */
export type IOfferingFaq = {
  __typename?: 'OfferingFAQ';
  answer: Scalars['String']['output'];
  question: Scalars['String']['output'];
};

/** Fee Tier Type for Offering in Core Domain */
export type IOfferingFeeTier = {
  __typename?: 'OfferingFeeTier';
  cashCommissionPercentage: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  /** Offering */
  offering: IOffering;
  securitiesCommissionPercentage: Scalars['Int']['output'];
  tierLowerBoundAmountCents: Scalars['Int']['output'];
  tierUpperBoundAmountCents: Scalars['Int']['output'];
  xTierLowerBoundAmountCents: Scalars['BigInt']['output'];
  xTierUpperBoundAmountCents: Scalars['BigInt']['output'];
};

export enum IOfferingFilter {
  Active = 'active',
  AlertBulkTransfersNotCreditedForStripePayoutsTp = 'alert_bulk_transfers_not_credited_for_stripe_payouts_tp',
  AlertChargesDisabledForStripeAccount = 'alert_charges_disabled_for_stripe_account',
  AlertChargesDisabledForStripeAccountTp = 'alert_charges_disabled_for_stripe_account_tp',
  AlertDanglingFundAmericaInvestments = 'alert_dangling_fund_america_investments',
  AlertDanglingStripeCharges = 'alert_dangling_stripe_charges',
  AlertDanglingStripeChargesTp = 'alert_dangling_stripe_charges_tp',
  AlertFundAmericaBulkTransfersNotCreditedForStripePayouts = 'alert_fund_america_bulk_transfers_not_credited_for_stripe_payouts',
  AlertFundAmericaEscrowClosedPrematurely = 'alert_fund_america_escrow_closed_prematurely',
  AlertFundAmericaEscrowPendingWithMissingInvestments = 'alert_fund_america_escrow_pending_with_missing_investments',
  AlertIncorrectAmountAchTransfersTp = 'alert_incorrect_amount_ach_transfers_tp',
  AlertManuallyHandledBulkTransfers = 'alert_manually_handled_bulk_transfers',
  AlertManuallyHandledStripePayouts = 'alert_manually_handled_stripe_payouts',
  AlertNoBulkTransfersForStripePayoutsTp = 'alert_no_bulk_transfers_for_stripe_payouts_tp',
  AlertNoFundAmericaBulkTransfersForStripePayouts = 'alert_no_fund_america_bulk_transfers_for_stripe_payouts',
  AlertNotRecentlyRefreshed = 'alert_not_recently_refreshed',
  AlertNotRecentlyRefreshedTp = 'alert_not_recently_refreshed_tp',
  AlertPayoutsDisabledForStripeAccount = 'alert_payouts_disabled_for_stripe_account',
  AlertPayoutsDisabledForStripeAccountTp = 'alert_payouts_disabled_for_stripe_account_tp',
}

/** Detail type for Offerings::Financials */
export type IOfferingFinancialDetail = INode & {
  __typename?: 'OfferingFinancialDetail';
  amountRaisedLastYearCents?: Maybe<Scalars['Int']['output']>;
  closedCampaignsLastYear: Scalars['Boolean']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  crowdfundingExperience: Scalars['Boolean']['output'];
  dateOfCampaignClosure?: Maybe<Scalars['ISO8601Date']['output']>;
  dateOfIncorporation: Scalars['ISO8601Date']['output'];
  documentRequirements: IOfferingFinancialDocumentRequirements;
  documents?: Maybe<Array<IOfferingFinancialDocument>>;
  financialType?: Maybe<Scalars['String']['output']>;
  fiscalYearEndMonth: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  maxRaiseAmountCents?: Maybe<Scalars['Int']['output']>;
  offering: IOffering;
  /** @deprecated Deprecated */
  operationalActivity: Scalars['Boolean']['output'];
  republicCpaRequestedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Document type for Offerings::Financials document */
export type IOfferingFinancialDocument = INode & {
  __typename?: 'OfferingFinancialDocument';
  attachmentContentType?: Maybe<Scalars['String']['output']>;
  attachmentFileName?: Maybe<Scalars['String']['output']>;
  attachmentFileSize?: Maybe<Scalars['BigInt']['output']>;
  attachmentUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  attachmentUrl?: Maybe<Scalars['Url']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  documentType: IOfferingFinancialDocumentTypeEnum;
  id: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Input for Offerings::Financials::Document object */
export type IOfferingFinancialDocumentCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  directUploadAttachmentUuid: Scalars['String']['input'];
  documentType: IOfferingFinancialDocumentTypeEnum;
  offeringSlug: Scalars['String']['input'];
};

/** Autogenerated return type of OfferingFinancialDocumentCreate. */
export type IOfferingFinancialDocumentCreatePayload = {
  __typename?: 'OfferingFinancialDocumentCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  document?: Maybe<IOfferingFinancialDocument>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Autogenerated input type of OfferingFinancialDocumentDelete */
export type IOfferingFinancialDocumentDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  documentId: Scalars['ID']['input'];
  offeringSlug: Scalars['String']['input'];
};

/** Autogenerated return type of OfferingFinancialDocumentDelete. */
export type IOfferingFinancialDocumentDeletePayload = {
  __typename?: 'OfferingFinancialDocumentDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  document?: Maybe<IOfferingFinancialDocument>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Data for Which years to include section */
export type IOfferingFinancialDocumentRequirements = INode & {
  __typename?: 'OfferingFinancialDocumentRequirements';
  /** ID of the object. */
  id: Scalars['ID']['output'];
  yearsToIncludeText: Scalars['String']['output'];
  yearsToIncludeTip?: Maybe<Scalars['String']['output']>;
};

export enum IOfferingFinancialDocumentTypeEnum {
  AuditLetter = 'AUDIT_LETTER',
  ReviewReport = 'REVIEW_REPORT',
}

/** Input for Offerings::Financials::Detail object */
export type IOfferingFinancialModifyDetailsInput = {
  /** Amount raised for the last year */
  amountRaisedLastYearCents?: InputMaybe<Scalars['Int']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Did issuer close campaing last year? */
  closedCampaignsLastYear?: InputMaybe<Scalars['Boolean']['input']>;
  /** Did issuer have past expirience? */
  crowdfundingExperience: Scalars['Boolean']['input'];
  dateOfCampaignClosure: Scalars['ISO8601DateTime']['input'];
  dateOfIncorporation: Scalars['ISO8601DateTime']['input'];
  /** Month number */
  fiscalYearEndMonth: Scalars['Int']['input'];
  /** ID for existed Detail object */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Amount issuer is looking to raise */
  maxRaiseAmountCents: Scalars['Int']['input'];
  /** Offering slug */
  offeringSlug: Scalars['String']['input'];
  /**
   * Does issuer have operation activity?
   * @deprecated Deprecated
   */
  operationalActivity?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of OfferingFinancialModifyDetails. */
export type IOfferingFinancialModifyDetailsPayload = {
  __typename?: 'OfferingFinancialModifyDetailsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  financialDetail?: Maybe<IOfferingFinancialDetail>;
};

/** Autogenerated input type of OfferingFinancialRequestCpa */
export type IOfferingFinancialRequestCpaInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  offeringSlug: Scalars['String']['input'];
};

/** Autogenerated return type of OfferingFinancialRequestCpa. */
export type IOfferingFinancialRequestCpaPayload = {
  __typename?: 'OfferingFinancialRequestCpaPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  financialDetail?: Maybe<IOfferingFinancialDetail>;
};

/** Form 1 A Type for Offering in Core Domain */
export type IOfferingForm1A = {
  __typename?: 'OfferingForm1A';
  attachmentContentType?: Maybe<Scalars['String']['output']>;
  attachmentFileName?: Maybe<Scalars['String']['output']>;
  attachmentFileSize?: Maybe<Scalars['BigInt']['output']>;
  attachmentUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  attachmentUrl?: Maybe<Scalars['Url']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  edgarUrl?: Maybe<Scalars['Url']['output']>;
  id: Scalars['ID']['output'];
  offeringId?: Maybe<Scalars['Int']['output']>;
  risksFinalizedAt: Scalars['ISO8601DateTime']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Form C Type for Offering in Core Domain */
export type IOfferingFormC = {
  __typename?: 'OfferingFormC';
  /** @deprecated Use xApprovedAt instead */
  approvedAt: Scalars['ISO8601DateTime']['output'];
  attachmentContentType?: Maybe<Scalars['String']['output']>;
  attachmentFileName?: Maybe<Scalars['String']['output']>;
  attachmentFileSize?: Maybe<Scalars['BigInt']['output']>;
  attachmentUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  attachmentUrl?: Maybe<Scalars['Url']['output']>;
  badActors?: Maybe<Scalars['String']['output']>;
  businessDescription?: Maybe<Scalars['String']['output']>;
  competitiveLandscape?: Maybe<Scalars['String']['output']>;
  competitors?: Maybe<Scalars['String']['output']>;
  conflictsOfInterest?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  customerBase?: Maybe<Scalars['String']['output']>;
  edgarUrl?: Maybe<Scalars['Url']['output']>;
  filedAt: Scalars['ISO8601DateTime']['output'];
  founders?: Maybe<Scalars['String']['output']>;
  fundAmericaDocumentId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  intellectualProperty?: Maybe<Scalars['String']['output']>;
  legalName?: Maybe<Scalars['String']['output']>;
  liquidAssetsDetails?: Maybe<Scalars['String']['output']>;
  litigationHistory?: Maybe<Scalars['String']['output']>;
  materialCapitalExpenditure?: Maybe<Scalars['String']['output']>;
  offeringId?: Maybe<Scalars['Int']['output']>;
  outstandingDebt?: Maybe<Scalars['String']['output']>;
  outstandingDebtDefaulted?: Maybe<Scalars['String']['output']>;
  primaryLocation?: Maybe<Scalars['String']['output']>;
  profitabilityProjection?: Maybe<Scalars['String']['output']>;
  regulatoryRequirements?: Maybe<Scalars['String']['output']>;
  relatedPersonsTransactions?: Maybe<Scalars['String']['output']>;
  securitiesIssued?: Maybe<Scalars['String']['output']>;
  sharesOutstandingPostOffering?: Maybe<Scalars['String']['output']>;
  supplierDescription?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  useOfProceeds?: Maybe<Scalars['String']['output']>;
  valuation409a?: Maybe<Scalars['String']['output']>;
  xApprovedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export type IOfferingHierarchicalOffering = INode & {
  __typename?: 'OfferingHierarchicalOffering';
  id: Scalars['ID']['output'];
  state: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated return type of OfferingInvestmentReasonCreate. */
export type IOfferingInvestmentReasonCreatePayload = {
  __typename?: 'OfferingInvestmentReasonCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  investmentReason?: Maybe<IOfferingInvestmentReasons>;
};

export enum IOfferingInvestmentReasonDecisionInfluenceEnum {
  Advantage = 'ADVANTAGE',
  Advisors = 'ADVISORS',
  BigProblem = 'BIG_PROBLEM',
  BusinessModel = 'BUSINESS_MODEL',
  Founders = 'FOUNDERS',
  Idea = 'IDEA',
  MarketSize = 'MARKET_SIZE',
  Mission = 'MISSION',
  PastInvestors = 'PAST_INVESTORS',
  PersonalPain = 'PERSONAL_PAIN',
  Product = 'PRODUCT',
  Recommended = 'RECOMMENDED',
  Team = 'TEAM',
  Traction = 'TRACTION',
}

export type IOfferingInvestmentReasonInput = {
  amountPublic?: InputMaybe<Scalars['Boolean']['input']>;
  body?: InputMaybe<Scalars['String']['input']>;
  decisionInfluences?: InputMaybe<
    Array<IOfferingInvestmentReasonDecisionInfluenceEnum>
  >;
  decisionInfluencesOther?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  investmentId?: InputMaybe<Scalars['ID']['input']>;
  source?: InputMaybe<IOfferingInvestmentReasonSourceEnum>;
  sourceOther?: InputMaybe<Scalars['String']['input']>;
};

export type IOfferingInvestmentReasons = INode & {
  __typename?: 'OfferingInvestmentReasons';
  amountPublic: Scalars['Boolean']['output'];
  body: Scalars['String']['output'];
  createdAt: Scalars['ISO8601Date']['output'];
  decisionInfluences?: Maybe<
    Array<IOfferingInvestmentReasonDecisionInfluenceEnum>
  >;
  decisionInfluencesOther?: Maybe<Scalars['String']['output']>;
  featured: Scalars['Boolean']['output'];
  hidden: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  investmentAmountCents?: Maybe<Scalars['Int']['output']>;
  ownerId?: Maybe<Scalars['ID']['output']>;
  ownerType?: Maybe<Scalars['String']['output']>;
  reason: Scalars['String']['output'];
  share: Scalars['Boolean']['output'];
  slug: Scalars['String']['output'];
  source?: Maybe<IOfferingInvestmentReasonSourceEnum>;
  sourceOther?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use xUser instead */
  user: IUser;
  xUser?: Maybe<IUser>;
};

export enum IOfferingInvestmentReasonSourceEnum {
  Facebook = 'FACEBOOK',
  Google = 'GOOGLE',
  Instagram = 'INSTAGRAM',
  Other = 'OTHER',
  Print = 'PRINT',
  Radio = 'RADIO',
  Republic = 'REPUBLIC',
  Tv = 'TV',
  WordOfMouth = 'WORD_OF_MOUTH',
}

/** Autogenerated return type of OfferingInvestmentReasonUpdate. */
export type IOfferingInvestmentReasonUpdatePayload = {
  __typename?: 'OfferingInvestmentReasonUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  investmentReason?: Maybe<IOfferingInvestmentReasons>;
};

export type IOfferingLocationRestricted = {
  __typename?: 'OfferingLocationRestricted';
  availableForBlockscore: Scalars['Boolean']['output'];
  availableForLocation: Scalars['Boolean']['output'];
  availableForUser: Scalars['Boolean']['output'];
  bitpayAvailable: Scalars['Boolean']['output'];
  /** @deprecated Use xBlockscoreAddress */
  blockscoreAddress: IAddress;
  unavailableForUserMessage: Scalars['String']['output'];
  unavailableJurisdictions: Array<Scalars['String']['output']>;
  xBlockscoreAddress?: Maybe<IAddress>;
};

export type IOfferingMaterialChangeInvestment = INode & {
  __typename?: 'OfferingMaterialChangeInvestment';
  id: Scalars['ID']['output'];
  investment?: Maybe<IInvestment>;
  state?: Maybe<Scalars['String']['output']>;
};

/** List of available orders for My Offerings resolver */
export enum IOfferingMyOfferingOrderEnum {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
}

export type IOfferingMyOfferingsItem = {
  __typename?: 'OfferingMyOfferingsItem';
  buttonText?: Maybe<Scalars['String']['output']>;
  buttonUrl?: Maybe<Scalars['Url']['output']>;
  offering?: Maybe<IOffering>;
  offeringUrl: Scalars['Url']['output'];
  role: Scalars['String']['output'];
  tooltip?: Maybe<Scalars['String']['output']>;
};

/** The connection type for OfferingMyOfferingsItem. */
export type IOfferingMyOfferingsItemConnection = {
  __typename?: 'OfferingMyOfferingsItemConnection';
  /** A list of edges. */
  edges: Array<IOfferingMyOfferingsItemEdge>;
  /** A list of nodes. */
  nodes: Array<IOfferingMyOfferingsItem>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IOfferingMyOfferingsItemEdge = {
  __typename?: 'OfferingMyOfferingsItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: IOfferingMyOfferingsItem;
};

export type IOfferingPerk = {
  __typename?: 'OfferingPerk';
  amountCents: Scalars['Int']['output'];
  description: Array<Scalars['String']['output']>;
  hasInvestments: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  inStock: Scalars['Boolean']['output'];
  investmentsCount: Scalars['Int']['output'];
  limited: Scalars['Boolean']['output'];
  quantity: Scalars['Int']['output'];
  quantityAvailable: Scalars['Int']['output'];
};

export type IOfferingPitchSection = {
  __typename?: 'OfferingPitchSection';
  /** Body of Pitch Section, currently HTML Formatted response */
  body?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  order: Scalars['Int']['output'];
  sectionType?: Maybe<Scalars['String']['output']>;
};

export type IOfferingPreviousCampaigns = {
  __typename?: 'OfferingPreviousCampaigns';
  amountRaisedCents?: Maybe<Scalars['BigInt']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  publishedAt: Scalars['ISO8601DateTime']['output'];
  slug?: Maybe<Scalars['String']['output']>;
};

export enum IOfferingRegulationEnum {
  RegulationAPlus = 'REGULATION_A_PLUS',
  RegulationCf = 'REGULATION_CF',
  RegulationD_504 = 'REGULATION_D_504',
  RegulationD_506B = 'REGULATION_D_506_B',
  RegulationD_506C = 'REGULATION_D_506_C',
  RegulationS = 'REGULATION_S',
}

export type IOfferingRisk = {
  __typename?: 'OfferingRisk';
  createdAt: Scalars['ISO8601DateTime']['output'];
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Input for Offerings risks */
export type IOfferingRiskInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

export type IOfferingRoleTeamMember = {
  __typename?: 'OfferingRoleTeamMember';
  angellistUrl?: Maybe<Scalars['Url']['output']>;
  avatarContentType?: Maybe<Scalars['String']['output']>;
  avatarFileName?: Maybe<Scalars['String']['output']>;
  avatarFileSize?: Maybe<Scalars['BigInt']['output']>;
  avatarUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  avatarUrl?: Maybe<Scalars['Url']['output']>;
  bio?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['Email']['output']>;
  facebookUrl?: Maybe<Scalars['Url']['output']>;
  featured?: Maybe<Scalars['Boolean']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  linkedinUrl?: Maybe<Scalars['Url']['output']>;
  order: Scalars['Int']['output'];
  role: Scalars['String']['output'];
  twitterUrl?: Maybe<Scalars['Url']['output']>;
  user?: Maybe<IUser>;
};

export type IOfferingRoleTeamMemberAvatarUrlArgs = {
  extension?: InputMaybe<IFileProcessingImgproxyExtensionEnum>;
  gravity?: InputMaybe<IFileProcessingImgproxyGravityEnum>;
  resize?: InputMaybe<IFileProcessingImgproxyResizeInput>;
};

/** Offerings Rolling Close Type in Core Domain */
export type IOfferingRollingClose = INode & {
  __typename?: 'OfferingRollingClose';
  changeDeadline: Scalars['ISO8601DateTime']['output'];
  changeDeadlineForDisplay: Scalars['String']['output'];
  deadline?: Maybe<Scalars['ISO8601DateTime']['output']>;
  deadlineForDisplay: Scalars['String']['output'];
  finalizedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  offering: IOffering;
  offeringsRollingClosesInvestments: Array<IOfferingRollingCloseInvestment>;
  preparingToFinalizeAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  publishedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  state?: Maybe<IOfferingRollingCloseStateEnum>;
};

/** Offerings Rolling Close Investment Type in Core Domain */
export type IOfferingRollingCloseInvestment = INode & {
  __typename?: 'OfferingRollingCloseInvestment';
  amountCents?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  investment?: Maybe<IInvestment>;
  offeringRollingClose?: Maybe<IOfferingRollingClose>;
};

export enum IOfferingRollingCloseStateEnum {
  Created = 'CREATED',
  Finalized = 'FINALIZED',
  PreparingToFinalize = 'PREPARING_TO_FINALIZE',
  Published = 'PUBLISHED',
}

export type IOfferings = {
  offerings: Array<IOffering>;
};

export enum IOfferingScopeEnum {
  /** @deprecated Use BaseScopeEnum value */
  All = 'ALL',
  BasedOnInterests = 'BASED_ON_INTERESTS',
  /** @deprecated Use BaseScopeEnum value */
  Closed = 'CLOSED',
  ClosingSoon = 'CLOSING_SOON',
  DealRoomNotNda = 'DEAL_ROOM_NOT_NDA',
  MostFunded = 'MOST_FUNDED',
  MostTraction = 'MOST_TRACTION',
  NewDeals = 'NEW_DEALS',
  OtherAfterHomepage = 'OTHER_AFTER_HOMEPAGE',
  SavedDeals = 'SAVED_DEALS',
  SavedDealsLive = 'SAVED_DEALS_LIVE',
  /** @deprecated Use BaseScopeEnum value */
  ShowOnHomepage = 'SHOW_ON_HOMEPAGE',
  Trending = 'TRENDING',
}

/** Input for Offerings resolver */
export type IOfferingScopeFilterInput = {
  /** period to get closing soon deals in days */
  closingSoonPeriodDays?: InputMaybe<Scalars['Int']['input']>;
  /** period to get most traction deals in days */
  mostTractionPeriodDays?: InputMaybe<Scalars['Int']['input']>;
  /** period to get new deals in days */
  newDealsPeriodDays?: InputMaybe<Scalars['Int']['input']>;
  scopeName: IOfferingScopeEnum;
};

/** Autogenerated input type of OfferingsCustomTermCreate */
export type IOfferingsCustomTermCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  customTerm: IOfferingCustomTermInput;
};

/** Autogenerated return type of OfferingsCustomTermCreate. */
export type IOfferingsCustomTermCreatePayload = {
  __typename?: 'OfferingsCustomTermCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customTerm?: Maybe<IOfferingCustomTerm>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Autogenerated input type of OfferingsCustomTermDelete */
export type IOfferingsCustomTermDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of OfferingsCustomTermDelete. */
export type IOfferingsCustomTermDeletePayload = {
  __typename?: 'OfferingsCustomTermDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customTerm?: Maybe<IOfferingCustomTerm>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Autogenerated input type of OfferingsCustomTermUpdate */
export type IOfferingsCustomTermUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  customTerm: IOfferingCustomTermInput;
};

/** Autogenerated return type of OfferingsCustomTermUpdate. */
export type IOfferingsCustomTermUpdatePayload = {
  __typename?: 'OfferingsCustomTermUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customTerm?: Maybe<IOfferingCustomTerm>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Input for offerings search amount interval */
export type IOfferingSearchAmountIntervalInput = {
  maximum?: InputMaybe<Scalars['Float']['input']>;
  minimum: Scalars['Float']['input'];
};

export enum IOfferingSearchFilterEnum {
  ShowOnCompaniesPage = 'SHOW_ON_COMPANIES_PAGE',
  ShowOnHomepage = 'SHOW_ON_HOMEPAGE',
}

/** Input for Offerings search */
export type IOfferingSearchInput = {
  companyLocation?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<IOfferingSearchFilterEnum>;
  /** @deprecated Use cursor-based pagination arguments */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** @deprecated Use minInvestmentAmountCents instead */
  minInvestmentAmount?: InputMaybe<IOfferingSearchAmountIntervalInput>;
  minInvestmentAmountCents?: InputMaybe<IOfferingSearchXAmountIntervalInput>;
  mostTrackedOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** @deprecated Use cursor-based pagination arguments */
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Use revenueCents instead */
  revenue?: InputMaybe<IOfferingSearchAmountIntervalInput>;
  revenueCents?: InputMaybe<IOfferingSearchXAmountIntervalInput>;
  sort?: InputMaybe<IOfferingSearchSortEnum>;
  state?: InputMaybe<IOfferingSearchStateEnum>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  /** @deprecated Use valuationCents instead */
  valuation?: InputMaybe<IOfferingSearchAmountIntervalInput>;
  valuationCents?: InputMaybe<IOfferingSearchXAmountIntervalInput>;
};

export enum IOfferingSearchSortEnum {
  ClosingSoon = 'CLOSING_SOON',
  MostFunded = 'MOST_FUNDED',
  MostTractionLastWeek = 'MOST_TRACTION_LAST_WEEK',
  RecentlyLaunched = 'RECENTLY_LAUNCHED',
}

export enum IOfferingSearchStateEnum {
  Closed = 'CLOSED',
  Else = 'ELSE',
  Finalized = 'FINALIZED',
  PreparingToFinalize = 'PREPARING_TO_FINALIZE',
  Published = 'PUBLISHED',
}

/** Input for offerings search amount interval */
export type IOfferingSearchXAmountIntervalInput = {
  maximum?: InputMaybe<Scalars['BigInt']['input']>;
  minimum?: InputMaybe<Scalars['BigInt']['input']>;
};

export type IOfferingSecurity = {
  __typename?: 'OfferingSecurity';
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** @deprecated Incorrect nullability, pls use 'x_description_for_display' */
  descriptionForDisplay: Scalars['String']['output'];
  discountForDisplay?: Maybe<Scalars['String']['output']>;
  earlyTokenRepaymentBonusPercentage?: Maybe<Scalars['String']['output']>;
  escrow?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  interestRatePercentage?: Maybe<Scalars['String']['output']>;
  minimumReturnMultiple?: Maybe<Scalars['Float']['output']>;
  /** @deprecated Incorrect nullability, pls use 'x_name_for_display' */
  nameForDisplay: Scalars['String']['output'];
  /** @deprecated Incorrect nullability, pls use 'x_name_short_for_display' */
  nameShortForDisplay: Scalars['String']['output'];
  normalTokenRepaymentBonusPercentage?: Maybe<Scalars['String']['output']>;
  partialRefundPercentage?: Maybe<Scalars['String']['output']>;
  partialRefundPeriod?: Maybe<Scalars['Int']['output']>;
  pricePerSecurityCents?: Maybe<Scalars['Int']['output']>;
  pricePerToken?: Maybe<Scalars['Float']['output']>;
  securityClassName: Scalars['String']['output'];
  /** @deprecated Incorrect field type, pls use 'xValuationCapCents' */
  valuationCapCents?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Incorrect field type, pls use 'xValuationCents' */
  valuationCents?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Incorrect nullability, pls use 'xValuationOrCapLabel' */
  valuationOrCapLabel: Scalars['String']['output'];
  xDescriptionForDisplay?: Maybe<Scalars['String']['output']>;
  xNameForDisplay?: Maybe<Scalars['String']['output']>;
  xNameShortForDisplay?: Maybe<Scalars['String']['output']>;
  xValuationCapCents?: Maybe<Scalars['BigInt']['output']>;
  xValuationCents?: Maybe<Scalars['BigInt']['output']>;
  xValuationOrCapLabel?: Maybe<Scalars['String']['output']>;
};

export type IOfferingSliderMediaItem = {
  __typename?: 'OfferingSliderMediaItem';
  contentContentType?: Maybe<Scalars['String']['output']>;
  contentFileName?: Maybe<Scalars['String']['output']>;
  contentFileSize?: Maybe<Scalars['BigInt']['output']>;
  /** Video or image */
  contentType?: Maybe<IOfferingSliderMediaItemContentTypeEnum>;
  contentUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  contentUrl?: Maybe<Scalars['Url']['output']>;
  id: Scalars['ID']['output'];
  orderNum: Scalars['Int']['output'];
  previewContentType?: Maybe<Scalars['String']['output']>;
  previewFileName?: Maybe<Scalars['String']['output']>;
  previewFileSize?: Maybe<Scalars['BigInt']['output']>;
  previewUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  previewUrl?: Maybe<Scalars['Url']['output']>;
  /** Content url for video from Youtube */
  url?: Maybe<Scalars['Url']['output']>;
  /** Preview url for video from Youtube */
  urlPreview?: Maybe<Scalars['Url']['output']>;
  /** ID for video from Youtube */
  youtubeId?: Maybe<Scalars['Url']['output']>;
};

export type IOfferingSliderMediaItemPreviewUrlArgs = {
  extension?: InputMaybe<IFileProcessingImgproxyExtensionEnum>;
  gravity?: InputMaybe<IFileProcessingImgproxyGravityEnum>;
  resize?: InputMaybe<IFileProcessingImgproxyResizeInput>;
};

export enum IOfferingSliderMediaItemContentTypeEnum {
  Image = 'IMAGE',
  Video = 'VIDEO',
}

/** Autogenerated input type of OfferingSliderMediaItemCreate */
export type IOfferingSliderMediaItemCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  sliderMediaItem: IOfferingSliderMediaItemInput;
};

/** Autogenerated return type of OfferingSliderMediaItemCreate. */
export type IOfferingSliderMediaItemCreatePayload = {
  __typename?: 'OfferingSliderMediaItemCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  sliderMediaItem?: Maybe<IOfferingSliderMediaItem>;
};

/** Autogenerated input type of OfferingSliderMediaItemDelete */
export type IOfferingSliderMediaItemDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of OfferingSliderMediaItemDelete. */
export type IOfferingSliderMediaItemDeletePayload = {
  __typename?: 'OfferingSliderMediaItemDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  sliderMediaItem?: Maybe<IOfferingSliderMediaItem>;
};

export type IOfferingSliderMediaItemInput = {
  contentType: IOfferingSliderMediaItemContentTypeEnum;
  directUploadContentUuid?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  offeringSlug?: InputMaybe<Scalars['String']['input']>;
  orderNum?: InputMaybe<Scalars['Int']['input']>;
  url?: InputMaybe<Scalars['Url']['input']>;
  urlPreview?: InputMaybe<Scalars['Url']['input']>;
};

export type IOfferingSliderMediaItemOrderUpdateInput = {
  id: Scalars['ID']['input'];
  orderNum: Scalars['Int']['input'];
};

/** Autogenerated input type of OfferingSliderMediaItemsUpdateOrder */
export type IOfferingSliderMediaItemsUpdateOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  offeringSlug: Scalars['String']['input'];
  orderUpdates: Array<IOfferingSliderMediaItemOrderUpdateInput>;
};

/** Autogenerated return type of OfferingSliderMediaItemsUpdateOrder. */
export type IOfferingSliderMediaItemsUpdateOrderPayload = {
  __typename?: 'OfferingSliderMediaItemsUpdateOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  sliderMediaItems?: Maybe<Array<IOfferingSliderMediaItem>>;
};

/** Autogenerated input type of OfferingSliderMediaItemUpdate */
export type IOfferingSliderMediaItemUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  sliderMediaItem: IOfferingSliderMediaItemInput;
};

/** Autogenerated return type of OfferingSliderMediaItemUpdate. */
export type IOfferingSliderMediaItemUpdatePayload = {
  __typename?: 'OfferingSliderMediaItemUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  sliderMediaItem?: Maybe<IOfferingSliderMediaItem>;
};

export enum IOfferingStateEnum {
  Cancelled = 'CANCELLED',
  Closed = 'CLOSED',
  Created = 'CREATED',
  Finalized = 'FINALIZED',
  PreparingToFinalize = 'PREPARING_TO_FINALIZE',
  Published = 'PUBLISHED',
}

export type IOfferingTags = {
  tags?: Maybe<Array<ITagsTag>>;
};

export type IOfferingTerm = {
  __typename?: 'OfferingTerm';
  checkbox: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  text: Scalars['String']['output'];
};

export type IOfferingTermTier = INode & {
  __typename?: 'OfferingTermTier';
  amountRaisedCents?: Maybe<Scalars['Float']['output']>;
  /** Deal Terms */
  dealTerms?: Maybe<Array<IOfferingTermTierDealTerm>>;
  id: Scalars['ID']['output'];
  /** Offering */
  offering?: Maybe<IOffering>;
  /** @deprecated refer to offering type to get more information */
  offeringId: Scalars['Int']['output'];
  previousTermTierId?: Maybe<Scalars['ID']['output']>;
  transitionAt?: Maybe<Scalars['ISO8601Date']['output']>;
  transitionStrategyType?: Maybe<IOfferingTermTierStrategyTypeEnum>;
};

/** The connection type for OfferingTermTier. */
export type IOfferingTermTierConnection = {
  __typename?: 'OfferingTermTierConnection';
  /** A list of edges. */
  edges: Array<IOfferingTermTierEdge>;
  /** A list of nodes. */
  nodes: Array<IOfferingTermTier>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** TermTeir::DealTerm Type for Offering in Core Domain */
export type IOfferingTermTierDealTerm = {
  __typename?: 'OfferingTermTierDealTerm';
  active?: Maybe<Scalars['Boolean']['output']>;
  description: Scalars['String']['output'];
  displayOrder: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  mailMergeVar?: Maybe<Scalars['String']['output']>;
  nextTierValue?: Maybe<Scalars['String']['output']>;
  showOnCertificate?: Maybe<Scalars['Boolean']['output']>;
  showOnDealPage: Scalars['Boolean']['output'];
  showOnEmail?: Maybe<Scalars['Boolean']['output']>;
  showOnTombstone?: Maybe<Scalars['Boolean']['output']>;
  termTierId: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  titleForDealCard: Scalars['String']['output'];
  type: IOfferingTermTierDealTermTypeEnum;
  value: Scalars['String']['output'];
  /** @deprecated Use xValueForDealCard instead */
  valueForDealCard: Scalars['String']['output'];
  xValueForDealCard?: Maybe<Scalars['String']['output']>;
};

export type IOfferingTermTierDealTermInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  displayOrder: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  mailMergeVar?: InputMaybe<Scalars['String']['input']>;
  showOnCertificate: Scalars['Boolean']['input'];
  showOnDealPage: Scalars['Boolean']['input'];
  showOnEmail: Scalars['Boolean']['input'];
  showOnTombstone: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
  type: IOfferingTermTierDealTermTypeEnum;
  value: Scalars['String']['input'];
};

export enum IOfferingTermTierDealTermTypeEnum {
  Allocation = 'ALLOCATION',
  Custom = 'CUSTOM',
  Deadline = 'DEADLINE',
  Discount = 'DISCOUNT',
  FundingGoal = 'FUNDING_GOAL',
  InterestRate = 'INTEREST_RATE',
  InvestmentMultiplier = 'INVESTMENT_MULTIPLIER',
  Maturity = 'MATURITY',
  MaximumInvestment = 'MAXIMUM_INVESTMENT',
  MinimumInvestment = 'MINIMUM_INVESTMENT',
  PricePerSecurity = 'PRICE_PER_SECURITY',
  SecurityType = 'SECURITY_TYPE',
  SpvDeal = 'SPV_DEAL',
  TokensPerDrop = 'TOKENS_PER_DROP',
  TokenName = 'TOKEN_NAME',
  TokenPrice = 'TOKEN_PRICE',
  TotalTokensAmount = 'TOTAL_TOKENS_AMOUNT',
  ValuationCap = 'VALUATION_CAP',
}

/** An edge in a connection. */
export type IOfferingTermTierEdge = {
  __typename?: 'OfferingTermTierEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: IOfferingTermTier;
};

export type IOfferingTermTierInput = {
  amountRaisedCents?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  previousTermTierId?: InputMaybe<Scalars['Int']['input']>;
  transitionAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
  transitionStrategyType: IOfferingTermTierStrategyTypeEnum;
};

export enum IOfferingTermTierStrategyTypeEnum {
  RaisedAmount = 'RAISED_AMOUNT',
  Timestamp = 'TIMESTAMP',
}

export enum IOfferingTypeEnum {
  CohortCampaign = 'CohortCampaign',
  ConvertibleCampaign = 'ConvertibleCampaign',
  EquityCampaign = 'EquityCampaign',
  LimitedPartnershipFundCampaign = 'LimitedPartnershipFundCampaign',
  SecondaryCampaign = 'SecondaryCampaign',
}

/** Autogenerated input type of OfferingUnlockDeal */
export type IOfferingUnlockDealInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  ndaSigned: Scalars['Boolean']['input'];
  offeringId: Scalars['Int']['input'];
};

/** Autogenerated return type of OfferingUnlockDeal. */
export type IOfferingUnlockDealPayload = {
  __typename?: 'OfferingUnlockDealPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  offering?: Maybe<IOffering>;
};

/** object = [timeline, update] (timeline type) OR object = [update] (Offerings::Update type) */
export type IOfferingUpdate = {
  __typename?: 'OfferingUpdate';
  actionable?: Maybe<Scalars['Boolean']['output']>;
  approvedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  body?: Maybe<Scalars['String']['output']>;
  commentsCount?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  headline?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  likedByUser: Scalars['Boolean']['output'];
  likesCount?: Maybe<Scalars['Int']['output']>;
  offering?: Maybe<IOffering>;
  preview?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  publishedByRepublic?: Maybe<Scalars['Boolean']['output']>;
  slug: Scalars['String']['output'];
  state: IOfferingUpdateStateEnum;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  url: Scalars['Url']['output'];
  /** @deprecated Can be null, pls use 'xUser' */
  user: IUser;
  xUser?: Maybe<IUser>;
};

/** The connection type for OfferingUpdate. */
export type IOfferingUpdateConnection = {
  __typename?: 'OfferingUpdateConnection';
  /** A list of edges. */
  edges: Array<IOfferingUpdateEdge>;
  /** A list of nodes. */
  nodes: Array<IOfferingUpdate>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IOfferingUpdateEdge = {
  __typename?: 'OfferingUpdateEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: IOfferingUpdate;
};

/** Autogenerated input type of OfferingUpdateMarkAsSeen */
export type IOfferingUpdateMarkAsSeenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of OfferingUpdateMarkAsSeen. */
export type IOfferingUpdateMarkAsSeenPayload = {
  __typename?: 'OfferingUpdateMarkAsSeenPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  update?: Maybe<IOfferingUpdate>;
};

export enum IOfferingUpdateScopeEnum {
  UpdatesFromSavedDeals = 'UPDATES_FROM_SAVED_DEALS',
  UpdatesFromSavedDealsNotViewed = 'UPDATES_FROM_SAVED_DEALS_NOT_VIEWED',
}

export enum IOfferingUpdateStateEnum {
  Approved = 'APPROVED',
  Created = 'CREATED',
  Published = 'PUBLISHED',
}

/** User permissions exposed to a client for Offering object of Core domain */
export type IOfferingUserPermissions = {
  __typename?: 'OfferingUserPermissions';
  canShow: Scalars['Boolean']['output'];
};

/** Autogenerated return type of OnboardingAirdropActionPointCreate. */
export type IOnboardingAirdropActionPointCreatePayload = {
  __typename?: 'OnboardingAirdropActionPointCreatePayload';
  actionPoint?: Maybe<IAirdropActionPoint>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Autogenerated input type of OnboardingAirdropActionPointDelete */
export type IOnboardingAirdropActionPointDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of OnboardingAirdropActionPointDelete. */
export type IOnboardingAirdropActionPointDeletePayload = {
  __typename?: 'OnboardingAirdropActionPointDeletePayload';
  actionPoint?: Maybe<IAirdropActionPoint>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Autogenerated input type of OnboardingAirdropActionPointPublish */
export type IOnboardingAirdropActionPointPublishInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  offeringSlug?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OnboardingAirdropActionPointPublish. */
export type IOnboardingAirdropActionPointPublishPayload = {
  __typename?: 'OnboardingAirdropActionPointPublishPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  state?: Maybe<IOnboardingSidebarSectionStateEnum>;
};

/** Autogenerated return type of OnboardingAirdropActionPointUpdate. */
export type IOnboardingAirdropActionPointUpdatePayload = {
  __typename?: 'OnboardingAirdropActionPointUpdatePayload';
  actionPoint?: Maybe<IAirdropActionPoint>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Autogenerated input type of OnboardingCardPaymentCreateStripeAccount */
export type IOnboardingCardPaymentCreateStripeAccountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  offeringSlug: Scalars['String']['input'];
  rolesCoveredPersonId: Scalars['ID']['input'];
};

/** Autogenerated return type of OnboardingCardPaymentCreateStripeAccount. */
export type IOnboardingCardPaymentCreateStripeAccountPayload = {
  __typename?: 'OnboardingCardPaymentCreateStripeAccountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  mediciOffering?: Maybe<IMediciIntegrationOffering>;
};

export type IOnboardingCompanyProfileUpdateInput = {
  address?: InputMaybe<IAddressInput>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  contactPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  discordUrl?: InputMaybe<Scalars['Url']['input']>;
  doingBusinessAs?: InputMaybe<Scalars['String']['input']>;
  employeeCount?: InputMaybe<Scalars['Int']['input']>;
  employerIdNumber?: InputMaybe<Scalars['String']['input']>;
  facebookUrl?: InputMaybe<Scalars['Url']['input']>;
  fiscalYearEndMonth?: InputMaybe<Scalars['Int']['input']>;
  incorporatedInUs: Scalars['Boolean']['input'];
  incorporationCountry?: InputMaybe<IAddressIncorporationCountriesEnum>;
  incorporationDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  incorporationState?: InputMaybe<IAddressIncorporationStatesEnum>;
  incorporationType?: InputMaybe<IIssuerIssuerProfileIncorporationTypeEnum>;
  incorporationTypeOtherValue?: InputMaybe<Scalars['String']['input']>;
  instagramUrl?: InputMaybe<Scalars['Url']['input']>;
  legalCompanyName?: InputMaybe<Scalars['String']['input']>;
  linkedinUrl?: InputMaybe<Scalars['Url']['input']>;
  mediumUrl?: InputMaybe<Scalars['Url']['input']>;
  naics?: InputMaybe<Scalars['String']['input']>;
  /** Offering slug to update */
  offeringSlug: Scalars['String']['input'];
  pinterestUrl?: InputMaybe<Scalars['Url']['input']>;
  primaryContact?: InputMaybe<IOnboardingMemberUpdateInput>;
  publicPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  tiktokUrl?: InputMaybe<Scalars['Url']['input']>;
  twitterUrl?: InputMaybe<Scalars['Url']['input']>;
  websiteUrl?: InputMaybe<Scalars['Url']['input']>;
  youtubeUrl?: InputMaybe<Scalars['Url']['input']>;
};

/** Autogenerated return type of OnboardingCompanyProfileUpdate. */
export type IOnboardingCompanyProfileUpdatePayload = {
  __typename?: 'OnboardingCompanyProfileUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  issuerProfile?: Maybe<IIssuerProfile>;
};

export enum IOnboardingDocumentDocumentTypeEnum {
  CoverLetter = 'COVER_LETTER',
  FormId = 'FORM_ID',
  FormIdSigned = 'FORM_ID_SIGNED',
  PassphraseForm = 'PASSPHRASE_FORM',
  PassphraseFormSigned = 'PASSPHRASE_FORM_SIGNED',
}

/** Edgar access document */
export type IOnboardingDocumentEdgarAccess = {
  __typename?: 'OnboardingDocumentEdgarAccess';
  attachmentContentType?: Maybe<Scalars['String']['output']>;
  attachmentFileName?: Maybe<Scalars['String']['output']>;
  attachmentFileSize?: Maybe<Scalars['BigInt']['output']>;
  attachmentUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  attachmentUrl?: Maybe<Scalars['Url']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  documentType: IOnboardingDocumentDocumentTypeEnum;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for OnboardingDocumentEdgarAccess. */
export type IOnboardingDocumentEdgarAccessConnection = {
  __typename?: 'OnboardingDocumentEdgarAccessConnection';
  /** A list of edges. */
  edges: Array<IOnboardingDocumentEdgarAccessEdge>;
  /** A list of nodes. */
  nodes: Array<IOnboardingDocumentEdgarAccess>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Autogenerated input type of OnboardingDocumentEdgarAccessCreate */
export type IOnboardingDocumentEdgarAccessCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  document: IOnboardingDocumentEdgarAccessInput;
  offeringSlug: Scalars['String']['input'];
};

/** Autogenerated return type of OnboardingDocumentEdgarAccessCreate. */
export type IOnboardingDocumentEdgarAccessCreatePayload = {
  __typename?: 'OnboardingDocumentEdgarAccessCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  document?: Maybe<IOnboardingDocumentEdgarAccess>;
  errors?: Maybe<Array<IValidationError>>;
};

/** An edge in a connection. */
export type IOnboardingDocumentEdgarAccessEdge = {
  __typename?: 'OnboardingDocumentEdgarAccessEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: IOnboardingDocumentEdgarAccess;
};

export type IOnboardingDocumentEdgarAccessInput = {
  directUploadAttachmentUuid: Scalars['String']['input'];
  documentType: IOnboardingDocumentDocumentTypeEnum;
};

/** Autogenerated input type of OnboardingDocumentEdgarAccessUpdate */
export type IOnboardingDocumentEdgarAccessUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  document: IOnboardingDocumentEdgarAccessInput;
  documentId: Scalars['ID']['input'];
};

/** Autogenerated return type of OnboardingDocumentEdgarAccessUpdate. */
export type IOnboardingDocumentEdgarAccessUpdatePayload = {
  __typename?: 'OnboardingDocumentEdgarAccessUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  document?: Maybe<IOnboardingDocumentEdgarAccess>;
  errors?: Maybe<Array<IValidationError>>;
};

export enum IOnboardingEdgarAccessFlowEnum {
  New = 'NEW',
  Provide = 'PROVIDE',
  Regenerate = 'REGENERATE',
}

/** Autogenerated input type of OnboardingEdgarAccessSelectCikFlow */
export type IOnboardingEdgarAccessSelectCikFlowInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  flow: IOnboardingEdgarAccessFlowEnum;
  offeringSlug: Scalars['String']['input'];
};

/** Autogenerated return type of OnboardingEdgarAccessSelectCikFlow. */
export type IOnboardingEdgarAccessSelectCikFlowPayload = {
  __typename?: 'OnboardingEdgarAccessSelectCikFlowPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Autogenerated input type of OnboardingFormCRequestAttorney */
export type IOnboardingFormCRequestAttorneyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  offeringSlug: Scalars['String']['input'];
};

/** Autogenerated return type of OnboardingFormCRequestAttorney. */
export type IOnboardingFormCRequestAttorneyPayload = {
  __typename?: 'OnboardingFormCRequestAttorneyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  offering?: Maybe<IOffering>;
};

/** Onboarding members */
export type IOnboardingMember = INode & {
  __typename?: 'OnboardingMember';
  acceptedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  accesses: Scalars['JSON']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  discardedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  email: Scalars['Email']['output'];
  founder: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isPrimaryContact: Scalars['Boolean']['output'];
  position: Scalars['String']['output'];
  subscriptions: Scalars['JSON']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user?: Maybe<IUser>;
};

/** The connection type for OnboardingMember. */
export type IOnboardingMemberConnection = {
  __typename?: 'OnboardingMemberConnection';
  /** A list of edges. */
  edges: Array<IOnboardingMemberEdge>;
  /** A list of nodes. */
  nodes: Array<IOnboardingMember>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Autogenerated input type of OnboardingMemberCreate */
export type IOnboardingMemberCreateInput = {
  accesses: Scalars['JSON']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['Email']['input'];
  offeringSlug: Scalars['String']['input'];
  position: Scalars['String']['input'];
  subscriptions: Scalars['JSON']['input'];
};

/** Autogenerated return type of OnboardingMemberCreate. */
export type IOnboardingMemberCreatePayload = {
  __typename?: 'OnboardingMemberCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  member?: Maybe<IOnboardingMember>;
};

/** Autogenerated input type of OnboardingMemberDelete */
export type IOnboardingMemberDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of OnboardingMemberDelete. */
export type IOnboardingMemberDeletePayload = {
  __typename?: 'OnboardingMemberDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  member?: Maybe<IOnboardingMember>;
};

/** An edge in a connection. */
export type IOnboardingMemberEdge = {
  __typename?: 'OnboardingMemberEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: IOnboardingMember;
};

export type IOnboardingMemberUpdateInput = {
  accesses?: InputMaybe<Scalars['JSON']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isPrimaryContact?: InputMaybe<Scalars['Boolean']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  subscriptions?: InputMaybe<Scalars['JSON']['input']>;
};

/** Autogenerated return type of OnboardingMemberUpdate. */
export type IOnboardingMemberUpdatePayload = {
  __typename?: 'OnboardingMemberUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  member?: Maybe<IOnboardingMember>;
};

/** Onboarding required action type */
export type IOnboardingOverviewRequiredAction = INode & {
  __typename?: 'OnboardingOverviewRequiredAction';
  /** SLUG of corresponding action list issiu */
  actionListIssueSlug?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /** Texted duration */
  duration?: Maybe<Scalars['String']['output']>;
  /** ID of the object. */
  id: Scalars['ID']['output'];
  /** link to section */
  link?: Maybe<Scalars['String']['output']>;
  /** Priority of the action */
  priority: Scalars['Int']['output'];
  /** SLUG of corresponding progress */
  progressSlug: Scalars['String']['output'];
  /** Name of the action */
  title: Scalars['String']['output'];
};

/** Autogenerated input type of OnboardingOverviewTargetLaunchDateUpdate */
export type IOnboardingOverviewTargetLaunchDateUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  offeringSlug: Scalars['String']['input'];
  targetLaunchDate: Scalars['String']['input'];
};

/** Autogenerated return type of OnboardingOverviewTargetLaunchDateUpdate. */
export type IOnboardingOverviewTargetLaunchDateUpdatePayload = {
  __typename?: 'OnboardingOverviewTargetLaunchDateUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  offering?: Maybe<IOffering>;
};

/** Onboarding Timeline item */
export type IOnboardingOverviewTimeline = {
  __typename?: 'OnboardingOverviewTimeline';
  endDate: Scalars['ISO8601Date']['output'];
  startDate: Scalars['ISO8601Date']['output'];
  state: IOnboardingSidebarSectionStateEnum;
  title: Scalars['String']['output'];
};

/** Autogenerated input type of OnboardingPostInvestmentMessageUpdate */
export type IOnboardingPostInvestmentMessageUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  message: Scalars['String']['input'];
  offeringSlug: Scalars['String']['input'];
};

/** Autogenerated return type of OnboardingPostInvestmentMessageUpdate. */
export type IOnboardingPostInvestmentMessageUpdatePayload = {
  __typename?: 'OnboardingPostInvestmentMessageUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  offering?: Maybe<IOffering>;
};

/** Autogenerated input type of OnboardingReservationCampaignRequestTtw */
export type IOnboardingReservationCampaignRequestTtwInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  offeringSlug: Scalars['String']['input'];
};

/** Autogenerated return type of OnboardingReservationCampaignRequestTtw. */
export type IOnboardingReservationCampaignRequestTtwPayload = {
  __typename?: 'OnboardingReservationCampaignRequestTtwPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  offering?: Maybe<IOffering>;
};

/** Autogenerated input type of OnboardingReservationCampaignUpdatePostMessage */
export type IOnboardingReservationCampaignUpdatePostMessageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  message: Scalars['String']['input'];
  offeringSlug: Scalars['String']['input'];
};

/** Autogenerated return type of OnboardingReservationCampaignUpdatePostMessage. */
export type IOnboardingReservationCampaignUpdatePostMessagePayload = {
  __typename?: 'OnboardingReservationCampaignUpdatePostMessagePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  offering?: Maybe<IOffering>;
};

/** Autogenerated input type of OnboardingRiskPublishRisks */
export type IOnboardingRiskPublishRisksInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  offeringSlug: Scalars['String']['input'];
};

/** Autogenerated return type of OnboardingRiskPublishRisks. */
export type IOnboardingRiskPublishRisksPayload = {
  __typename?: 'OnboardingRiskPublishRisksPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  offering?: Maybe<IOffering>;
};

/** Autogenerated input type of OnboardingRiskUpdateFormCRisks */
export type IOnboardingRiskUpdateFormCRisksInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  offeringSlug: Scalars['String']['input'];
};

/** Autogenerated return type of OnboardingRiskUpdateFormCRisks. */
export type IOnboardingRiskUpdateFormCRisksPayload = {
  __typename?: 'OnboardingRiskUpdateFormCRisksPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  risks?: Maybe<Array<IOfferingRisk>>;
};

/** Autogenerated input type of OnboardingRiskUpdateRisks */
export type IOnboardingRiskUpdateRisksInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  offeringSlug: Scalars['String']['input'];
  risks: Array<IOfferingRiskInput>;
};

/** Autogenerated return type of OnboardingRiskUpdateRisks. */
export type IOnboardingRiskUpdateRisksPayload = {
  __typename?: 'OnboardingRiskUpdateRisksPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  risks?: Maybe<Array<IOfferingRisk>>;
};

/** Checklist for section in onboarding */
export type IOnboardingSectionChecklist = INode & {
  __typename?: 'OnboardingSectionChecklist';
  descriptionText?: Maybe<Scalars['String']['output']>;
  estimationText?: Maybe<Scalars['String']['output']>;
  /** ID of the object. */
  id: Scalars['ID']['output'];
  link: Scalars['Url']['output'];
  name: Scalars['String']['output'];
  nameEnum: IOnboardingSidebarNameEnum;
  status: IOnboardingSectionStatusEnum;
  /** Tooltip information */
  tooltipData?: Maybe<IOnboardingSidebarSectionTooltipData>;
};

export enum IOnboardingSectionChecklistEnum {
  FormC = 'FORM_C',
  TestingTheWaters = 'TESTING_THE_WATERS',
}

export enum IOnboardingSectionStatusEnum {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  Locked = 'LOCKED',
  Read = 'READ',
  Todo = 'TODO',
}

export enum IOnboardingSidebarNameEnum {
  AchAccount = 'ACH_ACCOUNT',
  AdminDashboard = 'ADMIN_DASHBOARD',
  AirdropActionPoints = 'AIRDROP_ACTION_POINTS',
  AttorneyReview = 'ATTORNEY_REVIEW',
  BuildPage = 'BUILD_PAGE',
  CampaignAgreement = 'CAMPAIGN_AGREEMENT',
  CardPayments = 'CARD_PAYMENTS',
  Cik = 'CIK',
  CompanyInformation = 'COMPANY_INFORMATION',
  CpaReview = 'CPA_REVIEW',
  DealPage = 'DEAL_PAGE',
  DealTerms = 'DEAL_TERMS',
  Financials = 'FINANCIALS',
  FinancialLegalSummary = 'FINANCIAL_LEGAL_SUMMARY',
  FormC = 'FORM_C',
  GaapConversion = 'GAAP_CONVERSION',
  Legal = 'LEGAL',
  ManageCampaign = 'MANAGE_CAMPAIGN',
  MarketingGuide = 'MARKETING_GUIDE',
  MinInvestmentAmount = 'MIN_INVESTMENT_AMOUNT',
  Overview = 'OVERVIEW',
  Perks = 'PERKS',
  PostInvestmentMessage = 'POST_INVESTMENT_MESSAGE',
  PreLaunch = 'PRE_LAUNCH',
  Risks = 'RISKS',
  Shareholders = 'SHAREHOLDERS',
  Slack = 'SLACK',
  StrategyGuide = 'STRATEGY_GUIDE',
  Team = 'TEAM',
  TestingTheWaters = 'TESTING_THE_WATERS',
  TestingTheWatersPostInvestmentMessage = 'TESTING_THE_WATERS_POST_INVESTMENT_MESSAGE',
  TrackingPixel = 'TRACKING_PIXEL',
  Video = 'VIDEO',
  WebsiteBanner = 'WEBSITE_BANNER',
}

/** Onboarding sidebar section */
export type IOnboardingSidebarSection = INode & {
  __typename?: 'OnboardingSidebarSection';
  /** Slug for ActionList::Issue */
  actionListIssueSlug?: Maybe<Scalars['String']['output']>;
  /** ID of the object. */
  id: Scalars['ID']['output'];
  /** @deprecated Use new structure */
  items: Array<IOnboardingSidebarSectionItem>;
  /** Name of the section */
  label: Scalars['String']['output'];
  /** URL where to lead by click */
  link?: Maybe<Scalars['Url']['output']>;
  /** @deprecated Use new structure */
  sectionLabel?: Maybe<Scalars['String']['output']>;
  /** URL where to lead by click */
  state?: Maybe<IOnboardingSidebarSectionStateEnum>;
  /** Tooltip information */
  tooltipData?: Maybe<IOnboardingSidebarSectionTooltipData>;
  /** Type of section */
  type: IOnboardingSidebarSectionTypeEnum;
};

/** Onboarding sidebar item */
export type IOnboardingSidebarSectionItem = INode & {
  __typename?: 'OnboardingSidebarSectionItem';
  /** ID of the object. */
  id: Scalars['ID']['output'];
  /** true -> completed; false -> unlocked, nil -> locked */
  isComplete?: Maybe<Scalars['Boolean']['output']>;
  label: Scalars['String']['output'];
  link: Scalars['Url']['output'];
  /** @deprecated Not in use for new onboarding */
  tooltip?: Maybe<Scalars['String']['output']>;
  /** List of actions to unlock section */
  tooltipActions?: Maybe<Array<Scalars['String']['output']>>;
  type: IOnboardingSidebarSectionTypeEnum;
};

export enum IOnboardingSidebarSectionStateEnum {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  Locked = 'LOCKED',
  Read = 'READ',
  Todo = 'TODO',
}

/** Onboarding sidebar tooltip information */
export type IOnboardingSidebarSectionTooltipData = INode & {
  __typename?: 'OnboardingSidebarSectionTooltipData';
  /** ID of the object. */
  id: Scalars['ID']['output'];
  /** Dynamic list of actions for display */
  list?: Maybe<Array<Scalars['String']['output']>>;
  /** Static text for display */
  text?: Maybe<Scalars['String']['output']>;
};

export enum IOnboardingSidebarSectionTypeEnum {
  Check = 'CHECK',
  Doc = 'DOC',
  Link = 'LINK',
  Lock = 'LOCK',
}

/** Autogenerated input type of OnboardingTrackingPixelUpdate */
export type IOnboardingTrackingPixelUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  facebookPixelId?: InputMaybe<Scalars['String']['input']>;
  googleAnalyticsId?: InputMaybe<Scalars['String']['input']>;
  /** Offering slug to update */
  offeringSlug: Scalars['String']['input'];
  twitterPixelId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OnboardingTrackingPixelUpdate. */
export type IOnboardingTrackingPixelUpdatePayload = {
  __typename?: 'OnboardingTrackingPixelUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  offering?: Maybe<IOffering>;
};

export type IOpportunityConnection = {
  __typename?: 'OpportunityConnection';
  previousCount: Scalars['Int']['output'];
  currentCount: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  edges: Array<IOpportunityTypeEdge>;
  pageInfo: IPageInfo;
};

export type IOpportunityDetails = {
  __typename?: 'OpportunityDetails';
  id: Scalars['BigInt']['output'];
  netFairMarketValueCents: Scalars['BigInt']['output'];
  /** Multiple value of an opportunity. Note: rounded to 1 decimal place by request */
  multiple: Scalars['Float']['output'];
  /** Amount contributed to a fund. Only applies to MAF Opportunities */
  contributionAmountCents?: Maybe<Scalars['BigInt']['output']>;
  /** For MAF Investments, this is the total amount committed for contribution */
  totalCommittedCents?: Maybe<Scalars['BigInt']['output']>;
  xStage: IStage;
  investedAmountCents: Scalars['BigInt']['output'];
  fundType: Scalars['String']['output'];
  investmentDate: Scalars['ISO8601DateTime']['output'];
  instrument: IInstrumentType;
  entity: IInvestmentEntityType;
  distributionCents: Scalars['BigInt']['output'];
  dealManagementFee: Scalars['Float']['output'];
  dealCarryPercent: Scalars['Float']['output'];
  /** This field comes from the Investment Instrument associated with the Opportunity */
  name: Scalars['String']['output'];
  /** This field comes from the Investment Instrument associated with the Opportunity */
  lastMarkupDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** @deprecated Changing to Enum -- Use x_stage instead */
  stage: Scalars['String']['output'];
  /**
   * MOIC of an opportunity. Note: rounded to 1 decimal place by request
   * @deprecated Salesforce has changed this field to multiple
   */
  moic: Scalars['Float']['output'];
  investorSubscriptionDocs: Array<ISubscriptionDocumentType>;
  investorTaxDocs: Array<ITaxDocumentType>;
};

/** Sorting order for Republic Capital investment entities. */
export enum IOpportunityOrder {
  CloseDateAsc = 'CLOSE_DATE_ASC',
  CloseDateDesc = 'CLOSE_DATE_DESC',
}

export type IOpportunityType = {
  __typename?: 'OpportunityType';
  id: Scalars['BigInt']['output'];
  netFairMarketValueCents: Scalars['BigInt']['output'];
  /** Multiple value of an opportunity. Note: rounded to 1 decimal place by request */
  multiple: Scalars['Float']['output'];
  /** Amount contributed to a fund. Only applies to MAF Opportunities */
  contributionAmountCents?: Maybe<Scalars['BigInt']['output']>;
  /** For MAF Investments, this is the total amount committed for contribution */
  totalCommittedCents?: Maybe<Scalars['BigInt']['output']>;
  xStage: IStage;
  investedAmountCents: Scalars['BigInt']['output'];
  fundType: Scalars['String']['output'];
  investmentDate: Scalars['ISO8601DateTime']['output'];
  instrument: IInstrumentType;
  entity: IInvestmentEntityType;
  distributionCents: Scalars['BigInt']['output'];
  dealManagementFee: Scalars['Float']['output'];
  dealCarryPercent: Scalars['Float']['output'];
  /** This field comes from the Investment Instrument associated with the Opportunity */
  name: Scalars['String']['output'];
  /** This field comes from the Investment Instrument associated with the Opportunity */
  lastMarkupDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** @deprecated Changing to Enum -- Use x_stage instead */
  stage: Scalars['String']['output'];
  /**
   * MOIC of an opportunity. Note: rounded to 1 decimal place by request
   * @deprecated Salesforce has changed this field to multiple
   */
  moic: Scalars['Float']['output'];
};

export type IOpportunityTypeEdge = {
  __typename?: 'OpportunityTypeEdge';
  cursor: Scalars['String']['output'];
  node: IOpportunityType;
};

export type IOutwardNotificationsCategoryInput = {
  emailPreference?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<IUserOutwardNotificationCategoryEnum>;
  pushNotificationsPreference?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated input type of OutwardNotificationsCategoryUpdate */
export type IOutwardNotificationsCategoryUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  outwardNotificationsCategory: IOutwardNotificationsCategoryInput;
};

/** Autogenerated return type of OutwardNotificationsCategoryUpdate. */
export type IOutwardNotificationsCategoryUpdatePayload = {
  __typename?: 'OutwardNotificationsCategoryUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  outwardNotificationsCategory?: Maybe<IUserOutwardNotificationsCategory>;
};

/** The record for which the privilege applies */
export type IOwnerUnion = IIssuerProfile;

/** Information about pagination in a connection. */
export type IPageInfo = {
  __typename?: 'PageInfo';
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
};

export type IPaymentMethod = {
  __typename?: 'PaymentMethod';
  id: Scalars['ID']['output'];
  source: IPaymentMethodSource;
  uuid: Scalars['String']['output'];
};

export type IPaymentMethodAch = IPaymentMethodInterface & {
  __typename?: 'PaymentMethodAch';
  achAccount?: Maybe<IPaymentMethodAchAccount>;
  achAccountName?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use achAccount fields */
  achAccountType?: Maybe<Scalars['String']['output']>;
  bankName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  failureMessage?: Maybe<Scalars['String']['output']>;
  hasActiveOrders: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated Use lastPaymentFailed */
  isLastPaymentFailed: Scalars['Boolean']['output'];
  /** @deprecated Use lastFour instead */
  last4?: Maybe<Scalars['String']['output']>;
  lastFour?: Maybe<Scalars['String']['output']>;
  lastPaymentFailed: Scalars['Boolean']['output'];
  type: IPaymentMethodTypeEnum;
};

export type IPaymentMethodAchAccount = {
  __typename?: 'PaymentMethodAchAccount';
  accountNumberLastFour?: Maybe<Scalars['String']['output']>;
  accountType?: Maybe<Scalars['String']['output']>;
  bankName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastPaymentError?: Maybe<Scalars['String']['output']>;
  routingNumber: Scalars['String']['output'];
};

export type IPaymentMethodAchInput = {
  /** New account number */
  accountNumber?: InputMaybe<Scalars['String']['input']>;
  /** New account type */
  accountType?: InputMaybe<Scalars['String']['input']>;
  /** Existing payment method ID, can be left blank for a create action */
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
  /** New routing number */
  routingNumber?: InputMaybe<Scalars['String']['input']>;
};

export type IPaymentMethodAltoAccount = {
  __typename?: 'PaymentMethodAltoAccount';
  altoUser: IPaymentMethodAltoUser;
};

export type IPaymentMethodAltoIra = IPaymentMethodInterface & {
  __typename?: 'PaymentMethodAltoIra';
  altoAccount: IPaymentMethodAltoAccount;
  createdAt: Scalars['ISO8601DateTime']['output'];
  failureMessage?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** @deprecated Use lastPaymentFailed */
  isLastPaymentFailed: Scalars['Boolean']['output'];
  lastPaymentFailed: Scalars['Boolean']['output'];
  type: IPaymentMethodTypeEnum;
};

export type IPaymentMethodAltoIraInput = {
  oauthCode?: InputMaybe<Scalars['String']['input']>;
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
};

export type IPaymentMethodAltoUser = {
  __typename?: 'PaymentMethodAltoUser';
  accounts: Array<IPaymentMethodAltoUserAccount>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
};

export type IPaymentMethodAltoUserAccount = {
  __typename?: 'PaymentMethodAltoUserAccount';
  accountType: Scalars['String']['output'];
  altoAccountNumber: Scalars['Int']['output'];
  authorizedSigner: Scalars['String']['output'];
  emailAddress: Scalars['String']['output'];
  investingEntityName: Scalars['String']['output'];
  iraAddress: Scalars['String']['output'];
  iraEin: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
};

export type IPaymentMethodApiKeys = {
  __typename?: 'PaymentMethodApiKeys';
  altoBaseUrl: Scalars['String']['output'];
  altoClientId: Scalars['String']['output'];
  plaidEnv: Scalars['String']['output'];
  plaidPublicKey: Scalars['String']['output'];
  stripePublishableKey: Scalars['String']['output'];
  stripeScriptUrl: Scalars['String']['output'];
};

export type IPaymentMethodBitpay = IPaymentMethodInterface & {
  __typename?: 'PaymentMethodBitpay';
  createdAt: Scalars['ISO8601DateTime']['output'];
  failureMessage?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** @deprecated Use lastPaymentFailed */
  isLastPaymentFailed: Scalars['Boolean']['output'];
  lastPaymentFailed: Scalars['Boolean']['output'];
  type: IPaymentMethodTypeEnum;
};

export type IPaymentMethodCard = IPaymentMethodInterface & {
  __typename?: 'PaymentMethodCard';
  cardBrand: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  failureMessage?: Maybe<Scalars['String']['output']>;
  funding: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated Use lastPaymentFailed */
  isLastPaymentFailed: Scalars['Boolean']['output'];
  lastFour?: Maybe<Scalars['String']['output']>;
  lastPaymentFailed: Scalars['Boolean']['output'];
  /** @deprecated Use xLogoUrl */
  logo2xUrl?: Maybe<Scalars['Url']['output']>;
  /** @deprecated Use xLogoUrl */
  logoUrl?: Maybe<Scalars['Url']['output']>;
  /** @deprecated Use xLogoUrl */
  pngLogoUrl?: Maybe<Scalars['Url']['output']>;
  type: IPaymentMethodTypeEnum;
  xLogoUrl?: Maybe<Scalars['Url']['output']>;
  xLogoUrlContentType?: Maybe<Scalars['String']['output']>;
  xLogoUrlFileName?: Maybe<Scalars['String']['output']>;
  xLogoUrlFileSize?: Maybe<Scalars['BigInt']['output']>;
  xLogoUrlUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export type IPaymentMethodCardXLogoUrlArgs = {
  extension?: InputMaybe<IFileProcessingImgproxyExtensionEnum>;
  gravity?: InputMaybe<IFileProcessingImgproxyGravityEnum>;
  resize?: InputMaybe<IFileProcessingImgproxyResizeInput>;
};

export type IPaymentMethodCardInput = {
  offeringSlug?: InputMaybe<Scalars['String']['input']>;
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of PaymentMethodCreateAch */
export type IPaymentMethodCreateAchInput = {
  /** New account number */
  accountNumber?: InputMaybe<Scalars['String']['input']>;
  /** New account type */
  accountType?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Existing payment method ID, can be left blank for a create action */
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
  /** New routing number */
  routingNumber?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of PaymentMethodCreateAch. */
export type IPaymentMethodCreateAchPayload = {
  __typename?: 'PaymentMethodCreateAchPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  paymentMethod?: Maybe<IPaymentMethodAch>;
};

/** Autogenerated input type of PaymentMethodCreateAltoIra */
export type IPaymentMethodCreateAltoIraInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  oauthCode: Scalars['String']['input'];
};

/** Autogenerated return type of PaymentMethodCreateAltoIra. */
export type IPaymentMethodCreateAltoIraPayload = {
  __typename?: 'PaymentMethodCreateAltoIraPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  paymentMethod?: Maybe<IPaymentMethodAltoIra>;
};

/** Autogenerated input type of PaymentMethodCreateBitpay */
export type IPaymentMethodCreateBitpayInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of PaymentMethodCreateBitpay. */
export type IPaymentMethodCreateBitpayPayload = {
  __typename?: 'PaymentMethodCreateBitpayPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  paymentMethod?: Maybe<IPaymentMethodBitpay>;
};

/** Autogenerated input type of PaymentMethodCreateCard */
export type IPaymentMethodCreateCardInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  offeringSlug?: InputMaybe<Scalars['String']['input']>;
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of PaymentMethodCreateCard. */
export type IPaymentMethodCreateCardPayload = {
  __typename?: 'PaymentMethodCreateCardPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  paymentMethod?: Maybe<IPaymentMethodCard>;
};

/** Autogenerated input type of PaymentMethodCreateWire */
export type IPaymentMethodCreateWireInput = {
  achAccountRefund?: InputMaybe<IPaymentMethodAchInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of PaymentMethodCreateWire. */
export type IPaymentMethodCreateWirePayload = {
  __typename?: 'PaymentMethodCreateWirePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  paymentMethod?: Maybe<IPaymentMethodWire>;
};

export type IPaymentMethodCryptoTransfer = IPaymentMethodInterface & {
  __typename?: 'PaymentMethodCryptoTransfer';
  createdAt: Scalars['ISO8601DateTime']['output'];
  failureMessage?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** @deprecated Use lastPaymentFailed */
  isLastPaymentFailed: Scalars['Boolean']['output'];
  lastPaymentFailed: Scalars['Boolean']['output'];
  type: IPaymentMethodTypeEnum;
};

/** Autogenerated input type of PaymentMethodDelete */
export type IPaymentMethodDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Payment method ID */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of PaymentMethodDelete. */
export type IPaymentMethodDeletePayload = {
  __typename?: 'PaymentMethodDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  paymentMethod?: Maybe<IPaymentMethodUnion>;
};

export type IPaymentMethodInterface = {
  createdAt: Scalars['ISO8601DateTime']['output'];
  failureMessage?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** @deprecated Use lastPaymentFailed */
  isLastPaymentFailed: Scalars['Boolean']['output'];
  lastPaymentFailed: Scalars['Boolean']['output'];
  type: IPaymentMethodTypeEnum;
};

export type IPaymentMethodServiceProviderCredit = IPaymentMethodInterface & {
  __typename?: 'PaymentMethodServiceProviderCredit';
  createdAt: Scalars['ISO8601DateTime']['output'];
  failureMessage?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** @deprecated Use lastPaymentFailed */
  isLastPaymentFailed: Scalars['Boolean']['output'];
  lastPaymentFailed: Scalars['Boolean']['output'];
  type: IPaymentMethodTypeEnum;
};

export enum IPaymentMethodSource {
  AchAccount = 'ACH_ACCOUNT',
  AltoAccount = 'ALTO_ACCOUNT',
  BitpayInvestorAccount = 'BITPAY_INVESTOR_ACCOUNT',
  ServiceProviderCredit = 'SERVICE_PROVIDER_CREDIT',
  StripeCard = 'STRIPE_CARD',
  WireAccount = 'WIRE_ACCOUNT',
}

export enum IPaymentMethodTypeEnum {
  Ach = 'ACH',
  Alto = 'ALTO',
  AndroidPay = 'ANDROID_PAY',
  ApplePay = 'APPLE_PAY',
  Bitpay = 'BITPAY',
  Card = 'CARD',
  CryptoTransfer = 'CRYPTO_TRANSFER',
  PayLater = 'PAY_LATER',
  ServiceProviderCredit = 'SERVICE_PROVIDER_CREDIT',
  Wire = 'WIRE',
}

/** Possible types of payment methods */
export type IPaymentMethodUnion =
  | IPaymentMethodAch
  | IPaymentMethodAltoIra
  | IPaymentMethodBitpay
  | IPaymentMethodCard
  | IPaymentMethodCryptoTransfer
  | IPaymentMethodServiceProviderCredit
  | IPaymentMethodWire;

/** The connection type for PaymentMethodUnion. */
export type IPaymentMethodUnionConnection = {
  __typename?: 'PaymentMethodUnionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IPaymentMethodUnionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<IPaymentMethodUnion>>>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
};

/** An edge in a connection. */
export type IPaymentMethodUnionEdge = {
  __typename?: 'PaymentMethodUnionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<IPaymentMethodUnion>;
};

export type IPaymentMethodWire = IPaymentMethodInterface & {
  __typename?: 'PaymentMethodWire';
  createdAt: Scalars['ISO8601DateTime']['output'];
  failureMessage?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** @deprecated Use lastPaymentFailed */
  isLastPaymentFailed: Scalars['Boolean']['output'];
  lastPaymentFailed: Scalars['Boolean']['output'];
  type: IPaymentMethodTypeEnum;
};

export type IPaymentMethodWireInput = {
  achAccountRefund?: InputMaybe<IPaymentMethodAchInput>;
};

export type IPaymentOption = {
  __typename?: 'PaymentOption';
  /** The minimum investment amount for this payment option */
  minimumInvestment: IAmount;
  /** The type of the payment option */
  type: IInvestmentPaymentOptionEnum;
};

export type IPlaceholderDoNotUseInput = {
  state: Scalars['String']['input'];
};

export type IPlaceholderDoNotUsePayload = IBasePayloadType & {
  __typename?: 'PlaceholderDoNotUsePayload';
  errors?: Maybe<Array<IValidationError>>;
  id: Scalars['Int']['output'];
  state: Scalars['String']['output'];
  deadline: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of PlaidIntegrationCreate */
export type IPlaidIntegrationCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  publicToken: Scalars['String']['input'];
  userSlug?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of PlaidIntegrationCreate. */
export type IPlaidIntegrationCreatePayload = {
  __typename?: 'PlaidIntegrationCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  paymentMethod?: Maybe<Array<IPaymentMethodAch>>;
  paymentMethods?: Maybe<Array<IPaymentMethodAch>>;
};

/** Details of an offering's asset */
export type IPortfolioAsset = IAssetInterface & {
  __typename?: 'PortfolioAsset';
  assetType?: Maybe<IAssetTypeEnum>;
  assetYear: Scalars['Int']['output'];
  availableForTradeUnits?: Maybe<Scalars['BigInt']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /** Discount offered in the Safe agreement rounded to 4 decimal points eg. 0.25 means 25% */
  discountPercentage: Scalars['BigDecimal']['output'];
  displayName?: Maybe<Scalars['String']['output']>;
  /** @deprecated This can be found using value_change_ratio on asset */
  estimatedValuePerUnitCents?: Maybe<Scalars['BigInt']['output']>;
  /** These are active holdings in a user's portfolio */
  holdings?: Maybe<Array<IHolding>>;
  id: Scalars['ID']['output'];
  issuerProfile?: Maybe<IIssuerProfile>;
  listedForTradeUnits?: Maybe<Scalars['BigInt']['output']>;
  offering: IOffering;
  symbol?: Maybe<Scalars['String']['output']>;
  /** Current secondary market tradability status of the asset */
  tradability: ISecondaryTradabilityEnum;
  tradable: Scalars['Boolean']['output'];
  userCrowdSafes: Array<IInvestorCrowdSafe>;
  userHeldUnits?: Maybe<Scalars['BigInt']['output']>;
  userHeldValueCents?: Maybe<Scalars['BigInt']['output']>;
  /** How much a user has spent to acquire all holdings of this asset */
  userPurchaseCostBasis?: Maybe<Scalars['BigInt']['output']>;
  userReturnOnInvestmentCents?: Maybe<Scalars['BigInt']['output']>;
  valuationCapCents?: Maybe<Scalars['BigInt']['output']>;
  valueChangeRatio?: Maybe<Scalars['BigDecimal']['output']>;
};

/** Aggregate portfolio figures for a republic capital investor */
export type IPortfolioType = {
  __typename?: 'PortfolioType';
  id: Scalars['BigInt']['output'];
  /** The value of this field is affected by the Investment Entity filter */
  portfolioValueCents: Scalars['BigInt']['output'];
  /** Account name */
  accountName: Scalars['String']['output'];
  /** The value of this field is affected by the Investment Entity filter */
  portfolioRealizedValueCents?: Maybe<Scalars['BigInt']['output']>;
  /** The value of this field is affected by the Investment Entity filter */
  portfolioUnrealizedValueCents?: Maybe<Scalars['BigInt']['output']>;
  /** The value of this field is affected by the Investment Entity filter */
  capitalInvestedCents: Scalars['BigInt']['output'];
  investmentCount: Scalars['Int']['output'];
};

export type IPost = IPostInterface & {
  __typename?: 'Post';
  affiliation?: Maybe<Scalars['String']['output']>;
  amountInvested?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use xApprovedAt instead */
  approvedAt: Scalars['ISO8601DateTime']['output'];
  body: Scalars['String']['output'];
  comments?: Maybe<Array<IComment>>;
  commentsCount: Scalars['Int']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  deletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  downvoted: Scalars['Boolean']['output'];
  flaggedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  headline?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  markedAsSpamByUserId?: Maybe<Scalars['Int']['output']>;
  markedHiddenByUserId?: Maybe<Scalars['Int']['output']>;
  ownerId: Scalars['ID']['output'];
  ownerType: Scalars['String']['output'];
  pinned: Scalars['Boolean']['output'];
  pinnedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  pinnedBy?: Maybe<IUser>;
  rating: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  spam?: Maybe<Scalars['Boolean']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  upvoted: Scalars['Boolean']['output'];
  user: IUser;
  /** Permitted actions for post */
  userPermissions?: Maybe<IInvestorGroupPostUserPermissions>;
  xApprovedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export type IPostInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Information for a post/comment type object */
export type IPostInterface = {
  affiliation?: Maybe<Scalars['String']['output']>;
  amountInvested?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use xApprovedAt instead */
  approvedAt: Scalars['ISO8601DateTime']['output'];
  body: Scalars['String']['output'];
  comments?: Maybe<Array<IComment>>;
  commentsCount: Scalars['Int']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  deletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  downvoted: Scalars['Boolean']['output'];
  flaggedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  headline?: Maybe<Scalars['String']['output']>;
  markedAsSpamByUserId?: Maybe<Scalars['Int']['output']>;
  markedHiddenByUserId?: Maybe<Scalars['Int']['output']>;
  ownerId: Scalars['ID']['output'];
  ownerType: Scalars['String']['output'];
  pinned: Scalars['Boolean']['output'];
  pinnedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  rating: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  spam?: Maybe<Scalars['Boolean']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  upvoted: Scalars['Boolean']['output'];
  user: IUser;
  xApprovedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/** Possible types of posts */
export type IPostUnion = IGroupDealPost | IPost;

/** The connection type for PostUnion. */
export type IPostUnionConnection = {
  __typename?: 'PostUnionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IPostUnionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<IPostUnion>>>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
};

/** An edge in a connection. */
export type IPostUnionEdge = {
  __typename?: 'PostUnionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<IPostUnion>;
};

export type IPresignedFileUpload = {
  __typename?: 'PresignedFileUpload';
  fileName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  originalFileName?: Maybe<Scalars['String']['output']>;
  publicFileUrl?: Maybe<Scalars['String']['output']>;
  uploadUrl?: Maybe<Scalars['String']['output']>;
  uploadUrlExpiresAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/** Autogenerated input type of PressReleasesCreate */
export type IPressReleasesCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  press: IPressReleasesPressCreateInput;
};

/** Autogenerated return type of PressReleasesCreate. */
export type IPressReleasesCreatePayload = {
  __typename?: 'PressReleasesCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  press?: Maybe<IPressReleasesPress>;
};

/** Autogenerated input type of PressReleasesDelete */
export type IPressReleasesDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of PressReleasesDelete. */
export type IPressReleasesDeletePayload = {
  __typename?: 'PressReleasesDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

export type IPressReleasesInterface = {
  pressReleases: Array<IPressReleasesPress>;
};

export type IPressReleasesMutations = {
  /** Create Press article object of Press Releases domain */
  pressReleasesCreate?: Maybe<IPressReleasesCreatePayload>;
  /** Delete Press article object of Press Releases domain by id */
  pressReleasesDelete?: Maybe<IPressReleasesDeletePayload>;
  /** Publish Press article object of Press Releases domain by id */
  pressReleasesPublish?: Maybe<IPressReleasesPublishPayload>;
  /** Unpublish Press article object of Press Releases domain by id */
  pressReleasesUnpublish?: Maybe<IPressReleasesUnpublishPayload>;
  /** Update Press article object of Press Releases domain */
  pressReleasesUpdate?: Maybe<IPressReleasesUpdatePayload>;
};

export type IPressReleasesMutationsPressReleasesCreateArgs = {
  input: IPressReleasesCreateInput;
};

export type IPressReleasesMutationsPressReleasesDeleteArgs = {
  input: IPressReleasesDeleteInput;
};

export type IPressReleasesMutationsPressReleasesPublishArgs = {
  input: IPressReleasesPublishInput;
};

export type IPressReleasesMutationsPressReleasesUnpublishArgs = {
  input: IPressReleasesUnpublishInput;
};

export type IPressReleasesMutationsPressReleasesUpdateArgs = {
  input: IPressReleasesUpdateInput;
};

/** List of available orders for Press Index query of Press Releases domain */
export enum IPressReleasesOrderEnum {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
}

/** Press object of Press Releases domain */
export type IPressReleasesPress = {
  __typename?: 'PressReleasesPress';
  content?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** @deprecated Use xImageUrl instead */
  imageUrl?: Maybe<Scalars['String']['output']>;
  ownerId: Scalars['ID']['output'];
  ownerType: Scalars['String']['output'];
  providerName?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use xTitle instead */
  title: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  url: Scalars['Url']['output'];
  urlPublishedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Permitted actions for press articles */
  userPermissions: IPressReleasesUserPermissions;
  xImageUrl?: Maybe<Scalars['Url']['output']>;
  xImageUrlContentType?: Maybe<Scalars['String']['output']>;
  xImageUrlFileName?: Maybe<Scalars['String']['output']>;
  xImageUrlFileSize?: Maybe<Scalars['BigInt']['output']>;
  xImageUrlUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  xTitle?: Maybe<Scalars['String']['output']>;
};

/** Press object of Press Releases domain */
export type IPressReleasesPressXImageUrlArgs = {
  extension?: InputMaybe<IFileProcessingImgproxyExtensionEnum>;
  gravity?: InputMaybe<IFileProcessingImgproxyGravityEnum>;
  resize?: InputMaybe<IFileProcessingImgproxyResizeInput>;
};

/** The connection type for PressReleasesPress. */
export type IPressReleasesPressConnection = {
  __typename?: 'PressReleasesPressConnection';
  /** A list of edges. */
  edges: Array<IPressReleasesPressEdge>;
  /** A list of nodes. */
  nodes: Array<IPressReleasesPress>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type IPressReleasesPressCreateInput = {
  offeringId?: InputMaybe<Scalars['ID']['input']>;
  tagId?: InputMaybe<Scalars['ID']['input']>;
  url: Scalars['String']['input'];
};

/** An edge in a connection. */
export type IPressReleasesPressEdge = {
  __typename?: 'PressReleasesPressEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: IPressReleasesPress;
};

export type IPressReleasesPressUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  urlPublishedAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};

/** Autogenerated input type of PressReleasesPublish */
export type IPressReleasesPublishInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of PressReleasesPublish. */
export type IPressReleasesPublishPayload = {
  __typename?: 'PressReleasesPublishPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

export type IPressReleasesResolvers = {
  /** List of Press articles with ability to filter the result scope */
  pressReleases: IPressReleasesPressConnection;
};

export type IPressReleasesResolversPressReleasesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offeringId?: InputMaybe<Scalars['ID']['input']>;
  order?: InputMaybe<Array<IPressReleasesOrderEnum>>;
  states?: InputMaybe<Array<IPressReleasesStateEnum>>;
  tagId?: InputMaybe<Scalars['ID']['input']>;
  tagSlug?: InputMaybe<Scalars['String']['input']>;
};

/** List of available Press article states of Press Releases domain */
export enum IPressReleasesStateEnum {
  Created = 'CREATED',
  Published = 'PUBLISHED',
}

/** Autogenerated input type of PressReleasesUnpublish */
export type IPressReleasesUnpublishInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of PressReleasesUnpublish. */
export type IPressReleasesUnpublishPayload = {
  __typename?: 'PressReleasesUnpublishPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Autogenerated input type of PressReleasesUpdate */
export type IPressReleasesUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  press: IPressReleasesPressUpdateInput;
};

/** Autogenerated return type of PressReleasesUpdate. */
export type IPressReleasesUpdatePayload = {
  __typename?: 'PressReleasesUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  press?: Maybe<IPressReleasesPress>;
};

export type IPressReleasesUserPermissions = {
  __typename?: 'PressReleasesUserPermissions';
  canDelete: Scalars['Boolean']['output'];
  canPublish: Scalars['Boolean']['output'];
  canUnpublish: Scalars['Boolean']['output'];
  canUpdate: Scalars['Boolean']['output'];
};

export type IPrivacyUpdateBanner = {
  __typename?: 'PrivacyUpdateBanner';
  user: IUser;
};

/** A privilege describes a power that a user has for a specific record */
export type IPrivilege = INode & {
  __typename?: 'Privilege';
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  expired?: Maybe<Scalars['Boolean']['output']>;
  expiresAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  owner?: Maybe<IOwnerUnion>;
  /** The type of privilege that has been granted to a user */
  privilegeType?: Maybe<IPrivilegePrivilegeTypeEnum>;
  state?: Maybe<IPrivilegeStateEnum>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  user?: Maybe<IUser>;
};

/** Autogenerated input type of PrivilegeCreate */
export type IPrivilegeCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  ownerId: Scalars['ID']['input'];
  ownerType: IPrivilegeOwnerTypeEnum;
  privilegeType: IPrivilegePrivilegeTypeEnum;
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of PrivilegeCreate. */
export type IPrivilegeCreatePayload = {
  __typename?: 'PrivilegeCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  privilege?: Maybe<IPrivilege>;
};

/** Enum of possible owner types of privilege class */
export enum IPrivilegeOwnerTypeEnum {
  IssuerProfile = 'ISSUER_PROFILE',
}

/** All valid types of a Privilege */
export enum IPrivilegePrivilegeTypeEnum {
  SecondaryTransactionApproval = 'SECONDARY_TRANSACTION_APPROVAL',
}

/** Valid states for privileges */
export enum IPrivilegeStateEnum {
  Created = 'CREATED',
}

export type IProcessRequestInput = {
  factory: Scalars['String']['input'];
  factoryType: Scalars['String']['input'];
  eventType: Scalars['String']['input'];
  payload?: InputMaybe<Scalars['JSON']['input']>;
  meta?: InputMaybe<IProcessRequestMetaInput>;
  messageId?: InputMaybe<Scalars['String']['input']>;
};

export type IProcessRequestMetaInput = {
  sendNotification?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Regular promotion campaign type */
export type IPromotionCampaign = IPromotionCampaignInterface & {
  __typename?: 'PromotionCampaign';
  amountCents?: Maybe<Scalars['Int']['output']>;
  endAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  expired: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  message?: Maybe<Scalars['String']['output']>;
  mobile: Scalars['Boolean']['output'];
  status: IPromotionCampaignStatusEnum;
  valid: Scalars['Boolean']['output'];
};

/** Interface with shared fields for promotion campaign */
export type IPromotionCampaignInterface = {
  endAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  expired: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  message?: Maybe<Scalars['String']['output']>;
  mobile: Scalars['Boolean']['output'];
  status: IPromotionCampaignStatusEnum;
  valid: Scalars['Boolean']['output'];
};

/** Valid statuses for promotion campaigns */
export enum IPromotionCampaignStatusEnum {
  Expired = 'EXPIRED',
  UsedByUser = 'USED_BY_USER',
  Valid = 'VALID',
}

/** Autogenerated input type of PromotionCampaignValidateCode */
export type IPromotionCampaignValidateCodeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  investmentAmountCents: Scalars['Int']['input'];
  offeringSlug?: InputMaybe<Scalars['String']['input']>;
  userSlug: Scalars['String']['input'];
};

/** Autogenerated return type of PromotionCampaignValidateCode. */
export type IPromotionCampaignValidateCodePayload = {
  __typename?: 'PromotionCampaignValidateCodePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  promotionCampaign?: Maybe<IPromotionCampaign>;
};

/** Autogenerated input type of PromotionCampaignValidateNoteCode */
export type IPromotionCampaignValidateNoteCodeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  userSlug: Scalars['String']['input'];
};

/** Autogenerated return type of PromotionCampaignValidateNoteCode. */
export type IPromotionCampaignValidateNoteCodePayload = {
  __typename?: 'PromotionCampaignValidateNoteCodePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  notesPromotionCampaign?: Maybe<INotesPromotionCampaign>;
};

export type IQuery = IActionListResolvers &
  IActivityHistoriesResolvers &
  ICareersResolvers &
  IEventsResolvers &
  IFileProcessingResolvers &
  IGlobalSearchResolvers &
  IHelpCenterResolvers &
  IJournalResolvers &
  ILandingPagesResolvers &
  IPressReleasesResolvers &
  IResolvers &
  ITagsResolvers & {
    __typename?: 'Query';
    /** Pings the auth service */
    AuthDiagnostic_ping: Scalars['String']['output'];
    /** Returns the current password reset token */
    _passwordReset_getPasswordResetToken: Scalars['String']['output'];
    /** Finds the user and returns their authId */
    _userAuth_getAuthId: IAuthApplicationSyncUserPayload;
    /** A ping test */
    rollingClose_pingTestDoNotUse: Scalars['String']['output'];
    /** A test for integration of server-2 <> monolith DB */
    _rollingClose_thisIsAPlaceholderDoNotUse: IPlaceholderDoNotUsePayload;
    /** Gets all R/Capital users */
    capitalUsers_getAll: ICapitalUserConnection;
    /** Find R/Capital user by name or email */
    capitalUsers_getByNameOrEmail: ICapitalUserConnection;
    /** List of republic capital investment entities */
    capitalEntities_getEntities: IInvestmentEntityConnection;
    /** List of republic capital opportunities with filterable scope */
    capitalOpportunities_getOpportunities: IOpportunityConnection;
    /** Opportunity with associated tax information for the entity */
    capitalOpportunities_getOpportunityDetails: IOpportunityDetails;
    /** Box link to embed file into iframe */
    capitalOpportunities_getBoxEmbedLink: Scalars['String']['output'];
    /** Aggregate portfolio figures, filterable by entity */
    capitalPortfolio_getPortfolio: IPortfolioType;
    /** Lists all Transfer Agent Accounts */
    _transferAgentAccount_transferAgentAccounts: Array<ITransferAgentAccount>;
    /** Retrieves a single Transfer Agent Account given their ID */
    _transferAgentAccount_transferAgentAccount?: Maybe<ITransferAgentAccount>;
    /** Lists all Transfer Agent Entities */
    _transferAgentEntity_transferAgentEntities: Array<ITransferAgentEntity>;
    /** Retrieves a single Transfer Agent Entity given their ID */
    _transferAgentEntity_transferAgentEntity?: Maybe<ITransferAgentEntity>;
    /** Lists all Transfer Agent Holdings */
    _transferAgentHolding_transferAgentHoldings: Array<ITransferAgentHolding>;
    /** Retrieves a single Transfer Agent Holding given its ID */
    _transferAgentHolding_transferAgentHolding?: Maybe<ITransferAgentHolding>;
    /** Lists all Transfer Agent Securities */
    _transferAgentSecurity_transferAgentSecurities: Array<ITransferAgentSecurity>;
    /** Retrieves a single Transfer Agent Security given its ID */
    _transferAgentSecurity_transferAgentSecurity?: Maybe<ITransferAgentSecurity>;
    /** Lists all Transfer Agent Security Transfers */
    _transferAgentSecurityTransfer_transferAgentSecurityTransfers: Array<ITransferAgentSecurityTransfer>;
    /** Retrieves a single Transfer Agent Security Transfer given its ID */
    _transferAgentSecurityTransfer_transferAgentSecurityTransfer?: Maybe<ITransferAgentSecurityTransfer>;
    /** Lists all Transfer Agent Users */
    _transferAgentUser_transferAgentUsers: Array<ITransferAgentUser>;
    /** Retrieves a single Transfer Agent User given their ID */
    _transferAgentUser_transferAgentUser?: Maybe<ITransferAgentUser>;
    /** Lists all Transfer Agent Vendors currently supported */
    _transferAgentVendor_transferAgentVendors: Array<ITransferAgentVendor>;
    /** Retrieves a single Transfer Agent Vendor given its ID */
    _transferAgentVendor_transferAgentVendor?: Maybe<ITransferAgentVendor>;
    hostingEntities: Array<IContext>;
    /** Get a holding by UUID */
    mediciHolding: IMediciHolding;
    /** Get an offering by UUID */
    mediciOffering: IMediciOffering;
    /** List all offerings */
    mediciOfferings: IMediciOfferingConnection;
    mediciTransactions: Array<ITreasuryPrimeTransaction>;
    transactionLedger: ITransactionLedgerConnection;
    transactionLedgerCsvUrl?: Maybe<Scalars['String']['output']>;
    /** List of Advisors in Core (About) domain */
    aboutAdvisors: IUserRepublicTeamMemberAdvisorConnection;
    /** List of Growth Opportunities in Core (About) domain */
    aboutGrowthOpportunities: IUserRepublicTeamMemberGrowthOpportunityConnection;
    /** List of Partners in Core (About) domain */
    aboutPartners: IUserRepublicTeamMemberPartnerConnection;
    /** List of Porfolio Deals in Core (About) domain */
    aboutPortfolioDeals: IUserRepublicTeamMemberPortfolioDealConnection;
    /** List of Republic Team Members in Core (About) domain */
    aboutTeamMembers: IUserRepublicTeamMemberTeamMemberConnection;
    /** Fetch an ach account by id */
    achAccounts: IAchAccountConnection;
    /** List of Investments only with ActionRequired state */
    actionRequired?: Maybe<Array<IInvestmentActionRequired>>;
    /**
     * Details about actions on a single asset
     * @deprecated Use Granting Action
     */
    actions?: Maybe<ISecondaryActionConnection>;
    /** List of ActivityHistory records */
    activityHistories: IActivityHistoriesActivityHistoryConnection;
    /** List of useful admin links */
    adminMenu: Array<IAdminMenu>;
    /** Returns a singular asset record */
    asset?: Maybe<IAssetInterface>;
    /** List of assets of a given asset by a user */
    assets: IAssetInterfaceConnection;
    /** Returns an autocomplete results */
    autocomplete: IOfferingAutocomplete;
    /** Bank Info object (singular) of Core domain.  Accepts rounting number as a string. */
    bankInfo?: Maybe<IBankInfo>;
    /** Fetches actual banner for a user */
    banner?: Maybe<IBannerUnion>;
    /** Fetches banners for a user */
    banners?: Maybe<Array<IBannerUnion>>;
    /** Returns true/false if a user can/cannot message another user */
    canMessage: Scalars['Boolean']['output'];
    /**
     * List of Careers with ability to filter the result scope
     * @deprecated GQL API is not used for fetching career options.
     *                               Deprecated unless it would be decided otherwise
     */
    careers: Array<ICareersCareer>;
    /** Get cognito settings */
    cognitoSettings: ICognitoSettings;
    /** List of Comment objects with ability to filter the result by slug */
    comment?: Maybe<IComment>;
    /** List of Comment objects with ability to filter the result scope */
    comments: ICommentConnection;
    /** List of Common Term objects with ability to filter the result by id */
    commonTerm: ICommonTerm;
    /** List of Common Terms Names */
    commonTermTypes: Scalars['String']['output'];
    /** List of Common Term objects with ability to filter the result scope */
    commonTerms: ICommonTermConnection;
    /** List of Conversations in Core domain */
    conversations: IUserConversationConnection;
    countries: Array<ICountry>;
    /** List of Covered People */
    coveredPeople: IRoleCoveredPersonConnection;
    /** Find user by public key and return encrypted challenge */
    cryptoAuthGetChallenge: ICryptoEncryptedChallengeType;
    /** Find Crypto Blockchain RuleSet object by id */
    cryptoBlockchainRuleSet: ICryptoBlockchainRuleSetType;
    /** Crypto Blockchain RuleSet objects with ability to filter the result scope */
    cryptoBlockchainRuleSets: ICryptoBlockchainRuleSetTypeConnection;
    /** List of Crypto Distributions objects with ability to filter the result scope */
    cryptoDistributions: ICryptoDistributionConnection;
    /** Find Crypto Investment Option by id */
    cryptoInvestmentOption: ICryptoInvestmentOptionType;
    /** Crypto Investment Option objects with ability to filter the result scope */
    cryptoInvestmentOptions: ICryptoInvestmentOptionTypeConnection;
    /** Crypto Multisig Account object with ability to filter by id */
    cryptoMultisigAccount: ICryptoMultisigAccount;
    /** Crypto Multisig Account Signer object with ability to filter by id */
    cryptoMultisigAccountSigner: ICryptoMultisigAccountSigner;
    /** List of Crypto Multisig Account Signer objects with ability to filter the result scope */
    cryptoMultisigAccountSigners: ICryptoMultisigAccountSignerConnection;
    /** List of Crypto Multisig Account objects with ability to filter the result scope */
    cryptoMultisigAccounts: ICryptoMultisigAccountConnection;
    /** List of Crypto Multisig Account Signers Emails objects */
    cryptoMultisigSignerEmails?: Maybe<
      Array<ICryptoMultisigAccountSignerEmail>
    >;
    /** Get native token balance */
    cryptoNativeTokenBalance: ICryptoBlockchainRuleSetNativeTokenBalance;
    /** CryptoTokens objects with ability to filter the result scope by id */
    cryptoToken: ICryptoTokensType;
    /** Find Crypto Token Address by id */
    cryptoTokenAddress: ICryptoTokenAddressConnection;
    /** Crypto Token Addresses objects with ability to filter the result scope */
    cryptoTokenAddresses: ICryptoTokenAddressConnection;
    /** Crypto Tokens objects with ability to filter the result scope */
    cryptoTokens: ICryptoTokensTypeConnection;
    /** List of Crypto Transaction objects with ability to filter the result scope */
    cryptoTransactions: ICryptoTransactionConnection;
    /** Validate User Provided Address with Blockchain Rule Set object */
    cryptoValidateAddress: ICryptoBlockchainRuleSetValidateAddress;
    /** Find Crypto Wallet object by id */
    cryptoWallet: ICryptoWalletType;
    /** Find Crypto WalletDevices that should be cleared from user device */
    cryptoWalletDevicesToClear: ICryptoWalletDeviceConnection;
    /** find Crypto Wallet Disclaimer by id */
    cryptoWalletDisclaimer: ICryptoWalletDisclaimerType;
    /** List of Crypto Wallet Disclaimer objects with ability to filter the result scope */
    cryptoWalletDisclaimers: ICryptoWalletDisclaimerTypeConnection;
    /** Find Crypto Wallet Token by id */
    cryptoWalletToken: ICryptoWalletTokenType;
    /** List of Crypto Wallet Tokens objects with ability to filter the result scope */
    cryptoWalletTokens: ICryptoWalletTokenTypeConnection;
    /** List of Crypto Wallet objects with ability to filter the result scope */
    cryptoWallets: ICryptoWalletTypeConnection;
    /** The Custodial Account for a user based on their investment */
    custodialAccount?: Maybe<ICustodialAccount>;
    /**
     * A custodial account application that needs to be complete in order for a user to open a Custodial Account
     * @deprecated Deprecated in favor of the CustodialAccountType
     */
    custodialAccountApplication?: Maybe<ICustodialAccountApplication>;
    /** Custodial account setup details */
    custodialAccountSetup: ICustodialAccountSetup;
    /** Offering Custom Term object of Core domain */
    customTerm?: Maybe<IOfferingCustomTerm>;
    /** Offering Custom Term object of Core domain */
    customTerms?: Maybe<IOfferingCustomTermConnection>;
    /** A list of Offerings and Teaser Pages, filterable by scope */
    deals?: Maybe<IDealUnionConnection>;
    /** Get issuer's edgar access codes */
    edgarAccess?: Maybe<IIssuerIssuerProfileEdgarAccess>;
    /** List of republic capital investment entities */
    entities?: Maybe<IRepublicCapitalEntityConnection>;
    /** Fetch an event by a slug */
    event?: Maybe<IEventsEvent>;
    /** List of Events with ability to filter the result scope */
    events: IEventsEventConnection;
    /** Get the list of Featured investors */
    featuredInvestors: Array<IUser>;
    /** Provide information about validations for uploaded files */
    fileProcessingValidations: Array<IFileProcessingUploadValidation>;
    /** Returns status for flag or popup in Core domain */
    flipper: IFlipper;
    /** List of Flipper objects for the current user */
    flippers: Array<IFlipper>;
    /**
     * Follow object by slug
     * @deprecated Use xFollow instead
     */
    follow: IFollow;
    /** List of Follows objects with ability to filter the result scope */
    follows: IFollowConnection;
    /** Footer object by name and type */
    footer: IFooter;
    /** Check if user in Europe */
    gdprZone: Scalars['Boolean']['output'];
    /** Returns global search results */
    globalSearch: IGlobalSearchBase;
    /** Details about actions on a single asset */
    grantingActions?: Maybe<ISecondaryGrantingActionConnection>;
    /** List of Categories articles with ability to filter the result scope */
    helpCenterArticles: IHelpCenterArticleConnection;
    /** List of Categories with ability to filter the result scope */
    helpCenterCategories: IHelpCenterCategoryConnection;
    /** Download Gaap Example PDF of HelpCenter domain */
    helpCenterGaapExample: Scalars['Url']['output'];
    /** Download Gaap Example PDF of HelpCenter domain */
    helpCenterOpendealBcp: Scalars['Url']['output'];
    /** Holding object by id */
    holding?: Maybe<IHolding>;
    /** Figures on what is owned of a given asset by a user */
    holdings?: Maybe<IHoldingConnection>;
    /** Identity object for current user */
    identity: IUserInvestorProfileIdentity;
    impliedValuation?: Maybe<IImpliedValuation>;
    /** @deprecated Use impliedValuation instead returning also the changePercentage */
    impliedValuationCents?: Maybe<Scalars['BigInt']['output']>;
    /** Investment object with ability to filter the result by id */
    investment: IInvestment;
    /** Get investment by offering slug. Used in create wallet flow for crypto tokens distributions */
    investmentFromOffering?: Maybe<IInvestment>;
    /** List of Investment objects with ability to filter the result scope */
    investments: IInvestmentConnection;
    /** Entity objects for current user */
    investorEntities: Array<IEntityUnion>;
    investorEntity: IInvestorEntityType;
    /** InvestorGroup object by slug */
    investorGroup?: Maybe<IInvestorGroup>;
    investorGroupDealPostsPreview?: Maybe<IGroupDealPostPreview>;
    investorGroupPortfolio?: Maybe<IGroupDealPostConnection>;
    investorGroupPosts?: Maybe<IPostUnionConnection>;
    /** Get all InvestorGroups for Core domain */
    investorGroups?: Maybe<IInvestorGroupConnection>;
    /**
     * InvestorProfile object by user_slug
     * @deprecated Use x version, which is nullable
     */
    investorProfile: IInvestorProfile;
    /** RegBI Quiz for current user */
    investorRegulationBestInterestQuiz?: Maybe<IRegulationBestInterestQuiz>;
    /**
     * Find of IssuerApplication in Core (Issuers) domain
     * @deprecated Use x version, which is protected/nullable
     */
    issuerApplications: IIssuerApplicationConnection;
    /** Details about a single issuer profile */
    issuerProfile?: Maybe<IIssuerProfile>;
    /** Details about issuer profiles */
    issuerProfiles?: Maybe<IIssuerProfileConnection>;
    /** List of Journal articles with ability to filter the result scope */
    journalArticles: IJournalArticleConnection;
    journalArticlesCategories: Array<Scalars['String']['output']>;
    /** KycAmlState for current user */
    kycAmlState: IInvestorProfileKycAmlStateEnum;
    /** Request a Landing Page by ID */
    landingPage: ILandingPage;
    /** LegalInformation object for current user */
    legalInformation: IUserLegalInformation;
    /** LocationRestricted object (singular) of Core domain. Accepts offering_slug as a string. */
    locationRestricted?: Maybe<IOfferingLocationRestricted>;
    /** Find medici integration offering */
    mediciIntegrationOffering?: Maybe<IMediciIntegrationOffering>;
    /** Get a list of medici integration offerings */
    mediciIntegrationOfferings: IMediciIntegrationOfferingConnection;
    /** List of Messages in Core domain */
    messages: IMessageConnection;
    /** List of Addresses filtered by Multisig Asset Symbol */
    multisigAddressesByAssetSymbol: ICryptoMultisigAccountAddress;
    /** Returns Offerings where user is an issuer or a team member */
    myOfferings: IOfferingMyOfferingsItemConnection;
    /** Note launch configuration */
    noteLaunchConfig?: Maybe<INoteLaunchConfig>;
    /** Note price */
    notePrice?: Maybe<INotePrice>;
    /** Get current users notifications */
    notifications: INotificationConnection;
    /** Returns an Offering by slug for the Core domain */
    offering?: Maybe<IOffering>;
    /** Get Offering Security */
    offeringSecurity?: Maybe<ISecurityUnion>;
    /** Get offering terms */
    offeringTerms: Array<IOfferingTerm>;
    /** Get Offering Update object by id */
    offeringUpdate?: Maybe<IOfferingUpdate>;
    /** Offering Update of Core domain */
    offeringUpdates?: Maybe<IOfferingUpdateConnection>;
    /** Get all Offerings for Core domain */
    offerings: IOfferingConnection;
    /** Returns an Offerings list after search */
    offeringsSearch: IOfferingConnection;
    /** Returns current spotlight deals */
    offeringsSpotlightDeals: IOfferingConnection;
    /** Fetch Onboarding EdgarAccess Documents */
    onboardingDocumentsEdgarAccess?: Maybe<IOnboardingDocumentEdgarAccessConnection>;
    /** Fetch Onboarding Members */
    onboardingMembers?: Maybe<IOnboardingMemberConnection>;
    /** Fetch an onboarding timeline by Offering slug */
    onboardingOverviewTimeline?: Maybe<Array<IOnboardingOverviewTimeline>>;
    /** Fetch required actions in onboarding for issuer by offering slug */
    onboardingRequiredActions?: Maybe<Array<IOnboardingOverviewRequiredAction>>;
    /** Fetch an onboarding checklist for section by Offering slug */
    onboardingSectionChecklist?: Maybe<Array<IOnboardingSectionChecklist>>;
    /** List of republic capital opportunities with filterable scope */
    opportunities?: Maybe<IRepublicCapitalOpportunityConnection>;
    /** Fetch an Order by its id */
    order?: Maybe<ISecondaryOrder>;
    /** Details about secondary market orders */
    orders?: Maybe<ISecondaryOrderConnection>;
    /** Object containing Outward Notifications Categories */
    outwardNotificationsCategories?: Maybe<
      Array<IUserOutwardNotificationsCategory>
    >;
    /** Fetch a single Medici integration payment method */
    paymentMethod?: Maybe<IPaymentMethodUnion>;
    /** Fetch all users payment methods */
    paymentMethods: IPaymentMethodUnionConnection;
    /** Return public keys/data for various payment API integrations */
    paymentMethodsApiKeys: IPaymentMethodApiKeys;
    /** List of Perk objects */
    perks: Array<IOfferingPerk>;
    /** Returns a short-lived Link Plaid token */
    plaidIntegrationLinkToken?: Maybe<Scalars['String']['output']>;
    /** Aggregate portfolio figures filterable by entity */
    portfolio?: Maybe<IRepublicCapitalPortfolio>;
    /** List of Press articles with ability to filter the result scope */
    pressReleases: IPressReleasesPressConnection;
    /** Fetch Project Issues */
    projectIssues?: Maybe<IActionListIssueConnection>;
    /** List of Regulation Best Interest Quiz objects with ability to filter the result by id */
    regulationBestInterestQuiz: IRegulationBestInterestQuiz;
    /** List of RegulationBestInterestQuiz objects with ability to filter the result scope */
    regulationBestInterestQuizzes: IRegulationBestInterestQuizConnection;
    /**
     * Get saved offerings for user
     * @deprecated Use offerings resolver with SAVED_DEALS or SAVED_DEALS_LIVE scope
     */
    savedOfferingsForUser: IOfferingConnection;
    /** Returns an categories list */
    searchCategories: Array<IOfferingCategory>;
    /** All necessary details for creating a sell request */
    secondaryMarketBuyOrderCreationSummary: ISecondaryBuyOrderCreationSummary;
    /** All necessary details for creating a sell request */
    secondaryMarketSellOrderCreationSummary: ISecondarySellOrderCreationSummary;
    /** Get market summary */
    secondaryMarketSummary: ISecondaryMarketSummaryType;
    /** A list of issuer profiles that are currently traded on the secondaries market */
    secondaryMarketTradingIssuerProfiles?: Maybe<ISecondaryIssuerProfileConnection>;
    /** Returns Secondary Transactions filterable by various arguments */
    secondaryTransaction?: Maybe<ISecondaryTransaction>;
    /** Returns Secondary Transactions filterable by various arguments */
    secondaryTransactions?: Maybe<ISecondaryTransactionConnection>;
    /** List of SliderMediaItem objects */
    sliderMediaItems: Array<IOfferingSliderMediaItem>;
    /** Fetch a sluggable type by slug */
    slug: ISlug;
    states: Array<IState>;
    /** Returns a tag by slug */
    tag?: Maybe<ITagsTag>;
    /** List of Tags with ability to filter the result scope */
    tags: ITagsTagConnection;
    /**
     * Fetch a TeaserPage by slug
     * @deprecated Use teaserPages
     */
    teaserPage?: Maybe<ITeaserPage>;
    /** Fetch all published Teaser Pages slugs */
    teaserPageSlugs: Array<Scalars['String']['output']>;
    /** Fetch Teaser Pages */
    teaserPages?: Maybe<ITeaserPageConnection>;
    /** Fetch a Follower by TeaserPage slug */
    teaserPagesFollower?: Maybe<ITeaserPageFollower>;
    /** Return a term tier type object */
    termTier?: Maybe<IOfferingTermTier>;
    /** Get term tiers for an offering */
    termTiers?: Maybe<IOfferingTermTierConnection>;
    /** Investments for Ticker on homepage with limit */
    tickerInvestments?: Maybe<IInvestmentTickerConnection>;
    /** Fetch a user by slug, returns current users if slug is not provided */
    user?: Maybe<IUser>;
    /** Retrieve 2FA backup codes for current user */
    userMfaBackupCodes?: Maybe<Array<IMultiFactorAuthenticationBackupCode>>;
    /** Retrieve 2FA secrets for current user */
    userMfaSecrets?: Maybe<IMultiFactorAuthenticationSecret>;
    /** Get all email preferences */
    userNotificationPreferences: Array<IUserNotificationPreference>;
    /** Object containing privacy settings for current user */
    userPrivacySettings?: Maybe<Array<IUserPrivacySetting>>;
    userProfile?: Maybe<IUserProfile>;
    userProfileIntercomData: Scalars['JSON']['output'];
    /** List of Users Subscription object with ability to filter by id */
    userSubscription: IUserSubscription;
    /** List of Users Subscription objects with ability to filter the result scope */
    userSubscriptions: IUserSubscriptionConnection;
    /** Fetch all users */
    users?: Maybe<IUserConnection>;
    /**
     * Returns a singular asset record
     * @deprecated Use asset Resolver Instead
     */
    xAsset?: Maybe<IAssetInterface>;
    /**
     * List of assets of a given asset by a user
     * @deprecated Use assets Resolver Instead
     */
    xAssets: IAssetInterfaceConnection;
    /** Follow object by slug and record_type */
    xFollow?: Maybe<IFollow>;
    /** InvestorProfile object by user_slug */
    xInvestorProfile?: Maybe<IInvestorProfile>;
    /** Find of IssuerApplication in Core (Issuers) domain */
    xIssuerApplications?: Maybe<IIssuerApplicationConnection>;
    /**
     * Returns an Offerings list after search
     * @deprecated Use old offerings_search instead. Now it has cursor pagination
     */
    xOfferingsSearch: IOfferingConnection;
    /** Onboarding sidebar by Offering slug */
    xOnboardingSidebar?: Maybe<Array<IOnboardingSidebarSection>>;
    /** List of ZeroHash assets available to a user */
    zeroHashAssets?: Maybe<Array<Scalars['String']['output']>>;
    /** Returns carousels within the user's jurisdiction */
    carousels: ICarouselConnection;
    /** List of Offerings records */
    seedrsOfferings: ISeedrsOfferingConnection;
    /** A test field to test seedrs GQL setup */
    seedrsTestQuery: Scalars['String']['output'];
  };

export type IQuery_PasswordReset_GetPasswordResetTokenArgs = {
  email: Scalars['String']['input'];
};

export type IQuery_UserAuth_GetAuthIdArgs = {
  createdAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type IQuery_RollingClose_ThisIsAPlaceholderDoNotUseArgs = {
  input: IPlaceholderDoNotUseInput;
};

export type IQueryCapitalUsers_GetAllArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
};

export type IQueryCapitalUsers_GetByNameOrEmailArgs = {
  searchToken: Scalars['String']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
};

export type IQueryCapitalEntities_GetEntitiesArgs = {
  _forUserId?: InputMaybe<Scalars['ID']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
};

export type IQueryCapitalOpportunities_GetOpportunitiesArgs = {
  _forUserId?: InputMaybe<Scalars['ID']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  fundType?: InputMaybe<IRecordType>;
  order?: InputMaybe<IOpportunityOrder>;
  entityId?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type IQueryCapitalOpportunities_GetOpportunityDetailsArgs = {
  _forUserId?: InputMaybe<Scalars['ID']['input']>;
  opportunityId: Scalars['Int']['input'];
};

export type IQueryCapitalOpportunities_GetBoxEmbedLinkArgs = {
  _forUserId?: InputMaybe<Scalars['ID']['input']>;
  fileId: Scalars['String']['input'];
};

export type IQueryCapitalPortfolio_GetPortfolioArgs = {
  _forUserId?: InputMaybe<Scalars['ID']['input']>;
  entityId?: InputMaybe<Scalars['Int']['input']>;
};

export type IQuery_TransferAgentAccount_TransferAgentAccountsArgs = {
  limit?: InputMaybe<Scalars['Float']['input']>;
  page?: InputMaybe<Scalars['Float']['input']>;
};

export type IQuery_TransferAgentAccount_TransferAgentAccountArgs = {
  id: Scalars['String']['input'];
};

export type IQuery_TransferAgentEntity_TransferAgentEntitiesArgs = {
  limit?: InputMaybe<Scalars['Float']['input']>;
  page?: InputMaybe<Scalars['Float']['input']>;
};

export type IQuery_TransferAgentEntity_TransferAgentEntityArgs = {
  id: Scalars['String']['input'];
};

export type IQuery_TransferAgentHolding_TransferAgentHoldingsArgs = {
  limit?: InputMaybe<Scalars['Float']['input']>;
  page?: InputMaybe<Scalars['Float']['input']>;
  filters?: InputMaybe<ITransferAgentHoldingFiltersInput>;
};

export type IQuery_TransferAgentHolding_TransferAgentHoldingArgs = {
  id: Scalars['String']['input'];
  taHoldingId?: InputMaybe<Scalars['String']['input']>;
};

export type IQuery_TransferAgentSecurity_TransferAgentSecuritiesArgs = {
  limit?: InputMaybe<Scalars['Float']['input']>;
  page?: InputMaybe<Scalars['Float']['input']>;
  filters?: InputMaybe<ITransferAgentSecurityFiltersInput>;
};

export type IQuery_TransferAgentSecurity_TransferAgentSecurityArgs = {
  id: Scalars['String']['input'];
  taSecurityId?: InputMaybe<Scalars['String']['input']>;
};

export type IQuery_TransferAgentSecurityTransfer_TransferAgentSecurityTransferArgs =
  {
    id: Scalars['String']['input'];
  };

export type IQuery_TransferAgentUser_TransferAgentUsersArgs = {
  limit?: InputMaybe<Scalars['Float']['input']>;
  page?: InputMaybe<Scalars['Float']['input']>;
};

export type IQuery_TransferAgentUser_TransferAgentUserArgs = {
  id: Scalars['String']['input'];
};

export type IQuery_TransferAgentVendor_TransferAgentVendorsArgs = {
  limit?: InputMaybe<Scalars['Float']['input']>;
  page?: InputMaybe<Scalars['Float']['input']>;
};

export type IQuery_TransferAgentVendor_TransferAgentVendorArgs = {
  id: Scalars['String']['input'];
};

export type IQueryMediciHoldingArgs = {
  id: Scalars['ID']['input'];
};

export type IQueryMediciOfferingArgs = {
  uuid: Scalars['String']['input'];
};

export type IQueryMediciOfferingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Array<IOfferingFilter>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IQueryMediciTransactionsArgs = {
  uuid: Scalars['String']['input'];
};

export type IQueryTransactionLedgerArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  uuid: Scalars['String']['input'];
};

export type IQueryTransactionLedgerCsvUrlArgs = {
  uuid: Scalars['String']['input'];
};

export type IQueryAboutAdvisorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IQueryAboutGrowthOpportunitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IQueryAboutPartnersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IQueryAboutPortfolioDealsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IQueryAboutTeamMembersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IQueryAchAccountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IQueryActionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  assetSymbol: Scalars['String']['input'];
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IQueryActivityHistoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<IActivityHistoriesOrderEnum>>;
  overridingAdminIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  primaryCategories?: InputMaybe<Array<Scalars['String']['input']>>;
  primaryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  primaryTypes?: InputMaybe<Array<IActivityHistoriesPrimaryTypeEnum>>;
  secondaryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  secondaryTypes?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type IQueryAssetArgs = {
  assetSymbol: Scalars['String']['input'];
};

export type IQueryAssetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  holdingState?: InputMaybe<IHoldingStateEnum>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<IAssetOrderEnum>>;
  scope?: InputMaybe<IAssetScopeEnum>;
};

export type IQueryAutocompleteArgs = {
  filter?: InputMaybe<IOfferingSearchFilterEnum>;
  q: Scalars['String']['input'];
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type IQueryBankInfoArgs = {
  routingNumber: Scalars['String']['input'];
};

export type IQueryBannerArgs = {
  dismissedBanners?: InputMaybe<Array<IBannerTypeEnum>>;
  path: Scalars['String']['input'];
};

export type IQueryBannersArgs = {
  dismissedBanners?: InputMaybe<Array<IBannerTypeEnum>>;
  path: Scalars['String']['input'];
};

export type IQueryCanMessageArgs = {
  offeringSlug?: InputMaybe<Scalars['String']['input']>;
  recipientSlug: Scalars['String']['input'];
};

export type IQueryCareersArgs = {
  slugs?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type IQueryCommentArgs = {
  slug: Scalars['String']['input'];
};

export type IQueryCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offeringId?: InputMaybe<Scalars['ID']['input']>;
  offeringSlug?: InputMaybe<Scalars['String']['input']>;
  offeringUpdateCommentsBySlug?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<ICommentOrderEnum>>;
  slug?: InputMaybe<Scalars['String']['input']>;
  userComments?: InputMaybe<Scalars['ID']['input']>;
};

export type IQueryCommonTermArgs = {
  id: Scalars['ID']['input'];
};

export type IQueryCommonTermsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<ICommonTermOrderEnum>>;
  termType?: InputMaybe<Array<ICommonTermTermTypeEnum>>;
};

export type IQueryConversationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IQueryCoveredPeopleArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  issuerProfileId?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offeringSlug?: InputMaybe<Scalars['String']['input']>;
  primaryOfficer?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IQueryCryptoAuthGetChallengeArgs = {
  authId?: InputMaybe<Scalars['String']['input']>;
  publicKey: Scalars['String']['input'];
};

export type IQueryCryptoBlockchainRuleSetArgs = {
  id: Scalars['ID']['input'];
};

export type IQueryCryptoBlockchainRuleSetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  cryptoTokenId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<ICryptoBlockchainRuleSetOrderEnum>>;
};

export type IQueryCryptoDistributionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  investorProfileId?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<ICryptoDistributionOrderEnum>>;
};

export type IQueryCryptoInvestmentOptionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type IQueryCryptoInvestmentOptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  investmentId?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offeringId?: InputMaybe<Scalars['ID']['input']>;
};

export type IQueryCryptoMultisigAccountArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type IQueryCryptoMultisigAccountSignerArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type IQueryCryptoMultisigAccountSignersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  investorProfileId?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  multisigAccountId?: InputMaybe<Scalars['ID']['input']>;
};

export type IQueryCryptoMultisigAccountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  cryptoWalletTokenId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  investorProfileId?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type IQueryCryptoNativeTokenBalanceArgs = {
  blockchainRuleSetId: Scalars['ID']['input'];
};

export type IQueryCryptoTokenArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  offeringSlug?: InputMaybe<Scalars['String']['input']>;
};

export type IQueryCryptoTokenAddressArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IQueryCryptoTokenAddressesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  walletId?: InputMaybe<Scalars['ID']['input']>;
  walletTokenId?: InputMaybe<Scalars['ID']['input']>;
};

export type IQueryCryptoTokensArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offeringId?: InputMaybe<Scalars['ID']['input']>;
  offeringSlug?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<ICryptoTokenOrderEnum>>;
};

export type IQueryCryptoTransactionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  investorProfileId?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IQueryCryptoValidateAddressArgs = {
  address: Scalars['String']['input'];
  blockchainRuleSetId: Scalars['ID']['input'];
};

export type IQueryCryptoWalletArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type IQueryCryptoWalletDevicesToClearArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  investorProfileId?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IQueryCryptoWalletDisclaimerArgs = {
  id: Scalars['ID']['input'];
};

export type IQueryCryptoWalletDisclaimersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IQueryCryptoWalletTokenArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type IQueryCryptoWalletTokensArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  cryptoWalletId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IQueryCryptoWalletsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  investorProfileId?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IQueryCustodialAccountArgs = {
  investmentId?: InputMaybe<Scalars['ID']['input']>;
};

export type IQueryCustodialAccountApplicationArgs = {
  investmentId?: InputMaybe<Scalars['ID']['input']>;
};

export type IQueryCustodialAccountSetupArgs = {
  investmentId: Scalars['ID']['input'];
};

export type IQueryCustomTermArgs = {
  id: Scalars['ID']['input'];
};

export type IQueryCustomTermsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offeringSlug?: InputMaybe<Scalars['String']['input']>;
};

export type IQueryDealsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  scope?: InputMaybe<IDealScopeEnum>;
};

export type IQueryEdgarAccessArgs = {
  offeringSlug: Scalars['String']['input'];
};

export type IQueryEntitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IQueryEventArgs = {
  slug: Scalars['String']['input'];
};

export type IQueryEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  categories?: InputMaybe<Array<IEventsCategoryEnum>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  future?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<IEventsOrderEnum>>;
  slugs?: InputMaybe<Array<Scalars['String']['input']>>;
  states?: InputMaybe<Array<IEventsStateEnum>>;
  tagSlug?: InputMaybe<Scalars['String']['input']>;
  timeFilters?: InputMaybe<IEventsTimeFiltersInput>;
};

export type IQueryFileProcessingValidationsArgs = {
  types?: InputMaybe<Array<IFileProcessingUploadValidationTypeEnum>>;
};

export type IQueryFlipperArgs = {
  feature: IFlipperEnum;
};

export type IQueryFollowArgs = {
  slug: Scalars['String']['input'];
};

export type IQueryFollowsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  targetSlug: Scalars['String']['input'];
};

export type IQueryFooterArgs = {
  name: Scalars['String']['input'];
  type: IFooterTypeEnum;
};

export type IQueryGlobalSearchArgs = {
  q?: InputMaybe<Scalars['String']['input']>;
};

export type IQueryGrantingActionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  assetSymbol: Scalars['String']['input'];
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IQueryHelpCenterArticlesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<IHelpCenterArticleOrderEnum>>;
  search?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Array<IHelpCenterArticleStateEnum>>;
};

export type IQueryHelpCenterCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  articleSlug?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type IQueryHoldingArgs = {
  id: Scalars['ID']['input'];
};

export type IQueryHoldingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<Array<IHoldingStateEnum>>;
  userSlug?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type IQueryImpliedValuationArgs = {
  askingPriceCents: Scalars['BigInt']['input'];
  assetId: Scalars['ID']['input'];
  units: Scalars['BigInt']['input'];
};

export type IQueryImpliedValuationCentsArgs = {
  askingPriceCents: Scalars['BigInt']['input'];
  assetId: Scalars['ID']['input'];
  units: Scalars['BigInt']['input'];
};

export type IQueryInvestmentArgs = {
  id: Scalars['ID']['input'];
};

export type IQueryInvestmentFromOfferingArgs = {
  offeringSlug: Scalars['String']['input'];
};

export type IQueryInvestmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<IInvestmentOrderEnum>>;
  scope?: InputMaybe<IInvestmentScopeEnum>;
  states?: InputMaybe<Array<IInvestmentStateEnum>>;
};

export type IQueryInvestorEntityArgs = {
  crossTableId: Scalars['ID']['input'];
};

export type IQueryInvestorGroupArgs = {
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type IQueryInvestorGroupDealPostsPreviewArgs = {
  groupSlug: Scalars['String']['input'];
  offeringSlug: Scalars['String']['input'];
};

export type IQueryInvestorGroupPortfolioArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  slug: Scalars['String']['input'];
};

export type IQueryInvestorGroupPostsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  slug: Scalars['String']['input'];
};

export type IQueryInvestorGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  tagSlug?: InputMaybe<Scalars['String']['input']>;
};

export type IQueryInvestorProfileArgs = {
  userSlug?: InputMaybe<Scalars['String']['input']>;
};

export type IQueryIssuerApplicationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IQueryIssuerProfileArgs = {
  companyName?: InputMaybe<Scalars['String']['input']>;
  companySymbol?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type IQueryIssuerProfilesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  scope?: InputMaybe<ISecondaryTradabilityEnum>;
};

export type IQueryJournalArticlesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  categories?: InputMaybe<Array<IJournalCategoryEnum>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<IJournalOrderEnum>>;
  states?: InputMaybe<Array<IJournalStateEnum>>;
  tagSlug?: InputMaybe<Scalars['String']['input']>;
};

export type IQueryLandingPageArgs = {
  landingPageId: Scalars['ID']['input'];
};

export type IQueryLocationRestrictedArgs = {
  offeringSlug: Scalars['String']['input'];
};

export type IQueryMediciIntegrationOfferingArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type IQueryMediciIntegrationOfferingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  uuids?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type IQueryMessagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  userSlug?: InputMaybe<Scalars['String']['input']>;
};

export type IQueryMultisigAddressesByAssetSymbolArgs = {
  assetSymbol: Scalars['String']['input'];
  investorProfileIds: Array<Scalars['ID']['input']>;
};

export type IQueryMyOfferingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<IOfferingMyOfferingOrderEnum>>;
};

export type IQueryNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<INotificationOrderEnum>>;
  scope?: InputMaybe<INotificationScopeEnum>;
};

export type IQueryOfferingArgs = {
  slug: Scalars['String']['input'];
};

export type IQueryOfferingSecurityArgs = {
  offeringSlug: Scalars['String']['input'];
};

export type IQueryOfferingTermsArgs = {
  offeringSlug: Scalars['String']['input'];
};

export type IQueryOfferingUpdateArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type IQueryOfferingUpdatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offeringSlug?: InputMaybe<Scalars['String']['input']>;
  scope?: InputMaybe<IOfferingUpdateScopeEnum>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type IQueryOfferingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  baseScopeFilters?: InputMaybe<Array<IOfferingBaseScopeEnum>>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  newDealsPeriodDays?: InputMaybe<Scalars['Int']['input']>;
  scope?: InputMaybe<IOfferingScopeEnum>;
  scopeFilter?: InputMaybe<IOfferingScopeFilterInput>;
  tagSlug?: InputMaybe<Scalars['String']['input']>;
};

export type IQueryOfferingsSearchArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  searchParams: IOfferingSearchInput;
};

export type IQueryOfferingsSpotlightDealsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IQueryOnboardingDocumentsEdgarAccessArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  documentType?: InputMaybe<IOnboardingDocumentDocumentTypeEnum>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offeringSlug?: InputMaybe<Scalars['String']['input']>;
};

export type IQueryOnboardingMembersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offeringSlug?: InputMaybe<Scalars['String']['input']>;
};

export type IQueryOnboardingOverviewTimelineArgs = {
  offeringSlug: Scalars['String']['input'];
};

export type IQueryOnboardingRequiredActionsArgs = {
  offeringSlug: Scalars['String']['input'];
};

export type IQueryOnboardingSectionChecklistArgs = {
  offeringSlug: Scalars['String']['input'];
  section: IOnboardingSectionChecklistEnum;
};

export type IQueryOpportunitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  entityId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  fundType?: InputMaybe<IRepublicCapitalRecordTypeEnum>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<IRepublicCapitalOrderEnum>>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type IQueryOrderArgs = {
  adminView?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
};

export type IQueryOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  assetSymbol?: InputMaybe<Array<Scalars['String']['input']>>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<ISecondaryOrderOrderEnum>>;
  orderType?: InputMaybe<Array<ISecondaryOrderTypeEnum>>;
  scope?: InputMaybe<ISecondaryOrderScopeEnum>;
  state?: InputMaybe<Array<ISecondaryOrderStateEnum>>;
  userSlug?: InputMaybe<Scalars['String']['input']>;
};

export type IQueryPaymentMethodArgs = {
  id: Scalars['ID']['input'];
};

export type IQueryPaymentMethodsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offeringSlug?: InputMaybe<Scalars['String']['input']>;
};

export type IQueryPaymentMethodsApiKeysArgs = {
  offeringSlug?: InputMaybe<Scalars['String']['input']>;
};

export type IQueryPerksArgs = {
  offeringSlug: Scalars['String']['input'];
};

export type IQueryPortfolioArgs = {
  entityId?: InputMaybe<Scalars['ID']['input']>;
};

export type IQueryPressReleasesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offeringId?: InputMaybe<Scalars['ID']['input']>;
  order?: InputMaybe<Array<IPressReleasesOrderEnum>>;
  states?: InputMaybe<Array<IPressReleasesStateEnum>>;
  tagId?: InputMaybe<Scalars['ID']['input']>;
  tagSlug?: InputMaybe<Scalars['String']['input']>;
};

export type IQueryProjectIssuesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  projectSlug: Scalars['String']['input'];
};

export type IQueryRegulationBestInterestQuizArgs = {
  id: Scalars['ID']['input'];
};

export type IQueryRegulationBestInterestQuizzesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<IRegulationBestInterestQuizOrderEnum>>;
  state?: InputMaybe<Array<IRegulationBestInterestQuizStateEnum>>;
};

export type IQuerySavedOfferingsForUserArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  userSlug?: InputMaybe<Scalars['String']['input']>;
};

export type IQuerySecondaryMarketTradingIssuerProfilesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IQuerySecondaryTransactionArgs = {
  adminView?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  issuerView?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IQuerySecondaryTransactionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  assetSymbol?: InputMaybe<Array<Scalars['String']['input']>>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  issuerConfirmed?: InputMaybe<Scalars['Boolean']['input']>;
  issuerProfile?: InputMaybe<Array<Scalars['String']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<ISecondaryTransactionOrderEnum>>;
  scope?: InputMaybe<ISecondaryTransactionScopeEnum>;
  state?: InputMaybe<Array<ISecondaryTransactionStateEnum>>;
};

export type IQuerySliderMediaItemsArgs = {
  offeringSlug: Scalars['String']['input'];
};

export type IQuerySlugArgs = {
  slug: Scalars['String']['input'];
};

export type IQueryStatesArgs = {
  code: Scalars['String']['input'];
};

export type IQueryTagArgs = {
  slug: Scalars['String']['input'];
};

export type IQueryTagsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  categories?: InputMaybe<Array<ITagsCategoryEnum>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  group?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  highlights?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<ITagsOrderEnum>>;
  scope?: InputMaybe<ITagsScopeEnum>;
  slugs?: InputMaybe<Array<Scalars['String']['input']>>;
  states?: InputMaybe<Array<ITagsStateEnum>>;
  vertical?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

export type IQueryTeaserPageArgs = {
  slug: Scalars['String']['input'];
};

export type IQueryTeaserPagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  scope: ITeaserPageScopeEnum;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type IQueryTeaserPagesFollowerArgs = {
  teaserPageSlug: Scalars['String']['input'];
};

export type IQueryTermTierArgs = {
  id: Scalars['ID']['input'];
};

export type IQueryTermTiersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offeringSlug?: InputMaybe<Scalars['String']['input']>;
};

export type IQueryTickerInvestmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IQueryUserArgs = {
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type IQueryUserProfileArgs = {
  slug: Scalars['String']['input'];
};

export type IQueryUserProfileIntercomDataArgs = {
  iosParameters?: InputMaybe<IIosParametersInput>;
};

export type IQueryUserSubscriptionArgs = {
  id: Scalars['ID']['input'];
};

export type IQueryUserSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type IQueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<IUserFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<IUserOrderEnum>>;
  role?: InputMaybe<Array<IUserRoleEnum>>;
};

export type IQueryXAssetArgs = {
  assetSymbol: Scalars['String']['input'];
};

export type IQueryXAssetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  holdingState?: InputMaybe<IHoldingStateEnum>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<IAssetOrderEnum>>;
  scope?: InputMaybe<IAssetScopeEnum>;
};

export type IQueryXFollowArgs = {
  recordType: IFollowRecordTypeEnum;
  slug: Scalars['String']['input'];
};

export type IQueryXInvestorProfileArgs = {
  userSlug?: InputMaybe<Scalars['String']['input']>;
};

export type IQueryXIssuerApplicationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IQueryXOfferingsSearchArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  searchParams: IOfferingSearchInput;
};

export type IQueryXOnboardingSidebarArgs = {
  offeringSlug: Scalars['String']['input'];
};

export type IQueryZeroHashAssetsArgs = {
  offeringSlug: Scalars['String']['input'];
};

export type IQueryCarouselsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type IQuerySeedrsOfferingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  listed?: InputMaybe<Scalars['Boolean']['input']>;
  listedTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  private?: InputMaybe<Scalars['Boolean']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  states?: InputMaybe<Array<Scalars['String']['input']>>;
  trendingIn?: InputMaybe<Scalars['String']['input']>;
};

/** Possible record types for republic capital opportunities. */
export enum IRecordType {
  Spv = 'SPV',
  Maf = 'MAF',
}

/** Referral Token Type for Core Domain */
export type IReferralToken = {
  __typename?: 'ReferralToken';
  allUsers?: Maybe<Array<IUser>>;
  allUsersConfirmed?: Maybe<Array<IUser>>;
  allUsersUnconfirmed?: Maybe<Array<IUser>>;
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  investmentCount: Scalars['Int']['output'];
  investments?: Maybe<Array<IInvestment>>;
  issuerApplicationsCount: Scalars['Int']['output'];
  leadsUsers?: Maybe<Array<IUser>>;
  noteAmountUnits?: Maybe<Scalars['Int']['output']>;
  referrerId: Scalars['Int']['output'];
  referrerType: Scalars['String']['output'];
  totalInvestmentsAmount?: Maybe<Scalars['String']['output']>;
  users?: Maybe<Array<IUser>>;
  usersCount: Scalars['Int']['output'];
  usersTotalInvestmentsAmount?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of RefreshHolding */
export type IRefreshHoldingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of RefreshHolding. */
export type IRefreshHoldingPayload = {
  __typename?: 'RefreshHoldingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  holding: IMediciHolding;
};

/** Autogenerated input type of RefreshTransactions */
export type IRefreshTransactionsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  uuid: Scalars['String']['input'];
};

/** Autogenerated return type of RefreshTransactions. */
export type IRefreshTransactionsPayload = {
  __typename?: 'RefreshTransactionsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IError>>;
  success?: Maybe<ISuccess>;
};

/** Regulation Best Interest Quiz object of Regulation Best Interest Quizzes domain */
export type IRegulationBestInterestQuiz = INode & {
  __typename?: 'RegulationBestInterestQuiz';
  abandonedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  annualIncomeCents?: Maybe<Scalars['Int']['output']>;
  approvedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  approximateAnnualIncome?: Maybe<IRegulationBestInterestQuizApproximateAnnualIncomesEnum>;
  approximateInvestableAssets?: Maybe<IRegulationBestInterestQuizApproximateInvestableAssetsEnum>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  dependents: Scalars['Boolean']['output'];
  dependentsQuantity?: Maybe<Scalars['Int']['output']>;
  employmentStatus: Scalars['Boolean']['output'];
  financialRegulatorAffiliation: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  institution?: Maybe<Scalars['String']['output']>;
  investableAssets: Scalars['Boolean']['output'];
  investmentExperience: Scalars['Boolean']['output'];
  investmentId?: Maybe<Scalars['Int']['output']>;
  investmentObjectives: Scalars['Boolean']['output'];
  investorProfileId: Scalars['Int']['output'];
  moreInfoRequestedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  rejectedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  reviewRequiredAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  reviewerId?: Maybe<Scalars['Int']['output']>;
  state: IRegulationBestInterestQuizStateEnum;
  totalAssetsAmountCents?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /** Permitted actions for Regulation Best Interest Quiz object */
  userPermissions: IRegulationBestInterestQuizUserPermissions;
};

/** List of available approximate annual income values */
export enum IRegulationBestInterestQuizApproximateAnnualIncomesEnum {
  Over_300K = 'OVER_300K',
  Under_100K = 'UNDER_100K',
  Under_200K = 'UNDER_200K',
  Under_300K = 'UNDER_300K',
  Under_50K = 'UNDER_50K',
  Under_75K = 'UNDER_75K',
}

/** List of available approximate investable assets values */
export enum IRegulationBestInterestQuizApproximateInvestableAssetsEnum {
  Over_1M = 'OVER_1M',
  Under_100K = 'UNDER_100K',
  Under_1M = 'UNDER_1M',
  Under_200K = 'UNDER_200K',
  Under_300K = 'UNDER_300K',
  Under_500K = 'UNDER_500K',
}

/** The connection type for RegulationBestInterestQuiz. */
export type IRegulationBestInterestQuizConnection = {
  __typename?: 'RegulationBestInterestQuizConnection';
  /** A list of edges. */
  edges: Array<IRegulationBestInterestQuizEdge>;
  /** A list of nodes. */
  nodes: Array<IRegulationBestInterestQuiz>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Autogenerated input type of RegulationBestInterestQuizCreate */
export type IRegulationBestInterestQuizCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  regulation: IRegulationBestInterestQuizInput;
};

/** Autogenerated return type of RegulationBestInterestQuizCreate. */
export type IRegulationBestInterestQuizCreatePayload = {
  __typename?: 'RegulationBestInterestQuizCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  regulation?: Maybe<IRegulationBestInterestQuiz>;
};

/** Autogenerated input type of RegulationBestInterestQuizDelete */
export type IRegulationBestInterestQuizDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of RegulationBestInterestQuizDelete. */
export type IRegulationBestInterestQuizDeletePayload = {
  __typename?: 'RegulationBestInterestQuizDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  regulation?: Maybe<IRegulationBestInterestQuiz>;
};

/** An edge in a connection. */
export type IRegulationBestInterestQuizEdge = {
  __typename?: 'RegulationBestInterestQuizEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: IRegulationBestInterestQuiz;
};

export type IRegulationBestInterestQuizInput = {
  annualIncome?: InputMaybe<Scalars['Int']['input']>;
  approximateAnnualIncome?: InputMaybe<IRegulationBestInterestQuizApproximateAnnualIncomesEnum>;
  approximateInvestableAssets?: InputMaybe<IRegulationBestInterestQuizApproximateInvestableAssetsEnum>;
  dependents?: InputMaybe<Scalars['Boolean']['input']>;
  dependentsQuantity?: InputMaybe<Scalars['Int']['input']>;
  employmentStatus?: InputMaybe<Scalars['Boolean']['input']>;
  financialRegulatorAffiliation?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  institution?: InputMaybe<Scalars['String']['input']>;
  investableAssets?: InputMaybe<Scalars['Boolean']['input']>;
  investmentExperience?: InputMaybe<Scalars['Boolean']['input']>;
  investmentExperience2?: InputMaybe<Scalars['Boolean']['input']>;
  investmentId?: InputMaybe<Scalars['Int']['input']>;
  investmentObjectives?: InputMaybe<Scalars['Boolean']['input']>;
  investmentObjectives2?: InputMaybe<Scalars['Boolean']['input']>;
  investorProfileId?: InputMaybe<Scalars['Int']['input']>;
  reviewerId?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  totalAssetsAmount?: InputMaybe<Scalars['Int']['input']>;
};

/** List of available Order Enums of Regulation Best Interest Quiz domain */
export enum IRegulationBestInterestQuizOrderEnum {
  AbandonedAtAsc = 'ABANDONED_AT_ASC',
  AbandonedAtDesc = 'ABANDONED_AT_DESC',
  ApprovedAtAsc = 'APPROVED_AT_ASC',
  ApprovedAtDesc = 'APPROVED_AT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  MoreInfoRequestedAtAsc = 'MORE_INFO_REQUESTED_AT_ASC',
  MoreInfoRequestedAtDesc = 'MORE_INFO_REQUESTED_AT_DESC',
  RejectedAtAsc = 'REJECTED_AT_ASC',
  RejectedAtDesc = 'REJECTED_AT_DESC',
  ReviewRequiredAtAsc = 'REVIEW_REQUIRED_AT_ASC',
  ReviewRequiredAtDesc = 'REVIEW_REQUIRED_AT_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
}

/** List of available State Enums of Regulation Best Interest Quizzes domain */
export enum IRegulationBestInterestQuizStateEnum {
  Abandoned = 'ABANDONED',
  Approved = 'APPROVED',
  AutoApproved = 'AUTO_APPROVED',
  MoreInfoRequested = 'MORE_INFO_REQUESTED',
  Rejected = 'REJECTED',
  ReviewRequired = 'REVIEW_REQUIRED',
}

/** Autogenerated input type of RegulationBestInterestQuizUpdate */
export type IRegulationBestInterestQuizUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  regulation: IRegulationBestInterestQuizInput;
};

/** Autogenerated return type of RegulationBestInterestQuizUpdate. */
export type IRegulationBestInterestQuizUpdatePayload = {
  __typename?: 'RegulationBestInterestQuizUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  regulation?: Maybe<IRegulationBestInterestQuiz>;
};

/** User permissions exposed to a client for Regulation Best Interest Quizzes domain */
export type IRegulationBestInterestQuizUserPermissions = {
  __typename?: 'RegulationBestInterestQuizUserPermissions';
  canApprove: Scalars['Boolean']['output'];
  canCreate: Scalars['Boolean']['output'];
  canDestroy: Scalars['Boolean']['output'];
  canMarkMoreInfoRequested: Scalars['Boolean']['output'];
  canMarkReviewRequired: Scalars['Boolean']['output'];
  canReject: Scalars['Boolean']['output'];
  canShow: Scalars['Boolean']['output'];
  canUpdate: Scalars['Boolean']['output'];
};

/** One single republic capital investment entity */
export type IRepublicCapitalEntity = {
  __typename?: 'RepublicCapitalEntity';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** The connection type for RepublicCapitalEntity. */
export type IRepublicCapitalEntityConnection = {
  __typename?: 'RepublicCapitalEntityConnection';
  /** A list of edges. */
  edges: Array<IRepublicCapitalEntityEdge>;
  /** A list of nodes. */
  nodes: Array<IRepublicCapitalEntity>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IRepublicCapitalEntityEdge = {
  __typename?: 'RepublicCapitalEntityEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: IRepublicCapitalEntity;
};

/** Possible fund status names for republic capital investment instruments */
export enum IRepublicCapitalFundStatusEnum {
  AwaitingDistribution = 'AWAITING_DISTRIBUTION',
  DealClosing = 'DEAL_CLOSING',
  Distributed = 'DISTRIBUTED',
  Formation = 'FORMATION',
  Fundraising = 'FUNDRAISING',
  FundraisingComplete = 'FUNDRAISING_COMPLETE',
  FundComplete = 'FUND_COMPLETE',
  Investing = 'INVESTING',
  PartiallyDistributed = 'PARTIALLY_DISTRIBUTED',
  Terminated = 'TERMINATED',
}

/** One single republic capital investment instrument */
export type IRepublicCapitalInstrument = {
  __typename?: 'RepublicCapitalInstrument';
  fundStatus: IRepublicCapitalFundStatusEnum;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** One single republic capital opportunity */
export type IRepublicCapitalOpportunity = {
  __typename?: 'RepublicCapitalOpportunity';
  /** Amount contributed to a fund. Only applies to MAF Opportunities. */
  contributionAmountCents?: Maybe<Scalars['Int']['output']>;
  distributionCents: Scalars['BigInt']['output'];
  entity: IRepublicCapitalEntity;
  fundType: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  instrument: IRepublicCapitalInstrument;
  investedAmountCents: Scalars['Int']['output'];
  investmentDate: Scalars['ISO8601DateTime']['output'];
  /** This field comes from the Investment Instrument associated with the Opportunity */
  lastMarkupDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /**
   * MOIC of an opportunity. Note: rounded to 1 decimal place by request
   * @deprecated Salesforce has changed this field to multiple
   */
  moic: Scalars['BigDecimal']['output'];
  /** Multiple value of an opportunity. Note: rounded to 1 decimal place by request */
  multiple: Scalars['BigDecimal']['output'];
  /** This field comes from the Investment Instrument associated with the Opportunity */
  name: Scalars['String']['output'];
  netFairMarketValueCents: Scalars['Int']['output'];
  /** @deprecated Changing to Enum -- Use x_stage instead */
  stage: Scalars['String']['output'];
  /** For MAF Investments, this is the total amount committed for contribution */
  totalCommittedCents?: Maybe<Scalars['Int']['output']>;
  xStage: IRepublicCapitalStageEnum;
};

/** The connection type for RepublicCapitalOpportunity. */
export type IRepublicCapitalOpportunityConnection = {
  __typename?: 'RepublicCapitalOpportunityConnection';
  /** A list of edges. */
  edges: Array<IRepublicCapitalOpportunityEdge>;
  /** A list of nodes. */
  nodes: Array<IRepublicCapitalOpportunity>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IRepublicCapitalOpportunityEdge = {
  __typename?: 'RepublicCapitalOpportunityEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: IRepublicCapitalOpportunity;
};

/** List of available orders for Republic Capital Opportunities. Note: Close Date is equivalent to Investment Date */
export enum IRepublicCapitalOrderEnum {
  CloseDateAsc = 'CLOSE_DATE_ASC',
  CloseDateDesc = 'CLOSE_DATE_DESC',
}

/** Autogenerated input type of RepublicCapitalPopulateUserData */
export type IRepublicCapitalPopulateUserDataInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
};

/** Autogenerated return type of RepublicCapitalPopulateUserData. */
export type IRepublicCapitalPopulateUserDataPayload = {
  __typename?: 'RepublicCapitalPopulateUserDataPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  success?: Maybe<Scalars['String']['output']>;
};

/** Aggregate portfolio figures for a republic capital investor */
export type IRepublicCapitalPortfolio = {
  __typename?: 'RepublicCapitalPortfolio';
  /** The value of this field is affected by the Investment Entity filter */
  capitalInvestedCents: Scalars['BigInt']['output'];
  id: Scalars['Int']['output'];
  /** The value of this field is affected by the Investment Entity filter */
  investmentCount: Scalars['Int']['output'];
  /** The value of this field is affected by the Investment Entity filter */
  portfolioRealizedValueCents?: Maybe<Scalars['BigInt']['output']>;
  /** The value of this field is affected by the Investment Entity filter */
  portfolioUnrealizedValueCents?: Maybe<Scalars['BigInt']['output']>;
  /** The value of this field is affected by the Investment Entity filter */
  portfolioValueCents: Scalars['BigInt']['output'];
};

/** Autogenerated input type of RepublicCapitalPortfolioCSV */
export type IRepublicCapitalPortfolioCsvInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of RepublicCapitalPortfolioCSV. */
export type IRepublicCapitalPortfolioCsvPayload = {
  __typename?: 'RepublicCapitalPortfolioCSVPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  /** The server that the download URL should be queried against */
  serverBaseUrl?: Maybe<Scalars['Url']['output']>;
  url?: Maybe<Scalars['Url']['output']>;
};

/** Possible record types for republic capital opportunities */
export enum IRepublicCapitalRecordTypeEnum {
  Maf = 'maf',
  Spv = 'spv',
}

/** Autogenerated input type of RepublicCapitalRefreshUserData */
export type IRepublicCapitalRefreshUserDataInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of RepublicCapitalRefreshUserData. */
export type IRepublicCapitalRefreshUserDataPayload = {
  __typename?: 'RepublicCapitalRefreshUserDataPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  success?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of RepublicCapitalResetPasswordEmail */
export type IRepublicCapitalResetPasswordEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
};

/** Autogenerated return type of RepublicCapitalResetPasswordEmail. */
export type IRepublicCapitalResetPasswordEmailPayload = {
  __typename?: 'RepublicCapitalResetPasswordEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  resetToken?: Maybe<Scalars['String']['output']>;
};

/** Possible stage names for republic capital opportunities */
export enum IRepublicCapitalStageEnum {
  Signed = 'SIGNED',
  Wired = 'WIRED',
}

/** Investor entity type from Republic */
export enum IRepublicInvestorEntityType {
  Corporation = 'CORPORATION',
  Individual = 'INDIVIDUAL',
  Ira = 'IRA',
  JointSpouse = 'JOINT_SPOUSE',
  JointTenant = 'JOINT_TENANT',
  Llc = 'LLC',
  Partnership = 'PARTNERSHIP',
  Trust = 'TRUST',
}

/** This field allows us to restrict a vendor’s usage to a specific Republic platform / entity */
export enum IRepublicPlatform {
  Capital = 'CAPITAL',
  Core = 'CORE',
  DealRoom = 'DEAL_ROOM',
  Fig = 'FIG',
  FundingPortal = 'FUNDING_PORTAL',
  RealEstate = 'REAL_ESTATE',
  Secondaries = 'SECONDARIES',
}

export type IRepublicWalletLaunchBanner = {
  __typename?: 'RepublicWalletLaunchBanner';
  user?: Maybe<IUser>;
};

export type IResolvers = {
  /** List of Advisors in Core (About) domain */
  aboutAdvisors: IUserRepublicTeamMemberAdvisorConnection;
  /** List of Growth Opportunities in Core (About) domain */
  aboutGrowthOpportunities: IUserRepublicTeamMemberGrowthOpportunityConnection;
  /** List of Partners in Core (About) domain */
  aboutPartners: IUserRepublicTeamMemberPartnerConnection;
  /** List of Porfolio Deals in Core (About) domain */
  aboutPortfolioDeals: IUserRepublicTeamMemberPortfolioDealConnection;
  /** List of Republic Team Members in Core (About) domain */
  aboutTeamMembers: IUserRepublicTeamMemberTeamMemberConnection;
  /** Fetch an ach account by id */
  achAccounts: IAchAccountConnection;
  /** List of Investments only with ActionRequired state */
  actionRequired?: Maybe<Array<IInvestmentActionRequired>>;
  /**
   * Details about actions on a single asset
   * @deprecated Use Granting Action
   */
  actions?: Maybe<ISecondaryActionConnection>;
  /** List of ActivityHistory records */
  activityHistories: IActivityHistoriesActivityHistoryConnection;
  /** List of useful admin links */
  adminMenu: Array<IAdminMenu>;
  /** Returns a singular asset record */
  asset?: Maybe<IAssetInterface>;
  /** List of assets of a given asset by a user */
  assets: IAssetInterfaceConnection;
  /** Returns an autocomplete results */
  autocomplete: IOfferingAutocomplete;
  /** Bank Info object (singular) of Core domain.  Accepts rounting number as a string. */
  bankInfo?: Maybe<IBankInfo>;
  /** Fetches actual banner for a user */
  banner?: Maybe<IBannerUnion>;
  /** Fetches banners for a user */
  banners?: Maybe<Array<IBannerUnion>>;
  /** Returns true/false if a user can/cannot message another user */
  canMessage: Scalars['Boolean']['output'];
  /** Get cognito settings */
  cognitoSettings: ICognitoSettings;
  /** List of Comment objects with ability to filter the result by slug */
  comment?: Maybe<IComment>;
  /** List of Comment objects with ability to filter the result scope */
  comments: ICommentConnection;
  /** List of Common Term objects with ability to filter the result by id */
  commonTerm: ICommonTerm;
  /** List of Common Terms Names */
  commonTermTypes: Scalars['String']['output'];
  /** List of Common Term objects with ability to filter the result scope */
  commonTerms: ICommonTermConnection;
  /** List of Conversations in Core domain */
  conversations: IUserConversationConnection;
  countries: Array<ICountry>;
  /** List of Covered People */
  coveredPeople: IRoleCoveredPersonConnection;
  /** Find user by public key and return encrypted challenge */
  cryptoAuthGetChallenge: ICryptoEncryptedChallengeType;
  /** Find Crypto Blockchain RuleSet object by id */
  cryptoBlockchainRuleSet: ICryptoBlockchainRuleSetType;
  /** Crypto Blockchain RuleSet objects with ability to filter the result scope */
  cryptoBlockchainRuleSets: ICryptoBlockchainRuleSetTypeConnection;
  /** List of Crypto Distributions objects with ability to filter the result scope */
  cryptoDistributions: ICryptoDistributionConnection;
  /** Find Crypto Investment Option by id */
  cryptoInvestmentOption: ICryptoInvestmentOptionType;
  /** Crypto Investment Option objects with ability to filter the result scope */
  cryptoInvestmentOptions: ICryptoInvestmentOptionTypeConnection;
  /** Crypto Multisig Account object with ability to filter by id */
  cryptoMultisigAccount: ICryptoMultisigAccount;
  /** Crypto Multisig Account Signer object with ability to filter by id */
  cryptoMultisigAccountSigner: ICryptoMultisigAccountSigner;
  /** List of Crypto Multisig Account Signer objects with ability to filter the result scope */
  cryptoMultisigAccountSigners: ICryptoMultisigAccountSignerConnection;
  /** List of Crypto Multisig Account objects with ability to filter the result scope */
  cryptoMultisigAccounts: ICryptoMultisigAccountConnection;
  /** List of Crypto Multisig Account Signers Emails objects */
  cryptoMultisigSignerEmails?: Maybe<Array<ICryptoMultisigAccountSignerEmail>>;
  /** Get native token balance */
  cryptoNativeTokenBalance: ICryptoBlockchainRuleSetNativeTokenBalance;
  /** CryptoTokens objects with ability to filter the result scope by id */
  cryptoToken: ICryptoTokensType;
  /** Find Crypto Token Address by id */
  cryptoTokenAddress: ICryptoTokenAddressConnection;
  /** Crypto Token Addresses objects with ability to filter the result scope */
  cryptoTokenAddresses: ICryptoTokenAddressConnection;
  /** Crypto Tokens objects with ability to filter the result scope */
  cryptoTokens: ICryptoTokensTypeConnection;
  /** List of Crypto Transaction objects with ability to filter the result scope */
  cryptoTransactions: ICryptoTransactionConnection;
  /** Validate User Provided Address with Blockchain Rule Set object */
  cryptoValidateAddress: ICryptoBlockchainRuleSetValidateAddress;
  /** Find Crypto Wallet object by id */
  cryptoWallet: ICryptoWalletType;
  /** Find Crypto WalletDevices that should be cleared from user device */
  cryptoWalletDevicesToClear: ICryptoWalletDeviceConnection;
  /** find Crypto Wallet Disclaimer by id */
  cryptoWalletDisclaimer: ICryptoWalletDisclaimerType;
  /** List of Crypto Wallet Disclaimer objects with ability to filter the result scope */
  cryptoWalletDisclaimers: ICryptoWalletDisclaimerTypeConnection;
  /** Find Crypto Wallet Token by id */
  cryptoWalletToken: ICryptoWalletTokenType;
  /** List of Crypto Wallet Tokens objects with ability to filter the result scope */
  cryptoWalletTokens: ICryptoWalletTokenTypeConnection;
  /** List of Crypto Wallet objects with ability to filter the result scope */
  cryptoWallets: ICryptoWalletTypeConnection;
  /** The Custodial Account for a user based on their investment */
  custodialAccount?: Maybe<ICustodialAccount>;
  /**
   * A custodial account application that needs to be complete in order for a user to open a Custodial Account
   * @deprecated Deprecated in favor of the CustodialAccountType
   */
  custodialAccountApplication?: Maybe<ICustodialAccountApplication>;
  /** Custodial account setup details */
  custodialAccountSetup: ICustodialAccountSetup;
  /** Offering Custom Term object of Core domain */
  customTerm?: Maybe<IOfferingCustomTerm>;
  /** Offering Custom Term object of Core domain */
  customTerms?: Maybe<IOfferingCustomTermConnection>;
  /** A list of Offerings and Teaser Pages, filterable by scope */
  deals?: Maybe<IDealUnionConnection>;
  /** Get issuer's edgar access codes */
  edgarAccess?: Maybe<IIssuerIssuerProfileEdgarAccess>;
  /** List of republic capital investment entities */
  entities?: Maybe<IRepublicCapitalEntityConnection>;
  /** Get the list of Featured investors */
  featuredInvestors: Array<IUser>;
  /** Returns status for flag or popup in Core domain */
  flipper: IFlipper;
  /** List of Flipper objects for the current user */
  flippers: Array<IFlipper>;
  /**
   * Follow object by slug
   * @deprecated Use xFollow instead
   */
  follow: IFollow;
  /** List of Follows objects with ability to filter the result scope */
  follows: IFollowConnection;
  /** Footer object by name and type */
  footer: IFooter;
  /** Check if user in Europe */
  gdprZone: Scalars['Boolean']['output'];
  /** Details about actions on a single asset */
  grantingActions?: Maybe<ISecondaryGrantingActionConnection>;
  /** Holding object by id */
  holding?: Maybe<IHolding>;
  /** Figures on what is owned of a given asset by a user */
  holdings?: Maybe<IHoldingConnection>;
  /** Identity object for current user */
  identity: IUserInvestorProfileIdentity;
  impliedValuation?: Maybe<IImpliedValuation>;
  /** @deprecated Use impliedValuation instead returning also the changePercentage */
  impliedValuationCents?: Maybe<Scalars['BigInt']['output']>;
  /** Investment object with ability to filter the result by id */
  investment: IInvestment;
  /** Get investment by offering slug. Used in create wallet flow for crypto tokens distributions */
  investmentFromOffering?: Maybe<IInvestment>;
  /** List of Investment objects with ability to filter the result scope */
  investments: IInvestmentConnection;
  /** Entity objects for current user */
  investorEntities: Array<IEntityUnion>;
  investorEntity: IInvestorEntityType;
  /** InvestorGroup object by slug */
  investorGroup?: Maybe<IInvestorGroup>;
  investorGroupDealPostsPreview?: Maybe<IGroupDealPostPreview>;
  investorGroupPortfolio?: Maybe<IGroupDealPostConnection>;
  investorGroupPosts?: Maybe<IPostUnionConnection>;
  /** Get all InvestorGroups for Core domain */
  investorGroups?: Maybe<IInvestorGroupConnection>;
  /**
   * InvestorProfile object by user_slug
   * @deprecated Use x version, which is nullable
   */
  investorProfile: IInvestorProfile;
  /** RegBI Quiz for current user */
  investorRegulationBestInterestQuiz?: Maybe<IRegulationBestInterestQuiz>;
  /**
   * Find of IssuerApplication in Core (Issuers) domain
   * @deprecated Use x version, which is protected/nullable
   */
  issuerApplications: IIssuerApplicationConnection;
  /** Details about a single issuer profile */
  issuerProfile?: Maybe<IIssuerProfile>;
  /** Details about issuer profiles */
  issuerProfiles?: Maybe<IIssuerProfileConnection>;
  /** KycAmlState for current user */
  kycAmlState: IInvestorProfileKycAmlStateEnum;
  /** LegalInformation object for current user */
  legalInformation: IUserLegalInformation;
  /** LocationRestricted object (singular) of Core domain. Accepts offering_slug as a string. */
  locationRestricted?: Maybe<IOfferingLocationRestricted>;
  /** Find medici integration offering */
  mediciIntegrationOffering?: Maybe<IMediciIntegrationOffering>;
  /** Get a list of medici integration offerings */
  mediciIntegrationOfferings: IMediciIntegrationOfferingConnection;
  /** List of Messages in Core domain */
  messages: IMessageConnection;
  /** List of Addresses filtered by Multisig Asset Symbol */
  multisigAddressesByAssetSymbol: ICryptoMultisigAccountAddress;
  /** Returns Offerings where user is an issuer or a team member */
  myOfferings: IOfferingMyOfferingsItemConnection;
  /** Note launch configuration */
  noteLaunchConfig?: Maybe<INoteLaunchConfig>;
  /** Note price */
  notePrice?: Maybe<INotePrice>;
  /** Get current users notifications */
  notifications: INotificationConnection;
  /** Returns an Offering by slug for the Core domain */
  offering?: Maybe<IOffering>;
  /** Get Offering Security */
  offeringSecurity?: Maybe<ISecurityUnion>;
  /** Get offering terms */
  offeringTerms: Array<IOfferingTerm>;
  /** Get Offering Update object by id */
  offeringUpdate?: Maybe<IOfferingUpdate>;
  /** Offering Update of Core domain */
  offeringUpdates?: Maybe<IOfferingUpdateConnection>;
  /** Get all Offerings for Core domain */
  offerings: IOfferingConnection;
  /** Returns an Offerings list after search */
  offeringsSearch: IOfferingConnection;
  /** Returns current spotlight deals */
  offeringsSpotlightDeals: IOfferingConnection;
  /** Fetch Onboarding EdgarAccess Documents */
  onboardingDocumentsEdgarAccess?: Maybe<IOnboardingDocumentEdgarAccessConnection>;
  /** Fetch Onboarding Members */
  onboardingMembers?: Maybe<IOnboardingMemberConnection>;
  /** Fetch an onboarding timeline by Offering slug */
  onboardingOverviewTimeline?: Maybe<Array<IOnboardingOverviewTimeline>>;
  /** Fetch required actions in onboarding for issuer by offering slug */
  onboardingRequiredActions?: Maybe<Array<IOnboardingOverviewRequiredAction>>;
  /** Fetch an onboarding checklist for section by Offering slug */
  onboardingSectionChecklist?: Maybe<Array<IOnboardingSectionChecklist>>;
  /** List of republic capital opportunities with filterable scope */
  opportunities?: Maybe<IRepublicCapitalOpportunityConnection>;
  /** Fetch an Order by its id */
  order?: Maybe<ISecondaryOrder>;
  /** Details about secondary market orders */
  orders?: Maybe<ISecondaryOrderConnection>;
  /** Object containing Outward Notifications Categories */
  outwardNotificationsCategories?: Maybe<
    Array<IUserOutwardNotificationsCategory>
  >;
  /** Fetch a single Medici integration payment method */
  paymentMethod?: Maybe<IPaymentMethodUnion>;
  /** Fetch all users payment methods */
  paymentMethods: IPaymentMethodUnionConnection;
  /** Return public keys/data for various payment API integrations */
  paymentMethodsApiKeys: IPaymentMethodApiKeys;
  /** List of Perk objects */
  perks: Array<IOfferingPerk>;
  /** Returns a short-lived Link Plaid token */
  plaidIntegrationLinkToken?: Maybe<Scalars['String']['output']>;
  /** Aggregate portfolio figures filterable by entity */
  portfolio?: Maybe<IRepublicCapitalPortfolio>;
  /** List of Regulation Best Interest Quiz objects with ability to filter the result by id */
  regulationBestInterestQuiz: IRegulationBestInterestQuiz;
  /** List of RegulationBestInterestQuiz objects with ability to filter the result scope */
  regulationBestInterestQuizzes: IRegulationBestInterestQuizConnection;
  /**
   * Get saved offerings for user
   * @deprecated Use offerings resolver with SAVED_DEALS or SAVED_DEALS_LIVE scope
   */
  savedOfferingsForUser: IOfferingConnection;
  /** Returns an categories list */
  searchCategories: Array<IOfferingCategory>;
  /** All necessary details for creating a sell request */
  secondaryMarketBuyOrderCreationSummary: ISecondaryBuyOrderCreationSummary;
  /** All necessary details for creating a sell request */
  secondaryMarketSellOrderCreationSummary: ISecondarySellOrderCreationSummary;
  /** Get market summary */
  secondaryMarketSummary: ISecondaryMarketSummaryType;
  /** A list of issuer profiles that are currently traded on the secondaries market */
  secondaryMarketTradingIssuerProfiles?: Maybe<ISecondaryIssuerProfileConnection>;
  /** Returns Secondary Transactions filterable by various arguments */
  secondaryTransaction?: Maybe<ISecondaryTransaction>;
  /** Returns Secondary Transactions filterable by various arguments */
  secondaryTransactions?: Maybe<ISecondaryTransactionConnection>;
  /** List of SliderMediaItem objects */
  sliderMediaItems: Array<IOfferingSliderMediaItem>;
  /** Fetch a sluggable type by slug */
  slug: ISlug;
  states: Array<IState>;
  /**
   * Fetch a TeaserPage by slug
   * @deprecated Use teaserPages
   */
  teaserPage?: Maybe<ITeaserPage>;
  /** Fetch all published Teaser Pages slugs */
  teaserPageSlugs: Array<Scalars['String']['output']>;
  /** Fetch Teaser Pages */
  teaserPages?: Maybe<ITeaserPageConnection>;
  /** Fetch a Follower by TeaserPage slug */
  teaserPagesFollower?: Maybe<ITeaserPageFollower>;
  /** Return a term tier type object */
  termTier?: Maybe<IOfferingTermTier>;
  /** Get term tiers for an offering */
  termTiers?: Maybe<IOfferingTermTierConnection>;
  /** Investments for Ticker on homepage with limit */
  tickerInvestments?: Maybe<IInvestmentTickerConnection>;
  /** Fetch a user by slug, returns current users if slug is not provided */
  user?: Maybe<IUser>;
  /** Retrieve 2FA backup codes for current user */
  userMfaBackupCodes?: Maybe<Array<IMultiFactorAuthenticationBackupCode>>;
  /** Retrieve 2FA secrets for current user */
  userMfaSecrets?: Maybe<IMultiFactorAuthenticationSecret>;
  /** Get all email preferences */
  userNotificationPreferences: Array<IUserNotificationPreference>;
  /** Object containing privacy settings for current user */
  userPrivacySettings?: Maybe<Array<IUserPrivacySetting>>;
  userProfile?: Maybe<IUserProfile>;
  userProfileIntercomData: Scalars['JSON']['output'];
  /** List of Users Subscription object with ability to filter by id */
  userSubscription: IUserSubscription;
  /** List of Users Subscription objects with ability to filter the result scope */
  userSubscriptions: IUserSubscriptionConnection;
  /** Fetch all users */
  users?: Maybe<IUserConnection>;
  /**
   * Returns a singular asset record
   * @deprecated Use asset Resolver Instead
   */
  xAsset?: Maybe<IAssetInterface>;
  /**
   * List of assets of a given asset by a user
   * @deprecated Use assets Resolver Instead
   */
  xAssets: IAssetInterfaceConnection;
  /** Follow object by slug and record_type */
  xFollow?: Maybe<IFollow>;
  /** InvestorProfile object by user_slug */
  xInvestorProfile?: Maybe<IInvestorProfile>;
  /** Find of IssuerApplication in Core (Issuers) domain */
  xIssuerApplications?: Maybe<IIssuerApplicationConnection>;
  /**
   * Returns an Offerings list after search
   * @deprecated Use old offerings_search instead. Now it has cursor pagination
   */
  xOfferingsSearch: IOfferingConnection;
  /** Onboarding sidebar by Offering slug */
  xOnboardingSidebar?: Maybe<Array<IOnboardingSidebarSection>>;
  /** List of ZeroHash assets available to a user */
  zeroHashAssets?: Maybe<Array<Scalars['String']['output']>>;
};

export type IResolversAboutAdvisorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IResolversAboutGrowthOpportunitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IResolversAboutPartnersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IResolversAboutPortfolioDealsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IResolversAboutTeamMembersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IResolversAchAccountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IResolversActionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  assetSymbol: Scalars['String']['input'];
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IResolversActivityHistoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<IActivityHistoriesOrderEnum>>;
  overridingAdminIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  primaryCategories?: InputMaybe<Array<Scalars['String']['input']>>;
  primaryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  primaryTypes?: InputMaybe<Array<IActivityHistoriesPrimaryTypeEnum>>;
  secondaryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  secondaryTypes?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type IResolversAssetArgs = {
  assetSymbol: Scalars['String']['input'];
};

export type IResolversAssetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  holdingState?: InputMaybe<IHoldingStateEnum>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<IAssetOrderEnum>>;
  scope?: InputMaybe<IAssetScopeEnum>;
};

export type IResolversAutocompleteArgs = {
  filter?: InputMaybe<IOfferingSearchFilterEnum>;
  q: Scalars['String']['input'];
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type IResolversBankInfoArgs = {
  routingNumber: Scalars['String']['input'];
};

export type IResolversBannerArgs = {
  dismissedBanners?: InputMaybe<Array<IBannerTypeEnum>>;
  path: Scalars['String']['input'];
};

export type IResolversBannersArgs = {
  dismissedBanners?: InputMaybe<Array<IBannerTypeEnum>>;
  path: Scalars['String']['input'];
};

export type IResolversCanMessageArgs = {
  offeringSlug?: InputMaybe<Scalars['String']['input']>;
  recipientSlug: Scalars['String']['input'];
};

export type IResolversCommentArgs = {
  slug: Scalars['String']['input'];
};

export type IResolversCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offeringId?: InputMaybe<Scalars['ID']['input']>;
  offeringSlug?: InputMaybe<Scalars['String']['input']>;
  offeringUpdateCommentsBySlug?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<ICommentOrderEnum>>;
  slug?: InputMaybe<Scalars['String']['input']>;
  userComments?: InputMaybe<Scalars['ID']['input']>;
};

export type IResolversCommonTermArgs = {
  id: Scalars['ID']['input'];
};

export type IResolversCommonTermsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<ICommonTermOrderEnum>>;
  termType?: InputMaybe<Array<ICommonTermTermTypeEnum>>;
};

export type IResolversConversationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IResolversCoveredPeopleArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  issuerProfileId?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offeringSlug?: InputMaybe<Scalars['String']['input']>;
  primaryOfficer?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IResolversCryptoAuthGetChallengeArgs = {
  authId?: InputMaybe<Scalars['String']['input']>;
  publicKey: Scalars['String']['input'];
};

export type IResolversCryptoBlockchainRuleSetArgs = {
  id: Scalars['ID']['input'];
};

export type IResolversCryptoBlockchainRuleSetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  cryptoTokenId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<ICryptoBlockchainRuleSetOrderEnum>>;
};

export type IResolversCryptoDistributionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  investorProfileId?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<ICryptoDistributionOrderEnum>>;
};

export type IResolversCryptoInvestmentOptionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type IResolversCryptoInvestmentOptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  investmentId?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offeringId?: InputMaybe<Scalars['ID']['input']>;
};

export type IResolversCryptoMultisigAccountArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type IResolversCryptoMultisigAccountSignerArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type IResolversCryptoMultisigAccountSignersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  investorProfileId?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  multisigAccountId?: InputMaybe<Scalars['ID']['input']>;
};

export type IResolversCryptoMultisigAccountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  cryptoWalletTokenId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  investorProfileId?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type IResolversCryptoNativeTokenBalanceArgs = {
  blockchainRuleSetId: Scalars['ID']['input'];
};

export type IResolversCryptoTokenArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  offeringSlug?: InputMaybe<Scalars['String']['input']>;
};

export type IResolversCryptoTokenAddressArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IResolversCryptoTokenAddressesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  walletId?: InputMaybe<Scalars['ID']['input']>;
  walletTokenId?: InputMaybe<Scalars['ID']['input']>;
};

export type IResolversCryptoTokensArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offeringId?: InputMaybe<Scalars['ID']['input']>;
  offeringSlug?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<ICryptoTokenOrderEnum>>;
};

export type IResolversCryptoTransactionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  investorProfileId?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IResolversCryptoValidateAddressArgs = {
  address: Scalars['String']['input'];
  blockchainRuleSetId: Scalars['ID']['input'];
};

export type IResolversCryptoWalletArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type IResolversCryptoWalletDevicesToClearArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  investorProfileId?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IResolversCryptoWalletDisclaimerArgs = {
  id: Scalars['ID']['input'];
};

export type IResolversCryptoWalletDisclaimersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IResolversCryptoWalletTokenArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type IResolversCryptoWalletTokensArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  cryptoWalletId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IResolversCryptoWalletsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  investorProfileId?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IResolversCustodialAccountArgs = {
  investmentId?: InputMaybe<Scalars['ID']['input']>;
};

export type IResolversCustodialAccountApplicationArgs = {
  investmentId?: InputMaybe<Scalars['ID']['input']>;
};

export type IResolversCustodialAccountSetupArgs = {
  investmentId: Scalars['ID']['input'];
};

export type IResolversCustomTermArgs = {
  id: Scalars['ID']['input'];
};

export type IResolversCustomTermsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offeringSlug?: InputMaybe<Scalars['String']['input']>;
};

export type IResolversDealsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  scope?: InputMaybe<IDealScopeEnum>;
};

export type IResolversEdgarAccessArgs = {
  offeringSlug: Scalars['String']['input'];
};

export type IResolversEntitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IResolversFlipperArgs = {
  feature: IFlipperEnum;
};

export type IResolversFollowArgs = {
  slug: Scalars['String']['input'];
};

export type IResolversFollowsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  targetSlug: Scalars['String']['input'];
};

export type IResolversFooterArgs = {
  name: Scalars['String']['input'];
  type: IFooterTypeEnum;
};

export type IResolversGrantingActionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  assetSymbol: Scalars['String']['input'];
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IResolversHoldingArgs = {
  id: Scalars['ID']['input'];
};

export type IResolversHoldingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<Array<IHoldingStateEnum>>;
  userSlug?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type IResolversImpliedValuationArgs = {
  askingPriceCents: Scalars['BigInt']['input'];
  assetId: Scalars['ID']['input'];
  units: Scalars['BigInt']['input'];
};

export type IResolversImpliedValuationCentsArgs = {
  askingPriceCents: Scalars['BigInt']['input'];
  assetId: Scalars['ID']['input'];
  units: Scalars['BigInt']['input'];
};

export type IResolversInvestmentArgs = {
  id: Scalars['ID']['input'];
};

export type IResolversInvestmentFromOfferingArgs = {
  offeringSlug: Scalars['String']['input'];
};

export type IResolversInvestmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<IInvestmentOrderEnum>>;
  scope?: InputMaybe<IInvestmentScopeEnum>;
  states?: InputMaybe<Array<IInvestmentStateEnum>>;
};

export type IResolversInvestorEntityArgs = {
  crossTableId: Scalars['ID']['input'];
};

export type IResolversInvestorGroupArgs = {
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type IResolversInvestorGroupDealPostsPreviewArgs = {
  groupSlug: Scalars['String']['input'];
  offeringSlug: Scalars['String']['input'];
};

export type IResolversInvestorGroupPortfolioArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  slug: Scalars['String']['input'];
};

export type IResolversInvestorGroupPostsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  slug: Scalars['String']['input'];
};

export type IResolversInvestorGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  tagSlug?: InputMaybe<Scalars['String']['input']>;
};

export type IResolversInvestorProfileArgs = {
  userSlug?: InputMaybe<Scalars['String']['input']>;
};

export type IResolversIssuerApplicationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IResolversIssuerProfileArgs = {
  companyName?: InputMaybe<Scalars['String']['input']>;
  companySymbol?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type IResolversIssuerProfilesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  scope?: InputMaybe<ISecondaryTradabilityEnum>;
};

export type IResolversLocationRestrictedArgs = {
  offeringSlug: Scalars['String']['input'];
};

export type IResolversMediciIntegrationOfferingArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type IResolversMediciIntegrationOfferingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  uuids?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type IResolversMessagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  userSlug?: InputMaybe<Scalars['String']['input']>;
};

export type IResolversMultisigAddressesByAssetSymbolArgs = {
  assetSymbol: Scalars['String']['input'];
  investorProfileIds: Array<Scalars['ID']['input']>;
};

export type IResolversMyOfferingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<IOfferingMyOfferingOrderEnum>>;
};

export type IResolversNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<INotificationOrderEnum>>;
  scope?: InputMaybe<INotificationScopeEnum>;
};

export type IResolversOfferingArgs = {
  slug: Scalars['String']['input'];
};

export type IResolversOfferingSecurityArgs = {
  offeringSlug: Scalars['String']['input'];
};

export type IResolversOfferingTermsArgs = {
  offeringSlug: Scalars['String']['input'];
};

export type IResolversOfferingUpdateArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type IResolversOfferingUpdatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offeringSlug?: InputMaybe<Scalars['String']['input']>;
  scope?: InputMaybe<IOfferingUpdateScopeEnum>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type IResolversOfferingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  baseScopeFilters?: InputMaybe<Array<IOfferingBaseScopeEnum>>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  newDealsPeriodDays?: InputMaybe<Scalars['Int']['input']>;
  scope?: InputMaybe<IOfferingScopeEnum>;
  scopeFilter?: InputMaybe<IOfferingScopeFilterInput>;
  tagSlug?: InputMaybe<Scalars['String']['input']>;
};

export type IResolversOfferingsSearchArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  searchParams: IOfferingSearchInput;
};

export type IResolversOfferingsSpotlightDealsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IResolversOnboardingDocumentsEdgarAccessArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  documentType?: InputMaybe<IOnboardingDocumentDocumentTypeEnum>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offeringSlug?: InputMaybe<Scalars['String']['input']>;
};

export type IResolversOnboardingMembersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offeringSlug?: InputMaybe<Scalars['String']['input']>;
};

export type IResolversOnboardingOverviewTimelineArgs = {
  offeringSlug: Scalars['String']['input'];
};

export type IResolversOnboardingRequiredActionsArgs = {
  offeringSlug: Scalars['String']['input'];
};

export type IResolversOnboardingSectionChecklistArgs = {
  offeringSlug: Scalars['String']['input'];
  section: IOnboardingSectionChecklistEnum;
};

export type IResolversOpportunitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  entityId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  fundType?: InputMaybe<IRepublicCapitalRecordTypeEnum>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<IRepublicCapitalOrderEnum>>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type IResolversOrderArgs = {
  adminView?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
};

export type IResolversOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  assetSymbol?: InputMaybe<Array<Scalars['String']['input']>>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<ISecondaryOrderOrderEnum>>;
  orderType?: InputMaybe<Array<ISecondaryOrderTypeEnum>>;
  scope?: InputMaybe<ISecondaryOrderScopeEnum>;
  state?: InputMaybe<Array<ISecondaryOrderStateEnum>>;
  userSlug?: InputMaybe<Scalars['String']['input']>;
};

export type IResolversPaymentMethodArgs = {
  id: Scalars['ID']['input'];
};

export type IResolversPaymentMethodsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offeringSlug?: InputMaybe<Scalars['String']['input']>;
};

export type IResolversPaymentMethodsApiKeysArgs = {
  offeringSlug?: InputMaybe<Scalars['String']['input']>;
};

export type IResolversPerksArgs = {
  offeringSlug: Scalars['String']['input'];
};

export type IResolversPortfolioArgs = {
  entityId?: InputMaybe<Scalars['ID']['input']>;
};

export type IResolversRegulationBestInterestQuizArgs = {
  id: Scalars['ID']['input'];
};

export type IResolversRegulationBestInterestQuizzesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<IRegulationBestInterestQuizOrderEnum>>;
  state?: InputMaybe<Array<IRegulationBestInterestQuizStateEnum>>;
};

export type IResolversSavedOfferingsForUserArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  userSlug?: InputMaybe<Scalars['String']['input']>;
};

export type IResolversSecondaryMarketTradingIssuerProfilesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IResolversSecondaryTransactionArgs = {
  adminView?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  issuerView?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IResolversSecondaryTransactionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  assetSymbol?: InputMaybe<Array<Scalars['String']['input']>>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  issuerConfirmed?: InputMaybe<Scalars['Boolean']['input']>;
  issuerProfile?: InputMaybe<Array<Scalars['String']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<ISecondaryTransactionOrderEnum>>;
  scope?: InputMaybe<ISecondaryTransactionScopeEnum>;
  state?: InputMaybe<Array<ISecondaryTransactionStateEnum>>;
};

export type IResolversSliderMediaItemsArgs = {
  offeringSlug: Scalars['String']['input'];
};

export type IResolversSlugArgs = {
  slug: Scalars['String']['input'];
};

export type IResolversStatesArgs = {
  code: Scalars['String']['input'];
};

export type IResolversTeaserPageArgs = {
  slug: Scalars['String']['input'];
};

export type IResolversTeaserPagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  scope: ITeaserPageScopeEnum;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type IResolversTeaserPagesFollowerArgs = {
  teaserPageSlug: Scalars['String']['input'];
};

export type IResolversTermTierArgs = {
  id: Scalars['ID']['input'];
};

export type IResolversTermTiersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offeringSlug?: InputMaybe<Scalars['String']['input']>;
};

export type IResolversTickerInvestmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IResolversUserArgs = {
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type IResolversUserProfileArgs = {
  slug: Scalars['String']['input'];
};

export type IResolversUserProfileIntercomDataArgs = {
  iosParameters?: InputMaybe<IIosParametersInput>;
};

export type IResolversUserSubscriptionArgs = {
  id: Scalars['ID']['input'];
};

export type IResolversUserSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type IResolversUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<IUserFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<IUserOrderEnum>>;
  role?: InputMaybe<Array<IUserRoleEnum>>;
};

export type IResolversXAssetArgs = {
  assetSymbol: Scalars['String']['input'];
};

export type IResolversXAssetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  holdingState?: InputMaybe<IHoldingStateEnum>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<IAssetOrderEnum>>;
  scope?: InputMaybe<IAssetScopeEnum>;
};

export type IResolversXFollowArgs = {
  recordType: IFollowRecordTypeEnum;
  slug: Scalars['String']['input'];
};

export type IResolversXInvestorProfileArgs = {
  userSlug?: InputMaybe<Scalars['String']['input']>;
};

export type IResolversXIssuerApplicationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IResolversXOfferingsSearchArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  searchParams: IOfferingSearchInput;
};

export type IResolversXOnboardingSidebarArgs = {
  offeringSlug: Scalars['String']['input'];
};

export type IResolversZeroHashAssetsArgs = {
  offeringSlug: Scalars['String']['input'];
};

/** Input for CoveredPerson or CoveredEntity model */
export type IRoleCoveredCoreCreateInput = {
  /** Nested address attributes */
  addressAttributes?: InputMaybe<IAddressInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Email of contact person */
  contactEmail?: InputMaybe<Scalars['Email']['input']>;
  /** First name of contact person */
  contactFirstName?: InputMaybe<Scalars['String']['input']>;
  /** Last name of contact person */
  contactLastName?: InputMaybe<Scalars['String']['input']>;
  /** Phone number of contact person */
  contactPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  dateOfIncorporation?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  documentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** email for officer(primary only) */
  email?: InputMaybe<Scalars['Email']['input']>;
  /** First name of AP */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** ID if Core exists */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Primary officer or not */
  isPrimaryOfficer?: InputMaybe<Scalars['Boolean']['input']>;
  /** Kind of Core(Person or Entity) */
  kind: IRoleCoveredCoreKindsEnum;
  /** Last name of AP */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Company(entity) name */
  legalCompanyName?: InputMaybe<Scalars['String']['input']>;
  /** Type of entity */
  legalType?: InputMaybe<IRoleLegalTypesEnum>;
  /** If legal type Other provide type */
  legalTypeOtherValue?: InputMaybe<Scalars['String']['input']>;
  offeringSlug: Scalars['String']['input'];
  /** % of ownership */
  ownershipPercentage?: InputMaybe<Scalars['String']['input']>;
  /** Passport of AP(Non-US only) */
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  /** Phone number of AP */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** Role of AP */
  role?: InputMaybe<Scalars['String']['input']>;
  /** SSN of AP(US only) */
  ssn?: InputMaybe<Scalars['String']['input']>;
  /** EIN of entity */
  taxIdNumber?: InputMaybe<Scalars['String']['input']>;
  usBased: Scalars['Boolean']['input'];
};

/** Autogenerated return type of RoleCoveredCoreCreate. */
export type IRoleCoveredCoreCreatePayload = {
  __typename?: 'RoleCoveredCoreCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  coveredCore?: Maybe<IRoleCoveredCoreUnion>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Autogenerated input type of RoleCoveredCoreDelete */
export type IRoleCoveredCoreDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  coveredCoreId: Scalars['ID']['input'];
  coveredCoreKind: IRoleCoveredCoreKindsEnum;
  offeringSlug: Scalars['String']['input'];
};

/** Autogenerated return type of RoleCoveredCoreDelete. */
export type IRoleCoveredCoreDeletePayload = {
  __typename?: 'RoleCoveredCoreDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  coveredCore?: Maybe<IRoleCoveredCoreUnion>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Document for Covered Person or Entity document */
export type IRoleCoveredCoreDocument = INode & {
  __typename?: 'RoleCoveredCoreDocument';
  attachmentContentType?: Maybe<Scalars['String']['output']>;
  attachmentFileName?: Maybe<Scalars['String']['output']>;
  attachmentFileSize?: Maybe<Scalars['BigInt']['output']>;
  attachmentUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  attachmentUrl?: Maybe<Scalars['Url']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  documentType: IRoleCoveredCoreDocumentTypeEnum;
  id: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of RoleCoveredCoreDocumentCreate */
export type IRoleCoveredCoreDocumentCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  directUploadAttachmentUuid: Scalars['String']['input'];
  documentType: IRoleCoveredCoreDocumentTypeEnum;
  offeringSlug: Scalars['String']['input'];
};

/** Autogenerated return type of RoleCoveredCoreDocumentCreate. */
export type IRoleCoveredCoreDocumentCreatePayload = {
  __typename?: 'RoleCoveredCoreDocumentCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  document?: Maybe<IRoleCoveredCoreDocument>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Autogenerated input type of RoleCoveredCoreDocumentDelete */
export type IRoleCoveredCoreDocumentDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  documentId: Scalars['ID']['input'];
  offeringSlug: Scalars['String']['input'];
};

/** Autogenerated return type of RoleCoveredCoreDocumentDelete. */
export type IRoleCoveredCoreDocumentDeletePayload = {
  __typename?: 'RoleCoveredCoreDocumentDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  document?: Maybe<IRoleCoveredCoreDocument>;
  errors?: Maybe<Array<IValidationError>>;
};

export enum IRoleCoveredCoreDocumentTypeEnum {
  Other = 'OTHER',
  PassportOrLicense = 'PASSPORT_OR_LICENSE',
  ProofOfAddress = 'PROOF_OF_ADDRESS',
}

export enum IRoleCoveredCoreKindsEnum {
  CoveredEntity = 'COVERED_ENTITY',
  CoveredPerson = 'COVERED_PERSON',
}

/** Different kinds of Covered kinds(Person or Entity) item */
export type IRoleCoveredCoreUnion = IRoleCoveredEntity | IRoleCoveredPerson;

/** Input for CoveredPerson or CoveredEntity model */
export type IRoleCoveredCoreUpdateInput = {
  /** Nested address attributes */
  addressAttributes?: InputMaybe<IAddressInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Email of contact person */
  contactEmail?: InputMaybe<Scalars['Email']['input']>;
  /** First name of contact person */
  contactFirstName?: InputMaybe<Scalars['String']['input']>;
  /** Last name of contact person */
  contactLastName?: InputMaybe<Scalars['String']['input']>;
  /** Phone number of contact person */
  contactPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  dateOfIncorporation?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  documentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** email for officer(primary only) */
  email?: InputMaybe<Scalars['Email']['input']>;
  /** First name of AP */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** ID if Core exists */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Primary officer or not */
  isPrimaryOfficer?: InputMaybe<Scalars['Boolean']['input']>;
  /** Kind of Core(Person or Entity) */
  kind: IRoleCoveredCoreKindsEnum;
  /** Last name of AP */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Company(entity) name */
  legalCompanyName?: InputMaybe<Scalars['String']['input']>;
  /** Type of entity */
  legalType?: InputMaybe<IRoleLegalTypesEnum>;
  /** If legal type Other provide type */
  legalTypeOtherValue?: InputMaybe<Scalars['String']['input']>;
  offeringSlug: Scalars['String']['input'];
  /** % of ownership */
  ownershipPercentage?: InputMaybe<Scalars['String']['input']>;
  /** Passport of AP(Non-US only) */
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  /** Phone number of AP */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** Role of AP */
  role?: InputMaybe<Scalars['String']['input']>;
  /** SSN of AP(US only) */
  ssn?: InputMaybe<Scalars['String']['input']>;
  /** EIN of entity */
  taxIdNumber?: InputMaybe<Scalars['String']['input']>;
  usBased: Scalars['Boolean']['input'];
};

/** Autogenerated return type of RoleCoveredCoreUpdate. */
export type IRoleCoveredCoreUpdatePayload = {
  __typename?: 'RoleCoveredCoreUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  coveredCore?: Maybe<IRoleCoveredCoreUnion>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Roles Covered Entity Type in Core Domain */
export type IRoleCoveredEntity = INode & {
  __typename?: 'RoleCoveredEntity';
  address?: Maybe<IAddress>;
  contactEmail?: Maybe<Scalars['Email']['output']>;
  contactFirstName?: Maybe<Scalars['String']['output']>;
  contactLastName?: Maybe<Scalars['String']['output']>;
  contactPhoneNumber?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  dateOfIncorporation?: Maybe<Scalars['ISO8601DateTime']['output']>;
  documents?: Maybe<Array<IRoleCoveredCoreDocument>>;
  id: Scalars['ID']['output'];
  issuerProfile: IIssuerProfile;
  legalCompanyName?: Maybe<Scalars['String']['output']>;
  legalType?: Maybe<IRoleLegalTypesEnum>;
  legalTypeOtherValue?: Maybe<Scalars['String']['output']>;
  ownershipPercentage?: Maybe<Scalars['Int']['output']>;
  taxIdNumber: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  usBased: Scalars['Boolean']['output'];
};

/** Roles Covered Person Type in Core Domain */
export type IRoleCoveredPerson = INode & {
  __typename?: 'RoleCoveredPerson';
  address?: Maybe<IAddress>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  dateOfBirth?: Maybe<Scalars['ISO8601DateTime']['output']>;
  documentType?: Maybe<IRoleDocumentTypesEnum>;
  documentValue?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<IRoleCoveredCoreDocument>>;
  email?: Maybe<Scalars['Email']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isPrimaryOfficer?: Maybe<Scalars['Boolean']['output']>;
  issuerProfile?: Maybe<IIssuerProfile>;
  lastName?: Maybe<Scalars['String']['output']>;
  offeringAssociatedPersonUuid?: Maybe<Scalars['String']['output']>;
  ownershipPercentage?: Maybe<Scalars['Int']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  usBased: Scalars['Boolean']['output'];
};

/** The connection type for RoleCoveredPerson. */
export type IRoleCoveredPersonConnection = {
  __typename?: 'RoleCoveredPersonConnection';
  /** A list of edges. */
  edges: Array<IRoleCoveredPersonEdge>;
  /** A list of nodes. */
  nodes: Array<IRoleCoveredPerson>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IRoleCoveredPersonEdge = {
  __typename?: 'RoleCoveredPersonEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: IRoleCoveredPerson;
};

export enum IRoleDocumentTypesEnum {
  Passport = 'PASSPORT',
  Ssn = 'SSN',
}

export enum IRoleLegalTypesEnum {
  CCorp = 'C_CORP',
  Foundation = 'FOUNDATION',
  HoldingsCompany = 'HOLDINGS_COMPANY',
  Limited = 'LIMITED',
  Llc = 'LLC',
  Other = 'OTHER',
  Partnership = 'PARTNERSHIP',
  PrivateLimited = 'PRIVATE_LIMITED',
  SCorp = 'S_CORP',
  Trust = 'TRUST',
}

/** Secondary market granting action */
export type ISecondaryAction = {
  __typename?: 'SecondaryAction';
  actionType?: Maybe<ISecondaryActionTypeEnum>;
  costBasisCents?: Maybe<Scalars['BigInt']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  estimatedValueCents?: Maybe<Scalars['BigInt']['output']>;
  faceValueCents?: Maybe<Scalars['BigInt']['output']>;
  id: Scalars['ID']['output'];
  returnOnInvestmentCents?: Maybe<Scalars['BigInt']['output']>;
  source?: Maybe<ISecondaryActionSourceUnion>;
  transactionDisplayName?: Maybe<Scalars['String']['output']>;
};

/** The connection type for SecondaryAction. */
export type ISecondaryActionConnection = {
  __typename?: 'SecondaryActionConnection';
  /** A list of edges. */
  edges: Array<ISecondaryActionEdge>;
  /** A list of nodes. */
  nodes: Array<ISecondaryAction>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ISecondaryActionEdge = {
  __typename?: 'SecondaryActionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ISecondaryAction;
};

/** Possible types of action source */
export type ISecondaryActionSourceUnion =
  | IInvestment
  | ISecondaryIndicativeValuation
  | ISecondaryTransaction;

/** Types of actions */
export enum ISecondaryActionTypeEnum {
  Investment = 'INVESTMENT',
  SecondaryTransaction = 'SECONDARY_TRANSACTION',
}

export type ISecondaryBuyOrderCreationSummary = {
  __typename?: 'SecondaryBuyOrderCreationSummary';
  availablePaymentMethods: Array<ISecondaryBuyOrderPaymentMethod>;
  hasRepublicWallet: Scalars['Boolean']['output'];
};

export type ISecondaryBuyOrderPaymentMethod = {
  __typename?: 'SecondaryBuyOrderPaymentMethod';
  id: Scalars['ID']['output'];
  type: ISecondaryBuyOrderPaymentMethodTypeEnum;
  usdcTokenAddress?: Maybe<Scalars['String']['output']>;
};

export enum ISecondaryBuyOrderPaymentMethodTypeEnum {
  RepublicWalletUsdc = 'REPUBLIC_WALLET_USDC',
}

/** Autogenerated input type of SecondaryCancelOrder */
export type ISecondaryCancelOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of SecondaryCancelOrder. */
export type ISecondaryCancelOrderPayload = {
  __typename?: 'SecondaryCancelOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  order?: Maybe<ISecondaryOrder>;
};

/** Autogenerated input type of SecondaryCreateSellOrder */
export type ISecondaryCreateSellOrderInput = {
  acceptPartial: Scalars['Boolean']['input'];
  askingPriceCents: Scalars['BigInt']['input'];
  assetId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  paymentMethodId?: InputMaybe<Scalars['ID']['input']>;
  payoutMethodId?: InputMaybe<Scalars['ID']['input']>;
  privacyPolicyAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  tosAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  transferAgreementAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  units: Scalars['BigInt']['input'];
};

/** Autogenerated return type of SecondaryCreateSellOrder. */
export type ISecondaryCreateSellOrderPayload = {
  __typename?: 'SecondaryCreateSellOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  sellOrder?: Maybe<ISecondaryOrder>;
};

/** Autogenerated input type of SecondaryEditOrder */
export type ISecondaryEditOrderInput = {
  acceptPartial?: InputMaybe<Scalars['Boolean']['input']>;
  askingPriceCents?: InputMaybe<Scalars['BigInt']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  paymentMethodId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of SecondaryEditOrder. */
export type ISecondaryEditOrderPayload = {
  __typename?: 'SecondaryEditOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  order?: Maybe<ISecondaryOrder>;
};

/** Secondary market granting action */
export type ISecondaryGrantingAction = {
  __typename?: 'SecondaryGrantingAction';
  actionType?: Maybe<ISecondaryGrantingActionTypeEnum>;
  costBasisCents?: Maybe<Scalars['BigInt']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  estimatedValueCents?: Maybe<Scalars['BigInt']['output']>;
  event: ISecondaryGrantingActionEventEnum;
  faceValueCents?: Maybe<Scalars['BigInt']['output']>;
  grantedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** @deprecated Not used anymore */
  returnOnInvestmentCents?: Maybe<Scalars['BigInt']['output']>;
  source?: Maybe<ISecondaryGrantingActionSourceUnion>;
  /** @deprecated Use the 'event' field instead */
  transactionDisplayName: Scalars['String']['output'];
};

/** The connection type for SecondaryGrantingAction. */
export type ISecondaryGrantingActionConnection = {
  __typename?: 'SecondaryGrantingActionConnection';
  /** A list of edges. */
  edges: Array<ISecondaryGrantingActionEdge>;
  /** A list of nodes. */
  nodes: Array<ISecondaryGrantingAction>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ISecondaryGrantingActionEdge = {
  __typename?: 'SecondaryGrantingActionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ISecondaryGrantingAction;
};

/** Granting action events */
export enum ISecondaryGrantingActionEventEnum {
  Invested = 'INVESTED',
  PurchasedOnMarket = 'PURCHASED_ON_MARKET',
  SoldOnMarket = 'SOLD_ON_MARKET',
}

/** Possible types of action source */
export type ISecondaryGrantingActionSourceUnion =
  | IInvestment
  | ISecondaryIndicativeValuation
  | ISecondaryTransaction;

/** Types of actions */
export enum ISecondaryGrantingActionTypeEnum {
  Investment = 'INVESTMENT',
  SecondaryTransaction = 'SECONDARY_TRANSACTION',
}

/** One single indicative valuation */
export type ISecondaryIndicativeValuation = {
  __typename?: 'SecondaryIndicativeValuation';
  /**
   * Change Percentage = (Valuation - Initial Valuation / Initial Valuation) * 100
   * rounded to 4 decimal points eg. -0.075 means -7.5%
   */
  changePercentage: Scalars['BigDecimal']['output'];
  id: Scalars['ID']['output'];
  valuationCents: Scalars['BigInt']['output'];
  valuationDate: Scalars['ISO8601Date']['output'];
  /**
   * Change Percentage = (Valuation - Initial Valuation / Initial Valuation) * 100
   * rounded to 4 decimal points eg. -0.075 means -7.5%
   * @deprecated Use changePercentage for most accurate field type
   */
  xChangePercentage: Scalars['String']['output'];
};

/** Autogenerated input type of SecondaryIssuerConfirmSecondaryTransactions */
export type ISecondaryIssuerConfirmSecondaryTransactionsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  ids: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of SecondaryIssuerConfirmSecondaryTransactions. */
export type ISecondaryIssuerConfirmSecondaryTransactionsPayload = {
  __typename?: 'SecondaryIssuerConfirmSecondaryTransactionsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  secondaryTransactions?: Maybe<Array<ISecondaryTransaction>>;
};

/** Issuer Profile Type in Core Domain */
export type ISecondaryIssuerProfile = {
  __typename?: 'SecondaryIssuerProfile';
  companyName?: Maybe<Scalars['String']['output']>;
  companySymbol: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  investorCount: Scalars['Int']['output'];
  latestIndicativeValuation?: Maybe<ISecondaryIndicativeValuation>;
  logoContentType?: Maybe<Scalars['String']['output']>;
  logoFileName?: Maybe<Scalars['String']['output']>;
  logoFileSize?: Maybe<Scalars['BigInt']['output']>;
  logoUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  logoUrl?: Maybe<Scalars['Url']['output']>;
  sellOrdersAmountCents?: Maybe<Scalars['BigInt']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  tradableAssetTypes?: Maybe<Array<IAssetTypeEnum>>;
  userHasHoldings: Scalars['Boolean']['output'];
  userHeldUnits?: Maybe<Scalars['BigInt']['output']>;
  userHeldValueCents?: Maybe<Scalars['BigInt']['output']>;
};

/** Issuer Profile Type in Core Domain */
export type ISecondaryIssuerProfileLogoUrlArgs = {
  extension?: InputMaybe<IFileProcessingImgproxyExtensionEnum>;
  gravity?: InputMaybe<IFileProcessingImgproxyGravityEnum>;
  resize?: InputMaybe<IFileProcessingImgproxyResizeInput>;
};

/** The connection type for SecondaryIssuerProfile. */
export type ISecondaryIssuerProfileConnection = {
  __typename?: 'SecondaryIssuerProfileConnection';
  /** A list of edges. */
  edges: Array<ISecondaryIssuerProfileEdge>;
  /** A list of nodes. */
  nodes: Array<ISecondaryIssuerProfile>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ISecondaryIssuerProfileEdge = {
  __typename?: 'SecondaryIssuerProfileEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ISecondaryIssuerProfile;
};

/** A summary for the current state of the secondary market */
export type ISecondaryMarketSummaryType = {
  __typename?: 'SecondaryMarketSummaryType';
  companiesCount: Scalars['Int']['output'];
  sellRequestsCount: Scalars['Int']['output'];
  sellRequestsTotalAmountCents: Scalars['BigInt']['output'];
};

/** Secondary market order */
export type ISecondaryOrder = {
  __typename?: 'SecondaryOrder';
  acceptPartial: Scalars['Boolean']['output'];
  activeSecondaryTransaction?: Maybe<ISecondaryTransaction>;
  allocations?: Maybe<Array<ISecondaryOrderAllocation>>;
  askingPriceCents: Scalars['BigInt']['output'];
  asset?: Maybe<IAssetInterface>;
  /** @deprecated Not used anymore */
  assetType: IAssetTypeEnum;
  cancellationReasonType?: Maybe<ISecondaryOrderCancellationReasonTypesEnum>;
  cancelledAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  completedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** @deprecated No seller confirmation from seller anymore */
  confirmationDeadline?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** @deprecated No seller confirmation from seller anymore */
  confirmationRequired: Scalars['Boolean']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  expiredAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  feeCents?: Maybe<Scalars['BigInt']['output']>;
  /** The actual units that were filled as part of the order. For a full order this equals units. */
  filledAmountCents?: Maybe<Scalars['BigInt']['output']>;
  /** Issuer consent collected */
  hasIssuerSignedTransferAgreement: Scalars['Boolean']['output'];
  hasToReconfirmInterestToSell: Scalars['Boolean']['output'];
  holdings?: Maybe<Array<IHolding>>;
  id: Scalars['ID']['output'];
  impliedValuationCents?: Maybe<Scalars['BigInt']['output']>;
  interestToSellReconfirmedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  investorProfile?: Maybe<IInvestorProfile>;
  issuerConsentCollectedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** @deprecated Not used anymore */
  offering: IOffering;
  orderState: ISecondaryOrderStateEnum;
  /** @deprecated Use orderState instead */
  orderStatus: ISecondaryOrderStatusEnum;
  orderType: ISecondaryOrderTypeEnum;
  /** @deprecated Use xPaymentMethod instead */
  paymentMethod?: Maybe<IPaymentMethodUnion>;
  payoutMethod?: Maybe<ISecondarySellOrderPayoutMethod>;
  percentChange?: Maybe<Scalars['String']['output']>;
  placedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  privacyPolicyAcceptedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  secondariesTaRecord?: Maybe<ISecondaryTransferAgreementRecord>;
  secondaryTransactions?: Maybe<Array<ISecondaryTransaction>>;
  termsOfServicesAcceptedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  units: Scalars['BigInt']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /** @deprecated Use holdings instead */
  xHoldings?: Maybe<Array<IHolding>>;
  /** @deprecated Not used anymore */
  xOffering?: Maybe<IOffering>;
  xPaymentMethod?: Maybe<ISecondaryBuyOrderPaymentMethod>;
};

/** Secondary market allocation */
export type ISecondaryOrderAllocation = {
  __typename?: 'SecondaryOrderAllocation';
  filledUnits?: Maybe<Scalars['BigInt']['output']>;
  id: Scalars['ID']['output'];
  state: ISecondaryOrderAllocationStateEnum;
  units: Scalars['BigInt']['output'];
};

/** List of available State Enums of Allocation objects */
export enum ISecondaryOrderAllocationStateEnum {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Filled = 'FILLED',
  Pending = 'PENDING',
}

/** List of possible reason types for Order objects */
export enum ISecondaryOrderCancellationReasonTypesEnum {
  FaliedPayment = 'FALIED_PAYMENT',
  SuspiciousPayment = 'SUSPICIOUS_PAYMENT',
  TimeoutToSendPayment = 'TIMEOUT_TO_SEND_PAYMENT',
  UserRequest = 'USER_REQUEST',
}

/** The connection type for SecondaryOrder. */
export type ISecondaryOrderConnection = {
  __typename?: 'SecondaryOrderConnection';
  /** A list of edges. */
  edges: Array<ISecondaryOrderEdge>;
  /** A list of nodes. */
  nodes: Array<ISecondaryOrder>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ISecondaryOrderEdge = {
  __typename?: 'SecondaryOrderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ISecondaryOrder;
};

/** List of available Order Enums of Order objects */
export enum ISecondaryOrderOrderEnum {
  AskingPriceCentsAsc = 'ASKING_PRICE_CENTS_ASC',
  AskingPriceCentsDesc = 'ASKING_PRICE_CENTS_DESC',
  CancelledAtAsc = 'CANCELLED_AT_ASC',
  CancelledAtDesc = 'CANCELLED_AT_DESC',
  CompletedAtAsc = 'COMPLETED_AT_ASC',
  CompletedAtDesc = 'COMPLETED_AT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ImpliedValuationCentsAsc = 'IMPLIED_VALUATION_CENTS_ASC',
  ImpliedValuationCentsDesc = 'IMPLIED_VALUATION_CENTS_DESC',
  IssuerProfileNameAsc = 'ISSUER_PROFILE_NAME_ASC',
  IssuerProfileNameDesc = 'ISSUER_PROFILE_NAME_DESC',
  NumericalStateAsc = 'NUMERICAL_STATE_ASC',
  NumericalStateDesc = 'NUMERICAL_STATE_DESC',
  OrderTypeAsc = 'ORDER_TYPE_ASC',
  OrderTypeDesc = 'ORDER_TYPE_DESC',
  PlacedAtAsc = 'PLACED_AT_ASC',
  PlacedAtDesc = 'PLACED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
}

/** Possible secondary market scopes */
export enum ISecondaryOrderScopeEnum {
  Admin = 'ADMIN',
  Secondaries = 'SECONDARIES',
}

/** Possible secondary market order states */
export enum ISecondaryOrderStateEnum {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Complete = 'COMPLETE',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  Pending = 'PENDING',
}

/** Possible secondary market order statuses */
export enum ISecondaryOrderStatusEnum {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Complete = 'COMPLETE',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  Pending = 'PENDING',
}

/** Types of orders */
export enum ISecondaryOrderTypeEnum {
  BuyOrder = 'BUY_ORDER',
  SellOrder = 'SELL_ORDER',
}

/** Autogenerated input type of SecondaryReconfirmInterestToSell */
export type ISecondaryReconfirmInterestToSellInput = {
  askingPriceCents?: InputMaybe<Scalars['BigInt']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  privacyPolicyAccepted: Scalars['Boolean']['input'];
  tosAccepted: Scalars['Boolean']['input'];
  transferAgreementAccepted: Scalars['Boolean']['input'];
};

/** Autogenerated return type of SecondaryReconfirmInterestToSell. */
export type ISecondaryReconfirmInterestToSellPayload = {
  __typename?: 'SecondaryReconfirmInterestToSellPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  order?: Maybe<ISecondaryOrder>;
};

/** Autogenerated input type of SecondaryRequestAccess */
export type ISecondaryRequestAccessInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of SecondaryRequestAccess. */
export type ISecondaryRequestAccessPayload = {
  __typename?: 'SecondaryRequestAccessPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  user?: Maybe<IUser>;
};

/** Autogenerated input type of SecondarySellerConfirmSecondaryTransaction */
export type ISecondarySellerConfirmSecondaryTransactionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of SecondarySellerConfirmSecondaryTransaction. */
export type ISecondarySellerConfirmSecondaryTransactionPayload = {
  __typename?: 'SecondarySellerConfirmSecondaryTransactionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  secondaryTransaction?: Maybe<ISecondaryTransaction>;
};

/** Autogenerated input type of SecondarySellOrderCreateBuyOrder */
export type ISecondarySellOrderCreateBuyOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  paymentMethodId: Scalars['ID']['input'];
  privacyPolicyAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  sellOrderId: Scalars['ID']['input'];
  tosAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  transferAgreementAccepted?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of SecondarySellOrderCreateBuyOrder. */
export type ISecondarySellOrderCreateBuyOrderPayload = {
  __typename?: 'SecondarySellOrderCreateBuyOrderPayload';
  buyOrder?: Maybe<ISecondaryOrder>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

export type ISecondarySellOrderCreationSummary = {
  __typename?: 'SecondarySellOrderCreationSummary';
  availablePayoutMethods: Array<ISecondarySellOrderPayoutMethod>;
  hasRepublicWallet: Scalars['Boolean']['output'];
};

export type ISecondarySellOrderPayoutMethod = {
  __typename?: 'SecondarySellOrderPayoutMethod';
  id: Scalars['ID']['output'];
  type: ISecondarySellOrderPayoutMethodTypeEnum;
  usdcTokenAddress?: Maybe<Scalars['String']['output']>;
};

export enum ISecondarySellOrderPayoutMethodTypeEnum {
  RepublicWalletUsdc = 'REPUBLIC_WALLET_USDC',
}

/** Autogenerated input type of SecondaryStartBuyerPayment */
export type ISecondaryStartBuyerPaymentInput = {
  buyOrderId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of SecondaryStartBuyerPayment. */
export type ISecondaryStartBuyerPaymentPayload = {
  __typename?: 'SecondaryStartBuyerPaymentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  order?: Maybe<ISecondaryOrder>;
};

/** Tradability statuses for issuers */
export enum ISecondaryTradabilityEnum {
  Closed = 'CLOSED',
  NotTrading = 'NOT_TRADING',
  Paused = 'PAUSED',
  Trading = 'TRADING',
  Unlisted = 'UNLISTED',
}

/** The transaction details of a secondary transfer */
export type ISecondaryTransaction = {
  __typename?: 'SecondaryTransaction';
  buyOrder: ISecondaryOrder;
  cancellationReason?: Maybe<ISecondaryTransactionCancellationReasonEnum>;
  cancelledAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  expiresAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  finalizedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  grantingActions: Array<ISecondaryGrantingAction>;
  id: Scalars['ID']['output'];
  issuerConfirmed?: Maybe<Scalars['Boolean']['output']>;
  issuerConfirmedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  payoutTransferredToSeller?: Maybe<Scalars['Boolean']['output']>;
  payoutTransferredToSellerAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  sellOrder: ISecondaryOrder;
  sellerConfirmed?: Maybe<Scalars['Boolean']['output']>;
  sellerConfirmedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  state: ISecondaryTransactionStateEnum;
  /** @deprecated Use finalizedAt */
  transactionDate: Scalars['ISO8601DateTime']['output'];
  transferAgreementContentType?: Maybe<Scalars['String']['output']>;
  transferAgreementFileName?: Maybe<Scalars['String']['output']>;
  transferAgreementFileSize?: Maybe<Scalars['BigInt']['output']>;
  transferAgreementUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  transferAgreementUrl?: Maybe<Scalars['Url']['output']>;
  units: Scalars['BigInt']['output'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/** Cancellation reason of the Secondary Transaction */
export enum ISecondaryTransactionCancellationReasonEnum {
  IssuerNotApproved = 'ISSUER_NOT_APPROVED',
  SellerNotApproved = 'SELLER_NOT_APPROVED',
}

/** The connection type for SecondaryTransaction. */
export type ISecondaryTransactionConnection = {
  __typename?: 'SecondaryTransactionConnection';
  /** A list of edges. */
  edges: Array<ISecondaryTransactionEdge>;
  /** A list of nodes. */
  nodes: Array<ISecondaryTransaction>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ISecondaryTransactionEdge = {
  __typename?: 'SecondaryTransactionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ISecondaryTransaction;
};

/** Autogenerated input type of SecondaryTransactionGenerateSecondaryTransferAgreementUrl */
export type ISecondaryTransactionGenerateSecondaryTransferAgreementUrlInput = {
  assetId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  transactionId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of SecondaryTransactionGenerateSecondaryTransferAgreementUrl. */
export type ISecondaryTransactionGenerateSecondaryTransferAgreementUrlPayload =
  {
    __typename?: 'SecondaryTransactionGenerateSecondaryTransferAgreementUrlPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']['output']>;
    errors?: Maybe<Array<IValidationError>>;
    fileUrl: Scalars['Url']['output'];
  };

/** List of available Order Enums of Order objects */
export enum ISecondaryTransactionOrderEnum {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  FinalizedAtAsc = 'FINALIZED_AT_ASC',
  FinalizedAtDesc = 'FINALIZED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IssuerConfirmedAtAsc = 'ISSUER_CONFIRMED_AT_ASC',
  IssuerConfirmedAtDesc = 'ISSUER_CONFIRMED_AT_DESC',
  SellerConfirmedAtAsc = 'SELLER_CONFIRMED_AT_ASC',
  SellerConfirmedAtDesc = 'SELLER_CONFIRMED_AT_DESC',
}

/** Autogenerated input type of SecondaryTransactionProvideCryptoHash */
export type ISecondaryTransactionProvideCryptoHashInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  cryptoTransactionHash: Scalars['String']['input'];
  transactionId: Scalars['ID']['input'];
};

/** Autogenerated return type of SecondaryTransactionProvideCryptoHash. */
export type ISecondaryTransactionProvideCryptoHashPayload = {
  __typename?: 'SecondaryTransactionProvideCryptoHashPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  transaction: ISecondaryTransaction;
};

/** Possible secondary market transaction scopes */
export enum ISecondaryTransactionScopeEnum {
  Admin = 'ADMIN',
  Issuer = 'ISSUER',
}

/** State of the Secondary Transaction */
export enum ISecondaryTransactionStateEnum {
  BuyerPaymentInProgress = 'BUYER_PAYMENT_IN_PROGRESS',
  Cancelled = 'CANCELLED',
  Failed = 'FAILED',
  Finalized = 'FINALIZED',
  PendingAdminApproval = 'PENDING_ADMIN_APPROVAL',
  PendingBuyerPayment = 'PENDING_BUYER_PAYMENT',
  PendingHoldingsUpdate = 'PENDING_HOLDINGS_UPDATE',
  PendingIssuerConfirmation = 'PENDING_ISSUER_CONFIRMATION',
  PendingPayoutToSeller = 'PENDING_PAYOUT_TO_SELLER',
  PendingSellerConfirmation = 'PENDING_SELLER_CONFIRMATION',
  PendingTaConfirmation = 'PENDING_TA_CONFIRMATION',
}

/** Record is being created once seller/byuer/issuer sign Secondaries Transfer Agreement */
export type ISecondaryTransferAgreementRecord = {
  __typename?: 'SecondaryTransferAgreementRecord';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  secondariesTaTemplateId: Scalars['ID']['output'];
};

export type ISecurityConsensusTokenDpa = ISecurityInterface & {
  __typename?: 'SecurityConsensusTokenDpa';
  attachmentUrl?: Maybe<Scalars['Url']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use description instead */
  descriptionForDisplay?: Maybe<Scalars['String']['output']>;
  earlyTokenRepaymentBonusPercentage?: Maybe<Scalars['Float']['output']>;
  escrow: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  interestRatePercentage?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  /** @deprecated Use name instead */
  nameForDisplay?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use shortName instead */
  nameShortForDisplay?: Maybe<Scalars['String']['output']>;
  normalTokenRepaymentBonusPercentage?: Maybe<Scalars['Float']['output']>;
  partialRefundPercentage?: Maybe<Scalars['Float']['output']>;
  partialRefundPeriod?: Maybe<Scalars['Int']['output']>;
  pricePerToken?: Maybe<Scalars['Float']['output']>;
  /** @deprecated Use Union processing instead */
  securityClassName: Scalars['String']['output'];
  shortName: Scalars['String']['output'];
  valuationOrCapLabel?: Maybe<Scalars['String']['output']>;
};

export type ISecurityConvertibleEquity = ISecurityInterface & {
  __typename?: 'SecurityConvertibleEquity';
  attachmentUrl?: Maybe<Scalars['Url']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use description instead */
  descriptionForDisplay?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use discount */
  discountForDisplay?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** @deprecated Use name instead */
  nameForDisplay?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use shortName instead */
  nameShortForDisplay?: Maybe<Scalars['String']['output']>;
  pricePerSecurityCents?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use Union processing instead */
  securityClassName: Scalars['String']['output'];
  shortName: Scalars['String']['output'];
  valuationCapCents?: Maybe<Scalars['Int']['output']>;
  valuationCents?: Maybe<Scalars['Int']['output']>;
  valuationOrCapLabel?: Maybe<Scalars['String']['output']>;
};

export type ISecurityConvertibleNote = ISecurityInterface & {
  __typename?: 'SecurityConvertibleNote';
  attachmentUrl?: Maybe<Scalars['Url']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use description instead */
  descriptionForDisplay?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use discount */
  discountForDisplay?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  interestRatePercentage?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  /** @deprecated Use name instead */
  nameForDisplay?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use shortName instead */
  nameShortForDisplay?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use Union processing instead */
  securityClassName: Scalars['String']['output'];
  shortName: Scalars['String']['output'];
  valuationCapCents?: Maybe<Scalars['Int']['output']>;
  valuationOrCapLabel?: Maybe<Scalars['String']['output']>;
};

export type ISecurityDebtAgreement = ISecurityInterface & {
  __typename?: 'SecurityDebtAgreement';
  attachmentUrl?: Maybe<Scalars['Url']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use description instead */
  descriptionForDisplay?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  interestRatePercentage?: Maybe<Scalars['Float']['output']>;
  minimumReturnMultiple?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  /** @deprecated Use name instead */
  nameForDisplay?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use shortName instead */
  nameShortForDisplay?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use Union processing instead */
  securityClassName: Scalars['String']['output'];
  shortName: Scalars['String']['output'];
  valuationOrCapLabel?: Maybe<Scalars['String']['output']>;
};

export type ISecurityEquity = ISecurityInterface & {
  __typename?: 'SecurityEquity';
  attachmentUrl?: Maybe<Scalars['Url']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use description instead */
  descriptionForDisplay?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** @deprecated Use name instead */
  nameForDisplay?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use shortName instead */
  nameShortForDisplay?: Maybe<Scalars['String']['output']>;
  pricePerSecurityCents?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use Union processing instead */
  securityClassName: Scalars['String']['output'];
  shortName: Scalars['String']['output'];
  valuationCents?: Maybe<Scalars['Int']['output']>;
  valuationOrCapLabel?: Maybe<Scalars['String']['output']>;
};

export type ISecurityGenesisTokenDpa = ISecurityInterface & {
  __typename?: 'SecurityGenesisTokenDpa';
  attachmentUrl?: Maybe<Scalars['Url']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use description instead */
  descriptionForDisplay?: Maybe<Scalars['String']['output']>;
  escrow?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  interestRatePercentage?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  /** @deprecated Use name instead */
  nameForDisplay?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use shortName instead */
  nameShortForDisplay?: Maybe<Scalars['String']['output']>;
  pricePerToken?: Maybe<Scalars['BigDecimal']['output']>;
  /** @deprecated Use Union processing instead */
  securityClassName: Scalars['String']['output'];
  shortName: Scalars['String']['output'];
  valuationOrCapLabel?: Maybe<Scalars['String']['output']>;
};

export type ISecurityInterestsPurchaseAgreement = ISecurityInterface & {
  __typename?: 'SecurityInterestsPurchaseAgreement';
  attachmentUrl?: Maybe<Scalars['Url']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use description instead */
  descriptionForDisplay?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** @deprecated Use name instead */
  nameForDisplay?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use shortName instead */
  nameShortForDisplay?: Maybe<Scalars['String']['output']>;
  pricePerSecurityCents?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use Union processing instead */
  securityClassName: Scalars['String']['output'];
  shortName: Scalars['String']['output'];
  valuationCents?: Maybe<Scalars['Int']['output']>;
  valuationOrCapLabel?: Maybe<Scalars['String']['output']>;
};

export type ISecurityInterface = {
  attachmentUrl?: Maybe<Scalars['Url']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use description instead */
  descriptionForDisplay?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** @deprecated Use name instead */
  nameForDisplay?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use shortName instead */
  nameShortForDisplay?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use Union processing instead */
  securityClassName: Scalars['String']['output'];
  shortName: Scalars['String']['output'];
  valuationOrCapLabel?: Maybe<Scalars['String']['output']>;
};

export type ISecurityMembershipInterest = ISecurityInterface & {
  __typename?: 'SecurityMembershipInterest';
  attachmentUrl?: Maybe<Scalars['Url']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use description instead */
  descriptionForDisplay?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** @deprecated Use name instead */
  nameForDisplay?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use shortName instead */
  nameShortForDisplay?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use Union processing instead */
  securityClassName: Scalars['String']['output'];
  shortName: Scalars['String']['output'];
  valuationCents?: Maybe<Scalars['Int']['output']>;
  valuationOrCapLabel?: Maybe<Scalars['String']['output']>;
};

export type ISecuritySimpleAgreementForFutureToken = ISecurityInterface & {
  __typename?: 'SecuritySimpleAgreementForFutureToken';
  attachmentUrl?: Maybe<Scalars['Url']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use description instead */
  descriptionForDisplay?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use discount */
  discountForDisplay?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** @deprecated Use name instead */
  nameForDisplay?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use shortName instead */
  nameShortForDisplay?: Maybe<Scalars['String']['output']>;
  pricePerToken?: Maybe<Scalars['BigDecimal']['output']>;
  /** @deprecated Use Union processing instead */
  securityClassName: Scalars['String']['output'];
  shortName: Scalars['String']['output'];
  valuationOrCapLabel?: Maybe<Scalars['String']['output']>;
};

export type ISecurityStockPurchaseAgreement = ISecurityInterface & {
  __typename?: 'SecurityStockPurchaseAgreement';
  attachmentUrl?: Maybe<Scalars['Url']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use description instead */
  descriptionForDisplay?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** @deprecated Use name instead */
  nameForDisplay?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use shortName instead */
  nameShortForDisplay?: Maybe<Scalars['String']['output']>;
  pricePerSecurityCents?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use Union processing instead */
  securityClassName: Scalars['String']['output'];
  shortName: Scalars['String']['output'];
  valuationCents?: Maybe<Scalars['Int']['output']>;
  valuationOrCapLabel?: Maybe<Scalars['String']['output']>;
};

export type ISecuritySubscriptionAgreement = ISecurityInterface & {
  __typename?: 'SecuritySubscriptionAgreement';
  attachmentUrl?: Maybe<Scalars['Url']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use description instead */
  descriptionForDisplay?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** @deprecated Use name instead */
  nameForDisplay?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use shortName instead */
  nameShortForDisplay?: Maybe<Scalars['String']['output']>;
  pricePerSecurityCents?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use Union processing instead */
  securityClassName: Scalars['String']['output'];
  shortName: Scalars['String']['output'];
  valuationOrCapLabel?: Maybe<Scalars['String']['output']>;
};

export type ISecurityTokenPurchaseAgreement = ISecurityInterface & {
  __typename?: 'SecurityTokenPurchaseAgreement';
  attachmentUrl?: Maybe<Scalars['Url']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use description instead */
  descriptionForDisplay?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use discount */
  discountForDisplay?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** @deprecated Use name instead */
  nameForDisplay?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use shortName instead */
  nameShortForDisplay?: Maybe<Scalars['String']['output']>;
  pricePerToken?: Maybe<Scalars['BigDecimal']['output']>;
  /** @deprecated Use Union processing instead */
  securityClassName: Scalars['String']['output'];
  shortName: Scalars['String']['output'];
  valuationOrCapLabel?: Maybe<Scalars['String']['output']>;
};

/** Type of security on Brassica side */
export enum ISecurityType {
  Debt = 'DEBT',
  Derivative = 'DERIVATIVE',
  Equity = 'EQUITY',
  Hybrid = 'HYBRID',
}

export type ISecurityUnion =
  | ISecurityConsensusTokenDpa
  | ISecurityConvertibleEquity
  | ISecurityConvertibleNote
  | ISecurityDebtAgreement
  | ISecurityEquity
  | ISecurityGenesisTokenDpa
  | ISecurityInterestsPurchaseAgreement
  | ISecurityMembershipInterest
  | ISecuritySimpleAgreementForFutureToken
  | ISecurityStockPurchaseAgreement
  | ISecuritySubscriptionAgreement
  | ISecurityTokenPurchaseAgreement;

export type ISeedrsAddress = {
  __typename?: 'SeedrsAddress';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
};

export type ISeedrsOffering = INode & {
  __typename?: 'SeedrsOffering';
  address?: Maybe<ISeedrsAddress>;
  allowedInvestorType?: Maybe<Scalars['String']['output']>;
  allowedToInvest?: Maybe<Scalars['Boolean']['output']>;
  businessId?: Maybe<Scalars['Int']['output']>;
  coverImage?: Maybe<IImage>;
  currency?: Maybe<Scalars['String']['output']>;
  customCheckoutInfo?: Maybe<ICustomCheckoutInfo>;
  description?: Maybe<Scalars['String']['output']>;
  equityOffered?: Maybe<Scalars['String']['output']>;
  equityOfferedForInvestmentRaised?: Maybe<Scalars['String']['output']>;
  expiresAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  favourite?: Maybe<Scalars['Boolean']['output']>;
  featured?: Maybe<Scalars['Boolean']['output']>;
  hasBusinessRepresentative?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  introducer?: Maybe<Scalars['Boolean']['output']>;
  investmentRaised?: Maybe<IAmount>;
  investmentSought?: Maybe<IAmount>;
  isCompanyPayer?: Maybe<Scalars['Boolean']['output']>;
  listed?: Maybe<Scalars['Boolean']['output']>;
  listedType?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<IImage>;
  maximumInvestmentSought?: Maybe<IAmount>;
  minimumInvestmentAmount?: Maybe<IAmount>;
  name?: Maybe<Scalars['String']['output']>;
  nthRound?: Maybe<Scalars['Int']['output']>;
  numberOfInvestors?: Maybe<Scalars['Int']['output']>;
  partnerImage?: Maybe<IImage>;
  paymentOptions?: Maybe<Array<IPaymentOption>>;
  percentageAmountRaised?: Maybe<Scalars['String']['output']>;
  preMoneyValuation?: Maybe<IAmount>;
  preemption?: Maybe<Scalars['Boolean']['output']>;
  shareholdingOptions?: Maybe<Array<IShareholdingOption>>;
  slug?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  summary?: Maybe<ISummary>;
  taxEligibility?: Maybe<Scalars['String']['output']>;
  type: IOfferingTypeEnum;
  userInvestmentCapped?: Maybe<Scalars['Boolean']['output']>;
  userMaximumInvestmentAmount?: Maybe<IAmount>;
  userMinimumInvestmentAmount?: Maybe<IAmount>;
  valuationChangePercentage?: Maybe<Scalars['Float']['output']>;
  video?: Maybe<IVideo>;
};

/** The connection type for SeedrsOffering. */
export type ISeedrsOfferingConnection = {
  __typename?: 'SeedrsOfferingConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ISeedrsOfferingEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ISeedrsOffering>>>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
};

/** An edge in a connection. */
export type ISeedrsOfferingEdge = {
  __typename?: 'SeedrsOfferingEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ISeedrsOffering>;
};

export type IShareholdingOption = {
  __typename?: 'ShareholdingOption';
  carryPercentage: Scalars['String']['output'];
  customCarryPercentage: Scalars['String']['output'];
  investmentFee: IInvestmentFee;
  minimumInvestment: IAmount;
  type: Scalars['String']['output'];
};

export type ISlug = INode & {
  __typename?: 'Slug';
  /** ID of the object. */
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
  sluggable: ISlugUnion;
};

/** Possible types of slugs */
export type ISlugUnion =
  | IComment
  | IEventsEvent
  | IHelpCenterArticle
  | ILandingPage
  | IOffering
  | IOfferingUpdate
  | IPressReleasesPress
  | ITagsTag
  | IUser;

export type ISocialAuthInput = {
  authId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  provider: Scalars['String']['input'];
  providerId: Scalars['String']['input'];
};

export type ISpaceWeekBanner = {
  __typename?: 'SpaceWeekBanner';
  user: IUser;
};

/** Possible fund status names for republic capital investment instruments */
export enum IStage {
  Wired = 'WIRED',
  Signed = 'SIGNED',
}

export type IState = INode & {
  __typename?: 'State';
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type IStateChangeConfirmationBanner = {
  __typename?: 'StateChangeConfirmationBanner';
  investment?: Maybe<IInvestment>;
  /** Whether this is a reservation or not */
  reservation: Scalars['Boolean']['output'];
  /** Whether this investment is a waitlist investment */
  waitlist: Scalars['Boolean']['output'];
};

export type IStripeAccount = {
  __typename?: 'StripeAccount';
  id: Scalars['ID']['output'];
  payouts: IStripePayoutConnection;
  stripeId: Scalars['String']['output'];
};

export type IStripeAccountPayoutsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IStripeCharge = {
  __typename?: 'StripeCharge';
  amount: Scalars['Money']['output'];
  amountRefunded: Scalars['Money']['output'];
  captured: Scalars['Boolean']['output'];
  created: Scalars['ISO8601DateTime']['output'];
  disputes: IStripeDisputeConnection;
  failureMessage?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  payout?: Maybe<IStripePayout>;
  refunds: IStripeRefundConnection;
  status: Scalars['String']['output'];
  stripeId: Scalars['String']['output'];
  stripeUrl: Scalars['String']['output'];
};

export type IStripeChargeDisputesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IStripeChargeRefundsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for StripeCharge. */
export type IStripeChargeConnection = {
  __typename?: 'StripeChargeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IStripeChargeEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<IStripeCharge>>>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IStripeChargeEdge = {
  __typename?: 'StripeChargeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<IStripeCharge>;
};

export type IStripeDispute = {
  __typename?: 'StripeDispute';
  amount: Scalars['Money']['output'];
  amountInBalanceTransactions: Scalars['Money']['output'];
  created: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  reason: Scalars['String']['output'];
  status: Scalars['String']['output'];
  stripeId: Scalars['String']['output'];
};

/** The connection type for StripeDispute. */
export type IStripeDisputeConnection = {
  __typename?: 'StripeDisputeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IStripeDisputeEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<IStripeDispute>>>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IStripeDisputeEdge = {
  __typename?: 'StripeDisputeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<IStripeDispute>;
};

/** Autogenerated input type of StripeIntegrationInitializeOnrampSession */
export type IStripeIntegrationInitializeOnrampSessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  destinationAmount?: InputMaybe<Scalars['String']['input']>;
  destinationCurrencies?: InputMaybe<Array<Scalars['String']['input']>>;
  destinationCurrency: Scalars['String']['input'];
  lockWalletAddress?: InputMaybe<Scalars['Boolean']['input']>;
  walletAddress?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of StripeIntegrationInitializeOnrampSession. */
export type IStripeIntegrationInitializeOnrampSessionPayload = {
  __typename?: 'StripeIntegrationInitializeOnrampSessionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  redirectUrl?: Maybe<Scalars['String']['output']>;
};

export type IStripePayout = {
  __typename?: 'StripePayout';
  amount: Scalars['Money']['output'];
  arrivalDate: Scalars['ISO8601DateTime']['output'];
  fundAmericaBulkTransfers: IFundAmericaBulkTransferConnection;
  id: Scalars['ID']['output'];
  manuallyHandled: Scalars['Boolean']['output'];
  status: Scalars['String']['output'];
  stripeId: Scalars['String']['output'];
  stripeUrl: Scalars['String']['output'];
};

export type IStripePayoutFundAmericaBulkTransfersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for StripePayout. */
export type IStripePayoutConnection = {
  __typename?: 'StripePayoutConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IStripePayoutEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<IStripePayout>>>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IStripePayoutEdge = {
  __typename?: 'StripePayoutEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<IStripePayout>;
};

export type IStripeRefund = {
  __typename?: 'StripeRefund';
  amount: Scalars['Money']['output'];
  created: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  stripeId: Scalars['String']['output'];
};

/** The connection type for StripeRefund. */
export type IStripeRefundConnection = {
  __typename?: 'StripeRefundConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IStripeRefundEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<IStripeRefund>>>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IStripeRefundEdge = {
  __typename?: 'StripeRefundEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<IStripeRefund>;
};

export type ISubmitTransferAgentSecurityTransferInput = {
  id: Scalars['String']['input'];
  approvedBy: Scalars['String']['input'];
};

/** Autogenerated input type of SubscribeUserViaEmail */
export type ISubscribeUserViaEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  subscribe: IUserSubscriptionInput;
};

/** Autogenerated return type of SubscribeUserViaEmail. */
export type ISubscribeUserViaEmailPayload = {
  __typename?: 'SubscribeUserViaEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  /** @deprecated Use xLead instead */
  lead?: Maybe<ILead>;
  /** @deprecated Manage redirect logic based on lead field */
  redirectTo?: Maybe<Scalars['Url']['output']>;
  xLead?: Maybe<Scalars['Boolean']['output']>;
};

/** Subscription documents added onto an opportunity */
export type ISubscriptionDocumentType = {
  __typename?: 'SubscriptionDocumentType';
  fileId: Scalars['String']['output'];
  boxPath: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
};

/** A human-readable message */
export type ISuccess = {
  __typename?: 'Success';
  /** add success message */
  message?: Maybe<Scalars['String']['output']>;
};

export type ISummary = {
  __typename?: 'Summary';
  currency: Scalars['String']['output'];
  goLiveDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  investmentAmountMultiple: IAmount;
  investmentSought: IAmount;
  percentageOfEquityOffered: Scalars['String']['output'];
  timelinePdfLocation: Scalars['String']['output'];
};

/** Autogenerated input type of SynchronizeHolding */
export type ISynchronizeHoldingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of SynchronizeHolding. */
export type ISynchronizeHoldingPayload = {
  __typename?: 'SynchronizeHoldingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<IError>;
  holding: IMediciHolding;
};

/** Autogenerated input type of SynchronizeOfferingHolding */
export type ISynchronizeOfferingHoldingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  uuid: Scalars['String']['input'];
};

/** Autogenerated return type of SynchronizeOfferingHolding. */
export type ISynchronizeOfferingHoldingPayload = {
  __typename?: 'SynchronizeOfferingHoldingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<IError>;
  offering: IMediciOffering;
};

export enum ITagsCategoryEnum {
  Alias = 'ALIAS',
  Cause = 'CAUSE',
  Tag = 'TAG',
}

/** Autogenerated input type of TagsCreate */
export type ITagsCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  tag: ITagsTagInput;
};

/** Autogenerated return type of TagsCreate. */
export type ITagsCreatePayload = {
  __typename?: 'TagsCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  tag?: Maybe<ITagsTag>;
};

/** Autogenerated input type of TagsDelete */
export type ITagsDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of TagsDelete. */
export type ITagsDeletePayload = {
  __typename?: 'TagsDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

export type ITagsMutations = {
  /** Create Tag object of Tags domain */
  tagsCreate?: Maybe<ITagsCreatePayload>;
  /** Delete Tag object of Tags domain by id */
  tagsDelete?: Maybe<ITagsDeletePayload>;
  /** Publish Tag object of Tags domain by id */
  tagsPublish?: Maybe<ITagsPublishPayload>;
  /** Unpublish Tag object of Tags domain by id */
  tagsUnpublish?: Maybe<ITagsUnpublishPayload>;
  /** Update Tag object of Tags domain by id */
  tagsUpdate?: Maybe<ITagsUpdatePayload>;
};

export type ITagsMutationsTagsCreateArgs = {
  input: ITagsCreateInput;
};

export type ITagsMutationsTagsDeleteArgs = {
  input: ITagsDeleteInput;
};

export type ITagsMutationsTagsPublishArgs = {
  input: ITagsPublishInput;
};

export type ITagsMutationsTagsUnpublishArgs = {
  input: ITagsUnpublishInput;
};

export type ITagsMutationsTagsUpdateArgs = {
  input: ITagsUpdateInput;
};

export enum ITagsOrderEnum {
  CategoryAsc = 'CATEGORY_ASC',
  CategoryDesc = 'CATEGORY_DESC',
  DisplayNameAsc = 'DISPLAY_NAME_ASC',
  DisplayNameDesc = 'DISPLAY_NAME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  OrderAsc = 'ORDER_ASC',
  OrderDesc = 'ORDER_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
}

/** Autogenerated input type of TagsPublish */
export type ITagsPublishInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of TagsPublish. */
export type ITagsPublishPayload = {
  __typename?: 'TagsPublishPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

export type ITagsResolvers = {
  /** Returns a tag by slug */
  tag?: Maybe<ITagsTag>;
  /** List of Tags with ability to filter the result scope */
  tags: ITagsTagConnection;
};

export type ITagsResolversTagArgs = {
  slug: Scalars['String']['input'];
};

export type ITagsResolversTagsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  categories?: InputMaybe<Array<ITagsCategoryEnum>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  group?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  highlights?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<ITagsOrderEnum>>;
  scope?: InputMaybe<ITagsScopeEnum>;
  slugs?: InputMaybe<Array<Scalars['String']['input']>>;
  states?: InputMaybe<Array<ITagsStateEnum>>;
  vertical?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

export enum ITagsScopeEnum {
  Followed = 'FOLLOWED',
  Popular = 'POPULAR',
  Settings = 'SETTINGS',
}

export enum ITagsStateEnum {
  Created = 'CREATED',
  Hidden = 'HIDDEN',
  Published = 'PUBLISHED',
}

export type ITagsTag = INode & {
  __typename?: 'TagsTag';
  adminComment?: Maybe<Scalars['String']['output']>;
  aliasTargetTag?: Maybe<ITagsTag>;
  blogPostsCount: Scalars['Int']['output'];
  category?: Maybe<ITagsCategoryEnum>;
  childTags: Array<ITagsTag>;
  colorHex?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  detailedDescription?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  eventsCount: Scalars['Int']['output'];
  following?: Maybe<Scalars['Boolean']['output']>;
  followsCount: Scalars['Int']['output'];
  group: Scalars['Boolean']['output'];
  hiddenAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  iconUrl?: Maybe<Scalars['Url']['output']>;
  iconUrlContentType?: Maybe<Scalars['String']['output']>;
  iconUrlFileName?: Maybe<Scalars['String']['output']>;
  iconUrlFileSize?: Maybe<Scalars['BigInt']['output']>;
  iconUrlUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  impact: Scalars['Boolean']['output'];
  investmentsCount?: Maybe<Scalars['Int']['output']>;
  investmentsSum?: Maybe<Scalars['Int']['output']>;
  investorGroupsCount: Scalars['Int']['output'];
  isFollowed: Scalars['Boolean']['output'];
  offeringsCount: Scalars['Int']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  parent?: Maybe<ITagsTag>;
  pressCount: Scalars['Int']['output'];
  publishedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  relatedTags: Array<ITagsTag>;
  slug: Scalars['String']['output'];
  state: ITagsStateEnum;
  topLevelSectorTag?: Maybe<ITagsTag>;
  vertical: Scalars['Boolean']['output'];
};

export type ITagsTagChildTagsArgs = {
  order?: InputMaybe<Array<ITagsOrderEnum>>;
};

export type ITagsTagIconUrlArgs = {
  extension?: InputMaybe<IFileProcessingImgproxyExtensionEnum>;
  gravity?: InputMaybe<IFileProcessingImgproxyGravityEnum>;
  resize?: InputMaybe<IFileProcessingImgproxyResizeInput>;
};

/** The connection type for TagsTag. */
export type ITagsTagConnection = {
  __typename?: 'TagsTagConnection';
  /** A list of edges. */
  edges: Array<ITagsTagEdge>;
  /** A list of nodes. */
  nodes: Array<ITagsTag>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ITagsTagEdge = {
  __typename?: 'TagsTagEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ITagsTag;
};

export type ITagsTagInput = {
  adminComment?: InputMaybe<Scalars['String']['input']>;
  aliasTargetTagId?: InputMaybe<Scalars['ID']['input']>;
  category: ITagsCategoryEnum;
  colorHex?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  detailedDescription?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  group: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  order: Scalars['Int']['input'];
  parentId?: InputMaybe<Scalars['ID']['input']>;
  slug: Scalars['String']['input'];
  vertical: Scalars['Boolean']['input'];
};

/** Autogenerated input type of TagsUnpublish */
export type ITagsUnpublishInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of TagsUnpublish. */
export type ITagsUnpublishPayload = {
  __typename?: 'TagsUnpublishPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Autogenerated input type of TagsUpdate */
export type ITagsUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  tag: ITagsTagInput;
};

/** Autogenerated return type of TagsUpdate. */
export type ITagsUpdatePayload = {
  __typename?: 'TagsUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  tag?: Maybe<ITagsTag>;
};

/** Tax documents added onto an opportunity */
export type ITaxDocumentType = {
  __typename?: 'TaxDocumentType';
  fileId: Scalars['String']['output'];
  boxPath: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  year?: Maybe<Scalars['Int']['output']>;
};

/** Teaser pages */
export type ITeaserPage = INode &
  ITeaserPageTag & {
    __typename?: 'TeaserPage';
    backgroundImageContentType?: Maybe<Scalars['String']['output']>;
    backgroundImageFileName?: Maybe<Scalars['String']['output']>;
    backgroundImageFileSize?: Maybe<Scalars['BigInt']['output']>;
    backgroundImageUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    backgroundImageUrl?: Maybe<Scalars['Url']['output']>;
    companyName: Scalars['String']['output'];
    createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    creator: IUser;
    discardedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    editedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    /** @deprecated Use x version */
    followers: ITeaserPageFollowerConnection;
    followersCount: Scalars['Int']['output'];
    id: Scalars['ID']['output'];
    issuerApplication?: Maybe<IIssuerApplication>;
    logoImageContentType?: Maybe<Scalars['String']['output']>;
    logoImageFileName?: Maybe<Scalars['String']['output']>;
    logoImageFileSize?: Maybe<Scalars['BigInt']['output']>;
    logoImageUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    logoImageUrl?: Maybe<Scalars['Url']['output']>;
    /** @deprecated Use x version */
    newFollowersPastWeekCount: Scalars['Int']['output'];
    offering?: Maybe<IOffering>;
    publishedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    /** @deprecated Use tag instead */
    sector: Scalars['String']['output'];
    slug: Scalars['String']['output'];
    socialLinksUrls: Array<Scalars['Url']['output']>;
    state: ITeaserPageStateEnum;
    summary: Scalars['String']['output'];
    tag?: Maybe<ITagsTag>;
    /** @deprecated Use x version */
    totalAmountReservedCents: Scalars['Int']['output'];
    /** @deprecated Wrong type */
    updatedAt?: Maybe<Scalars['String']['output']>;
    xFollowers?: Maybe<ITeaserPageFollowerConnection>;
    /** @deprecated Use followersCount */
    xFollowersCount?: Maybe<Scalars['Int']['output']>;
    xNewFollowersPastWeekCount?: Maybe<Scalars['Int']['output']>;
    xTotalAmountReservedCents?: Maybe<Scalars['Int']['output']>;
    xUpdatedAt: Scalars['ISO8601DateTime']['output'];
  };

/** Teaser pages */
export type ITeaserPageBackgroundImageUrlArgs = {
  extension?: InputMaybe<IFileProcessingImgproxyExtensionEnum>;
  gravity?: InputMaybe<IFileProcessingImgproxyGravityEnum>;
  resize?: InputMaybe<IFileProcessingImgproxyResizeInput>;
};

/** Teaser pages */
export type ITeaserPageFollowersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Teaser pages */
export type ITeaserPageLogoImageUrlArgs = {
  extension?: InputMaybe<IFileProcessingImgproxyExtensionEnum>;
  gravity?: InputMaybe<IFileProcessingImgproxyGravityEnum>;
  resize?: InputMaybe<IFileProcessingImgproxyResizeInput>;
};

/** Teaser pages */
export type ITeaserPageXFollowersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for TeaserPage. */
export type ITeaserPageConnection = {
  __typename?: 'TeaserPageConnection';
  /** A list of edges. */
  edges: Array<ITeaserPageEdge>;
  /** A list of nodes. */
  nodes: Array<ITeaserPage>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Autogenerated input type of TeaserPageCreate */
export type ITeaserPageCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  teaserPage: ITeaserPageInput;
};

/** Autogenerated return type of TeaserPageCreate. */
export type ITeaserPageCreatePayload = {
  __typename?: 'TeaserPageCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  teaserPage?: Maybe<ITeaserPage>;
};

/** Autogenerated input type of TeaserPageDelete */
export type ITeaserPageDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
};

/** Autogenerated return type of TeaserPageDelete. */
export type ITeaserPageDeletePayload = {
  __typename?: 'TeaserPageDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  teaserPage?: Maybe<ITeaserPage>;
};

/** An edge in a connection. */
export type ITeaserPageEdge = {
  __typename?: 'TeaserPageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ITeaserPage;
};

/** Teaser pages followers */
export type ITeaserPageFollower = INode & {
  __typename?: 'TeaserPageFollower';
  channel: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  email?: Maybe<Scalars['Email']['output']>;
  id: Scalars['ID']['output'];
  intendedInvestmentCents?: Maybe<Scalars['Int']['output']>;
  user?: Maybe<IUser>;
  uuid: Scalars['ID']['output'];
};

/** The connection type for TeaserPageFollower. */
export type ITeaserPageFollowerConnection = {
  __typename?: 'TeaserPageFollowerConnection';
  /** A list of edges. */
  edges: Array<ITeaserPageFollowerEdge>;
  /** A list of nodes. */
  nodes: Array<ITeaserPageFollower>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Autogenerated input type of TeaserPageFollowerCreate */
export type ITeaserPageFollowerCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  follower: ITeaserPageFollowerCreationInput;
};

/** Autogenerated return type of TeaserPageFollowerCreate. */
export type ITeaserPageFollowerCreatePayload = {
  __typename?: 'TeaserPageFollowerCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  follower?: Maybe<ITeaserPageFollower>;
};

/** Input for teaser pages follower create mutation */
export type ITeaserPageFollowerCreationInput = {
  email?: InputMaybe<Scalars['Email']['input']>;
  intendedInvestmentCents?: InputMaybe<Scalars['Int']['input']>;
  teaserPageSlug: Scalars['String']['input'];
  utm?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of TeaserPageFollowerDelete */
export type ITeaserPageFollowerDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  uuid: Scalars['ID']['input'];
};

/** Autogenerated return type of TeaserPageFollowerDelete. */
export type ITeaserPageFollowerDeletePayload = {
  __typename?: 'TeaserPageFollowerDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

/** An edge in a connection. */
export type ITeaserPageFollowerEdge = {
  __typename?: 'TeaserPageFollowerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ITeaserPageFollower;
};

/** Autogenerated input type of TeaserPageFollowersCSV */
export type ITeaserPageFollowersCsvInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
};

/** Autogenerated return type of TeaserPageFollowersCSV. */
export type ITeaserPageFollowersCsvPayload = {
  __typename?: 'TeaserPageFollowersCSVPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  url?: Maybe<Scalars['Url']['output']>;
};

/** Autogenerated input type of TeaserPageFollowerUpdate */
export type ITeaserPageFollowerUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  follower: ITeaserPageFollowerUpdatingInput;
};

/** Autogenerated return type of TeaserPageFollowerUpdate. */
export type ITeaserPageFollowerUpdatePayload = {
  __typename?: 'TeaserPageFollowerUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  follower?: Maybe<ITeaserPageFollower>;
};

/** Input for teaser pages follower update mutation */
export type ITeaserPageFollowerUpdatingInput = {
  email?: InputMaybe<Scalars['Email']['input']>;
  intendedInvestmentCents?: InputMaybe<Scalars['Int']['input']>;
  utm?: InputMaybe<Scalars['String']['input']>;
  uuid: Scalars['ID']['input'];
};

/** Input for teaser page create mutation */
export type ITeaserPageInput = {
  companyName: Scalars['String']['input'];
  directUploadBackgroundImageUuid?: InputMaybe<Scalars['String']['input']>;
  directUploadLogoImageUuid?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Use tagSlug instead */
  sector?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  /** Valid URL is preceded by http:// */
  socialLinksUrls: Array<Scalars['Url']['input']>;
  summary: Scalars['String']['input'];
  tagSlug: Scalars['String']['input'];
};

/** Autogenerated input type of TeaserPagePublish */
export type ITeaserPagePublishInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
};

/** Autogenerated return type of TeaserPagePublish. */
export type ITeaserPagePublishPayload = {
  __typename?: 'TeaserPagePublishPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  teaserPage?: Maybe<ITeaserPage>;
};

export enum ITeaserPageScopeEnum {
  OwnedByUser = 'OWNED_BY_USER',
  Published = 'PUBLISHED',
}

/** Autogenerated input type of TeaserPageSendCreateLink */
export type ITeaserPageSendCreateLinkInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['Email']['input'];
};

/** Autogenerated return type of TeaserPageSendCreateLink. */
export type ITeaserPageSendCreateLinkPayload = {
  __typename?: 'TeaserPageSendCreateLinkPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

export enum ITeaserPageStateEnum {
  Finished = 'FINISHED',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED',
}

export type ITeaserPageTag = {
  tag?: Maybe<ITagsTag>;
};

/** Autogenerated input type of TeaserPageUnpublish */
export type ITeaserPageUnpublishInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
};

/** Autogenerated return type of TeaserPageUnpublish. */
export type ITeaserPageUnpublishPayload = {
  __typename?: 'TeaserPageUnpublishPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  teaserPage?: Maybe<ITeaserPage>;
};

/** Autogenerated input type of TeaserPageUpdate */
export type ITeaserPageUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  teaserPage: ITeaserPageInput;
};

/** Autogenerated return type of TeaserPageUpdate. */
export type ITeaserPageUpdatePayload = {
  __typename?: 'TeaserPageUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  teaserPage?: Maybe<ITeaserPage>;
};

export type ITransactionLedger = {
  __typename?: 'TransactionLedger';
  administrationFee?: Maybe<Scalars['Money']['output']>;
  advances?: Maybe<Scalars['Money']['output']>;
  amount: Scalars['Money']['output'];
  balance: Scalars['Money']['output'];
  commission?: Maybe<Scalars['Money']['output']>;
  date: Scalars['ISO8601DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  discountReduced?: Maybe<Scalars['Money']['output']>;
  escrowFees?: Maybe<Scalars['Money']['output']>;
  giftToEscrow?: Maybe<Scalars['Money']['output']>;
  investmentLink: Scalars['String']['output'];
  investorEmail: Scalars['String']['output'];
  investorName: Scalars['String']['output'];
  offeringName: Scalars['String']['output'];
  paymentMethod: Scalars['String']['output'];
  reimbursement?: Maybe<Scalars['Money']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  stripeFees?: Maybe<Scalars['Money']['output']>;
  transactionType?: Maybe<Scalars['String']['output']>;
  treasuryPrimeId: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

/** The connection type for TransactionLedger. */
export type ITransactionLedgerConnection = {
  __typename?: 'TransactionLedgerConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ITransactionLedgerEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ITransactionLedger>>>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ITransactionLedgerEdge = {
  __typename?: 'TransactionLedgerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ITransactionLedger>;
};

/** Crypto transaction signatures associated with multisignature accounts and transactions */
export type ITransactionSignatureType = INode & {
  __typename?: 'TransactionSignatureType';
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  cryptoMultisigAccountId: Scalars['ID']['output'];
  cryptoTransactionId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  /** Investor profile of signer */
  investorProfile: IInvestorProfile;
  investorProfileId: Scalars['ID']['output'];
  isOwner: Scalars['Boolean']['output'];
  multisigAccount: ICryptoMultisigAccount;
  signed?: Maybe<Scalars['Boolean']['output']>;
  signedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  transaction: ICryptoTransaction;
  walletToken: ICryptoWalletTokenType;
};

export type ITransferAgentAccount = {
  __typename?: 'TransferAgentAccount';
  id: Scalars['ID']['output'];
  accountType: ITransferAgentAccountType;
  ownerId: Scalars['ID']['output'];
  ownerType: ITransferAgentAccountOwnerType;
  accountName?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  vendor: ITransferAgentVendor;
  vendorAccountId: Scalars['ID']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export type ITransferAgentAccountCreateInput = {
  accountType: ITransferAgentAccountType;
  ownerId: Scalars['String']['input'];
  ownerType: ITransferAgentAccountOwnerType;
  accountName?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
};

/** This is the type of the resource that owns the account */
export enum ITransferAgentAccountOwnerType {
  TaUser = 'TA_USER',
  TaEntity = 'TA_ENTITY',
}

/** Determines if this is an investor or an issuer account */
export enum ITransferAgentAccountType {
  Investor = 'INVESTOR',
  Issuer = 'ISSUER',
}

export type ITransferAgentAccountUpdateAttribute = {
  accountName?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
};

export type ITransferAgentAccountUpdateInput = {
  id: Scalars['String']['input'];
  attributes: ITransferAgentAccountUpdateAttribute;
};

export type ITransferAgentAddress = {
  __typename?: 'TransferAgentAddress';
  street: Scalars['String']['output'];
  city: Scalars['String']['output'];
  region: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  countryCode: Scalars['String']['output'];
};

export type ITransferAgentAddressInput = {
  street: Scalars['String']['input'];
  city: Scalars['String']['input'];
  region: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  countryCode: Scalars['String']['input'];
};

/** Status of the user/entity change request */
export enum ITransferAgentChangeRequestStatus {
  Pending = 'PENDING',
  Cancelled = 'CANCELLED',
  Approved = 'APPROVED',
  Denied = 'DENIED',
}

/** Currency codes supported by the Transfer Agent Module */
export enum ITransferAgentCurrencyCode {
  Usd = 'USD',
}

export type ITransferAgentEngagementRequest = {
  __typename?: 'TransferAgentEngagementRequest';
  id: Scalars['ID']['output'];
  status: ITransferAgentEngagementRequestStatus;
  agreementUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  effectiveDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  acceptedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  cancelledAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  terminatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/** Allowed values for the status of a Transfer Agent Engagement Request on Brassica */
export enum ITransferAgentEngagementRequestStatus {
  Pending = 'PENDING',
  Accepted = 'ACCEPTED',
  Cancelled = 'CANCELLED',
  Terminated = 'TERMINATED',
}

export type ITransferAgentEntity = {
  __typename?: 'TransferAgentEntity';
  id: Scalars['ID']['output'];
  owners: Array<ITransferAgentEntityOwner>;
  republicEntityId: Scalars['BigInt']['output'];
  republicEntityType: ITransferAgentRepublicEntityType;
  vendorEntityId: Scalars['ID']['output'];
  vendor: ITransferAgentVendor;
  primaryOfficerId?: Maybe<Scalars['ID']['output']>;
  changeRequests?: Maybe<Array<IEntityChangeRequest>>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export type ITransferAgentEntityChangeRequestChangesInput = {
  fullName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  legalAddress?: InputMaybe<ITransferAgentAddressInput>;
  mailingAddress?: InputMaybe<ITransferAgentAddressInput>;
};

export type ITransferAgentEntityChangeRequestInput = {
  id: Scalars['String']['input'];
  reason: Scalars['String']['input'];
  changes: ITransferAgentEntityChangeRequestChangesInput;
};

export type ITransferAgentEntityCreateInput = {
  fullName: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  legalAddress?: InputMaybe<ITransferAgentAddressInput>;
  mailingAddress?: InputMaybe<ITransferAgentAddressInput>;
  owners: Array<IEntityOwnerInput>;
  republicEntityId: Scalars['BigInt']['input'];
  republicEntityType: ITransferAgentRepublicEntityType;
  vendorId: Scalars['ID']['input'];
  primaryOfficerId?: InputMaybe<Scalars['String']['input']>;
};

export type ITransferAgentEntityOwner = {
  __typename?: 'TransferAgentEntityOwner';
  ownerId: Scalars['ID']['output'];
  ownerType: ITransferAgentEntityOwnerType;
  title?: Maybe<Scalars['String']['output']>;
};

/** Type of the entity owner */
export enum ITransferAgentEntityOwnerType {
  TaUser = 'TA_USER',
  TaEntity = 'TA_ENTITY',
}

export type ITransferAgentEntityUpdateInput = {
  id: Scalars['String']['input'];
  primaryOfficerId?: InputMaybe<Scalars['String']['input']>;
  owners: Array<IEntityOwnerInput>;
};

export type ITransferAgentHolding = {
  __typename?: 'TransferAgentHolding';
  id: Scalars['ID']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  taHoldingId?: Maybe<Scalars['ID']['output']>;
  taRestricted?: Maybe<Scalars['Boolean']['output']>;
  taSettledOn?: Maybe<Scalars['ISO8601DateTime']['output']>;
  taCurrencyCode?: Maybe<Scalars['String']['output']>;
  taOfferingType?: Maybe<ITransferAgentHoldingOfferingType>;
  taApprovedBy?: Maybe<Scalars['ID']['output']>;
  taApprovedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  taVoidedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  taVoidedReason?: Maybe<Scalars['String']['output']>;
  taInvestorAccount?: Maybe<ITransferAgentAccount>;
  taSecurity?: Maybe<ITransferAgentSecurity>;
  taVendor?: Maybe<ITransferAgentVendor>;
  taRefreshedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export type ITransferAgentHoldingCreateInput = {
  republicHoldingId: Scalars['BigInt']['input'];
  taInvestorAccountId: Scalars['String']['input'];
  taSecurityId: Scalars['String']['input'];
  costBasis: Scalars['String']['input'];
  unitCount: Scalars['String']['input'];
  taSettledOn?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  taCurrencyCode?: InputMaybe<Scalars['String']['input']>;
  taOfferingType?: InputMaybe<ITransferAgentHoldingOfferingType>;
  taApprovedBy?: InputMaybe<Scalars['String']['input']>;
  taVoidedReason?: InputMaybe<Scalars['String']['input']>;
};

export type ITransferAgentHoldingFiltersInput = {
  taInvestorAccountId?: InputMaybe<Scalars['String']['input']>;
  republicAssetId?: InputMaybe<Scalars['BigInt']['input']>;
};

/** If the security was purchased under a supported offering type, it may be included here. The purpose of this field is so that offering imposed Transfer Restrictions may be imposed on the holding. */
export enum ITransferAgentHoldingOfferingType {
  RegATier_1 = 'REG_A_TIER_1',
  RegATier_2 = 'REG_A_TIER_2',
  RegCf_4A6 = 'REG_CF_4A6',
  RegD_506B = 'REG_D_506B',
  RegD_506C = 'REG_D_506C',
}

export type ITransferAgentHoldingUpdateInput = {
  taHoldingId: Scalars['String']['input'];
  taSettledOn?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  taApprovedBy?: InputMaybe<Scalars['String']['input']>;
  taVoidedReason?: InputMaybe<Scalars['String']['input']>;
};

/** Type of the entity owner */
export enum ITransferAgentRepublicEntityType {
  Corporation = 'CORPORATION',
  Ira = 'IRA',
  JointSpouse = 'JOINT_SPOUSE',
  JointTenant = 'JOINT_TENANT',
  Llc = 'LLC',
  Partnership = 'PARTNERSHIP',
  Trust = 'TRUST',
  Issuer = 'ISSUER',
}

/** Determines what is the resource type on Republic is related to the TA User */
export enum ITransferAgentRepublicUserType {
  Individual = 'INDIVIDUAL',
  CoveredPerson = 'COVERED_PERSON',
}

export type ITransferAgentSecurity = {
  __typename?: 'TransferAgentSecurity';
  id: Scalars['BigInt']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  taIssuerAccount?: Maybe<ITransferAgentAccount>;
  taSecurityName?: Maybe<Scalars['String']['output']>;
  taSecurityId?: Maybe<Scalars['ID']['output']>;
  taSecurityType?: Maybe<ISecurityType>;
  taMeta?: Maybe<ITransferAgentSecurityMetadata>;
  taEngagementRequests?: Maybe<Array<ITransferAgentEngagementRequest>>;
  taVendor?: Maybe<ITransferAgentVendor>;
};

export type ITransferAgentSecurityCreateInput = {
  republicAssetId: Scalars['BigInt']['input'];
  taIssuerAccountId: Scalars['String']['input'];
  taSecurityName: Scalars['String']['input'];
  taMeta?: InputMaybe<ITransferAgentSecurityMetadataInput>;
};

export type ITransferAgentSecurityEngagementReqRefreshInput = {
  taSecurityId: Scalars['String']['input'];
  returnUrl: Scalars['String']['input'];
};

export type ITransferAgentSecurityFiltersInput = {
  taIssuerAccountId?: InputMaybe<Scalars['String']['input']>;
};

export type ITransferAgentSecurityMetadata = {
  __typename?: 'TransferAgentSecurityMetadata';
  cusip?: Maybe<Scalars['String']['output']>;
  debtCurrencyCode?: Maybe<ITransferAgentCurrencyCode>;
  isDtcEligible?: Maybe<Scalars['Boolean']['output']>;
  isNmsListed?: Maybe<Scalars['Boolean']['output']>;
  isin?: Maybe<Scalars['String']['output']>;
  tradingVenue?: Maybe<Scalars['String']['output']>;
  statementName?: Maybe<Scalars['String']['output']>;
  unitNamePlural?: Maybe<Scalars['String']['output']>;
  unitNameSingular?: Maybe<Scalars['String']['output']>;
  unitPrecision?: Maybe<Scalars['Int']['output']>;
};

export type ITransferAgentSecurityMetadataInput = {
  cusip?: InputMaybe<Scalars['String']['input']>;
  debtCurrencyCode?: InputMaybe<ITransferAgentCurrencyCode>;
  isDtcEligible?: InputMaybe<Scalars['Boolean']['input']>;
  isNmsListed?: InputMaybe<Scalars['Boolean']['input']>;
  isin?: InputMaybe<Scalars['String']['input']>;
  tradingVenue?: InputMaybe<Scalars['String']['input']>;
  statementName?: InputMaybe<Scalars['String']['input']>;
  unitNamePlural?: InputMaybe<Scalars['String']['input']>;
  unitNameSingular?: InputMaybe<Scalars['String']['input']>;
  unitPrecision?: InputMaybe<Scalars['Int']['input']>;
};

export type ITransferAgentSecurityTransfer = {
  __typename?: 'TransferAgentSecurityTransfer';
  id: Scalars['ID']['output'];
  senderAccount?: Maybe<ITransferAgentAccount>;
  senderHolding: ITransferAgentHolding;
  recipientAccount?: Maybe<ITransferAgentAccount>;
  recipientHolding: ITransferAgentHolding;
  security: ITransferAgentSecurity;
  vendor: ITransferAgentVendor;
  transferType: ITransferAgentTransferType;
  transferStatus: ITransferAgentTransferStatus;
  totalAmountPaid?: Maybe<Scalars['String']['output']>;
  totalUnits: Scalars['String']['output'];
  restricted: Scalars['Boolean']['output'];
  currencyCode?: Maybe<Scalars['String']['output']>;
  approvedBy?: Maybe<Scalars['ID']['output']>;
  approvedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  settledAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export type ITransferAgentSecurityTransferInput = {
  senderHoldingId: Scalars['BigInt']['input'];
  recipientHoldingId: Scalars['BigInt']['input'];
  recipientEntityId?: InputMaybe<Scalars['BigInt']['input']>;
  recipientEntityType?: InputMaybe<IRepublicInvestorEntityType>;
  totalAmountPaid: Scalars['String']['input'];
  transferType: ITransferAgentTransferType;
  totalUnits: Scalars['String']['input'];
  approvedBy?: InputMaybe<Scalars['String']['input']>;
  isFinal?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ITransferAgentSecurityUpdateInput = {
  taSecurityId: Scalars['String']['input'];
  taSecurityName?: InputMaybe<Scalars['String']['input']>;
  taMeta?: InputMaybe<ITransferAgentSecurityMetadataInput>;
};

/** The current status of this transfer */
export enum ITransferAgentTransferStatus {
  Cancelled = 'CANCELLED',
  Pending = 'PENDING',
  Settled = 'SETTLED',
  TransferAgentReview = 'TRANSFER_AGENT_REVIEW',
}

/** Identifies the type of this transfer */
export enum ITransferAgentTransferType {
  Resale = 'RESALE',
  Beneficiary = 'BENEFICIARY',
}

export type ITransferAgentUser = {
  __typename?: 'TransferAgentUser';
  id: Scalars['ID']['output'];
  republicUserId: Scalars['BigInt']['output'];
  republicUserType: ITransferAgentRepublicUserType;
  vendorUserId: Scalars['ID']['output'];
  vendor: ITransferAgentVendor;
  changeRequests?: Maybe<Array<IChangeRequest>>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type ITransferAgentUserChangeRequestChangesInput = {
  fullName?: InputMaybe<Scalars['String']['input']>;
  nameDetails?: InputMaybe<INameDetailsInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  legalAddress?: InputMaybe<ITransferAgentAddressInput>;
  mailingAddress?: InputMaybe<ITransferAgentAddressInput>;
};

export type ITransferAgentUserChangeRequestInput = {
  id: Scalars['String']['input'];
  reason: Scalars['String']['input'];
  changes: ITransferAgentUserChangeRequestChangesInput;
};

export type ITransferAgentUserInput = {
  fullName?: InputMaybe<Scalars['String']['input']>;
  nameDetails?: InputMaybe<INameDetailsInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  legalAddress?: InputMaybe<ITransferAgentAddressInput>;
  mailingAddress?: InputMaybe<ITransferAgentAddressInput>;
  republicUserId: Scalars['BigInt']['input'];
  republicUserType: ITransferAgentRepublicUserType;
  vendorId: Scalars['ID']['input'];
};

export type ITransferAgentVendor = {
  __typename?: 'TransferAgentVendor';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  vendor: ITransferAgentVendorServiceName;
  platform?: Maybe<IRepublicPlatform>;
  status: ITransferAgentVendorStatus;
  apiAccountId?: Maybe<Scalars['String']['output']>;
  apiAccountSlug?: Maybe<Scalars['String']['output']>;
  authExpiration?: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type ITransferAgentVendorCreateInput = {
  name: Scalars['String']['input'];
  vendor: ITransferAgentVendorServiceName;
  platform?: InputMaybe<IRepublicPlatform>;
  status: ITransferAgentVendorStatus;
  apiAccountId?: InputMaybe<Scalars['String']['input']>;
  apiAccountSlug?: InputMaybe<Scalars['String']['input']>;
  apiUrl: Scalars['String']['input'];
  auth?: InputMaybe<Scalars['String']['input']>;
  authExpiration?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};

/** This determines which 3rd-party API will be used by the Transfer Agent Module */
export enum ITransferAgentVendorServiceName {
  Brassica = 'BRASSICA',
}

/** This field tells if a TA vendor is active and can be used, or not */
export enum ITransferAgentVendorStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type ITransferAgentVendorUpdateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  vendor?: InputMaybe<ITransferAgentVendorServiceName>;
  platform?: InputMaybe<IRepublicPlatform>;
  status?: InputMaybe<ITransferAgentVendorStatus>;
  apiAccountId?: InputMaybe<Scalars['String']['input']>;
  apiAccountSlug?: InputMaybe<Scalars['String']['input']>;
  apiUrl?: InputMaybe<Scalars['String']['input']>;
  auth?: InputMaybe<Scalars['String']['input']>;
  authExpiration?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  id: Scalars['String']['input'];
};

export type ITreasuryPrimeAccount = {
  __typename?: 'TreasuryPrimeAccount';
  accountNumber: Scalars['String']['output'];
  accountType: Scalars['String']['output'];
  availableBalance: Scalars['Money']['output'];
  bulkTransfers?: Maybe<IFundAmericaBulkTransferConnection>;
  currentBalance: Scalars['Money']['output'];
  disbursements?: Maybe<IFundAmericaDisbursementConnection>;
  id: Scalars['ID']['output'];
  routingNumber: Scalars['String']['output'];
  transactions?: Maybe<ITreasuryPrimeTransactionConnection>;
  treasuryPrimeId: Scalars['String']['output'];
};

export type ITreasuryPrimeAccountBulkTransfersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ITreasuryPrimeAccountDisbursementsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ITreasuryPrimeAccountTransactionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ITreasuryPrimeAccountApplication = {
  __typename?: 'TreasuryPrimeAccountApplication';
  apiCreatedAt: Scalars['ISO8601DateTime']['output'];
  apiUpdatedAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  status: Scalars['String']['output'];
  treasuryPrimeId: Scalars['String']['output'];
};

/** The connection type for TreasuryPrimeAccountApplication. */
export type ITreasuryPrimeAccountApplicationConnection = {
  __typename?: 'TreasuryPrimeAccountApplicationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ITreasuryPrimeAccountApplicationEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ITreasuryPrimeAccountApplication>>>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ITreasuryPrimeAccountApplicationEdge = {
  __typename?: 'TreasuryPrimeAccountApplicationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ITreasuryPrimeAccountApplication>;
};

/** The connection type for TreasuryPrimeAccount. */
export type ITreasuryPrimeAccountConnection = {
  __typename?: 'TreasuryPrimeAccountConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ITreasuryPrimeAccountEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ITreasuryPrimeAccount>>>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ITreasuryPrimeAccountEdge = {
  __typename?: 'TreasuryPrimeAccountEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ITreasuryPrimeAccount>;
};

export type ITreasuryPrimeAchTransfer = {
  __typename?: 'TreasuryPrimeACHTransfer';
  amount: Scalars['Money']['output'];
  apiCreatedAt: Scalars['ISO8601DateTime']['output'];
  apiUpdatedAt: Scalars['ISO8601DateTime']['output'];
  cleared: Scalars['Boolean']['output'];
  direction: Scalars['String']['output'];
  error?: Maybe<Scalars['String']['output']>;
  expectedClearingDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  owner?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  treasuryPrimeId: Scalars['String']['output'];
};

/** The connection type for TreasuryPrimeACHTransfer. */
export type ITreasuryPrimeAchTransferConnection = {
  __typename?: 'TreasuryPrimeACHTransferConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ITreasuryPrimeAchTransferEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ITreasuryPrimeAchTransfer>>>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ITreasuryPrimeAchTransferEdge = {
  __typename?: 'TreasuryPrimeACHTransferEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ITreasuryPrimeAchTransfer>;
};

export type ITreasuryPrimeBusinessApplication = {
  __typename?: 'TreasuryPrimeBusinessApplication';
  apiCreatedAt: Scalars['ISO8601DateTime']['output'];
  apiUpdatedAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  status: Scalars['String']['output'];
  treasuryPrimeId: Scalars['String']['output'];
};

export type ITreasuryPrimeIncomingWireTransfer = {
  __typename?: 'TreasuryPrimeIncomingWireTransfer';
  amount: Scalars['Money']['output'];
  decryptedJsonBlob?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

/** The connection type for TreasuryPrimeIncomingWireTransfer. */
export type ITreasuryPrimeIncomingWireTransferConnection = {
  __typename?: 'TreasuryPrimeIncomingWireTransferConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ITreasuryPrimeIncomingWireTransferEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ITreasuryPrimeIncomingWireTransfer>>>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ITreasuryPrimeIncomingWireTransferEdge = {
  __typename?: 'TreasuryPrimeIncomingWireTransferEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ITreasuryPrimeIncomingWireTransfer>;
};

export type ITreasuryPrimeTransaction = {
  __typename?: 'TreasuryPrimeTransaction';
  amount: Scalars['Money']['output'];
  balance: Scalars['Money']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  extendedTimestamp?: Maybe<Scalars['String']['output']>;
  fundAmericaInvestmentId?: Maybe<Scalars['Int']['output']>;
  giftToEscrow: Scalars['Money']['output'];
  id: Scalars['ID']['output'];
  incomingWireTransfers?: Maybe<ITreasuryPrimeIncomingWireTransferConnection>;
  offeringId?: Maybe<Scalars['String']['output']>;
  ownerableId?: Maybe<Scalars['Int']['output']>;
  ownerableType?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  transactionDate: Scalars['String']['output'];
  transactionType?: Maybe<Scalars['String']['output']>;
  treasuryPrimeId: Scalars['String']['output'];
};

export type ITreasuryPrimeTransactionIncomingWireTransfersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for TreasuryPrimeTransaction. */
export type ITreasuryPrimeTransactionConnection = {
  __typename?: 'TreasuryPrimeTransactionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ITreasuryPrimeTransactionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ITreasuryPrimeTransaction>>>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ITreasuryPrimeTransactionEdge = {
  __typename?: 'TreasuryPrimeTransactionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ITreasuryPrimeTransaction>;
};

/** Autogenerated input type of UnlikeObject */
export type IUnlikeObjectInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  objectId: Scalars['ID']['input'];
  objectType: ILikeableObjectTypeEnum;
};

/** Autogenerated return type of UnlikeObject. */
export type IUnlikeObjectPayload = {
  __typename?: 'UnlikeObjectPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  unlikedObject?: Maybe<ILikeableObjectUnion>;
};

export type IUser = INode & {
  __typename?: 'User';
  accreditedAccess?: Maybe<IAccreditedAccess>;
  adminDashUrl?: Maybe<Scalars['Url']['output']>;
  authId?: Maybe<Scalars['String']['output']>;
  avatarContentType?: Maybe<Scalars['String']['output']>;
  avatarFileName?: Maybe<Scalars['String']['output']>;
  avatarFileSize?: Maybe<Scalars['BigInt']['output']>;
  avatarUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  avatarUrl?: Maybe<Scalars['Url']['output']>;
  backupCodesData?: Maybe<Array<IMultiFactorAuthenticationBackupCode>>;
  badges?: Maybe<Array<IUserBadge>>;
  calendlyLink?: Maybe<Scalars['Url']['output']>;
  cannotSendMessagesReasons?: Maybe<Array<Scalars['String']['output']>>;
  capabilities?: Maybe<Array<ICapability>>;
  confirmedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** @deprecated Use x version for nullable */
  createdAt: Scalars['ISO8601DateTime']['output'];
  cryptoPublicKey?: Maybe<Scalars['String']['output']>;
  driOfferings: IOfferingConnection;
  /** @deprecated Use x version for nullable */
  editableOfferings: Array<IOffering>;
  email?: Maybe<Scalars['Email']['output']>;
  fcmToken?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  followersCount: Scalars['Int']['output'];
  following: Scalars['Boolean']['output'];
  followsOfferings?: Maybe<Array<IOffering>>;
  followsUsers?: Maybe<Array<IUser>>;
  followsUsersAsTarget?: Maybe<Array<IUser>>;
  forceMultiFactorAuth?: Maybe<Scalars['Boolean']['output']>;
  founder: Scalars['Boolean']['output'];
  fullLegalName?: Maybe<Scalars['String']['output']>;
  fullstoryLink?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  influencer: Scalars['Boolean']['output'];
  investorProfile?: Maybe<IInvestorProfile>;
  iosPushNotificationsEnabled?: Maybe<Scalars['Boolean']['output']>;
  isAdmin: Scalars['Boolean']['output'];
  isFlipperFeatureEnabled?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Find of IssuerApplication in Core (Issuers) domain
   * @deprecated Use x version for nullable
   */
  issuerApplications: IIssuerApplicationConnection;
  /** @deprecated Use x version for nullable */
  issuerProfiles: Array<IIssuerProfile>;
  lastIosAppSignInAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  lastWebSignInAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  loginAsUserUrl?: Maybe<Scalars['Url']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  /** Shows offerings where user is owner(issuer) */
  ownOfferings?: Maybe<Array<IOffering>>;
  privileges?: Maybe<Array<IPrivilege>>;
  referral?: Maybe<IReferralToken>;
  requestedSecondariesAccess?: Maybe<Scalars['Boolean']['output']>;
  requestedSecondariesAccessAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  requiresMultiFactorAuthentication?: Maybe<Scalars['Boolean']['output']>;
  resetPasswordToken?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use x version for nullable */
  role: IUserRoleEnum;
  /** Access to secondaries dashboard */
  secondariesAccessGranted: Scalars['Boolean']['output'];
  secondariesEligible: Scalars['Boolean']['output'];
  secretsData?: Maybe<IMultiFactorAuthenticationSecret>;
  shouldPromptToResendConfirmationEmail?: Maybe<Scalars['Boolean']['output']>;
  signInCount: Scalars['Int']['output'];
  skip2fa?: Maybe<Scalars['Boolean']['output']>;
  slug: Scalars['String']['output'];
  state: Scalars['String']['output'];
  subscriptions?: Maybe<Array<IUserSubscription>>;
  teaserPages?: Maybe<ITeaserPageConnection>;
  unconfirmedEmail?: Maybe<Scalars['Email']['output']>;
  /** @deprecated Use x version for nullable */
  unreadMessagesCount: Scalars['Int']['output'];
  /** @deprecated Use x version for nullable */
  unreadNotificationsCount: Scalars['Int']['output'];
  /** @deprecated Use raw id */
  userId: Scalars['ID']['output'];
  userProfile?: Maybe<IUserProfile>;
  verified?: Maybe<Scalars['Boolean']['output']>;
  xCreatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Shows offerings where user is editor */
  xEditableOfferings?: Maybe<Array<IOffering>>;
  /** Find of IssuerApplication in Core (Issuers) domain */
  xIssuerApplications?: Maybe<IIssuerApplicationConnection>;
  xIssuerProfiles?: Maybe<Array<IIssuerProfile>>;
  xRole?: Maybe<IUserRoleEnum>;
  xUnreadMessagesCount?: Maybe<Scalars['Int']['output']>;
  xUnreadNotificationsCount?: Maybe<Scalars['Int']['output']>;
};

export type IUserAvatarUrlArgs = {
  extension?: InputMaybe<IFileProcessingImgproxyExtensionEnum>;
  gravity?: InputMaybe<IFileProcessingImgproxyGravityEnum>;
  resize?: InputMaybe<IFileProcessingImgproxyResizeInput>;
};

export type IUserDriOfferingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IUserIsFlipperFeatureEnabledArgs = {
  featureName: IFlipperEnum;
};

export type IUserIssuerApplicationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IUserTeaserPagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IUserXIssuerApplicationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Users Badge */
export type IUserBadge = INode & {
  __typename?: 'UserBadge';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  offeringTarget?: Maybe<IOffering>;
  type: IUserBadgeTypeEnum;
};

export enum IUserBadgeTypeEnum {
  ActiveInvestor = 'ACTIVE_INVESTOR',
  Founder = 'FOUNDER',
  Investor = 'INVESTOR',
  PlatinumMember = 'PLATINUM_MEMBER',
  RepublicNoteHolder = 'REPUBLIC_NOTE_HOLDER',
  ValueAddInvestor = 'VALUE_ADD_INVESTOR',
}

export type IUserBasicInfoInput = {
  /**
   * Public URL UUID returned as result of
   *                  fileProcessingPresignUrl payload. Used to sync location
   *                  of stored file in S3 with our backend servers.
   */
  directUploadAvatarUuid?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UserBasicInfoUpdate */
export type IUserBasicInfoUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  info: IUserBasicInfoInput;
};

/** Autogenerated return type of UserBasicInfoUpdate. */
export type IUserBasicInfoUpdatePayload = {
  __typename?: 'UserBasicInfoUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  user?: Maybe<IUser>;
};

/** Autogenerated input type of UserConfirmEmail */
export type IUserConfirmEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  confirmationToken: Scalars['String']['input'];
};

/** Autogenerated return type of UserConfirmEmail. */
export type IUserConfirmEmailPayload = {
  __typename?: 'UserConfirmEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  user?: Maybe<IUser>;
};

/** The connection type for User. */
export type IUserConnection = {
  __typename?: 'UserConnection';
  /** A list of edges. */
  edges: Array<IUserEdge>;
  /** A list of nodes. */
  nodes: Array<IUser>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Users Conversation */
export type IUserConversation = INode & {
  __typename?: 'UserConversation';
  id: Scalars['ID']['output'];
  message: IMessage;
  unseenMessagesExists: Scalars['Boolean']['output'];
  user: IUser;
};

/** The connection type for UserConversation. */
export type IUserConversationConnection = {
  __typename?: 'UserConversationConnection';
  /** A list of edges. */
  edges: Array<IUserConversationEdge>;
  /** A list of nodes. */
  nodes: Array<IUserConversation>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IUserConversationEdge = {
  __typename?: 'UserConversationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: IUserConversation;
};

/** Input for new user creation */
export type IUserCreateInput = {
  directUploadAvatarUuid?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['Email']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

/** An edge in a connection. */
export type IUserEdge = {
  __typename?: 'UserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: IUser;
};

/** Autogenerated input type of UserEmailConfirmationResend */
export type IUserEmailConfirmationResendInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UserEmailConfirmationResend. */
export type IUserEmailConfirmationResendPayload = {
  __typename?: 'UserEmailConfirmationResendPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  user?: Maybe<IUser>;
};

/** Autogenerated input type of UserEmailUpdate */
export type IUserEmailUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['Email']['input'];
  password: Scalars['String']['input'];
};

/** Autogenerated return type of UserEmailUpdate. */
export type IUserEmailUpdatePayload = {
  __typename?: 'UserEmailUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  user?: Maybe<IUser>;
};

/** Different kinds of feed item */
export type IUserFeedItem =
  | IComment
  | IGroupDealPost
  | IOfferingInvestmentReasons
  | IPost;

/** The connection type for UserFeedItem. */
export type IUserFeedItemConnection = {
  __typename?: 'UserFeedItemConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IUserFeedItemEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<IUserFeedItem>>>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
};

/** An edge in a connection. */
export type IUserFeedItemEdge = {
  __typename?: 'UserFeedItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<IUserFeedItem>;
};

/** Input for User Filter */
export type IUserFilterInput = {
  OR?: InputMaybe<Array<IUserFilterInput>>;
  authIds?: InputMaybe<Array<Scalars['String']['input']>>;
  email?: InputMaybe<Scalars['Email']['input']>;
  emailContains?: InputMaybe<Scalars['String']['input']>;
  emailNot?: InputMaybe<Scalars['Email']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  firstNameContains?: InputMaybe<Scalars['String']['input']>;
  firstNameNot?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Array<Scalars['ID']['input']>>;
  idNot?: InputMaybe<Array<Scalars['ID']['input']>>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  lastNameContains?: InputMaybe<Scalars['String']['input']>;
  lastNameNot?: InputMaybe<Scalars['String']['input']>;
  lastSignInAtNotNull?: InputMaybe<Scalars['Boolean']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  nicknameContains?: InputMaybe<Scalars['String']['input']>;
  nicknameNot?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<IUserRoleEnum>;
  roleIn?: InputMaybe<Array<IUserRoleEnum>>;
  roleNot?: InputMaybe<IUserRoleEnum>;
  roleNotIn?: InputMaybe<Array<IUserRoleEnum>>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slugContains?: InputMaybe<Scalars['String']['input']>;
  slugNot?: InputMaybe<Scalars['String']['input']>;
};

/** Input for updating core user info */
export type IUserInput = {
  directUploadAvatarUuid?: InputMaybe<Scalars['String']['input']>;
  fcmToken?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  iosPushNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** This can only be updated by an admin! */
  secondariesAccessGranted?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum IUserInvestorProfileCognitoFlowSessionSourceEnum {
  Custody = 'CUSTODY',
  Fintron = 'FINTRON',
  Invest = 'INVEST',
  Inx = 'INX',
  OldSessionsUpdater = 'OLD_SESSIONS_UPDATER',
  Settings = 'SETTINGS',
}

export type IUserInvestorProfileDiversificationStats = {
  __typename?: 'UserInvestorProfileDiversificationStats';
  distributionInvestmentsColors: Array<Scalars['String']['output']>;
  distributionInvestmentsData: Array<IInvestment>;
  distributionInvestmentsPercents: Array<Scalars['String']['output']>;
  diversificationScoreForChart: IUserInvestorProfileDiversificationStatScore;
  id: Scalars['ID']['output'];
  investmentsPerColumn?: Maybe<Scalars['Int']['output']>;
  rootTags: Array<ITagsTag>;
  tags: Array<ITagsTag>;
  tagsTree: Array<IUserInvestorProfileDiversificationStatTagTree>;
};

export type IUserInvestorProfileDiversificationStatScore = {
  __typename?: 'UserInvestorProfileDiversificationStatScore';
  /** A discrete level attained by the diversification score */
  levelName?: Maybe<IInvestorProfileDiversificationStatsScoreLevelEnum>;
  /** The maximum obtainable diversification score */
  maxScore?: Maybe<Scalars['Int']['output']>;
  /** The diversification score */
  score?: Maybe<Scalars['Int']['output']>;
  /** Hex color associated with the score/level */
  scoreColor?: Maybe<Scalars['String']['output']>;
};

export type IUserInvestorProfileDiversificationStatTagTree = {
  __typename?: 'UserInvestorProfileDiversificationStatTagTree';
  childTags: Array<ITagsTag>;
  id: Scalars['ID']['output'];
  rootTag: ITagsTag;
};

export type IUserInvestorProfileDocument = INode & {
  __typename?: 'UserInvestorProfileDocument';
  attachmentContentType?: Maybe<Scalars['String']['output']>;
  attachmentFileName?: Maybe<Scalars['String']['output']>;
  attachmentFileSize?: Maybe<Scalars['BigInt']['output']>;
  attachmentUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  attachmentUrl?: Maybe<Scalars['Url']['output']>;
  documentType: IUserInvestorProfileDocumentTypeEnum;
  /** ID of the object. */
  id: Scalars['ID']['output'];
};

export type IUserInvestorProfileDocumentInput = {
  directUploadAttachmentUuid: Scalars['String']['input'];
  documentType: IUserInvestorProfileDocumentTypeEnum;
};

export enum IUserInvestorProfileDocumentTypeEnum {
  Other = 'OTHER',
  ProofOfAddress = 'PROOF_OF_ADDRESS',
}

export enum IUserInvestorProfileEmploymentStatusEnum {
  Employed = 'EMPLOYED',
  Retired = 'RETIRED',
  Student = 'STUDENT',
  Unemployed = 'UNEMPLOYED',
}

export type IUserInvestorProfileIdentity = INode & {
  __typename?: 'UserInvestorProfileIdentity';
  address?: Maybe<IAddress>;
  dateOfBirth?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** ID of the object. */
  id: Scalars['ID']['output'];
  /** Internal KYC-AML status */
  identityCheckStatus?: Maybe<IUserInvestorProfileIdentityCheckStatusEnum>;
  kycAmlNameMismatch?: Maybe<Scalars['Boolean']['output']>;
  /** Full name */
  name?: Maybe<Scalars['String']['output']>;
  passportImageProvided?: Maybe<Scalars['Boolean']['output']>;
  phone?: Maybe<Scalars['PhoneNumber']['output']>;
  taxIdNumber?: Maybe<Scalars['Ssn']['output']>;
  usCitizen?: Maybe<Scalars['Boolean']['output']>;
};

export enum IUserInvestorProfileIdentityCheckStatusEnum {
  Blocked = 'BLOCKED',
  DocumentCheckFailed = 'DOCUMENT_CHECK_FAILED',
  DocumentCheckRequired = 'DOCUMENT_CHECK_REQUIRED',
  NameMismatch = 'NAME_MISMATCH',
  NotRequired = 'NOT_REQUIRED',
  Processing = 'PROCESSING',
  Required = 'REQUIRED',
  Verified = 'VERIFIED',
}

export type IUserInvestorProfileIdentityInput = {
  address: IAddressInput;
  dateOfBirth?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  passportImageProvided?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['PhoneNumber']['input']>;
  taxIdNumber?: InputMaybe<Scalars['Ssn']['input']>;
  usCitizen?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum IUserInvestorProfileIncomeChangeStateEnum {
  Available = 'AVAILABLE',
  LastChange = 'LAST_CHANGE',
  NotAvailable = 'NOT_AVAILABLE',
}

export type IUserInvestorProfileInvestmentsStats = {
  __typename?: 'UserInvestorProfileInvestmentsStats';
  companiesInvestedIn: Scalars['Int']['output'];
  /** Same as investor_profile_id */
  id: Scalars['ID']['output'];
  investedPastYear: Scalars['Int']['output'];
  investedTotal: Scalars['Int']['output'];
  showPlaceholder: Scalars['Boolean']['output'];
  yearInvestmentsForChart: Array<Scalars['SeriesDate']['output']>;
  yearInvestmentsForChartPlaceholder: Array<Scalars['SeriesDate']['output']>;
};

export enum IUserInvestorProfileJobIndustryEnum {
  Academic = 'ACADEMIC',
  Accounting = 'ACCOUNTING',
  Administrative = 'ADMINISTRATIVE',
  Advertising = 'ADVERTISING',
  Appraisal = 'APPRAISAL',
  Banking = 'BANKING',
  Biotechnology = 'BIOTECHNOLOGY',
  Broadcasting = 'BROADCASTING',
  CallCenter = 'CALL_CENTER',
  Carpentry = 'CARPENTRY',
  CivilEngineering = 'CIVIL_ENGINEERING',
  Clerical = 'CLERICAL',
  ClientServices = 'CLIENT_SERVICES',
  Construction = 'CONSTRUCTION',
  Counseling = 'COUNSELING',
  CustomerSupport = 'CUSTOMER_SUPPORT',
  DigitalMarketing = 'DIGITAL_MARKETING',
  EducationTraining = 'EDUCATION_TRAINING',
  ElectricalEngineering = 'ELECTRICAL_ENGINEERING',
  FinancialServices = 'FINANCIAL_SERVICES',
  FoodServices = 'FOOD_SERVICES',
  Hospitality = 'HOSPITALITY',
  Hr = 'HR',
  HrTraining = 'HR_TRAINING',
  It = 'IT',
  Journalism = 'JOURNALISM',
  Laboratory = 'LABORATORY',
  LawEnforcement = 'LAW_ENFORCEMENT',
  LegalServices = 'LEGAL_SERVICES',
  Logistics = 'LOGISTICS',
  Manufacturing = 'MANUFACTURING',
  MechanicalEngineering = 'MECHANICAL_ENGINEERING',
  Medical = 'MEDICAL',
  Merchandising = 'MERCHANDISING',
  Nonprofit = 'NONPROFIT',
  Nursing = 'NURSING',
  Operations = 'OPERATIONS',
  Paralegal = 'PARALEGAL',
  Pharmaceuticals = 'PHARMACEUTICALS',
  Plumbing = 'PLUMBING',
  Production = 'PRODUCTION',
  PropertyManagement = 'PROPERTY_MANAGEMENT',
  PublicRelations = 'PUBLIC_RELATIONS',
  RealEstate = 'REAL_ESTATE',
  Recruitment = 'RECRUITMENT',
  Research = 'RESEARCH',
  Retail = 'RETAIL',
  RetailSales = 'RETAIL_SALES',
  Sales = 'SALES',
  Secretarial = 'SECRETARIAL',
  SocialWork = 'SOCIAL_WORK',
  SoftwareDevelopment = 'SOFTWARE_DEVELOPMENT',
  StoreManagement = 'STORE_MANAGEMENT',
  SupplyChain = 'SUPPLY_CHAIN',
  Teaching = 'TEACHING',
  Transportation = 'TRANSPORTATION',
  Travel = 'TRAVEL',
  WebDevelopment = 'WEB_DEVELOPMENT',
  Wholesale = 'WHOLESALE',
  WritingEditing = 'WRITING_EDITING',
}

/** LegalInformation object of Core domain */
export type IUserLegalInformation = INode & {
  __typename?: 'UserLegalInformation';
  /** @deprecated Use legacyApprovedAt instead */
  approvedBasicAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  approvedFullAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  finalizedKycStatus: IUserLegalInformationKycStatusEnum;
  fullLegalName?: Maybe<Scalars['String']['output']>;
  hasPoBoxAddress: Scalars['Boolean']['output'];
  /** @deprecated Use KycActionNeeded instead */
  hasUploadedIdDocument: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** The action the user needs to take to complete KYC. */
  kycActionNeeded?: Maybe<IUserLegalInformationKycActionNeededEnum>;
  kycStatus: IUserLegalInformationKycStatusEnum;
  legacyApprovedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  legalAddress?: Maybe<IAddress>;
  legalFirstName?: Maybe<Scalars['String']['output']>;
  legalLastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use previousLegacyApproved instead */
  previousApprovedBasic: Scalars['Boolean']['output'];
  /** @deprecated Use xPreviousKycStatus */
  previousKycStatus: IUserLegalInformationKycStatusEnum;
  previousLegacyApproved: Scalars['Boolean']['output'];
  xPreviousKycStatus?: Maybe<IUserLegalInformationKycStatusEnum>;
};

export enum IUserLegalInformationKycActionNeededEnum {
  Document = 'DOCUMENT',
  ExpiredDocument = 'EXPIRED_DOCUMENT',
  Pii = 'PII',
  PiiAndDocument = 'PII_AND_DOCUMENT',
}

export enum IUserLegalInformationKycStatusEnum {
  Active = 'ACTIVE',
  /** @deprecated No longer available, use LEGACY_APPROVED instead */
  ApprovedBasic = 'APPROVED_BASIC',
  ApprovedFull = 'APPROVED_FULL',
  Canceled = 'CANCELED',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  FailedBatch = 'FAILED_BATCH',
  LegacyApproved = 'LEGACY_APPROVED',
  NotStarted = 'NOT_STARTED',
  PendingReview = 'PENDING_REVIEW',
}

/** Autogenerated input type of UserLegalInformationUpdate */
export type IUserLegalInformationUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  cognitoSessionId: Scalars['ID']['input'];
};

/** Autogenerated return type of UserLegalInformationUpdate. */
export type IUserLegalInformationUpdatePayload = {
  __typename?: 'UserLegalInformationUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  legalInformation?: Maybe<IUserLegalInformation>;
};

/** Autogenerated input type of UserMfaDisable */
export type IUserMfaDisableInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UserMfaDisable. */
export type IUserMfaDisablePayload = {
  __typename?: 'UserMfaDisablePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  user?: Maybe<IUser>;
};

/** Autogenerated input type of UserMfaEnable */
export type IUserMfaEnableInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  secretKey: Scalars['String']['input'];
};

/** Autogenerated return type of UserMfaEnable. */
export type IUserMfaEnablePayload = {
  __typename?: 'UserMfaEnablePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  jwt?: Maybe<Scalars['String']['output']>;
  mfaBackupCodes?: Maybe<Array<IMultiFactorAuthenticationBackupCode>>;
  showBackupCodes?: Maybe<Scalars['Boolean']['output']>;
  user?: Maybe<IUser>;
};

export type IUserNotificationPreference = {
  __typename?: 'UserNotificationPreference';
  availableFrequencies: Array<Scalars['String']['output']>;
  category: IUserNotificationPreferenceCategoryEnum;
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  frequency: IUserNotificationPreferenceFrequencyEnum;
};

export enum IUserNotificationPreferenceCategoryEnum {
  AccreditedAccess = 'ACCREDITED_ACCESS',
  ApplicationUpdates = 'APPLICATION_UPDATES',
  AroraRepublic = 'ARORA_REPUBLIC',
  CapitalR = 'CAPITAL_R',
  Comments = 'COMMENTS',
  Fig = 'FIG',
  Follows = 'FOLLOWS',
  InvestmentReminders = 'INVESTMENT_REMINDERS',
  InvestorGroupModeratorNotifications = 'INVESTOR_GROUP_MODERATOR_NOTIFICATIONS',
  InvestorGroupUpdates = 'INVESTOR_GROUP_UPDATES',
  Issuer = 'ISSUER',
  Messages = 'MESSAGES',
  Newsletter = 'NEWSLETTER',
  Notifications = 'NOTIFICATIONS',
  Offerings = 'OFFERINGS',
  RepublicAdvisoryServices = 'REPUBLIC_ADVISORY_SERVICES',
  RepublicCapital = 'REPUBLIC_CAPITAL',
  RepublicCore = 'REPUBLIC_CORE',
  RepublicDealRoomAdvisor = 'REPUBLIC_DEAL_ROOM_ADVISOR',
  TrendingOfferings = 'TRENDING_OFFERINGS',
}

export enum IUserNotificationPreferenceFrequencyEnum {
  Daily = 'DAILY',
  Instant = 'INSTANT',
  Monthly = 'MONTHLY',
  Never = 'NEVER',
  Weekly = 'WEEKLY',
}

export type IUserNotificationPreferenceInput = {
  category: IUserNotificationPreferenceCategoryEnum;
  enabled: Scalars['Boolean']['input'];
  frequency: IUserNotificationPreferenceFrequencyEnum;
};

/** Autogenerated input type of UserNotificationPreferencesUpdate */
export type IUserNotificationPreferencesUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  preferences: IUserNotificationPreferenceInput;
};

/** Autogenerated return type of UserNotificationPreferencesUpdate. */
export type IUserNotificationPreferencesUpdatePayload = {
  __typename?: 'UserNotificationPreferencesUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  notificationPreference?: Maybe<IUserNotificationPreference>;
  user?: Maybe<IUser>;
};

/** List of available Order Enums of Users domain */
export enum IUserOrderEnum {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  FirstNameAsc = 'FIRST_NAME_ASC',
  FirstNameDesc = 'FIRST_NAME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LastNameAsc = 'LAST_NAME_ASC',
  LastNameDesc = 'LAST_NAME_DESC',
  LastSignInAtAsc = 'LAST_SIGN_IN_AT_ASC',
  LastSignInAtDesc = 'LAST_SIGN_IN_AT_DESC',
  NicknameAsc = 'NICKNAME_ASC',
  NicknameDesc = 'NICKNAME_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
}

/** List of available categories for notifications */
export enum IUserOutwardNotificationCategoryEnum {
  Follows = 'FOLLOWS',
  Messages = 'MESSAGES',
  Newsletter = 'NEWSLETTER',
  Notifications = 'NOTIFICATIONS',
  Offerings = 'OFFERINGS',
}

export type IUserOutwardNotificationsCategory = {
  __typename?: 'UserOutwardNotificationsCategory';
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  /** Returns true/false if push notifications are turned on/off */
  emailPreference: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: IUserOutwardNotificationCategoryEnum;
  /** Returns true/false if push notifications are turned on/off */
  pushNotificationsPreference: Scalars['Boolean']['output'];
};

export type IUserPasswordInput = {
  currentPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
};

/** Autogenerated input type of UserPasswordReset */
export type IUserPasswordResetInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['Email']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

/** Autogenerated return type of UserPasswordReset. */
export type IUserPasswordResetPayload = {
  __typename?: 'UserPasswordResetPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  user?: Maybe<IUser>;
};

/** Autogenerated input type of UserPasswordSet */
export type IUserPasswordSetInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
};

/** Autogenerated return type of UserPasswordSet. */
export type IUserPasswordSetPayload = {
  __typename?: 'UserPasswordSetPayload';
  authFlags?: Maybe<IAuthFlags>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  jwtToken?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use flags instead */
  needSetPassword?: Maybe<Scalars['Boolean']['output']>;
  user?: Maybe<IUser>;
};

/** Autogenerated input type of UserPasswordTokenCreate */
export type IUserPasswordTokenCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['Email']['input'];
};

/** Autogenerated return type of UserPasswordTokenCreate. */
export type IUserPasswordTokenCreatePayload = {
  __typename?: 'UserPasswordTokenCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

/** Autogenerated input type of UserPasswordUpdate */
export type IUserPasswordUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  passwords: IUserPasswordInput;
};

/** Autogenerated return type of UserPasswordUpdate. */
export type IUserPasswordUpdatePayload = {
  __typename?: 'UserPasswordUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  user?: Maybe<IUser>;
};

export type IUserPrivacySetting = {
  __typename?: 'UserPrivacySetting';
  category: IUserPrivacySettingCategoryEnum;
  categoryForDisplay: Scalars['String']['output'];
  descriptionForDisplay: Scalars['String']['output'];
  emojiForDisplay: Scalars['String']['output'];
  private: Scalars['Boolean']['output'];
  public: Scalars['Boolean']['output'];
  visibility: IUserPrivacySettingVisibilityEnum;
  visibilityForDisplay: Scalars['String']['output'];
  visibilityForUserDisplay: Scalars['String']['output'];
};

export enum IUserPrivacySettingCategoryEnum {
  Badge = 'BADGE',
  Demographic = 'DEMOGRAPHIC',
  Location = 'LOCATION',
  Republic = 'REPUBLIC',
  SearchEngine = 'SEARCH_ENGINE',
}

export type IUserPrivacySettingsInput = {
  category: IUserPrivacySettingCategoryEnum;
  visibility: IUserPrivacySettingVisibilityEnum;
};

/** Autogenerated input type of UserPrivacySettingsUpdate */
export type IUserPrivacySettingsUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  settings: IUserPrivacySettingsInput;
};

/** Autogenerated return type of UserPrivacySettingsUpdate. */
export type IUserPrivacySettingsUpdatePayload = {
  __typename?: 'UserPrivacySettingsUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  privacySettings?: Maybe<Array<IUserPrivacySetting>>;
};

export enum IUserPrivacySettingVisibilityEnum {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type IUserProfile = INode & {
  __typename?: 'UserProfile';
  angellistUrl?: Maybe<Scalars['Url']['output']>;
  bio?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  coverPhotoContentType?: Maybe<Scalars['String']['output']>;
  coverPhotoFileName?: Maybe<Scalars['String']['output']>;
  coverPhotoFileSize?: Maybe<Scalars['BigInt']['output']>;
  coverPhotoUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  coverPhotoUrl?: Maybe<Scalars['Url']['output']>;
  facebookUrl?: Maybe<Scalars['Url']['output']>;
  fullAddressForDisplay?: Maybe<Scalars['String']['output']>;
  headline?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  postFeed: IUserFeedItemConnection;
  state?: Maybe<Scalars['String']['output']>;
  twitterUrl?: Maybe<Scalars['Url']['output']>;
  websiteUrl?: Maybe<Scalars['Url']['output']>;
};

export type IUserProfileCoverPhotoUrlArgs = {
  extension?: InputMaybe<IFileProcessingImgproxyExtensionEnum>;
  gravity?: InputMaybe<IFileProcessingImgproxyGravityEnum>;
  resize?: InputMaybe<IFileProcessingImgproxyResizeInput>;
};

export type IUserProfilePostFeedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type IUserProfileInput = {
  address?: InputMaybe<IAddressInput>;
  angellistUrl?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  directUploadCoverPhotoUuid?: InputMaybe<Scalars['String']['input']>;
  facebookUrl?: InputMaybe<Scalars['String']['input']>;
  linkedinUrl?: InputMaybe<Scalars['String']['input']>;
  twitterUrl?: InputMaybe<Scalars['String']['input']>;
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UserProfileUpdate */
export type IUserProfileUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userProfile: IUserProfileInput;
};

/** Autogenerated return type of UserProfileUpdate. */
export type IUserProfileUpdatePayload = {
  __typename?: 'UserProfileUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  userProfile?: Maybe<IUserProfile>;
};

/** Advisor object of About domain (within the Core domain) */
export type IUserRepublicTeamMemberAdvisor = {
  __typename?: 'UserRepublicTeamMemberAdvisor';
  description: Scalars['String']['output'];
  imageName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  userSlug: Scalars['String']['output'];
};

/** The connection type for UserRepublicTeamMemberAdvisor. */
export type IUserRepublicTeamMemberAdvisorConnection = {
  __typename?: 'UserRepublicTeamMemberAdvisorConnection';
  /** A list of edges. */
  edges: Array<IUserRepublicTeamMemberAdvisorEdge>;
  /** A list of nodes. */
  nodes: Array<IUserRepublicTeamMemberAdvisor>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IUserRepublicTeamMemberAdvisorEdge = {
  __typename?: 'UserRepublicTeamMemberAdvisorEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: IUserRepublicTeamMemberAdvisor;
};

/** Autogenerated input type of UserRepublicTeamMemberCreate */
export type IUserRepublicTeamMemberCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  teamMember: IUserRepublicTeamMemberTeamMemberInput;
};

/** Autogenerated return type of UserRepublicTeamMemberCreate. */
export type IUserRepublicTeamMemberCreatePayload = {
  __typename?: 'UserRepublicTeamMemberCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  teamMember?: Maybe<IUserRepublicTeamMemberTeamMember>;
};

/** Autogenerated input type of UserRepublicTeamMemberDelete */
export type IUserRepublicTeamMemberDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UserRepublicTeamMemberDelete. */
export type IUserRepublicTeamMemberDeletePayload = {
  __typename?: 'UserRepublicTeamMemberDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
};

/** GrowthOpportunity object of About domain (within the Core domain) */
export type IUserRepublicTeamMemberGrowthOpportunity = {
  __typename?: 'UserRepublicTeamMemberGrowthOpportunity';
  buttonLink: Scalars['String']['output'];
  buttonText: Scalars['String']['output'];
  description: Scalars['String']['output'];
  imageKey: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

/** The connection type for UserRepublicTeamMemberGrowthOpportunity. */
export type IUserRepublicTeamMemberGrowthOpportunityConnection = {
  __typename?: 'UserRepublicTeamMemberGrowthOpportunityConnection';
  /** A list of edges. */
  edges: Array<IUserRepublicTeamMemberGrowthOpportunityEdge>;
  /** A list of nodes. */
  nodes: Array<IUserRepublicTeamMemberGrowthOpportunity>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IUserRepublicTeamMemberGrowthOpportunityEdge = {
  __typename?: 'UserRepublicTeamMemberGrowthOpportunityEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: IUserRepublicTeamMemberGrowthOpportunity;
};

/** Partner object of About domain (within the Core domain) */
export type IUserRepublicTeamMemberPartner = {
  __typename?: 'UserRepublicTeamMemberPartner';
  backlink: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** The connection type for UserRepublicTeamMemberPartner. */
export type IUserRepublicTeamMemberPartnerConnection = {
  __typename?: 'UserRepublicTeamMemberPartnerConnection';
  /** A list of edges. */
  edges: Array<IUserRepublicTeamMemberPartnerEdge>;
  /** A list of nodes. */
  nodes: Array<IUserRepublicTeamMemberPartner>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IUserRepublicTeamMemberPartnerEdge = {
  __typename?: 'UserRepublicTeamMemberPartnerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: IUserRepublicTeamMemberPartner;
};

/** PortfolioDeal object of About domain (within the Core domain) */
export type IUserRepublicTeamMemberPortfolioDeal = {
  __typename?: 'UserRepublicTeamMemberPortfolioDeal';
  amount: Scalars['String']['output'];
  backlink: Scalars['String']['output'];
  deal: Scalars['String']['output'];
  description: Scalars['String']['output'];
  mention: Scalars['String']['output'];
  tag: Scalars['String']['output'];
  tip: Scalars['String']['output'];
};

/** The connection type for UserRepublicTeamMemberPortfolioDeal. */
export type IUserRepublicTeamMemberPortfolioDealConnection = {
  __typename?: 'UserRepublicTeamMemberPortfolioDealConnection';
  /** A list of edges. */
  edges: Array<IUserRepublicTeamMemberPortfolioDealEdge>;
  /** A list of nodes. */
  nodes: Array<IUserRepublicTeamMemberPortfolioDeal>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IUserRepublicTeamMemberPortfolioDealEdge = {
  __typename?: 'UserRepublicTeamMemberPortfolioDealEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: IUserRepublicTeamMemberPortfolioDeal;
};

/** TeamMember object of About domain (within the Core domain) */
export type IUserRepublicTeamMemberTeamMember = {
  __typename?: 'UserRepublicTeamMemberTeamMember';
  angellistUrl: Scalars['Url']['output'];
  bio: Scalars['String']['output'];
  facebookUrl: Scalars['Url']['output'];
  id: Scalars['ID']['output'];
  linkedinUrl: Scalars['Url']['output'];
  order: Scalars['Int']['output'];
  role: Scalars['String']['output'];
  twitterUrl: Scalars['Url']['output'];
  user: IUser;
  /** Permitted actions for about domain */
  userPermissions: IUserRepublicTeamMemberUserPermissions;
};

/** The connection type for UserRepublicTeamMemberTeamMember. */
export type IUserRepublicTeamMemberTeamMemberConnection = {
  __typename?: 'UserRepublicTeamMemberTeamMemberConnection';
  /** A list of edges. */
  edges: Array<IUserRepublicTeamMemberTeamMemberEdge>;
  /** A list of nodes. */
  nodes: Array<IUserRepublicTeamMemberTeamMember>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IUserRepublicTeamMemberTeamMemberEdge = {
  __typename?: 'UserRepublicTeamMemberTeamMemberEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: IUserRepublicTeamMemberTeamMember;
};

export type IUserRepublicTeamMemberTeamMemberInput = {
  angellistUrl?: InputMaybe<Scalars['Url']['input']>;
  bio: Scalars['String']['input'];
  facebookUrl?: InputMaybe<Scalars['Url']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  linkedinUrl?: InputMaybe<Scalars['Url']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  role: Scalars['String']['input'];
  twitterUrl?: InputMaybe<Scalars['Url']['input']>;
  userSlug: Scalars['String']['input'];
};

/** Autogenerated input type of UserRepublicTeamMemberUpdate */
export type IUserRepublicTeamMemberUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  teamMember: IUserRepublicTeamMemberTeamMemberInput;
};

/** Autogenerated return type of UserRepublicTeamMemberUpdate. */
export type IUserRepublicTeamMemberUpdatePayload = {
  __typename?: 'UserRepublicTeamMemberUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  teamMember?: Maybe<IUserRepublicTeamMemberTeamMember>;
};

/** User permissions exposed to a client for About domain */
export type IUserRepublicTeamMemberUserPermissions = {
  __typename?: 'UserRepublicTeamMemberUserPermissions';
  canCreate: Scalars['Boolean']['output'];
  canDelete: Scalars['Boolean']['output'];
  canUpdate: Scalars['Boolean']['output'];
};

export enum IUserRoleEnum {
  Admin = 'ADMIN',
  Analyst = 'ANALYST',
  Blacklisted = 'BLACKLISTED',
  ContentCreator = 'CONTENT_CREATOR',
  InvestmentsRestricted = 'INVESTMENTS_RESTRICTED',
  RadarInvestor = 'RADAR_INVESTOR',
  Restricted = 'RESTRICTED',
  User = 'USER',
}

/** Autogenerated input type of UserSendDeleteRequest */
export type IUserSendDeleteRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
};

/** Autogenerated return type of UserSendDeleteRequest. */
export type IUserSendDeleteRequestPayload = {
  __typename?: 'UserSendDeleteRequestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  user?: Maybe<IUser>;
};

/** Autogenerated input type of UserSetInactive */
export type IUserSetInactiveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
};

/** Autogenerated return type of UserSetInactive. */
export type IUserSetInactivePayload = {
  __typename?: 'UserSetInactivePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  user?: Maybe<IUser>;
};

/** User Subscription for Core Domain */
export type IUserSubscription = INode & {
  __typename?: 'UserSubscription';
  /** Email body after subscription */
  body?: Maybe<Scalars['String']['output']>;
  /** Full description of the source name */
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Source name of subscription */
  name: Scalars['String']['output'];
  /** Checks if pre-register is required */
  preRegister: Scalars['Boolean']['output'];
  /** Redirects after subscription */
  redirectUrl?: Maybe<Scalars['Url']['output']>;
  /** Email subject after subscription */
  subject: Scalars['String']['output'];
};

/** The connection type for UserSubscription. */
export type IUserSubscriptionConnection = {
  __typename?: 'UserSubscriptionConnection';
  /** A list of edges. */
  edges: Array<IUserSubscriptionEdge>;
  /** A list of nodes. */
  nodes: Array<IUserSubscription>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IUserSubscriptionEdge = {
  __typename?: 'UserSubscriptionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: IUserSubscription;
};

/** Input paramaters for Users Subscription */
export type IUserSubscriptionInput = {
  email?: InputMaybe<Scalars['Email']['input']>;
  googleRecaptchaToken?: InputMaybe<Scalars['String']['input']>;
  /**
   * The name of the object being subscribed to
   *                    -- see UserSubscriptions query for a full list of options
   */
  source?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UserUpdate */
export type IUserUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** If provided, will allow an admin to modify another user record */
  id?: InputMaybe<Scalars['ID']['input']>;
  user: IUserInput;
};

/** Autogenerated return type of UserUpdate. */
export type IUserUpdatePayload = {
  __typename?: 'UserUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  user?: Maybe<IUser>;
};

/** Validation error type for mutations */
export type IValidationError = {
  __typename?: 'ValidationError';
  extensions: IExtensions;
  locations?: Maybe<Array<ILocation>>;
  message: Scalars['String']['output'];
  path?: Maybe<Array<Scalars['String']['output']>>;
};

export type IValidationErrors = {
  __typename?: 'ValidationErrors';
  messages?: Maybe<Scalars['JSON']['output']>;
};

export type IVideo = {
  __typename?: 'Video';
  id?: Maybe<Scalars['String']['output']>;
  source: Scalars['String']['output'];
};

export type IWatfordBanner = {
  __typename?: 'WatfordBanner';
  user: IUser;
};

export type IZenLedgerIntegrationMutations = {
  /** Send address to ZendLEdger */
  zenLedgerSendPortfolio?: Maybe<IZenLedgerIntegrationTaxPortfolioPayload>;
};

export type IZenLedgerIntegrationMutationsZenLedgerSendPortfolioArgs = {
  input: IZenLedgerIntegrationTaxPortfolioInput;
};

/** ZenLedger portfolio input */
export type IZenLedgerIntegrationPortfolioInput = {
  address: Scalars['String']['input'];
  blockchain: Scalars['String']['input'];
  coin: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
  walletTokenId: Scalars['String']['input'];
};

/** Autogenerated input type of ZenLedgerIntegrationTaxPortfolio */
export type IZenLedgerIntegrationTaxPortfolioInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  portfolioList: Array<IZenLedgerIntegrationPortfolioInput>;
};

/** Autogenerated return type of ZenLedgerIntegrationTaxPortfolio. */
export type IZenLedgerIntegrationTaxPortfolioPayload = {
  __typename?: 'ZenLedgerIntegrationTaxPortfolioPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<IValidationError>>;
  signupUrl: Scalars['String']['output'];
};
