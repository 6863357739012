import { Box, Button, Center, Flex, Heading } from '@chakra-ui/react';

export interface IGlobalCommunityProps {
  onClickRaiseCapital?: () => void;
  onClickViewDeals?: () => void;
}

export const GlobalCommunity = (props: IGlobalCommunityProps) => {
  return (
    <Box my="20" bg={{ base: 'grey.50', sm: 'white' }} mx="auto" maxW="1142px">
      <Center bgColor="grey.50" py="20" borderRadius="4xl" mx="4">
        <Box maxW="631px">
          <Heading
            fontSize={['xl', '3xl']}
            lineHeight={['xl', '3xl']}
            fontWeight="600"
            textAlign="center"
            letterSpacing="tighter"
            mb="8"
          >
            Community unlocks capital.
            <br />
            Republic is where it happens.{' '}
          </Heading>
          <Flex
            justifyContent="center"
            alignItems="center"
            direction={['column', 'row']}
            mt="6"
            gap="4"
          >
            <Button
              onClick={props.onClickViewDeals}
              variant="republic-primary"
              fontSize="lg"
              fontWeight="bold"
              w="160px"
              px="8"
              py="6"
            >
              View deals
            </Button>
            <Button
              onClick={props.onClickRaiseCapital}
              fontSize="lg"
              fontWeight="bold"
              w="160px"
              px="8"
              py="6"
            >
              Raise capital
            </Button>
          </Flex>
        </Box>
      </Center>
    </Box>
  );
};
