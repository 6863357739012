/*
 * CODE GENERATED AUTOMATICALLY WITH GraphQL Codegen
 * THIS FILE SHOULD NOT BE EDITED MANUALLY.
 */
import * as Types from '@r-client/data/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type IFollowOfferingMutationVariables = Types.Exact<{
  input: Types.IFollowCreateInput;
}>;

export type IFollowOfferingMutation = {
  __typename?: 'Mutation';
  follow?:
    | {
        __typename?: 'FollowCreatePayload';
        target?:
          | { __typename?: 'Offering'; following: boolean }
          | { __typename?: 'TagsTag' }
          | { __typename?: 'User' }
          | null
          | undefined;
        errors?:
          | Array<{ __typename?: 'ValidationError'; message: string }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type IOfferingCardFragment = {
  __typename?: 'Offering';
  id: string;
  slug: string;
  airdrop: boolean;
  companyName: string;
  following: boolean;
  description?: string | null | undefined;
  amountRaised?: string | null | undefined;
  amountRaisedWithExternalCents?: number | null | undefined;
  externalInvestmentsAmountTooltip?: string | null | undefined;
  externalInvestorsQuantity?: number | null | undefined;
  externalInvestorsDescription?: string | null | undefined;
  cardTooltipText?: string | null | undefined;
  cardImageUrl?: string | null | undefined;
  badges: Array<Types.IOfferingBadgeNameEnum>;
  spotlight?: boolean | null | undefined;
  minInvestmentAmountCents?: number | null | undefined;
  regulationForDisplay: string;
  cardComplianceText?: string | null | undefined;
  hostingEntityForDisplay?: string | null | undefined;
  calculatedMinInvestmentAmount?: string | null | undefined;
  logoUrl?: string | null | undefined;
  state?: Types.IOfferingStateEnum | null | undefined;
  timeRemainingValueForDisplay?: number | null | undefined;
  timeRemainingUnitForDisplay?: string | null | undefined;
  hasClosed: boolean;
  reviewsCount: number;
  testingTheWaters: boolean;
  testingTheWatersFirstDaysOff: boolean;
  amountRaisedCents?: number | null | undefined;
  maxGoalReached: boolean;
  investorsCount?: number | null | undefined;
  crypto: boolean;
  verticalTag?: string | null | undefined;
  trending: boolean;
  hostingEntity?: string | null | undefined;
  logoUrl2x?: string | null | undefined;
  tags?:
    | Array<{
        __typename?: 'TagsTag';
        id: string;
        colorHex?: string | null | undefined;
        displayName: string;
        slug: string;
        impact: boolean;
        vertical: boolean;
        group: boolean;
        topLevelSectorTag?:
          | {
              __typename?: 'TagsTag';
              colorHex?: string | null | undefined;
              displayName: string;
              description?: string | null | undefined;
              vertical: boolean;
              group: boolean;
            }
          | null
          | undefined;
      }>
    | null
    | undefined;
  address: {
    __typename?: 'Address';
    id: string;
    state?: string | null | undefined;
    city?: string | null | undefined;
  };
  flexibleDealTerms?:
    | Array<{
        __typename?: 'OfferingTermTierDealTerm';
        title: string;
        value: string;
        nextTierValue?: string | null | undefined;
        showOnTombstone?: boolean | null | undefined;
        type: Types.IOfferingTermTierDealTermTypeEnum;
        xValueForDealCard?: string | null | undefined;
        id: string;
      }>
    | null
    | undefined;
  xSecurity?:
    | {
        __typename?: 'OfferingSecurity';
        xValuationCapCents?: number | null | undefined;
        xValuationCents?: number | null | undefined;
      }
    | null
    | undefined;
  timeToInvestBadgeParams?:
    | { __typename?: 'OfferingBadgeTimeToInvest'; value: number; unit: string }
    | null
    | undefined;
  investmentBadge?:
    | {
        __typename?: 'InvestmentBadge';
        id: string;
        description?: string | null | undefined;
        state?: string | null | undefined;
        amountCents?: number | null | undefined;
      }
    | null
    | undefined;
};

export type IOfferingsSearchQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type IOfferingsSearchQuery = {
  __typename?: 'Query';
  xOfferingsSearch: {
    __typename?: 'OfferingConnection';
    nodes: Array<{
      __typename?: 'Offering';
      id: string;
      badges: Array<Types.IOfferingBadgeNameEnum>;
      cardImageUrl?: string | null | undefined;
      cardTooltipText?: string | null | undefined;
      companyName: string;
      description?: string | null | undefined;
      following: boolean;
      indexInAllOfferings: number;
      logoUrl?: string | null | undefined;
      numOfAllOfferings: number;
      slug: string;
      state?: Types.IOfferingStateEnum | null | undefined;
      terms?: string | null | undefined;
      address: {
        __typename?: 'Address';
        state?: string | null | undefined;
        city?: string | null | undefined;
      };
      tags?:
        | Array<{
            __typename?: 'TagsTag';
            id: string;
            colorHex?: string | null | undefined;
            displayName: string;
            slug: string;
          }>
        | null
        | undefined;
    }>;
  };
};

export type IOfferingQueryVariables = Types.Exact<{
  slug: Types.Scalars['String']['input'];
}>;

export type IOfferingQuery = {
  __typename?: 'Query';
  offering?:
    | { __typename?: 'Offering'; id: string; companyName: string }
    | null
    | undefined;
};

export type ISavedOfferingsQueryVariables = Types.Exact<{
  userSlug: Types.Scalars['String']['input'];
}>;

export type ISavedOfferingsQuery = {
  __typename?: 'Query';
  savedOfferingsForUser: {
    __typename?: 'OfferingConnection';
    nodes: Array<{ __typename?: 'Offering'; id: string; companyName: string }>;
  };
};

export type IUnfollowOfferingMutationVariables = Types.Exact<{
  input: Types.IFollowDeleteInput;
}>;

export type IUnfollowOfferingMutation = {
  __typename?: 'Mutation';
  unfollow?:
    | {
        __typename?: 'FollowDeletePayload';
        target?:
          | { __typename?: 'Offering'; following: boolean }
          | { __typename?: 'TagsTag' }
          | { __typename?: 'User' }
          | null
          | undefined;
        errors?:
          | Array<{ __typename?: 'ValidationError'; message: string }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const OfferingCardFragmentDoc = gql`
  fragment OfferingCard on Offering {
    id
    slug
    airdrop
    companyName
    following
    description
    amountRaised
    amountRaisedWithExternalCents
    externalInvestmentsAmountTooltip
    externalInvestorsQuantity
    externalInvestorsDescription
    cardTooltipText
    cardImageUrl
    amountRaised
    badges
    spotlight
    minInvestmentAmountCents
    regulationForDisplay
    cardComplianceText
    hostingEntityForDisplay
    calculatedMinInvestmentAmount
    logoUrl(resize: { width: 60, height: 60, type: fit })
    logoUrl2x: logoUrl(resize: { width: 120, height: 120, type: fit })
    tags {
      id
      colorHex
      displayName
      slug
      topLevelSectorTag {
        colorHex
        displayName
        description
        vertical
        group
      }
      impact
      vertical
      group
    }
    address {
      id
      state
      city
    }
    state
    flexibleDealTerms {
      title
      value
      nextTierValue
      showOnTombstone
      type
      xValueForDealCard
      id
    }
    xSecurity {
      xValuationCapCents
      xValuationCents
    }
    timeRemainingValueForDisplay
    timeRemainingUnitForDisplay
    timeToInvestBadgeParams {
      value
      unit
    }
    hasClosed
    reviewsCount
    testingTheWaters
    testingTheWatersFirstDaysOff
    amountRaisedCents
    maxGoalReached
    investorsCount
    crypto
    verticalTag
    trending
    investmentBadge {
      id
      description
      state
      amountCents
    }
    hostingEntity
  }
`;
export const FollowOfferingDocument = gql`
  mutation FollowOffering($input: FollowCreateInput!) {
    follow(input: $input) {
      target {
        ... on Offering {
          following
        }
      }
      errors {
        message
      }
    }
  }
`;
export type IFollowOfferingMutationFn = Apollo.MutationFunction<
  IFollowOfferingMutation,
  IFollowOfferingMutationVariables
>;
export type FollowOfferingMutationResult =
  Apollo.MutationResult<IFollowOfferingMutation>;
export type FollowOfferingMutationOptions = Apollo.BaseMutationOptions<
  IFollowOfferingMutation,
  IFollowOfferingMutationVariables
>;
export const OfferingsSearchDocument = gql`
  query OfferingsSearch {
    xOfferingsSearch(searchParams: {}, first: 9) {
      nodes {
        id
        address {
          state
          city
        }
        badges
        cardImageUrl
        cardTooltipText
        companyName
        description
        following
        indexInAllOfferings
        logoUrl
        numOfAllOfferings
        slug
        state
        tags {
          id
          colorHex
          displayName
          slug
        }
        terms
      }
    }
  }
`;
export type OfferingsSearchQueryResult = Apollo.QueryResult<
  IOfferingsSearchQuery,
  IOfferingsSearchQueryVariables
>;
export const OfferingDocument = gql`
  query Offering($slug: String!) {
    offering(slug: $slug) {
      id
      companyName
    }
  }
`;
export type OfferingQueryResult = Apollo.QueryResult<
  IOfferingQuery,
  IOfferingQueryVariables
>;
export const SavedOfferingsDocument = gql`
  query SavedOfferings($userSlug: String!) {
    savedOfferingsForUser(userSlug: $userSlug, first: 12) {
      nodes {
        id
        companyName
      }
    }
  }
`;
export type SavedOfferingsQueryResult = Apollo.QueryResult<
  ISavedOfferingsQuery,
  ISavedOfferingsQueryVariables
>;
export const UnfollowOfferingDocument = gql`
  mutation UnfollowOffering($input: FollowDeleteInput!) {
    unfollow(input: $input) {
      target {
        ... on Offering {
          following
        }
      }
      errors {
        message
      }
    }
  }
`;
export type IUnfollowOfferingMutationFn = Apollo.MutationFunction<
  IUnfollowOfferingMutation,
  IUnfollowOfferingMutationVariables
>;
export type UnfollowOfferingMutationResult =
  Apollo.MutationResult<IUnfollowOfferingMutation>;
export type UnfollowOfferingMutationOptions = Apollo.BaseMutationOptions<
  IUnfollowOfferingMutation,
  IUnfollowOfferingMutationVariables
>;
