import 'swiper/css';

import { useRef, useState } from 'react';

import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Heading,
  HStack,
  Image,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { type Swiper as ISwiper } from 'swiper/types';

import {
  ArrowLeftIcon,
  ArrowRightIcon,
} from '@r-client/shared/ui/chakra-ui-icons';

import { GlobalBusinessCaseCard } from '../global-business-case-card/global-business-case-card';
import { businesses } from './businesses';
import { cases } from './cases';

/* eslint-disable @typescript-eslint/no-empty-interface */
export interface IGlobalBusinessCasesProps {}

export const GlobalBusinessCases = (_props: IGlobalBusinessCasesProps) => {
  const swiperRef = useRef<ISwiper | null>(null);
  const [showPreviousButton, setShowPreviousButton] = useState<boolean>(false);

  // TODO: RP to understand how to show previous button when swiping forward
  const handleOnSliderInteraction = () => {
    setShowPreviousButton(true);
  };

  return (
    <Container maxW="1142px" my="20">
      <Heading size="republic-h5" fontSize="xl" mb="16">
        Businesses arrive with a vision.
        <br />
        Republic helps engineer it into reality.
      </Heading>
      <Box mb="6">
        <Swiper
          loop
          spaceBetween={24}
          slidesPerView="auto"
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          // onSlideNextTransitionStart={handleOnSliderInteraction}
          onSlidePrevTransitionStart={handleOnSliderInteraction}
        >
          {cases.map((businessCase) => (
            <SwiperSlide
              key={businessCase.id}
              style={{
                maxWidth: '312px',
              }}
            >
              <GlobalBusinessCaseCard businessCase={businessCase} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      <Flex direction="row-reverse" mb="6">
        <Button
          ml="4"
          onClick={() => {
            swiperRef.current?.slideNext();
            handleOnSliderInteraction();
          }}
        >
          <ArrowRightIcon />
        </Button>
        {showPreviousButton && (
          <Button ml="4" onClick={() => swiperRef.current?.slidePrev()}>
            <ArrowLeftIcon />
          </Button>
        )}
      </Flex>
      <Divider color="grey.100" />
      <Heading size="republic-h5" my="6">
        2,500+ businesses funded and served
      </Heading>

      <SimpleGrid columns={[1, 3]} spacingY="10">
        {businesses.map((business) => (
          <HStack key={business.title}>
            <Image src={business.logo} alt={business.title} boxSize="41px" />
            <Box>
              <Text textStyle="lg" fontWeight="semibold">
                {business.title}
              </Text>
              <Text fontSize="14px" color="grey.500">
                {business.description}
              </Text>
            </Box>
          </HStack>
        ))}
      </SimpleGrid>
    </Container>
  );
};
