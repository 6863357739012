import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';

import { InfoGray, SvgIconLight } from '@r-client/shared/ui/icons';

import { useMobile } from '../global-for-founders/use-mobile';

export interface IIconNoticeProps {
  text: string;
}

export const IconNotice = ({ text }: IIconNoticeProps) => {
  const isMobile = useMobile();

  const { isOpen, onOpen, onClose } = useDisclosure();

  return isMobile ? (
    <>
      <Text as="span" ml="1">
        <SvgIconLight
          dataTestId="icon-notice-svg"
          icon={InfoGray}
          onClick={onOpen}
        />
      </Text>
      <Drawer isOpen={isOpen} placement="bottom" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            <Box pt="6">{text}</Box>
          </DrawerBody>
          <DrawerFooter>
            <Button size="republic-default" w="100%" onClick={onClose}>
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  ) : (
    <Tooltip
      backgroundColor="grey.900"
      hasArrow
      label={text}
      placement="top"
      variant="republic"
    >
      <Text as="span" ml="1">
        <SvgIconLight icon={InfoGray} dataTestId="icon-notice-svg" />
      </Text>
    </Tooltip>
  );
};
