import { ComponentStyleConfig } from '@chakra-ui/react';

export const Badge: ComponentStyleConfig = {
  baseStyle: {
    textTransform: 'none',
  },
  sizes: {
    'republic-small': {
      fontSize: 12,
      fontWeight: 'bold',
      lineHeight: '16px',
      letterSpacing: '-0.12px',
      py: 1,
      px: 2,
      borderRadius: '4px',
    },
  },
};
