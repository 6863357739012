import React from 'react';

import CN from 'clsx';

import { Avatar, Box, CampaignTag, Text } from '@r-client/shared/ui/core';

import { E_MARKETING_BADGES } from '../../../types';
import { OfferingBadge } from '../offering-card-badge';

import styles from './offering-card-content.module.scss';

export interface IOfferingCardContentProps {
  logoUrl?: string;
  logoUrl2x?: string;
  className?: string;
  regulationClassName?: string;
  description?: string;
  indexInAllOfferings?: number;
  numOfAllOfferings?: number;
  companyName?: string;
  isSmallCard?: boolean;
  regulationEntityText?: string;
  spotlight?: boolean;
}

export const OfferingCardContent = ({
  logoUrl,
  logoUrl2x,
  className,
  regulationClassName,
  description,
  indexInAllOfferings,
  numOfAllOfferings,
  companyName,
  regulationEntityText,
  isSmallCard,
  spotlight,
}: IOfferingCardContentProps) => {
  return (
    <div className={className}>
      {logoUrl ? (
        <Avatar
          className={CN(styles.avatar, {
            [styles.smallAvatar]: isSmallCard,
          })}
          alt={`Logo of ${companyName}`}
          title={companyName}
          size={isSmallCard ? 'small' : 'offeringCard'}
          url={logoUrl}
          url2x={logoUrl2x || logoUrl}
          outline={false}
        />
      ) : null}
      {spotlight && (
        <Box className={styles.spotlight}>
          <OfferingBadge
            content="This link showcases content from our partners, distinct from our regular content."
            badge={E_MARKETING_BADGES.SPOTLIGHT}
          />
        </Box>
      )}
      <p
        className={CN(styles.title, { [styles.smallCardTitle]: isSmallCard })}
        itemProp="name"
      >
        {companyName}
        {numOfAllOfferings && numOfAllOfferings > 1 ? (
          <CampaignTag
            className={styles.tag}
            campaignIndex={indexInAllOfferings || 0}
            size="small"
          />
        ) : null}
      </p>
      <p
        className={CN(styles.description, {
          [styles.smallCardDescription]: isSmallCard,
        })}
        itemProp="description"
      >
        {description}
      </p>
      {isSmallCard && regulationEntityText && (
        <Text
          weight="500"
          className={CN(styles.regulationEntityText, regulationClassName)}
        >
          {regulationEntityText}
        </Text>
      )}
    </div>
  );
};
