export const Heading = {
  sizes: {
    'republic-h1': {
      fontSize: { base: '5xl', sm: '6xl' },
      lineHeight: { base: '5xl', sm: '6xl' },
      letterSpacing: 'tightest',
      fontWeight: 'black',
    },
    'republic-h2': {
      fontSize: { base: '3xl', sm: '4xl' },
      lineHeight: { base: '3xl', sm: '4xl' },
      letterSpacing: 'tighter',
      fontWeight: 'black',
    },
    'republic-h3': {
      fontSize: { base: '2xl', sm: '3xl' },
      lineHeight: { base: '2xl', sm: '3xl' },
      letterSpacing: { base: 'tight', sm: 'tighter' },
      fontWeight: 'black',
    },
    'republic-h4': {
      fontSize: 'xl',
      lineHeight: 'xl',
      letterSpacing: 'tight',
      fontWeight: 'semibold',
    },
    'republic-h5': {
      fontSize: 'lg',
      lineHeight: 'lg',
      letterSpacing: 'tight',
      fontWeight: 'bold',
    },
  },
};
