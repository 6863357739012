import {
  Box,
  Button,
  Heading,
  Link,
  List,
  ListItem,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';

import { useAnalytics } from '@r-client/shared/data/analytics';
import { Check } from '@r-client/shared/ui/chakra-ui-icons';
import {
  E_VIDEO_ASPECT_RATIO,
  E_VIDEO_TYPE,
  Video,
} from '@r-client/shared/ui/media';

import preview from './assets/preview.png';
import preview2x from './assets/preview2x.png';

export interface IForFoundersProps {
  variant: 'europe' | 'us';
}

const YOUTUBE_MEDIA = {
  type: E_VIDEO_TYPE.youTubeUrl,
  preview: {
    imageUrl: preview,
    imageUrl2x: preview2x,
  },
  thumb: {
    imageUrl: preview,
    imageUrl2x: preview,
  },
  videoUrl: 'https://youtu.be/lqL_biirPys',
};

const EUROPE_LIST = [
  'End to end services ',
  'Build customer advocacy',
  'Raise your brand profile',
  'Give up equity not cashflow',
  'VC-friendly',
];

const US_LIST = [
  'Powerful fundraising tools',
  'Engage your community',
  'Dedicated team',
  'SEC filing help',
];

export const ForFounders = ({ variant }: IForFoundersProps) => {
  const analytics = useAnalytics();
  return (
    <Box py="20">
      <Text fontSize="lg" lineHeight="md" color="grey.600" mb="4">
        For founders
      </Text>
      <Heading
        fontSize="3xl"
        lineHeight="3xl"
        fontWeight="600"
        mb="4"
        w={{ base: '100%', md: '50%' }}
      >
        Harness the power of your community
      </Heading>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing="6">
        <Box>
          <Text
            fontSize="lg"
            lineHeight="md"
            color="grey.600"
            maxW={{ base: '100%', md: '490px' }}
            mb={{ base: '6', md: '8' }}
          >
            Crowdfunding delivers marketing benefit, customer loyalty and funds
            with speed
          </Text>

          <List spacing={{ base: '3', md: '6' }} mb={{ base: '4', sm: '10' }}>
            {(variant === 'europe' ? EUROPE_LIST : US_LIST).map(
              (value, idx) => (
                <ListItem
                  key={`${variant}${idx}`}
                  fontSize="xl"
                  lineHeight="32px"
                >
                  <Check mr="2" color="green.500" />
                  {value}
                </ListItem>
              )
            )}
          </List>

          <Link
            href={
              variant === 'us'
                ? 'https://republic.com/raise'
                : 'https://europe.republic.com/raise'
            }
          >
            <Button
              variant="republic-primary"
              size={{ base: 'lg', md: 'republic-large' }}
              mb="8"
            >
              Raise capital
            </Button>
          </Link>
        </Box>

        <Box>
          <Video
            media={YOUTUBE_MEDIA}
            aspectRatio={E_VIDEO_ASPECT_RATIO.widescreen}
            borderRadius="12"
            iconSize="small"
            onPlay={() => {
              analytics?.track({
                name: 'homepage_raise_capital_video_play',
                params: {
                  label: 'for_founders',
                  description:
                    'Hear from alumni founders about their campaigns',
                },
              });
            }}
          />
          <Text
            mt="2"
            align="center"
            fontSize="lg"
            lineHeight="md"
            letterSpacing="tight"
            color="grey.900"
          >
            Hear from alumni founders about their campaigns.
          </Text>
        </Box>
      </SimpleGrid>
    </Box>
  );
};
