import { Fragment } from 'react';

import {
  Box,
  Flex,
  Grid,
  GridItem,
  Link,
  Portal,
  Text,
} from '@chakra-ui/react';
import { AnimationScope, motion, useReducedMotion } from 'framer-motion';
import { observer } from 'mobx-react-lite';

import { useGetLatestUpcomingEventQuery } from '@r-client/republic/feature/events';
import { ArrowUpIcon } from '@r-client/shared/ui/chakra-ui-icons';

import { INavigationData } from '../../../config/types';
import { useGetHeaderSpotlightQuery } from '../../../graphql/get-header-spotlight-query';
import { useHeaderMenuLinkTrack } from '../../../utils/use-header-menu-link-track';
import { SpotlightDeal } from '../spotlight-deal/spotlight-deal';
import { useNavigationEntity } from '../use-navigation-entity';
import { WhatsNewSection } from '../whats-new-section';

interface IPopoverMenuProps {
  navigationData: INavigationData[];
  selectedEntityIndex?: number;
  actions: ReturnType<typeof useNavigationEntity>['actions'];
  animationScope?: AnimationScope;
}

const MotionBox = motion(Box);
const MotionFlex = motion(Flex);

export const PopoverMenu = observer(function PopoverMenu({
  navigationData,
  selectedEntityIndex,
  actions,
  animationScope,
}: IPopoverMenuProps) {
  const prefersReducedMotion = useReducedMotion();
  const { data: latestEventData } = useGetLatestUpcomingEventQuery();
  const { data: spotlightDealsData, isLoading: isSpotlightLoading } =
    useGetHeaderSpotlightQuery();
  const { track: trackHeaderMenuLinkClick } = useHeaderMenuLinkTrack();

  const navigationEntityData =
    selectedEntityIndex === undefined
      ? undefined
      : navigationData[selectedEntityIndex];
  const navigationEntryItems = navigationEntityData?.items || [];

  return (
    <>
      <MotionBox
        position="absolute"
        width="100%"
        left="0"
        background="white"
        borderTop="none"
        initial={{ marginTop: '-20px', opacity: 0 }}
        animate={{ marginTop: '1px', opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          duration: prefersReducedMotion ? 0 : 0.1,
          ease: 'linear',
        }}
      >
        <Box
          bgColor="white"
          onMouseEnter={() => actions.openSection()}
          onMouseLeave={actions.closeCurrentSection}
        >
          <MotionFlex
            maxW="1152px"
            m="auto"
            px="4"
            width="100%"
            ref={animationScope}
          >
            <Grid
              py="12"
              columnGap="8"
              rowGap="5"
              gridTemplateColumns="repeat(3, 1fr)"
              gridTemplateRows="0.5fr repeat(4, 1fr)"
              gridAutoFlow="column"
              flex="3"
            >
              {navigationEntryItems.map((item, index) => (
                <Fragment key={`item_${index}`}>
                  <GridItem display="flex" alignItems="flex-end">
                    <Text fontWeight="semibold" letterSpacing="less-tight">
                      {item.name}
                    </Text>
                  </GridItem>
                  {item.subItems?.map((subItem, subIndex) => (
                    <GridItem
                      display="flex"
                      alignItems="flex-end"
                      key={`subItem_${subIndex}`}
                      {...subItem.style}
                    >
                      <Link
                        _hover={{ textDecoration: 'none' }}
                        href={subItem.href}
                        isExternal={subItem.isExternal}
                        role="group"
                        onClick={() =>
                          trackHeaderMenuLinkClick(
                            subItem.analyticsLabel ?? '',
                            navigationData[selectedEntityIndex!].navigationEntry
                          )
                        }
                      >
                        <Flex>
                          <Text
                            color="grey.900"
                            mr="px"
                            letterSpacing="less-tight"
                            lineHeight="md"
                          >
                            {subItem.title}
                          </Text>
                          {subItem.isExternal && (
                            <ArrowUpIcon
                              mt="1px"
                              color="brand.primary"
                              boxSize="5"
                              transform="rotate(45deg)"
                            />
                          )}
                        </Flex>
                        <Text
                          textStyle="sm"
                          color="grey.500"
                          mt="1"
                          _groupHover={{ color: 'grey.900' }}
                        >
                          {subItem.subTitle}
                        </Text>
                      </Link>
                    </GridItem>
                  ))}
                </Fragment>
              ))}
            </Grid>
            <Box display={{ base: 'none', lg: 'block' }} flex="1" maxW="256px">
              {navigationEntityData?.showWhatsNewSection &&
                !spotlightDealsData?.offeringsSpotlightDeals.edges[0]?.node && (
                  <WhatsNewSection
                    latestEvent={latestEventData?.events.nodes[0]}
                  />
                )}
              {navigationEntityData?.showSpotlightDeal && (
                <SpotlightDeal
                  offering={
                    spotlightDealsData?.offeringsSpotlightDeals.edges[0]?.node
                  }
                  isLoading={isSpotlightLoading}
                />
              )}
            </Box>
          </MotionFlex>
        </Box>
      </MotionBox>
      <Portal>
        <MotionBox
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.1 }}
          exit={{ opacity: 0 }}
          transition={{
            duration: prefersReducedMotion ? 0 : 0.2,
            ease: 'easeInOut',
          }}
          position="fixed"
          width="100vw"
          height="100vh"
          zIndex="overlay"
          top="0"
          left="0"
          bottom="0"
          right=" 0"
          background="grey.900"
        />
      </Portal>
    </>
  );
});
