import { ReactNode } from 'react';

import { ChakraProvider } from '@chakra-ui/react';

import {
  useRegionContext,
  withRegionContext,
} from '@r-client/republic/feature/main-layout';
import { theme } from '@r-client/republic/ui/theme';
import { TransferModalProvider as BaseProvider } from '@r-client/shared/feature/token-transfer';

export interface IWrapperProps {
  children?: ReactNode;
}

function Provider({ children }: IWrapperProps) {
  const { region } = useRegionContext();

  return (
    <ChakraProvider theme={theme}>
      <BaseProvider region={region}>{children}</BaseProvider>
    </ChakraProvider>
  );
}

export const TransferProvider = withRegionContext(Provider);
