import {
  Box,
  Flex,
  Heading,
  Img,
  Link,
  Text,
  useMediaQuery,
  useTheme,
} from '@chakra-ui/react';

import { useAnalytics } from '@r-client/shared/data/analytics';
import { useClientRender } from '@r-client/shared/util/components';

import androidStore from './images/android-store.svg';
import androidStoreBig from './images/android-store-big.svg';
import appStore from './images/app-store.svg';
import appStoreBig from './images/app-store-big.svg';
import phoneEurope from './images/phone-eu.png';
import phoneEurope2x from './images/phone-eu@2x.png';
import phoneUS from './images/phone-us.png';
import phoneUS2x from './images/phone-us@2x.png';
import qrEurope from './images/qr-europe.png';
import qrEurope2x from './images/qr-europe@2x.png';
import qrUs from './images/qr-us.png';
import qrUs2x from './images/qr-us@2x.png';

const STORELINKS_US = {
  appStore: 'https://apps.apple.com/us/app/id1475230022',
  androidStore:
    'https://play.google.com/store/apps/details?id=com.republicmobile&hl=en_US',
};

const STORELINKS_EU = {
  appStore: 'https://apps.apple.com/gb/app/seedrs/id1592310232',
  androidStore:
    'https://play.google.com/store/apps/details?id=com.seedrs.seedrs_mobile',
};

const DEVICE_IMAGES_US = {
  '@1x': phoneUS,
  '@2x': phoneUS2x,
};

const DEVICE_IMAGES_EU = {
  '@1x': phoneEurope,
  '@2x': phoneEurope2x,
};

export interface IBuildPortfolioProps {
  region: 'us' | 'europe';
}

export function BuildPortfolio({ region }: IBuildPortfolioProps) {
  const analytics = useAnalytics();
  const theme = useTheme();
  const isClientRender = useClientRender();
  const [isTablet] = useMediaQuery(`(min-width: ${theme.breakpoints.xs})`);

  const images = region === 'us' ? DEVICE_IMAGES_US : DEVICE_IMAGES_EU;

  const appStoreImage = !isTablet && isClientRender ? appStore : appStoreBig;
  const androidStoreImage =
    !isTablet && isClientRender ? androidStore : androidStoreBig;

  const storeLinks = region === 'us' ? STORELINKS_US : STORELINKS_EU;

  return (
    <Flex
      mb={{ base: 12, xs: 16, lg: 36 }}
      bg={{ base: 'grey.50', xs: 'white' }}
      flexDirection={{ base: 'column', lg: 'row' }}
      pt={{ base: 12, xs: 14 }}
      alignItems={{ base: 'center' }}
      gap={{ md: '10', lg: '12' }}
      justifyContent="flex-start"
    >
      <Box
        textAlign={{ base: 'center', lg: 'left' }}
        order={{ base: 1, lg: 2 }}
      >
        <Flex
          alignItems="center"
          justifyContent={{ base: 'center', lg: 'flex-start' }}
          mb="4"
        >
          <Text
            size={{ base: 'republic-body-lg', lg: 'republic-lead' }}
            lineHeight={{ base: 'lg', lg: '32px' }}
            color="grey.600"
          >
            iOS · Android
          </Text>
        </Flex>
        <Heading
          mb={{ base: 6, lg: 4 }}
          px={{ base: 4, xs: 8, lg: 0 }}
          color="black"
          size="republic-h3"
          fontWeight="semibold"
        >
          Invest in one tap
        </Heading>
        <Text
          textStyle={{ base: 'lg', xs: 'lg', lg: 'lead' }}
          color="grey.600"
          letterSpacing="-0.02em"
          maxW={{ base: 331, lg: 620 }}
          mx={{ base: 'auto', lg: 0 }}
          mb="8"
        >
          Access private market investing from your pocket.
        </Text>
        <Flex
          mb={{ base: 10, xs: 14, lg: 0 }}
          justifyContent={{ base: 'center', lg: 'left' }}
          alignItems="center"
        >
          {isTablet && isClientRender && (
            <Img
              mr="8"
              src={region === 'us' ? qrUs : qrEurope}
              srcSet={`${region === 'us' ? qrUs2x : qrEurope2x} 2x`}
            />
          )}
          <Link
            mr={{ base: '5', lg: '6' }}
            href={storeLinks.androidStore}
            target="_blank"
            onClick={() => {
              analytics?.track({
                name: 'click_mobile_store_external',
                params: {
                  label: 'android_store',
                },
              });
            }}
          >
            <Img src={androidStoreImage} />
          </Link>
          <Link
            href={storeLinks.appStore}
            target="_blank"
            onClick={() => {
              analytics?.track({
                name: 'click_mobile_store_external',
                params: {
                  label: 'app_store',
                },
              });
            }}
          >
            <Img src={appStoreImage} />
          </Link>
        </Flex>
      </Box>
      <Box mx={{ base: 4, xs: 8, lg: 0 }} order={{ base: 2, lg: 1 }}>
        <Img src={images['@1x']} srcSet={`${images['@2x']} 2x`} />
      </Box>
    </Flex>
  );
}
