import { useRef, useState } from 'react';

import { Box, Grid, Show, Text } from '@chakra-ui/react';
import { useSizes } from '@chakra-ui/react-use-size';

import { theme } from '@r-client/republic/ui/theme';

import { CONTENT_EU } from './data/content-eu';
import { CONTENT_US } from './data/content-us';
import { Slider } from './slider/slider';

export interface IPortfolioWinsProps {
  region: 'europe' | 'us';
}

export const PortfolioWins = ({ region }: IPortfolioWinsProps) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const boxRef = useRef<HTMLDivElement | null>(null);
  const [dimensions] = useSizes({
    getNodes: () => [boxRef.current],
    observeMutation: true,
  });

  function handleSliderChange(index: number) {
    setActiveTab(index);
  }

  return (
    <Box bgColor="grey.50" pt="16" pb="92px">
      <Box
        maxW={1112}
        mx="auto"
        px={{ base: '4', xs: '30px', md: '30px', lg: 0 }}
      >
        <Grid
          gridTemplateColumns={{ base: '100%', lg: '50% 50%' }}
          alignItems="stretch"
          ref={boxRef}
        >
          <Box>
            <Box mb={{ base: '18px', xs: '3', lg: '6' }}>
              <Text
                color="grey.900"
                fontSize="3xl"
                fontWeight="semibold"
                lineHeight="3xl"
                letterSpacing="tighter"
              >
                Portfolio wins
              </Text>
            </Box>
            <Box pr={{ base: 4 }}>
              <Text
                color="grey.600"
                fontSize="lg"
                fontWeight="normal"
                lineHeight="md"
                letterSpacing="tight"
              >
                Here are a few notable stories from Republic’s portfolio.
              </Text>
            </Box>
          </Box>
        </Grid>
      </Box>
      <Box mx="auto" pt={{ base: '8', xs: '10', lg: '52px' }}>
        <Show breakpoint="(min-width: 1440px)">
          <Show breakpoint="(min-width: 1920px)">
            <Box position="absolute" left="0px" h="452px" w="300px" zIndex="50">
              <Box h="100%" w="50%" bg="grey.50" position="absolute" />
              <Box
                h="100%"
                w="50%"
                left="50%"
                position="relative"
                backgroundImage={`linear-gradient(90deg, ${theme.colors.grey[50]}, transparent)`}
              />
            </Box>
          </Show>
          <Box position="absolute" right="0px" h="452px" w="300px" zIndex="50">
            <Box
              h="100%"
              w="50%"
              backgroundImage={`linear-gradient(270deg, ${theme.colors.grey[50]}, transparent)`}
              position="absolute"
            />
            <Box h="100%" w="50%" bg="grey.50" position="relative" left="50%" />
          </Box>
        </Show>
        <Slider
          slides={region === 'europe' ? CONTENT_EU : CONTENT_US}
          tabsWidth={dimensions?.width}
          activeSlide={activeTab}
          onSliderChange={handleSliderChange}
        />
      </Box>
    </Box>
  );
};
