/* eslint-disable max-lines */
import {
  Avatar,
  Box,
  Button,
  Flex,
  Image,
  Link,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';

import { theme } from '@r-client/republic/ui/theme';
import { SvgIconLight } from '@r-client/shared/ui/icons';

import { ISlide } from '../data/types';

export interface ISlideProps {
  slide: ISlide;
}

export const Slide = ({ slide }: ISlideProps) => {
  const [isLargerThan679] = useMediaQuery(`(min-width: 679px)`);
  const [isLargerThanMd] = useMediaQuery(
    `(min-width: ${theme.breakpoints.md})`
  );
  const bgImg = () => {
    switch (true) {
      case isLargerThan679 && !isLargerThanMd:
        return slide.bgImgXs;
      case isLargerThan679 && isLargerThanMd:
        return slide.bgImgMd;
      case !isLargerThan679 && !isLargerThanMd:
        return slide.bgImg;
      default:
        return slide.bgImg;
    }
  };

  const bgPosition = () => {
    switch (true) {
      case isLargerThan679 && !isLargerThanMd:
        return slide.bgXsPosition;
      case isLargerThan679 && isLargerThanMd:
        return slide.bgMdPosition;
      case !isLargerThan679 && !isLargerThanMd:
        return slide.bgPosition;
      default:
        return slide.bgPosition;
    }
  };

  const bgSize = () => {
    switch (true) {
      case isLargerThan679 && !isLargerThanMd:
        return slide.bgXsSize;
      case isLargerThan679 && isLargerThanMd:
        return slide.bgMdSize;
      case !isLargerThan679 && !isLargerThanMd:
        return slide.bgSize;
      default:
        return slide.bgSize;
    }
  };

  function handleClick() {
    window.location.href = slide.href;
  }

  return (
    <Box
      onClick={handleClick}
      position="relative"
      minH={isLargerThan679 ? '452px' : '540px'}
      bgColor={slide.bgColor}
      bgGradient={slide?.bgGradient}
      borderRadius="2xl"
      _hover={{
        cursor: 'pointer',
      }}
    >
      <Box
        zIndex="0"
        position="absolute"
        minWidth="100%"
        minH={{ base: '540px', xs: '452px' }}
        height="100%"
        borderRadius="2xl"
        minHeight="262px"
        bgImg={bgImg()}
        bgRepeat="no-repeat"
        bgPosition={bgPosition()}
        bgSize={bgSize()}
      />
      <Flex
        zIndex="1"
        position="absolute"
        direction="column"
        justifyContent={isLargerThan679 ? 'center' : 'flex-end'}
        minH={isLargerThan679 ? '452px' : '540px'}
        height="100%"
        width={isLargerThan679 ? 'auto' : '100%'}
        borderRadius="2xl"
        bgGradient={isLargerThan679 ? 'none' : slide.linearGradient}
        p={isLargerThan679 ? '68px 46px 56px 46px' : '4'}
      >
        {isLargerThan679 ? (
          <Box mb="7">
            {slide.logoXsUrl.type === 'svg' ? (
              <SvgIconLight height={54} icon={slide.logoXsUrl.icon} />
            ) : (
              <Image height={54} src={slide.logoXsUrl.src} />
            )}
          </Box>
        ) : (
          <Flex mb="4">
            <Box mr="4">
              <Avatar
                boxShadow="none"
                borderRadius="base"
                w="40px"
                h="40px"
                src={slide.logoUrl}
                srcSet={`${slide.logoUrl2x} 2x`}
              />
            </Box>
            <Box>
              <Box mb="0.5">
                <Text
                  color={slide.textColor}
                  fontWeight="bold"
                  fontSize="md"
                  lineHeight="19px"
                  letterSpacing="tight"
                >
                  {slide.name}
                </Text>
              </Box>
              <Box>
                <Text
                  color={slide.textColor}
                  fontWeight="normal"
                  fontSize="md"
                  lineHeight="19px"
                  letterSpacing="tight"
                  opacity="0.4"
                >
                  {slide.description}
                </Text>
              </Box>
            </Box>
          </Flex>
        )}
        <Box mb="4">
          <Text
            maxW="337px"
            noOfLines={2}
            color={slide.textColor}
            fontSize="xl"
            fontWeight="bold"
            lineHeight="28.8px"
            letterSpacing="tight"
          >
            {slide.title}
          </Text>
        </Box>
        {isLargerThan679 && (
          <Box mb="5" maxW={slide.subTitleWidth}>
            <Text
              noOfLines={3}
              color={slide.textColor}
              fontSize="md"
              fontWeight="light"
              lineHeight="20px"
            >
              {slide.subTitle}
            </Text>
          </Box>
        )}
        <Flex mb={{ base: '50px', xs: '30px' }}>
          {slide.feats.map((feat) => {
            return (
              <Box key={feat.title} flex="1 1 0px">
                <Text
                  color={slide.textColor}
                  fontSize="md"
                  fontWeight="bold"
                  lineHeight="19.36px"
                  letterSpacing="tight"
                >
                  {feat.value}
                </Text>
                <Text
                  color={slide.textColor}
                  fontSize="md"
                  fontWeight="normal"
                  lineHeight="19.36px"
                  letterSpacing="tight"
                >
                  {feat.title}
                </Text>
              </Box>
            );
          })}
        </Flex>
        <Box>
          <Link
            href={slide.href}
            color={slide.textColor}
            _hover={{
              textDecoration: 'none',
            }}
            _active={{
              textDecoration: 'none',
            }}
          >
            <Button
              h="12"
              bgColor={isLargerThan679 ? 'brand.primary' : slide.buttonBgColor}
              color={isLargerThan679 ? 'white' : slide.textColor}
              px={{ xs: 7 }}
              variant={isLargerThan679 ? 'republic-primary' : 'outline'}
              w={isLargerThan679 ? 'auto' : '100%'}
              borderColor={
                isLargerThan679 ? 'transparent' : slide.buttonBdColor
              }
              borderRadius={{ base: 'lg', xs: 'md' }}
            >
              {slide.buttonText ?? 'View case study'}
            </Button>
          </Link>
        </Box>
      </Flex>
    </Box>
  );
};
