/* eslint-disable @nx/enforce-module-boundaries */
import { formAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  formAnatomy.keys
);

export const Form = defineMultiStyleConfig({
  baseStyle: {
    helperText: {
      fontSize: '12px',
      color: 'brand.grey.7c',
    },
  },
});
